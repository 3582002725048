export interface ICustomerSearchUserSite {
  readonly custId: string;
  readonly siteId: string;
  readonly name: string;
  readonly siteAddress: string;
  readonly siteCode: string;
}

class CustomerSearchUserSite {
  custId?: string;
  siteId?: string;
  name?: string;
  siteAddress?: string;
  siteCode?: string;

  constructor(data?: Partial<CustomerSearchUserSite>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.name = data?.name;
    this.siteAddress = data?.siteAddress;
    this.siteCode = data?.siteCode;
  }
}

export default CustomerSearchUserSite;
