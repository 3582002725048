import update from 'immutability-helper';
import { isNumber } from 'lodash-es';

import RecurringService from 'contracts/models/service/RecurringService';
import RecurringServiceDropdownOptions from 'contracts/models/service/RecurringServiceDropdownOptions';
import RecurringServiceOptions from 'contracts/models/service/RecurringServiceOptions';
import { ActionDispatcher, OnDemandPickUpsEditAction } from 'contracts/types/action';
import { BulkUploadTemplateType, 
  OnDemandPickUpRequest, 
  GetOnDemandPickUpOptionsRequest, 
  GetOnDemandPickUpsRequest
} from 'contracts/types/request';
import { ApplicationState, OnDemandPickUpsEditState, ReduceFunctionMap } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as onDemandPickUpsServices from '../services/onDemandPickUpsServices';

// Actions Keys
const ROOT_KEY = 'bulk-upload/bulk-upload';
enum ActionKey {
  LOAD_ON_DEMAND_PICKUPS = 'bulk-upload/LOAD_ON_DEMAND_PICKUPS',
  LOAD_ON_DEMAND_PICKUPS_OPTIONS = 'bulk-upload/LOAD_ON_DEMAND_PICKUPS_OPTIONS',
  LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS = 'bulk-upload/LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS',
  CREATE_ON_DEMAND_PICKUP = 'bulk-upload/CREATE_ON_DEMAND_PICKUP',
  UPDATE_ON_DEMAND_PICKUP = 'bulk-upload/UPDATE_ON_DEMAND_PICKUP',
  RESET = 'bulk-upload/RESET',
}

// Initial State
const getInitialState: () => OnDemandPickUpsEditState = () => {
  return {
    pickUps: [],
    pickUpsOptions: undefined,
    pickUpsDropdownOptions: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_ON_DEMAND_PICKUPS, 
  ActionKey.LOAD_ON_DEMAND_PICKUPS_OPTIONS,
  ActionKey.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS,
  ActionKey.CREATE_ON_DEMAND_PICKUP,
  ActionKey.UPDATE_ON_DEMAND_PICKUP,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  OnDemandPickUpsEditState,
  OnDemandPickUpsEditAction
> = {
  [ActionKey.LOAD_ON_DEMAND_PICKUPS]: (state, action) => {
    if (!action.pickUps) {
      return state;
    }
    const pickUps = action.pickUps;

    return update(state, { $merge: { pickUps } });
  },
  [ActionKey.LOAD_ON_DEMAND_PICKUPS_OPTIONS]: (state, action) => {
    if (!action.pickUpsOptions) {
      return state;
    }
    const pickUpsOptions = action.pickUpsOptions;
    return update(state, { $merge: { pickUpsOptions } });
  },
  [ActionKey.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS]: (state, action) => {
    if (!action.pickUpsDropdownOptions) {
      return state;
    }
    const pickUpsDropdownOptions = action.pickUpsDropdownOptions;
    return update(state, { $merge: { pickUpsDropdownOptions } });
  },
  [ActionKey.CREATE_ON_DEMAND_PICKUP]: (state, action) => {
    const { pickUp } = action;
    if (
      !state.pickUps ||
      !pickUp
    ) {
      return state;
    }
    const newState = update(state, {
      pickUps: { $push: [pickUp] },
    });
    return newState;
  },
  [ActionKey.UPDATE_ON_DEMAND_PICKUP]: (state, action) => {
    const { indexPickUp, pickUp } = action;
    if (
      !isNumber(indexPickUp) ||
      !state.pickUps ||
      state.pickUps.length <= indexPickUp ||
      !pickUp
    ) {
      return state;
    }
    const newState = update(state, {
      pickUps: {
        [indexPickUp]: {
          $merge: {
            ...pickUp,
          },
        },
      },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<OnDemandPickUpsEditState, OnDemandPickUpsEditAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_ON_DEMAND_PICKUPS: (pickUps?: RecurringService[]): OnDemandPickUpsEditAction => ({
    type: ActionKey.LOAD_ON_DEMAND_PICKUPS,
    pickUps: pickUps || [],
  }),
  LOAD_ON_DEMAND_PICKUPS_OPTIONS: (pickUpsOptions?: RecurringServiceOptions): OnDemandPickUpsEditAction => ({
    type: ActionKey.LOAD_ON_DEMAND_PICKUPS_OPTIONS,
    pickUpsOptions: pickUpsOptions || undefined,
  }),
  LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS: 
  (pickUpsDropdownOptions?: RecurringServiceDropdownOptions): OnDemandPickUpsEditAction => ({
    type: ActionKey.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS,
    pickUpsDropdownOptions: pickUpsDropdownOptions || undefined,
  }),
  CREATE_ON_DEMAND_PICKUP: (pickUp?: RecurringService): OnDemandPickUpsEditAction => ({
    type: ActionKey.CREATE_ON_DEMAND_PICKUP,
    pickUp,
  }),
  UPDATE_ON_DEMAND_PICKUP: (indexPickUp?: number, pickUp?: RecurringService): OnDemandPickUpsEditAction => ({
    type: ActionKey.UPDATE_ON_DEMAND_PICKUP,
    pickUp,
    indexPickUp,
  }),
  RESET: (): OnDemandPickUpsEditAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadOnDemandPickUps = (request: GetOnDemandPickUpsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ON_DEMAND_PICKUPS,
    () => onDemandPickUpsServices.getOnDemandPickUps(request),
    result => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS(result.services));
    },
    () => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS());
    },
    true,
  );

const loadOnDemandPickUpOptions = (req: GetOnDemandPickUpOptionsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ON_DEMAND_PICKUPS_OPTIONS,
    () => onDemandPickUpsServices.getOnDemandPickUpOptions(req),
    result => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS_OPTIONS());
    },
    true,
  );

const loadOnDemandPickUpsDropdownOptions = (serviceType: BulkUploadTemplateType) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS,
    () => onDemandPickUpsServices.getOnDemandDropdownOptions(serviceType),
    result => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_ON_DEMAND_PICKUPS_DROPDOWN_OPTIONS());
    },
    true,
  );

const createOnDemandPickUp = (req: OnDemandPickUpRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CREATE_ON_DEMAND_PICKUP,
    () => onDemandPickUpsServices.createOnDemandPickUp(req),
    () => {

      dispatch(actionMap.CREATE_ON_DEMAND_PICKUP(req));
    },
    () => {
      dispatch(actionMap.CREATE_ON_DEMAND_PICKUP());
    },
    true,
  );
  
const updateOnDemandPickUp = (req: OnDemandPickUpRequest, indexService: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_ON_DEMAND_PICKUP,
    () => onDemandPickUpsServices.updateOnDemandPickUp(req),
    () => {

      dispatch(actionMap.UPDATE_ON_DEMAND_PICKUP(indexService, req));
    },
    () => {
      dispatch(actionMap.UPDATE_ON_DEMAND_PICKUP());
    },
    true,
  );

const onDemandPickUpsDuck = {
  thunks: { 
    loadOnDemandPickUps, 
    loadOnDemandPickUpOptions,
    loadOnDemandPickUpsDropdownOptions,
    createOnDemandPickUp,
    updateOnDemandPickUp,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default onDemandPickUpsDuck;
