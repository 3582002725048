import styled from 'styled-components';

import { theme } from 'core/styles';

export const EmptySectionContainer = styled.div`
  display: flex;
  flex-direction: 'column';
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: #555;
  background-color: ${theme.colors.brandWhite};

  text-align: center;
  font-size: 12px;
  font-style: normal;
`;

export default EmptySectionContainer;
