enum OptimizationReportSortKey {
  GusSiteCode = 'GusSiteCode',
  SiteName = 'SiteName',
  Address = 'Address',
  Equipment = 'Equipment',
  Size = 'Size',
  Material = 'Material',
  TonsPerHaul = 'TonsPerHaul',
  NumberOfHauls = 'NumberOfHauls',
  Goal = 'Goal',
  Variance = 'Variance',
  AvgDaysBetweenService = 'AvgDaysBetweenService',
}

export default OptimizationReportSortKey;
