enum EquipmentType {
  CLOSED_TOP = 'Closed Top',
  COMPACTOR = 'Stationary Compactor',
  CART = 'Cart',
  FRONT_LOAD = 'Front Load',
  LUGGER = 'Lugger',
  OPEN_TOP = 'Open Top',
  OPEN_TOP_TEMPORARY = 'Open Top Temporary',
  RECEIVER_BOX = 'Receiver Box',
  REAR_LOAD = 'Rear Load',
  SELF_CONTAINED = 'Self Contained',
  SIDE_LOAD = 'Side Load',
  VERTICAL_COMPACTOR = 'Vertical Compactor',
  COMMODITY_PALLET = 'Pallet',
  COMMODITY_CARDBOARD = 'Cardboard',
  COMMODITY_METAL = 'Metal',
  COMMODITY_PLASTIC = 'Plastic',
  COMMODITY_ORGANICS = 'Organics',
  COMMODITY_PAPER = 'Paper',
  COMMODITY_COMBO = 'Combo',
  COMMODITY_WIRESACCESSORIES = 'Wires/Accessories'
}
export default EquipmentType;
