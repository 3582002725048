import React from 'react';

import { RiskType } from 'contracts/models/service/RegWatchAssessment';
import { RegWatchPriorityFilter as RegWatchPriorityFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

const priorityOptions = [
  { id: RiskType.High, label: translate('regwatch.highRisk') },
  { id: RiskType.Medium, label: translate('regwatch.mediumRisk') },
  { id: RiskType.Low, label: translate('regwatch.lowRisk') },
];

const RegWatchPriorityFilter: React.FC<ComponentProps> = ({
  currentFilter,
  onFilterChanged,
  isDisabled,
  isLoading,
}) => {
  const filterChanged = (priorities: RiskType[]): void => {
    onFilterChanged({
      ...currentFilter,
      priorities,
    });
  };

  return (<DropdownFilter
    label={translate('regwatch.priority')}
    values={priorityOptions}
    selectedIds={currentFilter.priorities || []}
    onFilterChanged={filterChanged}
    isDisabled={isDisabled}
    isLoading={isLoading}
  />);
};

interface ComponentProps {
  currentFilter: RegWatchPriorityFilterType;
  onFilterChanged: (filter: RegWatchPriorityFilterType) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export default RegWatchPriorityFilter;
