import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { CieTradeRequestType } from 'contracts/models';
import {
  ActionDispatcher,
  BaseAction,
  CieTradeAction,
} from 'contracts/types/action';
import { SiteIdentifier } from 'contracts/types/component';
import { CieTradeRequest } from 'contracts/types/request';
import {
  ApplicationState,
  CieTradeState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getHasCietradeAccess,
  getRequestTypes,
  sendCieTrade,
} from 'services/services/cieTradeService';

// Actions Keys
const ROOT_KEY = 'cietrade';
enum ActionKey {
  LOAD_CIETRADE_REQUEST_TYPES = 'cietrade/LOAD_CIETRADE_REQUEST_TYPES',
  SEND_CIETRADE_REQUEST = 'cietrade/SEND_CIETRADE_REQUEST',
  LOAD_HAS_CIETRADE_ACCESS = 'cietrade/LOAD_HAS_CIETRADE_ACCESS',
  WAS_REQUEST_SENT_SUCCESSFULLY = 'cietrade/WAS_REQUEST_SENT_SUCCESSFULLY',
  RESET = 'cietrade/RESET',
}

// Initial state
const getInitialState: () => CieTradeState = () => {
  return {
    holidays: [],
    requestTypes: [],
    hasCietradeAccess: false,
    wasRequestSentSuccessfully: false,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_CIETRADE_REQUEST_TYPES,
  ActionKey.LOAD_HAS_CIETRADE_ACCESS,
  ActionKey.WAS_REQUEST_SENT_SUCCESSFULLY,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CieTradeState,
  CieTradeAction
> = {
  [ActionKey.LOAD_CIETRADE_REQUEST_TYPES]: (state, action) => {
    const { requestTypes } = action;
    return update(state, { $merge: { requestTypes } });
  },
  [ActionKey.LOAD_HAS_CIETRADE_ACCESS]: (state, action) => {
    const { hasCietradeAccess } = action;
    return update(state, { $merge: { hasCietradeAccess } });
  },
  [ActionKey.WAS_REQUEST_SENT_SUCCESSFULLY]: (state, action) => {
    const { wasRequestSentSuccessfully } = action;
    return update(state, { $merge: { wasRequestSentSuccessfully } });
  },
};

export const reducer = getReducerBuilder<CieTradeState, CieTradeAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_CIETRADE_REQUEST_TYPES: (
    requestTypes?: CieTradeRequestType[],
  ): CieTradeAction => ({
    type: ActionKey.LOAD_CIETRADE_REQUEST_TYPES,
    requestTypes,
  }),
  LOAD_HAS_CIETRADE_ACCESS: (hasCietradeAccess?: boolean): CieTradeAction => ({
    type: ActionKey.LOAD_HAS_CIETRADE_ACCESS,
    hasCietradeAccess,
  }),
  WAS_REQUEST_SENT_SUCCESSFULLY: (
    wasRequestSentSuccessfully: boolean,
  ): CieTradeAction => ({
    type: ActionKey.WAS_REQUEST_SENT_SUCCESSFULLY,
    wasRequestSentSuccessfully,
  }),
  RESET: (): BaseAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadHasCieTradeAccess = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_HAS_CIETRADE_ACCESS,
    async() => getHasCietradeAccess(siteIdentifier),
    result => {
      dispatch(actionMap.LOAD_HAS_CIETRADE_ACCESS(result));
    },
    () => {
      dispatch(actionMap.LOAD_HAS_CIETRADE_ACCESS());
    },
  );

const loadCieTradeRequestTypes = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CIETRADE_REQUEST_TYPES,
    async() => getRequestTypes(),
    result => {
      dispatch(actionMap.LOAD_CIETRADE_REQUEST_TYPES(result));
    },
    () => {
      dispatch(actionMap.LOAD_CIETRADE_REQUEST_TYPES());
    },
  );

export const sendCieTradeRequest = (cieTradeRequest: CieTradeRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SEND_CIETRADE_REQUEST,
    async() => sendCieTrade(cieTradeRequest),
    () => {
      dispatch(actionMap.WAS_REQUEST_SENT_SUCCESSFULLY(true));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Your request was sent successfully!',
        ),
      );
    },
    () => {
      dispatch(actionMap.WAS_REQUEST_SENT_SUCCESSFULLY(false));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          `Your request wasn't sent!`,
        ),
      );
    },
  );

const cieTradeDuck = {
  thunks: {
    sendCieTradeRequest,
    loadCieTradeRequestTypes,
    loadHasCieTradeAccess,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default cieTradeDuck;
