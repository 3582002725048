import BulkDataReport, { IBulkDataReport } from 'contracts/models/service/BulkDataReport';
import { BulkUploadTemplateType } from 'contracts/types/request';
import { GetBlobResponse, UploadFileRequest, UploadFileResponse } from 'contracts/types/service';
import { getBlobFile, getPostBlobFile, writeBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getReports(serviceType: BulkUploadTemplateType): Promise<BulkDataReport[]> {
  const response = await httpCustomerCore.post<BulkDataReport[]>(
    'self-managed/get-files',
    {
      uploadType: serviceType
    },
    {
      transformResponse: transformServiceResponse<IBulkDataReport, BulkDataReport>(BulkDataReport),
    },
  );
  return response.data;
}

export async function getReportDocument(id: number): Promise<GetBlobResponse> {
  const urlPath = `self-managed/download-report-file`;
  const reportDocument = getBlobFile(httpCustomerCore, urlPath, 'xlsx', { trackingId: id });
  return reportDocument;
}

export async function getOriginalReport(id: number): Promise<GetBlobResponse> {
  const urlPath = `self-managed/download-original-file`;
  const reportDocument = getBlobFile(httpCustomerCore, urlPath, 'xlsx', { trackingId: id });
  return reportDocument;
}

export async function uploadReportDocument(
  uploadDocumentRequest: UploadFileRequest,
  templateType: string
): Promise<UploadFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    `/self-managed/upload-file?uploadType=${templateType}`,
    uploadDocumentRequest,
  );
  return response;
}

export async function uploadReccuringServicesDocument(
  uploadDocumentRequest: UploadFileRequest,
): Promise<UploadFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    `/self-managed/upload-file?uploadType=${BulkUploadTemplateType.recurringService}`,
    uploadDocumentRequest,
  );
  return response;
}

export async function uploadSeasonalServicesDocument(
  uploadDocumentRequest: UploadFileRequest,
): Promise<UploadFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    `/self-managed/upload-file?uploadType=${BulkUploadTemplateType.seasonalService}`,
    uploadDocumentRequest,
  );
  return response;
}

export async function exportDataFile(serviceType: BulkUploadTemplateType): Promise<GetBlobResponse> {
  const file = await getPostBlobFile(httpCustomerCore, `self-managed/get-template/${serviceType}`, 'xlsx');
  return file;
}
