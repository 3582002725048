import { SiteIdentifier } from 'contracts/types/component';

export interface IFilterTag {
  readonly tagId: number;
  readonly tagName: string;
  readonly siteIds: string[];
  readonly siteIdetifiers: TagStoreFilter[];
}

class FilterTag {
  tagId?: number;
  tagName?: string;
  siteIds?: string[];
  siteIdetifiers?: SiteIdentifier[];

  constructor(data?: Partial<IFilterTag>) {
    this.tagId = data?.tagId;
    this.tagName = data?.tagName;
    this.siteIds = data?.siteIds;
    if (data?.siteIdetifiers) {
      this.siteIdetifiers = data?.siteIdetifiers.map(siteIdetifier => ({
        siteId: siteIdetifier.siteId,
        siteCustomerId: siteIdetifier.siteCustId,
      }));
    }
  }
}

export interface TagStoreFilter {
  siteCustId: string;
  siteId: string;
}

export default FilterTag;
