export interface ISiteProfileEdit {
  readonly siteId: string;
  readonly custId: string;
  readonly siteName: string;
  readonly address: string;
  readonly tags: SiteProfileEditTag[];
  readonly users: SiteProfileEditUser[];
}

class SiteProfileEdit {
  siteId?: string;
  custId?: string;
  siteName?: string;
  address?: string;
  tags?: SiteProfileEditTag[];
  users?: SiteProfileEditUser[];

  constructor(data?: Partial<ISiteProfileEdit>) {
    this.siteId = data?.siteId;
    this.custId = data?.custId;
    this.siteName = data?.siteName;
    this.address = data?.address;
    this.tags = data?.tags;
    this.users = data?.users;
  }
}

export interface SiteProfileEditUser {
  userId: number;
  name: string;
  isLinked: boolean;
  isEditable: boolean;
}

export interface SiteProfileEditTag {
  tagId: number;
  name: string;
  isLinked: boolean;
}

export default SiteProfileEdit;
