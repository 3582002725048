import User, { IUser } from 'contracts/models/service/User';
import { SessionClaims } from 'contracts/types/component';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

import { createSession, destroySession, getSessionClaims } from './session';

export interface LoginResponse {
  user: User;
  claims?: SessionClaims;
  authToken?: string;
}

export interface ExternalLoginResponseModel {
  redirectUrl: string;
  code: string;
}

export async function login(
  email: string,
  password: string,
): Promise<LoginResponse> {
  destroySession();
  const response = await httpCustomerCore.post<User>(
    'account/portal-login',
    { username: email, password },
    { transformResponse: transformServiceResponse<IUser, User>(User) },
  );
  const user = response.data;
  const authToken = response.headers.authorization;
  createSession(authToken, user);
  return { user, claims: getSessionClaims() };
}

export async function loginGusUser(
  tokenId: string,
  customerId: string,
): Promise<LoginResponse> {
  destroySession();
  const response = await httpCustomerCore.post<User>(
    'account/login-gus-user',
    { tokenId, custId: customerId },
    { transformResponse: transformServiceResponse<IUser, User>(User) },
  );
  const user = response.data;
  const authToken = response.headers.authorization;
  createSession(authToken, user);
  return { user, claims: getSessionClaims() };
}

export async function loginFedexUser(
  requestId: string,
): Promise<LoginResponse> {
  destroySession();
  const response = await httpCustomerCore.post<User>(
    'sso/login',
    { requestId },
    { transformResponse: transformServiceResponse<IUser, User>(User) },
  );
  const user = response.data;
  const authToken = response.headers.authorization;
  createSession(authToken, user);
  return { user, claims: getSessionClaims() };
}

export async function registerAndLoginWholesaleUser({
  customerName,
  email,
  firstName,
  lastName,
  phone,
}: {
  customerName: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}): Promise<LoginResponse> {
  destroySession();
  const args = { customerName, email, firstName, lastName, phone };
  const response = await httpCustomerCore.post<User>(
    'wholesale/pre-register',
    args,
    { transformResponse: transformServiceResponse<IUser, User>(User) },
  );
  const user = response.data;
  const authToken = response.headers.authorization;
  createSession(authToken, user);
  return { user, claims: getSessionClaims() };
}

export async function loginToLibryo(
  clientId: string,
  redirectUri: string,
): Promise<ExternalLoginResponseModel> {
  const response = await httpCustomerCore.post('open-auth/private', {
    clientId,
    redirectUri,
  });
  return response.data;
}

export async function refreshToken(): Promise<LoginResponse> {
  // destroySession();
  const response = await httpCustomerCore.post<User>(
    'account/refresh',
    { refreshToken },
    { transformResponse: transformServiceResponse<IUser, User>(User) },
  );
  const user = response.data;
  const authToken = response.headers.authorization;
  createSession(authToken, user);
  return { user, claims: getSessionClaims(), authToken };
}

export function logout(): void {
  destroySession(true);
}

export async function networkCheck(): Promise<LoginResponse> {
  const response = await httpCustomerCore.get('network/check');
  return response.data;
}
