import update from 'immutability-helper';

import NotificationItem, { NotificationItemDetails } from 'contracts/models/service/NotificationItem';
import { ActionDispatcher, NotificationsAction } from 'contracts/types/action';
import {
  NotificationsListRequest,
  UnreadNotificationCounterRequest,
} from 'contracts/types/request';
import {
  ApplicationState,
  NotificationsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as notificationsService from '../services/notifications';

// Actions Keys
const ROOT_KEY = 'notifications';
enum ActionKey {
  LOAD_NOTIFICATIONS_LIST = 'notifications/LOAD_NOTIFICATIONS_LIST',
  LOAD_LATEST_NOTIFICATIONS = 'notifications/LOAD_LATEST_NOTIFICATIONS',
  LOAD_NOTIFICATION_DETAILS = 'notifications/LOAD_NOTIFICATION_DETAILS',
  LOAD_NOTIFICATIONS_COUNTER = 'notifications/LOAD_NOTIFICATIONS_COUNTER',
  MARK_NOTIFICATIONS_AS_READ = 'notifications/MARK_NOTIFICATIONS_AS_READ',
  LISTEN_NOTIFICATIONS = 'notifications/LISTEN_NOTIFICATIONS',
  RESET_NOTIFICATIONS_LIST = 'notifications/RESET_NOTIFICATIONS_LIST',
  RESET = 'notifications/RESET',
}

// Initial State
const getInitialState: () => NotificationsState = () => {
  return {
    notificationsList: [],
    latestNotificationsList: undefined,
    nrUnreadNotifications: null,
    currentNotificationDetails: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_NOTIFICATIONS_LIST,
  ActionKey.LOAD_LATEST_NOTIFICATIONS,
  ActionKey.LOAD_NOTIFICATION_DETAILS,
  ActionKey.LOAD_NOTIFICATIONS_COUNTER,
  ActionKey.MARK_NOTIFICATIONS_AS_READ,
  ActionKey.RESET_NOTIFICATIONS_LIST,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NotificationsState,
  NotificationsAction
> = {
  [ActionKey.LOAD_NOTIFICATIONS_LIST]: (state, action) => {
    const { notificationsList } = action;
    if (!notificationsList) {
      return state;
    }
    return update(state, { $merge: { notificationsList } });
  },
  [ActionKey.LOAD_LATEST_NOTIFICATIONS]: (state, action) => {
    const { latestNotificationsList } = action;
    if (!latestNotificationsList) {
      return state;
    }
    return update(state, {
      $merge: {
        ...state,
        latestNotificationsList: latestNotificationsList,
      },
    });
  },
  [ActionKey.LOAD_NOTIFICATION_DETAILS]: (state, action) => {
    const { currentNotificationDetails } = action;
    return update(state, { $merge: { currentNotificationDetails } });
  },
  [ActionKey.LOAD_NOTIFICATIONS_COUNTER]: (state, action) => {
    const { nrUnreadNotifications } = action;
    if (typeof nrUnreadNotifications !== 'number') {
      return state;
    }
    return update(state, { $merge: { nrUnreadNotifications } });
  },
  [ActionKey.MARK_NOTIFICATIONS_AS_READ]: state => {
    return state;
  },
  [ActionKey.RESET_NOTIFICATIONS_LIST]: state => {
    return update(state, { $merge: { notificationsList: [] } });
  },
};

export const reducer = getReducerBuilder<
  NotificationsState,
  NotificationsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_NOTIFICATIONS_LIST: (
    notificationsList?: NotificationItem[],
  ): NotificationsAction => ({
    type: ActionKey.LOAD_NOTIFICATIONS_LIST,
    notificationsList,
  }),
  LOAD_LATEST_NOTIFICATIONS: (
    latestNotificationsList?: NotificationItem[],
  ): NotificationsAction => ({
    type: ActionKey.LOAD_LATEST_NOTIFICATIONS,
    latestNotificationsList,
  }),
  LOAD_NOTIFICATION_DETAILS: (
    currentNotificationDetails?: NotificationItemDetails,
  ): NotificationsAction => ({
    type: ActionKey.LOAD_NOTIFICATION_DETAILS,
    currentNotificationDetails,
  }),
  LOAD_NOTIFICATIONS_COUNTER: (
    nrUnreadNotifications?: number,
  ): NotificationsAction => ({
    type: ActionKey.LOAD_NOTIFICATIONS_COUNTER,
    nrUnreadNotifications,
  }),
  MARK_NOTIFICATIONS_AS_READ: () => ({
    type: ActionKey.MARK_NOTIFICATIONS_AS_READ,
  }),
  LISTEN_NOTIFICATIONS: (): NotificationsAction => ({
    type: ActionKey.LOAD_NOTIFICATIONS_COUNTER,
  }),
  RESET_NOTIFICATIONS_LIST: (): NotificationsAction => ({
    type: ActionKey.RESET_NOTIFICATIONS_LIST,
  }),
  RESET: (): NotificationsAction => ({ type: ActionKey.RESET }),
};

const loadNotificationsList = (inputArg: NotificationsListRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NOTIFICATIONS_LIST,
    () => notificationsService.getNotifications(inputArg),
    result => dispatch(actionMap.LOAD_NOTIFICATIONS_LIST(result)),
    () => dispatch(actionMap.LOAD_NOTIFICATIONS_LIST()),
  );

const loadLatestNotifications = (inputArg: NotificationsListRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_LATEST_NOTIFICATIONS,
    () => notificationsService.getNotifications(inputArg),
    result => {
      dispatch(actionMap.LOAD_LATEST_NOTIFICATIONS(result));
    },
    () => dispatch(actionMap.LOAD_LATEST_NOTIFICATIONS([])),
  );

const loadNotificationDetails = (notificationId: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NOTIFICATION_DETAILS,
    () => notificationsService.getNotificationDetails(notificationId),
    result => dispatch(actionMap.LOAD_NOTIFICATION_DETAILS(result)),
    () => dispatch(actionMap.LOAD_NOTIFICATION_DETAILS()),
  );

const resetNotificationDetails = () => async(dispatch: ActionDispatcher) =>
  dispatch(actionMap.LOAD_NOTIFICATION_DETAILS(undefined));

const loadUnreadNotificationsCounter = ({
  startDate,
  endDate,
}: UnreadNotificationCounterRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NOTIFICATIONS_COUNTER,
    () =>
      notificationsService.getUnreadNotificationsCounter({
        startDate,
        endDate,
      }),
    result => dispatch(actionMap.LOAD_NOTIFICATIONS_COUNTER(result.count)),
    () => dispatch(actionMap.LOAD_NOTIFICATIONS_COUNTER()),
  );

const listenNotifications = (callBack: () => void) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LISTEN_NOTIFICATIONS,
    () =>
      notificationsService.listenNotification(callBack),
    () => dispatch(actionMap.LISTEN_NOTIFICATIONS())
  );
};

const markNotificationsAsRead = (notificationIds: number[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.MARK_NOTIFICATIONS_AS_READ,
    async() => notificationsService.markNotificationsAsRead(notificationIds),
    () => {
      dispatch(actionMap.MARK_NOTIFICATIONS_AS_READ());
    },
    () => {
      dispatch(actionMap.MARK_NOTIFICATIONS_AS_READ());
    },
    true,
  );

const notificationsDuck = {
  thunks: {
    loadNotificationsList,
    loadLatestNotifications,
    loadNotificationDetails,
    resetNotificationDetails,
    loadUnreadNotificationsCounter,
    listenNotifications,
    markNotificationsAsRead,
  },
  actions: {
    reset: actionMap.RESET,
    resetNotificationsList: actionMap.RESET_NOTIFICATIONS_LIST,
  },
  actionKeys: ActionKey,
};

export default notificationsDuck;
