import { DateRange } from 'contracts/types/service';

export interface MaterialProfileMaterialItem {
  name: string;
  identifier: string;
  materialTonnages: number[];
  materialPounds: number[];
}

export interface IMaterialProfileComparison {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly materialEntries: MaterialProfileMaterialItem[];
}

class MaterialProfileComparison {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  materialEntries?: MaterialProfileMaterialItem[];

  constructor(data?: Partial<IMaterialProfileComparison>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.materialEntries = data?.materialEntries;
  }
}

export default MaterialProfileComparison;
