import update from 'immutability-helper';

import {
  FilterMaterial,
  FilterState,
  FilterStore,
  FilterTag,
} from 'contracts/models';
import OptimizationBinFilterModel from 'contracts/models/service/OptimizationBinFilterModel';
import {
  ActionDispatcher,
  LocationFiltersAction,
} from 'contracts/types/action';
import { BinEquipmentFilterModel } from 'contracts/types/compactorProfile';
import {
  ApplicationState,
  LocationFiltersState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import * as filterService from 'core/services/filterService';

// Actions Keys
const ROOT_KEY = 'core/filters';
enum ActionKey {
  LOAD_FILTER_TAGS = 'core/filters/LOAD_FILTER_TAGS',
  LOAD_FILTER_STORES = 'core/filters/LOAD_FILTER_STORES',
  LOAD_FILTER_STATES = 'core/filters/LOAD_FILTER_STATES',
  LOAD_FILTER_MATERIAL_CATEGORIES = 'core/filters/LOAD_FILTER_MATERIAL_CATEGORIES',
  LOAD_FILTER_EQUIPMENTS = 'core/filters/LOAD_FILTER_EQUIPMENTS',
  LOAD_FILTER_OPTIMIZATION_EQUIPMENTS = 'core/filters/LOAD_FILTER_OPTIMIZATION_EQUIPMENTS',
}

// Initial State
const getInitialState = (): LocationFiltersState => {
  return {
    tags: [],
    stores: [],
    states: [],
    materialCategories: [],
    equipments: [],
    optimizationEquipments: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_FILTER_TAGS,
  ActionKey.LOAD_FILTER_STORES,
  ActionKey.LOAD_FILTER_STATES,
  ActionKey.LOAD_FILTER_MATERIAL_CATEGORIES,
  ActionKey.LOAD_FILTER_EQUIPMENTS,
  ActionKey.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  LocationFiltersState,
  LocationFiltersAction
> = {
  [ActionKey.LOAD_FILTER_TAGS]: (state, action) => {
    const { tags } = action;
    const newState = update(state, { $merge: { tags } });
    return newState;
  },
  [ActionKey.LOAD_FILTER_STORES]: (state, action) => {
    const { stores } = action;
    const newState = update(state, { $merge: { stores } });
    return newState;
  },
  [ActionKey.LOAD_FILTER_STATES]: (state, action) => {
    const { states } = action;
    const newState = update(state, { $merge: { states } });
    return newState;
  },
  [ActionKey.LOAD_FILTER_MATERIAL_CATEGORIES]: (state, action) => {
    const { materialCategories } = action;
    const newState = update(state, { $merge: { materialCategories } });
    return newState;
  },
  [ActionKey.LOAD_FILTER_EQUIPMENTS]: (state, action) => {
    const { equipments } = action;
    const newState = update(state, { $merge: { equipments } });
    return newState;
  },
  [ActionKey.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS]: (state, action) => {
    const { optimizationEquipments } = action;
    const newState = update(state, { $merge: { optimizationEquipments } });
    return newState;
  },
};

export const reducer = getReducerBuilder<
  LocationFiltersState,
  LocationFiltersAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_FILTER_TAGS: (tags: FilterTag[]): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_TAGS,
    tags,
  }),
  LOAD_FILTER_STORES: (stores: FilterStore[]): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_STORES,
    stores,
  }),
  LOAD_FILTER_STATES: (states: FilterState[]): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_STATES,
    states,
  }),
  LOAD_FILTER_MATERIAL_CATEGORIES: (
    materialCategories: FilterMaterial[],
  ): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_MATERIAL_CATEGORIES,
    materialCategories,
  }),
  LOAD_FILTER_EQUIPMENTS: (equipments: BinEquipmentFilterModel[]): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_EQUIPMENTS,
    equipments,
  }),
  LOAD_FILTER_OPTIMIZATION_EQUIPMENTS: (
    optimizationEquipments: OptimizationBinFilterModel[],
  ): LocationFiltersAction => ({
    type: ActionKey.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS,
    optimizationEquipments,
  }),
};

// Thunks
const loadFilterTags = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_FILTER_TAGS,
    async() => filterService.getAllFilterTags(),
    result => {
      dispatch(actionMap.LOAD_FILTER_TAGS(result));
    },
  );

const loadFilterStores = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  const currentStores = getState().core.locationFilters.stores;
  if (!currentStores || !currentStores.length) {
    return runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_FILTER_STORES,
      async() => filterService.getAllFilterStores(),
      result => {
        dispatch(actionMap.LOAD_FILTER_STORES(result));
      },
    );
  }
};

const loadFilterStates = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  const currentStates = getState().core.locationFilters.states;
  if (!currentStates || !currentStates.length) {
    return runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_FILTER_STATES,
      async() => filterService.getAllFilterStates(),
      result => {
        dispatch(actionMap.LOAD_FILTER_STATES(result));
      },
    );
  }
};

const loadFilterMaterialCategories = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  const currentMaterialCategories = getState().core.locationFilters
    .materialCategories;
  if (!currentMaterialCategories || !currentMaterialCategories.length) {
    return runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_FILTER_MATERIAL_CATEGORIES,
      async() => filterService.getAllFilterMaterialCategories(),
      result => {
        dispatch(actionMap.LOAD_FILTER_MATERIAL_CATEGORIES(result));
      },
    );
  }
};

const loadFilterEquipments = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  const currentEquipments = getState().core.locationFilters.equipments;
  if (!currentEquipments || !currentEquipments.length) {
    return runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_FILTER_EQUIPMENTS,
      async() => filterService.getAllFilterEquipments(),
      result => {
        dispatch(actionMap.LOAD_FILTER_EQUIPMENTS(result));
      },
    );
  }
};

const loadFilterOptimizationEquipments = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  const currentOptEquipments = getState().core.locationFilters
    .optimizationEquipments;
  if (!currentOptEquipments || !currentOptEquipments.length) {
    return runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS,
      async() => filterService.getAllFilterOptimizationEquipments(),
      result => {
        dispatch(actionMap.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS(result));
      },
    );
  }
};

const locationFilterDuck = {
  actionKeys: ActionKey,
  thunks: {
    loadFilterTags,
    loadFilterStores,
    loadFilterStates,
    loadFilterMaterialCategories,
    loadFilterEquipments,
    loadFilterOptimizationEquipments,
  },
};

export default locationFilterDuck;
