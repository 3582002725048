import reduce from 'lodash-es/reduce';

import { Sizes } from 'core/styles';

const mapper = (
  map: { [key: string]: string },
  value?: string,
  defaultValue: string = Sizes.SIZE_MEDIUM,
): string =>
  reduce(
    (value || defaultValue).split(' '),
    (result, property) => `${result} ${map[property]} `,
    '',
  ).trim();

export default mapper;
