export interface ICustomerSite {
  readonly custID: string;
  readonly siteID: string;
  readonly name: string;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly country: string;
  readonly county: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly siteCode: string;
}

class CustomerSite {
  custID?: string;
  siteID?: string;
  name?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  county?: string;
  latitude?: number;
  longitude?: number;
  siteCode?: string;

  constructor(data?: Partial<ICustomerSite>) {
    this.custID = data?.custID;
    this.siteID = data?.siteID;
    this.name = data?.name;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.country = data?.country;
    this.county = data?.county;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
    this.siteCode = data?.siteCode;
  }
}

export interface Site extends CustomerSite {
  isChecked: boolean;
}

export default CustomerSite;
