import { RegWatchUpdate } from './RegWatchUpdates';

export interface IRegWatchSiteProfileUpdates {
  readonly updates: RegWatchUpdate[];
  readonly accessToken: string;
}

class RegWatchSiteProfileUpdates {
  updates?: RegWatchUpdate[];
  accessToken?: string;

  constructor(data?: Partial<IRegWatchSiteProfileUpdates>) {
    this.updates = data?.updates;
    this.accessToken = data?.accessToken;
  }
}

export default RegWatchSiteProfileUpdates;
