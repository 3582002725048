import React, { PropsWithChildren } from 'react';

import translate from 'core/helpers/translate';

import { EmptySectionContainer } from './styled/EmptySection';

const defaultText = translate('weCouldNotFindAnyRelevantData');

const EmptySection: React.FC<PropsWithChildren<ComponentProps>> = ({
  isEmpty,
  children,
  emptyText = defaultText,
}): JSX.Element =>
  isEmpty ? (
    <React.Fragment>
      <EmptySectionContainer>{emptyText}</EmptySectionContainer>
    </React.Fragment>
  ) : (
    <>{children}</>
  );

interface ComponentProps {
  isEmpty: boolean;
  emptyText?: string;
}

export default EmptySection;
