import { SiteIdentifier } from 'contracts/types/component';

export interface MaterialManagementSummaryDisposalMethod {
  name: string;
  identifier: SiteIdentifier | string;
  tonnage: number;
  pounds: number;
}

export interface IMaterialManagementSummary {
  readonly totalTons: number;
  readonly totalPounds: number;
  readonly disposalMethods: MaterialManagementSummaryDisposalMethod[];
}

class MaterialManagementSummary {
  totalTons?: number;
  totalPounds?: number;
  disposalMethods?: MaterialManagementSummaryDisposalMethod[];

  constructor(data?: Partial<IMaterialManagementSummary>) {
    this.totalTons = data?.totalTons;
    this.totalPounds = data?.totalPounds;
    this.disposalMethods = data?.disposalMethods;
  }
}

export default MaterialManagementSummary;
