import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { Devices, loading } from 'core/styles';
import theme from 'core/styles/theme';

import loginBanner from '../../assets/login/connect_banner.png';
import loginBannerMedium from '../../assets/login/connect_banner_m.png';
import loginBannerSmall from '../../assets/login/connect_banner_s.png';
import RubiconLogo from '../../assets/rubicon_logo_black_on_white.svg';

export const LoginPageWrapper = styled.div`
  height: auto;
  width: 100%;
  background: ${theme.colors.brandWhite};
  display: block;
`;

export const RubiconHorizontalLogo = styled.div`
  width: 165px;
  height: 30px;
  margin: 30px 0;
  background: url(${RubiconLogo});
  display: inline-block;
  @media screen and (max-width: 1640px) {
    margin: 30px;
  }
  @media ${Devices.laptop} {
    margin: 30px;
  }
`;

export const LoginAreaWrapper = styled.div`
  height: auto;
  width: 100%;
  background: ${theme.colors.brandWhite};
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0 0 50px;
`;

export const LoginFormWrapper = styled.div<LoadingStyledComponentProps>`
  width: 35%;
  min-height: 500px;
  display: inline-block;
  margin: auto;
  padding: 50px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media ${Devices.tablet} {
    width: 100%;
    box-shadow: none;
  }

  ${props =>
    props.isLoading &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: ${transparentize(0.05, theme.colors.grayLighter)};
      }

      &:after {
        position: absolute;
        content: '';
        top: 30%;
        left: 48%;
        z-index: 1000;
        justify-items: center;
        margin: -13px 0 0 -13px;
        ${loading(26)};
      }
    `};
`;

export const QuickPayLoginFormWrapper = styled.div`
  width: 380px;
  display: block;
  margin: 100px auto 30px;
`;

export const LoginPageBanner = styled.div`
  width: 65%;
  min-height: 500px;
  padding: 40px;
  display: inline-block;
  vertical-align: top;
  background: url(${loginBanner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${Devices.tablet} {
    width: 100%;
    background: url(${loginBannerMedium});
    background-repeat: no-repeat;
    min-height: 300px;
    padding: 30px;
  }
  @media ${Devices.mobile} {
    background-position: 60% center;
    background: url(${loginBannerSmall});
    background-repeat: no-repeat;
    min-height: auto;
    padding: 4px;
  }
`;

interface SsoLoginPageBannerProps {
  url?: string;
  urlSmall?: string;
  urlMedium?: string;
}
export const SsoLoginPageBanner = styled.div<SsoLoginPageBannerProps>`
  width: 65%;
  min-height: 500px;
  padding: 40px;
  display: inline-block;
  vertical-align: top;
  ${props =>
    props.url &&
    css`
      background: url(${props.url});
    `};
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${Devices.tablet} {
    width: 100%;
    min-height: 300px;
    padding: 30px;

    ${props =>
    props.urlMedium &&
      css`
        background: url(${props.urlMedium});
      `};
  }
  @media ${Devices.mobile} {
    background-position: 60% center;
    min-height: auto;
    padding: 4px;

    ${props =>
    props.urlSmall &&
        css`
          background: url(${props.urlSmall});
        `};
  }
`;

export const LoginBannerTitle = styled.h1`
  font-size: 44px;
  color: ${theme.colors.brandWhite};
  font-weight: 500;
  line-height: 54px;
  width: 60%;
  user-select: none;
  margin: 0;
  @media ${Devices.tablet} {
    width: 75%;
    font-size: 36px;
    line-height: 44px;
  }
  @media ${Devices.mobile} {
    width: 96%;
    font-size: 30px;
    line-height: 40px;
  }
`;

export const LoginBannerSubTitle = styled.p`
  font-size: 14px;
  color: ${theme.colors.brandWhite};
  font-weight: 500;
  line-height: 18px;
  margin: 100px 0 0;
  user-select: none;
  @media ${Devices.tablet} {
    margin: 120px 0 0;
  }
  @media ${Devices.mobile} {
    margin: 140px 0 0;
  }
`;

export const RubiconConnectLogo = styled.img`
  width: 25%;
  height: auto;
  margin: 80px 0 5px;
  display: block;
  @media ${Devices.tablet} {
    width: 30%;
  }
  @media ${Devices.mobile} {
    width: 40%;
  }
`;

interface LoginFormLinkProps {
  iscenter?: string;
}
export const LoginFormLink = styled(Link)<LoginFormLinkProps>`
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.brandGreenDark};
  display: block;
  margin-top: 15px;
  ${props =>
    props.iscenter &&
    css`
      text-align: center;
    `};
`;

export const SiteWidth = styled.div`
  width: 1250px;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 1640px) {
    width: 100%;
  }
  @media ${Devices.laptop} {
    width: 100%;
  }
`;

export const LoginFormTitle = styled.h1`
  margin-bottom: 60px;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
`;
