import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import accountReducer from 'account/ducks';
import billingReducer from 'billing/ducks';
import bulkUploadReducer from 'bulkUpload/ducks';
import { ApplicationState } from 'contracts/types/state';
import coreReducer from 'core/ducks';
import financialReducer from 'financial/ducks';
import marketplaceRegisterReducer from 'marketplace/ducks';
import recognitionReducer from 'recognition/ducks';
import regWatchReducer from 'regwatch/ducks';
import reportsReducer from 'reports/ducks';
import servicesReducer from 'services/ducks';
import sustainabilityReducer from 'sustainability/ducks';
import wholesaleReducer from 'wholesale/ducks';

const rootReducer = combineReducers<ApplicationState>({
  account: accountReducer,
  services: servicesReducer,
  core: coreReducer,
  billing: billingReducer,
  sustainability: sustainabilityReducer,
  financial: financialReducer,
  reports: reportsReducer,
  bulkUpload: bulkUploadReducer,
  wholesale: wholesaleReducer,
  regWatch: regWatchReducer,
  recognition: recognitionReducer,
  marketplace: marketplaceRegisterReducer,

});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
