export interface IFilterApprover {
  readonly reviewerName: string;
  readonly reviewerEmail: string;
}

class FilterApprover {
  reviewerName?: string;
  reviewerEmail?: string;

  constructor(data?: Partial<IFilterApprover>) {
    this.reviewerName = data?.reviewerName;
    this.reviewerEmail = data?.reviewerEmail;
  }
}

export default FilterApprover;
