import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { getSessionClaims } from 'account/services/session';
import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import { RegWatchRoutes } from '.';

const RegWatchAssessmentsPage = lazy(() => import('regwatch/components/Assessments/RegWatchAssessmentsPage'));
const RegWatchUpdateDetailsPage = lazy(() => import('regwatch/components/Updates/RegWatchUpdateDetailsPage'));
const RegWatchUpdatesPage = lazy(() => import('regwatch/components/Updates/RegWatchUpdatesPage'));
const RegWatchLatestUpdateDetailsPage = lazy(() => import('regwatch/components/Updates/RegWatchLatestUpdateDetailsPage'));

const getDefaultRoute = (): string => {
  const claims = getSessionClaims();
  const defaultRoute = claims?.brandings?.includes(BrandingType.RegWatchUpdates) ?
    RegWatchRoutes.updates : RegWatchRoutes.assessments;
  return RegWatchRoutes.root + defaultRoute;
};

const RegWatchRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={RegWatchRoutes.updates} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.RegWatchUpdatesPage}
      >
        <RegWatchUpdatesPage />
      </AuthRoute>
    } />
    <Route path={RegWatchRoutes.updateDetails} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.RegWatchUpdateDetailsPage}
      >
        <RegWatchUpdateDetailsPage />
      </AuthRoute>
    } />
    <Route path={RegWatchRoutes.latestUpdateDetails} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.RegWatchLatestUpdateDetailsPage}
      >
        <RegWatchLatestUpdateDetailsPage />
      </AuthRoute>
    } />
    <Route path={RegWatchRoutes.assessments} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.RegWatchAssessmentsPage}
      >
        <RegWatchAssessmentsPage />
      </AuthRoute>
    } />

    <Route path="*" element={<Navigate to={getDefaultRoute()} replace />} />
    <Route index element={<Navigate to={getDefaultRoute()} replace/>} />
  </Routes>
);

export default RegWatchRouter;
