import CarbonEmissionsComparison, { ICarbonEmissionsComparison } from 'contracts/models/service/CarbonEmissionsComparison';
import CarbonEmissionsSummary, { ICarbonEmissionsSummary } from 'contracts/models/service/CarbonEmissionsSummary';
import CarbonEmissionsTableItemData, { ICarbonEmissionsTableItemData } from 'contracts/models/service/CarbonEmissionsTableItemData';
import GhGCarbonEmissionsComparison, { IGhGCarbonEmissionsComparison } from 'contracts/models/service/GhGCarbonEmissionsComparison';
import GhGCarbonEmissionsSummary, { IGhGCarbonEmissionsSummary } from 'contracts/models/service/GhGCarbonEmissionsSummary';
import GhGCarbonEmissionsTable, { IGhGCarbonEmissionsTable } from 'contracts/models/service/GhGCarbonEmissionsTable';
import {
  CarbonEmissionsRequest,
  CarbonEmissionsTableRequest,
  GhGCarbonEmissionsRequest,
  GhGCarbonEmissionsTableRequest,
} from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getCarbonEmissionsSummary({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: CarbonEmissionsRequest): Promise<CarbonEmissionsSummary> {
  const response = await httpCustomerCore.post(
    'sustainability/carbon-emissions/summary',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
    {
      transformResponse: transformServiceResponse<
        ICarbonEmissionsSummary,
        CarbonEmissionsSummary
      >(CarbonEmissionsSummary),
    }
  );
  const { data } = response;
  return data;
}

export async function getCarbonEmissionsComparison({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: CarbonEmissionsRequest): Promise<CarbonEmissionsComparison> {
  const response = await httpCustomerCore.post(
    'sustainability/carbon-emissions/comparison',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
    {
      transformResponse: transformServiceResponse<
        ICarbonEmissionsComparison,
        CarbonEmissionsComparison
      >(CarbonEmissionsComparison),
    }
  );
  return response.data;
}

export async function getCarbonEmissionsTable({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
  pageSize,
  pageNumber,
}: CarbonEmissionsTableRequest): Promise<CarbonEmissionsTableItemData[]> {
  const response = await httpCustomerCore.post(
    'sustainability/carbon-emissions/table',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    {
      transformResponse: transformServiceResponse<
        ICarbonEmissionsTableItemData,
        CarbonEmissionsTableItemData
      >(CarbonEmissionsTableItemData),
    }
  );
  return response.data;
}

export async function getCarbonEmissionsReportCsv({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: CarbonEmissionsTableRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'sustainability/carbon-emissions/csv',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
  );
  return response;
}

export async function getGHGProtocolEmissionsSummary({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: GhGCarbonEmissionsRequest): Promise<GhGCarbonEmissionsSummary> {
  const response = await httpCustomerCore.post(
    'sustainability/ghg-carbon-emissions/summary',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
    {
      transformResponse: transformServiceResponse<
        IGhGCarbonEmissionsSummary,
        GhGCarbonEmissionsSummary
      >(GhGCarbonEmissionsSummary),
    }
  );
  const { data } = response;
  return data;
}

export async function getGHGProtocolEmissionsComparison({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: GhGCarbonEmissionsRequest): Promise<GhGCarbonEmissionsComparison> {
  const response = await httpCustomerCore.post(
    'sustainability/ghg-carbon-emissions/comparison',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
    {
      transformResponse: transformServiceResponse<
        IGhGCarbonEmissionsComparison,
        GhGCarbonEmissionsComparison
      >(GhGCarbonEmissionsComparison),
    }
  );
  return response.data;
}

export async function getGHGProtocolEmissionsTable({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
  pageSize,
  pageNumber,
}: GhGCarbonEmissionsTableRequest): Promise<GhGCarbonEmissionsTable> {
  const response = await httpCustomerCore.post(
    'sustainability/ghg-carbon-emissions/table',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    {
      transformResponse: transformServiceResponse<
        IGhGCarbonEmissionsTable,
        GhGCarbonEmissionsTable
      >(GhGCarbonEmissionsTable),
    }
  );
  return response.data;
}

export async function getGHGProtocolEmissionsCsv({
  fromDate,
  toDate,
  locationFilter,
  categoryId,
}: GhGCarbonEmissionsTableRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'sustainability/ghg-carbon-emissions/csv',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
      CategoryId: categoryId,
    },
  );
  return response;
}
