import { InvoiceDetailsReportSortKey } from 'contracts/enums';
import InvoiceComparisonInfo, {
  IInvoiceComparisonInfo,
} from 'contracts/models/service/InvoiceComparisonInfo';
import InvoiceSummaryInfo, {
  IInvoiceSummaryInfo,
} from 'contracts/models/service/InvoiceSummaryInfo';
import InvoiceTableData, {
  IInvoiceTableData,
} from 'contracts/models/service/InvoiceTableData';
import { SiteFiltersModel } from 'contracts/types/compactorProfile';
import { InvoiceDetailsRequest, InvoiceDetailsTableRequestArg } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getInvoiceDetailsSummary(
  arg: InvoiceDetailsRequest,
): Promise<InvoiceSummaryInfo> {
  const response = await httpCustomerCore.post<InvoiceSummaryInfo>(
    'invoice-details/summary',
    createRequestArg(arg),
    {
      transformResponse: transformServiceResponse<
        IInvoiceSummaryInfo,
        InvoiceSummaryInfo
      >(InvoiceSummaryInfo),
    },
  );
  return response.data;
}

export async function getInvoiceDetailsComparison(
  arg: InvoiceDetailsRequest,
): Promise<InvoiceComparisonInfo> {
  const response = await httpCustomerCore.post<InvoiceComparisonInfo>(
    'invoice-details/comparison',
    createRequestArg(arg),
    {
      transformResponse: transformServiceResponse<
        IInvoiceComparisonInfo,
        InvoiceComparisonInfo
      >(InvoiceComparisonInfo),
    },
  );
  return response.data;
}

export async function getInvoiceDetailsTableData(
  arg: InvoiceDetailsRequest,
): Promise<InvoiceTableData[]> {
  const requestArg = createRequestArg(arg);
  const { sortFields } = arg;
  requestArg.orderByAscending = buildSortArg(
    sortFields.ascending,
    InvoiceDetailsReportSortKey,
  );
  requestArg.orderByDescending = buildSortArg(
    sortFields.descending,
    InvoiceDetailsReportSortKey,
  );

  const response = await httpCustomerCore.post<InvoiceTableData[]>(
    'invoice-details/table-data',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IInvoiceTableData,
        InvoiceTableData
      >(InvoiceTableData),
    },
  );
  return response.data;
}

export async function getInvoiceDetailsCsv(
  arg: InvoiceDetailsRequest,
): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'invoice-details/generate-csv',
    createRequestArg(arg),
  );
  return response;
}

function createRequestArg({
  fromDate,
  toDate,
  pageSize,
  pageNumber,
  locationFilter,
  isProject,
  isRecurring,
  chargeType,
  siteIdentifier,
  isPermanent,
  isTemporary
}: InvoiceDetailsRequest): Partial<InvoiceDetailsTableRequestArg> {
  const arg = {
    startDate: fromDate,
    endDate: toDate,
    pageSize: pageSize,
    pageNumber: pageNumber,
    siteFilters: getLocationFilterApiArg(locationFilter) as SiteFiltersModel | null | undefined,
    chargeType: chargeType,
    isProject: isProject,
    isRecurring: isRecurring,
    isPermanent: isPermanent,
    isTemporary: isTemporary,
    siteIdentifier: siteIdentifier
      ? {
        siteId: siteIdentifier.siteId,
        siteCustomerId: siteIdentifier.siteCustomerId,
      }
      : null,
  };
  return arg;
}
