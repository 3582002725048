export const OpenTopTempTypes: string[] = [
  'OT10YD',
  'OT20YD',
  'OT30YD',
  'OT40YD',
];

export const OpenTopTempSizes: { [key: string]: number } = {
  OT10YD: 10,
  OT20YD: 20,
  OT30YD: 30,
  OT40YD: 40,
};
