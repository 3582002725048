export interface ICarbonFootprint {
  readonly totalReduce: number;
  readonly equivalentAmounts: CarbonFootprintAmounts;
  readonly emissionData: CarbonFootprintResponseEmission[];
  readonly emissionsMonthly: boolean;
}

class CarbonFootprint {
  totalReduce?: number;
  equivalentAmounts?: CarbonFootprintAmounts;
  emissionData?: CarbonFootprintResponseEmission[];
  emissionsMonthly?: boolean;

  constructor(data?: Partial<ICarbonFootprint>) {
    this.totalReduce = data?.totalReduce;
    this.equivalentAmounts = data?.equivalentAmounts;
    this.emissionData = data?.emissionData;
    this.emissionsMonthly = data?.emissionsMonthly;
  }
}

export interface CarbonFootprintAmounts {
  carEmissions: number;
  airplaneEmissions: number;
  treesSaved: number;
  homesPowered: number;
  barrelsOfOilSaved: number;
}

export interface CarbonFootprintResponseEmission {
  date: string;
  tons: number;
}

export default CarbonFootprint;
