import React, { PureComponent } from 'react';

import { ServiceTypeFilter as ServiceTypeFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class ServiceTypeFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (serviceProcessors: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      serviceProcessors,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled, serviceProcessors } = this.props;
    return (
      <DropdownFilter
        label={translate('core.serviceType')}
        values={serviceProcessors.map(serviceProcessor => ({
          id: serviceProcessor,
          label: serviceProcessor,
        }))}
        selectedIds={currentFilter.serviceProcessors || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
      />
    );
  }
}

interface ComponentProps {
  serviceProcessors: string[];
  currentFilter: ServiceTypeFilterType;
  onFilterChanged: (filter: ServiceTypeFilterType) => void;
  isDisabled?: boolean;
}

export default ServiceTypeFilter;
