import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AccountPanel, AccountPanelLogo, AccountPanelTitle, AccountPanelDescription, AccountPanelWrapper } from 'account/components/styled';
import { SsoUrlRedirectDataView } from 'contracts/types/service';
import { ApplicationState } from 'contracts/types/state';
import { Button } from 'core/components/styled';
import { actionIsRunning } from 'core/ducks/running';
import translate from 'core/helpers/translate';
import marketplaceRegisterDuck from 'marketplace/ducks/marketplaceRegister';

const MarketplaceUserPage: React.FC = () => {
  const dispatch = useDispatch();
  const [ssoRedirect, setSsoRedirect] = useState<SsoUrlRedirectDataView>();

  const isLoading = useSelector(
    (state: ApplicationState) => actionIsRunning(state, marketplaceRegisterDuck.actionKeys.INITIATE_SSO)
  );

  const handleButton = (): void => {
    if (ssoRedirect?.doSsoRedirect) {
      window.location.href = ssoRedirect.ssoRedirectUrl;
    }
  };

  useEffect(() => {
    dispatch(marketplaceRegisterDuck.thunks.initiateMarketplaceSso())
      .then(response => {
        setSsoRedirect(response);
      }).catch(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountPanelWrapper isCenter>
      <AccountPanel isLoading={isLoading}>
        <AccountPanelLogo />
        <AccountPanelTitle>{translate('redirectToMarketplace')}</AccountPanelTitle>
        <AccountPanelDescription>
          {translate('redirectToMarketplaceText')}
        </AccountPanelDescription>
        <Button onClick={handleButton} disabled={!ssoRedirect?.doSsoRedirect}>{translate('backToMarketplace')}</Button>
      </AccountPanel>
    </AccountPanelWrapper>
  );
};

export default MarketplaceUserPage;
