import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { UserRole } from 'contracts/enums';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute, GuestRoute, NewWindowRoute } from 'routing/Routes';
import { ServicesRoutes } from 'services/routing';

import { AccountRoutes } from '.';

const AccountDetailsPage = lazy(() => import('account/components/AccountDetails/AccountDetailsPage'));

const UsersAndTagsPage = lazy(() => import('account/components/UsersAndTags/UsersAndTagsPage'));
const BulkTagsPage = lazy(() => import('account/components/UsersAndTags/Tags/BulkTags/BulkTagsPage'));
const AddTagPage = lazy(() => import('account/components/UsersAndTags/Tags/AddTagPage'));
const ViewTagPage = lazy(() => import('account/components/UsersAndTags/Tags/ViewTagPage'));
const EditTagPage = lazy(() => import('account/components/UsersAndTags/Tags/EditTagPage'));
const AddUserPage = lazy(() => import('account/components/UsersAndTags/Users/AddUserPage'));
const EditUserAccessPage = lazy(() => import('account/components/UsersAndTags/Users/EditUserAccessPage'));
const ViewUserPage = lazy(() => import('account/components/UsersAndTags/Users/ViewUserPage'));

const CommunicationSettingsPage = lazy(() => import('account/components/CommunicationSettings/CommunicationSettingsPage'));
const NotificationsOverviewPage = lazy(() => import('account/components/notifications/NotificationsOverviewPage'));

const LoginPage = lazy(() => import('account/components/AccountActions/LoginPage'));
const LogOutPage = lazy(() => import('account/components/AccountActions/LogOutPage'));
const ForcePasswordResetPage = lazy(() => import('account/components/AccountActions/ForcePasswordResetPage'));
const ResetPasswordEmailSentConfirmationPage = lazy(() => import('account/components/AccountActions/ResetPasswordEmailSentConfirmationPage'));
const ForgotPasswordPage = lazy(() => import('account/components/AccountActions/ForgotPasswordPage'));
const LoginBackofficePage = lazy(() => import('account/components/AccountActions/LoginBackofficePage'));
const RegisterPage = lazy(() => import('account/components/AccountActions/RegisterPage'));
const ResendRegistrationPage = lazy(() => import('account/components/AccountActions/ResendRegistrationPage'));
const ResetPasswordPage = lazy(() => import('account/components/AccountActions/ResetPasswordPage'));
const SetPasswordPage = lazy(() => import('account/components/AccountActions/SetPasswordPage'));
const WholesaleLoginPage = lazy(() => import('account/components/AccountActions/WholesaleLoginPage'));
const RegisterEmailSentConfirmationPage = lazy(() => import('account/components/AccountActions/RegisterEmailSentConfirmationPage'));
const KeyManagementPage = lazy(() => import('account/components/KeyManagement/KeyManagementPage'));
const LoginSSOPage = lazy(() => import('account/components/AccountActions/LoginSSOPage'));

const AccountRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={AccountRoutes.settings} element={
      <AuthRoute>
        <AccountDetailsPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.users} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <UsersAndTagsPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.bulkTags} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <BulkTagsPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.addTag} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <AddTagPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.viewTag} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly]}>
        <ViewTagPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.editTag} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <EditTagPage />
      </AuthRoute>
    } />

    <Route path={AccountRoutes.addUser} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <AddUserPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.viewUser} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <ViewUserPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.editUser} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <EditUserAccessPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.editUserCommunication} element={
      <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <CommunicationSettingsPage />
      </AuthRoute>
    } />

    <Route path={AccountRoutes.notifications} element={
      <AuthRoute>
        <NotificationsOverviewPage />
      </AuthRoute>
    } />
    <Route path={AccountRoutes.communicationSettings} element={
      <AuthRoute>
        <CommunicationSettingsPage />
      </AuthRoute>
    } />

    <Route path={AccountRoutes.logout} element={
      <AuthRoute>
        <LogOutPage />
      </AuthRoute>
    } />

    <Route path={AccountRoutes.updatePassword} element={
      <AuthRoute>
        <ForcePasswordResetPage />
      </AuthRoute>
    } />

    <Route path={AccountRoutes.keyManagementPage} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ApiKeyManagementPage}
        unauthorizedUserRoles={[
          UserRole.Manager, UserRole.Readonly, UserRole.RegWatchOnly, UserRole.ServiceOnly, UserRole.User
        ]}
      >
        <KeyManagementPage />
      </AuthRoute>
    } />
    
    {/* Guest routes */}
    <Route path={AccountRoutes.login} element={<GuestRoute><LoginPage /></GuestRoute>} />
    <Route path={AccountRoutes.setPassword} element={<GuestRoute><SetPasswordPage /></GuestRoute>} />
    <Route path={AccountRoutes.register} element={<GuestRoute><RegisterPage /></GuestRoute>} />
    <Route path={AccountRoutes.forgotPassword} element={<GuestRoute><ForgotPasswordPage /></GuestRoute>} />
    <Route path={AccountRoutes.passwordResetSent}
      element={<GuestRoute><ResetPasswordEmailSentConfirmationPage /></GuestRoute>} />
    <Route path={AccountRoutes.registerEmailSent}
      element={<GuestRoute><RegisterEmailSentConfirmationPage /></GuestRoute>} />
    <Route path={AccountRoutes.resetPassword} element={<GuestRoute><ResetPasswordPage /></GuestRoute>} />
    <Route path={AccountRoutes.backoffice} element={<GuestRoute><LoginBackofficePage /></GuestRoute>} />
    <Route path={AccountRoutes.activation} element={<GuestRoute><ResendRegistrationPage /></GuestRoute>} />
    <Route path={AccountRoutes.wholesale} element={<GuestRoute><WholesaleLoginPage /></GuestRoute>} />
    <Route path={AccountRoutes.ssoLogin} element={<GuestRoute><LoginSSOPage /></GuestRoute>} />

    <Route path={AccountRoutes.checkNetworkStatus} element={
      <NewWindowRoute url='https://status.rubicon.com/' backToPath={ServicesRoutes.root + ServicesRoutes.locations} />
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={AccountRoutes.root + AccountRoutes.settings} replace />} />
    <Route index element={<Navigate to={AccountRoutes.root + AccountRoutes.settings} replace/>} />
  </Routes>
);

export default AccountRouter;
