import update from 'immutability-helper';

import BulkDataReport from 'contracts/models/service/BulkDataReport';
import { ActionDispatcher, BulkDataReportAction } from 'contracts/types/action';
import { BulkUploadTemplateType } from 'contracts/types/request';
import { ApplicationState, BulkDataReportState, ReduceFunctionMap } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as bulkDataService from '../services/bulkDataService';

// Actions Keys
const ROOT_KEY = 'bulk-upload';
enum ActionKey {
  LOAD_SERVICES_REPORTS = 'bulk-upload/LOAD_SERVICES_REPORTS',
  RESET = 'bulk-upload/RESET',
}

// Initial State
const getInitialState: () => BulkDataReportState = () => {
  return {
    reportServices: []
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SERVICES_REPORTS, 
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  BulkDataReportState,
  BulkDataReportAction
> = {
  [ActionKey.LOAD_SERVICES_REPORTS]: (state, action) => {
    if (!action.reportServices) {
      return state;
    }
    const reportServices = action.reportServices;
    return update(state, { $merge: { reportServices } });
  },
};

export const reducer = getReducerBuilder<BulkDataReportState, BulkDataReportAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_ON_DEMAND_SERVICES_REPORTS: (reportServices?: BulkDataReport[]): BulkDataReportAction => ({
    type: ActionKey.LOAD_SERVICES_REPORTS,
    reportServices: reportServices || [],
  }),
  RESET: (): BulkDataReportAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadServicesReports = (serviceType: BulkUploadTemplateType) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICES_REPORTS,
    () => bulkDataService.getReports(serviceType),
    result => dispatch(actionMap.LOAD_ON_DEMAND_SERVICES_REPORTS(result)),
    () => {
      dispatch(actionMap.LOAD_ON_DEMAND_SERVICES_REPORTS());
    },
    true,
  );
const bulkDataUploadDuck = {
  thunks: { loadServicesReports },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default bulkDataUploadDuck;
