import { AccountRoutes } from 'account/routing';
import { getSessionUser, getSessionClaims } from 'account/services/session';
import BulkUploadRoutes from 'bulkUpload/routing/bulkUploadRoutes';
import { SaleType, UserRole } from 'contracts/enums';
import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionType from 'contracts/enums/SubscriptionType';
import MarketplaceRoutes from 'marketplace/routing/marketplaceRoutes';
import { RegWatchRoutes } from 'regwatch/routing';
import { ServicesRoutes } from 'services/routing';
import { WholesaleRoutes } from 'wholesale/routing';

const getDefaultRoute = (
  userRole?: UserRole, 
  subscription?: SubscriptionType, 
  salePermissions?: SaleType[],
  brandings?: BrandingType[],
): string => {
  const hasMarketplaceBranding = brandings?.find(x => x === BrandingType.Marketplace);
  const isLoggedIn = !!getSessionUser() && !!getSessionClaims();

  switch (subscription) {
    case SubscriptionType.Silver: {
      return BulkUploadRoutes.root + BulkUploadRoutes.locations;
    }
    default: {
      if (isLoggedIn) {
        if (!subscription && hasMarketplaceBranding) {
          return MarketplaceRoutes.root + MarketplaceRoutes.marketplaceRedirection;
        }
        if (userRole === UserRole.RegWatchOnly) {
          return RegWatchRoutes.root;
        }
        if (subscription || userRole) {
          return ServicesRoutes.root;
        }
        if (salePermissions && salePermissions.includes(SaleType.Wholesale)) {
          return WholesaleRoutes.root;
        }
      }
      return AccountRoutes.root + AccountRoutes.login;
    }
  }
};

export default getDefaultRoute;
