/* eslint-disable max-len */
import React, { Suspense } from 'react';

import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import loginDuck from 'account/ducks/login';
import { AccountRoutes } from 'account/routing';
import AccountRouter from 'account/routing/AccountRouter';
import { getSessionAuthToken, getSessionClaims } from 'account/services/session';
import BillingRouter from 'billing/routing/BillingRouter';
import { BillingRoutes } from 'billing/routing/billingRoutes';
import BulkUploadRouter from 'bulkUpload/routing/BulkUploadRouter';
import BulkUploadRoutes from 'bulkUpload/routing/bulkUploadRoutes';
import CheckoutRoutes from 'checkout/routing/checkoutRoutes';
import { SaleType, UserRole } from 'contracts/enums';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import { ApplicationState } from 'contracts/types/state';
import { ModalRouter, ModalRoutes } from 'core/components/ModalManager';
import { PageLoading } from 'core/components/styled';
import FinancialRouter from 'financial/routing/FinancialRouter';
import FinancialRoutes from 'financial/routing/financialRoutes';
import lazy from 'lazyLoader';
import MarketplaceRouter from 'marketplace/routing/MarketplaceRouter';
import MarketplaceRoutes from 'marketplace/routing/marketplaceRoutes';
import RecognitionRouter from 'recognition/routing/RecognitionRouter';
import RecognitionRoutes from 'recognition/routing/recognitionRoutes';
import { RegWatchRoutes } from 'regwatch/routing';
import RegWatchRouter from 'regwatch/routing/RegWatchRouter';
import { ReportsRoutes } from 'reports/routing';
import ReportsRouter from 'reports/routing/ReportsRouter';
import { ServicesRoutes } from 'services/routing';
import ServicesRouter from 'services/routing/ServicesRouter';
import { SustainabilityRoutes } from 'sustainability/routing';
import SustainabilityRouter from 'sustainability/routing/SustainabilityRouter';
import { WholesaleRoutes } from 'wholesale/routing';
import WholesaleRouter from 'wholesale/routing/WholesaleRouter';

import getDefaultRoute from './helper/defaultRouting';
import { AuthRoute } from './Routes';
import LazyRoute from './Routes/LazyRoute';

const MainRouter: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  window.ga('send', 'pageview');

  if (window.location.pathname === `${AccountRoutes.root}${AccountRoutes.backoffice}`) {
    const authToken = getSessionAuthToken();
    if (authToken) {
      dispatch(loginDuck.actions.logout());
    }
  }

  const claims = getSessionClaims();

  const isLoggedIn = useSelector(
    (state: ApplicationState) => window.location.pathname !== `${AccountRoutes.root}${AccountRoutes.backoffice}`
      ? state.account.login.isLoggedIn
      : false
  );
    
  // Libryo login
  const { redirectUri, clientId } = queryString.parse(window.location.search);
  if (
    isLoggedIn &&
    typeof clientId === 'string' &&
    typeof redirectUri === 'string'
  ) {
    dispatch(loginDuck.thunks.libryoAuth(clientId, redirectUri));
  }
  // End of Libryo

  const ModalManager = lazy(() => import('core/components/ModalManager/ModalManager'));
  const NavigationBar = lazy(() => import('routing/NavigationBar/NavigationBar'));
  const Notifier = lazy(() => import('core/components/Notifier'));

  const UnauthorizedPage = lazy(() => import('account/components/AccountActions/UnauthorizedPage'));
  const LoginErrorPage = lazy(() => import('account/components/AccountActions/LoginErrorPage'));
  const LoginFedexSsoPage = lazy(() => import('account/components/AccountActions/LoginFedexSsoPage'));
  const LoginFedexSsoRedirectPage = lazy(() => import('account/components/AccountActions/LoginFedexSsoRedirectPage'));

  const ServiceCancelPaymentPage = lazy(() => import('services/components/ServiceCancel/ServiceCancelPaymentPage'));

  const DeliveryConfirmationPage = lazy(() => import('checkout/components/DeliveryConfirmationPage'));
  const ChangePickupDatePage = lazy(() => import('checkout/components/ChangePickupDatePage'));
  const OrderPlacedPage = lazy(() => import('checkout/components/OrderPlacedPage'));
  const ScheduledPickupPage = lazy(() => import('checkout/components/ScheduledPickupPage'));

  return (
    <>
      {isLoggedIn ? <Suspense fallback={<PageLoading />}><NavigationBar /></Suspense> : null}
      {isLoggedIn ? <Suspense fallback={<PageLoading />}><ModalManager /></Suspense> : null}
      {isLoggedIn ? <Suspense fallback={<PageLoading />}><Notifier /></Suspense> : null}
      <Routes>
        <Route path={AccountRoutes.unauthorized} element={<LazyRoute><UnauthorizedPage /></LazyRoute>} />
        <Route path={AccountRoutes.loginError} element={<LazyRoute><LoginErrorPage /></LazyRoute>} />
        
        <Route path={AccountRoutes.ssoFedex} element={<LazyRoute><LoginFedexSsoPage /></LazyRoute>} />
        <Route path={AccountRoutes.ssoFedexCustomer} element={<LazyRoute><LoginFedexSsoRedirectPage /></LazyRoute>} />

        <Route path={ServicesRoutes.serviceCancelPayment} element={<LazyRoute><ServiceCancelPaymentPage /></LazyRoute>} />
        <Route path={AccountRoutes.register} element={
          <Navigate to={AccountRoutes.root + AccountRoutes.register + location.search} replace/>} 
        />

        <Route path={CheckoutRoutes.deliveryConfirmation} element={<LazyRoute><DeliveryConfirmationPage /></LazyRoute>} />
        <Route path={CheckoutRoutes.changePickupDate} element={<LazyRoute><ChangePickupDatePage /></LazyRoute>} />
        <Route path={CheckoutRoutes.pickupDateChanged} element={<LazyRoute><OrderPlacedPage /></LazyRoute>} />
        <Route path={CheckoutRoutes.pickupScheduled} element={<LazyRoute><ScheduledPickupPage /></LazyRoute>} />

        {/* Routers */}
        <Route path={`${AccountRoutes.root}/*`} element={<AccountRouter/>} />
        <Route path={`${MarketplaceRoutes.root}/*`} element={<MarketplaceRouter />} />

        {/* Routers for logged in user */}
        <Route path={`${ModalRoutes.root}/*`} element={
          <AuthRoute>
            <ModalRouter />
          </AuthRoute>} 
        />
        <Route path={`${ServicesRoutes.root}/*`} element={ 
          <AuthRoute >
            <ServicesRouter />
          </AuthRoute> 
        } />
        <Route path={`${WholesaleRoutes.root}/*`} element={
          <AuthRoute
            componentKey={SubscriptionComponentKey.WholesaleFlow}
            salePermissionTypes={[SaleType.Wholesale]}>
            <WholesaleRouter />
          </AuthRoute>
        } />
        <Route path={`${FinancialRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <FinancialRouter />
          </AuthRoute>
        } />
        <Route path={`${BillingRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <BillingRouter />
          </AuthRoute>
        } />
        <Route path={`${SustainabilityRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <SustainabilityRouter />
          </AuthRoute>
        } />
        <Route path={`${ReportsRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <ReportsRouter />
          </AuthRoute>
        } />
        <Route path={`${BulkUploadRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <BulkUploadRouter />
          </AuthRoute>
        } />
        <Route path={`${RegWatchRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly]}>
            <RegWatchRouter />
          </AuthRoute>
        } />
        <Route path={`${RecognitionRoutes.root}/*`} element={
          <AuthRoute unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
            <RecognitionRouter />
          </AuthRoute>
        } />

        {/* Default Route */}
        <Route path="*" element={<Navigate to={getDefaultRoute(claims?.role, claims?.subscription, claims?.salePermissions, claims?.brandings)} replace />} />
        <Route index element={<Navigate to={getDefaultRoute(claims?.role, claims?.subscription, claims?.salePermissions, claims?.brandings)} replace/>} />
      </Routes>
    </>
    
  );
};

export default MainRouter;
