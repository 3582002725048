import update from 'immutability-helper';

import ServiceAgreementsModel from 'contracts/models/service/ServiceAgreementsItemData';
import { ActionDispatcher, ServiceAgreementsAction } from 'contracts/types/action';
import { ServiceAgreementsListRequest } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceAgreementsState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getServiceAgreementsList } from 'services/services/serviceAgreements';

// Actions
const ROOT_KEY = 'services/service-agreements';
enum ActionKey {
  LOAD_SERVICE_AGREEMENTS = 'services/LOAD_SERVICE_AGREEMENTS',
  RESET = 'services/RESET',
}

// Initial state
const getInitialState: () => ServiceAgreementsState = () => {
  return {
    servAgreementsList: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_SERVICE_AGREEMENTS] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceAgreementsState,
  ServiceAgreementsAction
> = {
  [ActionKey.LOAD_SERVICE_AGREEMENTS]: (state, action) => {
    const { servAgreementsList } = action;
    return update(state, {
      $merge: {
        servAgreementsList,
      },
    });
  },
};

export const reducer = getReducerBuilder<ServiceAgreementsState, ServiceAgreementsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SERVICE_AGREEMENTS: (servAgreementsList: ServiceAgreementsModel[]): ServiceAgreementsAction => ({
    type: ActionKey.LOAD_SERVICE_AGREEMENTS,
    servAgreementsList,
  }),
  RESET: (): ServiceAgreementsAction => ({ type: ActionKey.RESET }),
};

// API calls
const loadServiceAgreements = (params: ServiceAgreementsListRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_AGREEMENTS,
    async() => getServiceAgreementsList(params),
    result => {
      dispatch(actionMap.LOAD_SERVICE_AGREEMENTS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_AGREEMENTS([]));
    },
  );

const serviceAgreementsDuck = {
  thunks: { loadServiceAgreements },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default serviceAgreementsDuck;
