import { combineReducers } from 'redux';

import { reducer as haulerBillConsolidationReducer } from './haulerBillConsolidation';
import { reducer as invoiceDetailsReducer } from './invoiceDetailsRequests';
import { reducer as invoicesListReducer } from './invoicesListRequests';
import { reducer as savingsReducer } from './savings';
import { reducer as spendByMaterialReducer } from './spendByMaterial';

const reducer = combineReducers({
  invoiceDetails: invoiceDetailsReducer,
  invoices: invoicesListReducer,
  savings: savingsReducer,
  spendByMaterial: spendByMaterialReducer,
  haulerBillConsolidation: haulerBillConsolidationReducer
});

export default reducer;
