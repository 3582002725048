export interface IRequestServiceResult {
  readonly svcId: number;
  readonly svcOrd: string;
  readonly workOrder: number;
  readonly isServiceUpdate: boolean;
  readonly warningMessage: string;
  readonly needApproval: boolean;
}

class RequestServiceResult {
  svcId?: number;
  svcOrd?: string;
  workOrder?: number;
  isServiceUpdate?: boolean;
  warningMessage?: string;
  needApproval?: boolean;

  constructor(data?: Partial<IRequestServiceResult>) {
    this.svcId = data?.svcId;
    this.svcOrd = data?.svcOrd;
    this.workOrder = data?.workOrder;
    this.isServiceUpdate = data?.isServiceUpdate;
    this.warningMessage = data?.warningMessage;
    this.needApproval = data?.needApproval;
  }
}

export default RequestServiceResult;
