import { TableData } from 'contracts/types/component';

import FilterStore from './FilterStore';

export interface INotificationItem {
  readonly notificationId: number;
  readonly isAcknowledged: boolean;
  readonly date: string;
  readonly notificationTypeId: number;
  readonly notificationTypeCode: string;
  readonly notificationType: string;
  readonly notificationDescr: string;
  readonly extRefference1: string;
  readonly extRefference2: string;
  readonly extRefference3: string;
  readonly sites: FilterStore[];
}

class NotificationItem implements TableData {
  notificationId?: number;
  isAcknowledged?: boolean;
  date?: string;
  notificationTypeId?: number;
  notificationTypeCode?: string;
  notificationType?: string;
  notificationDescr?: string;
  extRefference1?: string;
  extRefference2?: string;
  extRefference3?: string;
  isHidden?: boolean;
  sites?: FilterStore[];
  
  constructor(data?: Partial<INotificationItem>) {
    this.notificationId = data?.notificationId;
    this.isAcknowledged = data?.isAcknowledged;
    this.date = data?.date;
    this.notificationTypeId = data?.notificationTypeId;
    this.notificationTypeCode = data?.notificationTypeCode;
    this.notificationType = data?.notificationType;
    this.notificationDescr = data?.notificationDescr;
    this.extRefference1 = data?.extRefference1;
    this.extRefference2 = data?.extRefference2;
    this.extRefference3 = data?.extRefference3;
    this.sites = data?.sites;
    
    if (this.notificationTypeCode === 'CCExp') {
      this.notificationType = 'Credit Card Expiration';
      this.notificationDescr = 'Credit Card Expired';
    } else if (this.notificationTypeCode === 'CCExpXMo') {
      this.notificationType = 'Credit Card Expiration';
      this.notificationDescr = 'Credit Card Expiring';
      if (this.extRefference3) {
        this.notificationDescr = `Credit Card Expiring In ${this.extRefference3} ${this.extRefference3 === '1' ? 'Month' : 'Months'}`;
      }
    }
  }
}

export default NotificationItem;

export interface INotificationItemDetails {
  readonly notificationId: number;
  readonly date: string;
  readonly isAcknowledged: boolean;
  readonly notificationTypeId: number;
  readonly notificationTypeCode: string;
  readonly notificationType: string;
  readonly notificationDescr: string;
  readonly isMessage: boolean;
  readonly isExternalUrl: boolean;
  readonly fullMessage: string;
  readonly absoluteUrl: string;
  readonly relativeUrl: string;
  readonly extRefference1: string;
  readonly extRefference2: string;
  readonly extRefference3: string;
  readonly computedRefference1: string;
}

export class NotificationItemDetails {
  notificationId?: number;
  date?: string;
  isAcknowledged?: boolean;
  notificationTypeId?: number;
  notificationTypeCode?: string;
  notificationType?: string;
  notificationDescr?: string;
  isMessage?: boolean;
  isExternalUrl?: boolean;
  fullMessage?: string;
  absoluteUrl?: string;
  relativeUrl?: string;
  extRefference1?: string;
  extRefference2?: string;
  extRefference3?: string;
  computedRefference1?: string;

  constructor(data?: Partial<INotificationItemDetails>) {
    this.notificationId = data?.notificationId;
    this.date = data?.date;
    this.isAcknowledged = data?.isAcknowledged;
    this.notificationTypeId = data?.notificationTypeId;
    this.notificationTypeCode = data?.notificationTypeCode;
    this.notificationType = data?.notificationType;
    this.notificationDescr = data?.notificationDescr;
    this.isMessage = data?.isMessage;
    this.isExternalUrl = data?.isExternalUrl;
    this.fullMessage = data?.fullMessage;
    this.absoluteUrl = data?.absoluteUrl;
    this.relativeUrl = data?.relativeUrl;
    this.extRefference1 = data?.extRefference1;
    this.extRefference2 = data?.extRefference2;
    this.extRefference3 = data?.extRefference3;
    this.computedRefference1 = data?.computedRefference1;

    if (this.notificationTypeCode === 'CCExp') {
      this.notificationType = 'Credit Card Expiration';
      this.notificationDescr = 'Credit Card Expired';
    } else if (this.notificationTypeCode === 'CCExpXMo') {
      this.notificationType = 'Credit Card Expiration';
      this.notificationDescr = 'Credit Card Expiring';
      if (this.extRefference3) {
        this.notificationDescr = `Credit Card Expiring In ${this.extRefference3} ${this.extRefference3 === '1' ? 'Month' : 'Months'}`;
      }
    }
  }
}
