import { LocationFilter } from 'contracts/types/component';

// eslint-disable-next-line import/prefer-default-export
export function getLocationFilterApiArg(
  locationFilter: LocationFilter,
): {
  Cities: string[];
  ZipCodes: string[];
  TagIds: number[];
  StateIds: string[];
  StoreIds: Array<{
    SiteCustomerId: string;
    SiteId: string;
  }>;
} | null {
  if (!locationFilter) {
    return null;
  }
  return {
    Cities: (locationFilter.cities && locationFilter.cities[0] && [locationFilter.cities[0].city]) || [],
    ZipCodes: locationFilter.zipCodes || [],
    TagIds: locationFilter.tags || [],
    StateIds: locationFilter.states || [],
    StoreIds:
      locationFilter.stores && locationFilter.stores.length
        ? locationFilter.stores.map(x => ({
          SiteCustomerId: x.siteCustomerId,
          SiteId: x.siteId,
        }))
        : [],
  };
}
