import React from 'react';

import { Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

const WholesaleFlow = lazy(() => import('wholesale/components/WholesaleFlow/WholesaleFlow'));

const WholesaleRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path="*" element={
      <AuthRoute componentKey={SubscriptionComponentKey.WholesaleFlow}>
        <WholesaleFlow />
      </AuthRoute>} />
    <Route index element={
      <AuthRoute componentKey={SubscriptionComponentKey.WholesaleFlow}>
        <WholesaleFlow />
      </AuthRoute>
    } />
  </Routes>
);

export default WholesaleRouter;
