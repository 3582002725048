import React, { PropsWithChildren, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';

import { Modal as ModalContainer, ModalDialog } from './styled';
import { ModalProps } from './styled/Modal';

const Modal: React.FC<PropsWithChildren<ComponentProps>> = ({
  addCustomScrollbar,
  onCustomSubmit,
  padding,
  size,
  children,
  ...props
}) => {
  const [containerElement] = useState<HTMLDivElement>(() => document.createElement('div'));

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>): void => {
    if (onCustomSubmit) {
      onCustomSubmit(event);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    document.body.appendChild(containerElement);
    return () => {
      document.body.removeChild(containerElement);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <ModalContainer size={size} {...props} onSubmit={handleSubmit}>
      <ModalDialog
        size={size}
        padding={padding}
        addCustomScrollbar={addCustomScrollbar}
      >
        {children}
      </ModalDialog>
    </ModalContainer>,
    containerElement
  );
};

interface OwnProps {
  addCustomScrollbar?: boolean;
  onCustomSubmit?: (event: React.FormEvent<HTMLDivElement>) => void;
}

type ComponentProps = OwnProps & ModalProps;

export default Modal;
