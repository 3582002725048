import { transparentize } from 'polished';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { fadeAndSlideInUp, loadingOverlay, Devices, theme } from 'core/styles';
import { mapper, sizeMapper } from 'utils/styles';

import Icon from '../Icon';

import { BareButtonStyle } from './Button';
import customerPortalScrollbar from './Scrollbar';

interface ModalTitleProps {
  textAlign?: string;
  bottomPadding?: boolean;
}
export const ModalTitle = styled.h2<ModalTitleProps>`
  margin: 0 0 8px;
  line-height: 29px;
  text-transform: capitalize;
  text-align: ${props =>
    mapper(
      { left: 'left', center: 'center', right: 'right' },
      props.textAlign,
      'center',
    )};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 24px;

  ${props =>
    props.bottomPadding &&
    css`
      padding-bottom: 20px;
    `}
`;

interface ModalSubtitleProps {
  textAlign?: string;
}
export const ModalSubtitle = styled.span<ModalSubtitleProps>`
  display: block;
  line-height: 16px;
  text-align: ${props =>
    mapper(
      { left: 'left', center: 'center', right: 'right' },
      props.textAlign,
      'center',
    )};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 13px;
  color: ${props => props.theme.colors.grayDark};
`;

export enum ModalSize {
  xSmall = 'xSmall',
  small = 'small',
  mSmall = 'mSmall',
  medium = 'medium',
  large = 'large',
  fullScreen = 'fullScreen',
}
export interface ModalProps extends LoadingStyledComponentProps {
  zIndex?: number;
  size?: ModalSize;
  padding?: string;
  addCustomScrollbar?: boolean;
  centered?: boolean;
}
export const ModalDialog = styled.div<ModalProps>`
  position: relative;
  width: ${props =>
    mapper(
      {
        [ModalSize.xSmall]: '400px',
        [ModalSize.small]: '600px',
        [ModalSize.mSmall]: '650px',
        [ModalSize.medium]: '800px',
        [ModalSize.large]: '1000px',
        [ModalSize.fullScreen]: '100%',
      },
      props.size,
      ModalSize.medium
    )};
  max-height: 80vh;
  padding: ${props => sizeMapper(props.padding)};
  background-color: ${theme.colors.brandWhite};
  border-radius: 2px;
  box-shadow: 0 4px 8px
    ${props => transparentize(0.9, props.theme.colors.grayBase)};
  animation: ${fadeAndSlideInUp} 0.3s ease-out;
  overflow: auto;

  ${props => props.addCustomScrollbar && customerPortalScrollbar()}

  @media screen and (max-height: 890px) {
    max-height: 90vh;
  }
  @media ${Devices.tablet} {
    max-width: 80%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media ${Devices.mobile} {
    width: 100%;
    height: 100vh;
    max-width: 100vh;
    max-height: 100vh;
  }

  ${props =>
    props.size === ModalSize.fullScreen &&
    css`
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      @media ${Devices.tablet} {
        max-width: 100vw;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    `}
`;

export const ModalCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 12px;
  height: 12px;
`;

export const ModalClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.colors.grayLight};
  border-radius: 4px;
  color: ${props => props.theme.colors.grayDark};
  transition: background-color 0.3s ease-out;
  &:active {
    background-color: ${props => props.theme.colors.grayBase};
  }
`;

export const ModalSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.colors.grayBase};
`;

export const ModalSearchInput = styled.input`
  flex: 1;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 22px;
  font-size: 18px;
  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.colors.grayDark};
  }
`;

export const ModalSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: solid ${props => props.theme.colors.grayLight};
  border-width: 1px 0;
`;

interface ModalSectionProps extends LoadingStyledComponentProps {
  margin: string;
  padding: string;
}
export const ModalSection = styled.div<ModalSectionProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay(24)};
    `};
`;

interface ModalFooterProps {
  align: string;
  margin: string;
}
export const ModalFooter = styled.div<ModalFooterProps>`
  display: flex;
  justify-content: ${props =>
    mapper(
      { left: 'flex-start', center: 'center', right: 'flex-end' },
      props.align,
      'left',
    )};
  padding: 20px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-top: 1px solid ${props => props.theme.colors.grayLight};
`;

export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex || 6000};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: ${props =>
    transparentize(0.7, props.theme.colors.grayBase)};
  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay(24)};
      }
    `};

  ${customerPortalScrollbar()}
  @media screen and (max-height: 890px) {
    padding-top: 50px;
  }
  @media screen and (max-height: 770px) {
    padding-top: 10px;
  }
  @media ${Devices.mobile} {
    padding-top: 0px;
  }

  ${props =>
    props.size === ModalSize.fullScreen &&
    css`
      padding-top: 0px;
      @media screen and (max-height: 890px) {
        padding-top: 0px;
      }
      @media screen and (max-height: 770px) {
        padding-top: 0px;
      }
    `}

  ${props =>
    props.centered &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding-top: 0px;
      @media screen and (max-height: 890px) {
        padding-top: 0px;
      }
      @media screen and (max-height: 770px) {
        padding-top: 0px;
      }
    `}
`;

export const SavingModalContainer = styled.div`
  background-color: ${theme.colors.brandWhite};
  color: ${props => props.theme.colors.brandPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  min-width: 150px;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20vh;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 10000;
`;

export const SavingModalText = styled.span`
  margin-top: 10px;
  font-size: 14px;
  @media ${Devices.tablet} {
    font-size: 12px;
  }
`;
export const ConfirmationMessage = styled.span`
  display: block;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
  word-break: break-word;
`;

export const ConfirmationButtonSet = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
