import React, { ChangeEvent, PureComponent } from 'react';

import translate from 'core/helpers/translate';

import {
  DropdownFilterChecklistEmpty,
  DropDownFilterExtraButton,
  DropdownFilterExtraCommentRow,
  DropdownFilterExtraRow,
  DropdownFilterPopupBody,
} from '../styled/DropdownFilter';

import DropdownFilterChecklistRow from './DropdownFilterChecklistRow';

class DropdownFilterChecklist<T extends number | string> extends PureComponent<
  ComponentProps<T>
> {
  state = {};

  render(): React.ReactNode {
    const {
      data,
      onCheckboxChange,
      selectedIds,
      extraUncheckedCount,
      showMore,
      checkboxLabelLineCount,
    } = this.props;
    return (
      <DropdownFilterPopupBody>
        {!!data.length && (
          <>
            {data.map(entry => (
              <DropdownFilterChecklistRow
                key={entry.id}
                entry={entry}
                onCheckboxChange={onCheckboxChange}
                checked={selectedIds && selectedIds.indexOf(entry.id) >= 0}
                checkboxLabelLineCount={checkboxLabelLineCount}
              />
            ))}
            {extraUncheckedCount > 0 && (
              <DropdownFilterExtraRow>
                {extraUncheckedCount} (of {extraUncheckedCount + data.length})
                {translate('core.moreRows')}...
                <DropdownFilterExtraCommentRow>
                  {translate('core.pleaseUseTheSearchFunctionality')}
                  {translate('or')}{' '}
                  {
                    <DropDownFilterExtraButton onClick={showMore}>
                      {translate('showMore')}
                    </DropDownFilterExtraButton>
                  }
                </DropdownFilterExtraCommentRow>
              </DropdownFilterExtraRow>
            )}
          </>
        )}
        {!data.length && (
          <DropdownFilterChecklistEmpty>
            {translate('core.noOptions')}
          </DropdownFilterChecklistEmpty>
        )}
      </DropdownFilterPopupBody>
    );
  }
}

interface ComponentProps<T extends number | string> {
  data: Array<{
    id: T;
    label: string;
  }>;
  selectedIds: T[];
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>, id: T) => void;
  extraUncheckedCount: number;
  showMore: () => void;
  checkboxLabelLineCount?: number;
}

export default DropdownFilterChecklist;
