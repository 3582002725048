import NewServiceActions, { GetServiceMarketAvailabilitySmbNewServiceRequestModel, GetServiceMarketAvailabilitySmbNewServiceResponseModel, InitateSmbNewServiceRequestModel, InitateSmbNewServiceRresponseModel } from 'contracts/models/service/NewServiceActions';
import NewServiceOptions from 'contracts/models/service/NewServiceOptions';
import RequestNewServiceResult, { IRequestNewServiceResult } from 'contracts/models/service/RequestNewServiceResult';
import {
  NewServiceActionsRequest,
  NewServiceOptionsRequest,
  NewServiceSubmitRequest,
} from 'contracts/types/request';
import {
  UploadNewServiceFileRequest,
  UploadNewServiceFileResponse,
} from 'contracts/types/service';
import { writeBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getNewServiceOptions = async(
  arg: NewServiceOptionsRequest,
): Promise<NewServiceOptions> => {
  const response = await httpCustomerCore.post<NewServiceOptions>(
    'new-service/options',
    arg,
  );
  return response.data;
};

export const getNewServiceActions = async(
  arg: NewServiceActionsRequest,
): Promise<NewServiceActions[]> => {
  const response = await httpCustomerCore.post<NewServiceActions[]>(
    'new-service/service-actions',
    arg,
  );
  return response.data;
};

export async function uploadNewServiceImageFile(
  uploadFileRequest: UploadNewServiceFileRequest,
): Promise<UploadNewServiceFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    'new-service/request/upload-image-file',
    uploadFileRequest,
  );
  return response;
}

export const submitNewService = async(
  arg: NewServiceSubmitRequest,
): Promise<RequestNewServiceResult[]> => {
  const response = await httpCustomerCore.post<RequestNewServiceResult[]>(
    'new-service/request/submit',
    arg,
    {
      transformResponse: transformServiceResponse<
        IRequestNewServiceResult,
        RequestNewServiceResult
      >(RequestNewServiceResult),
    },
  );

  return response.data;
};

export const getSmbServiceMarketAvailability = async(
  arg: GetServiceMarketAvailabilitySmbNewServiceRequestModel,
): Promise<GetServiceMarketAvailabilitySmbNewServiceResponseModel> => {
  const response = await httpCustomerCore.post<GetServiceMarketAvailabilitySmbNewServiceResponseModel>(
    'new-service/smb-request/service-availability',
    arg,
  );
  return response.data;
};

export const initiateNewSmbService = async(
  arg: InitateSmbNewServiceRequestModel,
): Promise<InitateSmbNewServiceRresponseModel> => {
  const response = await httpCustomerCore.post<InitateSmbNewServiceRresponseModel>(
    'new-service/redirect/initiate',
    arg,
  );
  return response.data;
};
