import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { TooltipWrapper } from './styled/Tooltip';

const Tooltip: React.FC<PropsWithChildren<ComponentProps>> = ({
  className,
  content,
  borderRadius,
  centered,
  children,
  customOffsetLeft,
  customOffsetTop,
  darkTheme,
  leftArrow,
  maxWidth,
  topArrow,
  noTooltipArrow
}) => {
  const rootRef = useRef<HTMLSpanElement>(null);
  const element = document.getElementsByClassName(className)[0];
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipOffsetTop, setTooltipOffsetTop] = useState<number>(34);
  const [tooltipOffsetLeft, setTooltipOffsetLeft] = useState<number>(100);

  const handleClick = (e: Event): void => {
    if (
      rootRef &&
      rootRef.current &&
      !rootRef.current.contains(e.target as HTMLInputElement)
    ) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => document.removeEventListener('mousedown', handleClick, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (element && element.clientHeight > 0) {
      setTooltipOffsetTop(customOffsetTop || element.clientHeight + 8);
      setTooltipOffsetLeft(customOffsetLeft || Math.round(element.clientWidth / 2) - 14);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  const onMouseClick = (): void => {
    setShowTooltip(!showTooltip);
  };
  
  return (
    <span style={{ display: 'inline-block', marginBottom: leftArrow ? '5px' : '0px' }} ref={rootRef}>
      <TooltipWrapper
        className={className}
        maxWidth={maxWidth}
        showTooltip={showTooltip}
        centered={centered}
        leftArrow={leftArrow}
        topArrow={topArrow}
        darkTheme={darkTheme}
        tooltipOffsetTop={tooltipOffsetTop}
        tooltipOffsetLeft={tooltipOffsetLeft}
        borderRadius={borderRadius}
        noTooltipArrow={noTooltipArrow}
      >
        {content}
      </TooltipWrapper>
      <span onClick={onMouseClick}>{children}</span>
    </span>
  );

};

interface ComponentProps {
  content: React.ReactElement;
  maxWidth?: number;
  className: string;
  customOffsetTop?: number;
  customOffsetLeft?: number;
  centered?: boolean;
  darkTheme?: boolean;
  leftArrow?: boolean;
  topArrow?: boolean;
  borderRadius?: number;
  noTooltipArrow?: boolean;
}

export default Tooltip;
