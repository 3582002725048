import ServiceAgreementsModel from 'contracts/models/service/ServiceAgreementsItemData';
import { ServiceAgreementsListRequest } from 'contracts/types/request';
import { httpCustomerCore } from 'core/services/http';

export const getServiceAgreementsList = async(
  params: ServiceAgreementsListRequest
): Promise<ServiceAgreementsModel[]> => {
  const response = await httpCustomerCore.post(
    'service-agreements/documents',
    params,
  );
  return response.data;
};

export default getServiceAgreementsList;
