import { AxiosError } from 'axios';
import update from 'immutability-helper';
import isNumber from 'lodash-es/isNumber';

import { NotificationType } from 'contracts/enums';
import { NotifierAction } from 'contracts/types/action';
import { ServiceError } from 'contracts/types/service';
import { NotifierState, ReduceFunctionMap } from 'contracts/types/state';
import { formatServiceError } from 'core/helpers/formatServiceError';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';

// Actions Keys
const ROOT_KEY = 'core';
enum ActionKey {
  NOTIFICATION_CREATE = 'core/NOTIFICATION_CREATE',
  NOTIFICATION_DELETE = 'core/NOTIFICATION_DELETE',
}

// Initial State
const getInitialState: () => NotifierState = () => {
  return {
    notifications: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.NOTIFICATION_CREATE,
  ActionKey.NOTIFICATION_DELETE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NotifierState,
  NotifierAction
> = {
  [ActionKey.NOTIFICATION_CREATE]: (state, action) => {
    const {
      notificationType,
      notificationMessage,
      notificationDuration,
      notificationRedirectUrl,
    } = action;
    if (!notificationType || !notificationMessage) {
      return state;
    }
    return update(state, {
      notifications: {
        $push: [
          {
            id: new Date().valueOf(),
            type: notificationType,
            message: notificationMessage,
            duration: notificationDuration,
            redirectUrl: notificationRedirectUrl,
          },
        ],
      },
    });
  },
  [ActionKey.NOTIFICATION_DELETE]: (state, action) => {
    const { notificationId } = action;
    if (!isNumber(notificationId)) {
      return state;
    }
    const index = state.notifications.findIndex(
      notification => notification.id === notificationId,
    );
    if (index < 0) {
      return state;
    }
    return update(state, {
      notifications: { $splice: [[index, 1]] },
    });
  },
};

export const reducer = getReducerBuilder<NotifierState, NotifierAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
export const createNotificationMessage = (
  notificationType: NotificationType,
  notificationMessage: string,
): NotifierAction => ({
  type: ActionKey.NOTIFICATION_CREATE,
  notificationType,
  notificationMessage,
});

export const createTimedNotificationMessage = (
  notificationType: NotificationType,
  notificationMessage: string,
  notificationDuration = 5000,
  notificationRedirectUrl?: string,
): NotifierAction => ({
  type: ActionKey.NOTIFICATION_CREATE,
  notificationType,
  notificationMessage,
  notificationDuration,
  notificationRedirectUrl,
});

export const createServiceErrorNotificationMessage = (
  error: AxiosError<ServiceError>,
): NotifierAction => {
  const message = formatServiceError(error);
  return createNotificationMessage(NotificationType.Error, message);
};

export const deleteNotificationMessage = (
  notificationId?: number,
): NotifierAction => ({
  type: ActionKey.NOTIFICATION_DELETE,
  notificationId,
});
