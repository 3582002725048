import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { PermissionType, UserRole } from 'contracts/enums';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import { ServicesRoutes } from '.';

const NewServiceDetailsPageWrapper = lazy(() => import('services/components/NewService/ServiceDetails/NewServiceDetailsPageWrapper'));
const NewServiceStartDatePage = lazy(() => import('services/components/NewService/StartDate/NewServiceStartDatePage'));
const NewServiceSummaryPage = lazy(() => import('services/components/NewService/Summary/NewServiceSummaryPage'));
const NewServiceSubmissionSummaryPage = lazy(() => import('services/components/NewService/Summary/NewServiceSubmissionSummaryPage'));
const ServiceLocationsPage = lazy(() => import('services/components/pages/ServiceLocationsPage'));
const ServiceSiteProfilePageWrapper = lazy(() => import('services/components/pages/ServiceSiteProfilePageWrapper'));
const ServiceRequestsPage = lazy(() => import('services/components/pages/ServiceRequestsPage'));
const EditSitePage = lazy(() => import('services/components/pages/EditSitePage'));
const ServiceApprovalPage = lazy(() => import('services/components/pages/ServiceApprovalPage'));
const DisposalAuditPage = lazy(() => import('services/components/DisposalAudit/DisposalAuditPage'));
const ServiceInsightsPage = lazy(() => import('services/components/pages/ServiceInsightsPage'));
const ServiceAgreementPage = lazy(() => import('services/components/ServiceAgreement/ServiceAgreementPage'));
const ServiceUpdatePage = lazy(() => import('services/components/ServiceUpdate/ServiceUpdatePage'));
const ServiceAgreementsPage = lazy(() => import( 'services/components/ServiceAgreements/ServiceAgreementsPage'));
const NewSimplifiedServiceDetailsPageWrapper = lazy(() => import( 'services/components/NewService/SimplifiedWizzard/ServiceDetails/NewSimplifiedServiceDetailsPageWrapper'));
const NewSimplifiedServiceSummaryPage = lazy(() => import( 'services/components/NewService/SimplifiedWizzard/ServiceSummary/NewSimplifiedServiceSummaryPage'));

const ServicesRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={ServicesRoutes.newServiceDetails} element={
      <AuthRoute 
        componentKey={SubscriptionComponentKey.NewServiceDetailsPageWrapper}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewServiceDetailsPageWrapper />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.newServiceStartDate} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.NewServiceStartDatePage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewServiceStartDatePage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.newServiceSummary} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.NewServiceSummaryPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewServiceSummaryPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.newServiceSubmit} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.NewServiceSubmissionSummaryPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewServiceSubmissionSummaryPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.newSimplifiedServiceDetails} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewSimplifiedServiceDetailsPageWrapper />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.newSimplifiedServiceSummary} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.NewSimplifiedServiceSummaryPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <NewSimplifiedServiceSummaryPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.locations} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceLocationsPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceLocationsPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceSiteProfile} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceSiteProfilePageWrapper}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceSiteProfilePageWrapper />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.editSiteProfile} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.EditSitePage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <EditSitePage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceRequests} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceRequestsPage}
        unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <ServiceRequestsPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceRequestsClosed} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceRequestsPage}
        unauthorizedUserRoles={[UserRole.ServiceOnly, UserRole.RegWatchOnly]}>
        <ServiceRequestsPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.updateService} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceUpdatePage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceUpdatePage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.updateServiceAgreement} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceAgreementPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceAgreementPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceRequestApproval} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceApprovalPage}
        permissionTypes={[PermissionType.Approval]}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceApprovalPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceUpdateApproval} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceAgreementPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceAgreementPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.disposalAudit} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.DisposalAuditPage}
        permissionTypes={[PermissionType.DisposalAudit]}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <DisposalAuditPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceInsights} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceInsightsPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}>
        <ServiceInsightsPage />
      </AuthRoute>
    } />
    <Route path={ServicesRoutes.serviceAgreements} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceAgreementsPage}
        unauthorizedUserRoles={[UserRole.RegWatchOnly]}
      >
        <ServiceAgreementsPage />
      </AuthRoute>
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={ServicesRoutes.root + ServicesRoutes.locations} replace />} />
    <Route index element={<Navigate to={ServicesRoutes.root + ServicesRoutes.locations} replace/>} />
  </Routes>
);

export default ServicesRouter;
