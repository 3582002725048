export interface IMaterialManagementSiteComparison {
  readonly startDate: string;
  readonly endDate: string;
  readonly siteRanges: MaterialManagementDateRange[];
}

class MaterialManagementSiteComparison {
  startDate?: string;
  endDate?: string;
  siteRanges?: MaterialManagementDateRange[];

  constructor(data?: Partial<IMaterialManagementSiteComparison>) {
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.siteRanges = data?.siteRanges;
  }
}

export interface MaterialManagementDateRange {
  custId: string;
  siteId: string;
  methods: SiteComparisonMaterialManagement[];
}

export interface SiteComparisonMaterialManagement {
  disposalMethodType: string;
  disposalMethodTypeDescription: string;
  tons: number;
  pounds: number;
}

export default MaterialManagementSiteComparison;
