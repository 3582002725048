import React, { PureComponent } from 'react';

import { ServiceRequestsCategoryEntry } from 'contracts/models/service/ServiceRequestsSummary';
import { CieTradeRequestFilter as CieTradeRequestFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class CieTradeRequestFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (requestTypes: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      requestTypes,
    });
  };

  compareCallback(
    a: ServiceRequestsCategoryEntry,
    b: ServiceRequestsCategoryEntry
  ): number {
    // Compare the elements and return appropriate values
    if (a === b) {
      return 0;
    } else if (a > b) {
      return 1;
    } else {
      return -1;
    }
  }

  render(): React.ReactNode {
    const { currentFilter, isDisabled, requestTypes } = this.props;
    return (
      <DropdownFilter
        label={translate('core.requestTypes')}
        values={requestTypes
          .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          .map(requestType => ({
            id: requestType.identifier,
            label: requestType.name,
          }))}
        selectedIds={currentFilter.requestTypes || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled || requestTypes.length === 0}
      />
    );
  }
}

interface ComponentProps {
  requestTypes: ServiceRequestsCategoryEntry[];
  currentFilter: CieTradeRequestFilterType;
  onFilterChanged: (filter: CieTradeRequestFilterType) => void;
  isDisabled?: boolean;
}

export default CieTradeRequestFilter;
