import update from 'immutability-helper';

import { NationalInvoice, HaulerInvoice } from 'contracts/models';
import {
  ActionDispatcher,
  FinancialInvoicesListAction,
} from 'contracts/types/action';
import {
  InvoiceListRequest,
  HaulerInvoiceListRequest,
  VendorInvoiceListRequest,
} from 'contracts/types/request';
import {
  ApplicationState,
  FinancialInvoicesListState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getInvoicesList,
  getHaulerInvoicesList,
  getVendorInvoicesList,
} from 'financial/services/invoicesListServices';

// Actions Keys
const ROOT_KEY = 'financial/invoiceDetails';
enum ActionKey {
  LOAD_INVOICES_LIST = 'financial/invoiceDetails/LOAD_INVOICES_LIST',
  LOAD_HAULER_INVOICES_LIST = 'financial/invoiceDetails/LOAD_HAULER_INVOICES_LIST',
  LOAD_VENDOR_INVOICES_LIST = 'financial/invoiceDetails/LOAD_VENDOR_INVOICES_LIST',
  RESET = 'financial/invoiceDetails/RESET',
}

// Initial State
const getInitialState: () => FinancialInvoicesListState = () => {
  return {
    invoicesList: [],
    haulerInvoicesList: [],
    vendorInvoicesList: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_INVOICES_LIST,
  ActionKey.LOAD_HAULER_INVOICES_LIST,
  ActionKey.LOAD_VENDOR_INVOICES_LIST,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FinancialInvoicesListState,
  FinancialInvoicesListAction
> = {
  [ActionKey.LOAD_INVOICES_LIST]: (state, action) => {
    const { invoicesList } = action;
    return update(state, {
      $merge: { invoicesList },
    });
  },
  [ActionKey.LOAD_HAULER_INVOICES_LIST]: (state, action) => {
    const { haulerInvoicesList } = action;
    return update(state, {
      $merge: { haulerInvoicesList },
    });
  },
  [ActionKey.LOAD_VENDOR_INVOICES_LIST]: (state, action) => {
    const { vendorInvoicesList } = action;
    return update(state, {
      $merge: { vendorInvoicesList },
    });
  },
};

export const reducer = getReducerBuilder<
  FinancialInvoicesListState,
  FinancialInvoicesListAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_INVOICES_LIST: (
    invoicesList?: NationalInvoice[],
  ): FinancialInvoicesListAction => ({
    type: ActionKey.LOAD_INVOICES_LIST,
    invoicesList,
  }),
  LOAD_HAULER_INVOICES_LIST: (
    haulerInvoicesList?: HaulerInvoice[],
  ): FinancialInvoicesListAction => ({
    type: ActionKey.LOAD_HAULER_INVOICES_LIST,
    haulerInvoicesList,
  }),
  LOAD_VENDOR_INVOICES_LIST: (
    vendorInvoicesList?: HaulerInvoice[],
  ): FinancialInvoicesListAction => ({
    type: ActionKey.LOAD_VENDOR_INVOICES_LIST,
    vendorInvoicesList,
  }),
  RESET: (): FinancialInvoicesListAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadInvoicesList = (invoicesListParams: InvoiceListRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_INVOICES_LIST,
    () => getInvoicesList(invoicesListParams),
    result => {
      dispatch(actionMap.LOAD_INVOICES_LIST(result));
    },
    () => {
      dispatch(actionMap.LOAD_INVOICES_LIST());
    },
  );

const loadHaulerInvoicesList = (
  invoicesListParams: HaulerInvoiceListRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_HAULER_INVOICES_LIST,
    () => getHaulerInvoicesList(invoicesListParams),
    result => {
      dispatch(actionMap.LOAD_HAULER_INVOICES_LIST(result));
    },
    () => {
      dispatch(actionMap.LOAD_HAULER_INVOICES_LIST());
    },
  );

const loadVendorInvoicesList = (
  invoicesListParams: VendorInvoiceListRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_VENDOR_INVOICES_LIST,
    () => getVendorInvoicesList(invoicesListParams),
    result => {
      dispatch(actionMap.LOAD_VENDOR_INVOICES_LIST(result));
    },
    () => {
      dispatch(actionMap.LOAD_VENDOR_INVOICES_LIST());
    },
    true
  );

const invoicesListDuck = {
  thunks: { loadInvoicesList, loadHaulerInvoicesList, loadVendorInvoicesList },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};

export default invoicesListDuck;
