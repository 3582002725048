import update from 'immutability-helper';

import { SortFields } from 'contracts/types/request';

import { TableColumnSortState, TableColumnSortValue } from './constants';

export function buildDefaultState(
  defaultField = '',
  isAscending = true,
): TableColumnSortState {
  const result: TableColumnSortState = {};
  if (defaultField) {
    result[defaultField] = isAscending
      ? TableColumnSortValue.Ascending
      : TableColumnSortValue.Descending;
  }
  return result;
}

export function updateSortState(
  sortState: TableColumnSortState,
  sortKey: string,
  allowMultiple = false,
  defaultState?: TableColumnSortState,
): TableColumnSortState {
  const currentValue = sortState[sortKey];
  let newValue;
  let result: TableColumnSortState = sortState;

  switch (currentValue) {
    case TableColumnSortValue.Ascending:
      newValue = TableColumnSortValue.Descending;
      break;
    case TableColumnSortValue.Descending:
      newValue = allowMultiple ? undefined : TableColumnSortValue.Ascending;
      break;
    default:
      newValue = TableColumnSortValue.Ascending;
  }

  if (!allowMultiple) {
    result = { [sortKey]: newValue };
  } else {
    result = update(result, { $merge: { [sortKey]: newValue } });
  }
  if (
    newValue === undefined &&
    defaultState &&
    Object.keys(result).length === 0
  ) {
    result = defaultState;
  }

  return result;
}

export function updateDropdownSortState(
  sortState: TableColumnSortState,
  sortKey: string,
  sortType: TableColumnSortValue,
  allowMultiple = false,
  defaultState?: TableColumnSortState,
): TableColumnSortState {
  const newValue = sortType ? sortType : undefined;
  let result: TableColumnSortState = sortState;

  if (!allowMultiple) {
    result = { [sortKey]: newValue };
  } else {
    result = update(result, { $merge: { [sortKey]: newValue } });
  }
  if (
    newValue === undefined &&
    defaultState &&
    Object.keys(result).length === 0
  ) {
    result = defaultState;
  }

  return result;
}

export function getCombinedSortArg(
  sortState: TableColumnSortState,
): SortFields {
  const result: SortFields = {
    ascending: [],
    descending: [],
  };
  if (sortState && Object.keys(sortState).length > 0) {
    Object.keys(sortState).forEach(key => {
      if (sortState[key] === TableColumnSortValue.Ascending) {
        result.ascending.push(key);
      } else if (sortState[key] === TableColumnSortValue.Descending) {
        result.descending.push(key);
      }
    });
  }
  return result;
}

export function buildSortArg(
  sortKeys: string[],
  e: { [key: string]: string },
): {
  [key: string]: boolean;
} {
  const result: {
    [key: string]: boolean;
  } = {};
  sortKeys.forEach(key => {
    if (key in e) {
      const apiValue = e[key];
      result[apiValue] = true;
    }
  });
  return result;
}
