import { EquipmentType } from 'contracts/enums';

export interface IServiceCancel {
  readonly custId: string;
  readonly siteId: string;
  readonly locationName: string;
  readonly serviceAddress: string;
  readonly orderStatus: string;
  readonly billTotal: number;
  readonly outstandingBalanceTotal: number;
  readonly services: ServiceCancelService[];
  readonly invoices: ServiceInvoice[];
  readonly paymentRequiered: boolean;
  readonly abortCancellation: boolean;
}

class ServiceCancel {
  custId?: string;
  siteId?: string;
  locationName?: string;
  serviceAddress?: string;
  orderStatus?: string;
  billTotal?: number;
  outstandingBalanceTotal?: number;
  services?: ServiceCancelService[];
  invoices?: ServiceInvoice[];
  paymentRequiered?: boolean;
  abortCancellation?: boolean;

  constructor(data?: Partial<IServiceCancel>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.locationName = data?.locationName;
    this.serviceAddress = data?.serviceAddress;
    this.orderStatus = data?.orderStatus;
    this.billTotal = data?.billTotal;
    this.outstandingBalanceTotal = data?.outstandingBalanceTotal;
    this.services = data?.services;
    this.invoices = data?.invoices;
    this.paymentRequiered = data?.paymentRequiered;
    this.abortCancellation = data?.abortCancellation;
  }
}

interface ServiceInvoice {
  invoiceId: number;
  amount?: number;
  zuoraInvioceId?: number;
}

export interface CancellationCosts {
  incumbentLiquidationDamage: number;
  removalFee: number;
  rubiconLiquidationDamage: number;
}

export interface ServiceCancelService {
  cancellationCosts: CancellationCosts;
  serviceId: number;
  containerId: string;
  containerSize: string;
  containerType: EquipmentType;
  frequency: string;
  frequencyId: string;
  isTemp: boolean;
  quantity: number;
  serviceCode: string;
  serviceDescription: string;
  wasteTypeId: string;
  wasteType: string;
  expireDate?: string;
  cancellationDate?: string;
}

export default ServiceCancel;
