import update from 'immutability-helper';

import { getCustomerSites } from 'account/services/siteService';
import { getBulkTagFiles, getCustomerTags } from 'account/services/tagService';
import {
  getUserRoles,
  getUsers
} from 'account/services/userService';
import UserRoleType from 'contracts/enums/UserRole';
import { CustomerSite, CustomerTag, CustomerUser, UserRole } from 'contracts/models';
import TagImportDocument from 'contracts/models/service/TagImportDocument';
import { ActionDispatcher, UsersSitesTagsAction } from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap, UsersSitesTagsState
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getClaimsUserRole } from './selectors';

const ROOT_KEY = 'account/usersSitesTags';

enum ActionKey {
  LOAD_ROLES = 'account/usersSitesTags/LOAD_ROLES',
  LOAD_USERS = 'account/usersSitesTags/LOAD_USERS',
  LOAD_CUSTOMER_SITES = 'account/usersSitesTags/LOAD_CUSTOMER_SITES',
  LOAD_CUSTOMER_TAGS = 'account/usersSitesTags/LOAD_CUSTOMER_TAGS',
  LOAD_BULK_TAG_FILES_DATA = 'account/usersSitesTags/LOAD_BULK_TAG_FILES_DATA',
  RESET = 'account/usersSitesTags/RESET',
}

// Initial State
const getInitialState: () => UsersSitesTagsState = () => {
  return {
    roles: [],
    userRoles: [],
    users: [],
    sites: [],
    tags: [],
    bulkTagFilesData: [],
  };
};

const reducerKeys = [
  ActionKey.LOAD_ROLES,
  ActionKey.LOAD_USERS,
  ActionKey.LOAD_CUSTOMER_SITES,
  ActionKey.LOAD_CUSTOMER_TAGS,
  ActionKey.LOAD_BULK_TAG_FILES_DATA,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  UsersSitesTagsState,
  UsersSitesTagsAction
> = {
  [ActionKey.LOAD_ROLES]: (state: UsersSitesTagsState, action: UsersSitesTagsAction) => {
    const newState = update(state, {
      $merge: {
        roles: action.roles || [],
        userRoles: action.userRoles || [],
      },
    });
    return newState;
  },
  [ActionKey.LOAD_USERS]: (state: UsersSitesTagsState, action: UsersSitesTagsAction) => {
    return update(state, {
      $merge: {
        users: action.users || [],
      },
    });
  },
  [ActionKey.LOAD_CUSTOMER_SITES]: (state: UsersSitesTagsState, action: UsersSitesTagsAction) => {
    return update(state, {
      $merge: {
        sites: action.sites || [],
      },
    });
  },
  [ActionKey.LOAD_CUSTOMER_TAGS]: (state: UsersSitesTagsState, action: UsersSitesTagsAction) => {
    return update(state, {
      $merge: {
        tags: action.tags || [],
      },
    });
  },
  [ActionKey.LOAD_BULK_TAG_FILES_DATA]: (state: UsersSitesTagsState, action: UsersSitesTagsAction) => {
    if (!action.bulkTagFilesData) {
      return state;
    }
    return update(state, {
      $merge: {
        bulkTagFilesData: action.bulkTagFilesData,
      },
    });
  },
};

export const reducer = getReducerBuilder<UsersSitesTagsState, UsersSitesTagsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  LOAD_ROLES: (roles?: UserRole[], userRoles?: UserRole[]): UsersSitesTagsAction => ({
    type: ActionKey.LOAD_ROLES,
    roles,
    userRoles
  }),
  LOAD_USERS: (users?: CustomerUser[]): UsersSitesTagsAction => ({
    type: ActionKey.LOAD_USERS,
    users,
  }),
  LOAD_CUSTOMER_SITES: (sites?: CustomerSite[]): UsersSitesTagsAction => ({
    type: ActionKey.LOAD_CUSTOMER_SITES,
    sites,
  }),
  LOAD_CUSTOMER_TAGS: (tags?: CustomerTag[]): UsersSitesTagsAction => ({
    type: ActionKey.LOAD_CUSTOMER_TAGS,
    tags,
  }),
  LOAD_BULK_TAG_FILES_DATA: (bulkTagFilesData?: TagImportDocument[]): UsersSitesTagsAction => ({
    type: ActionKey.LOAD_BULK_TAG_FILES_DATA,
    bulkTagFilesData,
  }),
  RESET: (): UsersSitesTagsAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadUserRoles = () => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ROLES,
    () => getUserRoles(),
    roles => {
      const userRole = getClaimsUserRole(getState());
      const filteredRoles =
        userRole === UserRoleType.Admin
          ? roles
          : roles.filter(role => role.role !== UserRoleType.Admin);
      dispatch(actionMap.LOAD_ROLES(roles, filteredRoles));
    },
    () => {
      dispatch(actionMap.LOAD_ROLES());
    },
    true,
  );

const loadUsers = () => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_USERS,
    async() => getUsers(),
    result => {
      dispatch(actionMap.LOAD_USERS(result));
    },
    () => dispatch(actionMap.LOAD_USERS()),
  );

const loadCustomerSites = () => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CUSTOMER_SITES,
    () => getCustomerSites(),
    result => {
      dispatch(actionMap.LOAD_CUSTOMER_SITES(result));
    },
    () => {
      dispatch(actionMap.LOAD_CUSTOMER_SITES());
    },
    true,
  );

const loadCustomerTags = () => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CUSTOMER_TAGS,
    () => getCustomerTags(),
    result => {
      dispatch(actionMap.LOAD_CUSTOMER_TAGS(result));
    },
    () => {
      dispatch(actionMap.LOAD_CUSTOMER_TAGS());
    },
  );

const loadBulkTagFilesData = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_BULK_TAG_FILES_DATA,
    async() => getBulkTagFiles(),
    result => {
      dispatch(actionMap.LOAD_BULK_TAG_FILES_DATA(result));
    },
    () => actionMap.LOAD_BULK_TAG_FILES_DATA(),
  );

const usersSitesTagsDuck = {
  thunks: {
    loadUserRoles,
    loadUsers,
    loadCustomerSites,
    loadCustomerTags,
    loadBulkTagFilesData,
  },
  actionKeys: ActionKey,
  actions: { 
    reset: actionMap.RESET, 
  },
};
export default usersSitesTagsDuck;
