import update from 'immutability-helper';

import { getCancellationPaymentFormData } from 'billing/services/zuoraServices';
import ServiceCancel from 'contracts/models/service/ServiceCancel';
import { ZuoraHostPageResponseDataView, ZuoraHostPageWithSignatureRequestDataView } from 'contracts/models/service/ZuoraPayment';
import { ActionDispatcher, ServiceCancelAction } from 'contracts/types/action';
import { ServiceCancelPaymentRequest } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceCancelState
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getServicesByAlias,
  sendServiceCancelPayNow
} from '../services/serviceCancel';

// Actions Keys
const ROOT_KEY = 'services/serviceCancel';
enum ActionKey {
  LOAD_SERVICE_DETAILS = 'services/serviceCancel/LOAD_SERVICE_DETAILS',
  REQUEST_CANCEL_SERVICE = 'services/serviceCancel/REQUEST_CANCEL_SERVICE',
  GET_ACH_FORM_DATA = 'services/serviceCancel/GET_ACH_FORM_DATA',
  GET_CC_FORM_DATA = 'services/serviceCancel/GET_CC_FORM_DATA',
  RESET = 'services/serviceCancel/RESET',
}

// Initial state
const getInitialState: () => ServiceCancelState = () => {
  return {
    serviceDetails: {},
    cancelSucceeded: false,
    paymentSuccess: undefined,
    zuoraACHFormData: undefined,
    zuoraCCFormData: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SERVICE_DETAILS,
  ActionKey.REQUEST_CANCEL_SERVICE,
  ActionKey.GET_ACH_FORM_DATA,
  ActionKey.GET_CC_FORM_DATA,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceCancelState,
  ServiceCancelAction
> = {
  [ActionKey.LOAD_SERVICE_DETAILS]: (state, action) => {
    const { serviceDetails } = action;
    return update(state, { $merge: { serviceDetails } });
  },
  [ActionKey.REQUEST_CANCEL_SERVICE]: (state, action) => {
    const { paymentSuccess } = action;
    const cancelSucceeded = !!paymentSuccess;
    return update(state, { $merge: { paymentSuccess, cancelSucceeded } });
  },
  [ActionKey.GET_ACH_FORM_DATA]: (state, action) => {
    const { zuoraACHFormData } = action;
    return update(state, { $merge: { zuoraACHFormData } });
  },
  [ActionKey.GET_CC_FORM_DATA]: (state, action) => {
    const { zuoraCCFormData } = action;
    return update(state, { $merge: { zuoraCCFormData } });
  },
};

export const reducer = getReducerBuilder<
  ServiceCancelState,
  ServiceCancelAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SERVICE_DETAILS: (
    serviceDetails?: ServiceCancel,
  ): ServiceCancelAction => ({
    type: ActionKey.LOAD_SERVICE_DETAILS,
    serviceDetails,
  }),
  REQUEST_CANCEL_SERVICE: (paymentSuccess?: boolean): ServiceCancelAction => ({
    type: ActionKey.REQUEST_CANCEL_SERVICE,
    paymentSuccess,
  }),
  GET_ACH_FORM_DATA: (
    zuoraACHFormData?: ZuoraHostPageResponseDataView | undefined,
  ): ServiceCancelAction => ({
    type: ActionKey.GET_ACH_FORM_DATA,
    zuoraACHFormData,
  }),
  GET_CC_FORM_DATA: (
    zuoraCCFormData?: ZuoraHostPageResponseDataView | undefined,
  ): ServiceCancelAction => ({
    type: ActionKey.GET_CC_FORM_DATA,
    zuoraCCFormData,
  }),
  RESET: (): ServiceCancelAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadServices = (alias: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_DETAILS,
    async() => getServicesByAlias(alias),
    result => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS());
    },
    true,
  );

const requestCancelService = (
  serviceCancelParams: ServiceCancelPaymentRequest,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REQUEST_CANCEL_SERVICE,
    async() => sendServiceCancelPayNow(serviceCancelParams),
    () => {
      dispatch(actionMap.REQUEST_CANCEL_SERVICE(true));
    },
    () => {
      dispatch(actionMap.REQUEST_CANCEL_SERVICE(false));
    },
    true,
  );

const loadPaymentFormData = (request: ZuoraHostPageWithSignatureRequestDataView) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    request.type === 'ach' ? ActionKey.GET_ACH_FORM_DATA : ActionKey.GET_CC_FORM_DATA,
    async() => getCancellationPaymentFormData(request),
    zuoraFormData => {
      if (request.type === 'ach') {
        dispatch(actionMap.GET_ACH_FORM_DATA(zuoraFormData));
      } else {
        dispatch(actionMap.GET_CC_FORM_DATA(zuoraFormData));
      }
    },
    () => {
      if (request.type === 'ach') {
        dispatch(actionMap.GET_ACH_FORM_DATA());
      } else {
        dispatch(actionMap.GET_CC_FORM_DATA());
      }
    },
    true,
  );

const serviceCancelDuck = {
  thunks: {
    loadServices,
    requestCancelService,
    loadPaymentFormData,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default serviceCancelDuck;
