import update from 'immutability-helper';

import { AccountRoutes } from 'account/routing';
import {
  getBillingPaymentFormData,
  getHMACSignature,
  getQuickpayPaymentFormData,
  payNow,
  postCreditCard,
  setAutoPayForAccount,
  updatePaymentMethod,
} from 'billing/services/zuoraServices';
import { NotificationType } from 'contracts/enums';
import { ZuoraHostPageResponseDataView, ZuoraHostPageWithSignatureRequestDataView, ZuoraPaymentMethod } from 'contracts/models/service/ZuoraPayment';
import {
  ActionDispatcher,
  AddEditPaymentMethodAction,
} from 'contracts/types/action';
import { CreditCardFormData } from 'contracts/types/form';
import { ZuoraPaynowRequestArg } from 'contracts/types/request';
import {
  AddEditPaymentMethodState,
  ApplicationState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import {
  createNotificationMessage,
  createTimedNotificationMessage,
} from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Actions Keys
const ROOT_KEY = 'billing/addEditPaymentMethod';
enum ActionKey {
  ADD_CREDITCARD = 'billing/addEditPaymentMethod/ADD_CREDITCARD',
  PAY_INVOICE = 'billing/addEditPaymentMethod/PAY_INVOICE',
  GET_FORM_DATA = 'billing/addEditPaymentMethod/GET_FORM_DATA',
  SET_AUTO_PAY = 'billing/addEditPaymentMethod/SET_AUTO_PAY',
  EDIT_PAYMENT_METHOD = 'billing/addEditPaymentMethod/EDIT_PAYMENT_METHOD',
  RESET = 'billing/addEditPaymentMethod/RESET',
}

// Initial State
const getInitialState: () => AddEditPaymentMethodState = () => {
  return {
    zuoraFormData: undefined,
    paymentId: undefined,
    isAutoPay: false,
    zuoraAccountId: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.ADD_CREDITCARD,
  ActionKey.PAY_INVOICE,
  ActionKey.GET_FORM_DATA,
  ActionKey.SET_AUTO_PAY,
  ActionKey.EDIT_PAYMENT_METHOD,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AddEditPaymentMethodState,
  AddEditPaymentMethodAction
> = {
  [ActionKey.ADD_CREDITCARD]: state => {
    return state;
  },
  [ActionKey.PAY_INVOICE]: (state, action) => {
    return update(state, {
      $merge: { paymentId: action.paymentId },
    });
  },
  [ActionKey.GET_FORM_DATA]: (state, action) => {
    return update(state, { $merge: { zuoraFormData: action.zuoraFormData } });
  },
  [ActionKey.SET_AUTO_PAY]: (state, action) => {
    return update(state, {
      $merge: {
        isAutoPay: action.isAutoPay,
        zuoraAccountId: action.zuoraAccountId,
      },
    });
  },
  [ActionKey.EDIT_PAYMENT_METHOD]: state => {
    return state;
  },
};

export const reducer = getReducerBuilder<
  AddEditPaymentMethodState,
  AddEditPaymentMethodAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  ADD_CREDITCARD: (): AddEditPaymentMethodAction => ({
    type: ActionKey.ADD_CREDITCARD,
  }),
  PAY_INVOICE: (paymentId?: string): AddEditPaymentMethodAction => ({
    type: ActionKey.PAY_INVOICE,
    paymentId,
  }),
  GET_FORM_DATA: (zuoraFormData?: ZuoraHostPageResponseDataView): AddEditPaymentMethodAction => ({
    type: ActionKey.GET_FORM_DATA,
    zuoraFormData,
  }),
  EDIT_PAYMENT_METHOD: (): AddEditPaymentMethodAction => ({
    type: ActionKey.EDIT_PAYMENT_METHOD,
  }),
  RESET: (): AddEditPaymentMethodAction => ({ type: ActionKey.RESET }),
};

// Thunks
const addCreditCard = (params: CreditCardFormData, accountKey: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ADD_CREDITCARD,
    async() => getHMACSignature(accountKey),
    () => {},
    () => {
      dispatch(actionMap.ADD_CREDITCARD());
    },
    true,
  ).then(hmacSignature =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADD_CREDITCARD,
      async() =>
        postCreditCard(
          hmacSignature.data.token,
          hmacSignature.data.signature,
          params,
          accountKey,
        ),
      () => {
        dispatch(actionMap.ADD_CREDITCARD());
      },
      () => {
        dispatch(actionMap.ADD_CREDITCARD());
      },
      true,
    ),
  );

const loadPaymentFormData = (request: ZuoraHostPageWithSignatureRequestDataView) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.GET_FORM_DATA,
    async() => request.token ? getQuickpayPaymentFormData(request) : getBillingPaymentFormData(request),
    formData => {
      dispatch(actionMap.GET_FORM_DATA(formData));
    },
    () => dispatch(actionMap.GET_FORM_DATA()),
    true,
  );

const payInvoice = (
  request: ZuoraPaynowRequestArg,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.PAY_INVOICE,
    async() => payNow(request),
    result => {
      dispatch(actionMap.PAY_INVOICE(result.paymentId));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Invoice paid successfully.',
        ),
      );
      setTimeout(() => {
        window.location.href = AccountRoutes.root + AccountRoutes.login;
      }, 800);
    },
    () => {
      dispatch(
        createNotificationMessage(
          NotificationType.Error,
          'Error has occurred. Unable to process the payment.',
        ),
      );
      dispatch(actionMap.PAY_INVOICE());
    },
    true,
  );

const editPaymentMethod = (
  paymentMethodId: string,
  paymentMethodDetails: ZuoraPaymentMethod,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  onSubmit: () => void,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.EDIT_PAYMENT_METHOD,
    async() =>
      updatePaymentMethod(
        paymentMethodId,
        paymentMethodDetails.paymentType,
        params,
      ),
    () => {},
    () => {
      dispatch(actionMap.EDIT_PAYMENT_METHOD());
    },
    true,
  ).then(() =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.EDIT_PAYMENT_METHOD,
      async() =>
        setAutoPayForAccount(params.isAutoPay, paymentMethodDetails.accountId),
      () => {
        dispatch(actionMap.EDIT_PAYMENT_METHOD());
        dispatch(
          createNotificationMessage(
            NotificationType.Success,
            'Payment Method updated successfully.',
          ),
        );
        setTimeout(() => {
          onSubmit();
        }, 500);
      },
      () => {
        dispatch(
          createNotificationMessage(
            NotificationType.Error,
            'Payment Method could not be updated. Try again.',
          ),
        );
        dispatch(actionMap.EDIT_PAYMENT_METHOD());
      },
      true,
    ),
  );

const addEditPaymentMethodDuck = {
  actionKeys: ActionKey,
  thunks: {
    addCreditCard,
    loadPaymentFormData,
    payInvoice,
    editPaymentMethod,
  },
  actions: { reset: actionMap.RESET },
};

export default addEditPaymentMethodDuck;
