import ServiceRequestsClosedReport, {
  IServiceRequestsClosedReport
} from 'contracts/models/service/ServiceRequestsClosedReport';
import ServiceRequestsComparison, {
  IServiceRequestsComparison
} from 'contracts/models/service/ServiceRequestsComparison';
import ServiceRequestsOpenReport, {
  IServiceRequestsOpenReport
} from 'contracts/models/service/ServiceRequestsOpenReport';
import ServiceRequestsSummary, {
  IServiceRequestsSummary, ServiceRequestsCategoryEntry
} from 'contracts/models/service/ServiceRequestsSummary';
import SlaRule, { ISlaRule } from 'contracts/models/service/SlaRule';
import {
  ServiceRequestsRequest
} from 'contracts/types/request';
import {
  GetBlobResponse,
  GetCsvResponse
} from 'contracts/types/service';
import { getBlobFile, getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getServiceRequestsSummary(
  arg: ServiceRequestsRequest,
  closed: boolean,
): Promise<ServiceRequestsSummary> {
  const response = await httpCustomerCore.post<ServiceRequestsSummary>(
    `service-requests/summary?closed=${closed}`,
    mapArg(arg, closed),
    {
      transformResponse: transformServiceResponse<
        IServiceRequestsSummary,
        ServiceRequestsSummary
      >(ServiceRequestsSummary),
    },
  );
  return response.data;
}

export async function getServiceRequestsComparison(
  arg: ServiceRequestsRequest,
  closed: boolean,
): Promise<ServiceRequestsComparison> {
  const response = await httpCustomerCore.post<ServiceRequestsComparison>(
    `service-requests/comparison?closed=${closed}`,
    mapArg(arg, closed),
    {
      transformResponse: transformServiceResponse<
        IServiceRequestsComparison,
        ServiceRequestsComparison
      >(ServiceRequestsComparison),
    },
  );
  return response.data;
}

export async function getServiceSLAInfo(): Promise<SlaRule[]> {
  const response = await httpCustomerCore.get<SlaRule[]>(
    'service-requests/sla-rules',
    {
      transformResponse: transformServiceResponse<ISlaRule, SlaRule>(SlaRule),
    },
  );
  return response.data;
}

export async function getServiceRequestsTableClosed(
  arg: ServiceRequestsRequest,
): Promise<ServiceRequestsClosedReport[]> {
  const response = await httpCustomerCore.post<ServiceRequestsClosedReport[]>(
    'service-requests/closed-table-report',
    mapArg(arg, true),
    {
      transformResponse: transformServiceResponse<
        IServiceRequestsClosedReport,
        ServiceRequestsClosedReport
      >(ServiceRequestsClosedReport),
    },
  );
  return response.data;
}

export async function getServiceRequestsTable(
  arg: ServiceRequestsRequest,
): Promise<ServiceRequestsOpenReport> {
  const response = await httpCustomerCore.post<ServiceRequestsOpenReport>(
    'service-requests/open-table-report',
    mapArg(arg, false),
    {
      transformResponse: transformServiceResponse<
        IServiceRequestsOpenReport,
        ServiceRequestsOpenReport
      >(ServiceRequestsOpenReport),
    },
  );
  return response.data;
}

export async function getRequestTypesFilterData(
  arg: ServiceRequestsRequest,
  closed: boolean,
): Promise<ServiceRequestsCategoryEntry[]> {
  const response = await httpCustomerCore.post<ServiceRequestsCategoryEntry[]>(
    `service-requests/requests-types-filter-data?closed=${closed}`,
    mapArg(arg, closed)
  );
  return response.data;
}

export async function getReferenceNumbersFilterData(
  arg: ServiceRequestsRequest,
): Promise<string[]> {
  const response = await httpCustomerCore.post<string[]>(
    'service-requests/reference-numbers-filter-data',
    mapArg(arg, false)
  );
  return response.data;
}

export async function cancelServiceRequest(orderNumber: number): Promise<unknown> {
  const response = await httpCustomerCore.post(
    `service-requests/cancel-service-request?orderNumber=${orderNumber}`,
  );
  return response.data;
}

export async function getServiceRequestsCsv(
  arg: ServiceRequestsRequest,
  closed: boolean,
): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    `service-requests/generate-csv?closed=${closed}`,
    mapArg(arg, closed),
  );
  return response;
}

export async function getCustomerFile(id: number): Promise<GetBlobResponse> {
  const urlPath = `document/${id}`;
  const reportDocument = getBlobFile(httpCustomerCore, urlPath);
  return reportDocument;
}

function mapArg(
  {
    fromDate,
    toDate,
    filter,
    serviceTypeId,
    siteIdentifier,
    pageNumber = 1,
    pageSize = 50,
  }: ServiceRequestsRequest,
  closed: boolean,
): unknown {
  const mappedFilters = {
    FromDate: fromDate,
    ToDate: toDate,
    Filters: {
      ...getLocationFilterApiArg(filter),
      OrderTypes:
        filter && filter.orderType && filter.orderType.length
          ? filter.orderType
          : [],
      Sla:
        closed && filter && filter.sla && filter.sla.length ? filter.sla : [],
      OrderStatus:
        !closed && filter && filter.orderStatus && filter.orderStatus.length
          ? filter.orderStatus
          : [],
      RequestTypes:
        filter && filter.requestTypes && filter.requestTypes.length
          ? filter.requestTypes
          : [],
      ReferenceNumbers:
        !closed &&
          filter &&
          filter.referenceNumbers &&
          filter.referenceNumbers.length
          ? filter.referenceNumbers
          : [],
      ServiceProcessors:
        filter && filter.serviceProcessors && filter.serviceProcessors.length
          ? filter.serviceProcessors
          : [],
    },
    CategoryId: serviceTypeId,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
    PageNumber: pageNumber,
    PageSize: pageSize === 50 ? pageSize : pageSize + 50,
  };
  return mappedFilters;
}

