enum ContainerSizingSortKey {
  SiteCode = 'SiteCode',
  SiteName = 'SiteName',
  SiteAddress = 'SiteAddress',
  Equipment = 'Equipment',
  EquipmentSize = 'EquipmentSize',
  Material = 'Material',
  CurrentFullness = 'CurrentFullness',
  CurrentPickupNo = 'CurrentPickupNo',
  GoalFullness = 'GoalFullness',
  NewPickupNo = 'NewPickupNo',
  PeriodServiceCostSavings = 'PeriodServiceCostSavings',
}

export default ContainerSizingSortKey;
