import styled, { css } from 'styled-components';

import { Devices } from 'core/styles';

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-direction: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 10px 5px;
`;

export const Section = styled.div`
  width: 100%;
  margin-top: 45px;
  max-width: 1200px;

  @media ${Devices.tablet} {
    margin-top: 25px;
  }

  @media ${Devices.mobile} {
    margin-top: 15px;
  }
`;

interface SectionTitleProps {
  centeredBold?: boolean;
  bold?: boolean;
}
export const SectionTitle = styled.div<SectionTitleProps>`
  display: inline-block;
  font-size: 28px;
  font-weight: 300;
  padding: 5px 0px;

  @media ${Devices.laptop} {
    font-size: 25px;
  }

  @media ${Devices.tablet} {
    font-size: 22px;
  }

  @media ${Devices.mobile} {
    font-size: 18px;
  }

  ${props =>
    props.centeredBold &&
    css`
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-direction: 1;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: 500;
    `}
`;

interface SectionSubtitleProps {
  blackTextColor?: boolean;
  smallFontSize?: boolean;
  boldText?: boolean;
  expandedTablePadding?: boolean;
  noMargin?: boolean;
}
export const SectionSubtitle = styled.div<SectionSubtitleProps>`
  margin-left: 10px;
  line-height: 30px;
  color: ${props => props.theme.colors.grayDark};

  font-size: 16px;

  ${props =>
    props.blackTextColor &&
    css`
      color: black;
    `}

  ${props =>
    props.smallFontSize &&
    css`
      font-size: 14px;
    `}

  ${props =>
    props.boldText &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.expandedTablePadding &&
    css`
      padding-top: 30px;
      padding-left: 30px;
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;

export const SectionActionItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 10px;
`;

export const SectionActionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 10px;
`;

export const SectionActionItemLabel = styled.span`
  margin: 0px 10px;
`;

export const SectionGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  @media ${Devices.tablet} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

interface SectionGraphProps {
  oneGraphPerRow?: boolean;
}

export const SectionGraph = styled.div<SectionGraphProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;

  ${props =>
    props.oneGraphPerRow &&
    css`
      width: 100%;
    `}

  @media ${Devices.tablet} {
    flex-wrap: wrap-reverse;
  }

  @media ${Devices.mobile} {
    padding: 0px;
    flex-direction: column-reverse;
  }
`;
