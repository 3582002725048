import ServiceDetails, {
  IServiceDetails,
} from 'contracts/models/service/ServiceDetails';
import ServiceMarketAvailability from 'contracts/models/service/ServiceMarketAvailability';
import ServiceQuoteItem from 'contracts/models/service/ServiceQuoteItem';
import ServiceSupportContact from 'contracts/models/service/ServiceSupportContact';
import {
  CreateServiceUpdateRequest,
  ServiceSupportContactRequest,
  ServiceUpdateMarketAvailabilityRequest,
  ServiceUpdateQuoteRequest,
} from 'contracts/types/request';
import { SaveOrderResponseAppModel } from 'contracts/types/service';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getServiceDetails = async(
  serviceId: number,
): Promise<ServiceDetails> => {
  const response = await httpCustomerCore.get<ServiceDetails>(
    `site-profile/service-details?serviceId=${serviceId}`,
    {
      transformResponse: transformServiceResponse<
        IServiceDetails,
        ServiceDetails
      >(ServiceDetails),
    },
  );
  return response.data;
};

export const getMarketAvailability = async({
  zipCode,
  address,
  serviceId,
  siteId,
  custId,
}: ServiceUpdateMarketAvailabilityRequest): Promise<
  ServiceMarketAvailability
> => {
  const response = await httpCustomerCore.post(`service/market-availability`, {
    ZipCode: zipCode,
    Address: address,
    serviceId,
    siteId,
    custId,
  });
  return response.data;
};

export const getServiceQuote = async({
  serviceId,
  businessCode,
  zipCode,
  address,
  serviceType,
  material,
  frequency,
  schedule,
  numberOfContainers,
}: ServiceUpdateQuoteRequest): Promise<{quotes: ServiceQuoteItem[]}> => {
  const response = await httpCustomerCore.post(`service/quote`, {
    serviceId,
    businessCode,
    zipCode,
    address,
    service: serviceType,
    material,
    frequency,
    schedule,
    quantity: numberOfContainers,
  });
  return response.data;
};

export const createServiceUpdate = async({
  serviceId,
  siteId,
  custId,
  service,
}: CreateServiceUpdateRequest): Promise<SaveOrderResponseAppModel> => {
  const response = await httpCustomerCore.post(`service/update-request`, {
    serviceId,
    siteId,
    custId,
    new: service,
  });
  return response.data;
};

export const getServiceRequestSupportContact = async({
  siteId,
  custId,
}: ServiceSupportContactRequest): Promise<ServiceSupportContact> => {
  const response = await httpCustomerCore.get(
    `service-requests/support-contact?siteId=${siteId}&custId=${custId}`,
  );
  return response.data;
};
