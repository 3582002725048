import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { getSessionClaims } from 'account/services/session';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import SubscriptionType from 'contracts/enums/SubscriptionType';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import FinancialRoutes from './financialRoutes';

const InvoiceDetailsPage = lazy(() => import('financial/components/InvoiceDetails/InvoiceDetailsPage'));
const InvoicesListPage = lazy(() => import('financial/components/InvoicesList/InvoicesListPage'));
const SavingsPage = lazy(() => import('financial/components/Savings/SavingsPage'));
const SpendByMaterialPage = lazy(() => import('financial/components/SpendByMaterial/SpendByMaterialPage'));
const InvoiceAuditPage = lazy(() => import('financial/components/InvoiceAudit/InvoiceAuditPage'));
const HaulerBillConsolidationPage = lazy(() => import('financial/components/haulerBillConsolidation/HaulerBillConsolidationPage'));

const getDefaultRoute = (): string => {
  const claims = getSessionClaims();
  const defaultRoute = claims?.subscription === SubscriptionType.Silver ?
    FinancialRoutes.spendByMaterial : FinancialRoutes.invoiceDetails;
  return FinancialRoutes.root + defaultRoute;
};

const FinancialRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={FinancialRoutes.invoiceDetails} element={
      <AuthRoute componentKey={SubscriptionComponentKey.InvoiceDetailsPage}>
        <InvoiceDetailsPage />
      </AuthRoute>
    } />
    <Route path={FinancialRoutes.invoiceAudit} element={
      <AuthRoute 
        componentKey={SubscriptionComponentKey.InvoiceAuditPage}
      >
        <InvoiceAuditPage />
      </AuthRoute>
    } />
    <Route path={FinancialRoutes.haulerBillConsolidation} element={
      <AuthRoute 
        componentKey={SubscriptionComponentKey.HaulerBillConsolidationPage}
      >
        <HaulerBillConsolidationPage />
      </AuthRoute>
    } />
    <Route path={FinancialRoutes.savings} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.SavingsPage}
      >
        <SavingsPage />
      </AuthRoute>
    } />
    <Route path={FinancialRoutes.invoicesList} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.InvoicesListPage}
      >
        <InvoicesListPage />
      </AuthRoute>
    } />
    <Route path={FinancialRoutes.spendByMaterial} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.SpendByMaterialPage}
      >
        <SpendByMaterialPage />
      </AuthRoute>
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={getDefaultRoute()} replace />} />
    <Route index element={<Navigate to={getDefaultRoute()} replace/>} />
  </Routes>
);

export default FinancialRouter;
