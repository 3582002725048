import { TableData } from 'contracts/types/component';

export interface IServiceRequestsClosedReport {
  readonly serviceDate: string;
  readonly serviceType: string;
  readonly storeName: string;
  readonly address: string;
  readonly status: string;
  readonly sla: string;
  readonly orderNumber: number;
  readonly openById: number;
  readonly serviceProcessor: string;
  readonly workOrder: number;
  readonly serviceOrder: string;
  readonly serviceDescription: string;
  readonly referenceNumber: string;
}

class ServiceRequestsClosedReport implements TableData {
  serviceDate?: string;
  serviceType?: string;
  storeName?: string;
  address?: string;
  status?: string;
  sla?: string;
  orderNumber?: number;
  openById?: number;
  serviceProcessor?: string;
  workOrder?: number;
  serviceOrder?: string;
  serviceDescription?: string;
  isHidden?: boolean;
  referenceNumber?: string;

  constructor(data?: Partial<IServiceRequestsClosedReport>) {
    this.serviceDate = data?.serviceDate;
    this.serviceType = data?.serviceType;
    this.storeName = data?.storeName;
    this.address = data?.address;
    this.status = data?.status;
    this.sla = data?.sla;
    this.orderNumber = data?.orderNumber;
    this.openById = data?.openById;
    this.serviceProcessor = data?.serviceProcessor;
    this.workOrder = data?.workOrder;
    this.serviceOrder = data?.serviceOrder;
    this.serviceDescription = data?.serviceDescription;
    this.referenceNumber = data?.referenceNumber;
  }
}

export default ServiceRequestsClosedReport;
