import React, { Suspense, lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import { PageLoading } from 'core/components/styled';
import MarketplaceRedirectPage from 'marketplace/components/MarketplaceRedirectPage';
import AuthRoute from 'routing/Routes/AuthRoute';
import GuestRoute from 'routing/Routes/GuestRoute';
import LazyRoute from 'routing/Routes/LazyRoute';

import MarketplaceRoutes from './marketplaceRoutes';

const MarketplaceRouter: React.FC = () => {
  const RegistrationPage = lazy(() => import('../components/RegistrationPage'));
  const ActivationPage = lazy(() => import('../components/ActivationPage'));
  const Notifier = lazy(() => import('core/components/Notifier'));

  return (
    <>
      <Suspense fallback={<PageLoading />}><Notifier /></Suspense>
      <Routes>
        <Route path={MarketplaceRoutes.marketplaceRegister} element={
          <LazyRoute>
            <GuestRoute><RegistrationPage /></GuestRoute>
          </LazyRoute>} />
        <Route path={MarketplaceRoutes.accountActivation} element={
          <LazyRoute>
            <GuestRoute><ActivationPage /></GuestRoute>
          </LazyRoute>} />
        <Route path={MarketplaceRoutes.marketplaceRedirection} element={
          <AuthRoute brandingTypes={[BrandingType.Marketplace]} >
            <MarketplaceRedirectPage />
          </AuthRoute>
        } />

        {/* Default Route */}
        <Route path="*" element={<Navigate to={MarketplaceRoutes.root + MarketplaceRoutes.marketplaceRedirection} replace />} />
        <Route index element={<Navigate to={MarketplaceRoutes.root + MarketplaceRoutes.marketplaceRedirection} replace/>} />
      </Routes>
    </>
  );
};

export default MarketplaceRouter;
