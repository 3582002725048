import update from 'immutability-helper';

import {
  ModalManagerAction
} from 'contracts/types/action';
import { ConfirmationModalProps, ModalActionProps, ModalComponentProps, ModalStateProps } from 'contracts/types/modals';
import {
  ModalManagerState,
  ReduceFunctionMap
} from 'contracts/types/state';
import { ModalType } from 'core/components/ModalManager';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';

// Actions Keys
const ROOT_KEY = 'core/modals';
enum ActionKey {
  OPEN_MODAL = 'core/modals/OPEN_MODAL',
  CLOSE_MODAL = 'core/modals/CLOSE_MODAL',
  RESET = 'core/modals/RESET',
}

// Initial State
const getInitialState = (): ModalManagerState => {
  return {
    modals: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.OPEN_MODAL,
  ActionKey.CLOSE_MODAL,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ModalManagerState,
  ModalManagerAction
> = {
  [ActionKey.OPEN_MODAL]: (state, action) => {
    if (!action.props) {
      return state;
    }
    const newModal: ModalStateProps = {
      ...action.props,
      id: new Date().valueOf(),
      isOpen: false
    };
    const newState = update(state, {
      modals: { $push: [newModal] },
    });
    return newState;
  },
  [ActionKey.CLOSE_MODAL]: (state, action) => {
    if (!action.id) {
      return state;
    }
    const index = state.modals.findIndex(m => m.id === action.id);
    if (index < 0) {
      return state;
    }
    const newState = update(state, {
      modals: { $splice: [[index, 1]] },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<
ModalManagerState,
ModalManagerAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const openModal = <T extends ModalComponentProps>(props: ModalActionProps<T>): ModalManagerAction => ({
  type: ActionKey.OPEN_MODAL,
  props,
});

const openConfirmationModal = (message: string,
  closeModal: (value?: boolean) => void,
  noBtnVisible?: boolean, yesBtnText?:
  string,
  noBtnText?: string): ModalManagerAction => ({
  type: ActionKey.OPEN_MODAL,
  props: {
    modalType: ModalType.confirmation,
    props: {
      message,
      closeModal,
      noBtnText,
      noBtnVisible,
      yesBtnText
    } as ConfirmationModalProps
  },
});

const closeModal = (id: number): ModalManagerAction => ({
  type: ActionKey.CLOSE_MODAL,
  id,
});

const modalManager = {
  openModal,
  openConfirmationModal,
  closeModal,
};
export default modalManager;
