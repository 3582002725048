import React, { useEffect, useState } from 'react';

import { FallbackProps } from 'react-error-boundary';

import translate from 'core/helpers/translate';

const ErrorBoundaryComponent = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
  const [errorOccured, setErrorOccured] = useState(false);

  useEffect(() => {
    if (error && !errorOccured) {
      setErrorOccured(true);
      if (error.name !== 'ChunkLoadError') {
        setTimeout(() => {
          resetErrorBoundary();
        }, 3000);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <div role="alert">
    <p>{translate('somethingWentWrong')}</p>
  </div>;
};

export default ErrorBoundaryComponent;
