enum Colors {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  info = 'info',
  warning = 'warning',
  alert = 'alert',
  grayBase = 'grayBase',
  grayDarker = 'grayDarker',
  grayDark = 'grayDark',
  gray = 'gray',
  grayLight = 'grayLight',
  grayLighter = 'grayLighter',
}

export default Colors;
