export interface IDisposalAuditSummary {
  siteTotalCount: number;
  entries: DisplayAuditSummaryDetails[];
}

class DisposalAuditSummary {
  siteTotalCount?: number;
  entries?: DisplayAuditSummaryDetails[];

  constructor(data?: Partial<IDisposalAuditSummary>) {
    this.siteTotalCount = data?.siteTotalCount;
    this.entries = data?.entries;
  }
}

export interface DisplayAuditSummaryDetails {
  siteCount: number;
  siteName: string;
}

export default DisposalAuditSummary;
