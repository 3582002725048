enum BulkServicesUpdateSortKey {
  ExtServiceId = 'ExtServiceId',
  SiteCode = 'SiteCode',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  ServiceCode = 'ServiceCode',
  ServiceDescription = 'ServiceDescription',
  Material = 'Material',
  Frequency = 'Frequency',
  Quantity = 'Quantity',
  WorkOrder = 'WorkOrder',
  Total = 'Total',
  DisposalMethod = 'DisposalMethod',
}

export default BulkServicesUpdateSortKey;
