import { TableData } from 'contracts/types/component';

export interface IBulkDataReport {
  readonly id: number;
  readonly fileName: string;
  readonly fileTempName: string;
  readonly scenarioName: string;
  readonly friendlyUploadType: string;
  readonly status: string;
  readonly createdDateTime: string | null;
  readonly createdUser: string;
  readonly exceptionMessage: string;
  readonly reportName: string;
  readonly custId: string;
}

class BulkDataReport implements TableData {
  id?: number;
  fileName?: string;
  fileTempName?: string;
  scenarioName?: string;
  friendlyUploadType?: string;
  status?: string;
  createdDateTime?: string | null;
  createdUser?: string;
  exceptionMessage?: string;
  reportName?: string;
  custId?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IBulkDataReport>) {
    this.id = data?.id;
    this.fileName = data?.fileName;
    this.fileTempName = data?.fileTempName;
    this.scenarioName = data?.scenarioName;
    this.friendlyUploadType = data?.friendlyUploadType;
    this.status = data?.status;
    this.createdDateTime = data?.createdDateTime;
    this.createdUser = data?.createdUser;
    this.exceptionMessage = data?.exceptionMessage;
    this.reportName = data?.reportName;
    this.custId = data?.custId;
  }
}

export default BulkDataReport;
