import equal from 'fast-deep-equal';
import update from 'immutability-helper';

import {
  getEcoBillingStatus,
  getGroupedPayments,
  getInvoices,
  getOutstandingBalance,
  getPayments,
} from 'billing/services/billingServices';
import { EcoBillStatus, Invoice, Payment } from 'contracts/models';
import PaymentItemModel from 'contracts/models/service/PaymentItemModel';
import { ActionDispatcher, BillingAction } from 'contracts/types/action';
import { PaginationdRequest } from 'contracts/types/request';
import {
  ApplicationState,
  BillingState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createServiceErrorNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Actions Keys
const ROOT_KEY = 'billing';
enum ActionKey {
  LOAD_BALANCE = 'billing/LOAD_BALANCE',
  LOAD_ECOBILLING = 'billing/LOAD_ECOBILLING',
  LOAD_INVOICES = 'billing/LOAD_INVOICES',
  LOAD_PAYMENTS = 'billing/LOAD_PAYMENTS',
  RESET_INVOICES = 'billing/RESET_INVOICES',
  RESET_PAYMENTS = 'billing/RESET_PAYMENTS',
  RESET = 'billing/RESET',
  LOAD_GROUPED_PAYMENTS = 'LOAD_GROUPED_PAYMENTS'
}

// Initial State
const getInitialState: () => BillingState = () => {
  return {
    payments: [],
    groupedPayments: [],
    invoices: [],
    balance: 0,
    ecoBilling: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_ECOBILLING, 
  ActionKey.LOAD_BALANCE, 
  ActionKey.LOAD_INVOICES, 
  ActionKey.LOAD_PAYMENTS,
  ActionKey.RESET_INVOICES,
  ActionKey.RESET_PAYMENTS,
  ActionKey.LOAD_GROUPED_PAYMENTS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  BillingState,
  BillingAction
> = {
  [ActionKey.LOAD_BALANCE]: (state, action) => {
    return update(state, {
      $merge: {
        balance: action.balance,
      },
    });
  },
  [ActionKey.LOAD_ECOBILLING]: (state, action) => {
    return update(state, {
      $merge: {
        ecoBilling: action.ecoBilling,
      },
    });
  },
  [ActionKey.LOAD_PAYMENTS]: (state, action) => {
    return update(state, {
      $merge: {
        payments: action.payments,
      },
    });
  },
  [ActionKey.LOAD_INVOICES]: (state, action) => {
    return update(state, {
      $merge: {
        invoices: action.invoices,
      },
    });
  },
  [ActionKey.RESET_PAYMENTS]: state => {
    return update(state, {
      $merge: {
        payments: [],
      },
    });
  },
  [ActionKey.RESET_INVOICES]: state => {
    return update(state, {
      $merge: {
        invoices: [],
      },
    });
  },
  [ActionKey.LOAD_GROUPED_PAYMENTS]: (state, action) => {
    const groupedPayments = action.groupedPayments || [];
    const combinedPayments = state.groupedPayments.concat(groupedPayments);
    const distinctPayments = combinedPayments.reduce<PaymentItemModel[]>((payments, current) => {
      if (!payments.some(item => 
        equal(item.invoices, current.invoices) && 
        equal(item.paymentIdentifier, current.paymentIdentifier)
        )) {
        payments.push(current);
      }
      return payments;
    }, []);
    
    return update(state, {
      $merge: {
        groupedPayments:  distinctPayments
      },
    });
  },
};

export const reducer = getReducerBuilder<BillingState, BillingAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_BALANCE: (
    balance?: number,
  ): BillingAction => ({
    type: ActionKey.LOAD_BALANCE,
    balance,
  }),
  LOAD_ECOBILLING: (
    ecoBilling?: EcoBillStatus,
  ): BillingAction => ({
    type: ActionKey.LOAD_ECOBILLING,
    ecoBilling,
  }),
  LOAD_PAYMENTS: (
    payments?: Payment[],
  ): BillingAction => ({
    type: ActionKey.LOAD_PAYMENTS,
    payments,
  }),
  LOAD_INVOICES: (
    invoices?: Invoice[],
  ): BillingAction => ({
    type: ActionKey.LOAD_INVOICES,
    invoices,
  }),
  LOAD_GROUPED_PAYMENTS: (
    payments?: PaymentItemModel[],
  ): BillingAction => ({
    type: ActionKey.LOAD_GROUPED_PAYMENTS,
    groupedPayments: payments,
  }),
  RESET_PAYMENTS: (): BillingAction => ({ type: ActionKey.RESET_PAYMENTS }),
  RESET_INVOICES: (): BillingAction => ({ type: ActionKey.RESET_INVOICES }),
  RESET: (): BillingAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadBalance = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_BALANCE,
    async() => getOutstandingBalance(),
    result => {
      dispatch(actionMap.LOAD_BALANCE(result));
    },
    err => {
      dispatch(actionMap.LOAD_BALANCE());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const loadEcoBilling = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ECOBILLING,
    async() => getEcoBillingStatus(),
    result => {
      dispatch(actionMap.LOAD_ECOBILLING(result));
    },
    err => {
      dispatch(actionMap.LOAD_ECOBILLING());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const loadPayments = ({ pageNumber, pageSize }: PaginationdRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAYMENTS,
    async() => getPayments({ pageNumber, pageSize }),
    result => {
      dispatch(actionMap.LOAD_PAYMENTS(result));
    },
    err => {
      dispatch(actionMap.LOAD_PAYMENTS());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const loadInvoices = ({ pageNumber, pageSize }: PaginationdRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_INVOICES,
    async() => getInvoices({ pageNumber, pageSize }),
    result => {
      dispatch(actionMap.LOAD_INVOICES(result));
    },
    err => {
      dispatch(actionMap.LOAD_INVOICES());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const loadGroupedPayments = ({ pageNumber, pageSize }: PaginationdRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_GROUPED_PAYMENTS,
    async() => getGroupedPayments({ pageNumber, pageSize }),
    result => {
      dispatch(actionMap.LOAD_GROUPED_PAYMENTS(result));
    },
    err => {
      dispatch(actionMap.LOAD_GROUPED_PAYMENTS());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const billingDuck = {
  actionKeys: ActionKey,
  thunks: {
    loadEcoBilling,
    loadBalance,
    loadPayments,
    loadInvoices,
    loadGroupedPayments,
  },
  actions: {
    resetPayments: actionMap.RESET_PAYMENTS,
    resetInvoices: actionMap.RESET_INVOICES,
    reset: actionMap.RESET,
  },
};

export default billingDuck;
