import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import { ReportsRoutes } from '.';

const DashboardPage = lazy(() => import('reports/components/Dashboard/DashboardPage'));
const CompactorProfilePage = lazy(() => import('reports/components/Pages/CompactorProfilePage'));
const FrontLoadDiversionReportModelingPage = lazy(() => import('reports/components/Pages/FrontLoadDiversionReportModelingPage'));
const FrontLoadDiversionReportPage = lazy(() => import('reports/components/Pages/FrontLoadDiversionReportPage'));
const OptimizationReportPage = lazy(() => import('reports/components/Pages/OptimizationReportPage'));
const PowerBiPage = lazy(() => import('reports/components/PowerBi/PowerBiPage'));
const FoundryPage = lazy(() => import('reports/components/Foundry/FoundryPage'));
const UploadedDocsPage = lazy(() => import('reports/components/UploadedDocs/UploadedDocsPage'));
const RightSizingPage = lazy(() => import('reports/components/RightSizing/RightSizingPage'));
const ServiceConfirmationAnalytics = lazy(() => import('reports/components/ServiceConfirmationAnalytics/ServiceConfirmationAnalyticsPage'));

const ReportsRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={ReportsRoutes.dashboard} element={
      <AuthRoute componentKey={SubscriptionComponentKey.DashboardPage}>
        <DashboardPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.optimizationDetails} element={
      <AuthRoute componentKey={SubscriptionComponentKey.OptimizationDetailsPage}>
        <CompactorProfilePage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.optimizationReport} element={
      <AuthRoute componentKey={SubscriptionComponentKey.OptimizationReportPage}>
        <OptimizationReportPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.frontLoadDiversionReport} element={
      <AuthRoute componentKey={SubscriptionComponentKey.FrontLoadDiversionReportPage}>
        <FrontLoadDiversionReportPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.frontLoadDiversionDetails} element={
      <AuthRoute componentKey={SubscriptionComponentKey.FrontLoadDiversionDetailsPage}>
        <FrontLoadDiversionReportModelingPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.serviceConfirmationAnalytics} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.ServiceConfirmationAnalytics}
      >
        <ServiceConfirmationAnalytics />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.uploadedDocs} element={
      <AuthRoute componentKey={SubscriptionComponentKey.UploadedDocsPage}>
        <UploadedDocsPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.rightSizing} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.RightSizingPage}
      >
        <RightSizingPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.powerBi} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.PowerBiPage}
      >
        <PowerBiPage />
      </AuthRoute>
    } />
    <Route path={ReportsRoutes.foundry} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.FoundryPage}
      >
        <FoundryPage />
      </AuthRoute>
    } />
   
    {/* Default Route */}
    <Route path="*" element={<Navigate to={ReportsRoutes.root + ReportsRoutes.dashboard} replace />} />
    <Route index element={<Navigate to={ReportsRoutes.root + ReportsRoutes.dashboard} replace />} />
  </Routes>
);

export default ReportsRouter;
