import React, { ChangeEvent, PureComponent } from 'react';

import memoizeOne from 'memoize-one';

import translate from 'core/helpers/translate';

import {
  FilterClearButton,
  FilterOkButton,
  FilterPopupContainer,
  FilterPopupFooter,
} from '../styled/Filter';

import DropdownFilterChecklist from './DropdownFilterChecklist';
import DropdownFilterPopupSearch from './DropdownFilterPopupSearch';
import DropdownTypeAheadFilter from './DropdownTypeAheadFilter';

const MIN_FILTER_ROWS = 6;
const SHOW_MORE_COUNT = 100;

const trimSearchTerm = memoizeOne(searchTerm =>
  searchTerm.trim().toLowerCase(),
);

const extractVisibleEntries = memoizeOne(
  (data, searchTerm, searchByFilterKey) =>
    data
      ? searchTerm
        ? data.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (entry: any) =>
            !searchTerm ||
              (searchByFilterKey &&
                entry.filterKey &&
                entry.filterKey.trim().toLowerCase().indexOf(searchTerm) >
                  -1) ||
              (!searchByFilterKey &&
                entry.label.trim().toLowerCase().indexOf(searchTerm) > -1),
        )
        : data
      : [],
);

const getChecklistDataSource = memoizeOne(
  (
    data,
    maxUnchecked,
    extraRowsShow,
    searchTerm,
    searchByFilterKey = false,
  ) => {
    const normalizedSearchTerm = trimSearchTerm(searchTerm);
    let visibleEntries = extractVisibleEntries(
      data,
      normalizedSearchTerm,
      searchByFilterKey,
    );
    const totalRowsToShow = maxUnchecked + extraRowsShow;
    const remainingCount = Math.max(0, visibleEntries.length - totalRowsToShow);
    if (remainingCount > 0) {
      visibleEntries = visibleEntries.slice(0, totalRowsToShow);
    }
    return {
      visibleEntries,
      remainingCount,
    };
  },
);

class DropdownFilterPopup<T extends number | string> extends PureComponent<
  ComponentProps<T>
> {
  state = {
    searchTerm: '',
    internalSelectedIds: this.props.selectedIds || [],
    extraRowsShow: 0,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(prevProps: ComponentProps<T>): void {
    const { dataSource, selectedIds } = this.props;
    if (
      prevProps.dataSource !== dataSource ||
      prevProps.selectedIds !== selectedIds
    ) {
      this.setState({
        searchTerm: '',
        internalSelectedIds: selectedIds,
        extraRowsShow: 0,
      });
    }
  }

  clearSelection = (): void => {
    this.setState({
      internalSelectedIds: '',
      searchTerm: '',
      extraRowsShow: 0,
    });
  };

  onShowMore = (): void => {
    const { extraRowsShow } = this.state;
    this.setState({ extraRowsShow: extraRowsShow + SHOW_MORE_COUNT });
  };

  onSearchChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target) {
      const { value } = event.target;
      this.setState({ searchTerm: value, extraRowsShow: 0 });
    }
  };

  onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, id: T): void => {
    const { checked } = event.target;
    let { internalSelectedIds } = this.state;
    if (!checked) {
      internalSelectedIds = internalSelectedIds.filter(x => x !== id);
    } else {
      internalSelectedIds = [...internalSelectedIds, id];
    }
    this.setState({ internalSelectedIds });
  };

  confirmSelection = (): void => {
    const { onFilterChanged } = this.props;
    const { internalSelectedIds } = this.state;
    onFilterChanged(internalSelectedIds);
  };

  closeTypeAheadSelection = (): void => {
    const { closePopup } = this.props;
    closePopup();
  };

  clearTypeAheadSelection = (): void => {
    const { onFilterChanged } = this.props;
    onFilterChanged([]);
  };
  
  onSearchSubmit = (selectedEntity: T): void => {
    const { onCityZipFilterChanged } = this.props;
    onCityZipFilterChanged([selectedEntity]);
  };

  render(): React.ReactNode {
    const {
      label,
      dataSource,
      checkboxLabelLineCount,
      searchByFilterKey,
      typeAheadType,
      openOnLeft
    } = this.props;

    const { searchTerm, internalSelectedIds, extraRowsShow } = this.state;
    const { rows, maxUnchecked } = dataSource;
    const { visibleEntries, remainingCount } = getChecklistDataSource(
      rows,
      maxUnchecked,
      extraRowsShow,
      searchTerm,
      searchByFilterKey,
    );

    if (typeAheadType) {
      return (
        <FilterPopupContainer>
          <DropdownTypeAheadFilter
            label={label}
            typeAheadType={typeAheadType}
            onSubmit={this.onSearchSubmit}
          />
          <FilterPopupFooter>
            <FilterClearButton onClick={this.clearTypeAheadSelection}>
              {translate('clear')}
            </FilterClearButton>
            <FilterOkButton onClick={this.closeTypeAheadSelection}>Ok</FilterOkButton>
          </FilterPopupFooter>
        </FilterPopupContainer>
      );
    }

    return (
      <FilterPopupContainer openOnLeft={openOnLeft}>
        {rows.length >= MIN_FILTER_ROWS && (
          <DropdownFilterPopupSearch
            label={label}
            value={searchTerm}
            onSearchChange={this.onSearchChanged}
          />
        )}
        <DropdownFilterChecklist
          data={visibleEntries}
          onCheckboxChange={this.onCheckboxChange}
          selectedIds={internalSelectedIds}
          extraUncheckedCount={remainingCount}
          showMore={this.onShowMore}
          checkboxLabelLineCount={checkboxLabelLineCount}
        />
        <FilterPopupFooter>
          <FilterClearButton onClick={this.clearSelection}>
            {translate('clear')}
          </FilterClearButton>
          <FilterOkButton onClick={this.confirmSelection}>Ok</FilterOkButton>
        </FilterPopupFooter>
      </FilterPopupContainer>
    );
  }
}

interface ComponentProps<T extends number | string> {
  label: string; // name of filter
  dataSource: {
    rows: Array<DropdownFilterValue<T>>;
    maxUnchecked?: number;
  }; // values to map to selection list
  selectedIds: T[]; // default selected (when opening the popup)
  onFilterChanged: (selectedIds: T[]) => void; // raised when selection confirmed!
  onCityZipFilterChanged: (selectedEntity: T[]) => void; // TypeAhead filter changed
  checkboxLabelLineCount?: number;
  searchByFilterKey?: boolean;
  closePopup: () => void;
  typeAheadType?: string;
  openOnLeft?: boolean;
}

export interface DropdownFilterValue<T extends number | string> {
  id: T;
  label: string;
  filterKey?: string;
}

export default DropdownFilterPopup;
