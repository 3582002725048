import styled from 'styled-components';

import { sizeMapper } from 'utils/styles';

interface ButtonSetProps {
  marginLarge?: boolean;
  marginSmall?: boolean;
  margin?: string;
}
const ButtonSet = styled.div<ButtonSetProps>`
  margin-top: ${props =>
    (props.marginLarge && '45px') || (props.marginSmall && '15px') || '30px'};
  text-align: center;

  margin: ${props => sizeMapper(props.margin, 'no')};
`;

export default ButtonSet;
