import styled from 'styled-components';

interface DivProps {
  width?: boolean;
  margin?: string;
  display?: boolean;
  float?: boolean;
  padding?: string;
  hasPadding?: boolean;
}

const Div = styled.div<DivProps>`
  width: ${props => `${props.width || 'auto'}`};
  margin: ${props => `${props.margin || '0px'}`};
  display: ${props => `${props.display || 'block'}`};
  float: ${props => `${props.float || 'none'}`};
  padding: ${props => (props.hasPadding ? '15px 40px' : '')};
  padding: ${props => (props.padding ? props.padding : '')};

  .hasAction {
    font-size: 14px;
    float: right;
    margin: ${props => (props.hasPadding ? '-55px -40px 0 0' : '-40px 0 0 0')};

    .label {
      position: relative;
      top: -5px;
      margin-left: 5px;
    }

    svg {
      margin-right: 0;
    }
  }

  .hasActionSearch {
    font-size: 14px;
    float: right;
    margin-top: 10px;

    .label {
      position: relative;
      top: -5px;
      margin-left: 5px;
    }

    svg {
      margin-right: 0;
    }
  }
`;

export default Div;
