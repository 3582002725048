import styled, { css } from 'styled-components';

import { loading, theme } from 'core/styles';

interface LoadingSectionContainerProps {
  noBackground?: boolean;
}

interface LoadingSectionContentProps{
  small?: boolean;
}
export const LoadingSectionContent = styled.div<LoadingSectionContentProps>`
  ${loading(30)};

  ${props =>
    props.small &&
    css`
      ${loading(20)};
    `}
`;

export const LoadingSectionContainer = styled.div<LoadingSectionContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: ${theme.colors.brandWhite};

  ${props =>
    props.noBackground &&
    css`
      background-color: transparent;
    `}
`;

export const LoadingSectionFragmentContainer = styled.div`
  width: 30px;
`;
