import LineSeries from './LineSeries';

class YDefinition {
  series: LineSeries[];
  maxLines?: number;
  axisLabel: (value: number) => string;
  labelRender: (value: number) => string;
  color?: string;
  constructor({ series, maxLines, axisLabel, labelRender }: YDefinition) {
    this.series = series;
    this.maxLines = maxLines;
    this.axisLabel = axisLabel;
    this.labelRender = labelRender;
  }
}

export default YDefinition;
