import update from 'immutability-helper';

import { IntergratedReport } from 'contracts/models';
import { ActionDispatcher, FoundryAction } from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  FoundryState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as uploadedDocsService from '../services/uploadedDocsService';

// Actions Keys
const ROOT_KEY = 'reports/foundry';
enum ActionKey {
  LOAD_REPORT = 'reports/foundry/LOAD_REPORT',
  RESET = 'reports/foundry/RESET',
}

const getInitialState: () => FoundryState = () => {
  return {
    foundryReport: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_REPORT] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FoundryState,
  FoundryAction
> = {
  [ActionKey.LOAD_REPORT]: (state, action) => {
    if (!action.foundryReport) {
      return state;
    }
    const foundryReport = action.foundryReport;
    return update(state, { $merge: { foundryReport } });
  },
};

export const reducer = getReducerBuilder<FoundryState, FoundryAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_REPORT: (foundryReport?: IntergratedReport): FoundryAction => ({
    type: ActionKey.LOAD_REPORT,
    foundryReport: foundryReport || {},
  }),
  RESET: (): FoundryAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadFoundryReport = (id: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_REPORT,
    () => uploadedDocsService.getFoundryReport(id),
    result => dispatch(actionMap.LOAD_REPORT(result)),
    () => {
      dispatch(actionMap.LOAD_REPORT());
    },
    true,
  );

export const foundryDuck = {
  thunks: { loadFoundryReport },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
