export interface IServiceRequestsSummary {
  readonly categoriesSummary: ServiceRequestsCategorySummary;
  readonly slaSummary: ServiceRequestsSlaSummary;
  
  readonly serviceProcessorFilter: string[];
}

class ServiceRequestsSummary {
  categoriesSummary?: ServiceRequestsCategorySummary;
  slaSummary?: ServiceRequestsSlaSummary;
  serviceProcessorFilter?: string[];

  constructor(data?: Partial<IServiceRequestsSummary>) {
    this.categoriesSummary = data?.categoriesSummary;
    this.slaSummary = data?.slaSummary;
    this.serviceProcessorFilter = data?.serviceProcessorFilter;
  }
}

export interface ServiceRequestsCategorySummary {
  entries: ServiceRequestsCategoryEntry[];
  totalTickets: number;
}

export interface ServiceRequestsCategoryEntry {
  identifier: string;
  name: string;
  ticketsCount: number;
}

export interface ServiceRequestsSlaSummary {
  met: ServiceRequestSlaEntry;
  missed: ServiceRequestSlaEntry;
}

export interface ServiceRequestSlaEntry {
  count: number;
  percentage: number;
}

export default ServiceRequestsSummary;
