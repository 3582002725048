import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { InputStyle } from 'core/components/HookFormComponents/styled';
import { Devices, theme } from 'core/styles';

export const AccountDetailsInformationWrapper = styled.div`
  width: 100%;
  padding: 40px 20px 20px;
`;
export const AccountDetailsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const AccountDetailsContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  flex-direction: row;
  @media ${Devices.tablet} {
    flex-direction: column;
  }
`;

export const UserNameInformation = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 0 2%;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: ${theme.fontWeightBold};
  font-size: 16px;
  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const AccountButtonsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 0 2%;
  @media ${Devices.tablet} {
    margin-top: 20px;
    width: 100%;
  }
`;

export const AccountDetailsItem = styled.div`
  display: block;
  flex: 0 1 50%;
  padding: 0px 20px 20px 20px;
`;

export const AccountDetailsCheckboxItem = styled.div`
  display: block;
  flex: 0 1 16%;
  padding: 0px 20px 20px 20px;
`;

export const AccountDetailsLabel = styled.div`
  text-transform: capitalize;
  line-height: 16px;
  font-size: 12px;
  color: ${transparentize(0.6, theme.colors.brandBlack)};
`;

export const AccountDetailsValue = styled.div`
  ${InputStyle};

  display: block;
`;

export const AccountDetailsSsoContainer = styled.div`
  ${InputStyle};

  border: none;
  display: block;
`;

export const AccountDetailsSsoValue = styled.div`
  padding-left: 30px;
`;

export const AccountToggleWrapper = styled.div`
  width: 30%;
  display: inline-block;
  margin: 0 18%;
  @media ${Devices.tablet} {
    width: 80%;
    margin:0 auto;
    display: block;
  }
`;

export const SiteAccessContainer = styled.div`
  overflow-y: scroll;
  height: 350px;
  padding: 2px;
`;

export const SiteListContainer = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

interface SiteNameProps {
  selected?: boolean;
}

export const SiteName = styled.div<SiteNameProps>`
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 14px;
  padding: 5px 5px;
  display: inline-block;
  max-width: 400px;

  ${props =>
    props.selected &&
    css`
      background-color: ${theme.colors.brandWhite};
      border: 1px solid ${theme.colors.brandGreenLighter};
      display: inline-flex;
      padding: 2px;
      margin: 2px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 2px;
    `};
`;

export const SiteAddress = styled.div`
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 14px;
  padding: 2px 5px;
`;
