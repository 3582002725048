import update from 'immutability-helper';

import { DashboardReportResponse } from 'contracts/models';
import { ActionDispatcher, DashboardAction } from 'contracts/types/action';
import {
  ApplicationState,
  DashboardState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getDiversionPercentage,
  getInvoicedAmountsByChargeType,
  getNetCarbonEmissionsAvoided,
  getRebateBreakDownByMaterial,
  getSlaCompliance,
  getSubmittedRequests,
} from 'reports/services/dashboardService';

// Actions Keys
const ROOT_KEY = 'dashboard';
enum ActionKey {
  LOAD_SUBMITTED_REQUESTS = 'dashboard/LOAD_SUBMITTED_REQUESTS',
  LOAD_SLA_COMPLIANCE = 'dashboard/LOAD_SLA_COMPLIANCE',
  LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE = 'dashboard/LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE',
  LOAD_REBATE_BREAK_DOWN_BY_MATERIAL = 'dashboard/LOAD_REBATE_BREAK_DOWN_BY_MATERIAL',
  LOAD_DIVERSION_PERCENTAGE = 'dashboard/LOAD_DIVERSION_PERCENTAGE',
  LOAD_NET_CARBON_EMISSIONS_AVOIDED = 'dashboard/LOAD_NET_CARBON_EMISSIONS_AVOIDED',
  RESET = 'dashboard/RESET',
}

// Initial State
const getInitialState: () => DashboardState = () => {
  return {
    submittedRequests: {},
    slaCompliance: {},
    invoicedAmountsByChargeType: {},
    rebateBreakDownByMaterial: {},
    diversionPercentage: {},
    netCarbonEmissionsAvoided: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SUBMITTED_REQUESTS,
  ActionKey.LOAD_SLA_COMPLIANCE,
  ActionKey.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE,
  ActionKey.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL,
  ActionKey.LOAD_DIVERSION_PERCENTAGE,
  ActionKey.LOAD_NET_CARBON_EMISSIONS_AVOIDED,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  DashboardState,
  DashboardAction
> = {
  [ActionKey.LOAD_SUBMITTED_REQUESTS]: (state, action) => {
    const { submittedRequests } = action;
    return update(state, { $merge: { submittedRequests } });
  },
  [ActionKey.LOAD_SLA_COMPLIANCE]: (state, action) => {
    const { slaCompliance } = action;
    return update(state, { $merge: { slaCompliance } });
  },
  [ActionKey.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE]: (state, action) => {
    const { invoicedAmountsByChargeType } = action;
    return update(state, { $merge: { invoicedAmountsByChargeType } });
  },
  [ActionKey.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL]: (state, action) => {
    const { rebateBreakDownByMaterial } = action;
    return update(state, { $merge: { rebateBreakDownByMaterial } });
  },
  [ActionKey.LOAD_DIVERSION_PERCENTAGE]: (state, action) => {
    const { diversionPercentage } = action;
    return update(state, { $merge: { diversionPercentage } });
  },
  [ActionKey.LOAD_NET_CARBON_EMISSIONS_AVOIDED]: (state, action) => {
    const { netCarbonEmissionsAvoided } = action;
    return update(state, { $merge: { netCarbonEmissionsAvoided } });
  },
};

export const reducer = getReducerBuilder<DashboardState, DashboardAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SUBMITTED_REQUESTS: (
    submittedRequests?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_SUBMITTED_REQUESTS,
    submittedRequests,
  }),
  LOAD_SLA_COMPLIANCE: (
    slaCompliance?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_SLA_COMPLIANCE,
    slaCompliance,
  }),
  LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE: (
    invoicedAmountsByChargeType?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE,
    invoicedAmountsByChargeType,
  }),
  LOAD_REBATE_BREAK_DOWN_BY_MATERIAL: (
    rebateBreakDownByMaterial?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL,
    rebateBreakDownByMaterial,
  }),
  LOAD_DIVERSION_PERCENTAGE: (
    diversionPercentage?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_DIVERSION_PERCENTAGE,
    diversionPercentage,
  }),
  LOAD_NET_CARBON_EMISSIONS_AVOIDED: (
    netCarbonEmissionsAvoided?: DashboardReportResponse,
  ): DashboardAction => ({
    type: ActionKey.LOAD_NET_CARBON_EMISSIONS_AVOIDED,
    netCarbonEmissionsAvoided,
  }),
  RESET: (): DashboardAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadSubmittedRequests = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUBMITTED_REQUESTS,
    async() => getSubmittedRequests(),
    result => {
      dispatch(actionMap.LOAD_SUBMITTED_REQUESTS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SUBMITTED_REQUESTS());
    },
  );

const loadSlaCompliance = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SLA_COMPLIANCE,
    async() => getSlaCompliance(),
    result => {
      dispatch(actionMap.LOAD_SLA_COMPLIANCE(result));
    },
    () => {
      dispatch(actionMap.LOAD_SLA_COMPLIANCE());
    },
  );

const loadInvoicedAmountsByChargeType = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE,
    async() => getInvoicedAmountsByChargeType(),
    result => {
      dispatch(actionMap.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE(result));
    },
    () => {
      dispatch(actionMap.LOAD_INVOICED_AMOUNTS_BY_CHARGE_TYPE());
    },
  );

const loadRebateBreakDownByMaterial = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL,
    async() => getRebateBreakDownByMaterial(),
    result => {
      dispatch(actionMap.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL(result));
    },
    () => {
      dispatch(actionMap.LOAD_REBATE_BREAK_DOWN_BY_MATERIAL());
    },
  );

const loadDiversionPercentage = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_DIVERSION_PERCENTAGE,
    async() => getDiversionPercentage(),
    result => {
      dispatch(actionMap.LOAD_DIVERSION_PERCENTAGE(result));
    },
    () => {
      dispatch(actionMap.LOAD_DIVERSION_PERCENTAGE());
    },
  );

const loadNetCarbonEmissionsAvoided = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NET_CARBON_EMISSIONS_AVOIDED,
    async() => getNetCarbonEmissionsAvoided(),
    result => {
      dispatch(actionMap.LOAD_NET_CARBON_EMISSIONS_AVOIDED(result));
    },
    () => {
      dispatch(actionMap.LOAD_NET_CARBON_EMISSIONS_AVOIDED());
    },
  );

const dashboardDuck = {
  thunks: {
    loadSubmittedRequests,
    loadSlaCompliance,
    loadInvoicedAmountsByChargeType,
    loadRebateBreakDownByMaterial,
    loadDiversionPercentage,
    loadNetCarbonEmissionsAvoided,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default dashboardDuck;
