import {
  CustomerType,
  PermissionType,
  SaleType,
  UserRole,
} from 'contracts/enums';
import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionItems from 'contracts/enums/SubscriptionItems';
import SubscriptionType from 'contracts/enums/SubscriptionType';
import { ApplicationState } from 'contracts/types/state';

export function getClaimsUserRole(
  state: ApplicationState,
): UserRole | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.role
    : undefined;
}

export function getClaimsCustomerType(
  state: ApplicationState,
): CustomerType | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.customerType
    : undefined;
}

export function getClaimsSubscriptionType(
  state: ApplicationState,
): SubscriptionType | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.subscription
    : undefined;
}

export function getClaimsCustomerId(
  state: ApplicationState,
): string | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.custId
    : undefined;
}

export function getCurrentUserEmail(
  state: ApplicationState,
): string | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.emailAddress
    : undefined;
}

export function getSalePermissions(
  state: ApplicationState,
): SaleType[] | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.salePermissions
    : undefined;
}

export function getNeedsPasswordChange(state: ApplicationState): boolean {
  return state && state.account.login.claims
    ? state.account.login.claims.changePassword === 'true'
    : false;
}

export function getCustomerName(state: ApplicationState): string | undefined {
  return state && state.account.login.user
    ? state.account.login.user.customerName
    : undefined;
}

export function getUserBrandings(state: ApplicationState): BrandingType[] | undefined {
  return state && state.account.login.user
    ? state.account.login.user.brandings
    : undefined;
}

export function userHasBranding(state: ApplicationState, branding: BrandingType): boolean {
  return state && state.account.login.user && state.account.login.user.brandings
    ? state.account.login.user.brandings.includes(branding)
    : false;
}

export function getUserSubscriptionItems(state: ApplicationState): SubscriptionItems[] | undefined {
  return state && state.account.login.user
    ? state.account.login.user.subscriptionItems
    : undefined;
}

export function userHasSubscriptionItem(state: ApplicationState, module: SubscriptionItems): boolean {
  return state && state.account.login.user && state.account.login.user.subscriptionItems
    ? state.account.login.user.subscriptionItems.includes(module)
    : false;
}

export function canCustomerCreateUsers(state: ApplicationState): boolean {
  return state && state.account.login.user
    ? state.account.login.user.automaticallyCreateUsers ? false : true : true;
}

export function getTrackingIdentity(
  state: ApplicationState,
): string | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.trackingIdentity
    : undefined;
}

export function getChannel(state: ApplicationState): string | undefined {
  return state && state.account.login.claims
    ? state.account.login.claims.channel
    : undefined;
}

export function isUserImpersonating(state: ApplicationState): boolean {
  return state && state.account.login.claims
    ? Boolean(state.account.login.claims.gusUserId)
    : false;
}

export function getPermission(
  state: ApplicationState,
): PermissionType[] | undefined {
  if (state && state.account.login.user) {
    const permissions = [];
    if (state.account.login.user.hasApproval) {
      permissions.push(PermissionType.Approval);
    }

    if (state.account.login.user.hasDisposalAudit) {
      permissions.push(PermissionType.DisposalAudit);
    }

    if (state.account.login.user.hasLibryoAccess) {
      permissions.push(PermissionType.Libryo);
    }

    // Add any new user permission type here.

    return permissions;
  }
  return undefined;
}
