import styled, { css } from 'styled-components';

import { Icon } from 'core/components';
import { Devices, theme } from 'core/styles';

export const SearchTagSortContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  @media ${Devices.mobile} {
    flex-direction:column
  }
`;

export const TagTableSearchWrapper = styled.div`
  width: 76%;
  @media ${Devices.tablet} {
    width: 65%;
  }
  @media ${Devices.mobile} {
    width: 100%;
  }
`;

export const TagTableSortDropdown = styled.div`
  width: 24%;
  height: 54px;
  @media ${Devices.tablet} {
    width: 35%;
  }
  @media ${Devices.mobile} {
    width: 100%;
  }
`;

export const UsersAndTagsHeaderActionsWrapper = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media ${Devices.tablet} {
    width: 80%;
    margin: 20px auto;
    align-items: center;
  }
`;

export const SelectedHeadingRow = styled.div`
  display: block;
  overflow-y: auto;
  background-color: ${theme.colors.grayLight};
  height: 80px;
  width: calc(100% - 10px);
  margin: 20px 5px;
  line-height: 22px;
`;

export const UserSitesListContainer = styled.div`
  background-color: rgba(213, 216, 232, 0.25);
`;

export const AssignedSitesListContainer = styled.div`
  background-color: rgba(213, 216, 232, 0.25);
  height: 350px;
  margin: 2px;
`;

export const UserSitesRow = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

export const SitesTagsSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap:wrap;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
  > div {
    width: 48%;
  }
`;
export const SiteAccessPanelWrapper = styled.div`
  flex: 0 0 48%;
`;
export const TagAccessPanelWrapper = styled.div`
  flex: 0 0 48%;
  margin-left: 4%;
`;

export const ViewIcon = styled(Icon).attrs(() => ({
  icon: 'view',
}))`
  width: 25px;
  height: 30px;
  color: ${theme.colors.brandPrimary};
  cursor: pointer;
  margin-right:6px;
`;

interface DeleteIconProps {
  disabled?: boolean;
}
export const DeleteIcon = styled(Icon).attrs(() => ({
  icon: 'delete',
}))<DeleteIconProps>`
  width: 20px;
  height: 25px;
  color: ${theme.colors.brandPrimary};
  cursor: pointer;
  vertical-align: top;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.3;
    `};
`;
