import DashboardReportResponse, {
  IDashboardReportResponse,
} from 'contracts/models/service/DashboardReportResponse';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getSubmittedRequests = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/submitted-requests',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};

export const getSlaCompliance = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/sla-compliance',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};

export const getInvoicedAmountsByChargeType = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/invoiced-amounts-by-charge-type',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};

export const getRebateBreakDownByMaterial = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/rebate-break-down-by-material',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};

export const getDiversionPercentage = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/diversion-percentage',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};

export const getNetCarbonEmissionsAvoided = async(): Promise<DashboardReportResponse> => {
  const response = await httpCustomerCore.get<DashboardReportResponse>(
    'dashboard/net-carbon-emissions-avoided',
    {
      transformResponse: transformServiceResponse<
        IDashboardReportResponse,
        DashboardReportResponse
      >(DashboardReportResponse),
    },
  );
  return response.data;
};
