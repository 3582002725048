import CustomerUserSite, { ICustomerUserSite } from './CustomerUserSite';
import { ZuoraPaymentMethod } from './ZuoraPayment';

export interface ILocationPaymentInfo {
  readonly site: ICustomerUserSite;
  readonly defaultPaymentMethod: ZuoraPaymentMethod;
}

class LocationPaymentInfo {
  site?: CustomerUserSite;
  defaultPaymentMethod?: ZuoraPaymentMethod;

  constructor(data?: Partial<ILocationPaymentInfo>) {
    this.site = new CustomerUserSite(data?.site);
    this.defaultPaymentMethod = data?.defaultPaymentMethod;
  }
}

export default LocationPaymentInfo;
