import {
  OptimizationReportTableRowModel,
  OptimizationReportResultMetricsModel,
} from 'contracts/types/compactorProfile';

export interface IOptimizationReport {
  readonly tableRows: OptimizationReportTableRowModel[];
  readonly metrics: OptimizationReportResultMetricsModel;
}

class OptimizationReport {
  tableRows?: OptimizationReportTableRowModel[];
  metrics?: OptimizationReportResultMetricsModel;

  constructor(data?: Partial<IOptimizationReport>) {
    this.tableRows = data?.tableRows;
    this.metrics = data?.metrics;
  }
}

export default OptimizationReport;
