import React, { PropsWithChildren } from 'react';

import Script from './Script';

const GoogleAnalyticsScript: React.FC<PropsWithChildren<ComponentProps>> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='googleAnalytics'
      content={`function getCookie(name) {
      var value = '; ' + document.cookie
      var parts = value.split('; ' + name + '=')
      if (parts.length == 2)
        return parts
          .pop()
          .split(';')
          .shift()
    }

    ;(function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function() {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga')
    ga('create', 'UA-45333213-2', 'auto')
    ga('send', 'pageview')

    var sessionCookie = getCookie('session')

    if (sessionCookie) {
      var session = JSON.parse(decodeURIComponent(sessionCookie))
      ga('set', 'userId', session.user.userId)
    }`}
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
    >
      {children}
    </Script>
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
}

export default GoogleAnalyticsScript;
