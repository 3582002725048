import lazy from 'lazyLoader';

export enum ModalRoutes {
  root = '/modals',
  confirmation = '/confirmation',
  service = '/service',
  serviceInfo = '/service-info',
  serviceDeliveryConfirmation = '/service-delivery-confirmation',
  serviceDeliveryInformation = '/service-delivery-information',
  billConsolidationAddPaymentInfo = '/bill-consolidation-add-payment-info',
  billConsolidationReportIssue = '/bill-consolidation-report-issue'
}

const ConfirmationModal = lazy(() => import('core/components/Confirmation'));
const OpenServiceInfoModal = lazy(() => import('services/components/ServiceRequests/OpenServiceInfoModal'));
const ServiceDeliveryConfirmationModal = lazy(() => import('services/components/ServiceSiteProfile/ServiceDeliveryConfirmationModal/ServiceDeliveryConfirmationModal'));
const ServiceDeliveryInformationModal = lazy(() => import('services/components/ServiceSiteProfile/ServiceDeliveryInformationModel/ServiceDeliveryInformationModal'));
const ServiceModal = lazy(() => import('services/components/ServiceSiteProfile/ServiceModal/ServiceModal'));
const BillConsolidationAddPaymentInfoModal = lazy(() => import('financial/components/haulerBillConsolidation/BillConsolidationAddPaymentInfoModal'));
const BillConsolidationReportIssueModal = lazy(() => import('financial/components/haulerBillConsolidation/BillConsolidationReportIssueModal'));

export function buildModalUrl(modalType: ModalType, params: Record<string, string | number>): string {
  let url = `${ModalRoutes.root}${ModalRoutes[modalType]}`;
  if (params) {
    url += '?';
  }
  for (const key in params) {
    if (params[key]) {
      url += `${key}=${params[key]}&`;
    }
  }
  url = url.slice(0, -1);
  return url;
}

export enum ModalType {
  confirmation = 'confirmation',
  service = 'service',
  serviceInfo = 'serviceInfo',
  serviceDeliveryConfirmation = 'serviceDeliveryConfirmation',
  serviceDeliveryInformation = 'serviceDeliveryInformation',
  billConsolidationAddPaymentInfo = 'billConsolidationAddPaymentInfo',
  billConsolidationReportIssue = 'billConsolidationReportIssue',
}

export const modalDictionary: Record<ModalType, React.LazyExoticComponent<React.FC<any>>> = {
  [ModalType.confirmation]: ConfirmationModal,
  [ModalType.service]: ServiceModal,
  [ModalType.serviceInfo]: OpenServiceInfoModal,
  [ModalType.serviceDeliveryConfirmation]: ServiceDeliveryConfirmationModal,
  [ModalType.serviceDeliveryInformation]: ServiceDeliveryInformationModal,
  [ModalType.billConsolidationAddPaymentInfo]: BillConsolidationAddPaymentInfoModal,
  [ModalType.billConsolidationReportIssue]: BillConsolidationReportIssueModal
};

