enum PaymentStatusType {
  New = 'N',
  Invalid = 'V',
  Unpaid = 'U',
  UnderReview = 'R',
  Ignored = 'I',
  PartiallyPaid = 'T',
  Paid = 'P'
}

export default PaymentStatusType;
