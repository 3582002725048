import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { NewWindowRoute, AuthRoute } from 'routing/Routes';

import { SustainabilityRoutes } from '.';

const CarbonReductionPage = lazy(() => import('sustainability/components/CarbonFootprint/CarbonReductionPage'));
const DiversionPage = lazy(() => import('sustainability/components/Diversion/DiversionPage'));
const GhGCarbonEmissionsPage = lazy(() => import('sustainability/components/GhGCarbonEmissions/GhGCarbonEmissionsPage'));
const MaterialProfilePage = lazy(() => import('sustainability/components/MaterialProfile/MaterialProfilePage'));

const SustainabilityRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={SustainabilityRoutes.diversion} element={
      <AuthRoute componentKey={SubscriptionComponentKey.DiversionPage}>
        <DiversionPage />
      </AuthRoute>
    } />
    <Route path={SustainabilityRoutes.carbonReduction} element={
      <AuthRoute componentKey={SubscriptionComponentKey.CarbonReductionPage}>
        <CarbonReductionPage />
      </AuthRoute>
    } />
    <Route path={SustainabilityRoutes.materialProfile} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.MaterialProfilePage}
        // custTypes={[CustomerType.National, CustomerType.SAAS]}
      >
        <MaterialProfilePage />
      </AuthRoute>
    } />
    <Route path={SustainabilityRoutes.ghgCarbonEmissions} element={
      <AuthRoute
        componentKey={SubscriptionComponentKey.GhGCarbonEmissionsPage}
        // brandingTypes={[BrandingType.EndDestination]}
      >
        <GhGCarbonEmissionsPage />
      </AuthRoute>
    } />
    
    <Route path={SustainabilityRoutes.rubiconMethod} element={
      <NewWindowRoute url='https://www.rubicon.com/rubiconmethod/' backToPath={SustainabilityRoutes.root + SustainabilityRoutes.diversion} />
    } />
    <Route path={SustainabilityRoutes.sharepoint} element={
      <NewWindowRoute url='https://wegmans.sharepoint.com/sites/indirectprocurement/Sustainability/Forms/AllItems.aspx' backToPath={SustainabilityRoutes.root + SustainabilityRoutes.diversion} />
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={SustainabilityRoutes.root + SustainabilityRoutes.diversion} replace />} />
    <Route index element={<Navigate to={SustainabilityRoutes.root + SustainabilityRoutes.diversion} replace/>} />
  </Routes>
);

export default SustainabilityRouter;
