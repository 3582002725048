export interface IIntergratedReport {
  readonly id: number;
  readonly custId: string;
  readonly createdAt: string;
  readonly documentType: string;
  readonly name: string;
  readonly url: string;
  readonly embeddedUrl: string;
  readonly iframeAuthUrl: string;
}

class IntergratedReport {
  id?: number;
  custId?: string;
  createdAt?: string;
  documentType?: string;
  name?: string;
  url?: string;
  embeddedUrl?: string;
  iframeAuthUrl?: string;

  constructor(data?: Partial<IIntergratedReport>) {
    this.id = data?.id;
    this.custId = data?.custId;
    this.createdAt = data?.createdAt;
    this.documentType = data?.documentType;
    this.name = data?.name;
    this.url = data?.url;
    this.embeddedUrl = data?.embeddedUrl;
    this.iframeAuthUrl = data?.iframeAuthUrl;
  }
}

export default IntergratedReport;
