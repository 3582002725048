class ChartSeries {
  title: string;
  identifier: string | number | { [identifierName: string]: string | number };
  color: string;

  constructor({ title, identifier, color }: ChartSeries) {
    this.title = title;
    this.identifier = identifier;
    this.color = color;
  }
}

export default ChartSeries;
