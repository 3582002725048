enum DatePickerOptionType {
  today = 'today',
  last30daysFuture30days = 'last30daysFuture30days',
  last7days = 'last7days',
  last30days = 'last30days',
  last90days = 'last90days',
  last6Months = 'last6Months',
  yearToDate = 'yearToDate',
  lastYear = 'lastYear',
  next90days = 'next90days',
  thisYear = 'thisYear',
  oneYear = 'oneYear',
  nextYear = 'nextYear'
}
export default DatePickerOptionType;
