/* eslint-disable @typescript-eslint/member-ordering */
import { BarChartLabel } from 'contracts/types/chart';
import { DateRange } from 'contracts/types/service';

class XDefinition {
  labels: BarChartLabel[];
  axisLabelValueFormat?: (value: string | number) => string = value =>
    value ? value.toString() : '';
  tooltipTitleLabel: (label: BarChartLabel) => string;
  identifiers?: DateRange[];
  showGridLines?: boolean;
  labelPadding?: number;

  private dateTicks?: Array<{index: number, year: string}>;

  axisLabel?: (value: string | number, index: number) => string | number | undefined = (value, index) => {
    if (this.dateTicks && this.labels[index] && this.labels[index].length > 0) {
      const year = this.labels[index][1].split(' ')[1];
      for (let i = 0; i < this.dateTicks.length; i++) {
        if (this.dateTicks[i].year === year && index === this.dateTicks[i].index) {
          return year;
        }
      }
    } else {
      let label = value;
      if (value && Array.isArray(value)) {
        label = value[0];
      }
      return this.axisLabelValueFormat ? this.axisLabelValueFormat(label) : label;
    }
  };

  constructor({
    labels,
    axisLabelValueFormat,
    tooltipTitleLabel,
    identifiers,
  }: XDefinition) {
    this.labels = labels;
    this.axisLabelValueFormat = axisLabelValueFormat;
    this.tooltipTitleLabel = tooltipTitleLabel;
    this.identifiers = identifiers;
  }

  buildDateLabels? = (): void => {
    const replaceDateLabels = Boolean(this.labels && this.labels.length &&
      this.labels[0].length === 2 && this.labels[0][1].split(' ').length === 2);
    if (replaceDateLabels) {
      this.dateTicks = [];
      
      if (window.innerWidth <= 480) {
        this.dateTicks.push({ index: 0, year: this.labels[0][1].split(' ')[1] });
        this.dateTicks.push({ index: this.labels.length - 1, year: this.labels[this.labels.length - 1][1].split(' ')[1] });
      } else {
        let currentTick: {index: number, year: string} = {
          index: 0, year: this.labels[0][1].split(' ')[1]
        };
        for (let i = 1; i < this.labels.length; i++) {
          const year = this.labels[i][1].split(' ')[1];
          if (year !== currentTick.year) {
            this.dateTicks.push(currentTick);
            currentTick = { index: i, year };
          }
        }
        this.dateTicks.push(currentTick);
        if (window.innerWidth <= 768 && this.dateTicks.length > 1 && 
          this.dateTicks[1].index - this.dateTicks[0].index <= 1) {
          this.dateTicks.splice(0, 1);
        }
      }
    }
  };
}

export default XDefinition;
