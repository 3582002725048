import { combineReducers } from 'redux';

import { reducer as addressesReducer } from './addresses';
import { reducer as filtersReducer } from './filters';
import { reducer as modalManagerReducer } from './modalManager';
import { reducer as notifierReducer } from './notifier';
import { reducer as runningReducer } from './running';

const reducer = combineReducers({
  modalManager: modalManagerReducer,
  addresses: addressesReducer,
  notifier: notifierReducer,
  running: runningReducer,
  locationFilters: filtersReducer,
});

export default reducer;
