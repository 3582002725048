import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const emphasize = keyframes`
  0% {
    transform: scaled3d(1, 1, 1);
  }

  50% {
    transform: scaled3d(1.1, 1.1, 1.1);
  }

  100% {
    transform: none;
  }
`;

export const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const fadeAndSlideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

export const pulse = keyframes`
  0% {
    transform: none;
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: none;
  }
`;

export const enablePointerEvents = keyframes`
  0% {
    pointer-events: none;
  }
  100% {
    pointer-events: auto;
  }
`;
