/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FilterType } from 'contracts/enums';
import { TypeAheadOption } from 'contracts/types/form';
import { TypeAheadField } from 'core/components';
import translate from 'core/helpers/translate';

import { searchCities, searchZipCodes } from '../../services/filterService';
import {
  DropdownFilterPopupHeader,
  DropdownFilterSearchIcon
} from '../styled/DropdownFilter';
  
const DropdownTypeAheadFilter: React.FC<ComponentProps> = ({ label, typeAheadType, onSubmit }) => {
    
  const getOptionsApi: (searchTerm: string) => Promise<TypeAheadOption[]> = (searchTerm: string) => {
    return new Promise((resolve, reject) => {
      if (typeAheadType === FilterType.City) {
        searchCities(searchTerm).then(data => {
          const items: TypeAheadOption[] = data.map(d => ({
            label: d.label,
            value: d.value as any
          }));
          resolve(items);
        }).catch(e => reject(e));
      } else {
        searchZipCodes(searchTerm).then(data => {
          const items: TypeAheadOption[] = data.map(d => ({
            label: d.label,
            value: d.value
          }));
          resolve(items);
        }).catch(e => reject(e));
      }
    });
  };

  return (
    <DropdownFilterPopupHeader>
      <DropdownFilterSearchIcon />
      <TypeAheadField
        onChange={(data: any) => onSubmit(data)}
        getOptionsApi={getOptionsApi}
        placeholder={`${translate('searchFor')} ${label}`}
        fullWidth
      />
    </DropdownFilterPopupHeader>
  );
};

interface ComponentProps {
  label: string;
  typeAheadType: string;
  onSubmit: (formData: any) => void;
}

export default DropdownTypeAheadFilter;
