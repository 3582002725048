import update from 'immutability-helper';

import { DisposalAuditSummary, DisposalAuditTableData } from 'contracts/models';
import { ActionDispatcher, DisposalAuditAction } from 'contracts/types/action';
import { DisposalAuditRequest } from 'contracts/types/request';
import {
  ApplicationState,
  DisposalAuditState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getDisposalAuditSummary,
  getDisposalAuditTableData,
} from 'services/services/disposalAuditServices';

// Actions Keys
const ROOT_KEY = 'services/disposalAudit';
enum ActionKey {
  LOAD_SUMMARY = 'services/disposalAudit/LOAD_SUMMARY',
  LOAD_TABLE = 'services/disposalAudit/LOAD_TABLE',
  RESET = 'services/disposalAudit/RESET',
}

// Initial State
const getInitialState: () => DisposalAuditState = () => {
  return {
    summaryInfo: {},
    tableData: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_SUMMARY, ActionKey.LOAD_TABLE] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  DisposalAuditState,
  DisposalAuditAction
> = {
  [ActionKey.LOAD_SUMMARY]: (state, action) => {
    const { summaryInfo } = action;
    return update(state, { $merge: { summaryInfo } });
  },
  [ActionKey.LOAD_TABLE]: (state, action) => {
    const { tableData } = action;
    return update(state, { $merge: { tableData } });
  },
};

export const reducer = getReducerBuilder<
  DisposalAuditState,
  DisposalAuditAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SUMMARY: (summaryInfo?: DisposalAuditSummary): DisposalAuditAction => ({
    type: ActionKey.LOAD_SUMMARY,
    summaryInfo,
  }),
  LOAD_TABLE: (tableData?: DisposalAuditTableData[]): DisposalAuditAction => ({
    type: ActionKey.LOAD_TABLE,
    tableData,
  }),
  RESET: (): DisposalAuditAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadSummary = (arg: DisposalAuditRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY,
    async() => getDisposalAuditSummary(arg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY(result));
    },
    () => dispatch(actionMap.LOAD_SUMMARY()),
  );

const loadTable = (arg: DisposalAuditRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE,
    async() => getDisposalAuditTableData(arg),
    result => {
      dispatch(actionMap.LOAD_TABLE(result));
    },
    () => dispatch(actionMap.LOAD_TABLE()),
  );

const disposalAuditDuck = {
  thunks: { loadSummary, loadTable },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default disposalAuditDuck;
