import update from 'immutability-helper';

import { addRemoveUserSites, addTagSitesToUser, addUserSites, getAllUserSites, getUserSites } from 'account/services/siteService';
import {
  createUser,
  deleteUser,
  getUser,
  setUserAccess
} from 'account/services/userService';
import { NotificationType } from 'contracts/enums';
import { CustomerUser, CustomerUserSite } from 'contracts/models';
import { CustomerUserSiteAssigned } from 'contracts/models/service/CustomerUserSite';
import { ActionDispatcher, CurrentUserAction } from 'contracts/types/action';
import { AddTagSitesToUserRequest, AddUserSitesRequest, CreateUserRequest, UpdateUserSitesRequest } from 'contracts/types/request';
import {
  ApplicationState,
  CurrentUserState, ReduceFunctionMap
} from 'contracts/types/state';
import {
  createTimedNotificationMessage
} from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

const ROOT_KEY = 'account/currentUser';

enum ActionKey {
  GET_USER = 'account/currentUser/GET_USER',
  ADD_USER = 'account/currentUser/ADD_USER',
  REMOVE_USER = 'account/currentUser/REMOVE_USER',
  ADD_USER_SITES = 'account/currentUser/ADD_USER_SITES',
  ADD_USER_TAGS = 'account/currentUser/ADD_USER_TAGS',
  LOAD_USER_SITES = 'account/currentUser/LOAD_USER_SITES',
  LOAD_ALL_USER_SITES = 'account/currentUser/LOAD_ALL_USER_SITES',
  UPDATE_USER_SITES = 'account/currentUser/UPDATE_USER_SITES',
  UPDATE_USER_ACCESS = 'account/currentUser/UPDATE_USER_ACCESS',
  RESET = 'account/currentUser/RESET',
}

// Initial State
const getInitialState: () => CurrentUserState = () => {
  return {
    addUserId: undefined,
    details: {},
    assignedSites: [],
    allSites: []
  };
};

const reducerKeys = [
  ActionKey.GET_USER,
  ActionKey.ADD_USER,
  ActionKey.LOAD_USER_SITES,
  ActionKey.LOAD_ALL_USER_SITES,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CurrentUserState,
  CurrentUserAction
> = {
  [ActionKey.GET_USER]: (state: CurrentUserState, action: CurrentUserAction) => {
    const newState = update(state, {
      $merge: { 
        details: {
          ...state.details,
          ...action.details
        } 
      },
    });
    return newState;
  },
  [ActionKey.ADD_USER]: (state: CurrentUserState, action: CurrentUserAction) => {
    const newState = update(state, {
      $merge: {
        addUserId: action.addUserId,
      },
    });
    return newState;
  },
  [ActionKey.LOAD_USER_SITES]: (state: CurrentUserState, action: CurrentUserAction) => {
    const newState = update(state, {
      $merge: {
        assignedSites: action.assignedSites,
      },
    });
    return newState;
  },
  [ActionKey.LOAD_ALL_USER_SITES]: (state: CurrentUserState, action: CurrentUserAction) => {
    const newState = update(state, {
      $merge: {
        allSites: action.allSites,
      },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<CurrentUserState, CurrentUserAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  GET_USER: (user?: CustomerUser): CurrentUserAction => ({
    type: ActionKey.GET_USER,
    details: user
  }),
  ADD_USER: (addUserId?: number): CurrentUserAction => ({
    type: ActionKey.ADD_USER,
    addUserId
  }),
  LOAD_USER_SITES: (assignedSites?: CustomerUserSite[]): CurrentUserAction => ({
    type: ActionKey.LOAD_USER_SITES,
    assignedSites
  }),
  LOAD_ALL_USER_SITES: (allSites?: CustomerUserSiteAssigned[]): CurrentUserAction => ({
    type: ActionKey.LOAD_ALL_USER_SITES,
    allSites
  }),
  RESET: (): CurrentUserAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadUser = (email?: string) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.GET_USER,
    () => getUser(email),
    result => {
      dispatch(actionMap.GET_USER(result));
    },
    () => {},
    true,
  );

const addUser = (params: CreateUserRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADD_USER,
      async() => createUser(params),
      async result => {
        dispatch(actionMap.ADD_USER(result));
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            `${translate('account.user')} ${params.emailAddress} ${translate('account.addedSuccess')}`,
            3000,
          ),
        );
      },
      () => {},
      true,
    );

const removeUser = (userId: number, email?: string) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.REMOVE_USER,
      async() => deleteUser(userId),
      () => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            `${translate('account.user')} ${email} ${translate('account.hasBeenDeleted')}`,
            3000,
          ),
        );
      },
      () => {},
      true,
    );

const addSitesToUser = (params: AddUserSitesRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADD_USER_SITES,
      () => addUserSites(params),
      () => {},
      () => {},
      true,
    );

const addTagsToUser = (params: AddTagSitesToUserRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADD_USER_TAGS,
      () => addTagSitesToUser(params),
      () => {},
      () => {},
      true,
    );

const loadUserSites = (email?: string) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_USER_SITES,
      async() => getUserSites(email),
      result => {
        dispatch(actionMap.LOAD_USER_SITES(result));
      },
      () => {
        dispatch(actionMap.LOAD_USER_SITES());
      },
      true,
    );

const loadAllUserSites = (email: string) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_ALL_USER_SITES,
      async() => getAllUserSites(email),
      result => {
        dispatch(actionMap.LOAD_ALL_USER_SITES(result));
      },
      () => {
        dispatch(actionMap.LOAD_ALL_USER_SITES());
      },
      true,
    );

const updateUserSites = (params: UpdateUserSitesRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.UPDATE_USER_SITES,
      async() => addRemoveUserSites(params),
      result => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            translate('account.userUpdatedSuccess'),
            3000,
          ),
        );
        return result;
      },
      () => {},
      true,
    );
    
const updateUserAccess = 
(userId: number, allCustomerLocationsAccess: boolean, allOwnerLocationsAccess: boolean) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_USER_ACCESS,
    async() => setUserAccess(userId, { allCustomerLocationsAccess, allOwnerLocationsAccess }),
    () => {
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          translate('account.userUpdatedAccessSuccess'),
          3000,
        ),
      );
    },
    () => {},
    true,
  );
    
const currentUserDuck = {
  thunks: {
    loadUser,
    addUser,
    removeUser,
    addSitesToUser,
    addTagsToUser,
    loadUserSites,
    loadAllUserSites,
    updateUserSites,
    updateUserAccess
  },
  actionKeys: ActionKey,
  actions: { 
    reset: actionMap.RESET, updateUser: actionMap.GET_USER
  },
};
export default currentUserDuck;
