import React, { PropsWithChildren } from 'react';

import Script from './Script';

const PowerBiScript: React.FC<PropsWithChildren<ComponentProps>> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='powerBi'
      isUrl
      content='https://cdn.jsdelivr.net/npm/powerbi-client@2.7.1/dist/powerbi.min.js'
      attributes={{
        integrity: 'sha256-7M1TrX+sO6JiAOKJo6zDjblU1NHoP7l9lelvXJ/gbdU=',
        crossorigin: 'anonymous',
      }}
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
    >
      {children}
    </Script>
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
}

export default PowerBiScript;
