import update from 'immutability-helper';

import ContainerSizingReport, { ContainerSizingReportRequestArg } from 'contracts/models/ContainerSizingReport';
import { ActionDispatcher, ContainerSizingReportAction } from 'contracts/types/action';
import { ApplicationState, ContainerSizingReportState, ReduceFunctionMap } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getContainerSizingReport } from 'reports/services/containerSizingService';

// Action Keys
const ROOT_KEY = 'reports/right-sizing';
enum ActionKey {
  PAGE_DATA = 'reports/right-sizing/PAGE_DATA',
  TABLE_DATA = 'reports/right-sizing/TABLE_DATA',
  RESET = 'reports/right-sizing/RESET'
}

// Initial State
const getInitialState: () => ContainerSizingReportState = () => {
  return {
    containerSizingReport: {},
  };
};

const reducerKeys = [
  ActionKey.TABLE_DATA,
  ActionKey.PAGE_DATA,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
ReducerKey,
ContainerSizingReportState,
ContainerSizingReportAction
> = {
  [ActionKey.PAGE_DATA]: (state, action) => {
    const { containerSizingReport } = action;
    return update(state, { $merge: { containerSizingReport } });
  },
  [ActionKey.TABLE_DATA]: (state, action) => {
    const { containerSizingReport } = action;
    return update(state, { $merge: { 
      containerSizingReport: {
        ...state.containerSizingReport,
        tableRows: containerSizingReport?.tableRows
      } } });
  },
};

export const reducer = getReducerBuilder<
ContainerSizingReportState,
ContainerSizingReportAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  TABLE_DATA: (
    containerSizingReport?: ContainerSizingReport,
  ): ContainerSizingReportAction => ({
    type: ActionKey.TABLE_DATA,
    containerSizingReport,
  }),
  PAGE_DATA: (
    containerSizingReport?: ContainerSizingReport,
  ): ContainerSizingReportAction => ({
    type: ActionKey.PAGE_DATA,
    containerSizingReport,
  }),
  RESET: (): ContainerSizingReportAction => ({ type: ActionKey.RESET })
};

// Thunks
const loadContainerSizingReport = (arg: ContainerSizingReportRequestArg) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => 
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.PAGE_DATA,
    async() => getContainerSizingReport(arg),
    result => {
      dispatch(actionMap.PAGE_DATA(result));
    },
    () => dispatch(actionMap.PAGE_DATA()),
  );

const loadContainerSizingTable = (arg: ContainerSizingReportRequestArg) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => 
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_DATA,
    async() => getContainerSizingReport(arg),
    result => {
      dispatch(actionMap.TABLE_DATA(result));
    },
    () => dispatch(actionMap.TABLE_DATA()),
  );

const containerSizingReportDuck = {
  thunks: { loadContainerSizingReport, loadContainerSizingTable },
  actionMap: ActionKey,
  actions: { reset: actionMap.RESET }
};

export default containerSizingReportDuck;
