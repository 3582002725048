import {
  getSessionFilterStores,
  setSessionFilterStores,
} from 'account/services/session';
import { LocationFilter, SiteIdentifier } from 'contracts/types/component';

export function setFilterStores(stores: SiteIdentifier[]): void {
  setSessionFilterStores(stores);
}

export function initFilter<T extends LocationFilter>(): T {
  const filter = {} as T;
  const stores = getSessionFilterStores();
  if (stores && stores.length > 0) {
    filter.stores = stores;
  }
  return filter;
}
