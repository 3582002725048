import { CategoryEntry, DateRange } from 'contracts/types/service';

export interface IDashboardReportResponse {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly oneYearDateRanges: DateRange[];
  readonly categoriesEntries: CategoryEntry[];
  readonly oneYearCategoriesEntries: CategoryEntry[];
  readonly entriesCount: number;
}

class DashboardReportResponse {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  oneYearDateRanges?: DateRange[];
  categoriesEntries?: CategoryEntry[];
  oneYearCategoriesEntries?: CategoryEntry[];
  entriesCount?: number;

  constructor(data?: Partial<IDashboardReportResponse>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.oneYearDateRanges = data?.oneYearDateRanges;
    this.categoriesEntries = data?.categoriesEntries;
    this.oneYearCategoriesEntries = data?.oneYearCategoriesEntries;
    this.entriesCount = data?.entriesCount;
  }
}

export default DashboardReportResponse;
