import decode from 'jwt-decode';
import moment from 'moment';

import { AccountRoutes } from 'account/routing';
import { DecodedClaims } from 'contracts/types/component';
import { WholesaleRoutes } from 'wholesale/routing';

import {
  destroySession,
  getSessionAuthToken,
  getSessionUser,
  setRedirectUrl,
} from './session';

let currentTimerId: NodeJS.Timeout | undefined;

export function clearCurrentTokenTimer(): void {
  if (currentTimerId) {
    clearTimeout(currentTimerId);
    currentTimerId = undefined;
  }
}

export async function initializeAndStartTokenTimer(): Promise<void> {
  clearCurrentTokenTimer();
  const authToken = getSessionAuthToken();

  if (authToken) {
    const authTokenExpirationInSeconds = getTokenExpirationInSeconds(authToken);
    const timerSleepInMilliseconds = getMillisecondsToSleep(
      moment.unix(authTokenExpirationInSeconds),
    );
    currentTimerId = setTimeout(executeTokenRefresh, timerSleepInMilliseconds);
  }
}

export function getTokenExpirationInSeconds(stringToken: string): number {
  return (decode(stringToken.replace('bearer ', '')) as DecodedClaims).exp;
}

function getMillisecondsToSleep(expirationDate: moment.Moment): number {
  const now = moment.utc();
  const duration = moment.duration(expirationDate.diff(now));
  let milliseconds = duration.asMilliseconds() - 1 * 60 * 1000; // one minute before expirtion
  if (milliseconds <= 0) {
    milliseconds = 10; // trigger it fast
  }
  return milliseconds;
}

async function executeTokenRefresh(): Promise<void> {
  const userData = getSessionUser();

  if (userData && userData.refreshToken) {
    const refreshTokenExpirationInSeconds = getTokenExpirationInSeconds(
      userData.refreshToken,
    );
    const refreshTokenExpires = moment.unix(refreshTokenExpirationInSeconds);
    const now = moment.utc();
    const duration = moment.duration(refreshTokenExpires.diff(now));
    const canRefresh = duration.asSeconds() <= 0;
    if (canRefresh) {
      if (
        window.location.href.indexOf(AccountRoutes.root + AccountRoutes.login) < 0 &&
        window.location.href.indexOf(WholesaleRoutes.root) < 0
      ) {
        setRedirectUrl(window.location.href);
        destroySession();
        window.location.href = AccountRoutes.root + AccountRoutes.login;
      }
    }
  }
}
