import axios, { AxiosResponseTransformer } from 'axios';

const getAxiosTransformers = (
  ...concatWithItems: AxiosResponseTransformer[]
): AxiosResponseTransformer[] => {
  const transformResponse = Array.isArray(axios.defaults.transformResponse)
    ? axios.defaults.transformResponse
    : axios.defaults.transformResponse
      ? [axios.defaults.transformResponse]
      : [];
  return transformResponse.concat(concatWithItems);
};

export default getAxiosTransformers;
