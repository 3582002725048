import React from 'react';

import Switch from 'react-switch';

import theme from '../styles/theme';

import SwitchLabel from './styled/SwitchButton';

const SwitchButton: React.FC<ComponentProps> = ({
  offLabel,
  onLabel,
  value,
  disabled,
  onChange,
}) => (
  <>
    {!!offLabel && <SwitchLabel disabled={value}>{offLabel}</SwitchLabel>}
    <Switch
      disabled={disabled}
      onChange={onChange}
      checked={value}
      onColor={theme.colors.brandPrimary}
      onHandleColor={theme.colors.brandWhite}
      offColor={theme.colors.brandPrimary}
      uncheckedIcon={false}
      checkedIcon={false}
      width={35}
      height={20}
    />
    {!!onLabel && <SwitchLabel disabled={!value}>{onLabel}</SwitchLabel>}
  </>
);

interface ComponentProps {
  offLabel?: string;
  onLabel?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (args: boolean) => void;
}

export default SwitchButton;
