import update from 'immutability-helper';

import { MediaContentItem } from 'contracts/models';
import {
  ActionDispatcher,
  RecognitionMediaContentAction,
} from 'contracts/types/action';
import {
  ApplicationState,
  RecognitionMediaContentState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getMediaContentData } from 'recognition/services/recognitionService';

// Actions Keys
const ROOT_KEY = 'recognition/media-content';
enum ActionKey {
  LOAD_DATA = 'recognition/media-content/LOAD_DATA',
  RESET = 'recognition/media-content/RESET',
}

// Initial state
const getInitialState: () => RecognitionMediaContentState = () => {
  return {
    data: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_DATA] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RecognitionMediaContentState,
  RecognitionMediaContentAction
> = {
  [ActionKey.LOAD_DATA]: (state, action) => {
    const { data } = action;
    return update(state, {
      $merge: {
        data,
      },
    });
  },
};

export const reducer = getReducerBuilder<
RecognitionMediaContentState,
RecognitionMediaContentAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_DATA: (
    data?: MediaContentItem[],
  ): RecognitionMediaContentAction => ({
    type: ActionKey.LOAD_DATA,
    data,
  }),
  RESET: (): RecognitionMediaContentAction => ({ type: ActionKey.RESET }),
};

// Thunks
const getData = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_DATA,
    () => getMediaContentData(),
    result => dispatch(actionMap.LOAD_DATA(result)),
    () => dispatch(actionMap.LOAD_DATA()),
  );

const recognitionMediaContentDuck = {
  thunks: {
    getData,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default recognitionMediaContentDuck;
