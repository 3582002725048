export interface IZuoraPayment {
  readonly paymentId: string;
  readonly paymentNumber: string;
  readonly referenceId: string;
  readonly paymentAmount?: number;
  readonly balance?: number;
  readonly paidFrom: ZuoraPaymentMethod;
  readonly paymentDate?: string;
  readonly isAutomaticPayment: boolean;
  readonly status: string;
  readonly gatewayResponse: string;
  readonly gatewayResponseCode: string;
  readonly warningMessage: string;
}

class ZuoraPayment {
  paymentId?: string;
  paymentNumber?: string;
  referenceId?: string;
  paymentAmount?: number;
  balance?: number;
  paidFrom?: ZuoraPaymentMethod;
  paymentDate?: string;
  isAutomaticPayment?: boolean;
  status?: string;
  gatewayResponse?: string;
  gatewayResponseCode?: string;
  warningMessage?: string;

  constructor(data?: Partial<IZuoraPayment>) {
    this.paymentId = data?.paymentId;
    this.paymentNumber = data?.paymentNumber;
    this.referenceId = data?.referenceId;
    this.paymentAmount = data?.paymentAmount;
    this.balance = data?.balance;
    this.paidFrom = data?.paidFrom;
    this.paymentDate = data?.paymentDate;
    this.isAutomaticPayment = data?.isAutomaticPayment;
    this.status = data?.status;
    this.gatewayResponse = data?.gatewayResponse;
    this.gatewayResponseCode = data?.gatewayResponseCode;
    this.warningMessage = data?.warningMessage;
  }
}

export interface ZuoraPaymentMethod {
  accountId: string;
  accountNumber: string;
  accountAutoPay?: boolean;
  paymentMethodId: string;
  isCreditCard: boolean;
  creditCardMarkNumber: string;
  creditCardHolderName: string;
  creditCardType: string;
  creditCardExpirationMonth?: number;
  creditCardExpirationYear?: number;
  creditCardPostalCode: string;
  achAccountNumberMask: string;
  achAccountName: string;
  achAccountType: string;
  achBankName: string;
  achAddress1: string;
  achAddress2: string;
  creditCardAddress1: string;
  creditCardAddress2: string;
  creditCardCity: string;
  creditCardCountry: string;
  creditCardState: string;
  paymentType: string;
  achAbaCode: string;
  isDefault: boolean;
  defaultPaymentId: string;
  isCheck: boolean;
  isEditCreditCardAutopayEditable: boolean;
}

export interface ZuoraHostPageRequestDataView {
  profile: string | null;
  type: string;
}

export interface ZuoraHostPageWithSignatureRequestDataView extends ZuoraHostPageRequestDataView {
  token: string;
}

export interface ZuoraHostPageResponseDataView {
  id: string;
  signature: string;
  token: string;
  tenantId: string;
  key: string;
  success: boolean;
  url: string;
}

export default ZuoraPayment;
