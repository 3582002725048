import { QuickpayInvoiceDetails } from 'contracts/models';
import CustomerUserSite, {
  ICustomerUserSite,
} from 'contracts/models/service/CustomerUserSite';
import EcoBillStatus, {
  IEcoBillStatus,
} from 'contracts/models/service/EcoBillStatus';
import Invoice, { IInvoice } from 'contracts/models/service/Invoice';
import LocationBalance, {
  ILocationBalance,
} from 'contracts/models/service/LocationBalance';
import LocationPaymentInfo, {
  ILocationPaymentInfo,
} from 'contracts/models/service/LocationPaymentInfo';
import Payment, { IPayment } from 'contracts/models/service/Payment';
import PaymentItemModel, { IPaymentItemModel } from 'contracts/models/service/PaymentItemModel';
import { IQuickpayInvoiceDetails } from 'contracts/models/service/QuickpayInvoiceDetails';
import { PaginationdRequest } from 'contracts/types/request';
import { GetBlobResponse } from 'contracts/types/service';
import { getBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getPayments({ pageNumber, pageSize }: PaginationdRequest): Promise<Payment[]> {
  const response = await httpCustomerCore.get<Payment[]>(`payments?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
    transformResponse: transformServiceResponse<IPayment, Payment>(Payment),
  });
  return response.data;
}

export async function getGroupedPayments({ pageNumber, pageSize }: PaginationdRequest): Promise<PaymentItemModel[]> {
  const response = await httpCustomerCore.get<PaymentItemModel[]>(`payments/grouped?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
    transformResponse: transformServiceResponse<IPaymentItemModel, PaymentItemModel>(PaymentItemModel),
  });
  return response.data;
}

export async function getInvoices({ pageNumber, pageSize }: PaginationdRequest): Promise<Invoice[]> {
  const response = await httpCustomerCore.get<Invoice[]>(`invoices?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
    transformResponse: transformServiceResponse<IInvoice, Invoice>(Invoice),
  });
  return response.data;
}

export async function getOutstandingBalance(): Promise<number> {
  const response = await httpCustomerCore.get<number>(
    'billing/outstanding-balance',
  );
  return response.data;
}

export async function getLocations(): Promise<CustomerUserSite[]> {
  const response = await httpCustomerCore.get<CustomerUserSite[]>(
    'sites/user',
    {
      transformResponse: transformServiceResponse<
        ICustomerUserSite,
        CustomerUserSite
      >(CustomerUserSite),
    },
  );
  return response.data;
}

export async function getEcoBillingStatus(): Promise<EcoBillStatus> {
  const response = await httpCustomerCore.get<EcoBillStatus>(
    'billing/eco-bill',
    {
      transformResponse: transformServiceResponse<
        IEcoBillStatus,
        EcoBillStatus
      >(EcoBillStatus),
    },
  );
  return response.data;
}

export async function getLocationsPaymentInfo(): Promise<
  LocationPaymentInfo[]
  > {
  const response = await httpCustomerCore.get<LocationPaymentInfo[]>(
    'billing/location-info',
    {
      transformResponse: transformServiceResponse<
        ILocationPaymentInfo,
        LocationPaymentInfo
      >(LocationPaymentInfo),
    },
  );
  return response.data;
}

export async function getPaymentLocationsBalances(): Promise<
  LocationBalance[]
  > {
  const response = await httpCustomerCore.get<LocationBalance[]>(
    'billing/locations-balance',
    {
      transformResponse: transformServiceResponse<
        ILocationBalance,
        LocationBalance
      >(LocationBalance),
    },
  );
  return response.data;
}

export async function getOpenInvoices(
  custId: string,
  siteId: string,
): Promise<Invoice[]> {
  const response = await httpCustomerCore.get<Invoice[]>(
    `invoices/open?customerId=${custId}&siteId=${siteId}`,
    {
      transformResponse: transformServiceResponse<IInvoice, Invoice>(Invoice),
    },
  );
  return response.data;
}

export async function getPaymentMethodInfoBySite(
  custId: string,
  siteId: string,
): Promise<LocationPaymentInfo[]> {
  const response = await httpCustomerCore.get<LocationPaymentInfo[]>(
    `billing/location-info?custId=${custId}&siteId=${siteId}`,
    {
      transformResponse: transformServiceResponse<
        ILocationPaymentInfo,
        LocationPaymentInfo
      >(LocationPaymentInfo),
    },
  );
  return response.data;
}

export async function getDecryptedInvoiceDetails(
  encryptedStatementId: string,
): Promise<QuickpayInvoiceDetails> {
  const response = await httpCustomerCore.get<QuickpayInvoiceDetails>(
    `invoice/quick-pay?q=${encryptedStatementId}`,
    {
      transformResponse: transformServiceResponse<
        IQuickpayInvoiceDetails,
        QuickpayInvoiceDetails
      >(QuickpayInvoiceDetails),
    },
  );
  return response.data;
}

export async function downloadInvoice(
  invoiceNumber: string,
): Promise<GetBlobResponse> {
  const urlPath = `invoice/download-pdf/${invoiceNumber}`;
  const invoiceDocument = await getBlobFile(httpCustomerCore, urlPath);
  return invoiceDocument;
}

export async function downloadHaulerInvoice(
  invoiceId: number,
): Promise<GetBlobResponse> {
  const urlPath = `invoice/download-hauler-pdf/${invoiceId}`;
  const invoiceDocument = await getBlobFile(httpCustomerCore, urlPath);
  return invoiceDocument;
}

export async function downloadHaulerInvoiceByHashId(
  hashId: string,
): Promise<GetBlobResponse> {
  const urlPath = `invoice/download-hauler-pdf/hash/${hashId}`;
  const invoiceDocument = await getBlobFile(httpCustomerCore, urlPath);
  return invoiceDocument;
}

export async function getHaulerInvoicePreview(
  hashId: string,
): Promise<{ url: string }> {
  const response = await httpCustomerCore.get(`invoice/preview-hauler-pdf/${hashId}`);
  return response.data;
}

export async function editBillingEmail(
  custId: string, siteId: string, billingEmail: string
): Promise<void> {
  const response = await httpCustomerCore.put<void>(
    '/billing/billing-details',
    {
      custId,
      siteId,
      billingEmail
    },
    
  );
  return response.data;
}
