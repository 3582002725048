export interface IServiceAvailability {
  readonly available: boolean;
  readonly restriction: string;
  readonly duplicateDate: string;
  readonly isRequestBlocked: boolean;
}

class ServiceAvailability {
  available?: boolean;
  restriction?: string;
  duplicateDate?: string;
  isRequestBlocked?: boolean;

  constructor(data?: Partial<IServiceAvailability>) {
    this.available = data?.available;
    this.restriction = data?.restriction;
    this.duplicateDate = data?.duplicateDate;
    this.isRequestBlocked = data?.isRequestBlocked;
  }
}

export default ServiceAvailability;
