import { ServiceRequestsOpenReportDetails } from './ServiceRequestsOpenReport';
import { SiteProfileServiceAction } from './SiteProfile';

interface IWorkOrderNoteModel {
  readonly orderNumber: number;
  readonly noteType: string;
  readonly noteDate: Date;
  readonly user: string;
  readonly userNote: string;
  readonly header: string;
  readonly visibleToCustomer: boolean;
  readonly visibleToHauler: boolean;
  readonly isUrgent: boolean;
}

export interface IServiceRequestDetails {
  readonly details: ServiceRequestsOpenReportDetails;
  readonly notes: IWorkOrderNoteModel[];
  readonly actions: SiteProfileServiceAction[];
}

class ServiceRequestDetails {
  details?: ServiceRequestsOpenReportDetails;
  notes?: IWorkOrderNoteModel[];
  actions?: SiteProfileServiceAction[];
  constructor(data?: IServiceRequestDetails) {
    this.details = data?.details;
    this.notes = data?.notes;
    this.actions = data?.actions;
  }
}

export default ServiceRequestDetails;
