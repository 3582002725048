/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import HmacSignatureResponse, {
  IHmacSignatureResponse,
} from 'contracts/models/service/HmacSignatureResponse';
import ZuoraPayment, {
  IZuoraPayment,
  ZuoraHostPageRequestDataView,
  ZuoraHostPageResponseDataView,
  ZuoraHostPageWithSignatureRequestDataView,
  ZuoraPaymentMethod,
} from 'contracts/models/service/ZuoraPayment';
import { CreditCardFormData, UpdatePaymentMethodFormData } from 'contracts/types/form';
import { ZuoraPaynowRequestArg } from 'contracts/types/request';
import { apiBaseURLs } from 'core/services/environment';
import {
  httpCustomerCore,
  httpZuora,
  transformServiceResponse,
} from 'core/services/http';

export async function getCancellationPaymentFormData(request: ZuoraHostPageWithSignatureRequestDataView): 
  Promise<ZuoraHostPageResponseDataView> {
  const response = await httpCustomerCore.post<ZuoraHostPageResponseDataView>('payment/cancellation/formdata', request);
  return response.data;
}

export async function getQuickpayPaymentFormData(request: ZuoraHostPageWithSignatureRequestDataView): 
  Promise<ZuoraHostPageResponseDataView> {
  const response = await httpCustomerCore.post<ZuoraHostPageResponseDataView>('payment/quickpay/formdata', request);
  return response.data;
}

export async function getBillingPaymentFormData(request: ZuoraHostPageRequestDataView): 
  Promise<ZuoraHostPageResponseDataView> {
  const response = await httpCustomerCore.post<ZuoraHostPageResponseDataView>('payment/billing/formdata', request);
  return response.data;
}
  
// TODO: method is very wrong, please check usages in frontend!!!
export async function payNow(
  request: ZuoraPaynowRequestArg,
): Promise<ZuoraPayment> {
  const response = await httpCustomerCore.post<ZuoraPayment>(
    'zuora/paynow',
    {
      AccountId: request.accountId,
      PaymentMethodId: request.paymentMethodId,
      InvoicesToPay: request.invoicesToPay,
      QuickPay: request.quickPay,
      SiteId: request.siteId,
    },
    {
      transformResponse: transformServiceResponse<IZuoraPayment, ZuoraPayment>(
        ZuoraPayment,
      ),
    },
  );
  return response.data;
}

export async function postCreditCard(
  token: string | undefined,
  signature: string | undefined,
  params: CreditCardFormData,
  accountKey: string,
): Promise<any> {
  const data = {
    accountKey,
    creditCardType: params.creditCardType,
    creditCardNumber: params.cardNumber,
    expirationMonth: params.expDate.split('/')[0],
    expirationYear: params.expDate.split('/')[1],
    defaultPaymentMethod: params.defaultPaymentMethod,
    securityCode: params.securityCode,
    cardHolderInfo: {
      addressLine1: params.streetAddress,
      addressLine2: params.aptNumber,
      zipCode: params.zip,
      state: params.state,
      cardHolderName: params.cardHolderName,
      city: params.city,
    },
  };

  httpZuora.defaults.headers.post.token = token as string;
  httpZuora.defaults.headers.post.signature = signature as string;

  const response = await httpZuora.post('/payment-methods/credit-cards', data);

  return response;
}

export async function getInvoicesToPayNow(accountId: string): Promise<any> {
  const response = await httpCustomerCore.get(`zuora/invoices/${accountId}`);
  return response.data;
}

export async function getPaymentMethods(accountId: string): Promise<any> {
  const response = await httpCustomerCore.get(
    `zuora/payment-methods?accountId=${accountId}`,
  );
  return response.data;
}

export async function getPaymentMethodDetails(
  paymentMethodId: string,
): Promise<ZuoraPaymentMethod> {
  const response = await httpCustomerCore.get<ZuoraPaymentMethod>(
    `zuora/payment-method/${paymentMethodId}`,
  );
  return response.data;
}

export async function updatePaymentMethod(
  paymentMethodId: any,
  paymentType: any,
  params: UpdatePaymentMethodFormData,
): Promise<AxiosResponse<any>> {
  const updateParams: any = {
    AchAbaCode: params.achAbaCode,
    AchAccountName: params.achAccountName,
    AchAccountType: params.achAccountType,
    AchBankName: params.achBankName,
    PaymentType: paymentType,
    addressLine1: params.creditCardAddress1,
    addressLine2: params.creditCardAddress2,
    cardHolderName: params.creditCardHolderName,
    city: params.creditCardCity,
    defaultPaymentMethod: false,
    expirationMonth:
      params.expDate.split('/')[0] === 'null'
        ? 0
        : params.expDate.split('/')[0], // params.expirationMonth,
    expirationYear:
      params.expDate.split('/')[1] === 'null'
        ? 0
        : params.expDate.split('/')[1], // params.expirationYear,
    state: params.creditCardState,
    zipCode: params.creditCardPostalCode,
  };
  if (params.securityCode !== '***') {
    updateParams.securityCode = params.securityCode;
  }
  return httpCustomerCore.put(
    `zuora/payment-method/${paymentMethodId}`,
    updateParams,
  );
}

export async function getPayment(
  accountId: string,
  paymentId: string,
): Promise<any> {
  const response = await httpCustomerCore.get(
    `zuora/payment?accountId=${accountId}&paymentId=${paymentId}`,
  );
  return response.data;
}

export async function updateDefaultPaymentMethod(
  siteId: string,
  custId: string,
  paymentMethodId: string,
  autoPay: boolean,
): Promise<AxiosResponse<any>> {
  return httpCustomerCore.put('billing/site-payment-details', {
    custId,
    siteId,
    defaultPaymentMethodId: paymentMethodId,
    autoPay
  });
}

export async function setAutoPayForAccount(
  isAutoPay: boolean,
  accountId: string,
): Promise<AxiosResponse<any>> {
  return httpCustomerCore.put('zuora/autopay', {
    AutoPay: isAutoPay,
    ZuoraAccountId: accountId,
  });
}

export async function getHMACSignature(
  accountKey: string,
): Promise<AxiosResponse<HmacSignatureResponse>> {
  return httpCustomerCore.post<HmacSignatureResponse>(
    'zuora/hmac-signature',
    {
      uri: `${apiBaseURLs.zuora}/payment-methods/credit-cards`,
      method: 'POST',
      accountKey,
    },
    {
      transformResponse: transformServiceResponse<
        IHmacSignatureResponse,
        HmacSignatureResponse
      >(HmacSignatureResponse),
    },
  );
}
export async function getPaymentMethodType(
  paymentMethodId: string,
): Promise<string> {
  const response = await getPaymentMethodDetails(paymentMethodId);
  return response.paymentType;
}
