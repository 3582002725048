import update from 'immutability-helper';

import {
  InvoiceComparisonInfo,
  InvoiceSummaryInfo,
  InvoiceTableData,
} from 'contracts/models';
import {
  ActionDispatcher,
  FinancialInvoiceDetailsAction,
} from 'contracts/types/action';
import { InvoiceDetailsRequest } from 'contracts/types/request';
import {
  ApplicationState,
  FinancialInvoiceDetailsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getInvoiceDetailsComparison,
  getInvoiceDetailsSummary,
  getInvoiceDetailsTableData,
} from 'financial/services/invoiceDetailsServices';

// Actions Keys
const ROOT_KEY = 'financial/invoiceDetails';
enum ActionKey {
  LOAD_SUMMARY = 'financial/invoiceDetails/LOAD_SUMMARY',
  LOAD_COMPARISON = 'financial/invoiceDetails/LOAD_COMPARISON',
  LOAD_TABLE = 'financial/invoiceDetails/LOAD_TABLE',
  RESET = 'financial/invoiceDetails/RESET',
}

// Initial State
const getInitialState: () => FinancialInvoiceDetailsState = () => {
  return {
    summaryInfo: {},
    comparisonInfo: {},
    tableData: [],
    level: 1,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SUMMARY,
  ActionKey.LOAD_COMPARISON,
  ActionKey.LOAD_TABLE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FinancialInvoiceDetailsState,
  FinancialInvoiceDetailsAction
> = {
  [ActionKey.LOAD_SUMMARY]: (state, action) => {
    const { summaryInfo } = action;
    return update(state, { $merge: { summaryInfo } });
  },
  [ActionKey.LOAD_COMPARISON]: (state, action) => {
    const { comparisonInfo } = action;
    return update(state, { $merge: { comparisonInfo } });
  },
  [ActionKey.LOAD_TABLE]: (state, action) => {
    const { tableData } = action;
    return update(state, { $merge: { tableData } });
  },
};

export const reducer = getReducerBuilder<
  FinancialInvoiceDetailsState,
  FinancialInvoiceDetailsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SUMMARY: (
    summaryInfo?: InvoiceSummaryInfo,
  ): FinancialInvoiceDetailsAction => ({
    type: ActionKey.LOAD_SUMMARY,
    summaryInfo,
  }),
  LOAD_COMPARISON: (
    comparisonInfo?: InvoiceComparisonInfo,
  ): FinancialInvoiceDetailsAction => ({
    type: ActionKey.LOAD_COMPARISON,
    comparisonInfo,
  }),
  LOAD_TABLE: (
    tableData?: InvoiceTableData[],
  ): FinancialInvoiceDetailsAction => ({
    type: ActionKey.LOAD_TABLE,
    tableData,
  }),
  RESET: (): FinancialInvoiceDetailsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadSummary = (arg: InvoiceDetailsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY,
    async() => getInvoiceDetailsSummary(arg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY(result));
    },
    () => dispatch(actionMap.LOAD_SUMMARY()),
  );

const loadComparison = (arg: InvoiceDetailsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON,
    async() => getInvoiceDetailsComparison(arg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON(result));
    },
    () => dispatch(actionMap.LOAD_COMPARISON()),
  );

const loadTable = (arg: InvoiceDetailsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE,
    async() => getInvoiceDetailsTableData(arg),
    result => {
      dispatch(actionMap.LOAD_TABLE(result));
    },
    () => dispatch(actionMap.LOAD_TABLE()),
  );

const invoiceDetailsRequestsDuck = {
  thunks: { loadComparison, loadSummary, loadTable },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default invoiceDetailsRequestsDuck;
