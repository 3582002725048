export interface IReportBi {
  readonly id: number;
  readonly custId: string;
  readonly crtdDateTime: string;
  readonly name: string;
  readonly url: string;
  readonly powerBiDataSetId: string;
  readonly embedUrl: string;
  readonly reportId: string;
  readonly token: string;
}

class ReportBi {
  id?: number;
  custId?: string;
  crtdDateTime?: string;
  name?: string;
  url?: string;
  powerBiDataSetId?: string;
  embedUrl?: string;
  reportId?: string;
  token?: string;

  constructor(data?: Partial<IReportBi>) {
    this.id = data?.id;
    this.custId = data?.custId;
    this.crtdDateTime = data?.crtdDateTime;
    this.name = data?.name;
    this.url = data?.url;
    this.powerBiDataSetId = data?.powerBiDataSetId;
    this.embedUrl = data?.embedUrl;
    this.reportId = data?.reportId;
    this.token = data?.token;
  }
}

export default ReportBi;
