import update from 'immutability-helper';

import {
  MaterialSpendComparison,
  MaterialSpendOverview,
  MaterialSpendTable,
} from 'contracts/models';
import {
  ActionDispatcher,
  FinancialSpendByMaterialAction,
} from 'contracts/types/action';
import { SpendByMaterialRequest } from 'contracts/types/request';
import {
  ApplicationState,
  FinancialSpendByMaterialState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getSpendByMaterialComparison,
  getSpendByMaterialSummary,
  getSpendByMaterialTableData,
} from 'financial/services/spendByMaterialService';

// Actions Keys
const ROOT_KEY = 'financial/spendByMaterial';
enum ActionKey {
  SUMMARY = 'financial/spendByMaterial/SUMMARY',
  COMPARISON = 'financial/spendByMaterial/COMPARISON',
  TABLE_DATA = 'financial/spendByMaterial/TABLE_DATA',
  RESET = 'financial/spendByMaterial/RESET',
}

// Initial State
const getInitialState: () => FinancialSpendByMaterialState = () => {
  return {
    summary: {},
    comparison: {},
    tableData: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.SUMMARY,
  ActionKey.COMPARISON,
  ActionKey.TABLE_DATA,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FinancialSpendByMaterialState,
  FinancialSpendByMaterialAction
> = {
  [ActionKey.SUMMARY]: (state, action) => {
    const { summary } = action;
    return update(state, { $merge: { summary } });
  },
  [ActionKey.COMPARISON]: (state, action) => {
    const { comparison } = action;
    return update(state, { $merge: { comparison } });
  },
  [ActionKey.TABLE_DATA]: (state, action) => {
    const { tableData } = action;
    return update(state, { $merge: { tableData } });
  },
};

export const reducer = getReducerBuilder<
  FinancialSpendByMaterialState,
  FinancialSpendByMaterialAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  SUMMARY: (
    summary?: MaterialSpendOverview,
  ): FinancialSpendByMaterialAction => ({
    type: ActionKey.SUMMARY,
    summary,
  }),
  COMPARISON: (
    comparison?: MaterialSpendComparison,
  ): FinancialSpendByMaterialAction => ({
    type: ActionKey.COMPARISON,
    comparison,
  }),
  TABLE_DATA: (
    tableData?: MaterialSpendTable,
  ): FinancialSpendByMaterialAction => ({
    type: ActionKey.TABLE_DATA,
    tableData,
  }),
  RESET: (): FinancialSpendByMaterialAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadSummary = (arg: SpendByMaterialRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SUMMARY,
    async() => getSpendByMaterialSummary(arg),
    result => {
      dispatch(actionMap.SUMMARY(result));
    },
    () => dispatch(actionMap.SUMMARY()),
  );

const loadComparison = (arg: SpendByMaterialRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.COMPARISON,
    async() => getSpendByMaterialComparison(arg),
    result => {
      dispatch(actionMap.COMPARISON(result));
    },
    () => dispatch(actionMap.COMPARISON()),
  );

const loadTableData = (arg: SpendByMaterialRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_DATA,
    async() => getSpendByMaterialTableData(arg),
    result => {
      dispatch(actionMap.TABLE_DATA(result));
    },
    () => dispatch(actionMap.TABLE_DATA()),
  );

const resetSpendByMaterial = () => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.RESET());
};

const spendByMaterialDuck = {
  thunks: { loadComparison, loadSummary, loadTableData, resetSpendByMaterial },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default spendByMaterialDuck;
