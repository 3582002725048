import OnDemandPickUpsUpdateSortKey from 'contracts/enums/OnDemandPickUpsUpdateSortKey';
import { OnDemandPickUpsResponse } from 'contracts/models/service/OnDemandPickUp';
import OnDemandPickUpDropdownOptions, { IOnDemandPickUpDropdownOptions } from 'contracts/models/service/OnDemandPickUpDropdownOptions';
import OnDemandPickUpOptions, { IOnDemandPickUpOptions } from 'contracts/models/service/OnDemandPickUpOptions';
import { BulkUploadTemplateType, 
  GetOnDemandPickUpsRequest,
  GetOnDemandPickUpOptionsRequest, 
  OnDemandPickUpRequest,
  OnDemandPickUpUpdateRequest,
  CheckOnDemandPickUpsUpdateRequest
} from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getOnDemandPickUps(request: GetOnDemandPickUpsRequest): Promise<OnDemandPickUpsResponse> {
  const response = await httpCustomerCore.post<OnDemandPickUpsResponse>(
    `self-managed/on-demand/get-pickups`, {
      orderByAscending:
      request && request.ascending
        ? buildSortArg(request.ascending, OnDemandPickUpsUpdateSortKey)
        : {},
      orderByDescending:
      request && request.descending
        ? buildSortArg(request.descending, OnDemandPickUpsUpdateSortKey)
        : {},
      storeIds: request.storeIds || [],
      pageNumber: request.pageNumber,
      pageSize: request.pageSize,
    },
  );
  return response.data;
}

export async function downloadOnDemandPickUpsCSV(request: GetOnDemandPickUpsRequest): Promise<GetCsvResponse> {
  const urlPath = `self-managed/on-demand/get-pickups-csv`;
  const reportDocument = getCsv(httpCustomerCore, urlPath, {
    orderByAscending:
    request && request.ascending
      ? buildSortArg(request.ascending, OnDemandPickUpsUpdateSortKey)
      : {},
    orderByDescending:
    request && request.descending
      ? buildSortArg(request.descending, OnDemandPickUpsUpdateSortKey)
      : {},
    storeIds: request.storeIds || [],
  });
  return reportDocument;
}

export const getOnDemandPickUpOptions = async(req: GetOnDemandPickUpOptionsRequest): 
Promise<OnDemandPickUpOptions> => {
  const response = await httpCustomerCore.get<OnDemandPickUpOptions>(
    `/self-managed/on-demand/get-options?isNewPickup=${req.isNewPickup}`,
    {
      transformResponse: transformServiceResponse<
        IOnDemandPickUpOptions,
        OnDemandPickUpOptions
      >(OnDemandPickUpOptions),
    },
  );
  return response.data;
};

export const getOnDemandDropdownOptions = async(
  serviceType: BulkUploadTemplateType): Promise<OnDemandPickUpDropdownOptions> => {
  const response = await httpCustomerCore.post<OnDemandPickUpDropdownOptions>(
    'self-managed/get-dropdown-options',
    {
      uploadType: serviceType
    },
    {
      transformResponse: transformServiceResponse<
        IOnDemandPickUpDropdownOptions,
        OnDemandPickUpDropdownOptions
      >(OnDemandPickUpDropdownOptions),
    },
  );
  return response.data;
};

export async function createOnDemandPickUp(req: OnDemandPickUpRequest): Promise<void> {
  const response = await httpCustomerCore.post<void>(
    `/self-managed/on-demand/create-pickup`, {
      ...req
    },
  );
  return response.data;
}

export async function updateOnDemandPickUp(req: OnDemandPickUpRequest): Promise<void> {
  const response = await httpCustomerCore.post<void>(
    `/self-managed/on-demand/update-pickup`, {
      ...req
    },
  );
  return response.data;
}

export async function checkUpdateOnDemandPickUp(req: OnDemandPickUpUpdateRequest): 
Promise<CheckOnDemandPickUpsUpdateRequest> {
  const response = await httpCustomerCore.post<CheckOnDemandPickUpsUpdateRequest>(
    `/self-managed/on-demand/update-pickup-check`, {
      ...req
    },
  );
  return response.data;
}

