import ApiKeyPageOptions from 'contracts/models/service/ApiKeyPageOptions';
import ApiKeyTableRow from 'contracts/models/service/KeyManagement';
import { httpCustomerCore } from 'core/services/http';

export async function getKeyManagementTableData(): Promise<ApiKeyTableRow[]> {
  const response = await httpCustomerCore.get<ApiKeyTableRow[]>(
    `apiKey/table`
  );
  return response.data;
}

export async function generateApiKey(): Promise<ApiKeyTableRow> {
  const response = await httpCustomerCore.post(
    `apiKey/upload`
  );
  return response.data;
}

export async function deleteApiKey(apiKeyId: number): Promise<void> {
  await httpCustomerCore.delete<void>(
    `apiKey/${apiKeyId}`
  );
}

export async function unveilApiKey(id: number): Promise<ApiKeyTableRow> {
  const response = await httpCustomerCore.get(
    `apiKey/${id}`
  );
  return response.data;
}

export async function loadPageOptions(): Promise<ApiKeyPageOptions> {
  const response = await httpCustomerCore.get(
    `apiKey/options`
  );
  return response.data;
}
