import update from 'immutability-helper';

import { CustomerDocument, SiteProfileNote } from 'contracts/models';
import { SiteIdentifier } from 'contracts/types/component';
import {
  ActionDispatcher,
  PremierSiteProfileAction,
} from 'contracts/types/PremierSiteProfile/action';
import { PremierSiteProfileState } from 'contracts/types/PremierSiteProfile/state';
import { ApplicationState, ReduceFunctionMap } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getSiteFiles, getSiteNotes } from './services';
import { CustomerDocumentPaginationArg } from 'contracts/types/request';

// Actions
const ROOT_KEY = 'services/premierSiteProfile';
enum ActionKey {
  LOAD_SITE_FILES = 'services/premierSiteProfile/LOAD_SITE_FILES',
  LOAD_SITE_NOTES = 'services/premierSiteProfile/LOAD_SITE_NOTES',
  RESET = 'services/premierSiteProfile/RESET',
}

// Initial state
const getInitialState: () => PremierSiteProfileState = () => {
  return {
    notes: [],
    files: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SITE_FILES,
  ActionKey.LOAD_SITE_NOTES,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  PremierSiteProfileState,
  PremierSiteProfileAction
> = {
  [ActionKey.LOAD_SITE_FILES]: (state, action) => {
    const { files } = action;
    if (!files) {
      return state;
    }
    return update(state, { $merge: { files } });
  },
  [ActionKey.LOAD_SITE_NOTES]: (state, action) => {
    const { notes } = action;
    if (!notes) {
      return state;
    }
    return update(state, { $merge: { notes } });
  },
};

export const reducer = getReducerBuilder<
  PremierSiteProfileState,
  PremierSiteProfileAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action creators
const actionMap = {
  LOAD_SITE_FILES: (files?: CustomerDocument[]): PremierSiteProfileAction => ({
    type: ActionKey.LOAD_SITE_FILES,
    files,
  }),
  LOAD_SITE_NOTES: (notes?: SiteProfileNote[]): PremierSiteProfileAction => ({
    type: ActionKey.LOAD_SITE_NOTES,
    notes,
  }),
  RESET: (): PremierSiteProfileAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadSiteFiles = (params: CustomerDocumentPaginationArg) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_FILES,
    async() => getSiteFiles(params),
    result => {
      dispatch(actionMap.LOAD_SITE_FILES(result));
    },
    () => {
      dispatch(actionMap.LOAD_SITE_FILES());
    },
    true,
  );

const loadSiteNotes = (params: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_NOTES,
    async() => getSiteNotes(params),
    result => {
      dispatch(actionMap.LOAD_SITE_NOTES(result));
    },
    () => {
      dispatch(actionMap.LOAD_SITE_NOTES());
    },
    true,
  );

const duck = {
  thunks: { loadSiteFiles, loadSiteNotes },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default duck;
