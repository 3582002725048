import update from 'immutability-helper';

import * as account from 'account/services/account';
import {
  getCustomerPhone as getCustomerPhoneService,
  updateAccountUser,
  updatePhone as updatePhoneService
} from 'account/services/userService';
import { NotificationType } from 'contracts/enums';
import { AccountDetailsAction, ActionDispatcher } from 'contracts/types/action';
import { EditUserFormData } from 'contracts/types/form';
import { UpdateUserRequest } from 'contracts/types/request';
import {
  AccountDetails, ApplicationState,

  ReduceFunctionMap
} from 'contracts/types/state';
import {
  createNotificationMessage,
  createTimedNotificationMessage
} from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// TODO: THIS NEEDS A LOT OF REFACTORING
const ROOT_KEY = 'account/password';

enum ActionKey {
  CHANGE_PASSWORD = 'account/password/CHANGE_PASSWORD',
  CHANGE_USER_PASSWORD = 'account/password/CHANGE_USER_PASSWORD',
  ADD_PHONE = 'account/password/ADD_PHONE',
  FETCH_PHONE = 'account/password/FETCH_PHONE',
  UPDATE_USER = 'account/password/UPDATE_USER',
  REQUEST_PASSWORD_RESET = 'account/password/REQUEST_PASSWORD_RESET',
  PASSWORD_RESET = 'account/password/PASSWORD_RESET',
  VALIDATE_TOKEN = 'account/password/VALIDATE_TOKEN',
  RESET = 'account/password/RESET',
}

// Initial State
const getInitialState: () => AccountDetails = () => {
  return {
    phone: undefined,
    isTokenValid: undefined,
    errorMessage: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.FETCH_PHONE,
  ActionKey.VALIDATE_TOKEN,
  ActionKey.CHANGE_PASSWORD,
  ActionKey.CHANGE_USER_PASSWORD,
  ActionKey.PASSWORD_RESET,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AccountDetails,
  AccountDetailsAction
> = {
  [ActionKey.FETCH_PHONE]: (state: AccountDetails, action: AccountDetailsAction) => {
    return update(state, { $merge: { phone: action.phone } });
  },
  [ActionKey.VALIDATE_TOKEN]: (state: AccountDetails, action) => {
    return update(state, { $merge: { isTokenValid: action.isTokenValid } });
  },
  [ActionKey.CHANGE_PASSWORD]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
  [ActionKey.CHANGE_USER_PASSWORD]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
  [ActionKey.PASSWORD_RESET]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
};

export const reducer = getReducerBuilder<AccountDetails, AccountDetailsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  FETCH_PHONE: (phone?: string): AccountDetailsAction => ({
    type: ActionKey.FETCH_PHONE,
    phone,
  }),
  VALIDATE_TOKEN: (isTokenValid?: boolean): AccountDetailsAction => ({
    type: ActionKey.VALIDATE_TOKEN,
    isTokenValid,
  }),
  CHANGE_PASSWORD: (errorMessage?: string): AccountDetailsAction => ({
    type: ActionKey.CHANGE_PASSWORD,
    errorMessage,
  }),
  CHANGE_USER_PASSWORD: (errorMessage?: string): AccountDetailsAction => ({
    type: ActionKey.CHANGE_USER_PASSWORD,
    errorMessage,
  }),
  PASSWORD_RESET: (errorMessage?: string): AccountDetailsAction => ({
    type: ActionKey.PASSWORD_RESET,
    errorMessage,
  }),
  RESET: (): AccountDetailsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const changePassword = (
  userName: string,
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CHANGE_PASSWORD,
    () =>
      account.changePassword(
        password,
        passwordConfirmation,
        oldPassword,
        userName,
      ),
    () => {
      dispatch(actionMap.CHANGE_PASSWORD());
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          translate('account.passwordChangedSuccess'),
          3000,
        ),
      );
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.CHANGE_PASSWORD(errorMessage));
    },
  );

const changeUserPassword = (
  userName: string,
  password: string,
  passwordConfirmation: string
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CHANGE_USER_PASSWORD,
    () => account.changeUserPassword(password, passwordConfirmation, userName),
    () => {
      dispatch(actionMap.CHANGE_USER_PASSWORD());
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          translate('account.passwordChangedSuccess'),
          3000,
        ),
      );
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.CHANGE_USER_PASSWORD(errorMessage));
    },
  );

const updatePhone = (phoneNumber: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ADD_PHONE,
    () => updatePhoneService(phoneNumber),
    () => {},
    () => {},
    true,
  );

const getCustomerPhone = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.FETCH_PHONE,
    () => getCustomerPhoneService(),
    phone => {
      dispatch(actionMap.FETCH_PHONE(phone));
    },
    () => {
      dispatch(actionMap.FETCH_PHONE());
    },
    true,
  );

const saveChanges = (
  userName: string,
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
  params: UpdateUserRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CHANGE_PASSWORD,
    () =>
      account.changePassword(
        password,
        passwordConfirmation,
        oldPassword,
        userName,
      ),
    () => {
      return runTakeLastThunk(
        dispatch,
        getState,
        ActionKey.ADD_PHONE,
        () => updatePhoneService(params.phone),
        () => {},
        () => {},
        true,
      );
    },
    () => {},
    true,
  );

const updateUserInfo = (
  submittedValues: EditUserFormData,
  userId: number
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) => {
  const params: UpdateUserRequest = {
    firstName: submittedValues.firstName,
    lastName: submittedValues.lastName,
    phone: submittedValues.phoneNumber,
    role: submittedValues.role as string,
  };
  return runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_USER,
    () => updateAccountUser(userId, params),
    () => {
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          translate('account.userUpdatedSuccess'),
          3000,
        ),
      );
    },
    () => {},
    true,
  );
};

const requestPasswordReset = (email: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REQUEST_PASSWORD_RESET,
    async() => account.requestPasswordReset(email),
    () => {},
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('account.emailNotPresentInDB');
      dispatch(
        createNotificationMessage(
          NotificationType.Error,
          errorMessage,
        ),
      );
    },
    false,
  );

export const resetPassword = (
  token: string,
  email: string,
  password: string,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.PASSWORD_RESET,
    () => account.resetPassword(token, email, password),
    () => {
      dispatch(actionMap.PASSWORD_RESET());
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.PASSWORD_RESET(errorMessage));
    },
    false,
  );

export const checkPasswordResetToken = (email: string, token: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.VALIDATE_TOKEN,
    () => account.validatePasswordResetKey(email, token),
    result => {
      const { isTokenValid } = result;
      dispatch(actionMap.VALIDATE_TOKEN(isTokenValid));
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('account.emailNotPresentInDB');
      dispatch(actionMap.VALIDATE_TOKEN());
      dispatch(
        createNotificationMessage(
          NotificationType.Error,
          errorMessage,
        ),
      );
    },
    false,
  );

const accountDetailsDuck = {
  thunks: {
    changePassword,
    changeUserPassword,
    updatePhone,
    getCustomerPhone,
    saveChanges,
    updateUserInfo,
    requestPasswordReset,
    resetPassword,
    checkPasswordResetToken,
  },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default accountDetailsDuck;
