import { TdHTMLAttributes } from 'react';

import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { theme, loading, Devices, loadingOverlay } from 'core/styles';

import { Button, IconAction } from '.';

interface TableContainerProps extends LoadingStyledComponentProps {
  noScroll?: boolean;
  adjustToPanel?: boolean;
  noBoxShadow?: boolean;
  overflow?: boolean;
}
export const TableContainer = styled.div<TableContainerProps>`
  display: block;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 40px auto;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.brandWhite};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  overflow-y: scroll;

  ${props =>
    props.noScroll &&
    css`
      overflow-y: hidden;
    `}

  ${props =>
    props.adjustToPanel &&
    css`
      max-width: 1190px;
    `}

  ${props =>
    props.noBoxShadow &&
    css`
      box-shadow: none;
      margin-bottom: 20px;
    `}

    ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay(24)};
    `};

  ${props =>
    props.overflow &&
    css`
      overflow-y: visible;
    `}
`;

interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  width?: number;
  mobileWidth?: number;
  tabletWidth?: number;
  noLeftPadding?: boolean;
  whiteBackground?: boolean;
  centered?: boolean;
  alignRight?: boolean;
  SLA?: string;
  greenThemeTextColor?: boolean;
  redThemeTextColor?: boolean;
  yellowThemeTextColor?: boolean;
  noRecords?: boolean;
  textOverflow?: boolean;
  breakWord?: boolean;
  smallPadding?: boolean;
  expandLargeText?: boolean;
  bold?: boolean;
  vAlign?: boolean;
}

export const TableCell = styled.div<TableCellProps>`
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-grow: 1;
  width: ${props => (props.width ? `${props.width}%` : '25%')};
  padding-left: 30px;
  list-style: none;
  overflow-wrap: break-word;
  
  ${props =>
    props.smallPadding &&
    css`
    padding-left: 5px;
    `}

  ${props =>
    props.breakWord &&
    css`
      white-space: pre-wrap;
      word-break: break-all;
    `}

  @media ${Devices.tablet} {
    padding-left: 10px;
    ${props =>
    props.tabletWidth &&
      css`
        width: ${props.tabletWidth}%;
      `}
  }

  @media ${Devices.mobile} {
    padding-left: 10px;
    ${props =>
    props.mobileWidth &&
      css`
        width: ${props.mobileWidth}%;
      `}
  }

  ${props =>
    props.noLeftPadding &&
    css`
      padding-left: 0px;
    `}

  ${props =>
    props.whiteBackground &&
    css`
      background-color: white;
    `};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.alignRight &&
    css`
      text-align: right;
    `};

  ${props =>
    props.SLA === 'Missed' &&
    css`
      color: ${theme.colors.brandDanger};
    `};

  ${props =>
    props.redThemeTextColor &&
    css`
      color: ${theme.colors.brandDanger};
    `};

  ${props =>
    props.greenThemeTextColor &&
    css`
      color: ${theme.colors.brandPrimary};
    `};

  ${props =>
    props.yellowThemeTextColor &&
      css`
        color: ${theme.colors.brandWarning};
      `};

  ${props =>
    props.noRecords &&
    css`
      display: flex;
      -webkit-flex-direction: 'column';
      -ms-flex-direction: 'column';
      flex-direction: 'column';
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;
      color: #555;
      background-color: ${theme.colors.brandWhite};
      text-align: center;
      font-size: 12px;
      font-style: normal;
    `};

  ${props =>
    props.textOverflow &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 18px;
      transition: max-height 0.5s ease;

      &:hover {
        white-space: normal;
        max-height: 72px;
      }
    `};

    ${props =>
    props.expandLargeText &&
      css`
        white-space: normal;
        overflow: hidden;
        animation: fit-content 0.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
    `};

    ${props => 
    props.bold &&
      css`
        font-weight: 600;
      `}

    @keyframes fit-content {
      from {
        max-height: 18px;
      }
      to {
        max-height: 145px;
      }
    }
    ${props => 
    props.vAlign &&
      css`
        display: flex;
        align-items: center;
      `}
`;

interface TableRowProps {
  hasNoBorder?: boolean;
  highlighBorder?: boolean;
  invertedBorder?: boolean;
  hoverEffect?: boolean;
  clickable?: boolean;
  hideLast?: boolean;
  titleHead?: boolean;
  titleHeadLight?: boolean;
  visibilityAtHover?: boolean;
  greyThemeTextColor?: boolean;
  normalFontHeight?: boolean;
  greyBackground?: boolean;
  greyBackgroundLowercase?: boolean;
  hasMargin?: boolean;
  smallTitleHead?: boolean;
  smallVersion?: boolean;
  alignLeft?: boolean;
  isBold?: boolean;
  noBackground?: boolean;
  isCardResponsive?: boolean;
  customPadding?: string;
}
export const TableRow = styled.div<TableRowProps>`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 56px;
  padding: 10px 10px 11px 10px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;

  ${props =>
    props.invertedBorder &&
    css`
      border-bottom: 0px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    `}
    
    ${props =>
    props.highlighBorder &&
      css`
        border-top: 2px solid rgba(0, 0, 0, 0.1);
      `}

  ${props =>
    props.smallVersion &&
    css`
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    `}

  ${props =>
    props.isBold &&
    css`
      font-weight: ${theme.fontWeightBold};
    `};

  ${props =>
    props.smallTitleHead &&
    css`
      color: ${theme.colors.brandBlack};
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      line-height: 14px;
    `}

  ${props =>
    props.hasNoBorder &&
    css`
      border-bottom: none;
      border-top:none;
    `}

  ${props =>
    props.hoverEffect &&
    css`
      :hover {
        padding: 10px;
        border: 2px solid #00a599;
      }
    `}

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.hideLast &&
    css`
      ${TableCell}:last-child {
        visibility: hidden;
      }
    `}

  ${props =>
    props.titleHead &&
    css`
      color: ${theme.colors.brandBlack};
      font-weight: 700;
      font-size: 14px;
    `}

    ${props =>
    props.titleHead && props.isCardResponsive &&
    css`
      @media ${Devices.tablet} {
        display:none; 
      }
    `}

    ${props =>
    props.titleHeadLight && props.isCardResponsive &&
    css`
      @media ${Devices.tablet} {
        display:none; 
      }
    `}

    ${props =>
    props.smallTitleHead && props.isCardResponsive &&
    css`
      @media ${Devices.tablet} {
        display:none; 
      }
    `}

    ${props => props.greyBackground &&
    css`
      background-color: #fafafc;
      color: #8a8a9c;
      font-size: 12px;
      text-transform: uppercase;
    `}

    ${props => props.greyBackgroundLowercase &&
      css`
        background-color: #fafafc;
        color: #8a8a9c;
        font-size: 12px;
      `}

    ${props => props.hasMargin &&
      css`
        width: 95%;
        margin: 0 auto;
      `}

    ${props => props.isCardResponsive &&
    css`

      @media ${Devices.tablet} {
      flex-wrap: wrap;
      font-size: 12px;
      text-align: left;
      border-bottom-color: #00a599;
      &:nth-child(odd) {
          background-color: #f5faf9;
        }
      ${TableCell} {
        width: 100%;
        flex: 0 50%;
        flex-wrap: initial;
        padding: 5px 5px 5px 0;
        position: relative;
        color: #1f2a4b;
          &:before {
            content: attr(data-title);
            display: inline-block;
            vertical-align: top;
            width: 45%;
            padding-right: 10px;
            text-align: left;
            font-weight: ${theme.fontWeightBold};
            color: ${theme.colors.brandBlack};
            box-sizing: border-box;
          }
           > span{
            width: 55%;
            display: inline-block;
            ${Button} {
              font-size: 12px;
              padding: 3px 10px;
              display: block;
              min-width: auto;
            }
            ${IconAction} {
              font-size: 18px;
            }
          }

        }
      }
      @media ${Devices.mobile} {
      ${TableCell} {
        width: 100%;
        flex: 0 100%;
        padding: 5px 0 5px 0;
          &:before {
            width: 35%;
            padding-right: 10px;
            text-align: right;
          }
          > span{
            width: 65%;
          }
        }
      }
    `}

  ${props =>
    props.noBackground && props.isCardResponsive &&
    css`
      @media ${Devices.tablet} {
        background-color: transparent;
        &:nth-child(odd) {
          background-color: transparent;
        }
      }
    `}

  ${props =>
    props.titleHeadLight &&
    css`
      color: ${theme.colors.grayDark};
    `}

  ${props =>
    props.visibilityAtHover &&
    css`
      ${TableCell}:last-child {
        visibility: hidden;

        @media ${Devices.tablet} {
          visibility: visible;
        }
      }
      :hover {
        border: 2px solid #00a599;
        ${TableCell}:last-child {
          visibility: visible;
        }
        &:first-child {
          border: 2px solid transparent;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      
    `}

  ${props =>
    props.greyThemeTextColor &&
    css`
      color: #9dafc2;
    `}

  ${props =>
    props.normalFontHeight &&
    css`
      font-weight: normal;
    `}

  ${props =>
    props.alignLeft &&
    css`
      text-align: left;
    `}

  ${props =>
    props.customPadding &&
    css`
      padding: ${props.customPadding};
    `}
`;

interface ShowMoreButtonProps {
  isShowMoreButtonGrey?: boolean;
}
export const ShowMoreButton = styled.div<ShowMoreButtonProps>`
  color: ${props => props.theme.colors.brandPrimary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }

  ${props =>
    props.isShowMoreButtonGrey &&
    css`
      color: #9dafc2;
    `}
`;

export const NavLinkText = styled.span`
  color: ${theme.colors.brandDefault};
`;

export const ShowMoreButtonContainer = styled.div<LoadingStyledComponentProps>`
  display: flex;
  justify-content: center;

  ${props =>
    props.isLoading &&
    css`
      ${loading(30)};

      & > div {
        visibility: hidden;
      }
    `}
`;

interface TableTextProps {
  bold?: boolean;
}

export const TableText = styled.span<TableTextProps>`
  color: ${theme.colors.brandBlack};

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;
