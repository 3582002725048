import { DefaultTheme, ThemedStyledProps } from 'styled-components';

import Colors from './colors';

export interface ColorProps {
  color?: Colors;
}

const COLOR_MAP: Record<string, string> = {
  [Colors.default]: 'brandDefault',
  [Colors.primary]: 'brandPrimary',
  [Colors.secondary]: 'brandSecondary',
  [Colors.success]: 'brandSuccess',
  [Colors.info]: 'brandInfo',
  [Colors.warning]: 'brandWarning',
  [Colors.alert]: 'brandDanger',
  [Colors.grayBase]: 'grayBase',
  [Colors.grayDarker]: 'grayDarker',
  [Colors.grayDark]: 'grayDark',
  [Colors.gray]: 'gray',
  [Colors.grayLight]: 'grayLight',
  [Colors.grayLighter]: 'grayLighter',
};

const getKeyValue = <T extends Record<string, string>, U extends keyof T>(key: U) => (obj: T) =>
  obj[key];
  
const colorMapper = (
  props: ThemedStyledProps<ColorProps, DefaultTheme>,
  defaultColor = Colors.default): string => getKeyValue(
  COLOR_MAP[props.color || defaultColor])(props.theme.colors);

export default colorMapper;
