import { TableData } from 'contracts/types/component';

export interface ICustomerTag {
  readonly tagId: number;
  readonly name: string;
  readonly description: string;
  readonly tagTypeId: number;
  readonly customerId: string;
  readonly userCount: number;
  readonly siteCount: number;
  readonly isAdminTag: boolean;
}

class CustomerTag implements TableData {
  tagId?: number;
  name?: string;
  description?: string;
  tagTypeId?: number;
  customerId?: string;
  userCount?: number;
  siteCount?: number;
  isHidden?: boolean;
  isAdminTag?: boolean;

  constructor(data?: Partial<ICustomerTag>) {
    this.tagId = data?.tagId;
    this.name = data?.name;
    this.description = data?.description;
    this.tagTypeId = data?.tagTypeId;
    this.customerId = data?.customerId;
    this.userCount = data?.userCount;
    this.siteCount = data?.siteCount;
    this.isAdminTag = data?.isAdminTag;
  }
}

export interface ICustomerTagAssigned extends ICustomerTag {
  readonly isAssigned: boolean;
}
export class CustomerTagAssigned extends CustomerTag {
  isAssigned: boolean;

  constructor(data?: Partial<ICustomerTagAssigned>) {
    super(data);
    this.isAssigned = data?.isAssigned || false;
  }
}

export default CustomerTag;
