import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AccountRoutes } from 'account/routing';
import { ServiceError } from 'contracts/types/service';
import { httpCustomerCore } from 'core/services/http';
import { WholesaleRoutes } from 'wholesale/routing';

import { destroySession, getSessionAuthToken, setRedirectUrl } from './session';

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const authToken = getSessionAuthToken();
  if (authToken && config.headers) {
    config.headers.Authorization = authToken as string;
  }
  return config;
};

const requestErrorInterceptor = (error: AxiosError<ServiceError>): Promise<never> => Promise.reject(error);

const responseIncerceptor = (config: AxiosResponse): AxiosResponse => config;

const responseErrorInterceptor = (error: AxiosError<ServiceError>): Promise<never> => {
  if (
    error.response &&
    error.response.status === 401 &&
    window.location.href.indexOf(AccountRoutes.root + AccountRoutes.login) < 0 &&
    window.location.href.indexOf(WholesaleRoutes.root) < 0
  ) {
    setRedirectUrl(window.location.href);
    destroySession();
    window.location.href = AccountRoutes.root + AccountRoutes.login;
  }
  return Promise.reject(error);
};

const registerAuthInterceptor = (): void => {
  httpCustomerCore.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor,
  );
  httpCustomerCore.interceptors.response.use(
    responseIncerceptor,
    responseErrorInterceptor,
  );
};

export default registerAuthInterceptor;
