import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { CommunicationTypeModel, UserSetting } from 'contracts/models/service/CommunicationSettings';
import { ActionDispatcher, CommunicationSettingsAction } from 'contracts/types/action';
import {
  ApplicationState,
  CommunicationSettingsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as communicationSettingsService from '../services/communicationSettings';

// Actions Keys
const ROOT_KEY = 'communication-settings';
enum ActionKey {
  LOAD_COMMUNICATION_SETTINGS = 'communication-settings/LOAD_COMMUNICATION_SETTINGS',
  UPDATE_COMMUNICATION_SETTINGS = 'communication-settings/UPDATE_COMMUNICATION_SETTINGS',
  RESET = 'communication-settings/RESET',
}

// Initial State
const getInitialState: () => CommunicationSettingsState = () => {
  return {
    communicationTypes: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_COMMUNICATION_SETTINGS,
  ActionKey.UPDATE_COMMUNICATION_SETTINGS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CommunicationSettingsState,
  CommunicationSettingsAction
> = {
  [ActionKey.LOAD_COMMUNICATION_SETTINGS]: (state, action) => {
    const { communicationTypes } = action;
    if (!communicationTypes) {
      return state;
    }
    return update(state, { $merge: { communicationTypes } });
  },
  [ActionKey.UPDATE_COMMUNICATION_SETTINGS]: state => {
    return update(state, { $merge: { communicationTypes: [] } });
  },
};

export const reducer = getReducerBuilder<
  CommunicationSettingsState,
  CommunicationSettingsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_COMMUNICATION_SETTINGS: (
    communicationTypes?: CommunicationTypeModel[],
  ): CommunicationSettingsAction => ({
    type: ActionKey.LOAD_COMMUNICATION_SETTINGS,
    communicationTypes,
  }),
  UPDATE_COMMUNICATION_SETTINGS: (
    
  ): CommunicationSettingsAction => ({
    type: ActionKey.UPDATE_COMMUNICATION_SETTINGS,
   
  }),
  RESET: (): CommunicationSettingsAction => ({ type: ActionKey.RESET }),
};

const loadCommunicationSettings = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMMUNICATION_SETTINGS,
    () => communicationSettingsService.getCommunicationSettings(),
    result => dispatch(actionMap.LOAD_COMMUNICATION_SETTINGS(result.communicationTypes)),
    () => dispatch(actionMap.LOAD_COMMUNICATION_SETTINGS()),
  );

const loadUserCommunicationSettings = (email: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMMUNICATION_SETTINGS,
    () => communicationSettingsService.getUserCommunicationSettings(email),
    result => dispatch(actionMap.LOAD_COMMUNICATION_SETTINGS(result.communicationTypes)),
    () => dispatch(actionMap.LOAD_COMMUNICATION_SETTINGS()),
  );

const updateCommunicationSettings = (request: UserSetting[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_COMMUNICATION_SETTINGS,
    async() => communicationSettingsService.setCommunicationSettings({ settings: request }),
    () => {
      dispatch(actionMap.UPDATE_COMMUNICATION_SETTINGS());
      dispatch(createTimedNotificationMessage(NotificationType.Success, translate('account.communicationSettingsSuccesfullySaved')));
    },
    () => {
      dispatch(actionMap.UPDATE_COMMUNICATION_SETTINGS());
    },
    true,
  );

const updateUserCommunicationSettings = (email: string, request: UserSetting[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_COMMUNICATION_SETTINGS,
    async() => communicationSettingsService.setUserCommunicationSettings({ email, settings: request }),
    () => {
      dispatch(actionMap.UPDATE_COMMUNICATION_SETTINGS());
      dispatch(createTimedNotificationMessage(NotificationType.Success, translate('account.communicationSettingsSuccesfullySaved')));
    },
    () => {
      dispatch(actionMap.UPDATE_COMMUNICATION_SETTINGS());
    },
    true,
  );

const communicationSettingsDuck = {
  thunks: {
    loadCommunicationSettings,
    loadUserCommunicationSettings,
    updateCommunicationSettings,
    updateUserCommunicationSettings,
  },
  actions: {
    reset: actionMap.RESET,
  },
  actionKeys: ActionKey,
};

export default communicationSettingsDuck;
