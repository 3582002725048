export interface GhGCarbonEmissionsSummaryItem {
  name: string;
  identifier: string;
  mtCo2Emissions: number;
  emissionsWeight: string;
}

export interface IGhGCarbonEmissionsSummary {
  readonly emissions: GhGCarbonEmissionsSummaryItem[];
}

class GhGCarbonEmissionsSummary {
  emissions?: GhGCarbonEmissionsSummaryItem[];

  constructor(data?: Partial<IGhGCarbonEmissionsSummary>) {
    this.emissions = data?.emissions;
  }
}

export default GhGCarbonEmissionsSummary;
