import { WholesaleCart, WholesalePurchaseResult } from 'contracts/models';
import { SuccesfulSaleModel } from 'contracts/types/service';
import { httpCustomerCore } from 'core/services/http';

import { transformCartToSend } from './transformCartToSend';

export const purchase = async(cart: WholesaleCart): Promise<Partial<WholesalePurchaseResult & SuccesfulSaleModel>> => {
  const formattedCart = transformCartToSend(cart);
  const response = await httpCustomerCore.post(
    'wholesale/purchase',
    formattedCart,
  );
  return response.data;
};

export default purchase;
