import styled, { css } from 'styled-components';

export const CheckboxCheck = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  vertical-align: middle;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.colors.grayDark};
  border-radius: 3px;
  flex: 0 0 auto;
`;

interface CheckboxTextProps {
  size?: number;
  checkboxLabelLineCount?: number;
}
export const CheckboxText = styled.span<CheckboxTextProps>`
  vertical-align: middle;
  font-size: 13px;
  user-select: none;

  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  flex: 1 1 auto;

  ${props =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `};

  ${props =>
    props.checkboxLabelLineCount &&
    css`
      -webkit-line-clamp: ${props.checkboxLabelLineCount};
    `};
`;

export const CheckboxInput = styled.input`
  display: none;

  &:checked + ${CheckboxCheck} {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMkMuOSAwIDAgLjkgMCAydjE0YzAgMS4xLjkgMiAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWMmMwLTEuMS0uOS0yLTItMnpNNyAxNEwyIDkuMTkybDEuNC0xLjM0Nkw3IDExLjMwOCAxNC42IDQgMTYgNS4zNDYgNyAxNHoiIGZpbGw9IiMwMDk2ODgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
    background-size: 100%;
    border-width: 0px;
  }
`;

interface CheckboxProps {
  isDisabled?: boolean;
}
export const Checkbox = styled.label<CheckboxProps>`
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    & > ${CheckboxCheck} {
      border-color: ${props => props.theme.colors.brandPrimary};
    }

    & > ${CheckboxText} {
      color: ${props => props.theme.colors.brandPrimary};
    }
  }

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;
