import update from 'immutability-helper';

import { MaterialManagementComparison, MaterialManagementSiteComparison, MaterialManagementSummary, MaterialManagementTableData, MaterialProfileSiteComparison } from 'contracts/models';
import MaterialProfileComparison from 'contracts/models/service/MaterialProfileComparison';
import MaterialProfileSummary from 'contracts/models/service/MaterialProfileSummary';
import MaterialProfileTableData from 'contracts/models/service/MaterialProfileTableData';
import {
  ActionDispatcher,
  MaterialProfileAction,
} from 'contracts/types/action';
import { MaterialManagementRequest, MaterialProfileRequest } from 'contracts/types/request';
import {
  ApplicationState,
  MaterialProfileState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getMaterialManagementComparison,
  getMaterialManagementComparisonSite,
  getMaterialManagementSummary,
  getMaterialManagementTable,
  getMaterialProfileComparison,
  getMaterialProfileComparisonSite,
  getMaterialProfileSummary,
  getMaterialProfileTable,
} from '../services/materialProfileService';

// ACTIONS
const ROOT_KEY = 'sustainability/materialProfile';
enum ActionKey {
  LOAD_SUMMARY = 'sustainability/materialProfile/LOAD_SUMMARY',
  LOAD_COMPARISON = 'sustainability/materialProfile/LOAD_COMPARISON',
  LOAD_COMPARISON_SITE = 'sustainability/materialProfile/LOAD_COMPARISON_SITE',
  LOAD_TABLE_DATA = 'sustainability/materialProfile/LOAD_TABLE_DATA',
  LOAD_SUMMARY_MAT_MGMT = 'sustainability/materialProfile/LOAD_SUMMARY_MAT_MGMT',
  LOAD_COMPARISON_MAT_MGMT = 'sustainability/materialProfile/LOAD_COMPARISON_MAT_MGMT',
  LOAD_COMPARISON_MAT_MGMT_SITE = 'sustainability/materialProfile/LOAD_COMPARISON_MAT_MGMT_SITE',
  LOAD_TABLE_DATA_MAT_MGMT = 'sustainability/materialProfile/LOAD_TABLE_DATA_MAT_MGMT',
  RESET = 'sustainability/materialProfile/RESET',
}
// Initial state
const getInitialState: () => MaterialProfileState = () => {
  return {
    summary: {},
    comparison: {},
    comparisonSite: {},
    table: [],
    summaryMatMgmt: {},
    comparisonMatMgmt: {},
    comparisonMatMgmtSite: {},
    tableMatMgmt: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SUMMARY,
  ActionKey.LOAD_TABLE_DATA,
  ActionKey.LOAD_COMPARISON,
  ActionKey.LOAD_COMPARISON_SITE,
  ActionKey.LOAD_SUMMARY_MAT_MGMT,
  ActionKey.LOAD_TABLE_DATA_MAT_MGMT,
  ActionKey.LOAD_COMPARISON_MAT_MGMT,
  ActionKey.LOAD_COMPARISON_MAT_MGMT_SITE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  MaterialProfileState,
  MaterialProfileAction
> = {
  [ActionKey.LOAD_SUMMARY]: (state, action) => {
    const { summary } = action;
    if (!summary) {
      return state;
    }
    return update(state, { $merge: { summary } });
  },
  [ActionKey.LOAD_TABLE_DATA]: (state, action) => {
    const { table } = action;
    if (!table) {
      return state;
    }
    return update(state, { $merge: { table } });
  },
  [ActionKey.LOAD_COMPARISON]: (state, action) => {
    const { comparison } = action;
    if (!comparison) {
      return state;
    }
    return update(state, { $merge: { comparison } });
  },
  [ActionKey.LOAD_COMPARISON_SITE]: (state, action) => {
    const { comparisonSite } = action;
    if (!comparisonSite) {
      return state;
    }
    return update(state, { $merge: { comparisonSite } });
  },
  [ActionKey.LOAD_SUMMARY_MAT_MGMT]: (state, action) => {
    const { summaryMatMgmt } = action;
    if (!summaryMatMgmt) {
      return state;
    }
    return update(state, { $merge: { summaryMatMgmt } });
  },
  [ActionKey.LOAD_TABLE_DATA_MAT_MGMT]: (state, action) => {
    const { tableMatMgmt } = action;
    if (!tableMatMgmt) {
      return state;
    }
    return update(state, { $merge: { tableMatMgmt } });
  },
  [ActionKey.LOAD_COMPARISON_MAT_MGMT]: (state, action) => {
    const { comparisonMatMgmt } = action;
    if (!comparisonMatMgmt) {
      return state;
    }
    return update(state, { $merge: { comparisonMatMgmt } });
  },
  [ActionKey.LOAD_COMPARISON_MAT_MGMT_SITE]: (state, action) => {
    const { comparisonMatMgmtSite } = action;
    if (!comparisonMatMgmtSite) {
      return state;
    }
    return update(state, { $merge: { comparisonMatMgmtSite } });
  },
};

export const reducer = getReducerBuilder<
  MaterialProfileState,
  MaterialProfileAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  LOAD_SUMMARY: (summary?: MaterialProfileSummary): MaterialProfileAction => ({
    type: ActionKey.LOAD_SUMMARY,
    summary,
  }),
  LOAD_COMPARISON: (
    comparison?: MaterialProfileComparison,
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_COMPARISON,
    comparison,
  }),
  LOAD_COMPARISON_SITE: (
    comparisonSite?: MaterialProfileSiteComparison,
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_COMPARISON_SITE,
    comparisonSite,
  }),
  LOAD_TABLE_DATA: (
    table?: MaterialProfileTableData[],
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_TABLE_DATA,
    table,
  }),
  LOAD_SUMMARY_MAT_MGMT: (
    summaryMatMgmt?: MaterialManagementSummary): MaterialProfileAction => ({
    type: ActionKey.LOAD_SUMMARY_MAT_MGMT,
    summaryMatMgmt,
  }),
  LOAD_COMPARISON_MAT_MGMT: (
    comparisonMatMgmt?: MaterialManagementComparison,
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_COMPARISON_MAT_MGMT,
    comparisonMatMgmt,
  }),
  LOAD_COMPARISON_MAT_MGMT_SITE: (
    comparisonMatMgmtSite?: MaterialManagementSiteComparison,
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_COMPARISON_MAT_MGMT_SITE,
    comparisonMatMgmtSite,
  }),
  LOAD_TABLE_DATA_MAT_MGMT: (
    tableMatMgmt?: MaterialManagementTableData[],
  ): MaterialProfileAction => ({
    type: ActionKey.LOAD_TABLE_DATA_MAT_MGMT,
    tableMatMgmt,
  }),
  RESET: (): MaterialProfileAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadMaterialProfileSummary = (inputArg: MaterialProfileRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY,
    () => getMaterialProfileSummary(inputArg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY(result));
    },
    () => {
      dispatch(actionMap.LOAD_SUMMARY());
    },
    true,
  );

const loadMaterialProfileComparison = (
  inputArg: MaterialProfileRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON,
    () => getMaterialProfileComparison(inputArg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON(result));
    },
    () => {
      dispatch(actionMap.LOAD_COMPARISON());
    },
    true,
  );

const loadMaterialProfileComparisonSite = (
  inputArg: MaterialProfileRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON_SITE,
    () => getMaterialProfileComparisonSite(inputArg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON_SITE(result));
    },
    () => {
      dispatch(actionMap.LOAD_COMPARISON_SITE());
    },
    true,
  );
  
const loadMaterialProfileTable = (inputArg: MaterialProfileRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE_DATA,
    () => getMaterialProfileTable(inputArg),
    result => {
      dispatch(actionMap.LOAD_TABLE_DATA(result));
    },
    () => {
      dispatch(actionMap.LOAD_TABLE_DATA());
    },
    true,
  );

const loadMaterialManagementSummary = (inputArg: MaterialManagementRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY_MAT_MGMT,
    () => getMaterialManagementSummary(inputArg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY_MAT_MGMT(result));
    },
    () => {
      dispatch(actionMap.LOAD_SUMMARY_MAT_MGMT());
    },
    true,
  );
  
const loadMaterialManagementComparison = (inputArg: MaterialManagementRequest) => async(
  dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON_MAT_MGMT,
    () => getMaterialManagementComparison(inputArg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON_MAT_MGMT(result));
    },
    () => {
      dispatch(actionMap.LOAD_COMPARISON_MAT_MGMT());
    },
    true,
  );

const loadMaterialManagementComparisonSite = (inputArg: MaterialManagementRequest) => async(
  dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON_MAT_MGMT_SITE,
    () => getMaterialManagementComparisonSite(inputArg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON_MAT_MGMT_SITE(result));
    },
    () => {
      dispatch(actionMap.LOAD_COMPARISON_MAT_MGMT_SITE());
    },
    true,
  );
  
    
const loadMaterialManagementTable = (inputArg: MaterialManagementRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE_DATA_MAT_MGMT,
    () => getMaterialManagementTable(inputArg),
    result => {
      dispatch(actionMap.LOAD_TABLE_DATA_MAT_MGMT(result));
    },
    () => {
      dispatch(actionMap.LOAD_TABLE_DATA_MAT_MGMT());
    },
    true,
  );

const materialProfileDuck = {
  thunks: {
    loadMaterialProfileSummary,
    loadMaterialProfileComparison,
    loadMaterialProfileComparisonSite,
    loadMaterialProfileTable,
    loadMaterialManagementSummary,
    loadMaterialManagementComparison,
    loadMaterialManagementComparisonSite,
    loadMaterialManagementTable
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default materialProfileDuck;
