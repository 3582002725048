import React, { PropsWithChildren } from 'react';

import { NavLink } from 'react-router-dom';

import { Button } from './styled';
import { ButtonProps } from './styled/Button';

const ButtonLink: React.FC<PropsWithChildren<ComponentProps>> = ({ to, children, externalUrl, ...rest }) => (
  externalUrl ?
    (<NavLink to={{ pathname: to }} target="_blank">
      <Button {...rest}>{children}</Button>
    </NavLink>) :
    (<NavLink to={to}>
      <Button {...rest}>{children}</Button>
    </NavLink>)
);

interface OwnProps {
  to: string;
  externalUrl?: boolean;
}

type ComponentProps = OwnProps & ButtonProps;

export default ButtonLink;
