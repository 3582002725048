import styled, { css } from 'styled-components';

import { Devices, theme } from 'core/styles';
import { sizeMapper } from 'utils/styles';

interface DropdownFiltersBarProps {
  noMargin?: boolean;
  noBackground?: boolean;
  noPadding?: boolean;
}

export const DropdownFiltersBar = styled.div<DropdownFiltersBarProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 15px 5px;
  background-color: ${theme.colors.brandWhite};
  min-height: 70px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  clear: both;
  ${props => props.noMargin && css`
    margin: 0;
  `}

  ${props => props.noPadding && css`
    padding: 0;
  `}

  ${props => props.noBackground && css`
    background-color: none;
    box-shadow: none;
  `}
`;

interface DropdownFiltersCollectionProps {
  flexAuto?: boolean;
}
export const DropdownFiltersCollection = styled.div<DropdownFiltersCollectionProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;

  @media ${Devices.mobile} {
    >div {
      min-width: 50%;
      box-sizing: border-box;
    }
  }

  ${props => props.flexAuto && css`
    flex: 1 1 auto;
  `}
`;

export const DropdownFiltersClear = styled.button`
  display: inline-block;
  padding: 10px;
  color: ${props => props.theme.colors.brandPrimary};
  font-size: 14px;
  outline: none;
  border: none;
  background: transparent;

  &:enabled {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
