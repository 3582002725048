import { FullnessCalendarViewModel, FullnessEventsTableModel } from 'contracts/models/service/ServiceAnalyticsTableData';
import { FullnessEventsTableSubModel } from 'contracts/models/service/ServiceAnalyticsTableSubData';
import { DisposalAuditDetailsRequestArg } from 'contracts/types/request';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore } from 'core/services/http';

export const getServices = 
  async({ fromDate, pageNumber, pageSize, siteFilters, toDate }: DisposalAuditDetailsRequestArg): 
  Promise<FullnessEventsTableModel> => {
    const inputArg = {
      reportFilters: {
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        siteFilters: {
          ...getLocationFilterApiArg(siteFilters),
          materialCategories: siteFilters
            ? siteFilters.materials || []
            : [],
          size:
          siteFilters && siteFilters.sizeRange
            ? {
              from: siteFilters.sizeRange.from,
              to: siteFilters.sizeRange.to,
            }
            : null,
        }
      }
    };
    const response = await httpCustomerCore.post<FullnessEventsTableModel>('fullness-events/table-data', inputArg);
    return response.data;
  };

export const getServiceSchedule = async(svcId: number, startDate: string, endDate: string): 
Promise<FullnessCalendarViewModel> => {
  const response = await httpCustomerCore.get<FullnessCalendarViewModel>('fullness-events/calendar-view', { 
    params: { svcId, startDate, endDate } });
  return response.data;
};

export const getServiceSubData = async(svcId: number, startDate: string, endDate: string): 
Promise<FullnessEventsTableSubModel> => {
  const response = await httpCustomerCore.get<FullnessEventsTableSubModel>(`fullness-events/table-sub-data`, { 
    params: { svcId, startDate, endDate } });
  return response.data;
};
