import React from 'react';

import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled';

const UnconnectedCheckbox: React.FC<ComponentProps> = ({
  disabled,
  onChange,
  checked,
  label
}) => {
  return (
    <CheckboxContainer isDisabled={disabled}>
      <CheckboxInput
        id='checkbox-input'
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <CheckboxCheck />
      <CheckboxText>{label}</CheckboxText>
    </CheckboxContainer>
  );
};

interface ComponentProps {
  disabled: boolean;
  onChange: () => void;
  label?: string;
  checked?: boolean;
}

export default UnconnectedCheckbox;
