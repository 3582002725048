import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from 'utils/styles';
import colorMapper, { ColorProps } from 'utils/styles/colorMapper';

interface CustomTextProps extends ColorProps {
  size: string;
  margin?: string;
  block?: boolean;
  weight?: string;
  underline?: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const CustomText = styled.span<CustomTextProps>`
  font-size: ${props =>
    mapper({ small: '12px', medium: '14px', large: '18px', xLarge: '22px', xxLarge: '44px' }, props.size)};
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${mapper({
    light: props.theme.fontWeightLight.toString(),
    normal: props.theme.fontWeightNormal.toString(),
    medium: props.theme.fontWeightMedium.toString(),
    large: props.theme.fontWeightBold.toString(),
  }, props.weight)};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `};
`;
