import React, { Component, createRef, PropsWithChildren } from 'react';

import { GetBlobResponse } from 'contracts/types/service';
import downloadBlob from 'core/helpers/downloadBlob';

class DownloadBlob extends Component<PropsWithChildren<ComponentProps>> {
  state = { generating: false };
  linkRef = createRef<HTMLAnchorElement>();

  onClick = async(): Promise<void> => {
    const { getBlob } = this.props;
    this.setState({ generating: true });
    try {
      const { blob, filename } = await getBlob();
      downloadBlob(blob, filename);
    } finally {
      if (this.linkRef && this.linkRef.current) {
        this.setState({ generating: false });
      }
    }
  };

  render(): React.ReactNode {
    const { children, generatingComponent } = this.props;
    const { generating } = this.state;
    return (
      <div>
        {generating ? (
          generatingComponent ? (
            generatingComponent()
          ) : null
        ) : (
          <div style={{ display: 'inline-block' }} onClick={this.onClick}>
            {children}
          </div>
        )}
        <a style={{ visibility: 'hidden' }} href='' ref={this.linkRef} />
      </div>
    );
  }
}

interface ComponentProps {
  generatingComponent: () => JSX.Element;
  getBlob: () => Promise<GetBlobResponse>;
}
export default DownloadBlob;
