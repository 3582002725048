import moment from 'moment';

import CieTradeRequestType, {
  ICieTradeRequestType,
} from 'contracts/models/service/CieTradeRequestType';
import { SiteIdentifier } from 'contracts/types/component';
import { CieTradeRequestFormData } from 'contracts/types/form';
import { CieTradeRequest } from 'contracts/types/request';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const sendCieTrade = async({
  siteId,
  referenceNumber,
  matSubType,
  cietradeRequestTypeId,
  serviceDate,
  corePallet,
  nonCorePallet,
  chepPallet,
  peco,
  igps,
  baleCount,
  requestNote,
  cietradeTrackingHeaderId,
}: CieTradeRequest): Promise<unknown> => {
  const serviceDateFormatted = moment(serviceDate).format('YYYY-MM-DD');
  const response = await httpCustomerCore.post('cietrade/service-request', {
    siteId,
    referenceNumber,
    matSubType,
    cietradeRequestTypeId,
    serviceDate: serviceDateFormatted,
    corePallet,
    nonCorePallet,
    chepPallet,
    peco,
    igps,
    baleCount,
    requestNote,
    cietradeTrackingHeaderId,
  });
  return response.data;
};

export const getRequestTypes = async(): Promise<CieTradeRequestType[]> => {
  const response = await httpCustomerCore.get<CieTradeRequestType[]>(
    'cietrade/request-types',
    {
      transformResponse: transformServiceResponse<
        ICieTradeRequestType,
        CieTradeRequestType
      >(CieTradeRequestType),
    },
  );
  return response.data;
};

export const getHasCietradeAccess = async(siteIdentifier: SiteIdentifier): Promise<boolean> => {
  const response = await httpCustomerCore.get<boolean>(
    `cietrade/has-cietrade-acceess?customerId=${siteIdentifier.siteCustomerId}&siteId=${siteIdentifier.siteId}`,
  );
  return response.data;
};

export const isReferenceNumberDuplicate = async(
  request: CieTradeRequestFormData,
): Promise<boolean> => {
  if (!request.isEdit && request.referenceNumber) {
    const response = await httpCustomerCore.get<boolean>(
      `cietrade/is-reference-number-duplicate?referenceNumber=${request.referenceNumber}`,
    );
    return response.data;
  }
  return false;
};
