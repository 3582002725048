import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { SiteProfileEdit } from 'contracts/models';
import { ActionDispatcher, EditSiteAction } from 'contracts/types/action';
import { SiteIdentifier } from 'contracts/types/component';
import { SiteProfileEditRequest } from 'contracts/types/request';
import {
  ApplicationState,
  EditSiteState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getSiteEditInfo, saveSiteInfo } from '../services/serviceListServices';

// Actions Keys
const ROOT_KEY = 'services/editSite';
enum ActionKey {
  LOAD_EDIT_SITE_INFO = 'services/editSite/LOAD_EDIT_SITE_INFO',
  SAVE_SITE_INFO = 'services/editSite/SAVE_SITE_INFO',
  RESET = 'services/editSite/RESET',
}

// Initial state
const getInitialState: () => EditSiteState = () => {
  return {
    editSiteInfo: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_EDIT_SITE_INFO] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  EditSiteState,
  EditSiteAction
> = {
  [ActionKey.LOAD_EDIT_SITE_INFO]: (state, action) => {
    const { editSiteInfo } = action;
    return update(state, { $merge: { editSiteInfo } });
  },
};

export const reducer = getReducerBuilder<EditSiteState, EditSiteAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_EDIT_SITE_INFO: (editSiteInfo?: SiteProfileEdit): EditSiteAction => ({
    type: ActionKey.LOAD_EDIT_SITE_INFO,
    editSiteInfo,
  }),
  RESET: (): EditSiteAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadEditInfo = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_EDIT_SITE_INFO,
    () => getSiteEditInfo(siteIdentifier),
    result => dispatch(actionMap.LOAD_EDIT_SITE_INFO(result)),
    () => dispatch(actionMap.LOAD_EDIT_SITE_INFO()),
  );

const saveSite = (params: SiteProfileEditRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SAVE_SITE_INFO,
    () => saveSiteInfo(params),
    () => {
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          `${params.siteName} site updated successfully`,
        ),
      );
    },
    () =>
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          `We were unable to save ${params.siteName}. Please try again!`,
          5000,
        ),
      ),
  );

const editSiteDuck = {
  thunks: { loadEditInfo, saveSite },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default editSiteDuck;
