import { SiteProfileService } from './SiteProfile';

export interface IServiceDetails {
  readonly custId: string;
  readonly siteId: string;
  readonly locationName: string;
  readonly serviceAddress: string;
  readonly zipCode: string;
  readonly service: SiteProfileService;
}

class ServiceDetails {
  custId?: string;
  siteId?: string;
  locationName?: string;
  serviceAddress?: string;
  zipCode?: string;
  service?: SiteProfileService;

  constructor(data?: Partial<IServiceDetails>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.locationName = data?.locationName;
    this.serviceAddress = data?.serviceAddress;
    this.zipCode = data?.zipCode;
    this.service = data?.service;
  }
}

export interface SiteProfileCaseStatusStep {
  date?: string;
  caption: string;
  isActive: boolean;
}

export default ServiceDetails;
