import update from 'immutability-helper';

import { FrontLoadDiversionReport } from 'contracts/models';
import {
  ActionDispatcher,
  FrontLoadDiversionReportAction,
} from 'contracts/types/action';
import { FrontLoadDiversionReportRequestParam } from 'contracts/types/frontLoadDiversion';
import {
  ApplicationState,
  FrontLoadDiversionReportState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getFrontLoadDiversionReport } from 'reports/services/frontLoadDiversionReportService';

// Actions
const ROOT_KEY = 'reports/frontload-diversion';

enum ActionKey {
  LOAD_PAGE_DATA = 'reports/frontload-diversion/LOAD_PAGE_DATA',
  LOAD_TABLE_DATA = 'reports/frontload-diversion/LOAD_TABLE_DATA',
  RESET = 'reports/frontload-diversion/RESET_ALL',
}

// Initial state
const getInitialState: () => FrontLoadDiversionReportState = () => {
  return {
    frontLoadDiversionReportData: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_TABLE_DATA, ActionKey.LOAD_PAGE_DATA] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FrontLoadDiversionReportState,
  FrontLoadDiversionReportAction
> = {
  [ActionKey.LOAD_PAGE_DATA]: (
    state: FrontLoadDiversionReportState,
    action: FrontLoadDiversionReportAction,
  ) => {
    return update(state, {
      $merge: {
        frontLoadDiversionReportData: action.frontLoadDiversionReportData,
      },
    });
  },
  [ActionKey.LOAD_TABLE_DATA]: (
    state: FrontLoadDiversionReportState,
    action: FrontLoadDiversionReportAction,
  ) => {
    return update(state, {
      $merge: {
        frontLoadDiversionReportData: {
          ...state.frontLoadDiversionReportData,
          sites: action.frontLoadDiversionReportData?.sites,
        },
      },
    });
  },
};

export const reducer = getReducerBuilder<
  FrontLoadDiversionReportState,
  FrontLoadDiversionReportAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  LOAD_PAGE_DATA: (
    frontLoadDiversionReportData?: FrontLoadDiversionReport,
  ): FrontLoadDiversionReportAction => ({
    type: ActionKey.LOAD_PAGE_DATA,
    frontLoadDiversionReportData,
  }),
  LOAD_TABLE_DATA: (
    frontLoadDiversionReportData?: FrontLoadDiversionReport,
  ): FrontLoadDiversionReportAction => ({
    type: ActionKey.LOAD_TABLE_DATA,
    frontLoadDiversionReportData,
  }),
  RESET: (): FrontLoadDiversionReportAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadFrontLoadDiversionReport = (
  arg: FrontLoadDiversionReportRequestParam,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAGE_DATA,
    async() => getFrontLoadDiversionReport(arg),
    result => {
      dispatch(actionMap.LOAD_PAGE_DATA(result));
    },
    () => dispatch(actionMap.LOAD_PAGE_DATA()),
  );

const loadFrontLoadDiversionReportTable = (
  arg: FrontLoadDiversionReportRequestParam,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE_DATA,
    async() => getFrontLoadDiversionReport(arg),
    result => {
      dispatch(actionMap.LOAD_TABLE_DATA(result));
    },
    () => dispatch(actionMap.LOAD_TABLE_DATA()),
  );
const frontLoadDiversionReportDuck = {
  thunks: { loadFrontLoadDiversionReport, loadFrontLoadDiversionReportTable },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default frontLoadDiversionReportDuck;
