export interface ICieTradeRequestType {
  readonly cieTradeRequestTypeId: number;
  readonly cieTradeRequestTypeName: string;
  readonly material: string;
}

class CieTradeRequestType {
  cieTradeRequestTypeId?: number;
  cieTradeRequestTypeName?: string;
  material?: string;

  constructor(data?: Partial<ICieTradeRequestType>) {
    this.cieTradeRequestTypeId = data?.cieTradeRequestTypeId;
    this.cieTradeRequestTypeName = data?.cieTradeRequestTypeName;
    this.material = data?.material;
  }
}

export default CieTradeRequestType;
