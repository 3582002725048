import moment from 'moment';

export const decimal = (numberValue?: string | number): string => Number(numberValue).toFixed(2).replace(/[.,]00$/, '');
export const localDate = (dateValue?: string | number | Date): string =>
  moment(dateValue).format('L');
export const time = (dateValue?: string | number | Date): string =>
  moment(dateValue).format('LT');
export const dateTime = (dateValue?: string | number | Date): string =>
  moment(dateValue).format('MM/DD/YY h:mm A');

const currencyStyle = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const currency = (numberValue?: number, cutDecimals = false): string => {
  const formatter = new Intl.NumberFormat(
    'en-US',
    cutDecimals ? currencyStyle : { style: 'currency', currency: 'USD' },
  );

  return formatter.format(numberValue || 0);
};

export const formatThousandsToK = (numberValue: number, decimals = 2): string => {
  const amountPrefix = numberValue < 0 ? '-' : '';
  const rawAmount = Math.abs(numberValue);

  let formattedAmount;
  if (rawAmount >= 1000) {
    const cutAmount =
      decimals === 1 || decimals === 2 ? 10 ** (3 - decimals) : 1;
    const decAmount = decimals >= 1 ? 10 ** decimals : 1000;
    const amount = currency(
      Math.round(rawAmount / cutAmount) / decAmount,
      true,
    );
    formattedAmount = `${amount}k`;
  } else if (rawAmount > 0 && rawAmount < 1) {
    const pow = decimals <= 1 ? 10 : 10 ** decimals;
    formattedAmount = currency(Math.round(rawAmount * pow) / pow, true);
  } else {
    formattedAmount = currency(
      Math.round(rawAmount * 10 ** decimals) / 10 ** decimals,
      true,
    );
  }

  return amountPrefix + formattedAmount;
};

export const decimalFormatted = (numberValue: number, maxDigits = 2): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  });

  return formatter.format(numberValue);
};

export const decimalFormattedShort = (
  numberValue: number,
  maxDigits = 1,
  scaleToSymbol = '',
): string => {
  const thresholds = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  let indexThresholds;
  if (scaleToSymbol) {
    indexThresholds = thresholds.findIndex(t => t.symbol === scaleToSymbol);
  }
  if (!indexThresholds) {
    for (
      indexThresholds = thresholds.length - 1;
      indexThresholds > 0;
      indexThresholds--
    ) {
      if (numberValue >= thresholds[indexThresholds].value) {
        break;
      }
    }
  }
  return (
    (numberValue / thresholds[indexThresholds].value)
      .toFixed(maxDigits)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    thresholds[indexThresholds].symbol
  );
};

export const formatIntegerBetween = (min: number, max: number, value: string): number | undefined => {
  const val = parseInt(value);
  if (Number.isNaN(val)) return undefined;
  return val > max
    ? max
    : val < min
      ? min
      : (+val.toFixed(1));
};

export const roundToTwo = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
