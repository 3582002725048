import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(156, 175, 195, 0.1);
  padding:20px 50px;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
`;

export const SectionTitle = styled.div`
  flex-grow: 2;
  max-width:50%;
`;

export const SectionCommunicationType = styled.div`
  flex-grow: 1;
  min-width:25%;
`;

export const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

export const SectionCommunicationItemName = styled.div`
  flex-grow: 2;
  font-weight: 500;
  font-size: 14px;
  max-width:50%;
`;

export const SectionCommunicationItem = styled.div`
  display: flex;
  flex-direction: row;
  padding:15px 50px;
`;

export const NoSettingsAvailableNotification = styled.div`
  padding: 50px;
  font-size: 24px;
  text-align: center;
  width: 76%;
  margin: 0 auto;
`;
