import React from 'react';

import { Navigate } from 'react-router-dom';

const NewWindowRoute: React.FC<ComponentProps> = ({ url, backToPath }) => {
  window.open(url, '_blank');
  return <Navigate to={backToPath} />;
};

interface ComponentProps {
  url: string;
  backToPath: string;
}

export default NewWindowRoute;
