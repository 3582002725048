import React from 'react';

import { theme } from 'core/styles';

import { IconAction as IconActionStyled } from './styled';
import { IconActionProps } from './styled/IconAction';

const IconAction: React.FC<ComponentProps> = ({ icon, ...rest }) => {
  switch (icon) {
    case 'ban':
      return <IconActionStyled className='icon-ban' {...rest} alt='icon' />;

    case 'chat-bubble':
      return (
        <IconActionStyled className='icon-chat-bubble' {...rest} alt='icon' />
      );

    case 'close':
      return <IconActionStyled className='icon-close' {...rest} alt='icon' />;

    case 'delete':
      return <IconActionStyled className='icon-trash' {...rest} alt='icon' />;

    case 'left-arrow':
      return (
        <IconActionStyled className='icon-arrow-left' {...rest} alt='icon' />
      );

    case 'chevron-down':
      return (
        <IconActionStyled className='icon-chevron-down' {...rest} alt='icon' />
      );

    case 'chevron-up':
      return (
        <IconActionStyled className='icon-chevron-up' {...rest} alt='icon' />
      );

    case 'chevron-left':
      return (
        <IconActionStyled className='icon-chevron-down' {...rest} alt='icon' rotateZ={90} />
      );
    case 'chevron-right':
      return (
        <IconActionStyled className='icon-chevron-down' {...rest} alt='icon' rotateZ={-90} />
      );

    case 'file-o':
      return (
        <IconActionStyled
          className='icon-file-o'
          alt='icon'
          title='Download Invoice'
          {...rest}
        />
      );
    case 'edit':
      return <IconActionStyled className='icon-pencil' {...rest} alt='icon' />;

    case 'down-arrow':
      return (
        <IconActionStyled className='icon-down-arrow' {...rest} alt='icon' />
      );

    case 'leaf':
      return (
        <IconActionStyled
          className='icon-leaf'
          {...rest}
          alt='icon'
          color={theme.colors.brandWhite}
        />
      );

    case 'down-arrow-triangle':
      return (
        <IconActionStyled className='icon-arrow-down-b' {...rest} alt='icon' />
      );

    case 'check-mark':
      return (
        <IconActionStyled
          className='icon-checkmark-round'
          {...rest}
          alt='icon'
        />
      );

    default:
      return <></>;
  }
};

interface ComponentProps extends IconActionProps {
  icon: string;
}

export default IconAction;
