import React, { useEffect } from 'react';

import memoizeOne from 'memoize-one';
import { useDispatch, useSelector } from 'react-redux';

import {
  BinEquipmentFilterModel,
  EquipmentDisplay,
} from 'contracts/types/compactorProfile';
import { EquipmentFilter } from 'contracts/types/component';
import { ApplicationState } from 'contracts/types/state';
import translate from 'core/helpers/translate';

import locationFilterDuck from '../../ducks/filters';
import { actionIsRunning } from '../../ducks/running';
import DropdownFilter from '../DropdownFilter';

const EquipmentFilterBase: React.FC<ComponentProps> = ({ currentFilter, onFilterChanged, isDisabled }) => {
  const dispatch = useDispatch();

  const allEquipments = useSelector(
    (state: ApplicationState) => mapEquipments(state.core.locationFilters.equipments)
  );
  
  const isLoadingEquipments = useSelector(
    (state: ApplicationState) => actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_EQUIPMENTS,
    ),
  );

  useEffect(() => {
    dispatch(locationFilterDuck.thunks.loadFilterEquipments());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onFilterChange = (selectedIds: string[]): void => {
    onFilterChanged({
      ...currentFilter,
      equipments: selectedIds,
    });
  };
  return (
    <DropdownFilter
      label={translate('equipment')}
      values={allEquipments}
      selectedIds={currentFilter.equipments || []}
      onFilterChanged={onFilterChange}
      isLoading={isLoadingEquipments}
      isDisabled={isDisabled}
    />
  );
};

type ComponentProps = OwnProps & StateProps & DispatchProps;

interface OwnProps {
  currentFilter: EquipmentFilter;
  onFilterChanged: (selectedIds: { equipments: string[] }) => void;
  isDisabled?: boolean;
}

interface StateProps {
  allEquipments: EquipmentDisplay[];
  isLoadingEquipments: boolean;
}

interface DispatchProps {
  loadEquipments: () => void;
  onFilterChanged: (selectedIds: {
    equipments: string[];
}) => void;
}

const mapEquipments = memoizeOne(
  (equipments: BinEquipmentFilterModel[]): EquipmentDisplay[] =>
    equipments &&
    equipments.map(
      (eq: BinEquipmentFilterModel) =>
        ({
          id: eq.binClassPrefix,
          label: eq.name,
        } as EquipmentDisplay),
    ),
);

export { EquipmentFilterBase };
export default EquipmentFilterBase;
