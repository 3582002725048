import React, { PropsWithChildren, PureComponent } from 'react';

import { LocationEquipmentStatusFilter, LocationFilter } from 'contracts/types/component';
import { LocationFilters } from 'core/components';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

const equipCategories = [
  { id: 'temporary', label: translate('temporary') },
  { id: 'permanent', label: translate('permanent') },
];

class EquipmentStatusFilter extends PureComponent<PropsWithChildren<ComponentProps>> {
  onLocationFilterChanged = (filter: LocationFilter): void => {
    const { filterChanged } = this.props;
    filterChanged({ ...filter });
  };

  onFilterChanged = (selectedIds: string[]): void => {
    const { currentFilter, filterChanged } = this.props;
    
    filterChanged({
      ...currentFilter,
      isTemporary: Boolean(selectedIds && selectedIds.includes(equipCategories[0].id)),
      isPermanent: Boolean(selectedIds && selectedIds.includes(equipCategories[1].id))
    });
  };

  render(): React.ReactNode {
    const {
      currentFilter,
      hasCityZipFilter,
      isDisabled,
      children,
    } = this.props;
    const selectedIds: string[] = [];
    if (currentFilter && currentFilter.isTemporary) {
      selectedIds.push(equipCategories[0].id);
    }
    if (currentFilter && currentFilter.isPermanent) {
      selectedIds.push(equipCategories[1].id);
    }
    return (
      <LocationFilters
        currentFilter={currentFilter}
        onFilterChanged={this.onLocationFilterChanged}
        isDisabled={isDisabled}
        hasCityZipFilter={hasCityZipFilter}
      >
        <DropdownFilter
          label={translate('core.equipmentStatus')}
          values={equipCategories}
          selectedIds={selectedIds}
          onFilterChanged={this.onFilterChanged}
          isDisabled={isDisabled}
        />
        {children ? children : null}
      </LocationFilters>
    );
  }
}

interface ComponentProps {
  currentFilter: LocationEquipmentStatusFilter;
  filterChanged: (filter: LocationEquipmentStatusFilter) => void;
  isDisabled?: boolean;
  hasCityZipFilter?: boolean;
}

export default EquipmentStatusFilter;
