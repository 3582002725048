import { combineReducers } from 'redux';

import { reducer as bulkDataUploadReducer } from './bulkServicesUpload';
import { reducer as onDemandPickUpsReducer } from './onDemandPickUpsEdit';
import { reducer as recurringServicesEditReducer } from './recurringServicesEdit';

const reducer = combineReducers({
  bulkUploadData: bulkDataUploadReducer,
  recurringServicesEdit: recurringServicesEditReducer,
  onDemandPickUps: onDemandPickUpsReducer
});

export default reducer;
