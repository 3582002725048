import { getSessionUser } from 'account/services/session';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import { brandedContentDictionary, subscriptionItemsDictionary, subscriptionsDictionary } from 'contracts/enums/tieredSubscriptionModulesDictionary';
import User from 'contracts/models/service/User';

const authorizeSubscriptionModule = (componentKey?: SubscriptionComponentKey): boolean => {
  const user: User = getSessionUser();
  const userSubscription = user.subscription || null;
  const userSubscriptionItems = user.subscriptionItems || null;
  const userBrandings = user.brandings || null;

  if (!componentKey) {
    return true;
  }
  
  if (!userSubscription && !userSubscriptionItems) {
    return false;
  }

  // subscription validation - find the component key in the subscriptions dictionary
  const matchedSubscriptions = subscriptionsDictionary.filter(
    subscriptionSet => subscriptionSet.components.includes(componentKey),
  );

  const matchedSubscriptionItems = subscriptionItemsDictionary.filter(
    subscriptionItem =>
      subscriptionItem.components.includes(componentKey)
  );

  const matchedBrandings = brandedContentDictionary.filter(
    brandingItem =>
      brandingItem.components.includes(componentKey)
  );

  if (matchedSubscriptions.length > 0) {
    const matchedCurrentUserSubscriptions = matchedSubscriptions.filter(
      subscriptionSet => userSubscription === subscriptionSet.subscriptionKey,
    );
    if (matchedCurrentUserSubscriptions && matchedCurrentUserSubscriptions.length > 0) {
      if (matchedSubscriptionItems.length === 0 && matchedBrandings.length === 0) {
        return true;
      }
    } else {
      return false;
    } 
  }

  // subscription items validation - find the component key in the subscription items dictionary
  if (matchedSubscriptionItems.length > 0) {
    const matchedCurrentUserSubscriptionItems = matchedSubscriptionItems.filter(
      subscriptionItem =>
        userSubscriptionItems?.includes(subscriptionItem.moduleKey)
    );

    if (matchedCurrentUserSubscriptionItems.length > 0) {
      if (matchedBrandings.length === 0) {
        return true;
      }
    } else {
      return false;
    }
    
  } 
  
  // branding validation - find the component key in the branded content dictionary
  if (matchedBrandings.length > 0) {
    const matchedCurrentUserBrandings = matchedBrandings.filter(
      brandingItem =>
        userBrandings?.includes(brandingItem.brandingKey)
    );

    if (matchedCurrentUserBrandings.length > 0) {
      return true;
    } else {
      return false;
    }
  } 

  return false;
};

export default authorizeSubscriptionModule;
