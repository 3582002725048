import { RegWatchAssessment } from '..';

export interface IRegWatchAssessmentMetrics {
  readonly assessments: RegWatchAssessment[];
}

class RegWatchAssessmentMetrics {
  assessments?: RegWatchAssessment[];
  completedAssessments?: RegWatchAssessment[];

  constructor(data?: Partial<IRegWatchAssessmentMetrics>) {
    if (data && data.assessments) {
      this.assessments = data.assessments.map(assessment => new RegWatchAssessment(assessment));
    }
  }
}

export interface RegWatchSitesSummary {
  highRisk: number;
  mediumRisk: number;
  lowRisk: number;
}
export interface RegWatchAssessmentsSummary {
  yes: number;
  no: number;
  notApplicable: number;
  noResponse: number;
}

export default RegWatchAssessmentMetrics;
