import React, { PropsWithChildren } from 'react';

import Script from './Script';

const ZuoraScript: React.FC<PropsWithChildren<ComponentProps>> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='zuoraScript'
      isUrl
      content='https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora.js'
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
    >
      {children}
    </Script>
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
}

export default ZuoraScript;
