import { TableData } from 'contracts/types/component';

export interface PaymentInvoiceItemModel extends TableData{
  statementId: string;
  invoiceNumber: string;
  invoiceTotal: number;
  paymentTotal: number | null;
  remainingTotal: number | null;
  custId: string;
  siteId: string;
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zip: string;
  paymentStatus: string;
  statementStatus: string;
  zuoraInvoiceId: string;
  zuoraInvoiceBalance: string;
  isHidden?: boolean;
}

export interface IPaymentItemModel {
  expandSelection: boolean;
  paymentIdentifier: string;
  paymentAmmount: number;
  paymentMethod: string;
  paymentStatus: string;
  zuoraPaymentStatus: string;
  paymentDate: string;
  totalInvoiceBalances: number;
  invoiceTotal: number;
  invoices: PaymentInvoiceItemModel[];
}

class PaymentItemModel implements TableData {
  expandSelection?: boolean;
  paymentIdentifier?: string;
  paymentAmmount?: number;
  paymentMethod?: string;
  paymentStatus?: string;
  zuoraPaymentStatus?: string;
  paymentDate?: string;
  totalInvoiceBalances?: number;
  invoices?: PaymentInvoiceItemModel[];
  invoiceTotal?: number;
  isHidden?: boolean;

  constructor(data?: Partial<IPaymentItemModel>) {
    this.paymentIdentifier = data?.paymentIdentifier;
    this.paymentAmmount = data?.paymentAmmount;
    this.paymentMethod = data?.paymentMethod;
    this.paymentStatus = data?.paymentStatus;
    this.zuoraPaymentStatus = data?.zuoraPaymentStatus;
    this.paymentDate = data?.paymentDate;
    this.totalInvoiceBalances = data?.totalInvoiceBalances;
    this.invoices = data?.invoices;
    this.invoiceTotal = data?.invoiceTotal;
    this.expandSelection = data?.expandSelection;
  }
}

export default PaymentItemModel;
