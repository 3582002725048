import update from 'immutability-helper';

import { BlogPost, TwitterPost } from 'contracts/models';
import { ActionDispatcher, NewsAction } from 'contracts/types/action';
import {
  ApplicationState,
  NewsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getBlogPosts,
  getEnvironmentSafetyBlogPosts,
  getTwitterPosts,
  getPublicPolicyPosts,
} from '../services/news';

// Actions Keys
const ROOT_KEY = 'news';
enum ActionKey {
  LOAD_TWITTER = 'news/LOAD_TWITTER',
  LOAD_BLOG = 'news/LOAD_BLOG',
  LOAD_ENVIRONMENT_SAFETY_BLOG = 'news/LOAD_ENVIRONMENT_SAFETY_BLOG',
  LOAD_PUBLIC_POLICY = 'news/LOAD_PUBLIC_POLICY',
  RESET = 'news/RESET',
}

// Initial State
const getInitialState: () => NewsState = () => {
  return {
    blogPosts: [],
    twitterPosts: [],
    environmentSafetyBlogPosts: [],
    publicPolicyPosts: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_TWITTER,
  ActionKey.LOAD_BLOG,
  ActionKey.LOAD_ENVIRONMENT_SAFETY_BLOG,
  ActionKey.LOAD_PUBLIC_POLICY,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NewsState,
  NewsAction
> = {
  [ActionKey.LOAD_TWITTER]: (state, action) => {
    const { twitterPosts } = action;
    if (!twitterPosts) {
      return state;
    }
    return update(state, { $merge: { twitterPosts } });
  },
  [ActionKey.LOAD_BLOG]: (state, action) => {
    const { blogPosts } = action;
    if (!blogPosts) {
      return state;
    }
    return update(state, { $merge: { blogPosts } });
  },
  [ActionKey.LOAD_ENVIRONMENT_SAFETY_BLOG]: (state, action) => {
    const { environmentSafetyBlogPosts } = action;
    if (!environmentSafetyBlogPosts) {
      return state;
    }
    return update(state, { $merge: { environmentSafetyBlogPosts } });
  },
  [ActionKey.LOAD_PUBLIC_POLICY]: (state, action) => {
    const { publicPolicyPosts } = action;
    if (!publicPolicyPosts) {
      return state;
    }
    return update(state, { $merge: { publicPolicyPosts } });
  }
};

export const reducer = getReducerBuilder<NewsState, NewsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_TWITTER: (twitterPosts?: TwitterPost[]): NewsAction => ({
    type: ActionKey.LOAD_TWITTER,
    twitterPosts,
  }),
  LOAD_BLOG: (blogPosts?: BlogPost[]): NewsAction => ({
    type: ActionKey.LOAD_BLOG,
    blogPosts,
  }),
  LOAD_ENVIRONMENT_SAFETY_BLOG: (
    environmentSafetyBlogPosts?: BlogPost[],
  ): NewsAction => ({
    type: ActionKey.LOAD_ENVIRONMENT_SAFETY_BLOG,
    environmentSafetyBlogPosts,
  }),
  LOAD_PUBLIC_POLICY: (
    publicPolicyPosts?: BlogPost[],
  ): NewsAction => ({
    type: ActionKey.LOAD_PUBLIC_POLICY,
    publicPolicyPosts,
  }),
  RESET: (): NewsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadTwitterPosts = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TWITTER,
    () => getTwitterPosts(),
    result => {
      dispatch(actionMap.LOAD_TWITTER(result));
    },
    () => {
      dispatch(actionMap.LOAD_TWITTER());
    },
    false,
  );

const loadBlogPosts = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_BLOG,
    () => getBlogPosts(),
    result => dispatch(actionMap.LOAD_BLOG(result)),
    () => {
      dispatch(actionMap.LOAD_BLOG());
    },
    false,
  );

const loadEnvironmentSafetyBlogPosts = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ENVIRONMENT_SAFETY_BLOG,
    () => getEnvironmentSafetyBlogPosts(),
    result => dispatch(actionMap.LOAD_ENVIRONMENT_SAFETY_BLOG(result)),
    () => {
      dispatch(actionMap.LOAD_ENVIRONMENT_SAFETY_BLOG());
    },
    false,
  );

const loadPublicPolicyPosts = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PUBLIC_POLICY,
    () => getPublicPolicyPosts(),
    result => dispatch(actionMap.LOAD_PUBLIC_POLICY(result)),
    () => {
      dispatch(actionMap.LOAD_PUBLIC_POLICY());
    },
    false,
  );

const newsDuck = {
  thunks: { loadTwitterPosts, loadBlogPosts, loadEnvironmentSafetyBlogPosts, loadPublicPolicyPosts },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default newsDuck;
