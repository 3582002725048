import pick from 'lodash-es/pick';

import { WholesaleCart } from 'contracts/models';
import { LocationsList } from 'contracts/models/service/WholesaleCart';

export const transformCartToSend = (cart: WholesaleCart): {
  locations: Array<Pick<LocationsList, 'address' | 'services'>>;
} => {
  const formattedCart = {
    locations: cart.locations
      ? cart.locations.map(location => pick(location, ['address', 'services']))
      : [],
  };

  return formattedCart;
};

export default transformCartToSend;
