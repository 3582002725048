import React, { PureComponent } from 'react';

import Checkbox from '../Checkbox';
import { DropdownFilterPopupRow } from '../styled/DropdownFilter';

class DropdownFilterChecklistRow<
  T extends number | string
> extends PureComponent<ComponentProps<T>> {
  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { onCheckboxChange, entry } = this.props;
    onCheckboxChange(event, entry.id);
  };

  render(): React.ReactNode {
    const { entry, checked, checkboxLabelLineCount = 2 } = this.props;
    return (
      <DropdownFilterPopupRow checkboxLabelLineCount={checkboxLabelLineCount}>
        <Checkbox
          checked={checked}
          onChange={this.onCheckboxChange}
          label={entry.label}
          checkboxLabelLineCount={checkboxLabelLineCount}
        />
      </DropdownFilterPopupRow>
    );
  }
}

interface ComponentProps<T extends number | string> {
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: T) => void;
  checked: boolean;
  entry: {
    id: T;
    label: string;
  };
  checkboxLabelLineCount?: number;
}

export default DropdownFilterChecklistRow;
