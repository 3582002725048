import { TableData } from 'contracts/types/component';

export interface ITagImportDocument {
  readonly documentId?: number;
  readonly filename?: string;
  readonly wasUploadedByUser?: boolean;
  readonly creationDate?: Date;
  readonly username?: string;
}

class TagImportDocument implements TableData {
  documentId?: number;
  filename?: string;
  wasUploadedByUser?: boolean;
  creationDate?: Date;
  username?: string;
  isHidden?: boolean;

  constructor(data?: Partial<ITagImportDocument>) {
    this.documentId = data?.documentId;
    this.filename = data?.filename;
    this.wasUploadedByUser = data?.wasUploadedByUser;
    this.creationDate = data?.creationDate;
    this.username = data?.username;
  }
}

export default TagImportDocument;
