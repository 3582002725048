import { TableData } from 'contracts/types/component';

export interface INationalInvoice {
  readonly invoiceNumber: number;
  readonly customerId: string;
  readonly invoiceDate: string;
  readonly invoiceTotal: number;
  readonly hasFile: boolean;
}

class NationalInvoice implements TableData {
  invoiceNumber?: number;
  customerId?: string;
  invoiceDate?: string;
  invoiceTotal?: number;
  hasFile?: boolean;
  isHidden?: boolean;

  constructor(data?: Partial<INationalInvoice>) {
    this.invoiceNumber = data?.invoiceNumber;
    this.customerId = data?.customerId;
    this.invoiceDate = data?.invoiceDate;
    this.invoiceTotal = data?.invoiceTotal;
    this.hasFile = data?.hasFile;
  }
}

export default NationalInvoice;
