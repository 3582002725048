enum FieldErrorType {
  Required = 'required',
  MinLength = 'minLength',
  MaxLength = 'maxLength',
  Min = 'min',
  Max = 'max',
  Pattern = 'pattern',
  Validate = 'validate',
}
export default FieldErrorType;
