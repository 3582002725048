/* eslint-disable import/prefer-default-export */
import { WholesaleNavigationItem } from 'contracts/models/service/WholesaleCart';

export const initialNavigationItems: WholesaleNavigationItem[] = [
  {
    isActive: true,
    isChecked: false,
    navLabel: 'Location',
    navInfo: '',
    navPath: 'location',
    labelIndex: 1,
  },
  {
    isActive: false,
    isChecked: false,
    navLabel: 'Service',
    navInfo: '',
    navPath: 'service',
    labelIndex: 2,
  },
  {
    isActive: false,
    isChecked: false,
    navLabel: 'Date',
    navInfo: '',
    navPath: 'date',
    labelIndex: 3,
  },
];
