/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { DebouncedFunc } from 'lodash-es/debounce';
import has from 'lodash-es/has';
import { FieldError } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import Error from './Error';
import { FormGroup, FormLabel } from './styled';
import { FormRequired } from './styled/FormError';
import {
  typeAheadStyles,
  groupStyles,
  groupBadgeStyles,
  MyOptionType,
} from './styled/TypeAheadStyles';

const formatGroupLabel = (data: any): JSX.Element => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const TypeAhead = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({
    value,
    onChange,
    errors,
    disabled,
    isRequired,
    placeholder,
    label,
    getOptions,
    unconnected,
    noLengthRestriction,
    ...props
  }, ref) => {
    const [inputText, setInputText] = useState<string>('');

    const [option, setOption] = useState<MyOptionType | undefined>(undefined);

    const onInputChange = (inputText: string): void => {
      setInputText(inputText);
    };

    const formOnChange = (option: any): void => {
      const value = has(option, 'value') ? option.value : null;
      setOption(option);
      onChange(value);
    };

    const findSelectedOption = (): MyOptionType | undefined => {
      const formattedOption = option;
      if (formattedOption && formattedOption.label && option) {
        formattedOption.label = option.label;
      }

      return formattedOption;
    };

    const noOptionsMessage = ({ inputValue }: { inputValue: string }): string => {
      return inputValue.length < 3 && !noLengthRestriction
        ? 'Please type at least three characters'
        : 'No results';
    };

    return (
      <FormGroup
        noMargin
        hasValue={!!(value || inputText)}
        unconnected={unconnected}
      >
        {isRequired ? <FormRequired>*</FormRequired> : null}
        {!!label && <FormLabel>  {label}</FormLabel>}
        <AsyncSelect
          {...props}
          isSearchable
          isDisabled={disabled}
          styles={typeAheadStyles}
          formatGroupLabel={formatGroupLabel}
          loadOptions={getOptions}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder || ''}
          value={findSelectedOption()}
          onChange={formOnChange}
          onInputChange={onInputChange}
        />
        {/* {errors &&
        errors.map(error => <FormError key={error}>{error}</FormError>)} */}
        {errors && <Error error={errors} />}
      </FormGroup>
    );
  });

interface ComponentProps {
  disabled: boolean;
  isRequired?: boolean;
  placeholder?: string;
  label?: string;
  getOptions: DebouncedFunc<(searchTerm: any, onOptionsLoaded: any) => void>; 
  noLengthRestriction: boolean;
  unconnected: boolean;
  onChange: (formData: string) => void;
  value?: any;
  errors?: FieldError;
}

export default TypeAhead;
