import React, { useEffect } from 'react';

import memoizeOne from 'memoize-one';
import { useDispatch, useSelector } from 'react-redux';

import { FilterMaterial } from 'contracts/models';
import { MaterialSpendFilter } from 'contracts/types/component';
import { ApplicationState } from 'contracts/types/state';
import locationFilterDuck from 'core/ducks/filters';
import { actionIsRunning } from 'core/ducks/running';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';
import { DropdownFilterValue } from '../DropdownFilter/DropdownFilterPopup';

const MaterialFilter: React.FC<OwnProps> = ({ currentFilter, onFilterChanged, isDisabled }) => {
  const dispatch = useDispatch();

  const allMaterials = useSelector(
    (state: ApplicationState) => mapMaterials(state.core.locationFilters.materialCategories)
  );
  
  const isLoadingMaterials = useSelector(
    (state: ApplicationState) => actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_MATERIAL_CATEGORIES,
    ),
  );
  
  useEffect(() => {
    dispatch(locationFilterDuck.thunks.loadFilterMaterialCategories());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChange = (selectedIds: number[]): void => {
    onFilterChanged({
      ...currentFilter,
      materials: selectedIds,
    });
  };

  return (
    <DropdownFilter
      label={translate('material')}
      values={allMaterials}
      selectedIds={currentFilter.materials ? currentFilter.materials : []}
      onFilterChanged={onFilterChange}
      isLoading={isLoadingMaterials}
      isDisabled={isDisabled}
    />
  );

};

interface OwnProps {
  currentFilter: MaterialSpendFilter;
  onFilterChanged: (filter: MaterialSpendFilter) => void;
  isDisabled?: boolean;
}

const mapMaterials = memoizeOne<(materialCategories: FilterMaterial[]) => 
Array<DropdownFilterValue<number>>>(materialCategories =>
    materialCategories.length > 0
      ? materialCategories
        .filter(mc => mc.id && mc.name)
        .map(mc => mc as Required<FilterMaterial>)
        .map(mc => ({
          id: mc.id,
          label: mc.name,
        }))
      : [],
  );

export default MaterialFilter;
