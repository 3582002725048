import { TableData } from 'contracts/types/component';

import { RegWatchSite } from './RegWatchUpdates';

export interface IRegWatchAssessment extends RegWatchSite {
  yesHigh: number;
  noHigh: number;
  yesMedium: number;
  noMedium: number;
  yesLow: number;
  noLow: number;
  notApplicable: number;
  noResponse: number;
  riskType: RiskType;
  regWatchUrl: string;
}

class RegWatchAssessment implements TableData {
  name?: string;
  addr1?: string;
  siteID?: string;
  state?: string;
  country?: string;
  yesHigh?: number;
  noHigh?: number;
  yesMedium?: number;
  noMedium?: number;
  yesLow?: number;
  noLow?: number;
  notApplicable?: number;
  noResponse?: number;
  riskType?: RiskType;
  regWatchUrl?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IRegWatchAssessment>) {
    this.name = data?.name;
    this.addr1 = data?.addr1;
    this.siteID = data?.siteID;
    this.state = data?.state;
    this.country = data?.country;
    this.yesHigh = data?.yesHigh;
    this.noHigh = data?. noHigh;
    this.yesMedium = data?. yesMedium;
    this.noMedium = data?. noMedium;
    this.yesLow = data?. yesLow;
    this.noLow = data?. noLow;
    this.notApplicable = data?. notApplicable;
    this.noResponse = data?. noResponse;
    this.riskType = data?. riskType;
    this.regWatchUrl = data?. regWatchUrl;
  }
}

export enum RiskType {
  Low = 'lo',
  Medium = 'med',
  High = 'hi',
}

export default RegWatchAssessment;
