export interface ICustomerTagSite {
  readonly tagSiteId?: number;
  readonly siteId?: string;
  readonly custId?: string;
  readonly name?: string;
  readonly addr1?: string;
  readonly addr2?: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly country?: string;
  readonly latitude?: string;
  readonly longitude?: string;
}

class CustomerTagSite {
  tagSiteId?: number;
  siteId?: string;
  custId?: string;
  name?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  latitude?: string;
  longitude?: string;

  // temp
  isChecked: boolean;

  constructor(data?: Partial<ICustomerTagSite>) {
    this.tagSiteId = data?.tagSiteId;
    this.siteId = data?.siteId;
    this.custId = data?.custId;
    this.name = data?.name;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.country = data?.country;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;

    // temp
    this.isChecked = false;
  }
}

export interface ICustomerTagSiteAssigned extends ICustomerTagSite {
  readonly isAssigned: boolean;
}
export class CustomerTagSiteAssigned extends CustomerTagSite {
  isAssigned: boolean;

  constructor(data?: Partial<ICustomerTagSiteAssigned>) {
    super(data);
    this.isAssigned = data?.isAssigned || false;
  }
}

export default CustomerTagSite;
