import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import { ZuoraScript } from 'core/components/Scripts';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import { BillingRoutes } from './billingRoutes';

const CompletePaymentPage = lazy(() => import('billing/components/CompletePayment/CompletePaymentPage'));
const MakePaymentPage = lazy(() => import('billing/components/MakePayment/MakePaymentPage'));
const PaymentSetupPage = lazy(() => import('billing/components/PaymentSetup/PaymentSetupPage'));
const SummaryPage = lazy(() => import('billing/components/Summary/SummaryPage'));

const BillingRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={BillingRoutes.summary} element={
      <AuthRoute componentKey={SubscriptionComponentKey.SummaryPage}>
        <ZuoraScript><SummaryPage /></ZuoraScript>
      </AuthRoute>
    } />
    <Route path={BillingRoutes.paymentSetup} element={
      <AuthRoute componentKey={SubscriptionComponentKey.PaymentSetupPage}>
        <ZuoraScript><PaymentSetupPage /></ZuoraScript>
      </AuthRoute>
    } />
    <Route path={BillingRoutes.makePayment} element={
      <AuthRoute componentKey={SubscriptionComponentKey.MakePaymentPage}>
        <ZuoraScript><MakePaymentPage /></ZuoraScript>
      </AuthRoute>
    } />
    <Route path={BillingRoutes.completePayment} element={
      <AuthRoute componentKey={SubscriptionComponentKey.CompletePaymentPage}>
        <ZuoraScript><CompletePaymentPage /></ZuoraScript>
      </AuthRoute>
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={BillingRoutes.root + BillingRoutes.summary} replace />} />
    <Route index element={<Navigate to={BillingRoutes.root + BillingRoutes.summary} replace/>} />
  </Routes>
);

export default BillingRouter;
