export interface MaterialProfileCategoryItem {
  name: string;
  identifier: string;
  tonnage: number;
  pounds: number;
}

export interface MaterialSiteProfile {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any;
  name?: string;
}

export interface IMaterialProfileSummary {
  readonly totalTons: number;
  readonly totalPounds: number;
  readonly categories: MaterialProfileCategoryItem[];
}

class MaterialProfileSummary {
  totalTons?: number;
  totalPounds?: number;
  categories?: MaterialProfileCategoryItem[];

  constructor(data?: Partial<IMaterialProfileSummary>) {
    this.totalTons = data?.totalTons;
    this.totalPounds = data?.totalPounds;
    this.categories = data?.categories;
  }
}

export default MaterialProfileSummary;
