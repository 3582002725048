export interface ICustomerSiteEquipment {
  readonly equipmentName: string;
  readonly count: number;
}

class CustomerSiteEquipment {
  equipmentName?: string;
  count?: number;

  constructor(data?: Partial<ICustomerSiteEquipment>) {
    this.equipmentName = data?.equipmentName;
    this.count = data?.count;
  }
}

export default CustomerSiteEquipment;
