import update from 'immutability-helper';

import { MarketingContentItem } from 'contracts/models';
import {
  ActionDispatcher, RecognitionMarketingContentAction,
} from 'contracts/types/action';
import { MarketingContentRequest } from 'contracts/types/request';
import {
  ApplicationState,
  RecognitionMarketingContentState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getMarketingCategories, getMarketingContentData } from 'recognition/services/recognitionService';

// Actions Keys
const ROOT_KEY = 'recognition/marketing-content';
enum ActionKey {
  LOAD_CATEGORIES = 'recognition/marketing-content/LOAD_CATEGORIES',
  LOAD_DATA = 'recognition/marketing-content/LOAD_DATA',
  RESET = 'recognition/marketing-content/RESET',
}

// Initial state
const getInitialState: () => RecognitionMarketingContentState = () => {
  return {
    data: [],
    categories: []
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_DATA, ActionKey.LOAD_CATEGORIES] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RecognitionMarketingContentState,
  RecognitionMarketingContentAction
> = {
  [ActionKey.LOAD_CATEGORIES]: (state, action) => {
    const { categories } = action;
    return update(state, {

      $merge: {
        categories,
      },
    });
  },
  [ActionKey.LOAD_DATA]: (state, action) => {
    const { data } = action;
    return update(state, {
      $merge: {
        data,
      },
    });
  },
};

export const reducer = getReducerBuilder<
RecognitionMarketingContentState,
RecognitionMarketingContentAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_CATEGORIES: (
    categories?: string[],
  ): RecognitionMarketingContentAction => ({
    type: ActionKey.LOAD_CATEGORIES,
    categories,
  }),
  LOAD_DATA: (
    data?: MarketingContentItem[],
  ): RecognitionMarketingContentAction => ({
    type: ActionKey.LOAD_DATA,
    data,
  }),
  RESET: (): RecognitionMarketingContentAction => ({ type: ActionKey.RESET }),
};

// Thunks
const getCategories = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CATEGORIES,
    () => getMarketingCategories(),
    result => {
      dispatch(actionMap.LOAD_CATEGORIES(result));
    },
    () => dispatch(actionMap.LOAD_CATEGORIES()),
  );

const getData = (request: MarketingContentRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_DATA,
    () => getMarketingContentData(request),
    result => dispatch(actionMap.LOAD_DATA(result)),
    () => dispatch(actionMap.LOAD_DATA()),
  );

const recognitionMarketingContentDuck = {
  thunks: {
    getData,
    getCategories,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default recognitionMarketingContentDuck;
