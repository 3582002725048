import { transparentize } from 'polished';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

import customerPortalScrollbar from 'core/components/styled/Scrollbar';
import { theme } from 'core/styles';

type TextAreaStyleProps = ThemedStyledProps<TextAreaProps, DefaultTheme>;
export const TextAreaStyle = (props: TextAreaStyleProps): string => `
  resize: none;
  width: 100%;
  height: 35px;
  min-height: 35px;
  padding: 2px 2px 8px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${transparentize(0.9, theme.colors.brandBlack)};
  border-radius: 0;
  line-height: 24px;
  font-size: 14px;
  font-family: ${props.theme.fontFamilyBase};
  color: ${transparentize(0.1, theme.colors.brandBlack)};

  &:focus {
    border-bottom-color: ${props.theme.colors.brandPrimary};
  }
  &::placeholder {
    font-size: 14px;
  }
  ${customerPortalScrollbar()}

`;

interface TextAreaProps {
  maxHeight?: number;
  isHidden?: boolean;
  inline?: boolean;
  halfWidth?: boolean;
}
const Textarea = styled.textarea<TextAreaProps>`
  ${TextAreaStyle}

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
  
    ${props =>
    props.isHidden &&
      css`
        display: none;
      `};
  
    ${props =>
    props.inline &&
      css`
        min-width: 200px;
        width: auto;
        margin-right: 10px;
      `};
  
    ${props =>
    props.halfWidth &&
      css`
        width: 50%;
      `};
`;

export default Textarea; 
