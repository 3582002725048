import decode from 'jwt-decode';
import * as ls from 'local-storage';
import moment from 'moment';

import { AccountRoutes } from 'account/routing';
import { User } from 'contracts/models';
import { NewServiceCart } from 'contracts/models/service/NewServiceAvailability';
import { IUser } from 'contracts/models/service/User';
import WholesaleCart, {
  IWholesaleCart,
} from 'contracts/models/service/WholesaleCart';
import {
  DateRange,
  DecodedClaims,
  SessionClaims,
  SiteIdentifier,
} from 'contracts/types/component';
import { initialStorageState } from 'wholesale/constants/initialStorageState';
import { WholesaleRoutes } from 'wholesale/routing';

import { getTokenExpirationInSeconds } from './tokenRefreshTimerService';

enum LocalStorageKey {
  USER = 'user',
  AUTH_TOKEN = 'authToken',
  REDIRECT_URL = 'redirectUrl',
  WHOLESALE_CART = 'wholesaleCart',
  SELECTED_STORES = 'selectedStores',
  DATE_SELECTION = 'dateSelection',
  REGWATCH_DATE_SELECTION = 'regWatchDateSelection',
  CART = 'cart',
  PAGE_FORCE_REFRESHED = 'pageForceRefreshed'
}

function setSessionItem<T>(key: LocalStorageKey, value: T): boolean {
  return ls.set<T>(key, value);
}

function getSessionItem<T>(key: LocalStorageKey): T {
  return ls.get<T>(key);
}

export function createSession(authToken: string, user: User): void {
  // refreshToken will come here
  if (user) {
    checkRedirectUrl(user.emailAddress);
    ls.set(LocalStorageKey.USER, user);
  }
  if (authToken) {
    ls.set(LocalStorageKey.AUTH_TOKEN, authToken);
  }
}

export function getSessionUser(): User {
  const user = ls.get<IUser>(LocalStorageKey.USER);
  return new User(user);
}

export function updateSessionUser(userDetails: User): void {
  const user = ls.get<IUser>(LocalStorageKey.USER);
  const newUser = {
    ...user,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName
  };
  if (newUser && newUser.firstName && newUser.lastName) {
    ls.set(LocalStorageKey.USER, newUser);
  }
}

export function getSessionAuthToken(): string {
  return ls.get(LocalStorageKey.AUTH_TOKEN);
}

export function getSessionClaims(): SessionClaims | undefined {
  const token = getSessionAuthToken();
  if (token) {
    const decodedClaims: DecodedClaims = decode(token.substring('bearer '.length));
    const result: SessionClaims = {
      customerType: decodedClaims.CustomerType,
      role: decodedClaims.Role,
      managementFeeTypes: decodedClaims.CustomerMgmtFeeTypes,
      custId: decodedClaims.CustId,
      emailAddress: decodedClaims.Email,
      salePermissions: decodedClaims.SalePermisions,
      exp: decodedClaims.exp,
      changePassword: decodedClaims.ChangePassword,
      channel: decodedClaims.Channel,
      trackingIdentity: decodedClaims.TrackingIdentity,
      gusUserId: decodedClaims.GusUserId,
      subscription: decodedClaims.Subscription,
      subscriptionItems: decodedClaims.SubscriptionItems,
      brandings: decodedClaims.Branding,
    };

    return result;
  }
}

export function setRedirectUrl(redirectUrl?: string): void {
  if (redirectUrl) {
    const user = getSessionUser();
    if (user && user.emailAddress) {
      ls.set(LocalStorageKey.REDIRECT_URL, {
        userEmailAddress: user.emailAddress,
        redirectUrl,
      });
    }
  } else ls.remove(LocalStorageKey.REDIRECT_URL);
}

export function getRedirectUrl(): string | undefined {
  const redirectData = ls.get<{
    userEmailAddress: string;
    redirectUrl: string;
  }>(LocalStorageKey.REDIRECT_URL);
  setRedirectUrl();
  return redirectData &&
    redirectData.userEmailAddress &&
    redirectData.redirectUrl
    ? redirectData.redirectUrl
    : undefined;
}

function checkRedirectUrl(userEmailAddress?: string): void {
  const redirectData = ls.get<{
    userEmailAddress: string;
    redirectUrl: string;
  }>(LocalStorageKey.REDIRECT_URL);
  if (!redirectData || redirectData.userEmailAddress !== userEmailAddress) {
    setRedirectUrl();
  }
}

export function setSessionWholesaleCart(wholesaleCart: WholesaleCart): void {
  if (wholesaleCart) ls.set(LocalStorageKey.WHOLESALE_CART, wholesaleCart);
}

export function getSessionWholesaleCart(): WholesaleCart {
  const wholesaleCart = ls.get<IWholesaleCart>(LocalStorageKey.WHOLESALE_CART);
  return wholesaleCart ? new WholesaleCart(wholesaleCart) : initialStorageState;
}

export function setSessionFilterStores(stores: SiteIdentifier[]): void {
  if (stores) ls.set(LocalStorageKey.SELECTED_STORES, stores);
}

export function getSessionFilterStores(): SiteIdentifier[] {
  return ls.get<SiteIdentifier[]>(LocalStorageKey.SELECTED_STORES);
}

export function setSessionDateSelection(date: DateRange<string>): void {
  if (date) ls.set(LocalStorageKey.DATE_SELECTION, date);
}

export function getSessionDateSelection(): DateRange<string> {
  return ls.get<DateRange<string>>(LocalStorageKey.DATE_SELECTION);
}

export function setRegWatchSessionDateSelection(date: DateRange<string>): void {
  if (date) ls.set(LocalStorageKey.REGWATCH_DATE_SELECTION, date);
}

export function getRegWatchSessionDateSelection(): DateRange<string> {
  return ls.get<DateRange<string>>(LocalStorageKey.REGWATCH_DATE_SELECTION);
}

export function destroySession(clearRedirectUrl?: boolean): void {
  ls.remove(LocalStorageKey.USER);
  ls.remove(LocalStorageKey.AUTH_TOKEN);
  ls.remove(LocalStorageKey.WHOLESALE_CART);
  ls.remove(LocalStorageKey.SELECTED_STORES);
  ls.remove(LocalStorageKey.DATE_SELECTION);
  ls.remove(LocalStorageKey.REGWATCH_DATE_SELECTION);
  if (clearRedirectUrl) {
    ls.remove(LocalStorageKey.REDIRECT_URL);
  }
}

export async function checkTokenExpiration(): Promise<void> {
  const authToken = getSessionAuthToken();
  if (authToken) {
    const refreshTokenExpirationInSeconds = getTokenExpirationInSeconds(
      authToken,
    );
    const refreshTokenExpires = moment.unix(refreshTokenExpirationInSeconds);
    const now = moment.utc();
    const duration = moment.duration(refreshTokenExpires.diff(now));
    const canRefresh = duration.asSeconds() <= 0;
    if (canRefresh) {
      if (
        window.location.href.indexOf(AccountRoutes.root + AccountRoutes.login) < 0 &&
        window.location.href.indexOf(WholesaleRoutes.root) < 0
      ) {
        setRedirectUrl(window.location.href);
        destroySession();
        window.location.href = AccountRoutes.root + AccountRoutes.login;
      }
    }
  }
}

function checkCartValidity(cart: NewServiceCart): boolean {
  if (!cart) {
    return false;
  }
  return true;
}

export function getSessionCart(): NewServiceCart {
  let cart: NewServiceCart = getSessionItem(LocalStorageKey.CART);
  const isCartValid = checkCartValidity(cart);
  if (!cart || !isCartValid) {
    cart = {
      services: [],
      location: undefined,
      simplifiedServices: []
    } as NewServiceCart;
    setSessionCart(cart);
  }
  return cart;
}

export function setSessionCart(cart: NewServiceCart): boolean {
  return setSessionItem(LocalStorageKey.CART, cart);
}

export function destroySessionCart(): void {
  ls.remove(LocalStorageKey.CART);
}

export function setSessionPageRefreshed(val?: boolean): void {
  if (val) ls.set(LocalStorageKey.PAGE_FORCE_REFRESHED, val);
  else ls.remove(LocalStorageKey.PAGE_FORCE_REFRESHED);
}

export function getSessionPageRefreshed(): boolean {
  return ls.get<boolean>(LocalStorageKey.PAGE_FORCE_REFRESHED);
}
