import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { OpenTopPriceEquipmentQuoteModel } from 'contracts/models/service/OpenTopPriceQuoteModel';
import { ActionDispatcher, OpenTopServiceAction } from 'contracts/types/action';
import { OpenTopTempServicesRequest } from 'contracts/types/request';
import {
  ApplicationState,
  OpenTopServiceState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getOpenTopTempServices } from '../services/openTopServices';

// Actions
const ROOT_KEY = 'wholesale/openTopTempServices';
enum ActionKey {
  LOAD_OPENTOP_SERVICES = 'wholesale/openTopTempServices/LOAD_OPENTOP_SERVICES',
  RESET = 'wholesale/openTopTempServices/RESET',
}

// Initial state
const getInitialState: () => OpenTopServiceState = () => {
  return {
    openTopTempServices: [],
    error: '',
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_OPENTOP_SERVICES] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  OpenTopServiceState,
  OpenTopServiceAction
> = {
  [ActionKey.LOAD_OPENTOP_SERVICES]: (state, action) => {
    const { openTopTempServices, error } = action;
    return update(state, { $merge: { openTopTempServices, error } });
  },
};

export const reducer = getReducerBuilder<
  OpenTopServiceState,
  OpenTopServiceAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_OPENTOP_SERVICES: (
    openTopTempServices: OpenTopPriceEquipmentQuoteModel[], error: string): OpenTopServiceAction => ({
    type: ActionKey.LOAD_OPENTOP_SERVICES,
    openTopTempServices,
    error
  }),
  RESET: (): OpenTopServiceAction => ({
    type: ActionKey.RESET,
  }),
};

// API class
const loadOpenTopServices = (params: OpenTopTempServicesRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_OPENTOP_SERVICES,
    async() => getOpenTopTempServices(params),
    result => {
      dispatch(actionMap.LOAD_OPENTOP_SERVICES(result.services, ''));
    },
    () => {
      dispatch(
        actionMap.LOAD_OPENTOP_SERVICES([], 'no service price'),
      );
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          'Unable to load service prices. Please select another location.',
        ),
      );
    },
  );

const openTopTempServicesDuck = {
  thunks: { loadOpenTopServices },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default openTopTempServicesDuck;
