import styled, { css } from 'styled-components';

import { Devices, theme } from 'core/styles';

import customerPortalScrollbar from './Scrollbar';

export const DropdownFilterRoot = styled.div`
  display: inline-block;
  position: relative;
`;

/* Control */

export const DropdownFilterCount = styled.span`
  margin-left: 5px;
  display: inline-block;
  font-size: 11px;
`;

/* Popup */
export const DropdownFilterPopupHeader = styled.div`
  padding: 10px 10px;
  border-bottom: 1px solid ${theme.colors.grayLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media ${Devices.mobile} {
    input {
      width: 100% !important;
    }
  }
`;

export const DropdownFilterSearchIcon = styled.div`
  margin: 5px 5px;
  display: inline-block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADCElEQVRYR+3Wy0tUURzA8e9vdMIhp6CgwJ7QO9oVUVJkjz9ACmpVlvfKBJrObdUiyhYtTNCrKRTNWEqbCIrWvR/0gKJNYQ8KKgoiih5WkjP3F161EnPu8Ya16a6GOff8zuec+/udc4R//Mg/Hp9gQKplBmRLQUtA5yDMBMmi+gJ4iHAVjZ7GrnoWZjLDA1SFdFMlogdAYjmDK18Q3UV5sgURHQnk94BUwwREToCs6w92DdUzoFfIk0f+f1mdi0RWgm4AWd73np5DdRP2znemiKGA9MEiNHMdkRmoPkLzyqiovpkz4JHmZUS8DmAOqs+Q/GKsHa9MEIMBra2FFPTcBuahep9odBVlVW9NAtHeMpFM5hKwyM+N7ugSKiu7gvoOBqTcPQj7UJ6jLKUi+ToowKD2I+5kRG/5q4fUYtXsC+r/E5Cui6NjXiAyHomUUF59Oajzb9vbmleh3iWULrKxqSQSH3LF+QlINZUhegzlPHZyIPlCGUg3nvUTWGUrdk27IcA9jVCKsh07eTjcyP29Um4C4ZBfObZTaghofIjIXDxdSIXT+UeAowfn42U7/SqynXlmgHTjR5A4mdhYEokvfwToqB9LT7QL9BOWM25kgO5o3KR8cgL7yvkT6Gcsp9AQ4D7w6z+St4BtO3p/h3/Sbu+yP0B5gp2cbQZIjUoSnsJ2NhgCGjcj0gF6ActZG376QMo9h7AWVQvbaTMDHG8eR7f3EqEQZA1WzcVQiIGNCN4T94rYuPOrGaD3rXTTXtBa0Jd4sjjUVhzROyBTEN1NubM/aBLDH0Zwj/z8klCHkXKXWe+Xsro2MzKAvwq/HMfwGC+yxeg4lmy7v5H5jz7Fk2KTFRzZhSQiV+mJ9ZVo9Ot8lBXAeqC4b1ztBBmDMMsUkftK1uZWAXWBVzJ4A7hkYvUUfJ5ET+Q6wnQTRIhLqUwD+rdXvQG0Ev9wko2133587/aGKaaIYEBQFg3XbogYPUAvzAAxugADxOgDhiCwsZLpgS/3dwADiIxswnIafk2bvwcYJln/A74DattRMOo0SvIAAAAASUVORK5CYII=');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
`;

export const DropdownFilterSearchInput = styled.input.attrs({ type: 'text' })`
  border: none;
  outline: none;
  padding: 5px;
  color: ${props => props.theme.colors.brandPrimary};

  &::placeholder {
    color: ${theme.colors.labelText};
    font-size: 12px;
  }
`;

export const DropdownFilterPopupBody = styled.div`
  display: block;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 15px 20px;
  max-height: 300px;

  ${customerPortalScrollbar()}
`;

interface DropdownFilterPopupRowProps {
  checkboxLabelLineCount?: number;
}
export const DropdownFilterPopupRow = styled.div<DropdownFilterPopupRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  color: #1f2a4b;
  font-size: 13px;
  line-height: 1.1em;

  ${props =>
    props.checkboxLabelLineCount &&
    css`
      height: ${props.checkboxLabelLineCount * 15}px;
    `};
`;

export const DropdownFilterExtraRow = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  margin-top: 10px;
  border-top: 1px solid ${theme.colors.grayLight};
  color: #333;
`;

export const DropdownFilterExtraCommentRow = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: ${theme.colors.labelText};
`;

export const DropDownFilterExtraButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: ${props => props.theme.colors.brandPrimary};
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
`;

export const DropdownFilterChecklistEmpty = styled.div`
  display: block;
  font-size: 13px;
  color: ${theme.colors.labelText};
  text-align: center;
`;
