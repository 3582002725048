import {
  CityFilterSuggestions,
  CityFilterSuggestionsFormatted,
  ZipCodeFilterSuggestionsFormatted
} from 'contracts/models/service/CityZipFilter';
import FilterMaterial, {
  IFilterMaterial,
} from 'contracts/models/service/FilterMaterial';
import FilterState, {
  IFilterState,
} from 'contracts/models/service/FilterState';
import FilterStore, {
  IFilterStore,
} from 'contracts/models/service/FilterStore';
import FilterTag, { IFilterTag } from 'contracts/models/service/FilterTag';
import OptimizationBinFilterModel from 'contracts/models/service/OptimizationBinFilterModel';
import { BinEquipmentFilterModel } from 'contracts/types/compactorProfile';

import { httpCustomerCore, transformServiceResponse } from './http';

export async function getAllFilterStates(): Promise<FilterState[]> {
  const response = await httpCustomerCore.get<FilterState[]>(
    'site-filters/states',
    {
      transformResponse: transformServiceResponse<IFilterState, FilterState>(
        FilterState,
      ),
    },
  );
  return response.data;
}

export async function getAllFilterStores(): Promise<FilterStore[]> {
  const response = await httpCustomerCore.get<FilterStore[]>(
    'site-filters/stores',
    {
      transformResponse: transformServiceResponse<IFilterStore, FilterStore>(
        FilterStore,
      ),
    },
  );
  return response.data;
}

export async function getAllFilterTags(): Promise<FilterTag[]> {
  const response = await httpCustomerCore.get<FilterTag[]>(
    'site-filters/tags',
    {
      transformResponse: transformServiceResponse<IFilterTag, FilterTag>(
        FilterTag,
      ),
    },
  );
  return response.data;
}

export async function getAllFilterMaterialCategories(): Promise<
  FilterMaterial[]
  > {
  const response = await httpCustomerCore.get<FilterMaterial[]>(
    'site-filters/material-categories',
    {
      transformResponse: transformServiceResponse<
        IFilterMaterial,
        FilterMaterial
      >(FilterMaterial),
    },
  );
  return response.data;
}

export async function getAllFilterEquipments(): Promise<BinEquipmentFilterModel[]> {
  const response = await httpCustomerCore.get('site-filters/equipments');
  return response.data;
}

export async function getAllFilterOptimizationEquipments(): Promise<OptimizationBinFilterModel[]> {
  const response = await httpCustomerCore.get(
    'site-filters/optimization-equipments',
  );
  return response.data;
}

export async function searchCities(searchTerm: string): Promise<CityFilterSuggestionsFormatted[]> {
  const response = await httpCustomerCore.post<CityFilterSuggestions>('sustainability/typeahead-city-results', {
    city: searchTerm,
  });

  const formattedResult = response.data.cities.map(item => ({
    label: `${item.city}, ${item.state}`,
    value: item,
  }));

  return formattedResult;
}

export async function searchZipCodes(searchTerm: string): Promise<ZipCodeFilterSuggestionsFormatted[]> {
  const response = await httpCustomerCore.post<string[]>('sustainability/typeahead-zip-results', {
    zip: searchTerm,
  });

  const formattedResult = response.data.map((item: string) => ({
    label: item,
    value: item,
  }));
  
  return formattedResult;
}
