import { useMemo } from 'react';

import update from 'immutability-helper';
import isNumber from 'lodash-es/isNumber';
import { useSelector } from 'react-redux';
import { createSelector, OutputParametricSelector } from 'reselect';

import { getSessionCart, setSessionCart } from 'account/services/session';
import { NotificationType } from 'contracts/enums';
import { NewService, NewServiceOptions } from 'contracts/models';
import { NewServiceAvailabilityItem, NewServiceLocationDetails } from 'contracts/models/service/NewServiceAvailability';
import { NewServiceDetailsFormOptions, NewServiceEditableProps, NewServiceMarketAvailability } from 'contracts/models/service/NewServiceMarketAvailability';
import { AdditionalOnCallPickup, NewServiceEquipment, NewServiceEquipmentRequest, NewServiceQuote } from 'contracts/models/service/NewServiceQuote';
import { ActionDispatcher, NewServiceAction } from 'contracts/types/action';
import { MarketAvailabilityRequest, NewServiceOptionsRequest, NewServiceQuoteRequest, ServiceAvailabilityRequest } from 'contracts/types/request';
import { UploadFileResponse } from 'contracts/types/service';
import {
  ApplicationState,
  ReduceFunctionMap,
  NewServiceState,
} from 'contracts/types/state';
import {
  createNotificationMessage
} from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getMarketAvailability, getServiceAvailability, getServiceQuote } from 'services/services/newService';
import { getNewServiceOptions } from 'services/services/newServiceModalRequests';

// Actions
const ROOT_KEY = 'services/new-service';
enum ActionKey {
  LOAD_LOCATION_DETAILS = 'services/new-service/LOAD_LOCATION_DETAILS',
  LOAD_SERVICE_AVAILABILITY = 'services/new-service/LOAD_SERVICE_AVAILABILITY',
  LOAD_MARKET_AVAILABILITY = 'services/new-service/LOAD_MARKET_AVAILABILITY',
  RESET_MARKET_AVAILABILITY = 'services/new-service/RESET_MARKET_AVAILABILITY',
  LOAD_SERVICE_QUOTE = 'services/new-service/LOAD_SERVICE_QUOTE',
  RESET_SERVICE_QUOTE = 'services/new-service/RESET_SERVICE_QUOTE',
  UPDATE_FORM_OPTIONS = 'service/new-service/UPDATE_FORM_OPTIONS',
  UPDATE_FORM_RULES = 'service/new-service/UPDATE_FORM_RULES',
  NEW_SERVICE_ADD = 'service/new-service/NEW_SERVICE_ADD',
  NEW_SERVICE_EDIT = 'service/new-service/NEW_SERVICE_EDIT',
  NEW_SERVICE_DELETE = 'service/new-service/NEW_SERVICE_DELETE',
  NEW_SERVICE_SET_DATES = 'service/new-service/NEW_SERVICE_SET_DATES',
  NEW_SERVICE_SET_UPLOADED_FILES = 'service/new-service/NEW_SERVICE_SET_UPLOADED_FILES',
  NEW_SIMPLIFIED_SERVICE_ADD = 'service/new-service/NEW_SIMPLIFIED_SERVICE_ADD',
  NEW_SIMPLIFIED_SERVICE_EDIT = 'service/new-service/NEW_SIMPLIFIED_SERVICE_EDIT',
  NEW_SIMPLIFIED_SERVICE_DELETE = 'service/new-service/NEW_SIMPLIFIED_SERVICE_DELETE',
  LOAD_SIMPLIFIED_SERVICE_OPTIONS = 'services/new-service/LOAD_SIMPLIFIED_SERVICE_OPTIONS',
  RESET = 'services/RESET',
}

// Initial state
const getInitialState: () => NewServiceState = () => {
  return {
    availableServices: [],
    quotes: [],
    formSettings: {
      formRules: {},
      formOptions: {},
    },
    marketAvailability: undefined,
    location: getSessionCart().location,
    services: getSessionCart().services,
    // Simplified services
    simplifiedServices: getSessionCart().simplifiedServices,
    simplifiedServiceOptions: undefined
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_LOCATION_DETAILS,
  ActionKey.LOAD_SERVICE_AVAILABILITY,
  ActionKey.LOAD_MARKET_AVAILABILITY,
  ActionKey.RESET_MARKET_AVAILABILITY,
  ActionKey.LOAD_SERVICE_QUOTE,
  ActionKey.RESET_SERVICE_QUOTE,
  ActionKey.UPDATE_FORM_OPTIONS,
  ActionKey.UPDATE_FORM_RULES,
  ActionKey.NEW_SERVICE_ADD,
  ActionKey.NEW_SERVICE_EDIT,
  ActionKey.NEW_SERVICE_DELETE,
  ActionKey.NEW_SERVICE_SET_DATES,
  ActionKey.NEW_SERVICE_SET_UPLOADED_FILES,
  ActionKey.NEW_SIMPLIFIED_SERVICE_ADD,
  ActionKey.NEW_SIMPLIFIED_SERVICE_EDIT,
  ActionKey.NEW_SIMPLIFIED_SERVICE_DELETE,
  ActionKey.LOAD_SIMPLIFIED_SERVICE_OPTIONS
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NewServiceState,
  NewServiceAction
> = {
  [ActionKey.LOAD_LOCATION_DETAILS]: (state, action) => {
    const { location } = action;
    const newState = update(state, {
      $merge: {
        location,
      },
    });
    const newCart = {
      location: newState.location,
      services:newState.services,
      simplifiedServices:newState.simplifiedServices
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.LOAD_SERVICE_AVAILABILITY]: (state, action) => {
    const { availableServices } = action;
    return update(state, {
      $merge: {
        availableServices,
      },
    });
  },
  [ActionKey.LOAD_MARKET_AVAILABILITY]: (state, action) => {
    const { marketAvailability } = action;
    return update(state, {
      $merge: {
        marketAvailability,
      },
    });
  },
  [ActionKey.LOAD_SERVICE_QUOTE]: (state, action) => {
    const { quotes } = action;
    return update(state, { $merge: { quotes } });
  },
  [ActionKey.RESET_SERVICE_QUOTE]: state => {
    return update(state, {
      $merge: { quotes: [] },
    });
  },
  [ActionKey.RESET_MARKET_AVAILABILITY]: state => {
    return update(state, {
      $merge: { 
        marketAvailability: undefined,
        formSettings: {
          formRules: {},
          formOptions: {},
        },
        availableServices: []
      },
    });
  },
  [ActionKey.UPDATE_FORM_OPTIONS]: (state, action) => {
    const formOptions = action.formSettings;
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          formOptions: {
            ...state.formSettings.formOptions,
            ...formOptions,
          },
        },
      },
    });
  },
  [ActionKey.UPDATE_FORM_RULES]: (state, action) => {
    const formRules = action.formSettings;
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          formRules: {
            ...state.formSettings.formRules,
            ...formRules,
          },
        },
      },
    });
  },
  [ActionKey.NEW_SERVICE_ADD]: (state, action) => {
    const { service } = action;
    if (!service) {
      return state;
    }
    const newState = update(state, {
      services: {
        $push: [service],
      },
    });
    const newCart = {
      simplifiedServices:newState.simplifiedServices,
      location: newState.location,
      services:newState.services
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SERVICE_EDIT]: (state, action) => {
    const { indexService, service } = action;
    if (
      !isNumber(indexService) ||
      state.services.length <= indexService ||
      !service
    ) {
      return state;
    }

    const newState = update(state, {
      services: {
        [indexService]: {
          $set: service,
        },
      },
    });
    const newCart = {
      location: newState.location,
      services:newState.services,
      simplifiedServices:newState.simplifiedServices
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SERVICE_DELETE]: (state, action) => {
    const { indexService } = action;
    if (!isNumber(indexService) || state.services.length <= indexService) {
      return state;
    }
    const newState = update(state, {
      services: {
        $splice: [[indexService, 1]],
      },
    });
    const newCart = {
      location: newState.location,
      services:newState.services,
      simplifiedServices:newState.simplifiedServices
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SIMPLIFIED_SERVICE_ADD]: (state, action) => {
    const { simplifiedService } = action;
    if (!simplifiedService) {
      return state;
    }
    const newState = update(state, {
      simplifiedServices: {
        $push: [simplifiedService],
      },
    });
    const newCart = {
      location: newState.location,
      simplifiedServices: newState.simplifiedServices,
      services: newState.services || []
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SIMPLIFIED_SERVICE_EDIT]: (state, action) => {
    const { indexService, simplifiedService } = action;
    if (
      !isNumber(indexService) ||
      state.simplifiedServices.length <= indexService ||
      !simplifiedService
    ) {
      return state;
    }

    const newState = update(state, {
      simplifiedServices: {
        [indexService]: {
          $set: simplifiedService,
        },
      },
    });
    const newCart = {
      location: newState.location,
      simplifiedServices: newState.simplifiedServices,
      services: newState.services || []
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SIMPLIFIED_SERVICE_DELETE]: (state, action) => {
    const { indexService } = action;
    if (!isNumber(indexService) || state.simplifiedServices.length <= indexService) {
      return state;
    }
    const newState = update(state, {
      simplifiedServices: {
        $splice: [[indexService, 1]],
      },
    });
    const newCart = {
      location: newState.location,
      simplifiedServices: newState.simplifiedServices,
      services: newState.services || []
    };
    if (setSessionCart(newCart)) {
      return newState;
    }
    return state;
  },
  [ActionKey.NEW_SERVICE_SET_DATES]: (state, action) => {
    const {
      service,
      serviceStartDate,
      serviceEndDate,
      serviceDateNotes,
      serviceUploadedImageFiles,
      additionalOnCallPickups
    } = action;
    if (!service ) {
      return state;
    }
    const indexService = state.services.indexOf(service);
     
    if (indexService >= 0) {
      const newState = update(state, {
        services: {
          [indexService]: {
            $merge: {
              startDate: serviceStartDate,
              endDate: serviceEndDate,
              notes: serviceDateNotes,
              uploadedImageFiles: serviceUploadedImageFiles,
              additionalOnCallPickups: additionalOnCallPickups,
            },
          },
        },
      });
      const newCart = {
        location: newState.location,
        services:newState.services,
        simplifiedServices:newState.simplifiedServices
      };
      if (setSessionCart(newCart)) {
        return newState;
      }
    }
    return state;
  },
  [ActionKey.NEW_SERVICE_SET_UPLOADED_FILES]: (state, action) => {
    const {
      service,
      serviceUploadedImageFiles,
    } = action;
    if (!service ) {
      return state;
    }
    const indexService = state.services.indexOf(service);
     
    if (indexService >= 0) {
      const newState = update(state, {
        services: {
          [indexService]: {
            $merge: {
              uploadedImageFiles: serviceUploadedImageFiles
            },
          },
        },
      });
      const newCart = {
        location: newState.location,
        services:newState.services,
        simplifiedServices:newState.simplifiedServices
      };
      if (setSessionCart(newCart)) {
        return newState;
      }
    }
    return state;
  },
  [ActionKey.LOAD_SIMPLIFIED_SERVICE_OPTIONS]: (state, action) => {
    const { simplifiedServiceOptions } = action;
    return update(state, { $merge: { simplifiedServiceOptions } });
  }
};

export const reducer = getReducerBuilder<NewServiceState, NewServiceAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_LOCATION_DETAILS: (location: NewServiceLocationDetails): NewServiceAction => ({
    type: ActionKey.LOAD_LOCATION_DETAILS,
    location,
  }),
  LOAD_SERVICE_AVAILABILITY: (availableServices: NewServiceAvailabilityItem[]): NewServiceAction => ({
    type: ActionKey.LOAD_SERVICE_AVAILABILITY,
    availableServices,
  }),
  LOAD_MARKET_AVAILABILITY: (marketAvailability?: NewServiceMarketAvailability): NewServiceAction => ({
    type: ActionKey.LOAD_MARKET_AVAILABILITY,
    marketAvailability,
  }),
  RESET_MARKET_AVAILABILITY: (): NewServiceAction => ({
    type: ActionKey.RESET_MARKET_AVAILABILITY,
  }),
  LOAD_SERVICE_QUOTE: (
    quotes?: NewServiceQuote[],
  ): NewServiceAction => ({
    type: ActionKey.LOAD_SERVICE_QUOTE,
    quotes,
  }),
  RESET_SERVICE_QUOTE: (): NewServiceAction => ({
    type: ActionKey.RESET_SERVICE_QUOTE,
  }),
  UPDATE_FORM_OPTIONS: (
    formSettings?: NewServiceDetailsFormOptions,
  ): NewServiceAction => ({
    type: ActionKey.UPDATE_FORM_OPTIONS,
    formSettings,
  }),
  UPDATE_FORM_RULES: (
    formSettings?: NewServiceEditableProps,
  ): NewServiceAction => ({
    type: ActionKey.UPDATE_FORM_RULES,
    formSettings,
  }),
  LOAD_SIMPLIFIED_SERVICE_OPTIONS: (simplifiedServiceOptions?: NewServiceOptions): NewServiceAction => ({
    type: ActionKey.LOAD_SIMPLIFIED_SERVICE_OPTIONS,
    simplifiedServiceOptions,
  }),
  
  RESET: (): NewServiceAction => ({ type: ActionKey.RESET }),
};

interface ServiceIndexSelectorProps {
  indexService: number;
}

function GetSelector<DataType>(
  selector: (state: ApplicationState) => DataType,
): DataType {
  const data = useSelector((state: ApplicationState) => selector(state));
  return data;
}

const getServices = (state: ApplicationState): NewServiceEquipmentRequest[] => 
  state.services.newService.services;

const getSimplifiedServices = (state: ApplicationState): NewService[] => 
  state.services.newService.simplifiedServices;
  
const getService = (): OutputParametricSelector<
  ApplicationState, ServiceIndexSelectorProps, NewServiceEquipmentRequest | 
  undefined, (res1: NewServiceEquipmentRequest[], res2: ServiceIndexSelectorProps)
  => NewServiceEquipmentRequest | undefined> =>
  createSelector(
    getServices,
    (_: ApplicationState, serviceSelectorProps: ServiceIndexSelectorProps) =>
      serviceSelectorProps,
    (services, serviceSelectorProps) => {
      if (services.length > serviceSelectorProps.indexService) {
        return services[serviceSelectorProps.indexService];
      }
    },
  );

const getSimplifiedService = (): OutputParametricSelector<
ApplicationState, ServiceIndexSelectorProps, NewService | 
undefined, (res1: NewService[], res2: ServiceIndexSelectorProps) => NewService | undefined> =>
  createSelector(
    getSimplifiedServices,
    (_: ApplicationState, serviceSelectorProps: ServiceIndexSelectorProps) =>
      serviceSelectorProps,
    (simplifiedServices, serviceSelectorProps) => {
      if (simplifiedServices.length > serviceSelectorProps.indexService) {
        return simplifiedServices[serviceSelectorProps.indexService];
      }
    },
  );

const countServicesWithSetDates = (state: ApplicationState): number => {
  const services = getServices(state);
  return services.filter(
    s =>
      (!s.editStartDate && !s.editEndDate) ||
        (s.editStartDate && !!s.startDate) ||
        (s.editEndDate && !!s.endDate),
  ).length;
};

const haveAllServicesSetDates = (state: ApplicationState): boolean => {
  const services = getServices(state);
  return (
    !!services.length && services.length === countServicesWithSetDates(state)
  );
};
  
// Selector Helpers
function GetParametricSelector<DataType, PropsType, Res1Type = NewServiceEquipment[]>(
  selector: () => OutputParametricSelector<
    ApplicationState,
    PropsType,
    DataType,
    (res1: Res1Type, res2: PropsType) => DataType
  >,
  props: PropsType,
): DataType {
  const memoizedSelector = useMemo(selector, [selector]);
  const data = useSelector((state: ApplicationState) =>
    memoizedSelector(state, props),
  );
  return data;
}

// Selectors
const selectors = {
  getService: (indexService: number) =>
    GetParametricSelector(getService, { indexService }),
  getSimplifiedService: (indexService: number) =>
    GetParametricSelector(getSimplifiedService, { indexService }),

};

const flags = {
  countServicesWithSetDates: () => GetSelector(countServicesWithSetDates),
  haveAllServicesSetDates: () => GetSelector(haveAllServicesSetDates),
};

// API calls
const loadServiceAvailability = (params: ServiceAvailabilityRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_AVAILABILITY,
    async() => getServiceAvailability(params),
    result => {
      dispatch(actionMap.LOAD_SERVICE_AVAILABILITY(result.serviceLabels || []));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_AVAILABILITY([]));
    },
  );

const loadMarketAvailability = (params: MarketAvailabilityRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_MARKET_AVAILABILITY,
    async() => getMarketAvailability(params),
    result => {
      dispatch(actionMap.LOAD_MARKET_AVAILABILITY(result));
      const formOptions: NewServiceDetailsFormOptions = {
        serviceLabels: result.serviceLabels,
        materialLabels: result.materialLabels,
        frequencyLabels: result.frequencyLabels,
        scheduleLabels: result.scheduleLabels,
      };
      dispatch(actionMap.UPDATE_FORM_OPTIONS(formOptions));
      dispatch(actionMap.UPDATE_FORM_RULES(result.editableProps[0]));
    },
    () => {
      dispatch(actionMap.LOAD_MARKET_AVAILABILITY());
    },
  );

const loadServiceQuote = (request: NewServiceQuoteRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_QUOTE,
    async() => getServiceQuote(request),
    result => {
      dispatch(actionMap.LOAD_SERVICE_QUOTE(result.quotes));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_QUOTE());
      const errorMessage = translate('somethingWentWrong');
      dispatch(
        createNotificationMessage(
          NotificationType.Error,
          errorMessage,
        ),
      );
    },
    true,
  );

const resetServiceQuote = () => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.RESET_SERVICE_QUOTE());
};

const resetMarketAvailability = () => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.RESET_MARKET_AVAILABILITY());
};

const updateFormSettings = (
  formRules: NewServiceEditableProps,
  formOptions: NewServiceDetailsFormOptions,
) => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.UPDATE_FORM_RULES(formRules));
  dispatch(actionMap.UPDATE_FORM_OPTIONS(formOptions));
};

const updateLocationDetails = (
  location: NewServiceLocationDetails,
) => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.LOAD_LOCATION_DETAILS(location));
};

const loadSimplifiedServiceOptions = (serviceParams: NewServiceOptionsRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SIMPLIFIED_SERVICE_OPTIONS,
    async() => getNewServiceOptions(serviceParams),
    result => {
      dispatch(actionMap.LOAD_SIMPLIFIED_SERVICE_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SIMPLIFIED_SERVICE_OPTIONS());
    },
  );

type NewServiceActionDispatcher = ActionDispatcher<NewServiceAction>;

const actions = {
  addService: (service: NewServiceEquipmentRequest) => (
    dispatch: NewServiceActionDispatcher,
  ) => {
    dispatch({ type: ActionKey.NEW_SERVICE_ADD, service });
  },
  editService: (
    indexService: number,
    service: NewServiceEquipmentRequest,
  ) => (dispatch: NewServiceActionDispatcher) => {
    dispatch({
      type: ActionKey.NEW_SERVICE_EDIT,
      indexService,
      service,
    });
  },
  deleteService: (indexService: number) => (
    dispatch: NewServiceActionDispatcher,
  ) => {
    dispatch({ type: ActionKey.NEW_SERVICE_DELETE, indexService });
  },
  addSimplifiedService: (simplifiedService: NewService) => (
    dispatch: NewServiceActionDispatcher,
  ) => {
    dispatch({ type: ActionKey.NEW_SIMPLIFIED_SERVICE_ADD, simplifiedService });
  },
  editSimplifiedService: (
    indexService: number,
    simplifiedService: NewService,
  ) => (dispatch: NewServiceActionDispatcher) => {
    dispatch({
      type: ActionKey.NEW_SIMPLIFIED_SERVICE_EDIT,
      indexService,
      simplifiedService,
    });
  },
  deleteSimplifiedService: (indexService: number) => (
    dispatch: NewServiceActionDispatcher,
  ) => {
    dispatch({ type: ActionKey.NEW_SIMPLIFIED_SERVICE_DELETE, indexService });
  },
  setServiceDates: (
    service: NewServiceEquipmentRequest,
    serviceStartDate?: string,
    serviceEndDate?: string | null,
    serviceDateNotes?: string,
    serviceUploadedImageFiles?: UploadFileResponse[] | undefined,
    additionalOnCallPickups?: AdditionalOnCallPickup[] | []
  ) => (dispatch: NewServiceActionDispatcher) => {
    dispatch({
      type: ActionKey.NEW_SERVICE_SET_DATES,
      service,
      serviceStartDate,
      serviceEndDate,
      serviceDateNotes,
      serviceUploadedImageFiles,
      additionalOnCallPickups
    });
  },
  setUploadedFiles: (
    service: NewServiceEquipmentRequest,
    serviceUploadedImageFiles?: UploadFileResponse[] | undefined
  ) => (dispatch: NewServiceActionDispatcher) => {
    dispatch({
      type: ActionKey.NEW_SERVICE_SET_UPLOADED_FILES,
      service,
      serviceUploadedImageFiles
    });
  },
  reset: actionMap.RESET
};

const newServiceDuck = {
  thunks: {
    loadServiceAvailability,
    loadMarketAvailability,
    loadServiceQuote,
    resetServiceQuote,
    resetMarketAvailability,
    updateFormSettings,
    updateLocationDetails,
    loadSimplifiedServiceOptions
  },
  actions,
  actionKeys: ActionKey,
  selectors,
  flags
};

export default newServiceDuck;
