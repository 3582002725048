import styled, { css } from 'styled-components';

import { theme } from 'core/styles';

import Icon from '../Icon';

import { BareButtonStyle } from './Button';

interface ExportCsvButtonStyledProps {
  smallMinWidth?: boolean;
}

export const ExportCsvButtonStyled = styled.button<ExportCsvButtonStyledProps>`
  ${BareButtonStyle};
  min-width: 130px;
  border: 0px;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }

  ${props =>
    props.smallMinWidth &&
    css`
      min-width: 100px;
    `};
`;

export const ExportCsvTextSpan = styled.span`
  margin-right: 13.5px;
  font-size: 14px;
  color: ${theme.colors.brandBlack};
  line-height: 18px;
`;

export const ExportCsvGeneratingSpan = styled.span`
  min-width: 150px;
  margin-right: 5px;
  font-size: 14px;
  color: #808080;
  line-height: 18px;
`;

interface ExportCsvIconProps {
  size: number;
  iconColor: string;
}
export const ExportCsvIcon = styled(Icon)<ExportCsvIconProps>`
  cursor: pointer;
  width: ${props => props.size || 32}px;
  height: ${props => props.size || 32}px;
  margin-right: 10px;
  color: ${props => props.iconColor || ''};
`;
