import React, { PureComponent } from 'react';

import { LocationFilter } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';
import { DropdownFilterValue } from '../DropdownFilter/DropdownFilterPopup';

class LocationTagFilter extends PureComponent<ComponentProps> {
  render(): React.ReactNode {
    const {
      isLoading,
      isDisabled,
      tags,
      currentFilter,
      onFilterChanged,
    } = this.props;
    return (
      <DropdownFilter
        label={translate('tags')}
        values={tags}
        selectedIds={currentFilter.tags || []}
        onFilterChanged={onFilterChanged}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    );
  }
}

interface ComponentProps {
  isLoading: boolean;
  isDisabled?: boolean;
  tags: Array<DropdownFilterValue<number>>;
  currentFilter: LocationFilter;
  onFilterChanged: (selectedItems: number[]) => void;
}

export default LocationTagFilter;
