import { DateRange } from 'contracts/types/service';

export interface GhGCarbonEmissionsComparisonMaterial {
  identifier: number;
  name: string;
  emissionsWeight: string;
  mtCo2Emissions: number[];
}

export interface IGhGCarbonEmissionsComparison {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly mtCo2EmissionsEntries: GhGCarbonEmissionsComparisonMaterial[];
}

class GhGCarbonEmissionsComparison {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  mtCo2EmissionsEntries?: GhGCarbonEmissionsComparisonMaterial[];

  constructor(data?: Partial<IGhGCarbonEmissionsComparison>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.mtCo2EmissionsEntries = data?.mtCo2EmissionsEntries;
  }
}

export default GhGCarbonEmissionsComparison;
