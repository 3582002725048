import update from 'immutability-helper';

import { editBillingEmail, getLocationsPaymentInfo } from 'billing/services/billingServices';
import { NotificationType } from 'contracts/enums';
import { LocationPaymentInfo } from 'contracts/models';
import { ActionDispatcher, PaymentSetupAction } from 'contracts/types/action';
import {
  PaymentSetupState,
  ReduceFunctionMap,
  ApplicationState,
} from 'contracts/types/state';
import { createServiceErrorNotificationMessage, createTimedNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import { logError } from 'core/logging';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Actions Keys
const ROOT_KEY = 'billing/paymentSetup';

enum ActionKey {
  LOAD_PAYMENT_INFO = 'billing/paymentSetup/LOAD_PAYMENT_INFO',
  RESET = 'billing/paymentSetup/RESET',
}

// Initial State
const getInitialState: () => PaymentSetupState = () => {
  return {
    locations: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_PAYMENT_INFO] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  PaymentSetupState,
  PaymentSetupAction
> = {
  [ActionKey.LOAD_PAYMENT_INFO]: (state, action) => {
    return update(state, {
      $merge: {
        locations: action.locations,
      },
    });
  },
};

export const reducer = getReducerBuilder<PaymentSetupState, PaymentSetupAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_PAYMENT_INFO: (
    locations?: LocationPaymentInfo[],
  ): PaymentSetupAction => ({
    type: ActionKey.LOAD_PAYMENT_INFO,
    locations,
  }),
  RESET: (): PaymentSetupAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadLocations = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAYMENT_INFO,
    async() => getLocationsPaymentInfo(),
    result => {
      dispatch(actionMap.LOAD_PAYMENT_INFO(result));
    },
    err => {
      logError(err);
      dispatch(actionMap.LOAD_PAYMENT_INFO());
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const updateBillingEmail = (custId: string, siteId: string, billingEmail: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAYMENT_INFO,
    async() => editBillingEmail(custId, siteId, billingEmail),
    () => {
      dispatch(createTimedNotificationMessage(
        NotificationType.Success,
        translate('billing.billingEmailUpdateSuccessMessage')
      ));
    },
    err => {
      logError(err);
      
      dispatch(createServiceErrorNotificationMessage(err));
    },
    true,
  );

const paymentSetupDuck = {
  actionKeys: ActionKey,
  thunks: {
    loadLocations,
    updateBillingEmail,
  },
  actions: { reset: actionMap.RESET },
};

export default paymentSetupDuck;
