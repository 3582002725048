import { PreRegisterUserRequest, PreregisterCodeDataView, RegisterUserRequest } from 'contracts/types/request';
import { PortalUserAuthenticationDetailsModel, RegisterResult, RegistrationFormData, SsoUrlRedirectDataView } from 'contracts/types/service';
import { httpCustomerCore } from 'core/services/http';

export const validateCode = async(
  arg: PreregisterCodeDataView,
): Promise<void> => {
  const response = await httpCustomerCore.post<Promise<void>>(
    'preregistration/sso/validate',
    arg
  );
  return response.data;
};

export const preRegistration = async(
  arg: PreRegisterUserRequest,
): Promise<RegisterResult> => {
  const response = await httpCustomerCore.post<Promise<RegisterResult>>(
    'registration/pre-register',
    arg
  );
  return response.data;
};

export const accountActivation = async(
  arg: RegisterUserRequest,
): Promise<PortalUserAuthenticationDetailsModel> => {
  const response = await httpCustomerCore.post<Promise<PortalUserAuthenticationDetailsModel>>(
    'registration/activate',
    arg
  );
  return response.data;
};

export const initiateSso = async(): Promise<SsoUrlRedirectDataView> => {
  const response = await httpCustomerCore.get<Promise<SsoUrlRedirectDataView>>(
    'marketplace/sso/initiate',
  );
  return response.data;
};

export const getCaptchaKey = async(): Promise<RegistrationFormData> => {
  const response = await httpCustomerCore.get<Promise<RegistrationFormData>>(
    'preregistration/form-data',
  );
  return response.data;
};

export const activateCodeValidation = async(
  arg: PreregisterCodeDataView,
):  Promise<PreRegisterUserRequest> => {
  const response = await httpCustomerCore.get<PreRegisterUserRequest>(
    `registration/activation-lookup`, {
      params: {
        encodedValue: arg.encodedValue
      }
    }
  );
  return response.data;
};