import MaterialSpendComparison, {
  IMaterialSpendComparison,
} from 'contracts/models/service/MaterialSpendComparison';
import MaterialSpendOverview, {
  IMaterialSpendOverview,
} from 'contracts/models/service/MaterialSpendOverview';
import MaterialSpendTable, {
  IMaterialSpendTable,
} from 'contracts/models/service/MaterialSpendTable';
import { SpendByMaterialRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getSpendByMaterialSummary(
  arg: SpendByMaterialRequest,
): Promise<MaterialSpendOverview> {
  const reqArg = getRequestArg(arg);
  const response = await httpCustomerCore.post<MaterialSpendOverview>(
    'material-spend/summary',
    reqArg,
    {
      transformResponse: transformServiceResponse<
        IMaterialSpendOverview,
        MaterialSpendOverview
      >(MaterialSpendOverview),
    },
  );
  return response.data;
}

export async function getSpendByMaterialComparison(
  arg: SpendByMaterialRequest,
): Promise<MaterialSpendComparison> {
  const reqArg = getRequestArg(arg);
  const response = await httpCustomerCore.post<MaterialSpendComparison>(
    'material-spend/comparison',
    reqArg,
    {
      transformResponse: transformServiceResponse<
        IMaterialSpendComparison,
        MaterialSpendComparison
      >(MaterialSpendComparison),
    },
  );
  return response.data;
}

export async function getSpendByMaterialTableData(
  arg: SpendByMaterialRequest,
): Promise<MaterialSpendTable> {
  const reqArg = getRequestArg(arg);
  const response = await httpCustomerCore.post<MaterialSpendTable>(
    'material-spend/table-data',
    reqArg,
    {
      transformResponse: transformServiceResponse<
        IMaterialSpendTable,
        MaterialSpendTable
      >(MaterialSpendTable),
    },
  );
  return response.data;
}

export async function getSpendByMaterialCsv(
  arg: SpendByMaterialRequest & {showCost: boolean}): Promise<GetCsvResponse> {
  const reqArg = getRequestArg(arg);
  const response = await getCsv(
    httpCustomerCore,
    `material-spend/generate-csv?showCost=${arg.showCost}`,
    reqArg,
  );
  return response;
}

function getRequestArg({
  fromDate,
  toDate,
  reportFilters,
  materialCategoryId,
  site,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any): any {
  const siteInfo = site ? site.split('-') : null;
  const response = {
    fromDate,
    toDate,
    reportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      materials:
        reportFilters && reportFilters.materials ? reportFilters.materials : [],
      isTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      isPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    materialCategoryId,
    siteCustId: siteInfo ? siteInfo[0] : null,
    siteId: siteInfo ? siteInfo[1] : null,
  };
  return response;
}
