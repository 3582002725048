import update from 'immutability-helper';

import SustainabilityCarbonFootprintSummary from 'contracts/models/service/CarbonFootprint';
import {
  ActionDispatcher,
  CarbonReductionAction,
} from 'contracts/types/action';
import { CarbonReductionRequest } from 'contracts/types/request';
import {
  ApplicationState,
  CarbonReductionState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getCarbonFootprintReport } from '../services/carbonReductionService';

// ACTIONS
const ROOT_KEY = 'sustainability/carbonReduction';
enum ActionKey {
  LOAD_CARBON_REPORT = 'sustainability/carbonReduction/LOAD_CARBON_REPORT',
}

// Initial state
const getInitialState: () => CarbonReductionState = () => {
  return {
    carbonReport: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_CARBON_REPORT] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CarbonReductionState,
  CarbonReductionAction
> = {
  [ActionKey.LOAD_CARBON_REPORT]: (state, action) => {
    const { carbonReport } = action;
    if (!carbonReport) {
      return state;
    }
    return update(state, { $merge: { carbonReport } });
  },
};

export const reducer = getReducerBuilder<
  CarbonReductionState,
  CarbonReductionAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_CARBON_REPORT: (
    carbonReport?: SustainabilityCarbonFootprintSummary,
  ): CarbonReductionAction => ({
    type: ActionKey.LOAD_CARBON_REPORT,
    carbonReport,
  }),
};

// Thunks
const loadCarbonReport = (inputArg: CarbonReductionRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CARBON_REPORT,
    () => getCarbonFootprintReport(inputArg),
    result => {
      dispatch(actionMap.LOAD_CARBON_REPORT(result));
    },
    () => {
      dispatch(actionMap.LOAD_CARBON_REPORT());
    },
    true,
  );

const carbonReductionDuck = {
  thunks: { loadCarbonReport },
  actionKeys: ActionKey,
};

export default carbonReductionDuck;
