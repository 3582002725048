export interface ISlaRule {
  readonly ordType: string;
  readonly typeDescr: string;
  readonly requestedBefore: string;
  readonly requestedAfter: string;
}

class SlaRule {
  ordType?: string;
  typeDescr?: string;
  requestedBefore?: string;
  requestedAfter?: string;

  constructor(data?: Partial<ISlaRule>) {
    this.ordType = data?.ordType;
    this.typeDescr = data?.typeDescr;
    this.requestedBefore = data?.requestedBefore;
    this.requestedAfter = data?.requestedAfter;
  }
}

export default SlaRule;
