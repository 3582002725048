export interface ICarbonEmissionsSummaryCategory {
  name: string;
  identifier: string;
  co2Emissions: number;
  isAvoided?: boolean;
}

export interface ICarbonEmissionsSummary {
  readonly avoidedEmissionsCategories: ICarbonEmissionsSummaryCategory[];
  readonly emitedEmissionsCategories: ICarbonEmissionsSummaryCategory[];
  readonly totalEmissionsAvoided: number;
  readonly totalEmissionsEmited: number;
}

class CarbonEmissionsSummary {
  avoidedEmissionsCategories?: ICarbonEmissionsSummaryCategory[];
  emitedEmissionsCategories?: ICarbonEmissionsSummaryCategory[];
  totalEmissionsAvoided?: number;
  totalEmissionsEmited?: number;

  constructor(data?: Partial<ICarbonEmissionsSummary>) {
    this.avoidedEmissionsCategories = data?.avoidedEmissionsCategories;
    this.emitedEmissionsCategories = data?.emitedEmissionsCategories;
    this.totalEmissionsAvoided = data?.totalEmissionsAvoided;
    this.totalEmissionsEmited = data?.totalEmissionsEmited;
  }
}

export default CarbonEmissionsSummary;
