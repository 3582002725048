export interface IHmacSignatureResponse {
  readonly signature: string;
  readonly token: string;
  readonly success: boolean;
}

class HmacSignatureResponse {
  signature?: string;
  token?: string;
  success?: boolean;

  constructor(data?: Partial<IHmacSignatureResponse>) {
    this.signature = data?.signature;
    this.token = data?.token;
    this.success = data?.success;
  }
}

export default HmacSignatureResponse;
