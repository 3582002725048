import { BrandingType } from './brandedContentDictionary';
import SubscriptionComponentKey from './SubscriptionComponentKey';
import SubscriptionItems from './SubscriptionItems';
import SubscriptionType from './SubscriptionType';

type SubscriptionModuleDictionaryElement = {
  moduleKey: SubscriptionItems;
  components: SubscriptionComponentKey[];
};

type SubscriptionDictionaryElement = {
  subscriptionKey: SubscriptionType;
  components: SubscriptionComponentKey[];
};

type BrandingDictionaryElement = {
  brandingKey: BrandingType;
  components: SubscriptionComponentKey[];
};

export const subscriptionItemsDictionary: SubscriptionModuleDictionaryElement[] = [
  {
    moduleKey: SubscriptionItems.ManagedService,
    components: [
      SubscriptionComponentKey.DashboardPage, // partial components are shown
      SubscriptionComponentKey.OptimizationReportPage,
      SubscriptionComponentKey.OptimizationDetailsPage,
      SubscriptionComponentKey.FrontLoadDiversionReportPage,
      SubscriptionComponentKey.FrontLoadDiversionDetailsPage,
      SubscriptionComponentKey.ServiceLocationsPage,
      SubscriptionComponentKey.ServiceSiteProfilePageWrapper,
      SubscriptionComponentKey.ServiceRequestsPage,
      SubscriptionComponentKey.ServiceApprovalPage,
      SubscriptionComponentKey.NewServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewServiceStartDatePage,
      SubscriptionComponentKey.NewServiceSummaryPage,
      SubscriptionComponentKey.NewServiceSubmissionSummaryPage,
      SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewSimplifiedServiceSummaryPage,
      SubscriptionComponentKey.EditSitePage,
      SubscriptionComponentKey.ServiceUpdatePage,
      SubscriptionComponentKey.ServiceAgreementPage,
      SubscriptionComponentKey.ServiceAgreementsPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.UnmanageServicedUpload,
    components: [
      SubscriptionComponentKey.UnmanagedServicesLocationsPage,
      SubscriptionComponentKey.BulkOnDemandServicesUploadPage,
      SubscriptionComponentKey.BulkRecurringServicesUploadPage,
      SubscriptionComponentKey.BulkSeasonalServicesUploadPage,
      SubscriptionComponentKey.ServicesUpdatePage,
      SubscriptionComponentKey.OnDemandPickUpsPage
    ],
  },
  {
    moduleKey: SubscriptionItems.AuditInvoice,
    components: [
      SubscriptionComponentKey.InvoiceAuditPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.BillConsolidation,
    components: [
      SubscriptionComponentKey.HaulerBillConsolidationPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.InvoicingBilling,
    components: [
      SubscriptionComponentKey.SummaryPage,
      SubscriptionComponentKey.InvoiceDetailsPage,
      SubscriptionComponentKey.InvoicesListPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.CustomReport,
    components: [
      SubscriptionComponentKey.UploadedDocsPage,
      SubscriptionComponentKey.PowerBiPage,
      SubscriptionComponentKey.FoundryPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.PublicApi,
    components: [
      SubscriptionComponentKey.ApiKeyManagementPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.ESGReporting,
    components: [
      SubscriptionComponentKey.SpendByMaterialPage,
      SubscriptionComponentKey.DiversionPage,
      SubscriptionComponentKey.CarbonReductionPage,
      SubscriptionComponentKey.GhGCarbonEmissionsPage,
      SubscriptionComponentKey.MaterialProfilePage,
      SubscriptionComponentKey.SustainabilityHubPage,
      SubscriptionComponentKey.DashboardPage, // partial components are shown
    ],
  },
  {
    moduleKey: SubscriptionItems.EquipmentSensor,
    components: [
      SubscriptionComponentKey.RightSizingPage,
      SubscriptionComponentKey.ServiceInsightsPage,
      SubscriptionComponentKey.ServiceConfirmationAnalytics,
    ],
  },
  {
    moduleKey: SubscriptionItems.Regwatch,
    components: [
      SubscriptionComponentKey.RegWatchAssessmentsPage,
      SubscriptionComponentKey.RegWatchLatestUpdateDetailsPage,
      SubscriptionComponentKey.RegWatchUpdateDetailsPage,
      SubscriptionComponentKey.RegWatchUpdatesPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.BillPay,
    components: [
      SubscriptionComponentKey.SummaryPage,
      SubscriptionComponentKey.MakePaymentPage,
      SubscriptionComponentKey.PaymentSetupPage,
      SubscriptionComponentKey.CompletePaymentPage,
    ],
  },
  {
    moduleKey: SubscriptionItems.SecretShopper,
    components: [
      SubscriptionComponentKey.DisposalAuditPage,
    ],
  },
];

// dictionar de subscriptie  la componenta
export const subscriptionsDictionary: SubscriptionDictionaryElement[] = [
  {
    subscriptionKey: SubscriptionType.DigitalOnly,
    components: [
      // ManagedService module
      SubscriptionComponentKey.ServiceLocationsPage,
      SubscriptionComponentKey.ServiceSiteProfilePageWrapper,
      SubscriptionComponentKey.ServiceRequestsPage,
      SubscriptionComponentKey.ServiceApprovalPage,
      SubscriptionComponentKey.NewServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewServiceStartDatePage,
      SubscriptionComponentKey.NewServiceSummaryPage,
      SubscriptionComponentKey.NewServiceSubmissionSummaryPage,
      SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewSimplifiedServiceSummaryPage,
      SubscriptionComponentKey.EditSitePage,
      SubscriptionComponentKey.ServiceUpdatePage,
      SubscriptionComponentKey.ServiceAgreementPage,
      SubscriptionComponentKey.ServiceAgreementsPage,

      // InvoicingBilling module
      SubscriptionComponentKey.SummaryPage,

      // BillPay module
      SubscriptionComponentKey.SummaryPage,
      SubscriptionComponentKey.MakePaymentPage,
      SubscriptionComponentKey.PaymentSetupPage,
      SubscriptionComponentKey.CompletePaymentPage,

      // ESGReporting
      SubscriptionComponentKey.DiversionPage,
      SubscriptionComponentKey.CarbonReductionPage,
      SubscriptionComponentKey.GhGCarbonEmissionsPage,
      SubscriptionComponentKey.SustainabilityHubPage,
    ],
  },
  {
    subscriptionKey: SubscriptionType.Wholesale,
    components: [
      // ManagedService module
      SubscriptionComponentKey.ServiceLocationsPage,
      SubscriptionComponentKey.ServiceSiteProfilePageWrapper,
      SubscriptionComponentKey.ServiceRequestsPage,
      SubscriptionComponentKey.ServiceApprovalPage,
      SubscriptionComponentKey.NewServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewServiceStartDatePage,
      SubscriptionComponentKey.NewServiceSummaryPage,
      SubscriptionComponentKey.NewServiceSubmissionSummaryPage,
      SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewSimplifiedServiceSummaryPage,
      SubscriptionComponentKey.EditSitePage,
      SubscriptionComponentKey.ServiceUpdatePage,
      SubscriptionComponentKey.ServiceAgreementPage,
      SubscriptionComponentKey.ServiceAgreementsPage,
      
      // InvoicingBilling module
      SubscriptionComponentKey.SummaryPage,

      // BillPay module
      SubscriptionComponentKey.SummaryPage,
      SubscriptionComponentKey.MakePaymentPage,
      SubscriptionComponentKey.PaymentSetupPage,
      SubscriptionComponentKey.CompletePaymentPage,

      // wholesale
      SubscriptionComponentKey.WholesaleFlow
    ],
  },
  {
    subscriptionKey: SubscriptionType.Silver,
    components: [
      // UnamanagedServiceUpload module
      SubscriptionComponentKey.UnmanagedServicesLocationsPage,
      SubscriptionComponentKey.BulkOnDemandServicesUploadPage,
      SubscriptionComponentKey.BulkRecurringServicesUploadPage,
      SubscriptionComponentKey.BulkSeasonalServicesUploadPage,
      SubscriptionComponentKey.ServicesUpdatePage,
      SubscriptionComponentKey.OnDemandPickUpsPage,

      // PublicApi module
      SubscriptionComponentKey.ApiKeyManagementPage,

      // ESGReporting module
      SubscriptionComponentKey.SpendByMaterialPage,
      SubscriptionComponentKey.DiversionPage,
      SubscriptionComponentKey.CarbonReductionPage,
      SubscriptionComponentKey.GhGCarbonEmissionsPage,
      SubscriptionComponentKey.MaterialProfilePage,

      // HaulerBillConsolidation module
      SubscriptionComponentKey.HaulerBillConsolidationPage,
    ],
  },
  {
    subscriptionKey: SubscriptionType.Platinum,
    components: [
      // ManagedService module
      SubscriptionComponentKey.DashboardPage, // partial components are shown
      SubscriptionComponentKey.OptimizationReportPage,
      SubscriptionComponentKey.OptimizationDetailsPage,
      SubscriptionComponentKey.FrontLoadDiversionReportPage,
      SubscriptionComponentKey.FrontLoadDiversionDetailsPage,
      SubscriptionComponentKey.ServiceLocationsPage,
      SubscriptionComponentKey.ServiceSiteProfilePageWrapper,
      SubscriptionComponentKey.ServiceRequestsPage,
      SubscriptionComponentKey.ServiceApprovalPage,
      SubscriptionComponentKey.NewServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewServiceStartDatePage,
      SubscriptionComponentKey.NewServiceSummaryPage,
      SubscriptionComponentKey.NewServiceSubmissionSummaryPage,
      SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewSimplifiedServiceSummaryPage,
      SubscriptionComponentKey.EditSitePage,
      SubscriptionComponentKey.ServiceUpdatePage,
      SubscriptionComponentKey.ServiceAgreementPage,

      // UnamanagedServiceUpload module
      SubscriptionComponentKey.UnmanagedServicesLocationsPage,
      SubscriptionComponentKey.BulkOnDemandServicesUploadPage,
      SubscriptionComponentKey.BulkRecurringServicesUploadPage,
      SubscriptionComponentKey.BulkSeasonalServicesUploadPage,
      SubscriptionComponentKey.ServicesUpdatePage,
      SubscriptionComponentKey.OnDemandPickUpsPage,

      // AuditInvoice module
      SubscriptionComponentKey.InvoiceAuditPage,

      // HaulerBillConsolidation module
      SubscriptionComponentKey.HaulerBillConsolidationPage,

      // InvoicingBilling module
      SubscriptionComponentKey.InvoicesListPage,
      SubscriptionComponentKey.InvoiceDetailsPage,

      // CustomReport module
      SubscriptionComponentKey.UploadedDocsPage,
      SubscriptionComponentKey.PowerBiPage,
      SubscriptionComponentKey.FoundryPage,

      // PublicApi module
      SubscriptionComponentKey.ApiKeyManagementPage,

      // ESGReporting module
      SubscriptionComponentKey.SpendByMaterialPage,
      SubscriptionComponentKey.DiversionPage,
      SubscriptionComponentKey.CarbonReductionPage,
      SubscriptionComponentKey.GhGCarbonEmissionsPage,
      SubscriptionComponentKey.MaterialProfilePage,
      SubscriptionComponentKey.SustainabilityHubPage,
      SubscriptionComponentKey.DashboardPage, // partial components are shown
    
      // EquipmentSensor
      SubscriptionComponentKey.RightSizingPage,
      SubscriptionComponentKey.ServiceInsightsPage,
      SubscriptionComponentKey.ServiceConfirmationAnalytics,
    ],
  }, {
    subscriptionKey: SubscriptionType.Gold,
    components: [
      // ManagedService module
      SubscriptionComponentKey.DashboardPage, // partial components are shown
      SubscriptionComponentKey.OptimizationReportPage,
      SubscriptionComponentKey.OptimizationDetailsPage,
      SubscriptionComponentKey.FrontLoadDiversionReportPage,
      SubscriptionComponentKey.FrontLoadDiversionDetailsPage,
      SubscriptionComponentKey.ServiceLocationsPage,
      SubscriptionComponentKey.ServiceSiteProfilePageWrapper,
      SubscriptionComponentKey.ServiceRequestsPage,
      SubscriptionComponentKey.ServiceApprovalPage,
      SubscriptionComponentKey.NewServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewServiceStartDatePage,
      SubscriptionComponentKey.NewServiceSummaryPage,
      SubscriptionComponentKey.NewServiceSubmissionSummaryPage,
      SubscriptionComponentKey.NewSimplifiedServiceDetailsPageWrapper,
      SubscriptionComponentKey.NewSimplifiedServiceSummaryPage,
      SubscriptionComponentKey.EditSitePage,
      SubscriptionComponentKey.ServiceUpdatePage,
      SubscriptionComponentKey.ServiceAgreementPage,

      // UnamanagedServiceUpload module
      SubscriptionComponentKey.UnmanagedServicesLocationsPage,
      SubscriptionComponentKey.BulkOnDemandServicesUploadPage,
      SubscriptionComponentKey.BulkRecurringServicesUploadPage,
      SubscriptionComponentKey.BulkSeasonalServicesUploadPage,
      SubscriptionComponentKey.ServicesUpdatePage,
      SubscriptionComponentKey.OnDemandPickUpsPage,

      // AuditInvoice module
      SubscriptionComponentKey.InvoiceAuditPage,
      
      // HaulerBillConsolidation module
      SubscriptionComponentKey.HaulerBillConsolidationPage,
      
      // InvoicingBilling module
      SubscriptionComponentKey.InvoicesListPage,
      SubscriptionComponentKey.InvoiceDetailsPage,

      // CustomReport module
      SubscriptionComponentKey.UploadedDocsPage,
      SubscriptionComponentKey.PowerBiPage,
      SubscriptionComponentKey.FoundryPage,

      // PublicApi module
      SubscriptionComponentKey.ApiKeyManagementPage,
      
      // ESGReporting module
      SubscriptionComponentKey.SpendByMaterialPage,
      SubscriptionComponentKey.DiversionPage,
      SubscriptionComponentKey.CarbonReductionPage,
      SubscriptionComponentKey.GhGCarbonEmissionsPage,
      SubscriptionComponentKey.MaterialProfilePage,
      SubscriptionComponentKey.SustainabilityHubPage,
      SubscriptionComponentKey.DashboardPage, // partial components are shown
    
      // EquipmentSensor module
      SubscriptionComponentKey.RightSizingPage,
      SubscriptionComponentKey.ServiceInsightsPage,
      SubscriptionComponentKey.ServiceConfirmationAnalytics,
    ],
  }
];

export const brandedContentDictionary: BrandingDictionaryElement[] = [
  {
    brandingKey: BrandingType.RssNss,
    components: [
      SubscriptionComponentKey.SavingsPage
    ],
  },
  {
    brandingKey: BrandingType.RecognizedMarketingContent,
    components: [
      SubscriptionComponentKey.MarketingContentPage
    ],
  },
  {
    brandingKey: BrandingType.RecognizedMediaContent,
    components: [
      SubscriptionComponentKey.MediaContentPage,
    ],
  },
  {
    brandingKey: BrandingType.RegWatchUpdates,
    components: [
      SubscriptionComponentKey.RegWatchLatestUpdateDetailsPage,
      SubscriptionComponentKey.RegWatchUpdateDetailsPage,
      SubscriptionComponentKey.RegWatchUpdatesPage,
    ],
  },
  {
    brandingKey: BrandingType.RegWatchAssessments,
    components: [
      SubscriptionComponentKey.RegWatchAssessmentsPage,
    ],
  },
  {
    brandingKey: BrandingType.EndDestination,
    components: [
      SubscriptionComponentKey.GhGCarbonEmissionsPage
    ],
  },
  {
    brandingKey: BrandingType.ServiceConfirmationAnalytics,
    components: [
      SubscriptionComponentKey.ServiceConfirmationAnalytics
    ]
  }
];
