import React from 'react';

import { LocationFilter } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import LocationTypeAheadFilter from './LocationTypeAheadFilter';

const LocationCityFilter: React.FC<ComponentProps> = ({
  isDisabled,
  currentFilter,
  onFilterChanged,
}) => {
  return (
    <LocationTypeAheadFilter
      label={translate('city')}
      currentFilter={currentFilter}
      filterChanged={onFilterChanged}
      isDisabled={isDisabled}
      typeAheadType="city"
    />
  );
};

interface ComponentProps {
  isDisabled?: boolean;
  currentFilter: LocationFilter;
  onFilterChanged: (selectedCity: LocationFilter) => void;
}

export default LocationCityFilter;
