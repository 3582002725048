import filter from 'lodash-es/filter';

import { RunningStateItem } from 'contracts/types/state';

function buildRunningState(
  isRunning: boolean,
  actionName: string,
  timestamps: string[],
): RunningStateItem {
  const result = {
    isRunning,
    actionName,
    timestamps,
  };
  return result;
}

function runAction(
  runningState: RunningStateItem,
  actionName: string,
  timestamp?: string,
  takeLast?: boolean,
): RunningStateItem {
  if (runningState.actionName !== actionName) {
    throw Error(
      `Invalid running object selected! Expected ${runningState.actionName} but received ${actionName}`,
    );
  }
  if (!timestamp) {
    return buildRunningState(true, runningState.actionName, []);
  }
  if (takeLast) {
    return buildRunningState(true, runningState.actionName, [timestamp]);
  }
  if (runningState.timestamps.find(ts => ts === timestamp)) {
    return runningState;
  }
  const newTimestamps = [...runningState.timestamps, timestamp];
  return buildRunningState(true, runningState.actionName, newTimestamps);
}

function stopAction(
  runningState: RunningStateItem,
  actionName: string,
  timestamp?: string,
  takeLast?: boolean,
): RunningStateItem {
  if (runningState.actionName !== actionName) {
    throw Error(
      `Invalid running object selected! Expected ${runningState.actionName} but received ${actionName}`,
    );
  }
  if (
    !timestamp ||
    (takeLast && runningState.timestamps.find(ts => ts === timestamp))
  ) {
    return buildRunningState(false, runningState.actionName, []);
  }

  const newTimestamps = filter(runningState.timestamps, ts => ts !== timestamp);
  if (newTimestamps.length === runningState.timestamps.length) {
    return runningState;
  }
  return buildRunningState(
    newTimestamps.length > 0,
    runningState.actionName,
    newTimestamps,
  );
}

function buildRunningOperation(
  actionName: string,
  timestamp?: string,
): RunningStateItem {
  return buildRunningState(true, actionName, timestamp ? [timestamp] : []);
}

const RunningStateOperation = {
  buildRunningOperation,
  runAction,
  stopAction,
};

export default RunningStateOperation;
