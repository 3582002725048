import React, { PropsWithChildren, useEffect } from 'react';

import memoizeOne from 'memoize-one';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { FilterState, FilterStore, FilterTag } from 'contracts/models';
import { LocationFilter, SiteIdentifier } from 'contracts/types/component';
import { ApplicationState } from 'contracts/types/state';
import locationFilterDuck from 'core/ducks/filters';
import { actionIsRunning } from 'core/ducks/running';
import { setFilterStores } from 'core/helpers/locationStoreFilters';

import { DropdownFilterValue } from './DropdownFilter/DropdownFilterPopup';
import {
  LocationStateFilter,
  LocationStoreFilter,
  LocationTagFilter,
  LocationCityFilter,
  LocationZipFilter,
} from './Filters';
import {
  DropdownFiltersBar,
  DropdownFiltersClear,
  DropdownFiltersCollection,
} from './styled/DropdownFiltersBar';

const LocationFilters: React.FC<ComponentProps> = ({
  currentFilter,
  isLoadingStates,
  isLoadingStores,
  isLoadingTags,
  loadFilterStates,
  loadFilterStores,
  loadFilterTags,
  onFilterChanged,
  hasCityZipFilter,
  hideStates,
  states,
  stores,
  tags,
  isDisabled,
  children,
  extraChildren,
  noMargin
}) => {
  useEffect(() => {
    loadFilterTags();
    loadFilterStores();
    loadFilterStates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAll = (): void => {
    onFilterChanged({});
    setFilterStores([]);
  };

  const onFilterChangedCity = (newFiltersWithCities: LocationFilter): void => {
    onFilterChanged({
      ...currentFilter,
      ...newFiltersWithCities,
    });
  };

  const onFilterChangedZipCode = (newFiltersWithZip: LocationFilter): void => {
    onFilterChanged({
      ...currentFilter,
      ...newFiltersWithZip,
    });
  };

  const onFilterChangedTags = (selectedItems: number[]): void => {
    onFilterChanged({
      ...currentFilter,
      tags: selectedItems,
      stores: [],
      states: [],
    });
    setFilterStores([]);
  };

  const onFilterChangedStores = (selectedItems: string[]): void => {
    if (onFilterChanged) {
      const mappedStores: SiteIdentifier[] = selectedItems
        ? selectedItems.map(store => {
          const splitStrings = store.split('-');
          return {
            siteId: splitStrings[0],
            siteCustomerId: splitStrings[1],
          };
        })
        : [];
      onFilterChanged({
        ...currentFilter,
        stores: mappedStores,
      });
      setFilterStores(mappedStores);
    }
  };

  const onFilterChangedStates = (selectedItems: string[]): void => {
    onFilterChanged({
      ...currentFilter,
      states: selectedItems,
    });
  };

  const hasAnyFilters =
      currentFilter &&
      ((currentFilter.tags && currentFilter.tags.length > 0) ||
        (currentFilter.cities && currentFilter.cities.length > 0) ||
        (currentFilter.zipCodes && currentFilter.zipCodes.length > 0) ||
        (currentFilter.stores && currentFilter.stores.length > 0) ||
        (currentFilter.states && currentFilter.states.length > 0));

  const filteredTags = getFilteredTags(tags);
  const filteredStores = getFilteredStores(
    stores,
    filteredTags,
    currentFilter,
  );
  const filteredStates = getFilteredStates(states, filteredStores);

  return (
    <DropdownFiltersBar noMargin={noMargin}>
      <DropdownFiltersCollection>
        {hasCityZipFilter ? (
          <>
            <LocationCityFilter
              currentFilter={currentFilter}
              onFilterChanged={onFilterChangedCity}
            />
            <LocationZipFilter
              currentFilter={currentFilter}
              onFilterChanged={onFilterChangedZipCode}
            />
          </>
        ) : null}
        <LocationTagFilter
          isLoading={isLoadingTags}
          tags={mapTags(filteredTags)}
          currentFilter={currentFilter}
          onFilterChanged={onFilterChangedTags}
          isDisabled={isDisabled}
        />
        <LocationStoreFilter
          isLoading={isLoadingStores}
          stores={mapStores(filteredStores)}
          currentFilter={currentFilter}
          onFilterChanged={onFilterChangedStores}
          isDisabled={isDisabled}
        />
        {!hideStates &&
        <LocationStateFilter
          isLoading={isLoadingStates}
          states={mapStates(filteredStates)}
          currentFilter={currentFilter}
          onFilterChanged={onFilterChangedStates}
          isDisabled={isDisabled}
        />
        }
        {children}
      </DropdownFiltersCollection>
      {extraChildren}
      {!!hasAnyFilters && (
        <DropdownFiltersClear onClick={clearAll} disabled={isDisabled}>
            Clear All
        </DropdownFiltersClear>
      )}
    </DropdownFiltersBar>
  );

};

const getFilteredTags = memoizeOne<(tags: FilterTag[]) => Array<Required<FilterTag>>
  >(tags =>
    tags.length > 0
      ? tags
        .filter(tag => tag.tagId && tag.tagName)
        .map(tag => tag as Required<FilterTag>)
      : [],
  );
const mapTags = memoizeOne<(tags: Array<Required<FilterTag>>) => Array<DropdownFilterValue<number>>
  >(tags =>
    tags.length > 0
      ? tags.map(
        tag =>
          ({
            id: tag.tagId,
            label: tag.tagName,
          } as DropdownFilterValue<number>),
      )
      : [],
  );

const getFilteredStores = memoizeOne<(
  stores: FilterStore[],
  tags: Array<Required<FilterTag>>,
  currentFilter: LocationFilter,
) => Array<Required<FilterStore>>
  >((stores, tags, currentFilter) => {
    if (stores && stores.length) {
      let filteredStores = stores
        .filter(store => store.siteCustId && store.siteId && store.storeName)
        .map(store => store as Required<FilterStore>);
      if (
        tags &&
      tags.length &&
      currentFilter &&
      currentFilter.tags &&
      currentFilter.tags.length
      ) {
        const selectedTags = tags.filter(t =>
          currentFilter.tags?.includes(t.tagId),
        );
        let selectedTagsSiteIdentifiers: SiteIdentifier[] = [];
        selectedTags.forEach(selectedTag => {
          selectedTagsSiteIdentifiers = selectedTagsSiteIdentifiers.concat(
            selectedTag.siteIdetifiers,
          );
        });
        filteredStores = filteredStores.filter(store => {
          const storeMatch = selectedTagsSiteIdentifiers.filter(
            tagSiteId =>
              tagSiteId.siteId === store.siteId &&
            tagSiteId.siteCustomerId === store.siteCustId,
          );
          return storeMatch.length > 0;
        });
      }
      return filteredStores;
    }
    return [];
  });

const mapStores = memoizeOne<(stores: Array<Required<FilterStore>>) => Array<DropdownFilterValue<string>>
  >(stores =>
    stores && stores.length > 0
      ? stores.map(
        store =>
          ({
            id: `${store.siteId}-${store.siteCustId}`,
            label: `${store.storeName} / ${store.siteCode} (${store.siteId})`,
            filterKey: `${store.storeName} ${store.siteCode}`,
          } as DropdownFilterValue<string>),
      )
      : [],
  );

const getFilteredStates = memoizeOne<(
  states: FilterState[],
  stores: Array<Required<FilterStore>>,
) => Array<Required<FilterState>>
  >((states, stores) => {
    if (states && states.length) {
      let filteredStates = states
        .filter(state => state.stateId && state.stateName)
        .map(state => state as Required<FilterState>);
      if (stores && stores.length) {
        filteredStates = filteredStates.filter(state => {
          const stateMatch = stores.filter(
            store => store.stateId === state.stateId,
          );
          return stateMatch.length > 0;
        });
      }
      return filteredStates;
    }
    return [];
  });
const mapStates = memoizeOne<(states: Array<Required<FilterState>>) => Array<DropdownFilterValue<string>>
  >(states =>
    states && states.length > 0
      ? states.map(
        state =>
          ({
            id: `${state.countryId}-${state.stateId}`,
            label: state.stateName,
          } as DropdownFilterValue<string>),
      )
      : [],
  );

interface StateProps {
  isLoadingTags: boolean;
  isLoadingStores: boolean;
  isLoadingStates: boolean;
  tags: FilterTag[];
  stores: FilterStore[];
  states: FilterState[];
}

interface DispatchProps {
  loadFilterTags: () => unknown;
  loadFilterStores: () => unknown;
  loadFilterStates: () => unknown;
}

interface OwnProps {
  currentFilter: LocationFilter;
  onFilterChanged: (filter: LocationFilter) => void;
  isDisabled?: boolean;
  hasCityZipFilter?: boolean;
  hideStates?: boolean;
  noMargin?: boolean;
  extraChildren?: React.ReactNode;
}

type ComponentProps = StateProps & DispatchProps & PropsWithChildren<OwnProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  ApplicationState
> = (state: ApplicationState): StateProps => {
  const { tags, stores, states } = state.core.locationFilters;
  return {
    tags,
    stores,
    states,
    isLoadingTags: actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_TAGS,
    ),
    isLoadingStores: actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_STORES,
    ),
    isLoadingStates: actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_STATES,
    ),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  loadFilterTags: locationFilterDuck.thunks.loadFilterTags,
  loadFilterStores: locationFilterDuck.thunks.loadFilterStores,
  loadFilterStates: locationFilterDuck.thunks.loadFilterStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilters);
