import { log, event } from 'react-fullstory';

import { LOG_ERROR, LOG_EVENT, LOG_SUCCESS } from 'core/constants/logging';

export const logEvent = (type: string = LOG_EVENT, payload: any = {}): void => {
  event(type, {
    url: window?.location?.pathname.concat(window?.location?.search),
    ...payload,
  });
  log('log', payload);
};

export const logError = (type: string = LOG_ERROR, payload: any = {}): void => {
  event(type, {
    url: window?.location?.pathname.concat(window?.location?.search),
    ...payload,
  });
  log('warn', payload);
};

export const logSuccess = (type: string = LOG_SUCCESS, payload: any = {}): void => {
  event(type, {
    url: window?.location?.pathname.concat(window?.location?.search),
    ...payload,
  });
  log('info', payload);
};
