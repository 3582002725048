import { BarSeries } from '..';

class BarTotalizer {
  totals: number[] = [];
  totalValue = 0;
  topDatasetIndex = 0;

  build = (series: BarSeries[]): void => {
    if (series && series.length) {
      series.forEach((seriesItem, index) => {
        this.topDatasetIndex = index;
        if (seriesItem.values && seriesItem.values.length) {
          seriesItem.values.forEach((value, indexData) => {
            this.totals[indexData] = (this.totals[indexData] || 0) + value;
          });
        }
      });
      this.totalValue =
        this.totals.length > 0 ? this.totals.reduce((a, b) => a + b) : 0;
      for (let indexData = 0; indexData < this.totals.length; indexData++) {
        if (this.totals[indexData] < 0) {
          this.totals[indexData] = 0;
          for (
            let indexSeries = 0;
            indexSeries < series.length;
            indexSeries++
          ) {
            series[indexSeries].values[indexData] = 0;
          }
        }
      }
    }
  };

  buildFromValues = (values: number[], topDatasetIndex: number): void => {
    if (values && values.length) {
      values.forEach((value, index) => {
        this.topDatasetIndex = topDatasetIndex;
        this.totals[index] = (this.totals[index] || 0) + value;
      });
      this.totalValue =
        this.totals.length > 0 ? this.totals.reduce((a, b) => a + b) : 0;
    }
  };
}

export default BarTotalizer;
