import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';

import { EquipmentDisplay, EquipmentMap } from 'contracts/types/compactorProfile';
import { ApplicationState } from 'contracts/types/state';
import locationFilterDuck from 'core/ducks/filters';
import { actionIsRunning } from 'core/ducks/running';

import { EquipmentFilterBase } from './EquipmentFilter';

const mapEquipments = memoizeOne(equipments =>
  equipments && equipments.length > 0
    ? equipments.map((eq: EquipmentMap) => ({
      id: eq.svcCodePrefix,
      label: eq.name,
    }))
    : [],
);

const mapStateToProps = (state: ApplicationState): {
  allEquipments: EquipmentDisplay[];
  isLoadingEquipments: boolean;
} => {
  const { optimizationEquipments } = state.core.locationFilters;
  const result = {
    allEquipments: mapEquipments(optimizationEquipments),
    isLoadingEquipments: actionIsRunning(
      state,
      locationFilterDuck.actionKeys.LOAD_FILTER_OPTIMIZATION_EQUIPMENTS,
    ),
  };
  return result;
};

const mapDispatchToProps = {
  loadEquipments: locationFilterDuck.thunks.loadFilterOptimizationEquipments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EquipmentFilterBase);
