import Holiday, { IHoliday } from 'contracts/models/service/Holiday';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getPublicHolidays = async(yearCount = 1): Promise<Holiday[]> => {
  const response = await httpCustomerCore.get<Holiday[]>(
    `vendor/holidays?yearCount=${yearCount}`,
    {
      transformResponse: transformServiceResponse<IHoliday, Holiday>(Holiday),
    },
  );
  return response.data;
};

export default getPublicHolidays;
