import FilterApprover, {
  IFilterApprover,
} from 'contracts/models/service/FilterApprover';
import FilterRequester, {
  IFilterRequester,
} from 'contracts/models/service/FilterRequester';
import FilterRequestType, {
  IFilterRequestType,
} from 'contracts/models/service/FilterRequestType';
import ServiceRequestApproval, {
  IServiceRequestApproval,
} from 'contracts/models/service/ServiceRequestApproval';
import { LocationFilter } from 'contracts/types/component';
import { ApprovalRequestsRequest } from 'contracts/types/request';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getServiceRequests = async(arg: ApprovalRequestsRequest): Promise<ServiceRequestApproval[]> => {
  const requestArg = createRequestArg(arg);
  const response = await httpCustomerCore.post<ServiceRequestApproval[]>(
    '/service-requests/get-approval-requests',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IServiceRequestApproval,
        ServiceRequestApproval
      >(ServiceRequestApproval),
    },
  );

  return response.data;
};

function createRequestArg({
  fromDate,
  toDate,
  pageSize,
  pageNumber,
  filter,
}: ApprovalRequestsRequest): unknown {
  const arg = {
    StartDate: fromDate,
    EndDate: toDate,
    PageSize: pageSize,
    PageNumber: pageNumber,
    SiteFilters: filter ? getLocationFilterApiArg(filter) : null,
    RequestTypes:
      filter && filter.ordType && filter.ordType.length
        ? filter.ordType.map(f => ({ OrdType: f }))
        : [],
    RequesterCustomers:
      filter && filter.requesterCustomers && filter.requesterCustomers.length
        ? filter.requesterCustomers.map(f => ({ Email: f }))
        : [],
    Approvers:
      filter && filter.approvers && filter.approvers.length
        ? filter.approvers.map(f => ({ Email: f }))
        : [],
  };
  return arg;
}

export const sendApproveServiceRequests = async(serviceOrders: number[]): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'request-service/approval/service-bulk-approve',
    {
      serviceOrders,
    },
  );

  return response.data;
};

export const sendDenyServiceRequests = async(serviceOrders: number[]): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'request-service/approval/service-bulk-deny',
    {
      serviceOrders,
    },
  );

  return response.data;
};

export const getApproverCustomers = async(
  custId: string,
  locationFilters: LocationFilter,
): Promise<FilterApprover[]> => {
  const requestArg = {
    CustId: custId,
    SiteFilters: locationFilters
      ? getLocationFilterApiArg(locationFilters)
      : null,
  };
  const response = await httpCustomerCore.post<FilterApprover[]>(
    '/request-service/approval/approver-customers',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IFilterApprover,
        FilterApprover
      >(FilterApprover),
    },
  );

  return response.data;
};

export const getRequesterCustomers = async(
  custId: string,
  locationFilters: LocationFilter,
): Promise<FilterRequester[]> => {
  const requestArg = {
    CustId: custId,
    SiteFilters: locationFilters
      ? getLocationFilterApiArg(locationFilters)
      : null,
  };
  const response = await httpCustomerCore.post<FilterRequester[]>(
    '/request-service/approval/requester-customers',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IFilterRequester,
        FilterRequester
      >(FilterRequester),
    },
  );

  return response.data;
};

export const getFilterRequestTypes = async(
  custId: string,
  locationFilters: LocationFilter,
): Promise<FilterRequestType[]> => {
  const requestArg = {
    CustId: custId,
    SiteFilters: locationFilters
      ? getLocationFilterApiArg(locationFilters)
      : null,
  };
  const response = await httpCustomerCore.post<FilterRequestType[]>(
    '/request-service/approval/request-types',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IFilterRequestType,
        FilterRequestType
      >(FilterRequestType),
    },
  );

  return response.data;
};
