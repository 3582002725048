import Environments from './Environments';

export const BASE_URL_MAP: { [url: string]: Environments } = {
  'customer.rubicon.com': Environments.production,
  'customer.prod.aws.rubicon.com': Environments.production,
  'rubicon-customer-portal-web.aws.rubicon.com': Environments.production,
  'customer.qa.aws.rubicon.com': Environments.qa,
  'customer.dev.aws.rubicon.com': Environments.development,
  'customer.uat.aws.rubicon.com': Environments.uat,
  'customer-temp.dev.aws.rubicon.com': Environments.temp_development,
  'customer-alpha.dev.aws.rubicon.com': Environments.alpha_dev,
};

export const API_BASE_URL_MAP = {
  zuora: {
    [Environments.production]: 'https://api.zuora.com/rest/v1',
    [Environments.uat]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.qa]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.development]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.temp_development]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.alpha_dev]: 'https://apisandbox-api.zuora.com/rest/v1',
    [Environments.local]: 'https://apisandbox-api.zuora.com/rest/v1',
  },
  customerCore: {
    [Environments.production]:
      'https://customer-api.prod.aws.rubicon.com',
    [Environments.uat]: 'https://customer-api.uat.aws.rubicon.com',
    [Environments.qa]: 'https://customer-api.qa.aws.rubicon.com',
    [Environments.development]:
      'https://customer-api-core.dev.aws.rubicon.com',
    [Environments.temp_development]:
      'https://customer-api-core.dev.aws.rubicon.com',
    [Environments.alpha_dev]:
      'https://customer-api-alpha.dev.aws.rubicon.com',
    [Environments.local]: 'https://customer-api-core.dev.aws.rubicon.com',
  },
};
