import styled from 'styled-components';

const FormError = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${props => props.theme.colors.brandDanger};
`;

export const FormRequired = styled.span`
  font-size: 12px;
  position: absolute;
  left: -10px;
  top: 50%;
  color: ${props => props.theme.colors.brandDanger};
`;

export default FormError;
