import { API_BASE_URL_MAP, BASE_URL_MAP } from 'core/constants/BaseUrls';
import Environments from 'core/constants/Environments';

export const environment = BASE_URL_MAP[window.location.host] || Environments.local;
export const zuoraEnvironment = environment === Environments.local ? Environments.local : null;

export const apiBaseURLs = {
  zuora: API_BASE_URL_MAP.zuora[environment],
  customerCore: API_BASE_URL_MAP.customerCore[environment],
};
