export interface IUserRole {
  readonly role: string;
  readonly description: string;
}

class UserRole {
  role?: string;
  description?: string;

  constructor(data?: Partial<IUserRole>) {
    this.role = data?.role;
    this.description = data?.description;
  }
}

export default UserRole;
