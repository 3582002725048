import { TableData } from 'contracts/types/component';

export interface IServiceRequestApproval {
  readonly custId: string;
  readonly siteId: string;
  readonly serviceOrder: number;
  readonly orderNumber: number;
  readonly requestDate: string;
  readonly requestType: string;
  readonly siteCode: string;
  readonly siteName: string;
  readonly siteAddress: string;
  readonly serviceDescription: string;
  readonly requestedByName: string;
  readonly requestedByEmail: string;
  readonly displayedApproverEmail: string;
  readonly reviewerEmail: string;
  readonly contactType: string;
  readonly isApprovalRequestReadonly: boolean;
  readonly isChangeService: boolean;
  readonly changeServiceUrl: string;
}

class ServiceRequestApproval implements TableData {
  custId?: string;
  siteId?: string;
  serviceOrder?: number;
  orderNumber?: number;
  requestDate?: string;
  requestType?: string;
  siteCode?: string;
  siteName?: string;
  siteAddress?: string;
  serviceDescription?: string;
  requestedByName?: string;
  requestedByEmail?: string;
  displayedApproverEmail?: string;
  reviewerEmail?: string;
  contactType?: string;
  isApprovalRequestReadonly?: boolean;
  isChangeService?: boolean;
  changeServiceUrl?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IServiceRequestApproval>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.serviceOrder = data?.serviceOrder;
    this.orderNumber = data?.orderNumber;
    this.requestDate = data?.requestDate;
    this.requestType = data?.requestType;
    this.siteCode = data?.siteCode;
    this.siteName = data?.siteName;
    this.siteAddress = data?.siteAddress;
    this.serviceDescription = data?.serviceDescription;
    this.requestedByName = data?.requestedByName;
    this.requestedByEmail = data?.requestedByEmail;
    this.displayedApproverEmail = data?.displayedApproverEmail;
    this.reviewerEmail = data?.reviewerEmail;
    this.contactType = data?.contactType;
    this.isApprovalRequestReadonly = data?.isApprovalRequestReadonly;
    this.isChangeService = data?.isChangeService;
    this.changeServiceUrl = data?.changeServiceUrl;
  }
}

export default ServiceRequestApproval;
