import { RequestNewServiceResult } from 'contracts/models';
import RequestServiceResult, {
  IRequestServiceResult,
} from 'contracts/models/service/RequestServiceResult';
import ServiceAvailability, {
  IServiceAvailability,
} from 'contracts/models/service/ServiceAvailability';
import ServiceEquipmentRate, {
  IServiceEquipmentRate,
} from 'contracts/models/service/ServiceEquipmentRate';
import ServiceRequestOptions, {
  IServiceRequestOptions,
} from 'contracts/models/service/ServiceRequestOptions';
import {
  CheckRequestDuplicateArg,
  RequestServiceRequest,
  RequestServiceUpdateRequest,
  ServiceApprovalDetailsArg,
  ServiceApproveRequestArg,
  ServiceDenyRequestArg,
  ServiceRequestOption,
  UniqueReferenceNrRequest,
  UpdateServiceRequestOption,
} from 'contracts/types/request';
import {
  ExistentServiceRequestWithOptions,
  IExistentServiceRequestWithOptions,
  ServiceApprovalDetailsResponse,
  UploadFileRequest,
  UploadFileResponse,
} from 'contracts/types/service';
import { writeBlobFile } from 'core/services/fileManagement';
import {
  httpCustomerCore,
  transformServiceResponse,
  transformServiceResponseSimple,
} from 'core/services/http';

export const getServiceFees = async(serviceId: number): Promise<ServiceEquipmentRate[]> => {
  const response = await httpCustomerCore.get<ServiceEquipmentRate[]>(
    `service-equipment/rates?serviceId=${serviceId}`,
    {
      transformResponse: transformServiceResponse<
        IServiceEquipmentRate,
        ServiceEquipmentRate
      >(ServiceEquipmentRate),
    },
  );
  return response.data;
};

export const sendServiceRequest = async(data: RequestServiceRequest): Promise<RequestServiceResult[]> => {
  const response = await httpCustomerCore.post<RequestServiceResult[]>(
    'service-equipment/request-service',
    data,
    {
      transformResponse: transformServiceResponse<
        IRequestServiceResult,
        RequestServiceResult
      >(RequestServiceResult),
    },
  );
  return response.data;
};

export const updateServiceRequest = async(
  data: RequestServiceUpdateRequest,
): Promise<RequestServiceResult> => {
  const response = await httpCustomerCore.put<RequestServiceResult>(
    'service-equipment/request-service',
    data,
    {
      transformResponse: transformServiceResponse<
        IRequestServiceResult,
        RequestServiceResult
      >(RequestServiceResult),
    },
  );
  return response.data;
};

export const getServiceRequestAvailability = async(
  data: CheckRequestDuplicateArg,
): Promise<ServiceAvailability> => {
  const response = await httpCustomerCore.post<ServiceAvailability>(
    'service-equipment/request-service/availability',
    data,
    {
      transformResponse: transformServiceResponse<
        IServiceAvailability,
        ServiceAvailability
      >(ServiceAvailability),
    },
  );
  return response.data;
};

export async function uploadImageFile(
  uploadFileRequest: UploadFileRequest,
): Promise<UploadFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    '/service-equipment/request-service/upload-image-file',
    uploadFileRequest,
  );
  return response;
}

export const getServiceRequestOptions = async(arg: ServiceRequestOption): Promise<ServiceRequestOptions> => {
  const response = await httpCustomerCore.post<ServiceRequestOptions>(
    'request-service/options',
    arg,
    {
      transformResponse: transformServiceResponse<
        IServiceRequestOptions,
        ServiceRequestOptions
      >(ServiceRequestOptions),
    },
  );
  return response.data;
};

export const getRequestOrderOptions = async(
  arg: UpdateServiceRequestOption,
): Promise<ExistentServiceRequestWithOptions> => {
  const response = await httpCustomerCore.post<ExistentServiceRequestWithOptions>(
    'request-service/get-order-options',
    arg,
    {
      transformResponse: transformServiceResponse<
        IExistentServiceRequestWithOptions,
        ExistentServiceRequestWithOptions
      >(ExistentServiceRequestWithOptions),
    },
  );
  return response.data;
};

export const getApprovalServiceRequestOptions = async(
  arg: ServiceApprovalDetailsArg,
): Promise<ServiceApprovalDetailsResponse> => {
  const response = await httpCustomerCore.post<ServiceApprovalDetailsResponse>(
    'request-service/approval/service-details',
    arg,
    {
      transformResponse: transformServiceResponseSimple(
        (data: ServiceApprovalDetailsResponse) => {
          data.options = new ServiceRequestOptions(data.options);
          return data;
        },
      ),
    },
  );
  return response.data;
};

export const approveServiceRequest = async(arg: ServiceApproveRequestArg): Promise<RequestNewServiceResult[]> => {
  const response = await httpCustomerCore.post(
    'request-service/approval/service-approve',
    arg,
  );
  return response.data;
};

export const denyServiceRequest = async(arg: ServiceDenyRequestArg): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'request-service/approval/service-deny',
    arg,
  );
  return response.data;
};

export const checkIsReferenceNrUnique = async(params: UniqueReferenceNrRequest): Promise<boolean> => {
  const response = await httpCustomerCore.get<boolean>(
    `service-equipment/commodity/is-release-number-unique`,
    {
      params
    }
  );
  return response.data;
};
