import styled, { css } from 'styled-components';

import { theme } from 'core/styles';

export const ProgressBar = styled.div`
  position: absolute;
  height: 10px;
  width: 300px;
  border-radius: 50px;
  border: 0px solid #a9a9a9;
  margin-left: 60px;
  background: #a9a9a9;
`;

interface FillerProps {
  percentage: number;
}
export const Filler = styled.div<FillerProps>`
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;

  ${props =>
    props.percentage &&
    css`
      width: ${props.percentage}%;
      background: ${theme.colors.brandWhite};
    `};
`;
