import {
  FrontLoadDiversionSiteDetailsMetics,
  FrontLoadDiversionReportSiteService,
  WasteType,
  ContainerSize,
  Frequency,
  FrontLoadMaterialsModel,
} from 'contracts/types/frontLoadDiversion';

export interface IFrontLoadDiversionSiteDetails {
  readonly siteDetailsMetrics: FrontLoadDiversionSiteDetailsMetics;
  readonly siteServices: FrontLoadDiversionReportSiteService[];
  readonly suggestedSiteServices: FrontLoadDiversionReportSiteService[];
  readonly siteAddress: string;
  readonly wasteTypes: WasteType[];
  readonly containerSizes: ContainerSize[];
  readonly frequencies: Frequency[];
  readonly materials: FrontLoadMaterialsModel[];
}

class FrontLoadDiversionSiteDetails {
  siteDetailsMetrics?: FrontLoadDiversionSiteDetailsMetics;
  siteServices?: FrontLoadDiversionReportSiteService[];
  suggestedSiteServices?: FrontLoadDiversionReportSiteService[];
  siteAddress?: string;
  wasteTypes?: WasteType[];
  containerSizes?: ContainerSize[];
  frequencies?: Frequency[];
  materials?: FrontLoadMaterialsModel[];

  constructor(data?: Partial<IFrontLoadDiversionSiteDetails>) {
    this.siteDetailsMetrics = data?.siteDetailsMetrics;
    this.siteServices = data?.siteServices;
    this.suggestedSiteServices = data?.suggestedSiteServices;
    this.siteAddress = data?.siteAddress;
    this.wasteTypes = data?.wasteTypes;
    this.containerSizes = data?.containerSizes;
    this.frequencies = data?.frequencies;
    this.materials = data?.materials;
  }
}

export default FrontLoadDiversionSiteDetails;
