enum UserRole {
  Admin = 'CustAdmin',
  Manager = 'CustMgr',
  User = 'CustUser',
  Readonly = 'CustReadonly',
  ServiceOnly = 'CustServiceOnly',
  RegWatchOnly = 'CustRegWatchOnly',
}

export default UserRole;
