import { RegWatchAssessment } from 'contracts/models';
import RegWatchAssessmentMetrics, {
  IRegWatchAssessmentMetrics,
} from 'contracts/models/service/RegWatchAssessmentMetrics';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getRegWatchAssessmentMetrics(): Promise<RegWatchAssessmentMetrics> {
  
  const response = await httpCustomerCore.post<RegWatchAssessmentMetrics>(
    `reg-watch/customer/assessment-metrics`,
    {
      transformResponse: transformServiceResponse<
        IRegWatchAssessmentMetrics,
        RegWatchAssessmentMetrics
      >(RegWatchAssessmentMetrics),
    },
  );
  return response.data;
}

export async function getRegWatchAssessmentsCsv(assessments: RegWatchAssessment[]): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'reg-watch/generate-assessments-csv',
    assessments
  );
  return response;
}
