import { TooltipData } from 'contracts/types/chart';
import { decimalFormattedShort, roundToTwo } from 'core/services/formatter';

import { BarSeries, BarTotalizer } from '..';

class BarNormalizer {
  filteredSeries: BarSeries[] = [];
  data: number[][] = [];
  labelData: number[][] = [];
  tooltipData: TooltipData[][] = [];

  build = (
    series: BarSeries[],
    totalizer: BarTotalizer,
    hasTotalBar = false,
    groupPercentThreshold = 0,
    showPercents = false,
  ): void => {
    this.filteredSeries = [...series];

    if (
      series &&
      series.length > 0 &&
      totalizer &&
      totalizer.totals.length > 0
    ) {
      const { length: seriesLength } = series;
      const { length: dataLength } = series[0].values;
      const minPercentAccepted = showPercents ? 8 : 5;
      let highestTotal;
      if (hasTotalBar) {
        const totals = [...totalizer.totals].slice(0, -1);
        highestTotal = Math.max(...totals);
      }
      let tooltips: Array<{
        label: string;
        value: number;
      }>;

      for (let indexData = 0; indexData < dataLength; indexData++) {
        tooltips = [];
        for (let indexSeries = 0; indexSeries < seriesLength; indexSeries++) {
          if (!this.data[indexSeries]) {
            this.data[indexSeries] = [];
            this.labelData[indexSeries] = [];
            this.tooltipData[indexSeries] = [];
          }
          const value = series[indexSeries].values[indexData];
          let percent = Math.abs(
            totalizer.totals[indexData] > 0
              ? (100 * value) / totalizer.totals[indexData]
              : 0,
          );
          percent = roundToTwo(percent);

          if (
            groupPercentThreshold > 0 &&
            indexSeries < seriesLength - 1 &&
            percent > 0 &&
            percent < groupPercentThreshold
          ) {
            this.labelData[indexSeries][indexData] = 0;
            this.tooltipData[indexSeries][indexData] = [{ value: 0 }];
            this.data[indexSeries][indexData] = 0;
            series[seriesLength - 1].values[indexData] += value;
            tooltips.push({ label: series[indexSeries].title, value });
          } else {
            this.labelData[indexSeries][indexData] = showPercents
              ? percent
              : value;
            this.tooltipData[indexSeries][indexData] =
              tooltips.length > 0
                ? tooltips
                : [{ label: series[indexSeries].title, value }];
            this.data[indexSeries][indexData] = percent;
          }
        }
        // fix percentages
        let totalOffset = 0;
        let countOverMinPercent = 0;
        for (
          let indexDataSet = 0;
          indexDataSet < seriesLength;
          indexDataSet++
        ) {
          if (this.data[indexDataSet][indexData] > 0) {
            if (this.data[indexDataSet][indexData] < minPercentAccepted) {
              totalOffset +=
                minPercentAccepted - this.data[indexDataSet][indexData];
            } else if (
              this.data[indexDataSet][indexData] > minPercentAccepted
            ) {
              countOverMinPercent += 1;
            }
          }
        }
        if (totalOffset > 0 && countOverMinPercent > 0) {
          const avgOffset = totalOffset / countOverMinPercent;
          for (
            let indexDataSet = 0;
            indexDataSet < seriesLength;
            indexDataSet++
          ) {
            if (this.data[indexDataSet][indexData] > 0) {
              if (this.data[indexDataSet][indexData] > minPercentAccepted) {
                this.data[indexDataSet][indexData] -= avgOffset;
              } else {
                this.data[indexDataSet][indexData] = minPercentAccepted;
              }
            }
          }
        }
        if (!showPercents) {
          let percentCoefficient = totalizer.totals[indexData];
          if (hasTotalBar && indexData === dataLength - 1 && highestTotal) {
            percentCoefficient = highestTotal;
          }

          for (
            let indexDataSet = 0;
            indexDataSet < seriesLength;
            indexDataSet++
          ) {
            this.data[indexDataSet][indexData] =
              (this.data[indexDataSet][indexData] / 100) * percentCoefficient;
          }
        }
      }
      if (groupPercentThreshold > 0) {
        this.filteredSeries = [];
        const filteredData: number[][] = [];
        const filteredLabelData: number[][] = [];
        const filteredTooltipData: TooltipData[][] = [];
        series.forEach((element, indexSeries) => {
          const sum = this.data[indexSeries].reduce((a, b) => a + b);
          if (sum > 0) {
            this.filteredSeries.push(element);
            filteredData.push(this.data[indexSeries]);
            filteredLabelData.push(this.labelData[indexSeries]);
            filteredTooltipData.push(this.tooltipData[indexSeries]);
          }
        });
        totalizer.topDatasetIndex = this.filteredSeries.length - 1;
        this.data = filteredData;
        this.labelData = filteredLabelData;
        this.tooltipData = filteredTooltipData;
      }

      for (
        let indexSeries = 0;
        indexSeries < this.filteredSeries.length;
        indexSeries++
      ) {
        this.filteredSeries[indexSeries].values = this.data[indexSeries];
        this.filteredSeries[indexSeries].labelValues = this.labelData[
          indexSeries
        ].map(l =>
          showPercents ? `${+l.toFixed(1)}%` : decimalFormattedShort(l),
        );
        this.filteredSeries[indexSeries].tooltipValues = this.tooltipData[
          indexSeries
        ];
      }
    }
  };
}

export default BarNormalizer;
