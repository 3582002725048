import { combineReducers } from 'redux';

import { reducer as regWatchAssessmentsReducer } from './regWatchAssessments';
import { reducer as regWatchUpdatesReducer } from './regWatchUpdates';

const reducer = combineReducers({
  updates: regWatchUpdatesReducer,
  assessments: regWatchAssessmentsReducer,
});

export default reducer;
