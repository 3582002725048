export interface IMediaContentItem {
  readonly videoId: string;
  readonly title: string;
  readonly summary: string;
}

class MediaContentItem {
  videoId?: string;
  title?: string;
  summary?: string;

  constructor(data?: Partial<IMediaContentItem>) {
    this.videoId = data?.videoId;
    this.title = data?.title;
    this.summary = data?.summary;
  }
}

export default MediaContentItem;
