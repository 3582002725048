import MarketingContentItem, { IMarketingContentItem } from 'contracts/models/service/MarketingContentItem';
import MediaContentItem, { IMediaContentItem } from 'contracts/models/service/MediaContentItem';
import { MarketingContentRequest } from 'contracts/types/request';
import { GetBlobResponse } from 'contracts/types/service';
import { getBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getMediaContentData(): Promise<MediaContentItem[]> {
  const response = await httpCustomerCore.get<MediaContentItem[]>(
    'recognition/media-content',
    {
      transformResponse: transformServiceResponse<
        IMediaContentItem,
        MediaContentItem
      >(MediaContentItem),
    },
  );
  return response.data;
}

export async function getMarketingCategories(): Promise<string[]> {
  const response = await httpCustomerCore.get<string[]>(
    'recognition/marketing-categories',
  );
  return response.data;
}

export async function getMarketingContentData(request: MarketingContentRequest): Promise<MarketingContentItem[]> {
  const response = await httpCustomerCore.post<MarketingContentItem[]>(
    'recognition/marketing-content',
    request,
    {
      transformResponse: transformServiceResponse<
        IMarketingContentItem,
        MarketingContentItem
      >(MarketingContentItem),
    },
  );
  return response.data;
}

export async function downloadMarketingContentFile(
  id?: number,
): Promise<GetBlobResponse> {
  const urlPath = `recognition/download-marketing-file/${id}`;
  const marketingContentFile = await getBlobFile(httpCustomerCore, urlPath);
  return marketingContentFile;
}
