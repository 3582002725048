import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { getBase64Strings } from 'exif-rotate-js';
import * as mime from 'mime';

import {
  GetBlobResponse,
  GetCsvResponse,
  UploadFileResponse,
} from 'contracts/types/service';
import getAxiosTransformers from 'core/helpers/axiosTransformers';

export async function getCsv(
  api: AxiosInstance,
  endpoint: string,
  arg: unknown,
): Promise<GetCsvResponse> {
  const transformResponse = getAxiosTransformers(
    (data, headers) =>
      ({
        content: data,
        filename: getFileName(headers ? `${headers['content-disposition']}` : 'file', '.csv'),
      } as GetCsvResponse),
  );
  const result = await api.post<GetCsvResponse>(endpoint, arg, {
    responseType: 'stream',
    transformResponse,
  });
  return result.data;
}

export async function getBlobFile(
  api: AxiosInstance,
  endpoint: string,
  defaultExtension?: string,
  args?: AxiosRequestHeaders,
): Promise<GetBlobResponse> {
  const transformResponse = getAxiosTransformers((data, headers) => ({
    blob: data,
    filename: headers && getFileName(headers ? `${headers['content-disposition']}` : 'file', defaultExtension),
  }));
  const result = await api.get<GetBlobResponse>(endpoint, {
    params: args,
    responseType: 'blob',
    transformResponse,
  });
  return result.data;
}

export async function getPostBlobFile(
  api: AxiosInstance,
  endpoint: string,
  defaultExtension?: string,
  args?: AxiosRequestHeaders,
): Promise<GetBlobResponse> {
  const transformResponse = getAxiosTransformers((data, headers) => ({
    blob: data,
    filename: headers && getFileName(headers ? `${headers['content-disposition']}` : 'file', defaultExtension),
  }));
  const result = await api.post<GetBlobResponse>(endpoint, {}, {
    params: args,
    responseType: 'blob',
    transformResponse,
  });
  return result.data;
}

export async function getBlobFileFromPublicUrl(
  url: string,
): Promise<GetBlobResponse> {
  const transformResponse = getAxiosTransformers((data, headers) => ({
    blob: data,
    filename: getFileNameFromPublicUrl(url, headers),
  }));
  const result = await axios.get<GetBlobResponse>(url, {
    responseType: 'blob',
    transformResponse,
  });
  return result.data;
}

const DataURIToBlob = (dataURI: string): Blob => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export async function writeBlobFile(
  api: AxiosInstance,
  endpoint: string,
  { filename, blob }: { filename: string; blob: Blob },
): Promise<UploadFileResponse> {
  const headers = {
    'content-type': 'multipart/form-data',
  };

  let transformedBlob = blob;

  if (blob.type === 'image/png' || blob.type === 'image/jpeg') {
    const data = await getBase64Strings([blob]);
    transformedBlob = DataURIToBlob(data[0]);
  }

  const formData = new FormData();
  formData.append('', transformedBlob, filename);

  const result = await api.post<UploadFileResponse>(endpoint, formData, {
    headers,
  });

  return result.data;
}

function getFileName(disposition: string, extension?: string): string {
  if (disposition && disposition.indexOf('filename=') >= 0) {
    return disposition
      .split('filename=')[1]
      .split(';')[0]
      .replace(/^"(.+(?="$))"$/, '$1');
  }

  const currentDate = new Date().getDate();
  const actualExtension = extension && extension !== '' ? `.${extension}` : '';
  return `unnamed-file-${currentDate}${actualExtension}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFileNameFromPublicUrl(url: string, headers: any): string {
  const contentType = headers['content-type'];
  const extension = mime.getExtension(contentType);
  const urlComponentArray = url.split('/');
  const fileName = `${
    urlComponentArray[urlComponentArray.length - 1]
  }.${extension}`;
  return fileName;
}
