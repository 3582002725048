import update from 'immutability-helper';

import {
  destroySession,
  setSessionWholesaleCart,
} from 'account/services/session';
import { NotificationType } from 'contracts/enums';
import { WholesalePurchaseResult } from 'contracts/models';
import { ActionDispatcher, PurchaseAction } from 'contracts/types/action';
import { WholesaleCartRequest } from 'contracts/types/request';
import {
  ApplicationState,
  PurchaseState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { initialStorageState } from 'wholesale/constants/initialStorageState';

import { purchase } from '../services/purchase';

// Actions
const ROOT_KEY = 'wholesale/purchase';
enum ActionKey {
  PURCHASE_SERVICE = 'wholesale/purchase/PURCHASE_SERVICE',
  RESET = 'wholesale/purchase/RESET',
}

// Initial state
const getInitialState: () => PurchaseState = () => {
  return {
    purchaseResult: {
      registrationToken: '',
      registrationUrl: ''
    },
  };
};

// Reducer
const reducerKeys = [ActionKey.PURCHASE_SERVICE] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  PurchaseState,
  PurchaseAction
> = {
  [ActionKey.PURCHASE_SERVICE]: (state, action) => {
    const { purchaseResult } = action;
    return update(state, { $merge: { purchaseResult } });
  },
};

export const reducer = getReducerBuilder<PurchaseState, PurchaseAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  PURCHASE_SERVICE: (
    purchaseResult: WholesalePurchaseResult,
  ): PurchaseAction => ({
    type: ActionKey.PURCHASE_SERVICE,
    purchaseResult,
  }),
  RESET: (): PurchaseAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const purchaseService = (cart: WholesaleCartRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.PURCHASE_SERVICE,
    async() => purchase(cart),
    result => {
      dispatch(actionMap.PURCHASE_SERVICE(result));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Your purchase was successful!',
        ),
      );

      setSessionWholesaleCart(initialStorageState);
      if (result.registrationUrl) {
        destroySession();
      }
    },
    () => {
      dispatch(actionMap.PURCHASE_SERVICE({ purchaseError: true }));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          'Your purchase could not be finalized. Please try again.',
        ),
      );
      setSessionWholesaleCart(initialStorageState);
    },
  );

const purchaseDuck = {
  thunks: { purchaseService },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default purchaseDuck;
