import { createGlobalStyle } from 'styled-components';

// Using 'Medium' as 'Regular' and 'Regular' as 'Light'
import MontserratBold from 'core/assets/fonts/Montserrat/Montserrat-Bold.woff';
import MontserratRegular from 'core/assets/fonts/Montserrat/Montserrat-Medium.woff';
import MontserratLight from 'core/assets/fonts/Montserrat/Montserrat-Regular.woff';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratBold}) format('woff2'),
    url(${MontserratBold}) format('woff'),
    url(${MontserratBold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratRegular}) format('woff2'),
    url(${MontserratRegular}) format('woff'),
    url(${MontserratRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLight}) format('woff2'),
    url(${MontserratLight}) format('woff'),
    url(${MontserratLight}) format('truetype');
    font-weight: 300;
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    background: ${theme.colors.grayLighter};
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    color: ${theme.colors.brandDefault};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px;
    font-weight: 400;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
