import React, { PureComponent } from 'react';

import { CieTradeReferenceNumberFilter as CieTradeReferenceNumberFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class CieTradeReferenceNumberFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (referenceNumbers: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      referenceNumbers,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled, referenceNumbers } = this.props;
    return (
      <DropdownFilter
        label={translate('core.referenceNumber')}
        values={referenceNumbers.map(referenceNumber => ({
          id: referenceNumber,
          label: referenceNumber,
        }))}
        selectedIds={currentFilter.referenceNumbers || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled || referenceNumbers.length === 0}
      />
    );
  }
}

interface ComponentProps {
  referenceNumbers: string[];
  currentFilter: CieTradeReferenceNumberFilterType;
  onFilterChanged: (filter: CieTradeReferenceNumberFilterType) => void;
  isDisabled?: boolean;
}

export default CieTradeReferenceNumberFilter;
