import { EquipmentType, ServiceAction } from 'contracts/enums';
import { TableData } from 'contracts/types/component';

export interface ISiteProfile {
  readonly isSiteOnHold: boolean;
  readonly custId: string;
  readonly siteId: string;
  readonly locationName: string;
  readonly serviceAddress: string;
  readonly services: SiteProfileService[];
  readonly displaySiteProfile: DisplaySiteProfile;
}

class SiteProfile {
  isSiteOnHold?: boolean;
  custId?: string;
  siteId?: string;
  locationName?: string;
  serviceAddress?: string;
  services?: SiteProfileService[];
  displaySiteProfile?: DisplaySiteProfile;

  constructor(data?: Partial<ISiteProfile>) {
    this.isSiteOnHold = data?.isSiteOnHold;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.locationName = data?.locationName;
    this.serviceAddress = data?.serviceAddress;
    this.services = data?.services;
    this.displaySiteProfile = data?.displaySiteProfile;
  }
}

interface DisplaySiteProfile {
  showServiceRates: boolean;
  showVendorName: boolean;
  showNewService: boolean;
}

export interface SiteProfileService extends TableData{
  serviceId: number;
  commodityServiceIds: number[];
  serviceProcessor: string;
  quantity: number;
  serviceDescription: string;
  serviceCode: string;
  containerId: string;
  containerType: EquipmentType;
  containerSize: string;
  wasteTypeId: string;
  wasteType: string;
  startDate?: string;
  nextServiceDate?: string;
  lastServiceDate?: string;
  frequencyId: string;
  frequency: string;
  daysOfWeek: SiteProfileServiceDays[];
  isTemp: boolean;
  isIncumbent: boolean;
  isOwned: boolean;
  isLandlordManaged: boolean;
  isActive: boolean;
  isDispatched: boolean;
  isMarketShared: boolean;
  vendorName: string;
  caseStatusSteps: SiteProfileCaseStatusStep[];
  serviceRates: SiteProfileServiceRates[];
  cancellationDate?: string;
  disposalMethodTypeDescription?: string;
  hasDeliveryConfirmation?: boolean;
  thumbnailImageUrl?: string;

  // Other
  fullness?: number;
  hasInsights?: boolean;
}

export interface SiteProfileServiceDays {
  caption: string;
  value: boolean;
}

export interface SiteProfileServiceAction {
  caption: string;
  type: ServiceAction;
  isCommonlyRequested: boolean;
  customTitle?: string;
  alreadyExistCannotRequest?: boolean;
}

export interface SiteProfileCaseStatusStep {
  date?: string;
  caption: string;
  isActive: boolean;
}

export interface SiteProfileServiceRates {
  caption: string;
  value?: number;
  vendor: string;
  unitOfMeasure: string;
}

export default SiteProfile;
