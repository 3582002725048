import { TableData } from 'contracts/types/component';

export interface IGhGCarbonEmissionsTable {
  readonly rows: GhGCarbonEmissionsTableItem[];
}

class GhGCarbonEmissionsTable {
  rows?: GhGCarbonEmissionsTableItem[];

  constructor(data?: Partial<IGhGCarbonEmissionsTable>) {
    this.rows = data?.rows;
  }
}

export interface GhGCarbonEmissionsTableItem extends TableData{
  serviceDate: string;
  siteName: string;
  siteAddress: string;
  material: string;
  materialManagementMethod: string;
  emissionsWeight: string;
}

export default GhGCarbonEmissionsTable;
