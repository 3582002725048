import update from 'immutability-helper';

import {
  FilterApprover,
  FilterRequester,
  FilterRequestType,
  RequestServiceResult,
  ServiceRequestApproval,
} from 'contracts/models';
import {
  ActionDispatcher,
  ServiceRequestApprovalAction,
} from 'contracts/types/action';
import { LocationFilter } from 'contracts/types/component';
import {
  ApprovalRequestsRequest,
  ConfirmOrderRequestArg,
  ServiceApproveRequestArg,
} from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceRequestApprovalState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { approveOrder } from 'services/services/serviceUpdateDetails';

import {
  getApproverCustomers,
  getFilterRequestTypes,
  getRequesterCustomers,
  getServiceRequests,
  sendApproveServiceRequests,
  sendDenyServiceRequests,
} from '../services/serviceApproval';
import { approveServiceRequest } from '../services/serviceRequestEventsServices';

// Actions Keys
const ROOT_KEY = 'services/serviceRequestApproval';

enum ActionKey {
  LOAD_SERVICE_REQUESTS = 'services/serviceRequestApproval/LOAD_SERVICE_REQUESTS',
  APPROVE_SERVICE_REQUESTS = 'services/serviceRequestApproval/APPROVE_SERVICE_REQUESTS',
  DENY_SERVICE_REQUESTS = 'services/serviceRequestApproval/DENY_SERVICE_REQUESTS',
  APPROVE_ONE_SERVICE = 'services/serviceRequestApproval/APPROVE_ONE_SERVICE',
  DENY_ONE_SERVICE = 'services/serviceRequestApproval/DENY_ONE_SERVICE',
  CLOSE_SERVICE_CONFIRMATION = 'services/serviceRequestApproval/CLOSE_SERVICE_CONFIRMATION',
  LOAD_APPROVER_FILTERS = 'services/serviceRequestApproval/LOAD_APPROVER_FILTERS',
  LOAD_REQUESTER_FILTERS = 'services/serviceRequestApproval/LOAD_REQUESTER_FILTERS',
  LOAD_REQUEST_TYPES_FILTERS = 'services/serviceRequestApproval/LOAD_REQUEST_TYPES_FILTERS',
  RESET = 'services/serviceRequestApproval/RESET',
}

// Initial state
const getInitialState: () => ServiceRequestApprovalState = () => {
  return {
    serviceRequests: [],
    approveRequestResults: [],
    requestTypeFilters: [],
    requesterFilters: [],
    approverFilters: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SERVICE_REQUESTS,
  ActionKey.APPROVE_SERVICE_REQUESTS,
  ActionKey.DENY_SERVICE_REQUESTS,
  ActionKey.APPROVE_ONE_SERVICE,
  ActionKey.DENY_ONE_SERVICE,
  ActionKey.CLOSE_SERVICE_CONFIRMATION,
  ActionKey.LOAD_APPROVER_FILTERS,
  ActionKey.LOAD_REQUESTER_FILTERS,
  ActionKey.LOAD_REQUEST_TYPES_FILTERS,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceRequestApprovalState,
  ServiceRequestApprovalAction
> = {
  [ActionKey.LOAD_SERVICE_REQUESTS]: (state, action) => {
    const { serviceRequests } = action;
    return update(state, { $merge: { serviceRequests } });
  },
  [ActionKey.APPROVE_SERVICE_REQUESTS]: state => {
    return state;
  },
  [ActionKey.DENY_SERVICE_REQUESTS]: state => {
    return state;
  },
  [ActionKey.APPROVE_ONE_SERVICE]: (state, action) => {
    const { approveRequestResults } = action;
    return update(state, { $merge: { approveRequestResults } });
  },
  [ActionKey.DENY_ONE_SERVICE]: state => {
    return state;
  },
  [ActionKey.CLOSE_SERVICE_CONFIRMATION]: (state, action) => {
    const resultsToRemove = action.approveRequestResults;
    if (resultsToRemove && resultsToRemove.length === 1) {
      const index = (state.approveRequestResults || []).indexOf(
        resultsToRemove[0],
      );
      if (index < 0) {
        return state;
      }
      return update(state, {
        approveRequestResults: { $splice: [[index, 1]] },
      });
    }
    return state;
  },
  [ActionKey.LOAD_APPROVER_FILTERS]: (state, action) => {
    const { approverFilters } = action;
    return update(state, { $merge: { approverFilters } });
  },
  [ActionKey.LOAD_REQUESTER_FILTERS]: (state, action) => {
    const { requesterFilters } = action;
    return update(state, { $merge: { requesterFilters } });
  },
  [ActionKey.LOAD_REQUEST_TYPES_FILTERS]: (state, action) => {
    const { requestTypeFilters } = action;
    return update(state, { $merge: { requestTypeFilters } });
  },
};

export const reducer = getReducerBuilder<
  ServiceRequestApprovalState,
  ServiceRequestApprovalAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SERVICE_REQUESTS: (
    serviceRequests?: ServiceRequestApproval[],
  ): ServiceRequestApprovalAction => ({
    type: ActionKey.LOAD_SERVICE_REQUESTS,
    serviceRequests,
  }),
  APPROVE_SERVICE_REQUESTS: () => ({
    type: ActionKey.APPROVE_SERVICE_REQUESTS,
  }),
  DENY_SERVICE_REQUESTS: () => ({
    type: ActionKey.DENY_SERVICE_REQUESTS,
  }),
  APPROVE_ONE_SERVICE: (approveRequestResults?: RequestServiceResult[]) => ({
    type: ActionKey.APPROVE_ONE_SERVICE,
    approveRequestResults,
  }),
  // Run Deny through the modal if confirmation needed later
  DENY_ONE_SERVICE: () => ({
    type: ActionKey.DENY_ONE_SERVICE,
  }),
  CLOSE_SERVICE_CONFIRMATION: (
    approveRequestResult: RequestServiceResult,
  ): ServiceRequestApprovalAction => ({
    type: ActionKey.CLOSE_SERVICE_CONFIRMATION,
    approveRequestResults: [approveRequestResult],
  }),
  LOAD_APPROVER_FILTERS: (
    approverFilters?: FilterApprover[],
  ): ServiceRequestApprovalAction => ({
    type: ActionKey.LOAD_APPROVER_FILTERS,
    approverFilters,
  }),
  LOAD_REQUESTER_FILTERS: (
    requesterFilters?: FilterRequester[],
  ): ServiceRequestApprovalAction => ({
    type: ActionKey.LOAD_REQUESTER_FILTERS,
    requesterFilters,
  }),
  LOAD_REQUEST_TYPES_FILTERS: (
    requestTypeFilters?: FilterRequestType[],
  ): ServiceRequestApprovalAction => ({
    type: ActionKey.LOAD_REQUEST_TYPES_FILTERS,
    requestTypeFilters,
  }),
  RESET: (): ServiceRequestApprovalAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadServiceRequests = (arg: ApprovalRequestsRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_REQUESTS,
    async() => getServiceRequests(arg),
    result => {
      dispatch(actionMap.LOAD_SERVICE_REQUESTS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_REQUESTS());
    },
    true,
  );

const approveServiceRequests = (serviceIds: number[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.APPROVE_SERVICE_REQUESTS,
    async() => sendApproveServiceRequests(serviceIds),
    () => {
      dispatch(actionMap.APPROVE_SERVICE_REQUESTS());
    },
    () => {
      dispatch(actionMap.APPROVE_SERVICE_REQUESTS());
    },
    true,
  );

const denyServiceRequests = (serviceIds: number[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.DENY_SERVICE_REQUESTS,
    async() => sendDenyServiceRequests(serviceIds),
    () => {
      dispatch(actionMap.DENY_SERVICE_REQUESTS());
    },
    () => {
      dispatch(actionMap.DENY_SERVICE_REQUESTS());
    },
    true,
  );

const approveOneService = (params: ServiceApproveRequestArg) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.APPROVE_ONE_SERVICE,
    async() => approveServiceRequest(params),
    result => {
      dispatch(actionMap.APPROVE_ONE_SERVICE(result));
    },
    () => {
      dispatch(actionMap.APPROVE_ONE_SERVICE());
    },
    true,
  );

const approveChangeOfService = (params: ConfirmOrderRequestArg) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.APPROVE_ONE_SERVICE,
    async() => approveOrder(params),
    result => {
      dispatch(actionMap.APPROVE_ONE_SERVICE(result));
    },
    () => {
      dispatch(actionMap.APPROVE_ONE_SERVICE());
    },
    true,
  );

const loadApproverFilters = (
  custId: string,
  locationFilters: LocationFilter,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_APPROVER_FILTERS,
    async() => getApproverCustomers(custId, locationFilters),
    result => {
      dispatch(actionMap.LOAD_APPROVER_FILTERS(result));
    },
    () => {
      dispatch(actionMap.LOAD_APPROVER_FILTERS());
    },
    true,
  );

const loadRequesterFilters = (
  custId: string,
  locationFilters: LocationFilter,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_REQUESTER_FILTERS,
    async() => getRequesterCustomers(custId, locationFilters),
    result => {
      dispatch(actionMap.LOAD_REQUESTER_FILTERS(result));
    },
    () => {
      dispatch(actionMap.LOAD_REQUESTER_FILTERS());
    },
    true,
  );

const loadRequestTypesFilters = (
  custId: string,
  locationFilters: LocationFilter,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_REQUEST_TYPES_FILTERS,
    async() => getFilterRequestTypes(custId, locationFilters),
    result => {
      dispatch(actionMap.LOAD_REQUEST_TYPES_FILTERS(result));
    },
    () => {
      dispatch(actionMap.LOAD_REQUEST_TYPES_FILTERS());
    },
    true,
  );

const serviceApprovalDuck = {
  thunks: {
    loadServiceRequests,
    approveServiceRequests,
    denyServiceRequests,
    approveOneService,
    approveChangeOfService,
    loadApproverFilters,
    loadRequesterFilters,
    loadRequestTypesFilters,
  },
  actions: {
    closeServiceConfirmation: actionMap.CLOSE_SERVICE_CONFIRMATION,
    reset: actionMap.RESET,
  },
  actionKeys: ActionKey,
};

export default serviceApprovalDuck;
