import update from 'immutability-helper';

import { SavingsData } from 'contracts/models';
import {
  ActionDispatcher,
  FinancialSavingsAction,
} from 'contracts/types/action';
import { LocationFilter } from 'contracts/types/component';
import {
  ApplicationState,
  FinancialSavingsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getSavings } from 'financial/services/savingsServices';

// Actions Keys
const ROOT_KEY = 'financial/savings';
enum ActionKey {
  LOAD_SAVINGS_DATA = 'financial/savings/LOAD_SAVINGS_DATA',
  RESET = 'financial/savings/RESET',
}

// Initial State
const getInitialState: () => FinancialSavingsState = () => {
  return {
    savingsData: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_SAVINGS_DATA] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FinancialSavingsState,
  FinancialSavingsAction
> = {
  [ActionKey.LOAD_SAVINGS_DATA]: (state, action) => {
    const { savingsData } = action;
    return update(state, { $merge: { savingsData } });
  },
};

export const reducer = getReducerBuilder(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SAVINGS_DATA: (savingsData?: SavingsData): FinancialSavingsAction => ({
    type: ActionKey.LOAD_SAVINGS_DATA,
    savingsData,
  }),
  RESET: (): FinancialSavingsAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
export const loadSavingsData = (
  fromDate: string,
  toDate: string,
  locationFilter: LocationFilter,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SAVINGS_DATA,
    async() => getSavings({ fromDate, toDate, locationFilter }),
    result => {
      dispatch(actionMap.LOAD_SAVINGS_DATA(result));
    },
    () => actionMap.LOAD_SAVINGS_DATA(),
  );

const savingsDuck = {
  thunks: { loadSavingsData },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default savingsDuck;
