import update from 'immutability-helper';

import {
  RegWatchAssessmentMetrics,
} from 'contracts/models';
import {
  ActionDispatcher,
  RegWatchAssessmentsAction,
} from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  RegWatchAssessmentsState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as regWatchAssessmentsService from '../services/regWatchAssessmentsService';

// Actions Keys
const ROOT_KEY = 'regWatch/assessments';
enum ActionKey {
  ASSESSMENT_METRICS = 'regWatch/assessments/ASSESSMENT_METRICS',
  RESET = 'regWatch/assessments/RESET',
}

// Initial state
const getInitialState: () => RegWatchAssessmentsState = () => {
  return {
    assessmentMetrics: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.ASSESSMENT_METRICS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RegWatchAssessmentsState,
  RegWatchAssessmentsAction
> = {
  [ActionKey.ASSESSMENT_METRICS]: (state, action) => {
    const { assessmentMetrics } = action;
    return update(state, { $merge: { assessmentMetrics } });
  },
};

export const reducer = getReducerBuilder<
  RegWatchAssessmentsState,
  RegWatchAssessmentsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  ASSESSMENT_METRICS: (
    assessmentMetrics?: RegWatchAssessmentMetrics,
  ): RegWatchAssessmentsAction => ({
    type: ActionKey.ASSESSMENT_METRICS,
    assessmentMetrics,
  }),
  RESET: (): RegWatchAssessmentsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadRegWatchAssessmentMetrics = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ASSESSMENT_METRICS,
    () => regWatchAssessmentsService.getRegWatchAssessmentMetrics(),
    result => dispatch(actionMap.ASSESSMENT_METRICS(result)),
    () => dispatch(actionMap.ASSESSMENT_METRICS()),
  );

const regWatchAssessmentsDuck = {
  thunks: {
    loadRegWatchAssessmentMetrics,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default regWatchAssessmentsDuck;
