enum ServiceAction {
  EXTRA_PICKUP = 'IX',
  MISSED_PICKUP = 'IM',
  REQUEST_REPAIR = 'IR',
  EMPTY_AND_RETURN = 'O',
  CONTAINER_WASH = 'CW',
  DUMPSTER_RELOCATION = 'DL',
  SERVICE_ISSUE = 'IS',
  LOCKBAR_INSTALLATION = 'LI',
  LOCKBAR_REPLACEMENT = 'LP',
  LOCKBAR_REPAIR = 'LR',
  FINAL_PICKUP = 'E',
  UPDATE_SERVICE = 'CG',
  ON_CALL_PICKUP = 'O',
  EQUIPMENT_ACCESSORIES = 'OA',
}
export default ServiceAction;
