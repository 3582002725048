export interface ICustomerUserSiteLocation {
  readonly userSiteId: number;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly name: string;
  readonly custId: string;
  readonly siteId: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly locationName: string;
  readonly customerId: string;
  readonly siteAddress: string;
  readonly startDate: string;
  readonly siteCount: number;
  readonly siteCode: string;
}

class CustomerUserSiteLocation {
  userSiteId?: number;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  name?: string;
  custId?: string;
  siteId?: string;
  latitude?: number;
  longitude?: number;
  locationName?: string;
  customerId?: string;
  siteAddress?: string;
  startDate?: Date;
  siteCount?: number;
  siteCode?: string;

  constructor(data?: Partial<ICustomerUserSiteLocation>) {
    this.userSiteId = data?.userSiteId;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.name = data?.name;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
    this.locationName = data?.locationName;
    this.customerId = data?.customerId;
    this.siteAddress = data?.siteAddress;
    if (data?.startDate) {
      this.startDate = new Date(data.startDate);
    }
    this.siteCount = data?.siteCount;
    this.siteCode = data?.siteCode;
  }
}

export default CustomerUserSiteLocation;
