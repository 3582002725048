import React, { PureComponent } from 'react';

import { LocationFilter } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';
import { DropdownFilterValue } from '../DropdownFilter/DropdownFilterPopup';

class LocationStateFilter extends PureComponent<ComponentProps> {
  render(): React.ReactNode {
    const {
      isLoading,
      isDisabled,
      states,
      currentFilter,
      onFilterChanged,
    } = this.props;
    return (
      <DropdownFilter
        label={translate('states')}
        values={states}
        selectedIds={currentFilter.states || []}
        onFilterChanged={onFilterChanged}
        isLoading={isLoading}
        isDisabled={isDisabled}
        sortValues={false}
      />
    );
  }
}

interface ComponentProps {
  isLoading: boolean;
  isDisabled?: boolean;
  states: Array<DropdownFilterValue<string>>;
  currentFilter: LocationFilter;
  onFilterChanged: (selectedItems: string[]) => void;
}

export default LocationStateFilter;
