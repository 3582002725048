import CustomerSite from './CustomerSite';

export interface ICustomerUserSite {
  readonly userSiteId: number;
  readonly billingEmail: string;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly name: string;
  readonly custId: string;
  readonly siteId: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly locationName: string;
  readonly zuoraAccountId: string;
  readonly zuoraInvoiceOwnerId: string;
  readonly zuoraAccountNumber: string;
  readonly customerId: string;
  readonly siteAddress: string;
  readonly startDate: string;
  readonly tagId: number;
  readonly tagName: string;
  readonly equipmentCount: EquipmentCount[];
  readonly siteCount: number;
  readonly siteCode: string;
}

class CustomerUserSite {
  userSiteId?: number;
  billingEmail?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  name?: string;
  custId?: string;
  siteId?: string;
  latitude?: number;
  longitude?: number;
  locationName?: string;
  zuoraAccountId?: string;
  zuoraInvoiceOwnerId?: string;
  zuoraAccountNumber?: string;
  customerId?: string;
  siteAddress?: string;
  startDate?: Date;
  tagId?: number;
  tagName?: string;
  equipmentCount?: EquipmentCount[];
  siteCount?: number;
  siteCode?: string;

  constructor(data?: Partial<ICustomerUserSite>) {
    this.userSiteId = data?.userSiteId;
    this.billingEmail = data?.billingEmail;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.name = data?.name;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
    this.locationName = data?.locationName;
    this.zuoraAccountId = data?.zuoraAccountId;
    this.zuoraInvoiceOwnerId = data?.zuoraInvoiceOwnerId;
    this.zuoraAccountNumber = data?.zuoraAccountNumber;
    this.customerId = data?.customerId;
    this.siteAddress = data?.siteAddress;
    if (data?.startDate) {
      this.startDate = new Date(data.startDate);
    }
    this.tagId = data?.tagId;
    this.tagName = data?.tagName;
    this.equipmentCount = data?.equipmentCount;
    this.siteCount = data?.siteCount;
    this.siteCode = data?.siteCode;
  }
}

export interface EquipmentCount {
  equipmentName: string;
  count: number;
}

export interface ICustomerUserSiteAssigned extends ICustomerUserSite {
  readonly isAssigned: boolean;
}
export class CustomerUserSiteAssigned extends CustomerUserSite {
  isAssigned: boolean;

  constructor(data?: Partial<ICustomerUserSiteAssigned | CustomerSite>) {
    super(data);
    this.isAssigned = (data as ICustomerUserSiteAssigned).isAssigned || false;
    if (data instanceof CustomerSite) {
      this.siteId = data.siteID;
      this.custId = data.custID;
    }
  }
}

export default CustomerUserSite;
