import { TableData } from 'contracts/types/component';

export interface ICustomerUser {
  readonly emailAddress: string;
  readonly role: string;
  readonly roleDescription: string;
  readonly userId: number;
  readonly custId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly isRegistered: boolean;
  readonly ssoEnabled: boolean;
  readonly allCustomerLocationsAccess: boolean;
  readonly allOwnerLocationsAccess: boolean;
}

class CustomerUser implements TableData {
  emailAddress?: string;
  role?: string;
  roleDescription?: string;
  userId?: number;
  custId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  isRegistered?: boolean;
  ssoEnabled?: boolean;
  allCustomerLocationsAccess?: boolean;
  allOwnerLocationsAccess?: boolean;
  isHidden?: boolean;

  constructor(data?: Partial<ICustomerUser>) {
    this.emailAddress = data?.emailAddress;
    this.role = data?.role;
    this.roleDescription = data?.roleDescription;
    this.userId = data?.userId;
    this.custId = data?.custId;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.phoneNumber = data?.phoneNumber;
    this.isRegistered = data?.isRegistered;
    this.ssoEnabled = data?.ssoEnabled;
    this.allCustomerLocationsAccess = data?.allCustomerLocationsAccess;
    this.allOwnerLocationsAccess = data?.allOwnerLocationsAccess;
  }
}

export default CustomerUser;
