export interface ISaleAddress {
  readonly line1: string;
  readonly city: string;
  readonly country: string;
  readonly state: string;
  readonly street: string;
  readonly streetNumber: string;
  readonly zip: string;
  readonly latitude?: number;
  readonly longitude?: number;
}

class SaleAddress {
  line1?: string;
  city?: string;
  country?: string;
  state?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  latitude?: number;
  longitude?: number;

  constructor(data?: Partial<ISaleAddress>) {
    this.line1 = data?.line1;
    this.city = data?.city;
    this.country = data?.country;
    this.state = data?.state;
    this.street = data?.street;
    this.streetNumber = data?.streetNumber;
    this.zip = data?.zip;
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
  }
}

export default SaleAddress;
