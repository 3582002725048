import React from 'react';

import { YoutubeEmbedContainer, YoutubeEmbedFrame } from './styled/YoutubeEmbed';

const YoutubeEmbed: React.FC<ComponentProps> = ({
  embedId,
  title
}) => (
  <YoutubeEmbedContainer>
    <YoutubeEmbedFrame
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="picture-in-picture"
      allowFullScreen
      title={title}
    />
  </YoutubeEmbedContainer>
);

interface ComponentProps {
  embedId: string;
  title: string;
}

export default YoutubeEmbed;
