export interface IOnDemandPickUpOptions {
  readonly showTotalAmount: boolean;
  readonly totalAmountRequired: boolean;
  readonly totalAmountEditable: boolean;
  readonly totalAmountMinValue: number;
  readonly showWorkOrderNumber: boolean;
  readonly workOrderNumberRequired: boolean;
  readonly workOrderNumberEditable: boolean;
  readonly showUOM: boolean;
  readonly uomRequired: boolean;
  readonly uomEditable: boolean;
  readonly showServiceDate: boolean;
  readonly serviceDateRequired: boolean;
  readonly serviceDateEditable: boolean;
  readonly showServiceCode: boolean;
  readonly serviceCodeRequired: boolean;
  readonly serviceCodeEditable: boolean;
  readonly showServiceDescription: boolean;
  readonly serviceDescriptionRequired: boolean;
  readonly serviceDescriptionEditable: boolean;
  readonly showQuantity: boolean;
  readonly quantityRequired: boolean;
  readonly quantityMinValue: number;
  readonly quantityMaxValue: number;
  readonly quantityEditable: boolean;
  readonly showMaterial: boolean;
  readonly materialRequired: boolean;
  readonly materialEditable: boolean;
  readonly showSite: boolean;
  readonly siteEditable: boolean;
  readonly siteRequired: boolean;
  readonly showDisposalMethod: boolean;
  readonly disposalMethodRequired: boolean;
  readonly disposalMethodEditable: boolean;
}

class OnDemandPickUpOptions {
  showTotalAmount?: boolean;
  totalAmountRequired?: boolean;
  totalAmountEditable?: boolean;
  totalAmountMinValue?: number;
  showWorkOrderNumber?: boolean;
  workOrderNumberRequired?: boolean;
  workOrderNumberEditable?: boolean;
  showServiceDate?: boolean;
  serviceDateRequired?: boolean;
  serviceDateEditable?: boolean;
  showUOM?: boolean;
  uomRequired?: boolean;
  uomEditable?: boolean;
  showServiceCode?: boolean;
  serviceCodeRequired?: boolean;
  serviceCodeEditable?: boolean;
  showServiceDescription?: boolean;
  serviceDescriptionRequired?: boolean;
  serviceDescriptionEditable?: boolean;
  showQuantity?: boolean;
  quantityRequired?: boolean;
  quantityMinValue?: number;
  quantityMaxValue?: number;
  quantityEditable?: boolean;
  showMaterial?: boolean;
  materialRequired?: boolean;
  materialEditable?: boolean;
  showSite?: boolean;
  siteEditable?: boolean;
  siteRequired?: boolean;
  showDisposalMethod?: boolean;
  disposalMethodRequired?: boolean;
  disposalMethodEditable?: boolean;

  constructor(data?: Partial<IOnDemandPickUpOptions>) {
    this.showTotalAmount = data?.showTotalAmount;
    this.totalAmountRequired = data?.totalAmountRequired;
    this.totalAmountEditable = data?.totalAmountEditable;
    this.totalAmountMinValue = data?.totalAmountMinValue;
    this.showWorkOrderNumber = data?.showWorkOrderNumber;
    this.workOrderNumberRequired = data?.workOrderNumberRequired;
    this.workOrderNumberEditable = data?.workOrderNumberEditable;
    this.showUOM = data?.showUOM;
    this.uomRequired = data?.uomRequired;
    this.uomEditable = data?.uomEditable;
    this.showServiceDate = data?.showServiceDate;
    this.serviceDateRequired = data?.serviceDateRequired;
    this.serviceDateEditable = data?.serviceDateEditable;
    this.showServiceCode = data?.showServiceCode;
    this.serviceCodeRequired = data?.serviceCodeRequired;
    this.serviceCodeEditable = data?.serviceCodeEditable;
    this.showServiceDescription = data?.showServiceDescription;
    this.serviceDescriptionRequired = data?.serviceDescriptionRequired;
    this.serviceDescriptionEditable = data?.serviceDescriptionEditable;
    this.showQuantity = data?.showQuantity;
    this.quantityRequired = data?.quantityRequired;
    this.quantityMinValue = data?.quantityMinValue;
    this.quantityMaxValue = data?.quantityMaxValue;
    this.quantityEditable = data?.quantityEditable;
    this.showMaterial = data?.showMaterial;
    this.materialRequired = data?.materialRequired;
    this.materialEditable = data?.materialEditable;
    this.showSite = data?.showSite;
    this.siteEditable = data?.siteEditable;
    this.siteRequired = data?.siteRequired;
    this.showDisposalMethod = data?.showDisposalMethod;
    this.disposalMethodRequired = data?.disposalMethodRequired;
    this.disposalMethodEditable = data?.disposalMethodEditable;
  }
}

export default OnDemandPickUpOptions;
