import { combineReducers } from 'redux';

import { reducer as addEditPaymentMethodReducer } from './addEditPaymentMethod';
import { reducer as billingReducer } from './billing';
import { reducer as makePaymentReducer } from './makePayment';
import { reducer as paymentMethodDetailReducer } from './paymentMethodDetails';
import { reducer as paymentSetupReducer } from './paymentSetup';

const reducer = combineReducers({
  billing: billingReducer,
  paymentMethodDetails: paymentMethodDetailReducer,
  paymentSetup: paymentSetupReducer,
  addEditPaymentMethod: addEditPaymentMethodReducer,
  makePayment: makePaymentReducer,
});

export default reducer;
