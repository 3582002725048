import React, { Component, PropsWithChildren } from 'react';

import { logMessage, logError } from 'core/logging';

class Script extends Component<PropsWithChildren<ComponentProps>, OwnStateProps> {
  static scriptObservers: {
    [key: string]: { [key: string]: ComponentProps };
  } = {};
  static loadedScripts: { [key: string]: boolean } = {};
  static erroredScripts: { [key: string]: boolean } = {};
  static idCount = 0;

  scriptLoaderId: string;

  state: OwnStateProps = {
    isLoaded: false,
  };
  constructor(props: ComponentProps) {
    super(props);
    this.scriptLoaderId = `id${Script.idCount++}`;
  }

  componentDidMount(): void {
    const { name, onError, onLoad } = this.props;

    if (Script.loadedScripts[name]) {
      if (onLoad) {
        onLoad();
      }
      this.setState({ isLoaded: true });
      return;
    }

    if (Script.erroredScripts[name]) {
      if (onError) {
        onError();
      }
      this.setState({ isLoaded: true });
      return;
    }

    if (Script.scriptObservers[name]) {
      Script.scriptObservers[name][this.scriptLoaderId] = this.props;
      return;
    }

    Script.scriptObservers[name] = {
      [this.scriptLoaderId]: this.props,
    };

    this.createScript();
  }

  componentWillUnmount(): void {
    const { name } = this.props;
    const observers = Script.scriptObservers[name];
    if (observers) {
      delete observers[this.scriptLoaderId];
    }
  }

  onScriptLoaded = (error?: boolean): void => {
    const { name } = this.props;
    const observers = Script.scriptObservers[name];
    Object.keys(observers).forEach(key => {
      if (error) {
        const observer = observers[key];
        Script.erroredScripts[name] = true;
        logError(`Script ${name} failed to load!`);
        if (observer.onError) {
          observer.onError();
        }
      } else {
        const observer = observers[key];
        Script.loadedScripts[name] = true;
        logMessage(`Script ${name} loaded successfully`);
        if (observer.onLoad) {
          observer.onLoad();
        }
      }
    });
    this.setState({ isLoaded: true });
    delete Script.scriptObservers[name][this.scriptLoaderId];
  };

  createScript(): void {
    const { isUrl, content, onCreate, attributes, appendToHead } = this.props;
    const script = document.createElement('script');

    if (onCreate) {
      onCreate();
    }

    if (attributes) {
      Object.keys(attributes).forEach(prop =>
        script.setAttribute(prop, attributes[prop]),
      );
    }
    if (isUrl) {
      script.src = content;
    } else {
      script.text = content;
    }
    if (!script.hasAttribute('async')) {
      script.async = true;
    }

    if (appendToHead) {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
    if (isUrl) {
      script.onload = () => {
        this.onScriptLoaded();
      };
      script.onerror = () => {
        this.onScriptLoaded(true);
      };
    } else {
      this.onScriptLoaded();
    }
  }

  render(): React.ReactNode {
    if (this.props.children) {
      const { isLoaded } = this.state;
      if (isLoaded) {
        return <>{this.props.children}</>;
      }
    }
    return null;
  }
}

interface ComponentProps {
  name: string;
  content: string;
  isUrl?: boolean;
  attributes?: { [key: string]: string };
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  appendToHead?: boolean;
}

interface OwnStateProps {
  isLoaded: boolean;
}

export default Script;
