import moment from 'moment';
import { BeforeAfterModifier } from 'react-day-picker';

import { getRegWatchSessionDateSelection, getSessionDateSelection } from 'account/services/session';
import { DatePickerOptionType, DatePickerRangeType } from 'contracts/enums';
import { DatePickerOption, DateRange } from 'contracts/types/component';
import translate from 'core/helpers/translate';

const currentDate = (): moment.Moment => moment().startOf('day');

const datePickerOptions: Record<DatePickerOptionType, () => DatePickerOption> = {
  [DatePickerOptionType.today]: () => ({
    label: translate('dateRangePicker.today'),
    shortLabel: translate('dateRangePicker.today'),
    value: { from: currentDate().toDate(), to: currentDate().toDate() },
  }),
  [DatePickerOptionType.last30daysFuture30days]: () => ({
    label: translate('dateRangePicker.last30daysFuture30days'),
    shortLabel: 'L30-F30',
    value: {
      from: currentDate().subtract(30, 'days').toDate(),
      to: currentDate().add(30, 'days').toDate(),
    },
  }),
  [DatePickerOptionType.last7days]: () => ({
    label: translate('dateRangePicker.last7days'),
    shortLabel: translate('dateRangePicker.7days'),
    value: {
      from: currentDate().subtract(7, 'days').toDate(),
      to: currentDate().toDate(),
    },
  }),
  [DatePickerOptionType.last30days]: () => ({
    label: translate('dateRangePicker.last30days'),
    shortLabel: translate('dateRangePicker.30days'),
    value: {
      from: currentDate().subtract(30, 'days').toDate(),
      to: currentDate().toDate(),
    },
  }),
  [DatePickerOptionType.last90days]: () => ({
    label: translate('dateRangePicker.last90days'),
    shortLabel: translate('dateRangePicker.90days'),
    value: {
      from: currentDate().subtract(90, 'days').toDate(),
      to: currentDate().toDate(),
    },
  }),
  [DatePickerOptionType.last6Months]: () => ({
    label: translate('dateRangePicker.last6Months'),
    shortLabel: translate('dateRangePicker.6Months'),
    value: {
      from: currentDate().subtract(180, 'days').toDate(),
      to: currentDate().toDate(),
    }
  }),
  [DatePickerOptionType.yearToDate]: () => ({
    label: translate('dateRangePicker.yearToDate'),
    shortLabel: translate('dateRangePicker.ytd'),
    value: {
      from: currentDate().startOf('year').toDate(),
      to: currentDate().toDate(),
    },
  }),
  [DatePickerOptionType.lastYear]: () => ({
    label: translate('dateRangePicker.lastYear'),
    shortLabel: translate('dateRangePicker.lastYear'),
    value: {
      from: currentDate().startOf('year').subtract(1, 'year').toDate(),
      to: currentDate().endOf('year').subtract(1, 'year').toDate(),
    },
  }),
  [DatePickerOptionType.next90days]: () => ({
    label: translate('dateRangePicker.next90days'),
    shortLabel: translate('dateRangePicker.90days'),
    value: {
      from: currentDate().add(1, 'd').toDate(),
      to: currentDate().add(91, 'days').toDate(),
    },
  }),
  [DatePickerOptionType.thisYear]: () => ({
    label: translate('dateRangePicker.thisYear'),
    shortLabel: translate('dateRangePicker.thisYear'),
    value: {
      from: currentDate().add(1, 'd').toDate(),
      to: currentDate().endOf('year').toDate(),
    },
  }),
  [DatePickerOptionType.oneYear]: () => ({
    label: translate('dateRangePicker.oneYear'),
    shortLabel: translate('dateRangePicker.oneYear'),
    value: {
      from: currentDate().subtract(365, 'days').toDate(),
      to: currentDate().toDate(),
    },
  }),
  [DatePickerOptionType.nextYear]: () => ({
    label: translate('dateRangePicker.oneYear'),
    shortLabel: translate('dateRangePicker.oneYear'),
    value: {
      from: currentDate().add(1, 'd').toDate(),
      to: currentDate().add(366, 'days').toDate(),
    },
  }),
};

export const getDateRangePickerOptions = (type?: DatePickerRangeType): DatePickerOption[] => {
  switch (type) {
    case DatePickerRangeType.defaultWithLast30Future30:
      return [
        datePickerOptions[DatePickerOptionType.today](),
        datePickerOptions[DatePickerOptionType.last30days](),
        datePickerOptions[DatePickerOptionType.last90days](),
        datePickerOptions[DatePickerOptionType.yearToDate](),
        datePickerOptions[DatePickerOptionType.oneYear](),
        datePickerOptions[DatePickerOptionType.lastYear](),
        datePickerOptions[DatePickerOptionType.last30daysFuture30days]()
      ];
    case DatePickerRangeType.notifications:
      return [
        datePickerOptions[DatePickerOptionType.today](),
        datePickerOptions[DatePickerOptionType.last7days](),
        datePickerOptions[DatePickerOptionType.last30days]()
      ];
    case DatePickerRangeType.regWatch:
      return [
        datePickerOptions[DatePickerOptionType.today](),
        datePickerOptions[DatePickerOptionType.last7days](),
        datePickerOptions[DatePickerOptionType.last30days](),
        datePickerOptions[DatePickerOptionType.last90days](),
        datePickerOptions[DatePickerOptionType.yearToDate](),
        datePickerOptions[DatePickerOptionType.oneYear](),
      ];
    case DatePickerRangeType.forecast:
      return [
        datePickerOptions[DatePickerOptionType.next90days](),
        datePickerOptions[DatePickerOptionType.thisYear](),
        datePickerOptions[DatePickerOptionType.nextYear]()
      ];
    case DatePickerRangeType.serviceAnalytics:
      return [
        datePickerOptions[DatePickerOptionType.today](),
        datePickerOptions[DatePickerOptionType.last30days](),
        datePickerOptions[DatePickerOptionType.last90days]()
      ];
    default:
      return [
        datePickerOptions[DatePickerOptionType.today](),
        datePickerOptions[DatePickerOptionType.last30days](),
        datePickerOptions[DatePickerOptionType.last90days](),
        datePickerOptions[DatePickerOptionType.yearToDate](),
        datePickerOptions[DatePickerOptionType.oneYear](),
        datePickerOptions[DatePickerOptionType.lastYear]()
      ];
  }
};

export const getDateRangePickerDisabledDays = (type?: DatePickerRangeType): BeforeAfterModifier => {
  switch (type) {
    case DatePickerRangeType.notifications:
      return {
        before: currentDate().subtract(30, 'd').toDate(),
        after: currentDate().toDate(),
      };
    case DatePickerRangeType.regWatch:
      return {
        before: currentDate().subtract(365, 'days').toDate(),
        after: currentDate().toDate(),
      };
    case DatePickerRangeType.forecast:
      return {
        before: currentDate().add(1, 'd').toDate(),
        after: currentDate().add(10, 'y').toDate(),
      };
    default:
      return {
        before: currentDate().subtract(10, 'y').toDate(),
        after: currentDate().add(10, 'y').toDate(),
      };
  }
};

export const getDateRangeFilterValues = (type: DatePickerOptionType, loadFromSession = false): DateRange<string> => {
  if (loadFromSession) {
    const dateSelection: DateRange<string> = getSessionDateSelection();
    if (dateSelection && dateSelection.from && dateSelection.to) {
      return {
        from: dateSelection.from,
        to: dateSelection.to,
      };
    }
  }
  const { value } = datePickerOptions[type]();
  return {
    from: moment(value.from).format('MM - DD - YYYY'),
    to: moment(value.to).format('MM - DD - YYYY'),
  };
};

export const getRegwatchDateRangeFilterValues = (type = DatePickerOptionType.last30days): DateRange<string> => {
  const dateSelection: DateRange<string> = getRegWatchSessionDateSelection();
  if (dateSelection && dateSelection.from && dateSelection.to) {
    return {
      from: dateSelection.from,
      to: dateSelection.to,
    };
  }
  return getDateRangeFilterValues(type);
};
