export enum Routes {
  root = '/reg-watch',
  updates = '/updates',
  latestUpdateDetails = '/updates/:updateId',
  updateDetails = '/update-details',
  assessments = '/assessments',
}

export function buildUpdateDetailsRoute(
  siteId: string,
  custId: string
): string {
  return `${Routes.root + Routes.updateDetails}?siteId=${siteId}&custId=${custId}`;
}

export function buildUpdatesUrl(updateId: number): string {
  const redirectUrl = Routes.root + Routes.latestUpdateDetails.replace(
    ':updateId',
    updateId.toString(),
  );
  return redirectUrl;
}
