import BlogPost, { IBlogPost } from 'contracts/models/service/BlogPost';
import TwitterPost, {
  ITwitterPost,
} from 'contracts/models/service/TwitterPost';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getTwitterPosts(): Promise<TwitterPost[]> {
  const response = await httpCustomerCore.get<TwitterPost[]>(
    'social-media/twitter/posts',
    {
      transformResponse: transformServiceResponse<ITwitterPost, TwitterPost>(
        TwitterPost,
      ),
    },
  );
  return response.data;
}

export async function getBlogPosts(): Promise<BlogPost[]> {
  const response = await httpCustomerCore.get<BlogPost[]>(
    'social-media/wordpress/posts',
    {
      transformResponse: transformServiceResponse<IBlogPost, BlogPost>(
        BlogPost,
      ),
    },
  );
  return response.data;
}

export async function getEnvironmentSafetyBlogPosts(): Promise<BlogPost[]> {
  const response = await httpCustomerCore.get<BlogPost[]>(
    'social-media/environment-and-safety/posts',
    {
      transformResponse: transformServiceResponse<IBlogPost, BlogPost>(
        BlogPost,
      ),
    },
  );
  return response.data;
}

export async function getPublicPolicyPosts(): Promise<BlogPost[]> {
  const response = await httpCustomerCore.get<BlogPost[]>(
    'social-media/public-policy/posts',
    {
      transformResponse: transformServiceResponse<IBlogPost, BlogPost>(
        BlogPost,
      ),
    },
  );
  return response.data;
}
