import { combineReducers } from 'redux';

import { reducer as compactorProfileReducer } from './compactorProfile';
import { reducer as containerReducer } from './containerSizing'; 
import { reducer as dashboardReducer } from './dashboard';
import { reducer as foundryReducer } from './foundry';
import { reducer as frontLoadDiversionDetailsReducer } from './frontLoadDiversionDetails';
import { reducer as frontLoadDiversionReportReducer } from './frontLoadDiversionReport';
import { reducer as optimizationReportReducer } from './optimizationReport';
import { reducer as powerBiReducer } from './powerBi';
import { reducer as serviceAnalyticsReducer } from './serviceAnalytics';
import { reducer as uploadedDocsReducer } from './uploadedDocs';

const reducer = combineReducers({
  dashboard: dashboardReducer,
  optimizationReport: optimizationReportReducer,
  compactorProfile: compactorProfileReducer,
  frontLoadDiversionReport: frontLoadDiversionReportReducer,
  frontLoadDiversionDetails: frontLoadDiversionDetailsReducer,
  uploadedDocs: uploadedDocsReducer,
  powerBi: powerBiReducer,
  foundry: foundryReducer,
  sizingReport: containerReducer,
  serviceConfirmationAnalytics: serviceAnalyticsReducer
});

export default reducer;
