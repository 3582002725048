export interface IDocumentTypes {
  readonly documentTypes: DocumentTypeModel[];
}

class DocumentTypes {
  documentTypes?: DocumentTypeModel[];

  constructor(data?: Partial<IDocumentTypes>) {
    this.documentTypes = data?.documentTypes;
  }
}

export interface DocumentTypeModel {
  code: string;
  name: string;
}

export default DocumentTypes;

