import update from 'immutability-helper';
import isNumber from 'lodash-es/isNumber';

import { NotificationType } from 'contracts/enums';
import ApiKeyPageOptions from 'contracts/models/service/ApiKeyPageOptions';
import ApiKeyTableRow from 'contracts/models/service/KeyManagement';
import { ActionDispatcher, KeyManagementAction } from 'contracts/types/action';
import { ApplicationState, KeyManagementState, ReduceFunctionMap } from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as keyManagementService from '../services/keyManagement';

// Actions Keys
const ROOT_KEY = 'key-management';
enum ActionKey {
  LOAD_KEY_MANAGEMENT_TABLE_DATA = 'key-management/LOAD_KEY_MANAGEMENT_TABLE_DATA',
  UNVEIL_API_KEY = 'key-management/UNVEIL_API_KEY',
  GENERATE_API_KEY = 'key-management/GENERATE_API_KEY',
  DELETE_API_KEY = 'key-management/DELETE_API_KEY',
  LOAD_PAGE_OPTIONS = 'key-management/LOAD_PAGE_OPTIONS',
  RESET = 'key-management/RESET',
}

// Initial State
const getInitialState: () => KeyManagementState = () => {
  return {
    keyManagementData: [],
    pageOptions: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_KEY_MANAGEMENT_TABLE_DATA,
  ActionKey.UNVEIL_API_KEY,
  ActionKey.GENERATE_API_KEY,
  ActionKey.LOAD_PAGE_OPTIONS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  KeyManagementState,
  KeyManagementAction
> = {
  [ActionKey.LOAD_KEY_MANAGEMENT_TABLE_DATA]: 
  function(state: KeyManagementState, action: KeyManagementAction): KeyManagementState {
    const { keyManagementData } = action;
    if (!keyManagementData) {
      return state;
    }
    return update(state, { $merge: { keyManagementData } });
  },
  [ActionKey.UNVEIL_API_KEY]:
  function(state: KeyManagementState, action: KeyManagementAction): KeyManagementState {
    const { apiKeyItem, indexItem } = action;
    if (!isNumber(indexItem)) {
      return state;
    }
    const stateItem = { ...state.keyManagementData.filter((x, i) => i === indexItem)[0] };
    if (apiKeyItem && (stateItem.isVeiled === undefined || stateItem.isVeiled === true)) {
      apiKeyItem.isVeiled = false;
      const newState = update(state, {
        keyManagementData: {
          [indexItem]: {
            $merge: {
              ...apiKeyItem,
            }
          }
        }
      });
      return newState;
    } else {
      stateItem.isVeiled = !stateItem.isVeiled;
      const newState = update(state, {
        keyManagementData: {
          [indexItem]: {
            $merge: {
              ...stateItem,
            }
          }
        }
      });
      return newState;
    }
  },
  [ActionKey.GENERATE_API_KEY]:
  function(state: KeyManagementState, action: KeyManagementAction): KeyManagementState {
    const newState = update(state, {
      $merge: {
        addKeyId: action.addKeyId
      },
    });
    return newState;
  },
  [ActionKey.LOAD_PAGE_OPTIONS]: 
  function(state: KeyManagementState, action: KeyManagementAction): KeyManagementState {
    const { pageOptions } = action;
    if (!pageOptions) {
      return state;
    }
    return update(state, { $merge: { pageOptions } });
  },
};

export const reducer = getReducerBuilder<
 KeyManagementState,
 KeyManagementAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_KEY_MANAGEMENT_TABLE_DATA: (tableData?: ApiKeyTableRow[]): KeyManagementAction => ({
    type: ActionKey.LOAD_KEY_MANAGEMENT_TABLE_DATA,
    keyManagementData : tableData,
  }),
  UNVEIL_API_KEY: (index: number, apiKeyItem?: ApiKeyTableRow ): KeyManagementAction => ({
    type: ActionKey.UNVEIL_API_KEY,
    apiKeyItem,
    indexItem: index,
  }),
  GENERATE_API_KEY: (addKeyId?: number): KeyManagementAction => ({
    type: ActionKey.GENERATE_API_KEY,
    addKeyId,
  }),
  DELETE_API_KEY: (): KeyManagementAction => ({
    type: ActionKey.DELETE_API_KEY,
  }),
  LOAD_PAGE_OPTIONS: (pageOptions: ApiKeyPageOptions): KeyManagementAction => ({
    type: ActionKey.LOAD_PAGE_OPTIONS,
    pageOptions,
  }),
  RESET: (): KeyManagementAction => ({ type: ActionKey.RESET }),
};

const loadKeyManagementTable = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_KEY_MANAGEMENT_TABLE_DATA,
    () => keyManagementService.getKeyManagementTableData(),
    result => dispatch(actionMap.LOAD_KEY_MANAGEMENT_TABLE_DATA(result)),
    () => dispatch(actionMap.LOAD_KEY_MANAGEMENT_TABLE_DATA()),
  );

const unveilApiKey = (item: ApiKeyTableRow, index: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  !item.privateKey?.includes('*') ?
    dispatch(actionMap.UNVEIL_API_KEY(index))
    :
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.UNVEIL_API_KEY,
      () => keyManagementService.unveilApiKey(item.id as number),
      (response?: ApiKeyTableRow) => {
        dispatch(actionMap.UNVEIL_API_KEY(index, response));
      },
      () => {},
    );
const generateApiKey = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UNVEIL_API_KEY,
    () => keyManagementService.generateApiKey(),
    async response => {
      dispatch(actionMap.GENERATE_API_KEY(response.id));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          `${translate('account.apiKeyCreated')}`,
          3000,
        ),
      );
    },
    () => {
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          `${translate('account.apiKeyError')}`,
          3000,
        ),
      );
    }
  );
const deleteApiKey = (apiKeyId: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UNVEIL_API_KEY,
    () => keyManagementService.deleteApiKey(apiKeyId),
    () => {
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          `${translate('account.apiKeyRemoved')}`,
          3000,
        ),
      );
    },
    () => {},
    true,
  );
const loadPageOptions = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAGE_OPTIONS,
    () => keyManagementService.loadPageOptions(),
    result => dispatch(actionMap.LOAD_PAGE_OPTIONS(result)),
    () => {},
  );

const keyManagementDuck = {
  thunks: {
    loadKeyManagementTable,
    unveilApiKey,
    generateApiKey,
    deleteApiKey,
    loadPageOptions,
  },
  actions: {
    reset: actionMap.RESET,
  },
  actionKeys: ActionKey,
};

export default keyManagementDuck;
