import { SavingsData } from 'contracts/models';
import { SavingsRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore } from 'core/services/http';

export async function getSavings({
  fromDate,
  toDate,
  locationFilter,
}: SavingsRequest): Promise<SavingsData> {
  const response = await httpCustomerCore.post('savings/baselinereport', {
    FromDate: fromDate,
    ToDate: toDate,
    SiteFilters: getLocationFilterApiArg(locationFilter),
  });
  return response.data;
}

export async function getSavingsCsv({
  fromDate,
  toDate,
  locationFilter,
  buildSavingsDetails = false,
}: SavingsRequest): Promise<GetCsvResponse> {
  const file = getCsv(httpCustomerCore, 'savings/generate-csv', {
    FromDate: fromDate,
    ToDate: toDate,
    SiteFilters: getLocationFilterApiArg(locationFilter),
    buildSavingsDetails,
  });
  return file;
}
