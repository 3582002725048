export interface IServiceRequestCustomerFilesTableData {
  readonly id: number;
  readonly attachedDate: string | null;
  readonly fileName: string;
  readonly fileType: string;
  readonly uploadedBy: string;
  readonly custId: string;
  readonly siteId: string;
  readonly svcId: string;
}

class ServiceRequestCustomerFilesTableData {
  id?: number;
  attachedDate?: string | null;
  fileName?: string;
  fileType?: string;
  uploadedBy?: string;
  custId?: string;
  siteId?: string;
  svcId?: string;

  constructor(data?: Partial<IServiceRequestCustomerFilesTableData>) {
    this.id = data?.id;
    this.attachedDate = data?.attachedDate;
    this.fileName = data?.fileName;
    this.fileType = data?.fileType;
    this.uploadedBy = data?.uploadedBy;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.svcId = data?.svcId;
  }
}

export default ServiceRequestCustomerFilesTableData;
