import React, { PureComponent } from 'react';

import { FilterApprover } from 'contracts/models';
import { ApproversFilter as ApproversFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class ApproversFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (approvers: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      approvers,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled, isLoading, approvers } = this.props;
    return (
      <DropdownFilter
        label={translate('services.approver')}
        values={approvers.map(approver => ({
          id: approver.reviewerEmail,
          label: approver.reviewerEmail,
        }))}
        selectedIds={currentFilter.approvers || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    );
  }
}

interface ComponentProps {
  approvers: Array<Required<FilterApprover>>;
  currentFilter: ApproversFilterType;
  onFilterChanged: (filter: ApproversFilterType) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export default ApproversFilter;
