import CustomerDocument, {
  ICustomerDocument,
} from 'contracts/models/service/CustomerDocument';
import DocumentTypes, { IDocumentTypes } from 'contracts/models/service/DocumentType';
import SiteProfileNote, {
  ISiteProfileNote,
} from 'contracts/models/service/SiteProfileNote';
import { SiteIdentifier } from 'contracts/types/component';
import { CustomerDocumentArg, CustomerDocumentPaginationArg } from 'contracts/types/request';
import { GetBlobResponse } from 'contracts/types/service';
import { getBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getSiteFiles = async(req: CustomerDocumentPaginationArg): Promise<CustomerDocument[]> => {
  const response = await httpCustomerCore.post('site-profile/documents', req, {
    transformResponse: transformServiceResponse<
      ICustomerDocument,
      CustomerDocument
    >(CustomerDocument),
  });
  return response.data;
};

export const getSiteNotes = async({
  siteId,
  siteCustomerId,
}: SiteIdentifier): Promise<SiteProfileNote[]> => {
  const response = await httpCustomerCore.get('site-profile/notes', {
    params: {
      custId: siteCustomerId,
      siteId,
    },
    transformResponse: transformServiceResponse<
      ISiteProfileNote,
      SiteProfileNote
    >(SiteProfileNote),
  });
  return response.data;
};

export async function getReportDocument(id: number): Promise<GetBlobResponse> {
  const urlPath = `document/${id}`;
  const reportDocument = getBlobFile(httpCustomerCore, urlPath);
  return reportDocument;
}

export const getReportTypes = async(req: CustomerDocumentArg): Promise<DocumentTypes> => {
  const response = await httpCustomerCore.post('site-profile/document-type', req, {
    transformResponse: transformServiceResponse<
      IDocumentTypes,
      DocumentTypes
    >(DocumentTypes),
  });
  return response.data;
};