import { combineReducers } from 'redux';

import { reducer as premierSiteProfileReducer } from '../components/BrandedComponents/PremierSiteProfile/duck';

import { reducer as cieTradeRequestReducer } from './cieTrade';
import { reducer as disposalAuditReducer } from './disposalAudit';
import { reducer as editSiteReducer } from './editSite';
import { reducer as holidaysReducer } from './loadHolidays';
import { reducer as locationsReducer } from './locations';
import { reducer as newServiceReducer } from './newService';
import { reducer as newServiceModalReducer } from './newServiceModal';
import { reducer as serviceAgreementReducer } from './serviceAgreement';
import { reducer as serviceAgreementsReducer } from './serviceAgreements';
import { reducer as serviceRequestApprovalReducer } from './serviceApproval';
import { reducer as serviceCancelReducer } from './serviceCancel';
import { reducer as serviceListReducer } from './serviceList';
import { reducer as serviceRequestDetailsReducer } from './serviceRequestDetails';
import { reducer as serviceRequestEventsReducer } from './serviceRequestEvents';
import { reducer as serviceRequestsReducer } from './serviceRequests';
import { reducer as serviceUpdateReducer } from './serviceUpdate';

const reducer = combineReducers({
  holidays: holidaysReducer,
  locations: locationsReducer,
  serviceList: serviceListReducer,
  serviceRequests: serviceRequestsReducer,
  serviceRequestEvents: serviceRequestEventsReducer,
  serviceAgreement: serviceAgreementReducer,
  serviceAgreementsList: serviceAgreementsReducer,
  cieTradeRequest: cieTradeRequestReducer,
  editSite: editSiteReducer,
  newServiceModal: newServiceModalReducer,
  premierSiteProfile: premierSiteProfileReducer,
  serviceUpdate: serviceUpdateReducer,
  serviceCancel: serviceCancelReducer,
  serviceRequestDetails: serviceRequestDetailsReducer,
  serviceRequestApproval: serviceRequestApprovalReducer,
  disposalAudit: disposalAuditReducer,
  newService: newServiceReducer,
});

export default reducer;
