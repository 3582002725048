export interface IFilterMaterial {
  readonly id: number;
  readonly name: string;
}

class FilterMaterial {
  id?: number;
  name?: string;

  constructor(data?: Partial<IFilterMaterial>) {
    this.id = data?.id;
    this.name = data?.name;
  }
}

export default FilterMaterial;
