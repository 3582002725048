import IntergratedReport, { IIntergratedReport } from 'contracts/models/service/IntergratedReport';
import Report, { IReport } from 'contracts/models/service/Report';
import ReportBi, { IReportBi } from 'contracts/models/service/ReportBi';
import { GetBlobResponse } from 'contracts/types/service';
import { getBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getReports(year: string): Promise<Report[]> {
  const response = await httpCustomerCore.get<Report[]>(
    `reports?ReportYear=${year}`,
    {
      transformResponse: transformServiceResponse<IReport, Report>(Report),
    },
  );
  return response.data;
}

export async function getReportDocument(id: number): Promise<GetBlobResponse> {
  const urlPath = `report/${id}`;
  const reportDocument = getBlobFile(httpCustomerCore, urlPath);
  return reportDocument;
}

export async function getPowerBiReport(id: number): Promise<ReportBi> {
  const response = await httpCustomerCore.get<ReportBi>(
    `report/powerbi?id=${id}`,
    {
      transformResponse: transformServiceResponse<IReportBi, ReportBi>(
        ReportBi,
      ),
    },
  );
  return response.data;
}

export async function getFoundryReport(id: number): Promise<IntergratedReport> {
  const response = await httpCustomerCore.get<IntergratedReport>(
    `report/foundry?id=${id}`,
    {
      transformResponse: transformServiceResponse<IIntergratedReport, IntergratedReport>(
        IntergratedReport,
      ),
    },
  );
  return response.data;
}
