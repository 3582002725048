import React from 'react';

import formatInteger from 'core/helpers/formatNumber';

const NumberFormat: React.FC<ComponentProps> = ({ value }) => (
  <React.Fragment>{formatInteger(value)}</React.Fragment>
);

interface ComponentProps {
  value: number;
}
export default NumberFormat;
