import { CustomerType, PermissionType, SaleType, UserRole } from 'contracts/enums';
import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import authorizeSubscriptionModule from 'core/helpers/subscription';
import { Claims } from 'routing/Routes/AuthRoute';

const authorizeRole = (guardRole?: UserRole, userRole?: UserRole): boolean => {
  if (!guardRole) {
    return true;
  }
  if (!userRole) {
    return false;
  }
  switch (guardRole) {
    case UserRole.Admin:
      return userRole === UserRole.Admin;
    case UserRole.Manager:
      return userRole === UserRole.Manager;
    default:
      return true;
  }
};

const authorizeCustType = (
  guardCustTypes?: CustomerType[],
  userCustType?: CustomerType,
): boolean => {
  if (!guardCustTypes || !guardCustTypes.length) {
    return true;
  }
  if (!userCustType) {
    return false;
  }
  return guardCustTypes.indexOf(userCustType) > -1;
};

const authorizeSaleTypes = (
  guardSaleTypes?: SaleType[],
  userSaleTypes?: SaleType[],
): boolean => {
  if (!guardSaleTypes || !guardSaleTypes.length) {
    return true;
  }
  if (!userSaleTypes) {
    return false;
  }
  return Boolean(
    userSaleTypes.find(ct => guardSaleTypes.find(at => at === ct)),
  );
};

const authorizePermissionType = (
  permissionTypes?: PermissionType[],
  permissionType?: PermissionType[],
): boolean => {
  if (!permissionTypes || !permissionTypes.length) {
    return true;
  }
  if (!permissionType) {
    return false;
  }
  return Boolean(
    permissionTypes.find(permType =>
      permissionType.find(at => at === permType),
    ),
  );
};

const authorizeImpersonation = (
  guardUserIsImpersonating?: boolean,
  userIsImpersonating?: boolean,
): boolean => {
  if (guardUserIsImpersonating && userIsImpersonating) {
    return false;
  }
  return true;
};

const authorizeBranding = (
  guardBrandingTypes?: BrandingType[],
  brandingTypes?: BrandingType[],
): boolean => {
  if (guardBrandingTypes) {
    if (brandingTypes && brandingTypes.find(x => guardBrandingTypes.find(y => y === x))) {
      return true;
    } 
    return false;
  }
  return true;
};

const authorizeUserRole = (
  guardUserRoles?: UserRole[],
  userRole?: UserRole,
): boolean => {
  if (guardUserRoles) {
    if (userRole && guardUserRoles.find(x => x === userRole)) {
      return false;
    } 
  }
  return true;
};

const getHasAuthorization = (
  claims: Claims,
  componentKey?: SubscriptionComponentKey,
  guardRole?: UserRole,
  guardCustTypes?: CustomerType[],
  guardSaleTypes?: SaleType[],
  guardUserIsImpersonating?: boolean,
  guardPermissionTypes?: PermissionType[],
  guardBrandingTypes?: BrandingType[],
  guardUserRoles?: UserRole[],
): boolean =>
  authorizeRole(guardRole, claims.userRole) &&
  authorizeCustType(guardCustTypes, claims.customerType) &&
  authorizeSaleTypes(guardSaleTypes, claims.salePermissions) &&
  authorizePermissionType(guardPermissionTypes, claims.permissionType) &&
  authorizeImpersonation(guardUserIsImpersonating, claims.userIsImpersonating) &&
  authorizeBranding(guardBrandingTypes, claims.brandingTypes) &&
  authorizeUserRole(guardUserRoles, claims.userRole) && 
  authorizeSubscriptionModule(componentKey);

export default getHasAuthorization;
