// Action keys
import update from 'immutability-helper';

import FullnessEventsTableRow, { FullnessCalendarViewModel } from 'contracts/models/service/ServiceAnalyticsTableData';
import FullnessEventsTableSubRow from 'contracts/models/service/ServiceAnalyticsTableSubData';
import { ActionDispatcher, ServiceConfirmationAnalyticsAction } from 'contracts/types/action';
import { DisposalAuditDetailsRequestArg } from 'contracts/types/request';
import { ApplicationState, ReduceFunctionMap, ServiceConfirmationAnalyticsState } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as serviceAnalytics from '../services/serviceAnalytics';

const ROOT_KEY = 'reports/service-analytics';
enum ActionKey {
  TABLE_DATA = 'reports/service-analytics/TABLE_DATA',
  TABLE_SUBDATA = 'reports/service-analytics/TABLE_SUBDATA',
  VIEW_SCHEDULE = 'reports/service-analytics/VIEW_SCHEDULE',
  RESET = 'reports/service-analytics/RESET',
}

// Initial State
const getInitialState: () => ServiceConfirmationAnalyticsState = () => {
  return {
    services: [],
    subData: [],
    serviceSchedule: {} as FullnessCalendarViewModel,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.TABLE_DATA, 
  ActionKey.TABLE_SUBDATA, 
  ActionKey.VIEW_SCHEDULE
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceConfirmationAnalyticsState,
  ServiceConfirmationAnalyticsAction
> = {
  [ActionKey.TABLE_DATA]: (state, action) => {
    if (!action.services) {
      return state;
    }
    const services = action.services;
    return update(state, { $merge: { services } });
  },
  [ActionKey.TABLE_SUBDATA]: (state, action) => {
    if (!action.subData) {
      return state;
    }
    const subData = [...state.subData, ...action.subData];
    return update(state, { $merge: { subData } });
  },
  [ActionKey.VIEW_SCHEDULE]: (state, action) => {
    if (!action.serviceSchedule) {
      return state;
    }
    const serviceSchedule = action.serviceSchedule;
    return update(state, { $merge: { serviceSchedule } });
  },
};

export const reducer = getReducerBuilder<ServiceConfirmationAnalyticsState, ServiceConfirmationAnalyticsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  TABLE_DATA: (services?: FullnessEventsTableRow[]): ServiceConfirmationAnalyticsAction => ({
    type: ActionKey.TABLE_DATA,
    services: services || [],
  }),
  TABLE_SUBDATA: (subData?: FullnessEventsTableSubRow[]) => ({
    type: ActionKey.TABLE_SUBDATA,
    subData
  }),
  VIEW_SCHEDULE: (serviceSchedule?: FullnessCalendarViewModel): ServiceConfirmationAnalyticsAction => ({
    type: ActionKey.VIEW_SCHEDULE,
    serviceSchedule,
  }),
  RESET: (): ServiceConfirmationAnalyticsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadReports = (request: DisposalAuditDetailsRequestArg) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_DATA,
    () => serviceAnalytics.getServices(request),
    result => dispatch(actionMap.TABLE_DATA(result.rows)),
    () => {
      dispatch(actionMap.TABLE_DATA());
    },
    true,
  );
const getServiceSchedule = (svcId: number, startDate: string, endDate: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.VIEW_SCHEDULE,
    () => serviceAnalytics.getServiceSchedule(svcId, startDate, endDate),
    response => dispatch(actionMap.VIEW_SCHEDULE(response)),
    () => {
      dispatch(actionMap.VIEW_SCHEDULE());
    }
  );
};
const getServiceSubData = (svcId: number, startDate: string, endDate: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => {
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_SUBDATA,
    () => serviceAnalytics.getServiceSubData(svcId, startDate, endDate),
    response => dispatch(actionMap.TABLE_SUBDATA(response.rows)),
    () => {
      dispatch(actionMap.TABLE_SUBDATA());
    }
  );
};

export const serviceAnalyticsDuck = {
  thunks: { loadReports, getServiceSubData, getServiceSchedule },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
