import { Dispatch, SetStateAction } from 'react';

import update from 'immutability-helper';

import { RegWatchSiteProfileUpdates, RegWatchUpdates } from 'contracts/models';
import {
  ActionDispatcher,
  RegWatchUpdatesAction,
} from 'contracts/types/action';
import { CustomerRegWatchUpdatesRequest, SiteProfileRegWatchUpdatesRequest, UpdateAcknowledgementRequest } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  RegWatchUpdatesState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as regWatchUpdatesService from '../services/regWatchUpdatesService';

// Actions Keys
const ROOT_KEY = 'regWatch/updates';
enum ActionKey {
  LOAD_UPDATES = 'regWatch/updates/LOAD_UPDATES',
  LOAD_SINGLE_UPDATE = 'regWatch/updates/LOAD_SINGLE_UPDATE',
  LOAD_SITE_PROFILE_UPDATES = 'regWatch/updates/LOAD_SITE_PROFILE_UPDATES',
  LOAD_UPDATE_PDF_DOWNLOAD_URL = 'regWatch/updates/LOAD_UPDATE_PDF_DOWNLOAD_URL',
  SUBMIT_ACKNOWLEDGEMENT = 'regWatch/updates/SUBMIT_ACKNOWLEDGEMENT',
  RESET = 'regWatch/updates/RESET',
}

// Initial state
const getInitialState: () => RegWatchUpdatesState = () => {
  return {
    updates: {},
    siteProfileUpdates: {},
    singleUpdate: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_UPDATES,
  ActionKey.LOAD_SINGLE_UPDATE,
  ActionKey.SUBMIT_ACKNOWLEDGEMENT,
  ActionKey.LOAD_SITE_PROFILE_UPDATES] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RegWatchUpdatesState,
  RegWatchUpdatesAction
> = {
  [ActionKey.LOAD_UPDATES]: (state, action) => {
    const { updates } = action;
    return update(state, {
      $merge: {
        updates,
      },
    });
  },
  [ActionKey.LOAD_SINGLE_UPDATE]: (state, action) => {
    const { singleUpdate } = action;
    return update(state, {
      $merge: {
        singleUpdate,
      },
    });
  },
  [ActionKey.SUBMIT_ACKNOWLEDGEMENT]: state => {
    return state;
  },
  [ActionKey.LOAD_SITE_PROFILE_UPDATES]: (state, action) => {
    const { siteProfileUpdates } = action;
    return update(state, {
      $merge: {
        siteProfileUpdates,
      },
    });
  },
};

export const reducer = getReducerBuilder<
  RegWatchUpdatesState,
  RegWatchUpdatesAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_UPDATES: (
    updates?: RegWatchUpdates,
  ): RegWatchUpdatesAction => ({
    type: ActionKey.LOAD_UPDATES,
    updates,
  }),
  LOAD_SINGLE_UPDATE: (
    singleUpdate?: RegWatchUpdates,
  ): RegWatchUpdatesAction => ({
    type: ActionKey.LOAD_SINGLE_UPDATE,
    singleUpdate,
  }),
  SUBMIT_ACKNOWLEDGEMENT: () => ({
    type: ActionKey.SUBMIT_ACKNOWLEDGEMENT,
  }),
  LOAD_SITE_PROFILE_UPDATES: (
    siteProfileUpdates?: RegWatchSiteProfileUpdates,
  ): RegWatchUpdatesAction => ({
    type: ActionKey.LOAD_SITE_PROFILE_UPDATES,
    siteProfileUpdates,
  }),
  RESET: (): RegWatchUpdatesAction => ({ type: ActionKey.RESET }),
};

// Thunks
const getUpdates = (request: CustomerRegWatchUpdatesRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_UPDATES,
    () => regWatchUpdatesService.getRegWatchCustomerUpdates(request),
    result => dispatch(actionMap.LOAD_UPDATES(result)),
    () => dispatch(actionMap.LOAD_UPDATES()),
  );

const getSiteProfileUpdates = (request: SiteProfileRegWatchUpdatesRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_PROFILE_UPDATES,
    () => regWatchUpdatesService.getRegWatchSiteProfileUpdates(request),
    result => dispatch(actionMap.LOAD_SITE_PROFILE_UPDATES(result)),
    () => dispatch(actionMap.LOAD_SITE_PROFILE_UPDATES({})),
  );

const getSiteUpdates = (request: UpdateAcknowledgementRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SINGLE_UPDATE,
    () => regWatchUpdatesService.getRegWatchSingleUpdate(request),
    result => dispatch(actionMap.LOAD_SINGLE_UPDATE(result)),
    () => dispatch(actionMap.LOAD_SINGLE_UPDATE({})),
  );

const sendAcknowledgement = (
  request: UpdateAcknowledgementRequest,
  setIsAcknowledged: Dispatch<SetStateAction<boolean>>,
  dispatchCall: Dispatch<unknown>
) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SUBMIT_ACKNOWLEDGEMENT,
    () => regWatchUpdatesService.submitAcknowledgement(request),
    () => {
      dispatch(actionMap.SUBMIT_ACKNOWLEDGEMENT());
      dispatchCall(getSiteUpdates(request));
      setIsAcknowledged(true);
    },
    () => dispatch(actionMap.SUBMIT_ACKNOWLEDGEMENT()),
  );

const regWatchUpdatesDuck = {
  thunks: {
    getUpdates,
    getSiteUpdates,
    getSiteProfileUpdates,
    sendAcknowledgement,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default regWatchUpdatesDuck;
