import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { FrontLoadDiversionSiteDetails } from 'contracts/models';
import {
  ActionDispatcher,
  FrontLoadDiversionDetailsAction,
} from 'contracts/types/action';
import { DateRange } from 'contracts/types/component';
import { SendSuggestedServicesArg } from 'contracts/types/frontLoadDiversion';
import {
  ApplicationState,
  FrontLoadDiversionDetailsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getFrontLoadDiversionDetails,
  sendRequest,
} from '../services/frontLoadDiversionDetailsService';

// Actions
const ROOT_KEY = 'reports/frontload-diversion-details';

enum ActionKey {
  LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA = 'reports/frontload-diversion-details/LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA',
  SEND_SUGGESTED_SERVICES = 'reports/frontload-diversion-details/SEND_SUGGESTED_SERVICES',
  WERE_SUGGESTED_SERVICES_SENT = 'reports/frontload-diversion-details/WERE_SUGGESTED_SERVICES_SENT',
  RESET = 'reports/frontload-diversion-details/RESET_ALL',
}

// Initial State
const getInitialState: () => FrontLoadDiversionDetailsState = () => {
  return {
    frontLoadDiversionSiteDetailsData: {},
    wereSuggestedServicesSent: false,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA,
  ActionKey.WERE_SUGGESTED_SERVICES_SENT,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  FrontLoadDiversionDetailsState,
  FrontLoadDiversionDetailsAction
> = {
  [ActionKey.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA]: (
    state: FrontLoadDiversionDetailsState,
    action: FrontLoadDiversionDetailsAction,
  ) => {
    return update(state, {
      $merge: {
        frontLoadDiversionSiteDetailsData:
          action.frontLoadDiversionSiteDetailsData,
      },
    });
  },
  [ActionKey.WERE_SUGGESTED_SERVICES_SENT]: (
    state: FrontLoadDiversionDetailsState,
    action: FrontLoadDiversionDetailsAction,
  ) => {
    return update(state, {
      $merge: {
        wereSuggestedServicesSent: action.wereSuggestedServicesSent,
      },
    });
  },
};

export const reducer = getReducerBuilder<
  FrontLoadDiversionDetailsState,
  FrontLoadDiversionDetailsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA: (
    frontLoadDiversionSiteDetailsData?: FrontLoadDiversionSiteDetails,
  ): FrontLoadDiversionDetailsAction => ({
    type: ActionKey.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA,
    frontLoadDiversionSiteDetailsData,
  }),
  WERE_SUGGESTED_SERVICES_SENT: (
    wereSuggestedServicesSentFlag: boolean,
  ): FrontLoadDiversionDetailsAction => ({
    type: ActionKey.WERE_SUGGESTED_SERVICES_SENT,
    wereSuggestedServicesSent: wereSuggestedServicesSentFlag,
  }),
  RESET: (): FrontLoadDiversionDetailsAction => ({
    type: ActionKey.RESET,
  }),
};

// API Calls
const loadFrontLoadDiversionSiteDetails = (
  siteId: string,
  dataRange: DateRange<string>,
  custId: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA,
    async() => getFrontLoadDiversionDetails(siteId, dataRange, custId),
    result => {
      dispatch(actionMap.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA(result));
    },
    () => dispatch(actionMap.LOAD_FRONTLOAD_DIVERSION_SITE_DETAILS_DATA()),
  );

const sendSuggestedServicesRequest = (
  sendSuggestedServicesArg: SendSuggestedServicesArg,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SEND_SUGGESTED_SERVICES,
    async() => sendRequest(sendSuggestedServicesArg),
    () => {
      dispatch(actionMap.WERE_SUGGESTED_SERVICES_SENT(true));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Your request was sent successfully!',
        ),
      );
    },
    () => {
      dispatch(actionMap.WERE_SUGGESTED_SERVICES_SENT(false));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Error,
          `Your request wasn't sent!`,
        ),
      );
    },
  );

const frontLoadDiversionDetailsReportDuck = {
  thunks: { loadFrontLoadDiversionSiteDetails, sendSuggestedServicesRequest },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default frontLoadDiversionDetailsReportDuck;
