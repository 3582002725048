import React from 'react';

import iconFilter from 'core/assets/images/icon_filter.svg';
import translate from 'core/helpers/translate';

import { DropdownFilterCount } from '../styled/DropdownFilter';
import {
  FilterContainer,
  FilterByButton,
  FilterByArrow,
  FilterIcon,
} from '../styled/Filter';

const DropdownFilterControl: React.FC<ComponentProps> = ({
  label,
  isExpanded,
  isDisabled,
  isLoading,
  toggleExpanded,
  count,
  selectedFilterStatus,
  bold
}) => (
  <FilterContainer>
    <FilterIcon src={iconFilter} alt='filter icon' hasData={count > 0} />
    <FilterByButton
      onClick={toggleExpanded}
      isDisabled={isDisabled}
      isLoading={isLoading}
      hasData={count > 0}
      bold={bold}
    >
      {isLoading ? `${translate('loading')}...` : label}
      {count > 0 && <DropdownFilterCount>{`(${selectedFilterStatus || count})`}</DropdownFilterCount>}
      <FilterByArrow
        isDisabled={isDisabled}
        isLoading={isLoading}
        isExpanded={isExpanded}
      />
    </FilterByButton>
  </FilterContainer>
);

interface ComponentProps {
  label: string;
  isExpanded: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  bold?: boolean;
  toggleExpanded: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  count: number;
  selectedFilterStatus?: string;
}

export default DropdownFilterControl;
