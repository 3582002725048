export enum ServicesRoutes {
  root = '/services',
  locations = '/locations',
  serviceSiteProfile = '/locations/service-site-profile',
  editSiteProfile = '/locations/edit-site-profile',
  serviceRequests = '/service-requests',
  serviceRequestApproval = '/service-request-approval',
  serviceRequestsClosed = '/service-requests-closed',
  disposalAudit = '/disposal-audit',
  serviceCancelPayment = '/service-cancel',
  rubiconFlash = '/rubicon-flash',
  rubiconReferral = '/rubicon-referral',
  wholesale = '/wholesale/location',
  updateService = '/locations/service-site-profile/service-update',
  updateServiceAgreement = '/locations/service-site-profile/service-agreement',
  serviceUpdateApproval = '/locations/service-site-profile/service-update-approval',
  serviceInsights = '/locations/service-site-profile/service-insights',

  newServiceDetails = '/new-service/:serviceIndex/details',
  newServiceStartDate = '/new-service/:serviceIndex/start-date',
  newServiceSummary = '/new-service/summary',
  newServiceSubmit = '/new-service/submit-request',
  serviceAgreements = '/service-agreements',

  newSimplifiedServiceDetails = '/new-simplified-service/:serviceIndex/details',
  newSimplifiedServiceSummary = '/new-simplified-service/summary',

}

export function buildNewServiceUrl(): string {
  const redirectUrl = ServicesRoutes.root + ServicesRoutes.newServiceDetails.replace(
    ':serviceIndex',
    '1',
  );

  return redirectUrl;
}

export function buildNewSimplifiedServiceUrl(service: string, serviceIndex: string): string {
  const redirectUrl = ServicesRoutes.root + ServicesRoutes.newSimplifiedServiceDetails.replace(
    ':serviceIndex',
    serviceIndex,
  );
  return `${redirectUrl}?servType=${service}`;
}

export function buildServiceSiteProfile(
  siteId: string,
  custId: string,
): string {
  return `${ServicesRoutes.root + ServicesRoutes.serviceSiteProfile}?siteId=${siteId}&custId=${custId}`;
}

export function buildNewServiceModalUrl(
  siteId: string,
  custId: string,
  service?: string,
): string {
  return `${ServicesRoutes.root + ServicesRoutes.serviceSiteProfile}?siteId=${siteId}&custId=${custId}&service=${service}`;
}

export function buildServiceUpdate(serviceId: number): string {
  return `${ServicesRoutes.root + ServicesRoutes.updateService}?serviceId=${serviceId}`;
}

export function buildServiceUpdateAgreement(alias: string): string {
  return `${ServicesRoutes.root + ServicesRoutes.updateServiceAgreement}?alias=${alias}`;
}

export function buildEditSiteProfile(siteId: string, custId: string): string {
  return `${ServicesRoutes.root + ServicesRoutes.editSiteProfile}?siteId=${siteId}&custId=${custId}`;
}

export function buildServiceInsightsUrl(
  siteId: string,
  custId: string,
  serviceId: number,
): string {
  return `${ServicesRoutes.root + ServicesRoutes.serviceInsights}?siteId=${siteId}&custId=${custId}&serviceId=${serviceId}`;
}
