import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from 'core/components/styled';
import { theme } from 'core/styles';

interface AmountButtonProps {
  add?: boolean;
  subtract?: boolean;
  disabled?: boolean;
}

export const AmountButton = styled.button.attrs({ type: 'button' })<
  AmountButtonProps
>`
  ${BareButtonStyle};
  flex-basis: 45px;
  background: center no-repeat;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    `};

  ${props =>
    props.add &&
    css`
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMjE0LjV2Ny43MTRILjV2Mi41NzJoNy43MTRWMTguNWgyLjU3MnYtNy43MTRIMTguNVY4LjIxNGgtNy43MTRWLjV6IiBmaWxsPSIjMDA5Mzg1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
    `};

  ${props =>
    props.subtract &&
    css`
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjUgMGgxOHYyLjVILjV6IiBmaWxsPSIjMDA4OTdBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
    `};
`;

interface AmountValueProps {
  disabled?: boolean;
}
export const AmountValue = styled.span<AmountValueProps>`
  flex: 1;
  line-height: 44px;
  font-size: 24px;
  text-align: center;
  cursor: default;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

interface AmountProps {
  marginBottom?: boolean;
  width?: number;
}

export const Amount = styled.div<AmountProps>`
  display: flex;
  width: ${props => (props.width ? `${props.width}px` : '150px')};
  height: 44px;
  border: 1px solid ${transparentize(0.7, theme.colors.brandBlack)};
  border-radius: 6px;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 120px;
    `};
`;
