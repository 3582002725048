import { TableData } from 'contracts/types/component';

export interface IMaterialManagementTableData {
  readonly serviceDate: string;
  readonly siteName: string;
  readonly address: string;
  readonly equipment: string;
  readonly equipmentSize: string;
  readonly frequency: string;
  readonly material: string;
  readonly tonnage: number;
  readonly pounds: number;
  readonly materialManagementMethod: string;
}

class MaterialManagementTableData implements TableData {
  serviceDate?: string;
  siteName?: string;
  address?: string;
  equipment?: string;
  equipmentSize?: string;
  frequency?: string;
  material?: string;
  tonnage?: number;
  pounds?: number;
  materialManagementMethod?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IMaterialManagementTableData>) {
    this.serviceDate = data?.serviceDate;
    this.siteName = data?.siteName;
    this.address = data?.address;
    this.equipment = data?.equipment;
    this.equipmentSize = data?.equipmentSize;
    this.frequency = data?.frequency;
    this.material = data?.material;
    this.tonnage = data?.tonnage;
    this.pounds = data?.pounds;
    this.materialManagementMethod = data?.materialManagementMethod;
  }
}

export default MaterialManagementTableData;
