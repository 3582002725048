export interface SiteComparisonDateRange {
  custId: string;
  siteId: string;
  materials: SiteComparisonMaterials[];
}

export interface SiteComparisonMaterials {
  identifier: number;
  name: string;
  tons: number;
  pounds: number;
}

export interface IMaterialProfileSiteComparison {
  readonly startDate: string;
  readonly endDate: string;
  readonly siteRanges: SiteComparisonDateRange[];
}

class MaterialProfileSiteComparison {
  startDate?: string;
  endDate?: string;
  siteRanges?: SiteComparisonDateRange[];

  constructor(data?: Partial<IMaterialProfileSiteComparison>) {
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.siteRanges = data?.siteRanges;
  }
}

export default MaterialProfileSiteComparison;
