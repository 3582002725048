import BulkServicesUpdateSortKey from 'contracts/enums/BulkServicesUpdateSortKey';
import { RecurringServiceResponse } from 'contracts/models/service/RecurringService';
import RecurringServiceDropdownOptions, { IRecurringServiceDropdownOptions } from 'contracts/models/service/RecurringServiceDropdownOptions';
import RecurringServiceOptions, { IRecurringServiceOptions } from 'contracts/models/service/RecurringServiceOptions';
import RecurringSvcUniqueCheckResponse from 'contracts/models/service/RecurringSvcUniqueCheckResponse';
import { BulkUploadTemplateType, CheckRecurringServiceUpdateResponse, 
  GetRecurringServicesRequest,
  RecurringServiceRequest, 
  SaasCheckUniqueRecurringSvcRequest, 
  SaasServiceRecurringOptionsRequest, 
  SeasonalServiceRequest
} from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getServices(request: GetRecurringServicesRequest): Promise<RecurringServiceResponse> {
  const response = await httpCustomerCore.post<RecurringServiceResponse>(
    `self-managed/svc-shell/get-services`, {
      orderByAscending:
      request && request.ascending
        ? buildSortArg(request.ascending, BulkServicesUpdateSortKey)
        : {},
      orderByDescending:
      request && request.descending
        ? buildSortArg(request.descending, BulkServicesUpdateSortKey)
        : {},
      storeIds: request.storeIds || [],
      pageNumber: request.pageNumber,
      pageSize: request.pageSize,
      filter: request.filter 
        ? (!request.filter.isRecurring && !request.filter.isSeasonal) 
          ? undefined
          : request.filter
        : undefined
    },
  );
  return response.data;
}

export async function downloadServices(request: GetRecurringServicesRequest): Promise<GetCsvResponse> {
  const urlPath = `self-managed/svc-shell/get-services-csv`;
  const reportDocument = getCsv(httpCustomerCore, urlPath, {
    orderByAscending:
    request && request.ascending
      ? buildSortArg(request.ascending, BulkServicesUpdateSortKey)
      : {},
    orderByDescending:
    request && request.descending
      ? buildSortArg(request.descending, BulkServicesUpdateSortKey)
      : {},
    storeIds: request.storeIds || [],
    filter: request.filter 
      ? (!request.filter.isRecurring && !request.filter.isSeasonal) 
        ? undefined
        : request.filter
      : undefined
  });
  return reportDocument;
}

export const getOptions = async(req?: SaasServiceRecurringOptionsRequest): 
Promise<RecurringServiceOptions> => {
  const response = await httpCustomerCore.post<RecurringServiceOptions>(
    '/self-managed/svc-shell/get-options',
    { ...req },
    {
      transformResponse: transformServiceResponse<
        IRecurringServiceOptions,
        RecurringServiceOptions
      >(RecurringServiceOptions),
    },
  );
  return response.data;
};

export const getDropdownOptions = async(
  serviceType: BulkUploadTemplateType): Promise<RecurringServiceDropdownOptions> => {
  const response = await httpCustomerCore.post<RecurringServiceDropdownOptions>(
    'self-managed/get-dropdown-options',
    {
      uploadType: serviceType
    },
    {
      transformResponse: transformServiceResponse<
        IRecurringServiceDropdownOptions,
        RecurringServiceDropdownOptions
      >(RecurringServiceDropdownOptions),
    },
  );
  return response.data;
};

export const checkIsExternalServiceIdUnique = async(req: SaasCheckUniqueRecurringSvcRequest): 
Promise<RecurringSvcUniqueCheckResponse> => {
  const response = await httpCustomerCore.post<RecurringSvcUniqueCheckResponse>(
    `/self-managed/svc-shell/validate-unique-recurring`, { 
      ...req 
    },
  );
  return response.data;
};

export async function createRecurringService(req: RecurringServiceRequest): Promise<void> {
  const response = await httpCustomerCore.post<void>(
    `/self-managed/svc-shell/create-recurring`, {
      ...req
    },
  );
  return response.data;
}

export async function updateRecurringService(req: RecurringServiceRequest): Promise<void> {
  const response = await httpCustomerCore.put<void>(
    `/self-managed/svc-shell/update-recurring`, {
      ...req
    },
  );
  return response.data;
}

export async function checkUpdateRecurringService(req: RecurringServiceRequest): 
Promise<CheckRecurringServiceUpdateResponse> {
  const response = await httpCustomerCore.post<CheckRecurringServiceUpdateResponse>(
    `/self-managed/svc-shell/prevalidate-recurring`, {
      ...req
    },
  );
  return response.data;
}

export async function createSeasonalService(req: SeasonalServiceRequest): Promise<void> {
  const response = await httpCustomerCore.patch<void>(
    `/self-managed/svc-shell/create-seasonal`, {
      ...req
    },
  );
  return response.data;
}

export async function checkCreateSeasonalService(req: SeasonalServiceRequest): 
Promise<CheckRecurringServiceUpdateResponse> {
  const response = await httpCustomerCore.post<CheckRecurringServiceUpdateResponse>(
    `/self-managed/svc-shell/prevalidate-seasonal`, {
      ...req
    },
  );
  return response.data;
}
