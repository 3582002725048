export enum BillingRoutes {
  root = '/billing',
  summary = '/summary',
  paymentSetup = '/payment-setup',
  makePayment = '/make-payment',
  completePayment = '/complete-payment',
}

export function buildCompletePayment(custId: string, siteId: string): string {
  return `${BillingRoutes.root + BillingRoutes.completePayment}?custId=${custId}&siteId=${siteId}`;
}
