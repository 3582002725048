import update from 'immutability-helper';

import * as account from 'account/services/account';
import { ActionDispatcher, RegisterAction } from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  RegisterState,
} from 'contracts/types/state';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Actions Keys
const ROOT_KEY = 'account/register';
enum ActionKey {
  REGISTER = 'account/register/REGISTER',
  SET_PASSWORD = 'account/register/SET_PASSWORD',
  VALIDATE_TOKEN = 'account/register/VALIDATE_TOKEN',
  SEND_REGISTRATION_EMAIL = 'account/register/SEND_REGISTRATION_EMAIL',
  RESET = 'account/register/RESET',
}

// Initial State
const getInitialState: () => RegisterState = () => {
  return {
    errorMessage: undefined,
    isTokenValid: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.REGISTER,
  ActionKey.SET_PASSWORD,
  ActionKey.VALIDATE_TOKEN,
  ActionKey.SEND_REGISTRATION_EMAIL,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RegisterState,
  RegisterAction
> = {
  [ActionKey.REGISTER]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
  [ActionKey.SET_PASSWORD]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
  [ActionKey.VALIDATE_TOKEN]: (state, action) => {
    const { isTokenValid } = action;
    return update(state, { $merge: { isTokenValid } });
  },
  [ActionKey.SEND_REGISTRATION_EMAIL]: (state, action) => {
    const { errorMessage } = action;
    return update(state, { $merge: { errorMessage } });
  },
};

export const reducer = getReducerBuilder<RegisterState, RegisterAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  REGISTER: (errorMessage?: string): RegisterAction => ({
    type: ActionKey.REGISTER,
    errorMessage,
  }),
  SET_PASSWORD: (errorMessage?: string): RegisterAction => ({
    type: ActionKey.SET_PASSWORD,
    errorMessage,
  }),
  VALIDATE_TOKEN: (
    isTokenValid?: boolean,
    errorMessage?: string,
  ): RegisterAction => ({
    type: ActionKey.VALIDATE_TOKEN,
    isTokenValid,
    errorMessage,
  }),
  SEND_REGISTRATION_EMAIL: (errorMessage: string): RegisterAction => ({
    type: ActionKey.SEND_REGISTRATION_EMAIL,
    errorMessage,
  }),
  RESET: (): RegisterAction => ({ type: ActionKey.RESET }),
};

// Thunks
const register = (
  emailAddress: string,
  activationCode: string,
  password: string,
  passwordConfirmation: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REGISTER,
    () =>
      account.register(
        emailAddress,
        activationCode,
        password,
        passwordConfirmation,
      ),
    () => {
      dispatch(actionMap.REGISTER());
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.REGISTER(errorMessage));
    },
  );

const setPassword = (
  emailAddress: string,
  hashKey: string,
  password: string,
  passwordConfirmation: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) => new Promise((resolve, reject) => {
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SET_PASSWORD,
    () => account.validateRegistrationHashKey(emailAddress, hashKey),
    result => {
      if (!result.isValid) {
        dispatch(actionMap.SET_PASSWORD(result.message));
        reject();
      } else {
        runTakeLastThunk(
          dispatch,
          getState,
          ActionKey.SET_PASSWORD,
          () =>
            account.setPassword(
              emailAddress,
              password,
              passwordConfirmation,
              hashKey,
            ),
          () => {
            dispatch(actionMap.SET_PASSWORD());
            resolve(true);
          },
          err => {
            const errorMessage = err.response
              ? err.response.data.message
              : translate('core.anErrorOccurredLoadingThisPage');
            dispatch(actionMap.SET_PASSWORD(errorMessage));
            reject();
          },
        );
      }
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.SET_PASSWORD(errorMessage));
      reject();
    },
  );
});

const checkRegisterToken = (email: string, token: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.VALIDATE_TOKEN,
    () => account.validateRegisterKey(email, token),
    result => {
      const { isTokenValid } = result;
      dispatch(actionMap.VALIDATE_TOKEN(isTokenValid));
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.VALIDATE_TOKEN(undefined, errorMessage));
    },
  );

const sendRegistrationSetupEmail = (emailAddress: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SEND_REGISTRATION_EMAIL,
    () => account.sendRegistrationSetupEmail(emailAddress),
    () => {
      dispatch(actionMap.SEND_REGISTRATION_EMAIL(''));
    },
    err => {
      const errorMessage = err.response
        ? err.response.data.message
        : translate('core.anErrorOccurredLoadingThisPage');
      dispatch(actionMap.SEND_REGISTRATION_EMAIL(errorMessage));
    },
    true,
  );
    
const registerDuck = {
  thunks: { register, setPassword, checkRegisterToken, sendRegistrationSetupEmail },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};

export { registerDuck as default };
