/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { getSessionPageRefreshed, setSessionPageRefreshed } from 'account/services/session';

const lazy = <T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>): 
React.LazyExoticComponent<T> =>
    React.lazy(async() => {
      const pageHasAlreadyBeenForceRefreshed = getSessionPageRefreshed() || false;

      try {
        const component = await factory();
        setSessionPageRefreshed();

        return component;
      } catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
          setSessionPageRefreshed(true);
          window.location.reload();
        }

        // The page has already been reloaded
        // Assuming that user is already using the latest version of the application.
        // Let's let the application crash and raise the error.
        throw error;
      }
    });
export default lazy;
