import MaterialManagementComparison, { IMaterialManagementComparison } from 'contracts/models/service/MaterialManagementComparison';
import MaterialManagementSiteComparison, { IMaterialManagementSiteComparison } from 'contracts/models/service/MaterialManagementSiteComparison';
import MaterialManagementSummary, { IMaterialManagementSummary } from 'contracts/models/service/MaterialManagementSummary';
import MaterialManagementTableData, { IMaterialManagementTableData } from 'contracts/models/service/MaterialManagementTableData';
import MaterialProfileComparison, { IMaterialProfileComparison } from 'contracts/models/service/MaterialProfileComparison';
import MaterialProfileSiteComparison, { IMaterialProfileSiteComparison } from 'contracts/models/service/MaterialProfileSiteComparison';
import MaterialProfileSummary, { IMaterialProfileSummary } from 'contracts/models/service/MaterialProfileSummary';
import MaterialProfileTableData, { IMaterialProfileTableData } from 'contracts/models/service/MaterialProfileTableData';
import { MaterialManagementRequest, MaterialProfileRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getMaterialProfileSummary({
  fromDate,
  toDate,
  reportFilters,
  categoryId,
  siteIdentifier,
}: MaterialProfileRequest): Promise<MaterialProfileSummary> {
  const response = await httpCustomerCore.post('material-profile/summary', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    CategoryId: categoryId,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialProfileSummary,
      MaterialProfileSummary
    >(MaterialProfileSummary),
  },
  );
  const { data } = response;
  return data;
}

export async function getMaterialProfileComparison({
  fromDate,
  toDate,
  reportFilters,
  categoryId,
  siteIdentifier,
}: MaterialProfileRequest): Promise<MaterialProfileComparison> {
  const response = await httpCustomerCore.post('material-profile/comparison', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    CategoryId: categoryId,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialProfileComparison,
      MaterialProfileComparison
    >(MaterialProfileComparison),
  });
  const { data } = response;
  return data;
}

export async function getMaterialProfileComparisonSite({
  fromDate,
  toDate,
  reportFilters,
  categoryId,
  siteIdentifier,
}: MaterialProfileRequest): Promise<MaterialProfileSiteComparison> {
  const response = await httpCustomerCore.post('material-profile/site-comparison', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    CategoryId: categoryId,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialProfileSiteComparison,
      MaterialProfileSiteComparison
    >(MaterialProfileSiteComparison),
  });
  const { data } = response;
  return data;
}

export async function getMaterialProfileTable({
  fromDate,
  toDate,
  reportFilters,
  categoryId,
  siteIdentifier,
}: MaterialProfileRequest): Promise<MaterialProfileTableData[]> {
  const response = await httpCustomerCore.post(
    'material-profile/table-report',
    {
      FromDate: fromDate,
      ToDate: toDate,
      ReportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      CategoryId: categoryId,
      SiteId: siteIdentifier ? siteIdentifier.siteId : null,
      SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
    },
    {
      transformResponse: transformServiceResponse<
        IMaterialProfileTableData,
        MaterialProfileTableData
      >(MaterialProfileTableData),
    },
  );
  const { data } = response;
  return data;
}

export async function getMaterialProfileCsv({
  fromDate,
  toDate,
  reportFilters,
  categoryId,
  siteIdentifier,
}: MaterialProfileRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'material-profile/generate-csv',
    {
      FromDate: fromDate,
      ToDate: toDate,
      ReportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      CategoryId: categoryId,
      SiteId: siteIdentifier ? siteIdentifier.siteId : null,
      SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
    },
  );
  return response;
}

export async function getMaterialManagementSummary({
  fromDate,
  toDate,
  reportFilters,
  disposalMethodType,
  siteIdentifier
}: MaterialManagementRequest): Promise<MaterialManagementSummary> {
  const response = await httpCustomerCore.post('material-profile/management/summary', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    DisposalMethodType: disposalMethodType,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialManagementSummary,
      MaterialManagementSummary
    >(MaterialManagementSummary),
  },
  );
  const { data } = response;
  return data;
}

export async function getMaterialManagementComparison({
  fromDate,
  toDate,
  reportFilters,
  disposalMethodType,
  siteIdentifier
}: MaterialManagementRequest): Promise<MaterialManagementComparison> {
  const response = await httpCustomerCore.post('material-profile/management/comparison', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    DisposalMethodType: disposalMethodType,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialManagementComparison,
      MaterialManagementComparison
    >(MaterialManagementComparison),
  });
  const { data } = response;
  return data;
}

export async function getMaterialManagementComparisonSite({
  fromDate,
  toDate,
  reportFilters,
  disposalMethodType,
  siteIdentifier
}: MaterialManagementRequest): Promise<MaterialManagementSiteComparison> {
  const response = await httpCustomerCore.post('material-profile/management/site-comparison', {
    FromDate: fromDate,
    ToDate: toDate,
    ReportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    DisposalMethodType: disposalMethodType,
    SiteId: siteIdentifier ? siteIdentifier.siteId : null,
    SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
  },
  {
    transformResponse: transformServiceResponse<
      IMaterialManagementSiteComparison,
      MaterialManagementSiteComparison
    >(MaterialManagementSiteComparison),
  });
  const { data } = response;
  return data;
}

export async function getMaterialManagementTable({
  fromDate,
  toDate,
  reportFilters,
  disposalMethodType,
  siteIdentifier,
}: MaterialManagementRequest): Promise<MaterialManagementTableData[]> {
  const response = await httpCustomerCore.post(
    'material-profile/management/table-report',
    {
      FromDate: fromDate,
      ToDate: toDate,
      ReportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      DisposalMethodType: disposalMethodType,
      SiteId: siteIdentifier ? siteIdentifier.siteId : null,
      SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
    },
    {
      transformResponse: transformServiceResponse<
        IMaterialManagementTableData,
        MaterialManagementTableData
      >(MaterialManagementTableData),
    },
  );
  const { data } = response;
  return data;
}

export async function getMaterialManagementCsv({
  fromDate,
  toDate,
  reportFilters,
  disposalMethodType,
  siteIdentifier,
}: MaterialManagementRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'material-profile/management/generate-csv',
    {
      FromDate: fromDate,
      ToDate: toDate,
      ReportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        IsTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        IsPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      DisposalMethodType: disposalMethodType,
      SiteId: siteIdentifier ? siteIdentifier.siteId : null,
      SiteCustomerId: siteIdentifier ? siteIdentifier.siteCustomerId : null,
    },
  );
  return response;
}
