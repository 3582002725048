import React, { Component, CSSProperties } from 'react';

import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

class VirtualList<T> extends Component<ComponentProps<T>> {
  rowRenderer = ({ index, style, data }: ListChildProps<T>): JSX.Element => {
    const { rowItemTemplate, listPadding, rowPadding } = this.props;
    const elementStyle = { ...style };
    if (listPadding) {
      elementStyle.top = (style.top as number) + listPadding;
      elementStyle.paddingLeft = `${listPadding}px`;
      elementStyle.paddingRight = `${listPadding}px`;
    }
    if (rowPadding) {
      elementStyle.paddingBottom = `${rowPadding}px`;
    }
    return <div style={elementStyle}>{rowItemTemplate(data[index])}</div>;
  };

  render(): React.ReactNode {
    const {
      items = [],
      rowHeight = 0,
      width = 0,
      height = 0,
      rowPadding = 0,
      containerStyle,
    } = this.props;

    return (
      <List
        itemData={items}
        itemCount={items.length}
        itemSize={rowHeight + rowPadding}
        width={width}
        height={height}
        style={containerStyle}
      >
        {this.rowRenderer}
      </List>
    );
  }
}

interface ComponentProps<T> {
  items: T[];
  rowItemTemplate: (item: T) => JSX.Element;
  rowHeight: number;
  height: number;
  width?: number | string;
  listPadding?: number;
  rowPadding?: number;
  containerStyle?: CSSProperties;
}

interface ListChildProps<T> extends ListChildComponentProps {
  data: T[];
}

export default VirtualList;
