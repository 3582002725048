import ContainerSizingSortKey from 'contracts/enums/ContainerSizingSortKey';
import ContainerSizingReport, { ContainerSizingReportRequestArg, IContainerSizingReport } from 'contracts/models/ContainerSizingReport';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getContainerSizingReport({
  startDate,
  endDate,
  sortFields,
  filters,
}: ContainerSizingReportRequestArg): Promise<ContainerSizingReport> {
  const inputArg = {
    startDate,
    endDate,
    filters,
    orderByAscending: buildSortArg(
      sortFields.ascending,
      ContainerSizingSortKey,
    ),
    orderByDescending: buildSortArg(
      sortFields.descending,
      ContainerSizingSortKey,
    ),
  };

  const response = await httpCustomerCore.post<ContainerSizingReport>(
    'partner-data/right-sizing/report-table',
    inputArg,
    {
      transformResponse: transformServiceResponse<
      IContainerSizingReport,
      ContainerSizingReport
      >(ContainerSizingReport),
    },
  );
  return response.data;
}

export async function getContainerSizingReportCsv({
  startDate,
  endDate,
  filters,
}: ContainerSizingReportRequestArg): Promise<GetCsvResponse> {
  const inputArg = {
    startDate,
    endDate,
    filters
  };
  const response = await getCsv(
    httpCustomerCore,
    'partner-data/right-sizing/csv',
    inputArg,
  );
  return response;
}
