import { DateRange } from 'contracts/types/service';

export interface CarbonEmissionsComparisonItem {
  name: string;
  identifier: string;
  co2Emissions: number[];
}

export interface ICarbonEmissionsComparison {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly co2EmissionsEntries: CarbonEmissionsComparisonItem[];
}

class CarbonEmissionsComparison {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  co2EmissionsEntries?: CarbonEmissionsComparisonItem[];

  constructor(data?: Partial<ICarbonEmissionsComparison>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.co2EmissionsEntries = data?.co2EmissionsEntries;
  }
}

export default CarbonEmissionsComparison;
