import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import {
  RequestServiceResult,
  ServiceAvailability,
  ServiceEquipmentRate,
} from 'contracts/models';
import {
  ActionDispatcher,
  ServiceRequestEventsAction,
} from 'contracts/types/action';
import {
  CheckRequestDuplicateArg,
  RequestServiceRequest,
  RequestServiceUpdateRequest,
} from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceRequestEventsState,
} from 'contracts/types/state';
import {
  createNotificationMessage,
  createTimedNotificationMessage,
} from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getServiceFees,
  getServiceRequestAvailability,
  sendServiceRequest,
  updateServiceRequest,
} from '../services/serviceRequestEventsServices';

// Actions Keys
const ROOT_KEY = 'services/serviceRequestEvents';
enum ActionKey {
  SERVICE_FEES = 'services/serviceRequestEvents/SERVICE_FEES',
  SERVICE_REQUEST = 'services/serviceRequestEvents/SERVICE_REQUEST',
  CLOSE_SERVICE_CONFIRMATION = 'services/serviceRequestEvents/CLOSE_SERVICE_CONFIRMATION',
  SERVICE_REQUEST_AVAILABILITY = 'services/serviceRequestEvents/SERVICE_REQUEST_AVAILABILITY',
}

// Initial state
const getInitialState: () => ServiceRequestEventsState = () => {
  return {
    serviceFees: [],
    serviceRequestResults: undefined,
    ServiceAvailability: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.SERVICE_FEES,
  ActionKey.SERVICE_REQUEST,
  ActionKey.CLOSE_SERVICE_CONFIRMATION,
  ActionKey.SERVICE_REQUEST_AVAILABILITY,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceRequestEventsState,
  ServiceRequestEventsAction
> = {
  [ActionKey.SERVICE_FEES]: (state, action) => {
    return update(state, {
      $merge: {
        serviceFees: action.serviceFees,
      },
    });
  },
  [ActionKey.SERVICE_REQUEST]: (state, action) => {
    return update(state, {
      $merge: {
        serviceRequestResults: action.serviceRequestResults,
      },
    });
  },
  [ActionKey.CLOSE_SERVICE_CONFIRMATION]: (state, action) => {
    const resultsToRemove = action.serviceRequestResults;
    if (resultsToRemove && resultsToRemove.length > 0) {
      return update(state, {
        serviceRequestResults: { $set: [] },
      });
    }
    return state;
  },
  [ActionKey.SERVICE_REQUEST_AVAILABILITY]: (state, action) => {
    return update(state, {
      $merge: {
        serviceAvailability: action.serviceAvailability,
      },
    });
  },
};

export const reducer = getReducerBuilder<
  ServiceRequestEventsState,
  ServiceRequestEventsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const actionMap = {
  SERVICE_FEES: (
    serviceFees: ServiceEquipmentRate[] = [],
  ): ServiceRequestEventsAction => ({
    type: ActionKey.SERVICE_FEES,
    serviceFees,
  }),
  SERVICE_REQUEST: (
    serviceRequestResults?: RequestServiceResult[],
  ): ServiceRequestEventsAction => ({
    type: ActionKey.SERVICE_REQUEST,
    serviceRequestResults,
  }),
  CLOSE_SERVICE_CONFIRMATION: (
    serviceRequestResult: RequestServiceResult[],
  ): ServiceRequestEventsAction => ({
    type: ActionKey.CLOSE_SERVICE_CONFIRMATION,
    serviceRequestResults: serviceRequestResult,
  }),
  SERVICE_REQUEST_AVAILABILITY: (
    serviceAvailability: ServiceAvailability,
  ): ServiceRequestEventsAction => ({
    type: ActionKey.SERVICE_REQUEST_AVAILABILITY,
    serviceAvailability,
  }),
};

// API calls
const sendRequestCall = (data: RequestServiceRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_REQUEST,
    () => sendServiceRequest(data),
    response => {
      dispatch(actionMap.SERVICE_REQUEST(response));
      if (response && response.length) {
        response.forEach(serviceRequestResult => {
          if (serviceRequestResult.warningMessage) {
            dispatch(
              createNotificationMessage(
                NotificationType.Warning,
                serviceRequestResult.warningMessage,
              ),
            );
          }
        });
      }
    },
    () => {
      dispatch(actionMap.SERVICE_REQUEST());
    },
    true,
  );

const updateServiceOrder = (data: RequestServiceUpdateRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_REQUEST,
    () => updateServiceRequest(data),
    response => {
      dispatch(actionMap.SERVICE_REQUEST(response ? [response] : undefined));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Your request was updated successfully!',
          3000,
        ),
      );
    },
    () => {
      dispatch(actionMap.SERVICE_REQUEST());
    },
    true,
  );

const loadServiceFees = (serviceId: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_FEES,
    () => getServiceFees(serviceId),
    serviceFees => {
      dispatch(actionMap.SERVICE_FEES(serviceFees));
    },
    () => actionMap.SERVICE_FEES(),
  );

const loadServiceRequestAvailability = (data: CheckRequestDuplicateArg) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_REQUEST_AVAILABILITY,
    () => getServiceRequestAvailability(data),
    availabilityResponse => {
      dispatch(actionMap.SERVICE_REQUEST_AVAILABILITY(availabilityResponse));
    },
    () => { },
  );

const serviceRequestEventsDuck = {
  thunks: {
    sendRequestCall,
    updateServiceOrder,
    loadServiceFees,
    loadServiceRequestAvailability,
  },
  actionKeys: ActionKey,
  actions: {
    closeServiceConfirmation: actionMap.CLOSE_SERVICE_CONFIRMATION,
    setServiceOrder: actionMap.SERVICE_REQUEST,
  },
};

export default serviceRequestEventsDuck;
