import update from 'immutability-helper';

import { ServiceRequestCustomerFilesTableData } from 'contracts/models';
import ServiceRequestDetails from 'contracts/models/service/ServiceRequestDetails';
import {
  ActionDispatcher,
  ServiceRequestDetailsAction,
} from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceRequestDetailsState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getServiceRequestDetails,
  addNote,
  getServiceRequestsFiles,
} from '../services/serviceRequestDetails';

// Action Keys
const ROOT_KEY = 'services/serviceRequestDetails';
enum ActionKey {
  SERVICE_REQUEST_DETAILS = 'services/serviceRequestDetails/SERVICE_REQUEST_DETAILS',
  ADDING_NOTE = 'services/serviceRequestDetails/ADDING_NOTE',
  SERVICE_REQUESTS_FILES = 'services/serviceRequestDetails/SERVICE_REQUESTS_FILES',
  RESET = 'services/serviceRequestDetails/RESET',
}

// Initial State
const getInitialState: () => ServiceRequestDetailsState = () => {
  return {
    serviceRequestDetails: {},
    serviceRequestCustomerFilesTableData: []
  };
};

// Reducer
const reducerKeys = [ActionKey.SERVICE_REQUEST_DETAILS, ActionKey.SERVICE_REQUESTS_FILES] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceRequestDetailsState,
  ServiceRequestDetailsAction
> = {
  [ActionKey.SERVICE_REQUEST_DETAILS]: (state, action) => {
    const { serviceRequestDetails } = action;
    return update(state, { $merge: { serviceRequestDetails } });
  },
  [ActionKey.SERVICE_REQUESTS_FILES]: (state, action) => {
    const { serviceRequestCustomerFilesTableData } = action;
    return update(state, { $merge: { serviceRequestCustomerFilesTableData } });
  },
};

export const reducer = getReducerBuilder<
  ServiceRequestDetailsState,
  ServiceRequestDetailsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  SERVICE_REQUEST_DETAILS: (
    serviceRequestDetails?: ServiceRequestDetails,
  ): ServiceRequestDetailsAction => ({
    type: ActionKey.SERVICE_REQUEST_DETAILS,
    serviceRequestDetails,
  }),
  ADDING_NOTE: (): ServiceRequestDetailsAction => ({
    type: ActionKey.ADDING_NOTE,
  }),
  SERVICE_REQUESTS_FILES: 
  (serviceRequestCustomerFilesTableData?: ServiceRequestCustomerFilesTableData[]): ServiceRequestDetailsAction => ({
    type: ActionKey.SERVICE_REQUESTS_FILES,
    serviceRequestCustomerFilesTableData,
  }),
  RESET: (): ServiceRequestDetailsAction => ({ type: ActionKey.RESET }),
};

// API calls
// TODO: Maybe ordernumber parameter name must be change into workorder number
const loadServiceRequestDetails = (
  orderNumber: number,
  serviceProcessor: string,
  scheduledDate: string,
  serviceOrder: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_REQUEST_DETAILS,
    () =>
      getServiceRequestDetails(
        orderNumber,
        serviceProcessor,
        scheduledDate,
        serviceOrder,
      ),
    serviceRequestDetailsResult => {
      dispatch(actionMap.SERVICE_REQUEST_DETAILS(serviceRequestDetailsResult));
    },
    () => {
      dispatch(actionMap.SERVICE_REQUEST_DETAILS());
    },
  );

const saveNote = (
  orderNumber: number,
  note: string,
  orderStatus: string,
  scheduledDate: string,
  serviceProcessor: string
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ADDING_NOTE,
    () => addNote(orderNumber, note, orderStatus, scheduledDate, serviceProcessor),
    () => {
      dispatch(actionMap.ADDING_NOTE());
    },
    () => {},
  );

const loadServiceRequestsFiles = (serviceOrder: number, serviceProcessor: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SERVICE_REQUESTS_FILES,
    () => getServiceRequestsFiles(serviceOrder, serviceProcessor),
    result => dispatch(actionMap.SERVICE_REQUESTS_FILES(result)),
    () => dispatch(actionMap.SERVICE_REQUESTS_FILES()),
  );

export const serviceRequestDetailsDuck = {
  thunks: {
    loadServiceRequestDetails,
    saveNote,
    loadServiceRequestsFiles
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
