import { TableData } from 'contracts/types/component';

export interface IPayment {
  readonly invoiceNumber: string;
  readonly paymentAmount: number;
  readonly invoiceTotal: number;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly paymentDate: string;
  readonly paymentStatus: string;
  readonly zuoraPaymentStatus: string;
  readonly zuoraInvoiceId: string;
}

class Payment implements TableData {
  invoiceNumber?: string;
  paymentAmount?: number;
  invoiceTotal?: number;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  paymentDate?: string;
  paymentStatus?: string;
  zuoraPaymentStatus?: string;
  zuoraInvoiceId?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IPayment>) {
    this.invoiceNumber = data?.invoiceNumber;
    this.paymentAmount = data?.paymentAmount;
    this.invoiceTotal = data?.invoiceTotal;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.paymentDate = data?.paymentDate;
    this.paymentStatus = data?.paymentStatus;
    this.zuoraPaymentStatus = data?.zuoraPaymentStatus;
    this.zuoraInvoiceId = data?.zuoraInvoiceId;
  }
}

export default Payment;
