import styled from 'styled-components';

interface SpacingProps {
  size: number;
}
const Spacing = styled.div<SpacingProps>`
  margin-top: ${props => `${props.size || 0}px`};
`;

export default Spacing;
