import moment, { Moment } from 'moment';
import { Modifier, DaysOfWeekModifier, RangeModifier, BeforeModifier, AfterModifier } from 'react-day-picker';

export default function formatDate(dateString?: string): string | undefined {
  return dateString
    ? new Date(dateString).toLocaleDateString('en-US')
    : undefined;
}

export function formatNotificationDate(
  dateString?: string,
): string | undefined {
  return dateString
    ? moment.utc(dateString).local().format('MM/DD/YYYY')
    : undefined;
}

export function formatHour(dateString: string): string | null {
  return dateString ? moment(dateString).format('LT') : null;
}

export function getFirstValidDay(
  day: Moment,
  disabledDays: Modifier[],
): Moment {
  if (disabledDays && disabledDays.length) {
    for (let index = 0; index < disabledDays.length; index++) {
      if (disabledDays[index] instanceof Date) {
        const disabledDay = moment(disabledDays[index] as Date);
        if (disabledDay.isSame(day)) {
          return getFirstValidDay(day.add(1, 'days'), disabledDays);
        }
      } else if ((disabledDays[index] as DaysOfWeekModifier).daysOfWeek) {
        for (
          let indexD = 0;
          indexD <
          (disabledDays[index] as DaysOfWeekModifier).daysOfWeek.length;
          indexD++
        ) {
          if (
            day.weekday() ===
            (disabledDays[index] as DaysOfWeekModifier).daysOfWeek[indexD]
          ) {
            return getFirstValidDay(day.add(1, 'days'), disabledDays);
          }
        }
      }
    }
  }
  return day;
}

export function getFirstDisabledUpcomingDay(
  day: Moment,
  disabledDays: Modifier[],
): number | undefined {
  let smallestDifference = 99999999999;
  if (disabledDays && disabledDays.length) {
    for (let index = 0; index < disabledDays.length; index++) {
      if ( disabledDays[index] && (disabledDays[index] as RangeModifier).from ) {
        const range = disabledDays[index] as RangeModifier;
        if (range.from) {
          const from = moment(range?.from);
          const difference = from.diff(day, 'days');
          if (difference > 0 && difference < smallestDifference) {
            smallestDifference = difference;
          }
        }

      }
    }
  }
  if (smallestDifference === 99999999999 ) {
    return undefined;
  } else {
    return smallestDifference;
  }
}

export function getBeforeDisabledDay(
  disabledDays: Modifier[],
): Date | undefined {
  const beforeRange = disabledDays.filter( day => 
    day && (day as BeforeModifier).before)[0];

  if (beforeRange) {
    return (beforeRange as BeforeModifier).before;
  } else {
    return undefined;
  }
}

export function getAfterDisabledDay(
  disabledDays: Modifier[],
): Date | undefined {
  const afterRange = disabledDays.filter( day => 
    day && (day as AfterModifier).after)[0];

  if (afterRange) {
    return (afterRange as AfterModifier).after;
  } else {
    return undefined;
  }
}
