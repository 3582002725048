import { HaulerBillConsolidationModel, HaulerBillConsolidationVendorModel, IHaulerBillConsolidationModel } from 'contracts/models/service/HaulerBillConsolidation';
import {
  HaulerBillConsolidationPayInvoiceRequest,
  HaulerBillConsolidationReportIssueRequest,
  HaulerBillConsolidationRequest
} from 'contracts/types/request';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getInvoicesList(request: HaulerBillConsolidationRequest): Promise<HaulerBillConsolidationModel> {
  const response = await httpCustomerCore.post<HaulerBillConsolidationModel>(
    'hauler-bill-consolidation/list',
    {
      FromDate: request.fromDate,
      ToDate: request.toDate,
      PageNumber: request.pageNumber,
      PageSize: request.pageSize,
      SiteFilters: {
        ...getLocationFilterApiArg(request.siteFilters),
        PaymentStatuses: request.siteFilters
          ? request.siteFilters.paymentStatusTypes || []
          : [],
        VendorCodes: request.siteFilters
          ? request.siteFilters.vendorCodes || []
          : [],
      }
    },
    {
      transformResponse: transformServiceResponse<
        IHaulerBillConsolidationModel,
        HaulerBillConsolidationModel
      >(HaulerBillConsolidationModel),
    },
  );

  return response.data;
}

export async function getVendors(): Promise<HaulerBillConsolidationVendorModel[]> {
  const response = await httpCustomerCore.post(`hauler-bill-consolidation/vendors`);
  return response.data;
}

export async function markPaid(request: HaulerBillConsolidationPayInvoiceRequest): Promise<boolean> {
  const response = await httpCustomerCore.put<boolean>('hauler-bill-consolidation/mark-paid', request);
  return response.data;
}

export async function reportIssue(request: HaulerBillConsolidationReportIssueRequest): Promise<boolean> {
  const response = await httpCustomerCore.put<boolean>('hauler-bill-consolidation/report-issue', request);
  return response.data;
}

export async function downloadBill(invoiceId: number): Promise<string> {
  const response = await httpCustomerCore.get(`hauler-bill-consolidation/download-invoice/${invoiceId}`);
  return response.data;
}
