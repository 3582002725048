export interface IRequestNewServiceResult {
  readonly svcId: number;
  readonly svcOrd: string;
  readonly workOrder: number;
  readonly warningMessage: string;
  readonly needApproval: boolean;
}

class RequestNewServiceResult {
  svcId?: number;
  svcOrd?: string;
  workOrder?: number;
  warningMessage?: string;
  needApproval?: boolean;

  constructor(data?: Partial<IRequestNewServiceResult>) {
    this.svcId = data?.svcId;
    this.svcOrd = data?.svcOrd;
    this.workOrder = data?.workOrder;
    this.warningMessage = data?.warningMessage;
    this.needApproval = data?.needApproval;
  }
}

export default RequestNewServiceResult;
