import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const NotificationContainer = styled.span`
  line-height: 22px;

  > a {
    text-decoration: none;
    color: -webkit-link;
  }
`;

export const NotificationHeaderButtons = styled.div`
  margin-left: auto;
`;

export const NotificationHeaderFilters = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const NotificationLocations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
