import Franchise from 'contracts/models/service/Franchise';
import { FranchiseResponseModel } from 'contracts/types/service';
import { httpCustomerCore } from 'core/services/http';

const checkFranchise = async(params: Franchise): Promise<FranchiseResponseModel> => {
  const response = await httpCustomerCore.post('wholesale/franchise', {
    standardizedAddress: params.standardizedAddress,
    zipCode: params.zipCode,
  });
  return response.data;
};

export default checkFranchise;
