import React, { InputHTMLAttributes } from 'react';

import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled';

const Checkbox: React.FC<ComponentProps> = ({
  label,
  size,
  checkboxLabelLineCount,
  ...input
}) => {
  const childInput = input.input || input;

  if (childInput.checked === undefined) {
    childInput.checked = childInput.value;
  }

  return (
    <CheckboxContainer isDisabled={input.disabled}>
      <CheckboxInput {...childInput} type='checkbox' />
      <CheckboxCheck />
      {label ? (
        <CheckboxText size={size} checkboxLabelLineCount={checkboxLabelLineCount}>
          {label}
        </CheckboxText>
      ) : null}
    </CheckboxContainer>
  );
};

interface OwnProps {
  label?: string;
  size?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input?: any;
  checkboxLabelLineCount?: number;
}

type ComponentProps = OwnProps & InputHTMLAttributes<HTMLInputElement>;

export default Checkbox;
