import React, { PropsWithChildren, Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation, Path } from 'react-router-dom';

import { AccountRoutes } from 'account/routing';
import { ApplicationState } from 'contracts/types/state';
import { PageLoading } from 'core/components/styled';

const GuestRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const isLoggedIn = useSelector((state: ApplicationState) => state.account.login.isLoggedIn);
  const { state } = useLocation();
  const redirectPath = state && (state as Path).pathname ? (state as Path).pathname : '/';
  const redirectTo = redirectPath !== AccountRoutes.root + AccountRoutes.logout ? redirectPath : '/';
  return isLoggedIn ? <Navigate to={redirectTo} /> : <Suspense fallback={<PageLoading />}>{children}</Suspense>;
};

export default GuestRoute;
