import { OptimizationReportSortKey } from 'contracts/enums';
import OptimizationReport, {
  IOptimizationReport,
} from 'contracts/models/service/OptimizationReport';
import {
  LoadOptimizationReportArgs,
  OptimizationReportFilters,
  OptimizationReportRequestArg,
} from 'contracts/types/compactorProfile';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getOptimizationReport({
  fromDate,
  toDate,
  sortFields,
  optimizationFilter,
}: LoadOptimizationReportArgs): Promise<OptimizationReport> {
  const inputArg: OptimizationReportRequestArg = {
    fromDate,
    toDate,
    filters: {
      ...getLocationFilterApiArg(optimizationFilter),
      materialCategories: optimizationFilter && optimizationFilter.materials,
      equipments: optimizationFilter ? optimizationFilter.equipments || [] : [],
      size: optimizationFilter &&
        optimizationFilter.sizeRange && {
        from: optimizationFilter.sizeRange.from,
        to: optimizationFilter.sizeRange.to,
      },
    } as OptimizationReportFilters,
    orderByAscending: buildSortArg(
      sortFields.ascending,
      OptimizationReportSortKey,
    ),
    orderByDescending: buildSortArg(
      sortFields.descending,
      OptimizationReportSortKey,
    ),
  };

  const response = await httpCustomerCore.post<OptimizationReport>(
    'optimization/report-table',
    inputArg,
    {
      transformResponse: transformServiceResponse<
        IOptimizationReport,
        OptimizationReport
      >(OptimizationReport),
    },
  );
  return response.data;
}

export async function getOptimizationReportCsv({
  fromDate,
  toDate,
  optimizationFilter,
}: LoadOptimizationReportArgs): Promise<GetCsvResponse> {
  const inputArg = {
    fromDate,
    toDate,
    filters: {
      ...getLocationFilterApiArg(optimizationFilter),
      materialCategories: optimizationFilter
        ? optimizationFilter.materials || []
        : [],
      equipments: optimizationFilter ? optimizationFilter.equipments || [] : [],
      size:
        optimizationFilter && optimizationFilter.sizeRange
          ? {
            from: optimizationFilter.sizeRange.from,
            to: optimizationFilter.sizeRange.to,
          }
          : null,
    },
  };
  const response = await getCsv(
    httpCustomerCore,
    'optimization/report-table/generate-csv',
    inputArg,
  );
  return response;
}
