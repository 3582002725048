import React, { PropsWithChildren } from 'react';

import { Panel } from 'layout/components/styled';

import {
  LoadingSectionContent,
  LoadingSectionContainer,
} from './styled/LoadingSection';

const defaultText = 'Loading Data';
const LoadText: React.FC<LoadTextProps> = ({ loadText }) => (
  <div style={{ marginTop: 10 }}>{loadText}</div>
);

interface LoadTextProps {
  loadText: string;
}

const SimpleLoadingSpinner: React.FC<PropsWithChildren<ComponentProps>> = ({
  isLoading,
  children,
  loadText = defaultText,
}) =>
  isLoading ? (
    <Panel noBackground={false}>
      <LoadingSectionContainer noBackground>
        <LoadingSectionContent />
        <LoadText loadText={loadText} />
      </LoadingSectionContainer>
    </Panel>
  ) : (
    <>{children}</>
  );

interface ComponentProps {
  isLoading: boolean;
  loadText?: string;
}

export default SimpleLoadingSpinner;
