import { combineReducers } from 'redux';

import { reducer as recognitionMarketingContentReducer } from './recognitionMarketingContent';
import { reducer as recognitionMediaContentReducer } from './recognitionMediaContent';

const reducer = combineReducers({
  mediaContent: recognitionMediaContentReducer,
  marketingContent: recognitionMarketingContentReducer,
});

export default reducer;
