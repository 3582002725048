import React from 'react';

import ReactDOM from 'react-dom';

import App from 'App';
import {
  GoogleAnalyticsScript,
  GoogleTagManagerScript,
} from 'core/components/Scripts';

import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Load App after Google Maps script is loaded
ReactDOM.render(
  <>
    <GoogleTagManagerScript />
    <GoogleAnalyticsScript>
      <App />
    </GoogleAnalyticsScript>
  </>,
  document.getElementById('root'),
);
