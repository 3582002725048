import React, { PureComponent } from 'react';

import memoizeOne from 'memoize-one';

import { LocationFilter, SiteIdentifier } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';
import { DropdownFilterValue } from '../DropdownFilter/DropdownFilterPopup';

const mapStoreIds = memoizeOne<(selectedIds?: SiteIdentifier[]) => string[]>(
  selectedIds => {
    if (!selectedIds || !selectedIds.length) {
      return [];
    }
    return selectedIds.map(
      selectedId => `${selectedId.siteId}-${selectedId.siteCustomerId}`,
    );
  },
);

class LocationStoreFilter extends PureComponent<ComponentProps> {
  render(): React.ReactNode {
    const {
      isLoading,
      isDisabled,
      stores,
      currentFilter,
      onFilterChanged,
    } = this.props;
    return (
      <DropdownFilter
        label={translate('siteName')}
        values={stores}
        selectedIds={mapStoreIds(currentFilter.stores)}
        onFilterChanged={onFilterChanged}
        isLoading={isLoading}
        isDisabled={isDisabled}
        checkboxLabelLineCount={3}
        searchByFilterKey
      />
    );
  }
}

interface ComponentProps {
  isLoading: boolean;
  isDisabled?: boolean;
  stores: Array<DropdownFilterValue<string>>;
  currentFilter: LocationFilter;
  onFilterChanged: (selectedItems: string[]) => void;
}

export default LocationStoreFilter;
