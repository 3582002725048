export interface ITwitterPost {
  readonly text: string;
  readonly url: string;
  readonly profileImage: string;
}

class TwitterPost {
  text?: string;
  url?: string;
  profileImage?: string;

  constructor(data?: Partial<ITwitterPost>) {
    this.text = data?.text;
    this.url = data?.url;
    this.profileImage = data?.profileImage;
  }
}

export default TwitterPost;
