/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ButtonHTMLAttributes, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import DayPicker, { BeforeAfterModifier, DateUtils, DayModifiers, Modifier } from 'react-day-picker';
import MaskedInput from 'react-maskedinput';

import { DatePickerRangeType } from 'contracts/enums';
import { DatePickerOption, DateRange } from 'contracts/types/component';
import { getDateRangePickerDisabledDays, getDateRangePickerOptions } from 'core/helpers/dateRangePickerOptions';

import {
  DateRangePickerOption,
  DateRangePickerOptions,
  DateRangePickerOverlay,
  NewDatePicker as DatePickerContainer
} from './styled';
import {
  DateRangeContainer,
  DateRangeDateOutput,
  DateRangeDatesContainer,
  DateRangeInput
} from './styled/DateRangePicker';

const DateRangePicker: React.FC<ComponentProps> = ({
  inputOptions,
  inputDisabledDays,
  inputValue,
  datePickerRangeType,
  onDateRangeChange,
  disabled,
  ...props
}) => {
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);

  const [options, setOptions] = 
    useState<DatePickerOption[]>([]);
  const [from, setFrom] = useState<Date | undefined>(inputValue && inputValue.from
    ? moment(inputValue.from, 'MM - DD - YYYY').toDate()
    : moment().toDate());
  const [to, setTo] = useState<Date | undefined>(inputValue && inputValue.to
    ? moment(inputValue.to, 'MM - DD - YYYY').toDate()
    : moment().toDate());
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(to);
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [disabledDays, setDisabledDays] = 
    useState<BeforeAfterModifier>(getDateRangePickerDisabledDays(datePickerRangeType));
  
  useEffect(() => {
    setOptions(inputOptions || getDateRangePickerOptions(datePickerRangeType));
    setDisabledDays(getDateRangePickerDisabledDays(datePickerRangeType));
  }, [inputOptions, datePickerRangeType]);

  useEffect(() => {
    if (inputValue) {
      const newFrom = moment(inputValue.from, 'MM - DD - YYYY').toDate();
      const newTo = moment(inputValue.to, 'MM - DD - YYYY').toDate();
    
      setFrom(newFrom);
      setTo(newTo);
      setEnteredTo(newTo);
      setIsOverlayOpen(false);
    }
  }, [inputValue]);

  const resetState = (): void => {
    const from = inputValue && inputValue.from
      ? moment(inputValue.from, 'MM - DD - YYYY').toDate()
      : moment().toDate();
    const to = inputValue && inputValue.to
      ? moment(inputValue.to, 'MM - DD - YYYY').toDate()
      : moment().toDate();
    
    setFrom(from);
    setTo(to);
    setEnteredTo(to);
    setIsOverlayOpen(false);
  };

  const isActiveOption = (option: DatePickerOption): boolean => {
    if (!from || !to) {
      return false;
    }

    return (
      moment(from).isSame(option.value.from, 'day') &&
      moment(to).isSame(option.value.to, 'day')
    );
  };

  const activeOptionShortLabel = (): string => {
    if (options) {
      const applicableOptions = options.filter(isActiveOption);
      if (applicableOptions && applicableOptions.length > 0) {
        return applicableOptions[0].shortLabel;
      }
    }
    return 'Custom';
  };

  const isSelectingFirstDay = (day: Date, from?: Date, to?: Date): boolean => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = !!(from && to);
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const onDayClick = (day: Date, modifiers: DayModifiers): void => {
    if (modifiers.disabled) {
      return;
    }

    if (isSelectingFirstDay(day, from, to)) {
      setFrom(day);
      setTo(undefined);
      setEnteredTo(undefined);
      return;
    }
    setTo(day);
    setEnteredTo(day);
    setIsOverlayOpen(false);

    onDateRangeChange({
      from: moment(from).format('MM - DD - YYYY'),
      to: moment(day).format('MM - DD - YYYY'),
    });
  };

  const onDayMouseEnter = (day: Date): void => {
    if (!isSelectingFirstDay(day, from, to)) {
      setEnteredTo(day);
    }
  };

  const onOptionClick = ({ value: { from, to } }: DatePickerOption): void => {
    setFrom(from);
    setTo(to);
    setEnteredTo(to);
    setIsOverlayOpen(false);

    onDateRangeChange({
      from: moment(from).format('MM - DD - YYYY'),
      to: moment(to).format('MM - DD - YYYY'),
    });
  };

  const onOverlayMouseDown = (event: React.MouseEvent): void => {
    event.preventDefault();
  };

  const openOverlay = (): void => {
    setIsOverlayOpen(true);
  };

  const handleDayChangeFrom = (e: React.FocusEvent<HTMLInputElement>): void => {
    let dateValue = moment(e.target.value, 'MM - DD - YYYY');

    const isValidDate = !e.target.value.includes('_') && dateValue.isValid() && inputValue.from !== e.target.value;
    if (isValidDate) {
      if (moment(dateValue.toDate()).isAfter(to)) {
        dateValue = moment(to);
      } else if (moment(dateValue.toDate()).isBefore(disabledDays.before)) {
        dateValue = moment(disabledDays.before);
      }
      if (dateValue.isValid()) {
        onDateRangeChange({
          from: dateValue.format('MM - DD - YYYY'),
          to: inputValue.to,
        });
      }
    } else {
      dateValue = moment(from);
    }
    if (fromRef.current) {
      fromRef.current.input.value = dateValue.format('MM - DD - YYYY');
      fromRef.current.mask.value = Array.from(dateValue.format('MM - DD - YYYY'));
    }
  };

  const handleDayChangeTo = (e: React.FocusEvent<HTMLInputElement>): void => {
    let dateValue = moment(e.target.value, 'MM - DD - YYYY');

    const isValidDate = !e.target.value.includes('_') && dateValue.isValid() && inputValue.to !== e.target.value;
    if (isValidDate) {
      if (moment(dateValue.toDate()).isBefore(from)) {
        dateValue = moment(from);
      } else if (moment(dateValue.toDate()).isAfter(disabledDays.after)) {
        dateValue = moment(disabledDays.after);
      }
      if (dateValue.isValid()) {
        onDateRangeChange({
          from: inputValue.from,
          to: dateValue.format('MM - DD - YYYY'),
        });
      }
    } else {
      dateValue = moment(to);
    }
    if (toRef.current) {
      toRef.current.input.value = dateValue.format('MM - DD - YYYY');
      toRef.current.mask.value = Array.from(dateValue.format('MM - DD - YYYY'));
    }
  };

  const handleFromKeyEnter = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && fromRef.current) {
      fromRef.current.input.blur();
    }
  };

  const handleToKeyEnter = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && toRef.current) {
      toRef.current.input.blur();
    }
  };

  const modifiers = { start: from, end: enteredTo };
  const selectedDays =
    from && enteredTo ? [from, { from, to: enteredTo }] : [];
  const allDisabledDays: Modifier | Modifier[] = 
    inputDisabledDays ? inputDisabledDays.concat(disabledDays) : disabledDays;

  return (
    <DateRangeContainer>
      <DateRangeInput
        disabled={disabled}
        onClick={openOverlay}
        onFocus={openOverlay}
        onBlur={resetState}
        {...props}
      >
        {activeOptionShortLabel()}
      </DateRangeInput>
      <DateRangeDatesContainer>
        <DateRangeDateOutput onKeyDown={handleFromKeyEnter}>
          <MaskedInput
            mask='11 - 11 - 1111'
            placeholder='MM - DD - YYYY'
            value={inputValue && inputValue.from ? inputValue.from : ''}
            onBlur={handleDayChangeFrom}
            ref={fromRef}
          />
        </DateRangeDateOutput>
        <DateRangeDateOutput onKeyDown={handleToKeyEnter}>
          <MaskedInput
            mask='11 - 11 - 1111'
            placeholder='MM - DD - YYYY'
            value={inputValue && inputValue.to ? inputValue.to : ''}
            onBlur={handleDayChangeTo}
            ref={toRef}
          />
        </DateRangeDateOutput>
      </DateRangeDatesContainer>
      {isOverlayOpen && (
        <DateRangePickerOverlay onMouseDown={onOverlayMouseDown}>
          <DateRangePickerOptions>
            {options.map(option => (
              <DateRangePickerOption
                key={option.label}
                onClick={() => onOptionClick(option)}
                isActive={isActiveOption(option)}
              >
                {option.label}
              </DateRangePickerOption>
            ))}
          </DateRangePickerOptions>

          <DatePickerContainer>
            <DayPicker
              numberOfMonths={2}
              month={from}
              onDayClick={onDayClick}
              onDayMouseEnter={onDayMouseEnter}
              selectedDays={selectedDays}
              modifiers={modifiers}
              disabledDays={allDisabledDays}
            />
          </DatePickerContainer>
        </DateRangePickerOverlay>
      )}
    </DateRangeContainer>
  );
};

interface OwnProps {
  inputOptions?: DatePickerOption[];
  inputDisabledDays?: Modifier[];
  datePickerRangeType?: DatePickerRangeType;
  inputValue: DateRange<string>;
  onDateRangeChange: (dateRange: DateRange<string>) => void;
}

type ComponentProps = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>;

export default DateRangePicker;
