import ServiceCancel, {
  IServiceCancel,
} from 'contracts/models/service/ServiceCancel';
import { ServiceCancelPaymentRequest } from 'contracts/types/request';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const sendServiceCancelPayNow = async(
  data: ServiceCancelPaymentRequest,
): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'service-requests/cancellation/pay-now',
    {
      alias: data.alias,
      paymentMethodId: data.paymentMethodId,
    },
  );
  return response.data;
};

export const getServicesByAlias = async(alias: string): Promise<ServiceCancel> => {
  const response = await httpCustomerCore.get<ServiceCancel>(
    `service-requests/cancellation/${alias}`,
    {
      params: {},
      transformResponse: transformServiceResponse<
        IServiceCancel,
        ServiceCancel
      >(ServiceCancel),
    },
  );
  return response.data;
};
