import { HTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

interface SwitchLabel extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

const SwitchLabel = styled.div<SwitchLabel>`
  font-size: 12px;
  margin: 0 5px;

  ${props =>
    props.disabled &&
    css`
      color: ${subProps => subProps.theme.colors.grayDark};
    `}
`;

export default SwitchLabel;
