import axios, { AxiosError } from 'axios';
import update from 'immutability-helper';
import extend from 'lodash-es/extend';
import forEach from 'lodash-es/forEach';
import map from 'lodash-es/map';

import { payNow } from 'billing/services/zuoraServices';
import { NotificationType } from 'contracts/enums';
import {
  Invoice,
  LocationBalance,
  LocationPaymentInfo,
} from 'contracts/models';
import { ZuoraPaymentMethod } from 'contracts/models/service/ZuoraPayment';
import { ActionDispatcher, MakePaymentAction } from 'contracts/types/action';
import { ZuoraPaynowRequestArg } from 'contracts/types/request';
import { ServiceError } from 'contracts/types/service';
import {
  ApplicationState,
  MakePaymentState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import {
  createNotificationMessage,
  createTimedNotificationMessage,
} from 'core/ducks/notifier';
import { formatServiceError } from 'core/helpers/formatServiceError';
import { logError } from 'core/logging';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Services
import {
  getOpenInvoices,
  getOutstandingBalance,
  getPaymentLocationsBalances,
  getPaymentMethodInfoBySite,
} from '../services/billingServices';

// Actions Keys
const ROOT_KEY = 'billing/makePayment';
enum ActionKey {
  LOAD_LOCATIONS_BALANCES = 'billing/makePayment/LOAD_LOCATIONS_BALANCES',
  LOAD_INVOICES = 'billing/makePayment/LOAD_INVOICES',
  COMPLETE_PAY_NOW = 'billing/makePayment/COMPLETE_PAY_NOW',
  RESET = 'billing/makePayment/RESET',
}

// Initial State
const getInitialState: () => MakePaymentState = () => {
  return {
    paymentLocations: [],
    openInvoices: [],
    defaultPaymentMethodDetails: {} as ZuoraPaymentMethod,
    locationPaymentMethodInfo: {},
    balance: 0,
    locationAddress: '',
    invoiceTotal: 0,
    accountId: '',
    ownerAccountId: '',
    completedPaymentId: '',
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_LOCATIONS_BALANCES,
  ActionKey.LOAD_INVOICES,
  ActionKey.COMPLETE_PAY_NOW,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  MakePaymentState,
  MakePaymentAction
> = {
  [ActionKey.LOAD_LOCATIONS_BALANCES]: (state, action) => {
    return update(state, {
      $merge: {
        paymentLocations: action.paymentLocations,
        balance: action.balance,
      },
    });
  },
  [ActionKey.LOAD_INVOICES]: (state, action) => {
    if (!action.defaultPaymentMethodDetails) {
      action.defaultPaymentMethodDetails = [];
    }
    if (action.defaultPaymentMethodDetails.length === 0) {
      action.defaultPaymentMethodDetails[0] = {};
      action.defaultPaymentMethodDetails[0].site = {};
    }
    return update(state, {
      $merge: {
        openInvoices: action.openInvoices,
        locationPaymentMethodInfo: action.defaultPaymentMethodDetails[0],
        defaultPaymentMethodDetails:
          action.defaultPaymentMethodDetails[0].defaultPaymentMethod ||
          undefined,
        accountId: action.defaultPaymentMethodDetails[0].site?.zuoraAccountId,
        ownerAccountId:
          action.defaultPaymentMethodDetails[0].site?.zuoraInvoiceOwnerId,
        invoiceTotal: action.invoiceTotal,
        locationAddress:
          action.defaultPaymentMethodDetails.length > 0
            ? `Location - ${action.defaultPaymentMethodDetails[0].site?.siteAddress}`
            : '',
      },
    });
  },
  [ActionKey.COMPLETE_PAY_NOW]: (state, action) => {
    return update(state, {
      $merge: {
        completedPaymentId: action.completedPaymentId,
      },
    });
  },
};

export const reducer = getReducerBuilder<MakePaymentState, MakePaymentAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_LOCATIONS_BALANCES: (
    paymentLocations?: LocationBalance[],
    balance?: number,
  ): MakePaymentAction => ({
    type: ActionKey.LOAD_LOCATIONS_BALANCES,
    paymentLocations,
    balance,
  }),
  LOAD_INVOICES: (
    openInvoices?: Invoice[],
    defaultPaymentMethodDetails?: LocationPaymentInfo[],
    invoiceTotal?: number,
  ): MakePaymentAction => {
    return {
      type: ActionKey.LOAD_INVOICES,
      openInvoices,
      defaultPaymentMethodDetails,
      invoiceTotal,
    };
  },
  COMPLETE_PAY_NOW: (completedPaymentId?: string): MakePaymentAction => ({
    type: ActionKey.COMPLETE_PAY_NOW,
    completedPaymentId,
  }),
  RESET: (): MakePaymentAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadLocationsBalances = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_LOCATIONS_BALANCES,

    async() =>
      axios.all<LocationBalance[] | number>([
        getPaymentLocationsBalances(),
        getOutstandingBalance(),
      ]),
    result => {
      const [paymentLocationsResult, balanceResult] = result;
      const paymentLocations = paymentLocationsResult as LocationBalance[];
      const balance = balanceResult as number;
      dispatch(actionMap.LOAD_LOCATIONS_BALANCES(paymentLocations, balance));
    },
    err => {
      logError(err);
      dispatch(actionMap.LOAD_LOCATIONS_BALANCES());
    },
    true,
  );

const loadOpenInvoices = (custId: string, siteId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_INVOICES,
    async() =>
      axios.all<Invoice[] | LocationPaymentInfo[]>([
        getOpenInvoices(custId, siteId),
        getPaymentMethodInfoBySite(custId, siteId),
      ]),
    result => {
      const [openInvoicesResult, defaultPaymentMethodDetailsResult] = result;
      const openInvoices = openInvoicesResult as Invoice[];
      const defaultPaymentMethodDetails = defaultPaymentMethodDetailsResult as LocationPaymentInfo[];
      const checkedOpenInvoices = map(openInvoices, o =>
        extend({ isChecked: true }, o),
      );
      let invoiceTotal = 0;
      forEach(openInvoices, invoice => {
        invoiceTotal += invoice.balance || 0;
      });
      dispatch(
        actionMap.LOAD_INVOICES(
          checkedOpenInvoices,
          defaultPaymentMethodDetails,
          invoiceTotal,
        ),
      );
    },
    err => {
      logError(err);
      dispatch(actionMap.LOAD_INVOICES());
    },
    true,
  );

const payInvoices = (request: ZuoraPaynowRequestArg) => {
  return (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.COMPLETE_PAY_NOW,
      () => payNow(request),
      response => {
        dispatch(actionMap.COMPLETE_PAY_NOW(response.paymentId));
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            'Payment completed successfully',
          ),
        );
        if (response.warningMessage) {
          dispatch(
            createNotificationMessage(
              NotificationType.Warning,
              response.warningMessage,
            ),
          );
        }
        return response.paymentId;
      },
      (err: AxiosError<ServiceError>) => {
        logError(err);
        dispatch(actionMap.COMPLETE_PAY_NOW());
        if (err.response?.status === 400) {
          const errorMessage = formatServiceError(err);
          dispatch(
            createNotificationMessage(
              NotificationType.Error,
              errorMessage,
            ));
        } else {
          dispatch(
            createNotificationMessage(
              NotificationType.Error,
              'Something went wrong with your payment! Please try again later!',
            ));
        }
      },
    );
};

const makePaymentDuck = {
  actionKeys: ActionKey,
  thunks: { loadLocationsBalances, loadOpenInvoices, payInvoices },
  actions: { reset: actionMap.RESET },
};

export default makePaymentDuck;
