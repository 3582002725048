enum FinancialRoutes {
  root = '/finance',
  invoiceDetails = '/invoice-details',
  invoiceAudit = '/invoice-audit',
  haulerBillConsolidation = '/hauler-bill-consolidation',
  savings = '/savings',
  invoicesList = '/invoices-list',
  spendByMaterial = '/spend-by-material',
}

export default FinancialRoutes;
