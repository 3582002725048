import DisposalAuditSummary, {
  IDisposalAuditSummary,
} from 'contracts/models/service/DisposalAuditSummary';
import DisposalAuditTableData, {
  IDisposalAuditTableData,
} from 'contracts/models/service/DisposalAuditTableData';
import { DisposalAuditRequest } from 'contracts/types/request';
import { GetBlobResponse, GetCsvResponse } from 'contracts/types/service';
import { getBlobFileFromPublicUrl, getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getDisposalAuditTableData(
  arg: DisposalAuditRequest,
): Promise<DisposalAuditTableData[]> {
  const requestArg = createRequestArg(arg);

  const response = await httpCustomerCore.post<DisposalAuditTableData[]>(
    'disposal-audit/details',
    requestArg,
    {
      transformResponse: transformServiceResponse<
        IDisposalAuditTableData,
        DisposalAuditTableData
      >(DisposalAuditTableData),
    },
  );
  return response.data;
}

export async function getDisposalAuditSummary(
  arg: DisposalAuditRequest,
): Promise<DisposalAuditSummary> {
  const response = await httpCustomerCore.post<DisposalAuditSummary>(
    'disposal-audit/summary',
    createRequestArg(arg),
    {
      transformResponse: transformServiceResponse<
        IDisposalAuditSummary,
        DisposalAuditSummary
      >(DisposalAuditSummary),
    },
  );
  return response.data;
}

export async function getDisposalAuditCsv(
  arg: DisposalAuditRequest,
): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'disposal-audit/export-csv',
    createRequestArg(arg),
  );
  return response;
}

export async function downloadDisposalAuditFile(
  url: string,
): Promise<GetBlobResponse> {
  const disposalAuditFile = await getBlobFileFromPublicUrl(url);
  return disposalAuditFile;
}

function createRequestArg({
  fromDate,
  toDate,
  pageSize,
  pageNumber,
  locationFilter,
}: DisposalAuditRequest): unknown {
  const arg = {
    FromDate: fromDate,
    ToDate: toDate,
    PageSize: pageSize,
    PageNumber: pageNumber,
    SiteFilters: getLocationFilterApiArg(locationFilter),
  };
  return arg;
}
