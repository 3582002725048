import queryString from 'query-string';
import { Location } from 'react-router';

import { SiteIdentifier } from 'contracts/types/component';

export function getSiteIdentifier(location: Location): SiteIdentifier | undefined {
  const queryParams = queryString.parse(location.search);
  const { siteId, custId } = queryParams;
  if (typeof siteId === 'string' && typeof custId === 'string') {
    return {
      siteId,
      siteCustomerId: custId,
    };
  }
}

export function getNewServiceRequestDetails(location: Location): any | undefined {
  const queryParams = queryString.parse(location.search);
  const { siteId, custId, serviceId, requestType } = queryParams;
  if (typeof siteId === 'string' && typeof custId === 'string' &&
  typeof serviceId === 'string' && typeof requestType === 'string') {
    return {
      siteId,
      custId,
      serviceId,
      requestType
    };
  }
}

export function getServiceIdentifierAlias(location: Location): string | undefined {
  const queryParams = queryString.parse(location.search);
  const { alias } = queryParams;

  if (typeof alias === 'string') {
    return alias;
  }

  return undefined;
}

export function getServiceApprovalOrder(location: Location): string | undefined {
  const queryParams = queryString.parse(location.search);
  const { order } = queryParams;

  if (typeof order === 'string') {
    return order;
  }

  return undefined;
}

export function getServiceIdentifier(location: Location): number | undefined {
  const queryParams = queryString.parse(location.search);
  const { serviceId } = queryParams;
  if (typeof serviceId === 'string') {
    return parseInt(serviceId, 10);
  }
}

export function getLocationParams(search: string): Record<string, string> {
  const queryParams = new URLSearchParams(search);
  const data: Record<string, string> = {};
  for (const [key, value] of Array.from(queryParams.entries())) {
    if (typeof value === 'string') {
      data[key] = value;
    }
  }
  return data;
}
