enum BulkUploadRoutes {
  root = '/bulk-services',
  locations = '/locations',
  bulkDataUpload = '/bulk-data-upload',
  servicesEdit = '/recurring-service-update',
  onDemandPickUps = '/on-demand-services-update'
}

export function buildServicesEdit(siteId: string): string {
  return `${BulkUploadRoutes.root + BulkUploadRoutes.servicesEdit}?siteId=${siteId}`;
}

export default BulkUploadRoutes;
