import update from 'immutability-helper';

import { addTagSites, createTag, deleteTag, getAllTagSites, getTag, getTagSites, removeTagSites, updateTag } from 'account/services/tagService';
import { NotificationType } from 'contracts/enums';
import { CustomerTag, CustomerTagSite } from 'contracts/models';
import { CustomerTagSiteAssigned } from 'contracts/models/service/CustomerTagSite';
import { ActionDispatcher, CurrentTagAction } from 'contracts/types/action';
import { CustomerTagRequest, CustomerTagSiteAddRequest, CustomerTagSiteDeleteRequest } from 'contracts/types/request';
import {
  ApplicationState,
  CurrentTagState, ReduceFunctionMap
} from 'contracts/types/state';
import {
  createTimedNotificationMessage
} from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

const ROOT_KEY = 'account/currentTag';

enum ActionKey {
  GET_TAG = 'account/currentTag/GET_TAG',
  ADD_TAG = 'account/currentTag/ADD_TAG',
  REMOVE_TAG = 'account/currentTag/REMOVE_TAG',
  UPDATE_TAG = 'account/currentTag/UPDATE_TAG',
  LOAD_TAG_SITES = 'account/currentTag/LOAD_TAG_SITES',
  LOAD_ALL_TAG_SITES = 'account/currentTag/LOAD_ALL_TAG_SITES',
  ADD_SITES_TO_TAG = 'account/currentTag/ADD_SITES_TO_TAG',
  REMOVE_SITES_FROM_TAG = 'account/currentTag/REMOVE_SITES_FROM_TAG',
  RESET = 'account/currentTag/RESET',
}

// Initial State
const getInitialState: () => CurrentTagState = () => {
  return {
    addTagId: undefined,
    details: {},
    assignedTagSites: [],
    allTagSites: [],
  };
};

const reducerKeys = [
  ActionKey.GET_TAG,
  ActionKey.ADD_TAG,
  ActionKey.LOAD_TAG_SITES,
  ActionKey.LOAD_ALL_TAG_SITES,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CurrentTagState,
  CurrentTagAction
> = {
  [ActionKey.GET_TAG]: (state: CurrentTagState, action: CurrentTagAction) => {
    const newState = update(state, {
      $merge: {
        details: action.details,
      },
    });
    return newState;
  },
  [ActionKey.ADD_TAG]: (state: CurrentTagState, action: CurrentTagAction) => {
    const newState = update(state, {
      $merge: {
        addTagId: action.addTagId,
      },
    });
    return newState;
  },
  [ActionKey.LOAD_TAG_SITES]: (state: CurrentTagState, action: CurrentTagAction) => {
    const newState = update(state, {
      $merge: {
        assignedTagSites: action.assignedTagSites,
      },
    });
    return newState;
  },
  [ActionKey.LOAD_ALL_TAG_SITES]: (state: CurrentTagState, action: CurrentTagAction) => {
    const newState = update(state, {
      $merge: {
        allTagSites: action.allTagSites,
      },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<CurrentTagState, CurrentTagAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  GET_TAG: (currentTag?: CustomerTag): CurrentTagAction => ({
    type: ActionKey.GET_TAG,
    details: currentTag || {}
  }),
  ADD_TAG: (addTagId?: number): CurrentTagAction => ({
    type: ActionKey.ADD_TAG,
    addTagId
  }),
  LOAD_TAG_SITES: (assignedTagSites?: CustomerTagSite[]): CurrentTagAction => ({
    type: ActionKey.LOAD_TAG_SITES,
    assignedTagSites: assignedTagSites || []
  }),
  LOAD_ALL_TAG_SITES: (allTagSites?: CustomerTagSiteAssigned[]): CurrentTagAction => ({
    type: ActionKey.LOAD_ALL_TAG_SITES,
    allTagSites: allTagSites || [],
  }),
  RESET: (): CurrentTagAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadTag = (tagId: number) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.GET_TAG,
    () => getTag(tagId),
    tag => {
      dispatch(actionMap.GET_TAG(tag));
    },
    () => {},
    true,
  );

const addTag = (params: CustomerTagRequest) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.ADD_TAG,
    () => createTag(params),
    tagId => {
      dispatch(actionMap.ADD_TAG(tagId));
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          `${translate('account.tag')} ${params.name} ${translate('account.addedSuccess')}`,
          3000,
        ),
      );
    },
    () => {},
    true,
  );

const removeTag = (tagId: number, tagName: string) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.REMOVE_TAG,
      async() => deleteTag(tagId),
      () => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            `${translate('account.tag')} ${tagName} ${translate('account.hasBeenDeleted')}`,
            3000,
          ),
        );
      },
      () => {},
      true,
    );
    
const saveTag = (params: CustomerTagRequest, tagId: number) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.UPDATE_TAG,
      async() => updateTag(params, tagId),
      () => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Success,
            `${params.name} ${translate('account.tagUpdatedSuccess')}`,
            3000,
          ),
        );
      },
      () => {},
      true,
    );

const addSitesToTag = (params: CustomerTagSiteAddRequest[]) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.ADD_SITES_TO_TAG,
      async() => addTagSites(params),
      () => {},
      () => {},
      true,
    );
      
const removeSitesFromTag = (params: CustomerTagSiteDeleteRequest[]) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.REMOVE_SITES_FROM_TAG,
      async() => removeTagSites(params),
      () => {},
      () => {},
      true,
    );

const loadTagSites = (tagId: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TAG_SITES,
    () => getTagSites(tagId),
    result => {
      dispatch(actionMap.LOAD_TAG_SITES(result));
    },
    () => {
      dispatch(actionMap.LOAD_TAG_SITES());
    },
  );
    
const loadAllTagSites = (tagId: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ALL_TAG_SITES,
    async() => getAllTagSites(tagId),
    result => {
      dispatch(actionMap.LOAD_ALL_TAG_SITES(result));
    },
    () => {
      dispatch(actionMap.LOAD_ALL_TAG_SITES());
    },
    true,
  );

const currentTagDuck = {
  thunks: {
    loadTag,
    addTag,
    removeTag,
    saveTag,
    addSitesToTag,
    removeSitesFromTag,
    loadTagSites,
    loadAllTagSites,
  },
  actionKeys: ActionKey,
  actions: { 
    reset: actionMap.RESET, 
  },
};
export default currentTagDuck;
