import update from 'immutability-helper';

import {
  ServiceRequestsClosedReport,
  ServiceRequestsComparison,
  ServiceRequestsSummary,
  SlaRule,
} from 'contracts/models';
import { ServiceRequestsOpenReportTableData } from 'contracts/models/service/ServiceRequestsOpenReport';
import { ServiceRequestsCategoryEntry } from 'contracts/models/service/ServiceRequestsSummary';
import {
  ActionDispatcher,
  ServiceRequestsAction,
} from 'contracts/types/action';
import { ServiceRequestsRequest } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceRequestsState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as serviceRequestsService from '../services/serviceRequestsServices';

// Actions Keys
const ROOT_KEY = 'services/serviceRequests';
enum ActionKey {
  REFERENCE_NUMBERS_FILTER_DATA = 'services/serviceRequests/REFERENCE_NUMBERS_FILTER_DATA',
  OPEN_REQUEST_TYPES_FILTER_DATA = 'services/serviceRequests/OPEN_REQUEST_TYPES_FILTER_DATA',
  CLOSED_REQUEST_TYPES_FILTER_DATA = 'services/serviceRequests/CLOSED_REQUEST_TYPES_FILTER_DATA',
  CLOSED_COMPARISON = 'services/serviceRequests/CLOSED_COMPARISON',
  CLOSED_SUMMARY = 'services/serviceRequests/CLOSED_SUMMARY',
  CLOSED_TABLE = 'services/serviceRequests/CLOSED_TABLE',
  OPEN_SUMMARY = 'services/serviceRequests/OPEN_SUMMARY',
  OPEN_TABLE = 'services/serviceRequests/OPEN_TABLE',
  CAN_EDIT_COMMODITIES = 'services/serviceRequests/CAN_EDIT_COMMODITIES',
  SLA_INFO = 'services/serviceRequests/SLA_INFO',
  RESET = 'services/serviceRequests/RESET',
}

// Initial state
const getInitialState: () => ServiceRequestsState = () => {
  return {
    summaryClosed: {},
    comparisonClosed: {},
    tableClosed: [],
    summaryOpen: {},
    tableOpen: [],
    slaInfo: [],
    canEditCommodities: false,
    referenceNumbersFilterData: [],
    requestTypeEntriesByDateRangeFilterDataOpen: [],
    requestTypeEntriesByDateRangeFilterDataClosed: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.REFERENCE_NUMBERS_FILTER_DATA,
  ActionKey.OPEN_REQUEST_TYPES_FILTER_DATA,
  ActionKey.CLOSED_REQUEST_TYPES_FILTER_DATA,
  ActionKey.CLOSED_SUMMARY,
  ActionKey.CLOSED_COMPARISON,
  ActionKey.CLOSED_TABLE,
  ActionKey.OPEN_SUMMARY,
  ActionKey.OPEN_TABLE,
  ActionKey.CAN_EDIT_COMMODITIES,
  ActionKey.SLA_INFO,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceRequestsState,
  ServiceRequestsAction
> = {
  [ActionKey.REFERENCE_NUMBERS_FILTER_DATA]: (state, action) => {
    const { referenceNumbersFilterData } = action;
    return update(state, { $merge: { referenceNumbersFilterData } });
  },
  [ActionKey.OPEN_REQUEST_TYPES_FILTER_DATA]: (state, action) => {
    const { requestTypeEntriesByDateRangeFilterDataOpen } = action;
    return update(state, { $merge: { requestTypeEntriesByDateRangeFilterDataOpen } });
  },
  [ActionKey.CLOSED_REQUEST_TYPES_FILTER_DATA]: (state, action) => {
    const { requestTypeEntriesByDateRangeFilterDataClosed } = action;
    return update(state, { $merge: { requestTypeEntriesByDateRangeFilterDataClosed } });
  },
  [ActionKey.CLOSED_SUMMARY]: (state, action) => {
    const { summaryClosed } = action;
    return update(state, { $merge: { summaryClosed } });
  },
  [ActionKey.CLOSED_COMPARISON]: (state, action) => {
    const { comparisonClosed } = action;
    return update(state, { $merge: { comparisonClosed } });
  },
  [ActionKey.CLOSED_TABLE]: (state, action) => {
    const { tableClosed } = action;
    return update(state, { $merge: { tableClosed } });
  },
  [ActionKey.OPEN_SUMMARY]: (state, action) => {
    const { summaryOpen } = action;
    return update(state, { $merge: { summaryOpen } });
  },
  [ActionKey.OPEN_TABLE]: (state, action) => {
    const { tableOpen } = action;
    return update(state, { $merge: { tableOpen } });
  },
  [ActionKey.SLA_INFO]: (state, action) => {
    const { slaInfo } = action;
    return update(state, { $merge: { slaInfo } });
  },
  [ActionKey.CAN_EDIT_COMMODITIES]: (state, action) => {
    const { canEditCommodities } = action;
    return update(state, { $merge: { canEditCommodities } });
  },
};

export const reducer = getReducerBuilder<
  ServiceRequestsState,
  ServiceRequestsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  REFERENCE_NUMBERS_FILTER_DATA: (
    referenceNumbersFilterData?: string[],
  ): ServiceRequestsAction => ({
    type: ActionKey.REFERENCE_NUMBERS_FILTER_DATA,
    referenceNumbersFilterData,
  }),
  OPEN_REQUEST_TYPES_FILTER_DATA: (
    requestTypeEntriesByDateRangeFilterDataOpen?: ServiceRequestsCategoryEntry[],
  ): ServiceRequestsAction => ({
    type: ActionKey.OPEN_REQUEST_TYPES_FILTER_DATA,
    requestTypeEntriesByDateRangeFilterDataOpen,
  }),
  CLOSED_REQUEST_TYPES_FILTER_DATA: (
    requestTypeEntriesByDateRangeFilterDataClosed?: ServiceRequestsCategoryEntry[],
  ): ServiceRequestsAction => ({
    type: ActionKey.CLOSED_REQUEST_TYPES_FILTER_DATA,
    requestTypeEntriesByDateRangeFilterDataClosed,
  }),
  CLOSED_SUMMARY: (
    summaryClosed?: ServiceRequestsSummary,
  ): ServiceRequestsAction => ({
    type: ActionKey.CLOSED_SUMMARY,
    summaryClosed,
  }),
  CLOSED_COMPARISON: (
    comparisonClosed?: ServiceRequestsComparison,
  ): ServiceRequestsAction => ({
    type: ActionKey.CLOSED_COMPARISON,
    comparisonClosed,
  }),
  CLOSED_TABLE: (
    tableClosed?: ServiceRequestsClosedReport[],
  ): ServiceRequestsAction => ({
    type: ActionKey.CLOSED_TABLE,
    tableClosed,
  }),
  OPEN_SUMMARY: (
    summaryOpen?: ServiceRequestsSummary,
  ): ServiceRequestsAction => ({
    type: ActionKey.OPEN_SUMMARY,
    summaryOpen,
  }),
  OPEN_TABLE: (
    tableOpen?: ServiceRequestsOpenReportTableData[],
  ): ServiceRequestsAction => ({
    type: ActionKey.OPEN_TABLE,
    tableOpen,
  }),
  SLA_INFO: (slaInfo?: SlaRule[]): ServiceRequestsAction => ({
    type: ActionKey.SLA_INFO,
    slaInfo,
  }),
  CAN_EDIT_COMMODITIES: (
    canEditCommodities?: boolean,
  ): ServiceRequestsAction => ({
    type: ActionKey.CAN_EDIT_COMMODITIES,
    canEditCommodities,
  }),
  RESET: (): ServiceRequestsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadClosedSummary = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CLOSED_SUMMARY,
    () => serviceRequestsService.getServiceRequestsSummary(inputArg, true),
    result => dispatch(actionMap.CLOSED_SUMMARY(result)),
    () => dispatch(actionMap.CLOSED_SUMMARY()),
  );

const loadClosedComparison = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CLOSED_COMPARISON,
    () => serviceRequestsService.getServiceRequestsComparison(inputArg, true),
    result => dispatch(actionMap.CLOSED_COMPARISON(result)),
    () => dispatch(actionMap.CLOSED_COMPARISON()),
  );

const loadClosedTableData = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CLOSED_TABLE,
    () => serviceRequestsService.getServiceRequestsTableClosed(inputArg),
    result => dispatch(actionMap.CLOSED_TABLE(result)),
    () => dispatch(actionMap.CLOSED_TABLE()),
  );

const loadOpenSummary = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.OPEN_SUMMARY,
    () => serviceRequestsService.getServiceRequestsSummary(inputArg, false),
    result => dispatch(actionMap.OPEN_SUMMARY(result)),
    () => dispatch(actionMap.OPEN_SUMMARY()),
  );

const loadOpenTableData = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.OPEN_TABLE,
    () => serviceRequestsService.getServiceRequestsTable(inputArg),
    result => {
      dispatch(actionMap.OPEN_TABLE(result.tableData));
      dispatch(actionMap.CAN_EDIT_COMMODITIES(result.canEditCommodities));
    },
    () => {
      dispatch(actionMap.OPEN_TABLE());
      dispatch(actionMap.CAN_EDIT_COMMODITIES());
    },
  );

const loadReferenceNumbersFilterData = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REFERENCE_NUMBERS_FILTER_DATA,
    () => serviceRequestsService.getReferenceNumbersFilterData(inputArg),
    result => {
      dispatch(actionMap.REFERENCE_NUMBERS_FILTER_DATA(result),
      );
    },
    () => {
      dispatch(actionMap.REFERENCE_NUMBERS_FILTER_DATA());
    },
  );

const loadRequestTypeEntriesByDateRangeFilterDataOpen = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.OPEN_REQUEST_TYPES_FILTER_DATA,
    () => serviceRequestsService.getRequestTypesFilterData(inputArg, false),
    result => {
      dispatch(actionMap.OPEN_REQUEST_TYPES_FILTER_DATA(result),
      );
    },
    () => {
      dispatch(actionMap.OPEN_REQUEST_TYPES_FILTER_DATA());
    },
  );

const loadRequestTypeEntriesByDateRangeFilterDataClosed = (inputArg: ServiceRequestsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CLOSED_REQUEST_TYPES_FILTER_DATA,
    () => serviceRequestsService.getRequestTypesFilterData(inputArg, true),
    result => {
      dispatch(actionMap.CLOSED_REQUEST_TYPES_FILTER_DATA(result),
      );
    },
    () => {
      dispatch(actionMap.CLOSED_REQUEST_TYPES_FILTER_DATA());
    },
  );

const loadServiceSLA = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SLA_INFO,
    () => serviceRequestsService.getServiceSLAInfo(),
    result => dispatch(actionMap.SLA_INFO(result)),
    () => dispatch(actionMap.SLA_INFO()),
  );

export const serviceRequestsDuck = {
  thunks: {
    loadClosedComparison,
    loadClosedSummary,
    loadClosedTableData,
    loadOpenSummary,
    loadOpenTableData,
    loadServiceSLA,
    loadReferenceNumbersFilterData,
    loadRequestTypeEntriesByDateRangeFilterDataOpen,
    loadRequestTypeEntriesByDateRangeFilterDataClosed,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
