import { TableData } from 'contracts/types/component';

export interface IDisposalAuditTableData {
  siteId: string;
  custId: string;
  disposalAuditDate: string;
  disposalAuditId: number;
  siteName: string;
  siteAddress: string;
  dumpsterStampedPhotoUrl: string;
  hasDumpsterStampedPhoto: boolean;
  trackerStampedPhotoUrl: string;
  hasTrackerStampedPhoto: boolean;
  trackerStoppedPhotoUrl: string;
  hasTrackerStoppedPhoto: boolean;
}

class DisposalAuditTableData implements TableData {
  siteId?: string;
  custId?: string;
  disposalAuditDate?: string;
  disposalAuditId?: number;
  siteName?: string;
  siteAddress?: string;
  dumpsterStampedPhotoUrl?: string;
  hasDumpsterStampedPhoto?: boolean;
  trackerStampedPhotoUrl?: string;
  hasTrackerStampedPhoto?: boolean;
  trackerStoppedPhotoUrl?: string;
  hasTrackerStoppedPhoto?: boolean;
  isHidden?: boolean;

  constructor(data?: Partial<IDisposalAuditTableData>) {
    this.siteId = data?.siteId;
    this.custId = data?.custId;
    this.disposalAuditDate = data?.disposalAuditDate;
    this.disposalAuditId = data?.disposalAuditId;
    this.siteName = data?.siteName;
    this.siteAddress = data?.siteAddress;
    this.dumpsterStampedPhotoUrl = data?.dumpsterStampedPhotoUrl;
    this.hasDumpsterStampedPhoto = data?.hasDumpsterStampedPhoto;
    this.trackerStampedPhotoUrl = data?.trackerStampedPhotoUrl;
    this.hasTrackerStampedPhoto = data?.hasTrackerStampedPhoto;
    this.trackerStoppedPhotoUrl = data?.trackerStoppedPhotoUrl;
    this.hasTrackerStoppedPhoto = data?.hasTrackerStoppedPhoto;
  }
}

export default DisposalAuditTableData;
