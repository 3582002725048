import React, { PropsWithChildren, Suspense, useEffect } from 'react';

import cuid from 'cuid';
import { FullStoryAPI } from 'react-fullstory';
import {
  shallowEqual,
  useSelector
} from 'react-redux';
import { Navigate, Path, useLocation } from 'react-router-dom';

import {
  getChannel,
  getClaimsCustomerId,
  getClaimsCustomerType,
  getClaimsUserRole,
  getCurrentUserEmail,
  getCustomerName,
  getNeedsPasswordChange, getPermission, getSalePermissions, getTrackingIdentity, getUserBrandings, isUserImpersonating
} from 'account/ducks/selectors';
import { AccountRoutes } from 'account/routing';
import {
  CustomerType,
  PermissionType,
  SaleType,
  UserRole
} from 'contracts/enums';
import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import { ApplicationState } from 'contracts/types/state';
import { PageLoading } from 'core/components/styled';
import Environments from 'core/constants/Environments';
import { environment } from 'core/services/environment';
import { logEvent } from 'core/services/logging';
import getHasAuthorization from 'routing/helper/authorization';
import { WholesaleRoutes } from 'wholesale/routing';

const AuthRoute: React.FC<PropsWithChildren<ComponentProps>> = ({
  children,
  role,
  custTypes,
  salePermissionTypes,
  blockImpersonatingUser,
  permissionTypes,
  brandingTypes,
  unauthorizedUserRoles,
  componentKey,
}) => {
  const location = useLocation();

  const isLoggedIn = useSelector((state: ApplicationState) => state.account.login.isLoggedIn, shallowEqual);
  const customerName = useSelector<ApplicationState, string | undefined>(state => getCustomerName(state), shallowEqual);
  const claims = useSelector<ApplicationState, Claims>(state => ({
    custId: getClaimsCustomerId(state),
    custEmail: getCurrentUserEmail(state),
    customerType: getClaimsCustomerType(state),
    userRole: getClaimsUserRole(state),
    permissionType: getPermission(state),
    salePermissions: getSalePermissions(state),
    needsPasswordChange: getNeedsPasswordChange(state),
    trackingIdentity: getTrackingIdentity(state),
    channel: getChannel(state),
    userIsImpersonating: isUserImpersonating(state),
    brandingTypes: getUserBrandings(state)
  }), shallowEqual);

  const serviceCount = useSelector<ApplicationState, number | undefined>(state =>
    state.services && state.services.serviceList && state.services.serviceList.siteProfileInfo
      ? state.services.serviceList.siteProfileInfo.services?.length
      : undefined,
  shallowEqual,
  );

  useEffect(() => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      const uniqueId = cuid();
      sessionStorage.setItem('sessionId', uniqueId);
      sessionId = uniqueId;
    }
    if (window?.newrelic) {
      window?.newrelic.setCustomAttribute('uniqueId', sessionId);
    }
  }, []);

  useEffect(() => {
    const sessionId = sessionStorage.getItem('sessionId');

    const userId = claims.trackingIdentity;
    try {
      if (userId && environment !== Environments.local) {
        FullStoryAPI('identify', userId, {
          email: claims.custEmail,
          custId: claims.custId,
          custName: customerName,
          channel: claims.channel,
          billingType: claims.customerType,
          userPermission: claims.userRole,
          uniqueId: sessionId as string,
        });
        window.email = claims.custEmail;
        window.custId = claims.custId;
        window.custName = customerName;
        window.channel = claims.channel;
        window.billingType = claims.customerType;
        window.userPermission = claims.userRole;
      }
    } catch (err) {
      logEvent('ROUTING_EVENT_ERROR', { err });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claims.channel,
    claims.custEmail,
    claims.custId,
    claims.customerType,
    claims.trackingIdentity,
    claims.userRole,
    customerName,
  ]);

  useEffect(() => {
    if (location && location.pathname.indexOf('site-profile') >= 0) {
      window.serviceCount = serviceCount;
    } else {
      window.serviceCount = undefined;
    }
  }, [location, serviceCount]);

  // Wholesale
  const isWholesale = location.pathname.split('/')[1] === 'wholesale';
  if (isLoggedIn && isWholesale) {
    return location.pathname === WholesaleRoutes.root ? (
      <Suspense fallback={<PageLoading />}>{children}</Suspense>
    ) : (
      <Navigate to={WholesaleRoutes.root} />
    );
  }

  // Change password
  const isLoggingOut = location.pathname === AccountRoutes.root + AccountRoutes.logout;
  const onlyUpdatePasswordAccess = claims.needsPasswordChange;
  const isUpdatePassword = location.pathname === AccountRoutes.root + AccountRoutes.updatePassword;

  if (isLoggedIn && onlyUpdatePasswordAccess && !isLoggingOut) {
    return isUpdatePassword ? (
      <Suspense fallback={<PageLoading />}>{children}</Suspense>
    ) : (
      <Navigate to={AccountRoutes.root + AccountRoutes.updatePassword} />
    );
  }

  // Default
  const hasAuthorization = getHasAuthorization(
    claims,
    componentKey,
    role,
    custTypes,
    salePermissionTypes,
    blockImpersonatingUser,
    permissionTypes,
    brandingTypes,
    unauthorizedUserRoles,
  );
  const path: Path = { pathname: location.pathname, search: location.search, hash: location.hash };
  return isLoggedIn && hasAuthorization ? (
    <Suspense fallback={<PageLoading />}>{children}</Suspense>
  ) : isLoggedIn ? (
    <Navigate to={{ pathname: AccountRoutes.unauthorized }} />
  ) : isWholesale ? (
    <Navigate to={AccountRoutes.root + AccountRoutes.wholesale} state={path}/>
  ) : (
    <Navigate to={AccountRoutes.root + AccountRoutes.login} state={path}/>
  );
};

export interface Claims {
  custId?: string;
  custEmail?: string;
  customerType?: CustomerType;
  userRole?: UserRole;
  salePermissions?: SaleType[];
  needsPasswordChange: boolean;
  trackingIdentity?: string;
  channel?: string;
  userIsImpersonating?: boolean;
  permissionType?: PermissionType[];
  brandingTypes?: BrandingType[];
}

interface ComponentProps {
  role?: UserRole;
  custTypes?: CustomerType[];
  salePermissionTypes?: SaleType[];
  blockImpersonatingUser?: boolean;
  permissionTypes?: PermissionType[];
  brandingTypes?: BrandingType[];
  unauthorizedUserRoles?: UserRole[];
  componentKey?: SubscriptionComponentKey;
}

export default AuthRoute;
