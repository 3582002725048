export interface IMaterialSpendOverview {
  readonly perTon: MaterialSpendOverviewPerTon;
  readonly rebateCategories: MaterialSpendCategories;
  readonly costCategories: MaterialSpendCategories;
}

class MaterialSpendOverview {
  perTon?: MaterialSpendOverviewPerTon;
  rebateCategories?: MaterialSpendCategories;
  costCategories?: MaterialSpendCategories;

  constructor(data?: Partial<IMaterialSpendOverview>) {
    this.perTon = data?.perTon;
    this.rebateCategories = data?.rebateCategories;
    this.costCategories = data?.costCategories;
  }
}

interface MaterialSpendCategories {
  categories: MaterialSpendOverviewCategory[];
  total: number;
}

export interface MaterialSpendOverviewCategory {
  identifier: number;
  label: string;
  total: number;
}

interface MaterialSpendOverviewPerTon {
  costPerTon?: number;
  rebatePerTon?: number;
}

export default MaterialSpendOverview;
