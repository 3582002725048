export enum Routes {
  root = '/account',
  settings = '/settings',
  notifications = '/notifications',
  communicationSettings = '/communication-settings',
  checkNetworkStatus = '/check-network-status',
  keyManagementPage = '/key-management',

  users = '/users',
  addUser = '/users/create',
  editUser = '/users/editAccess',
  editUserCommunication = '/users/communication-settings',

  addTag = '/tags/create',
  editTag = '/tags/edit-tag',
  bulkTags = '/tags/bulk-tags',

  logout = '/logout',
  viewUser = '/viewUser',
  viewTag = '/viewTag',
  login = '/login',
  wholesale = '/wholesale/login',
  backoffice = '/backoffice',
  activation = '/activation',
  setPassword = '/setpassword',
  register = '/register',
  forgotPassword = '/forgot-password',
  passwordResetSent = '/password-reset-email-sent',
  registerEmailSent = '/register-email-sent',
  resetPassword = '/reset-password',
  updatePassword = '/update-password',
  unauthorized = '/unauthorized',
  loginError = '/login-error',
  ssoLogin = '/sso/login',
  ssoFedexCustomer = '/sso/:customer',
  ssoFedex = '/sso',
}

export function buildUsersSecondTab(): string {
  return `${Routes.root + Routes.users}?selectedTab=2`;
}

export function buildEditUser(
  email: string,
  userId: number,
  role: string,
): string {
  return `${Routes.root + Routes.editUser}?email=${email}&userId=${userId}&role=${role}`;
}

export function buildEditUserCommunicationSettings(
  email: string,
  userId: number,
  role: string,
): string {
  return `${Routes.root + Routes.editUserCommunication}?email=${email}&userId=${userId}&role=${role}`;
}

export function buildEditTag(tagId: string): string {
  return `${Routes.root + Routes.editTag}?tagId=${tagId}`;
}

export function buildViewTag(tagId: string): string {
  return `${Routes.root + Routes.viewTag}?tagId=${tagId}`;
}
