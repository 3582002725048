import update from 'immutability-helper';

import { Holiday } from 'contracts/models';
import { ActionDispatcher, HolidayAction } from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  HolidayState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getPublicHolidays } from 'utils/services/getPublicHolidays';

// Actions
const ROOT_KEY = 'services/holidays';
enum ActionKey {
  LOAD_HOLIDAYS = 'services/LOAD_HOLIDAYS',
  RESET = 'services/RESET',
}

// Initial state
const getInitialState: () => HolidayState = () => {
  return {
    holidays: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_HOLIDAYS] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  HolidayState,
  HolidayAction
> = {
  [ActionKey.LOAD_HOLIDAYS]: (state, action) => {
    const { holidays } = action;
    return update(state, {
      $merge: {
        holidays,
      },
    });
  },
};

export const reducer = getReducerBuilder<HolidayState, HolidayAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_HOLIDAYS: (holidays: Holiday[]): HolidayAction => ({
    type: ActionKey.LOAD_HOLIDAYS,
    holidays,
  }),
  RESET: (): HolidayAction => ({ type: ActionKey.RESET }),
};

// API calls
const loadHolidays = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_HOLIDAYS,
    async() => getPublicHolidays(),
    result => {
      dispatch(actionMap.LOAD_HOLIDAYS(result));
    },
    () => {
      dispatch(actionMap.LOAD_HOLIDAYS([]));
    },
  );

const loadHolidaysDuck = {
  thunks: { loadHolidays },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default loadHolidaysDuck;
