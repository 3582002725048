import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import BulkUploadRoutes from './bulkUploadRoutes';

const BulkLocationsPage = lazy(() => import('bulkUpload/components/Locations/BulkLocationsPage'));
const BulkServicesUploadPage = lazy(() => import('bulkUpload/components/BulkServicesUpload/BulkServicesUploadPage'));
const ServicesUpdatePage = lazy(() => import('bulkUpload/components/RecurringServices/ServicesUpdatePage'));
const OnDemandPickUpsPage = lazy(() => import('bulkUpload/components/OnDemandPickUps/OnDemandPickUpsPage'));

const BulkUploadRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={BulkUploadRoutes.locations} element={
      <AuthRoute componentKey={SubscriptionComponentKey.UnmanagedServicesLocationsPage}>
        <BulkLocationsPage />
      </AuthRoute>
    } />

    <Route path={BulkUploadRoutes.bulkDataUpload} element={
      <AuthRoute componentKey={SubscriptionComponentKey.BulkOnDemandServicesUploadPage}>
        <BulkServicesUploadPage />
      </AuthRoute>
    } />
    <Route path={BulkUploadRoutes.servicesEdit} element={
      <AuthRoute componentKey={SubscriptionComponentKey.ServicesUpdatePage}>
        <ServicesUpdatePage />
      </AuthRoute>
    } />

    <Route path={BulkUploadRoutes.onDemandPickUps} element={
      <AuthRoute componentKey={SubscriptionComponentKey.OnDemandPickUpsPage}>
        <OnDemandPickUpsPage />
      </AuthRoute>
    } />
    
    {/* Default Route */}
    <Route path="*" element={<Navigate to={BulkUploadRoutes.root + BulkUploadRoutes.locations} replace />} />
    <Route index element={<Navigate to={BulkUploadRoutes.root + BulkUploadRoutes.locations} replace />} />
  </Routes>
);

export default BulkUploadRouter;
