import { EquipmentType } from 'contracts/enums';
import { TableData } from 'contracts/types/component';

export interface IServiceRequestsOpenReport {
  readonly tableData: ServiceRequestsOpenReportTableData[];
  readonly canEditCommodities: boolean;
}

class ServiceRequestsOpenReport {
  tableData?: ServiceRequestsOpenReportTableData[];
  canEditCommodities?: boolean;

  constructor(data?: Partial<IServiceRequestsOpenReport>) {
    this.tableData = data?.tableData;
    this.canEditCommodities = data?.canEditCommodities;
  }
}

export interface ServiceRequestsOpenReportTableData extends TableData {
  openedDateTime: string;
  orderNumber: number;
  openById: number;
  orderType: string;
  storeName: string;
  serviceDescription: string;
  referenceNumber: string;
  status: string;
  scheduledDate: string;
  details: ServiceRequestsOpenReportDetails;
  cietradeDetails: CietradeServiceRequestDetails;
  serviceProcessor: string;
  workOrder: number;
  serviceOrder: string;
  canServiceRequestBeCancelled: boolean;
}

export interface ServiceRequestsOpenReportDetails {
  reasonId: string;
  reasonDescription: string;
  requestType: string;
  custId: string;
  siteId: string;
  serviceCode: string;
  serviceId: string;
  serviceProcessor: string;
  containerType: EquipmentType;
  containerSize: string;
  wasteType: string;
  open: ServiceRequestsOpenReportStatus;
  scheduled: ServiceRequestsOpenReportStatus;
  closed: ServiceRequestsOpenReportStatus;
  shipped: ServiceRequestsOpenReportStatus;
  requestTypeDescr: string;
  siteAddress: string;
}

export interface ServiceRequestsOpenReportStatus {
  date?: string;
  enabled: boolean;
}

export interface CietradeServiceRequestDetails {
  corePallet?: number;
  nonCorePallet?: number;
  chepPallet?: number;
  igps?: number;
  peco?: number;
  baleCount?: number;
  requestNote?: string;
  cieTradeHeaderId?: number;
  referenceNumber?: string;
}

export default ServiceRequestsOpenReport;
