import React, { PureComponent } from 'react';

import { FilterType } from 'contracts/enums';
import { CityFilterValue, LocationFilter } from 'contracts/types/component';

import DropdownFilter from '../DropdownFilter';
import { DropdownFilterValue } from '../DropdownFilter/DropdownFilterPopup';

class LocationTypeAheadFilter extends PureComponent<ComponentProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilterChanged = (selectedEntity: any[]): void => {
    const { typeAheadType, filterChanged } = this.props;
    
    filterChanged({
      ...(typeAheadType === FilterType.City ? { cities: selectedEntity } : {}),
      ...(typeAheadType === FilterType.ZipCode ? { zipCodes: selectedEntity } : {}),
    });
  };

  render(): React.ReactNode {
    const {
      currentFilter,
      isDisabled,
      values,
      label,
      typeAheadType,
    } = this.props;
    
    let selectedIds: string[] | CityFilterValue[] = [];
    if (typeAheadType === FilterType.City && currentFilter.cities) {
      selectedIds = currentFilter.cities; 
    }
    if (typeAheadType === FilterType.ZipCode && currentFilter.zipCodes) {
      selectedIds = currentFilter.zipCodes;
    }

    return (
      <DropdownFilter
        label={label}
        values={values}
        selectedIds={selectedIds}
        onFilterChanged={this.onFilterChanged}
        onCityZipFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
        typeAheadType={typeAheadType}
      />
    );
  }
}

interface ComponentProps {
  label: string;
  values?: Array<DropdownFilterValue<string>>;
  currentFilter: LocationFilter;
  filterChanged: (filter: LocationFilter) => void;
  isDisabled?: boolean;
  typeAheadType?: string;
}
export default LocationTypeAheadFilter;
