import { combineReducers } from 'redux';

import { reducer as openTopTempServicesReducer } from './openTopTempServices';
import { reducer as purchaseReducer } from './purchase';

const reducer = combineReducers({
  openTopServices: openTopTempServicesReducer,
  purchase: purchaseReducer,
});

export default reducer;
