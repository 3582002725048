/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import debounce from 'lodash-es/debounce';
import { FieldError } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import { TypeAheadOption } from 'contracts/types/form';
import translate from 'core/helpers/translate';

import Error from './Error';
import FormError from './HookFormComponents/styled/FormError';
import { FormGroup } from './HookFormComponents/styled/FormGroup';
import { FormLabel } from './HookFormComponents/styled/FormLabel';
import { groupBadgeStyles, groupStyles, typeAheadStyles } from './styled/TypeAhead';

const TypeAheadField = React.forwardRef<HTMLSelectElement, ComponentProps>(
  ({ getOptionsApi,
    disabled,
    placeholder,
    label,
    noLengthRestriction,
    fullWidth,
    onChange,
    value,
    errors,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    apiErrors, ...rest }, ref) => {
    const [fieldValue, setFieldValue] = useState<string | undefined>(undefined);
    const [option, setOption] = useState<TypeAheadOption | null>(null);
    
    const formOnChange = (option: any): void => {
      const value = option && option.value ? option.value : null;
      if (value) {
        setOption(option);
        if (onChange) {
          onChange(value);
        }
      }
    };
      
    const onInputChange = (value: string): void => {
      setFieldValue(value);
    };
      
    const getOptions = debounce((searchTerm: string, 
      onOptionsLoaded: (options: TypeAheadOption[]) => void) => {
      if (!noLengthRestriction && searchTerm.length < 3) {
        onOptionsLoaded([]);
        return;
      }
      getOptionsApi(searchTerm).then(onOptionsLoaded).catch(() => {});
    }, 500);
    
    const noOptionsMessage = ({ inputValue }: { inputValue: string }): string => {
      return inputValue.length < 3 && !noLengthRestriction
        ? translate('core.pleaseTypeAtLeastThreeCharacters')
        : translate('core.noResults');
    };
          
    return (
      <FormGroup
        hasValue={!!fieldValue || !!option || !!value}
        unconnected
        paddingFive
        fullWidth={fullWidth}
      >
        {!!label && <FormLabel>{label}</FormLabel>}
      
        <AsyncSelect<TypeAheadOption>
          isSearchable
          isDisabled={disabled}
          styles={typeAheadStyles}
          formatGroupLabel={formatGroupLabel}
          loadOptions={getOptions}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder || ''}
          onChange={formOnChange}
          onInputChange={onInputChange}
          name={rest.name}
        />
        {errors && <Error error={errors} />}
        {apiErrors &&
              apiErrors.map(error => <FormError key={error}>{error}</FormError>)}
      </FormGroup>
    );
  }
);

const formatGroupLabel = (group: any): JSX.Element => (
  <div style={groupStyles}>
    <span>{group.label}</span>
    <span style={groupBadgeStyles}>{group.options ? group.options.length : ''}</span>
  </div>
);

interface OwnProps {
  getOptionsApi: (searchTerm: string) => Promise<TypeAheadOption[]>;
  placeholder?: string;
  label?: string;
  defaultOptions?: TypeAheadOption[];
  noLengthRestriction?: boolean;
  errors?: FieldError;
  apiErrors?: string[];
  onChange?: (val: string) => void; // if overwritten, set form value manually
  fullWidth?: boolean;
}

type ComponentProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  OwnProps;

export default TypeAheadField;
