import NewServiceAvailability, { INewServiceAvailability } from 'contracts/models/service/NewServiceAvailability';
import { NewServiceMarketAvailability } from 'contracts/models/service/NewServiceMarketAvailability';
import { NewServiceQuoteResponse } from 'contracts/models/service/NewServiceQuote';
import { MarketAvailabilityRequest, NewServiceQuoteRequest, ServiceAvailabilityRequest } from 'contracts/types/request';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getServiceAvailability = async(
  { customerId, siteId, duration }: ServiceAvailabilityRequest
): Promise<NewServiceAvailability> => {
  const response = await httpCustomerCore.post<NewServiceAvailability>(
    `new-service/request/service-availability`,
    {
      customerId,
      siteId,
      duration
    },
    {
      transformResponse: transformServiceResponse<
        INewServiceAvailability,
        NewServiceAvailability
      >(NewServiceAvailability),
    },
  );
  return response.data;
};

export const getMarketAvailability = async(
  { customerId, siteId, serviceCode }: MarketAvailabilityRequest
): Promise<NewServiceMarketAvailability> => {
  const response = await httpCustomerCore.post<NewServiceMarketAvailability>(
    `new-service/request/market-availability`,
    {
      customerId,
      siteId,
      serviceCode
    },
  );
  return response.data;
};

export async function getServiceQuote(
  request: NewServiceQuoteRequest,
): Promise<NewServiceQuoteResponse> {
  const response = await httpCustomerCore.post<NewServiceQuoteResponse>(
    '/new-service/request/quote',
    {
      ...request,
    },
  );
  return response.data;
}
