import ServiceRequestCustomerFilesTableData, { IServiceRequestCustomerFilesTableData } from 'contracts/models/service/ServiceRequestCustomerFilesTableData';
import ServiceRequestDetails, {
  IServiceRequestDetails,
} from 'contracts/models/service/ServiceRequestDetails';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getServiceRequestDetails = async(
  orderNumber: number,
  serviceProcessor: string,
  scheduledDate: string,
  serviceOrder: string,
): Promise<ServiceRequestDetails> => {
  const response = await httpCustomerCore.get<ServiceRequestDetails>(
    `service-requests/get-details?orderNumber=${orderNumber}&serviceProcessor=${serviceProcessor}&scheduledDate=${scheduledDate}&serviceOrder=${serviceOrder}`,
    {
      params: {},
      transformResponse: transformServiceResponse<
        IServiceRequestDetails,
        ServiceRequestDetails
      >(ServiceRequestDetails),
    },
  );
  return response.data;
};

export const addNote = async(
  orderNumber: number,
  note: string,
  orderStatus: string,
  scheduledDate: string,
  serviceProcessor: string
): Promise<unknown> => {
  const response = await httpCustomerCore.post(`service-requests/add-note`, {
    orderNumber: orderNumber,
    note: note,
    orderStatus: orderStatus,
    scheduledDate: scheduledDate,
    serviceProcessor: serviceProcessor
  });
  return response.data;
};

export const getServiceRequestsFiles = async(
  serviceOrder: number,
  serviceProcessor: string,
): Promise<ServiceRequestCustomerFilesTableData[]> => {
  const response = await httpCustomerCore.get<ServiceRequestCustomerFilesTableData[]>(
    'service-requests/get-files',
    {
      params: {
        serviceOrder,
        serviceProcessor
      },
      transformResponse: 
      transformServiceResponse<IServiceRequestCustomerFilesTableData, ServiceRequestCustomerFilesTableData>(
        ServiceRequestCustomerFilesTableData,
      ),
    },
  );
  return response.data;
};
