enum Sizes {
  SIZE_AUTO = 'auto',
  SIZE_NO = 'no',
  SIZE_XX_SMALL = 'xxSmall',
  SIZE_X_SMALL = 'xSmall',
  SIZE_SMALL = 'small',
  SIZE_S_MEDIUM = 'sMedium',
  SIZE_MEDIUM = 'medium',
  SIZE_L_MEDIUM = 'lMedium',
  SIZE_LARGE = 'large',
  SIZE_X_LARGE = 'xLarge',
  SIZE_XX_LARGE = 'xxLarge',
  SIZE_GRANDE = 'grande',
  SIZE_X_GRANDE = 'xGrande',
  SIZE_XX_GRANDE = 'xxGrande',
}

export default Sizes;
