import CustomerTag, {
  ICustomerTag,
} from 'contracts/models/service/CustomerTag';
import CustomerTagSite, { CustomerTagSiteAssigned, ICustomerTagSite, ICustomerTagSiteAssigned } from 'contracts/models/service/CustomerTagSite';
import TagImportDocument, {
  ITagImportDocument,
} from 'contracts/models/service/TagImportDocument';
import { CustomerTagRequest, CustomerTagSiteAddRequest, CustomerTagSiteDeleteRequest } from 'contracts/types/request';
import { GetBlobResponse, UploadFileRequest, UploadFileResponse } from 'contracts/types/service';
import { getBlobFile, writeBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getCustomerTags = async(): Promise<CustomerTag[]> => {
  const response = await httpCustomerCore.get<CustomerTag[]>('customer-tag/all', {
    transformResponse: transformServiceResponse<ICustomerTag, CustomerTag>(
      CustomerTag,
    ),
  });
  return response.data;
};

export const getTag = async(tagId: number): Promise<CustomerTag> => {
  const response = await httpCustomerCore.get<CustomerTag>(
    `customer-tag?tagId=${tagId}`,
    {
      transformResponse: transformServiceResponse<ICustomerTag, CustomerTag>(
        CustomerTag,
      ),
    },
  );
  return response.data;
};

export const createTag = async(params: CustomerTagRequest): Promise<number> => {
  const response = await httpCustomerCore.post<number>('customer-tag', params);
  return response.data;
};

export const updateTag = async(params: CustomerTagRequest, tagId: number): Promise<unknown> => {
  const response = await httpCustomerCore.put(`customer-tag?tagId=${tagId}`, params);
  return response.data;
};

export const deleteTag = async(tagId: number): Promise<unknown> => {
  const response = await httpCustomerCore.delete(`customer-tag?tagId=${tagId}`);
  return response.data;
};

export const addTagSites = async(params: CustomerTagSiteAddRequest[]): Promise<unknown> => {
  const response = await httpCustomerCore.put('customer-tag-sites', params);
  return response.data;
};

export const getTagSites = async(tagId: string): Promise<CustomerTagSite[]> => {
  const response = await httpCustomerCore.get<CustomerTagSite[]>(
    `customer-tag-sites/${tagId}`,
    {
      transformResponse: transformServiceResponse<ICustomerTagSite, CustomerTagSite>(CustomerTagSite),
    },
  );
  return response.data;
};

export const getTagSitesUnassigned = async(
  tagId: number,
): Promise<CustomerTagSite[]> => {
  const response = await httpCustomerCore.get<CustomerTagSite[]>(
    `customer-tag-sites/${tagId}/unassigned`,
    {
      transformResponse: transformServiceResponse<ICustomerTagSite, CustomerTagSite>(CustomerTagSite),
    },
  );
  return response.data;
};

export const getAllTagSites = async(
  tagId: number,
): Promise<CustomerTagSiteAssigned[]> => {
  const response = await httpCustomerCore.get<CustomerTagSiteAssigned[]>(
    `customer-tag-sites/${tagId}/all`,
    {
      transformResponse: transformServiceResponse<
      ICustomerTagSiteAssigned, 
      CustomerTagSiteAssigned>(CustomerTagSiteAssigned),
    },
  );
  return response.data;
};

export const removeTagSites = async(params: CustomerTagSiteDeleteRequest[]): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'customer-tag-sites/delete',
    params,
  );
  return response.data;
};

export const getBulkTagFiles = async(): Promise<TagImportDocument[]> => {
  const response = await httpCustomerCore.get<TagImportDocument[]>(
    `all-tags/documents-metadata`,
    {
      transformResponse: transformServiceResponse<
        ITagImportDocument,
        TagImportDocument
      >(TagImportDocument),
    },
  );
  return response.data;
};

export async function downloadBulkTags(
  documentId: number,
): Promise<GetBlobResponse> {
  const bulkTagsFile = await getBlobFile(
    httpCustomerCore,
    `all-tags/document?documentId=${documentId}`,
  );
  return bulkTagsFile;
}

export async function uploadTagsSpreadsheet(
  uploadFileRequest: UploadFileRequest,
): Promise<UploadFileResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    '/all-tags/import',
    uploadFileRequest,
  );
  return response;
}

export async function downloadTagsSpreadsheet(): Promise<GetBlobResponse> {
  const file = await getBlobFile(httpCustomerCore, '/all-tags/snapshot');
  return file;
}
