export interface IQuickpayInvoiceDetails {
  readonly accountId: string;
  readonly accountKey: string;
  readonly invoiceAmount: number;
  readonly invoiceNumber: string;
  readonly invoiceId: string;
  readonly paymentStatus: string;
}

class QuickpayInvoiceDetails {
  accountId?: string;
  accountKey?: string;
  invoiceAmount?: number;
  invoiceNumber?: string;
  invoiceId?: string;
  paymentStatus?: string;

  constructor(data?: Partial<IQuickpayInvoiceDetails>) {
    this.accountId = data?.accountId;
    this.accountKey = data?.accountKey;
    this.invoiceAmount = data?.invoiceAmount;
    this.invoiceNumber = data?.invoiceNumber;
    this.invoiceId = data?.invoiceId;
    this.paymentStatus = data?.paymentStatus;
  }
}

export default QuickpayInvoiceDetails;
