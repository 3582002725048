/* eslint-disable camelcase */
enum Environments {
  local = 'local',
  development = 'development',
  temp_development = 'temp_development',
  alpha_dev = 'alpha_dev',
  qa = 'qa',
  uat = 'uat',
  production = 'production',
}

export default Environments;
