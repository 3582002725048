export interface IMaterialSpendComparison {
  readonly isMonthly: boolean;
  readonly hasRebateEntries: boolean;
  readonly hasCostEntries: boolean;
  readonly entries: MaterialSpendComparisonRow[];
}

class MaterialSpendComparison {
  isMonthly?: boolean;
  hasRebateEntries?: boolean;
  hasCostEntries?: boolean;
  entries?: MaterialSpendComparisonRow[];

  constructor(data?: Partial<IMaterialSpendComparison>) {
    this.isMonthly = data?.isMonthly;
    this.entries = data?.entries;
    this.hasRebateEntries = data?.hasRebateEntries;
    this.hasCostEntries = data?.hasCostEntries;
  }
}

export interface MaterialSpendComparisonRow {
  serviceDate: string;
  rebate: number;
  rebatePerTon?: number;
  cost: number;
  costPerTon?: number;
}

export default MaterialSpendComparison;
