/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

import { Devices, theme } from 'core/styles';

interface TooltipWrapperComponentProps {
  showTooltip: boolean;
  tooltipOffsetTop: number;
  tooltipOffsetLeft: number;
  maxWidth?: number;
  centered?: boolean;
  darkTheme?: boolean;
  leftArrow?: boolean;
  topArrow?: boolean;
  borderRadius?: number;
  noTooltipArrow?: boolean;
}

export const TooltipWrapper = styled.div<TooltipWrapperComponentProps>`
  display: -moz-inline-stack;
  display: inline-block;
  visibility: hidden;
  font-size: 12px;
  padding: 10px 15px 5px;
  position: absolute;
  box-shadow: 0px 6px 14px #dde1e6;
  background-color: ${theme.colors.brandWhite};
  max-width: 320px;
  z-index: 1;

  @media ${Devices.tablet} {
    font-size: 10px;
  }

  &:after {
    content: '▾';
    display: block;
    font-size: 2em;
    text-align: center;
    height: 0;
    line-height: 0;
    position: absolute;
    color: ${theme.colors.brandWhite};
    bottom: -1px;
    left: -1px;
    text-shadow: 0px 4px 8px #dde1e6;
    width: 100%;

    ${props =>
    props.darkTheme &&
    css`
        color: ${theme.colors.brandBlack};
      `};

    ${props =>
    props.leftArrow &&
    css`
        text-align: left;
        padding-left: 7px;
    `};
    ${props =>
    props.topArrow &&
    css`
        transform: rotate(180deg);
        bottom: unset;
        top: -3px;
    `};
  }

  ${props =>
    props.noTooltipArrow &&
    css` &:after {
      content: none;}
    `}

  ${props =>
    props.showTooltip &&
    css`
      visibility: visible;
    `};

  ${props =>
    props.tooltipOffsetTop &&
    css`
      margin-top: ${`${-props.tooltipOffsetTop}px`};
    `};

  ${props =>
    props.tooltipOffsetLeft &&
    css`
      margin-left: ${`${-props.tooltipOffsetLeft}px`};
    `};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${`${props.maxWidth}px`};
    `};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.darkTheme &&
    css`
      background-color: ${theme.colors.brandBlack};
      color: ${theme.colors.brandWhite};
    `};

  ${props =>
    props.borderRadius &&
    css`
      border-radius: ${`${props.borderRadius}px`};
    `};
`;

interface TooltipHoverContentProps {
  maxWidth?: number;
  centered?: boolean;
  darkTheme?: boolean;
  leftArrow?: boolean;
  topArrow?: boolean;
  borderRadius?: number;
  noTooltipArrow?: boolean;
}

export const TooltipHoverContent = styled.div<TooltipHoverContentProps>`
  display: -moz-inline-stack;
  display: inline-block;
  visibility: hidden;
  font-size: 12px;
  padding: 10px 15px 5px;
  position: absolute;
  box-shadow: 0px 6px 14px #dde1e6;
  background-color: ${theme.colors.brandWhite};
  max-width: 320px;
  z-index: 1;
  margin-top: -38px;
  margin-left: -100px;
  min-width: 180px;

  @media ${Devices.tablet} {
    font-size: 10px;
  }

  &:after {
    content: '▾';
    display: block;
    font-size: 2em;
    text-align: center;
    height: 0;
    line-height: 0;
    position: absolute;
    color: ${theme.colors.brandWhite};
    bottom: -1px;
    left: -1px;
    text-shadow: 0px 4px 8px #dde1e6;
    width: 100%;

    ${props =>
    props.darkTheme &&
    css`
        color: ${theme.colors.brandBlack};
      `};

    ${props =>
    props.leftArrow &&
    css`
        text-align: left;
        padding-left: 7px;
    `};
    ${props =>
    props.topArrow &&
    css`
        transform: rotate(180deg);
        bottom: unset;
        top: -3px;
    `};
  }

  ${props =>
    props.noTooltipArrow &&
    css` &:after {
      content: none;}
    `}
    ${props =>
    props.maxWidth &&
      css`
        max-width: ${`${props.maxWidth}px`};
      `};
  
    ${props =>
    props.centered &&
      css`
        text-align: center;
      `};
  
    ${props =>
    props.darkTheme &&
      css`
        background-color: ${theme.colors.brandBlack};
        color: ${theme.colors.brandWhite};
      `};
  
    ${props =>
    props.borderRadius &&
      css`
        border-radius: ${`${props.borderRadius}px`};
      `};

      `;

export const TooltipHover = styled.div`
  display: inline-block;
  position: relative;
  :hover {
    ${TooltipHoverContent}{
      visibility:visible ;
    }
  }
`;
