import CustomerSite, { ICustomerSite } from 'contracts/models/service/CustomerSite';
import CustomerUserSite, {
  CustomerUserSiteAssigned,
  ICustomerUserSite, ICustomerUserSiteAssigned
} from 'contracts/models/service/CustomerUserSite';
import { AddTagSitesToUserRequest, AddUserSitesRequest, RemoveUserSitesRequest, UpdateUserSitesRequest } from 'contracts/types/request';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getCustomerSites = async(): Promise<CustomerSite[]> => {
  const response = await httpCustomerCore.get<CustomerSite[]>('sites', {
    transformResponse: transformServiceResponse<ICustomerSite, CustomerSite>(
      CustomerSite,
    ),
  });
  return response.data;
};

export const getUserSites = async(email?: string): Promise<CustomerUserSite[]> => {
  const response = await httpCustomerCore.get<CustomerUserSite[]>(
    email ? `sites/user?email=${email}` : 'sites/user',
    {
      transformResponse: transformServiceResponse<
        ICustomerUserSite,
        CustomerUserSite
      >(CustomerUserSite),
    },
  );
  return response.data;
};

export const getUserUnlinkedSites = async(email: string): Promise<CustomerSite[]> => {
  const response = await httpCustomerCore.get<CustomerSite[]>(
    `sites/user-unlinked?email=${email}`,
    {
      transformResponse: transformServiceResponse<ICustomerSite, CustomerSite>(
        CustomerSite,
      ),
    },
  );
  return response.data;
};

export const getAllUserSites = async(email: string): Promise<CustomerUserSiteAssigned[]> => {
  const response = await httpCustomerCore.get<CustomerUserSiteAssigned[]>(
    `sites/user/all?email=${email}`,
    {
      transformResponse: transformServiceResponse<
        ICustomerUserSiteAssigned, 
        CustomerUserSiteAssigned
      >(CustomerUserSiteAssigned),
    },
  );
  return response.data;
};

export const addUserSites = async(params: AddUserSitesRequest): Promise<unknown> => {
  const response = await httpCustomerCore.post('sites/user', params);
  return response.data;
};

export const removeUserSites = async(params: RemoveUserSitesRequest): Promise<unknown> => {
  const response = await httpCustomerCore.post('sites/user/delete', params);
  return response.data;
};

export const addRemoveUserSites = async(params: UpdateUserSitesRequest): Promise<unknown> => {
  const paramsAdd: AddUserSitesRequest = {
    emailAddress: params.emailAddress,
    sites: params.addSites
  };
  const paramsRemove: RemoveUserSitesRequest = {
    emailAddress: params.emailAddress,
    sites: params.removeSites
  };
  let response: unknown;
  if (paramsAdd.sites.length > 0) {
    const responseAdd = await httpCustomerCore.post('sites/user', paramsAdd);
    response = response || responseAdd.data;
  }
  if (paramsRemove.sites.length > 0) {
    const responseRemove = await httpCustomerCore.post('sites/user/delete', paramsRemove);
    response = response || responseRemove.data;
  }
  return response;
};

export const addTagSitesToUser = async(params: AddTagSitesToUserRequest): Promise<unknown> => {
  const response = await httpCustomerCore.post('sites/tags/user', params);
  return response.data;
};
