import React, { PureComponent } from 'react';

import { FilterRequestType } from 'contracts/models';
import { RequestTypeFilter as RequestTypeFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class RequestTypesFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (ordType: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      ordType,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled, isLoading, requestTypes } = this.props;
    return (
      <DropdownFilter
        label={translate('core.requestType')}
        values={requestTypes.map(requestType => ({
          id: requestType.ordType,
          label: requestType.typeDescr,
        }))}
        selectedIds={currentFilter.ordType || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    );
  }
}

interface ComponentProps {
  requestTypes: Array<Required<FilterRequestType>>;
  currentFilter: RequestTypeFilterType;
  onFilterChanged: (filter: RequestTypeFilterType) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export default RequestTypesFilter;
