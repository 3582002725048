import { EquipmentType } from 'contracts/enums';

export interface IShoppingCart {
  readonly alias: string;
  readonly startDate: string;
  readonly requestedBy: string;
  readonly updateSvcOrd: string;
  readonly token: string;
  readonly svcId: number;
  readonly siteId: string;
  readonly custId: string;
  readonly businessCode: string;
  readonly address: string;
  readonly zipCode: string;
  readonly equipment: string;
  readonly service: string;
  readonly material: string;
  readonly schedule: string;
  readonly frequency: string;
  readonly quantity: number;
  readonly base: QuoteRate[];
  readonly extras: QuoteRate[];
  readonly optionalExtras: QuoteRate[];
  readonly serviceLabel: EquipmentType;
  readonly materialLabel: string;
  readonly scheduleLabel: string;
  readonly frequencyLabel: string;
  readonly equipmentLabel: string;
  readonly isReadonly: boolean;
  readonly minimumStartDateOffset: number;
}

class ShoppingCart {
  alias?: string;
  startDate?: string;
  requestedBy?: string;
  updateSvcOrd?: string;
  token?: string;
  svcId?: number;
  siteId?: string;
  custId?: string;
  businessCode?: string;
  address?: string;
  zipCode?: string;
  equipment?: string;
  service?: string;
  material?: string;
  schedule?: string;
  frequency?: string;
  quantity?: number;
  base?: QuoteRate[];
  extras?: QuoteRate[];
  optionalExtras?: QuoteRate[];
  serviceLabel?: EquipmentType;
  materialLabel?: string;
  scheduleLabel?: string;
  frequencyLabel?: string;
  equipmentLabel?: string;
  isReadonly?: boolean;
  minimumStartDateOffset?: number;

  constructor(data?: Partial<IShoppingCart>) {
    this.alias = data?.alias;
    this.startDate = data?.startDate;
    this.requestedBy = data?.requestedBy;
    this.updateSvcOrd = data?.updateSvcOrd;
    this.token = data?.token;
    this.svcId = data?.svcId;
    this.siteId = data?.siteId;
    this.custId = data?.custId;
    this.businessCode = data?.businessCode;
    this.address = data?.address;
    this.zipCode = data?.zipCode;
    this.equipment = data?.equipment;
    this.service = data?.service;
    this.material = data?.material;
    this.schedule = data?.schedule;
    this.frequency = data?.frequency;
    this.quantity = data?.quantity;
    this.base = data?.base;
    this.extras = data?.extras;
    this.optionalExtras = data?.optionalExtras;
    this.serviceLabel = data?.serviceLabel;
    this.materialLabel = data?.materialLabel;
    this.scheduleLabel = data?.scheduleLabel;
    this.frequencyLabel = data?.frequencyLabel;
    this.equipmentLabel = data?.equipmentLabel;
    this.isReadonly = data?.isReadonly;
    this.minimumStartDateOffset = data?.minimumStartDateOffset;
  }
}

export interface QuoteRate {
  code: string;
  label: string;
  value: number;
  description?: string | null;
  uom: string;
  uomCode: string;
  includes?: IncludeRate[] | null;
}

export interface IncludeRate {
  uom: string;
  value: number;
}

export default ShoppingCart;
