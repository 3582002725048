import { TableData } from 'contracts/types/component';

export interface ISiteProfileNote {
  readonly noteId: number;
  readonly custId: string;
  readonly siteId: string;
  readonly note: string;
  readonly type: string;
  readonly status: boolean;
  readonly date: string;
  readonly user: string;
}

class SiteProfileNote implements TableData {
  noteId?: number;
  custId?: string;
  siteId?: string;
  note?: string;
  type?: string;
  status?: boolean;
  date?: string;
  user?: string;
  isHidden?: boolean;

  constructor(data?: Partial<ISiteProfileNote>) {
    this.noteId = data?.noteId;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.note = data?.note;
    this.type = data?.type;
    this.status = data?.status;
    this.date = data?.date;
    this.user = data?.user;
  }
}

export default SiteProfileNote;
