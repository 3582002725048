import styled from 'styled-components';

import { FormGroup } from 'core/components/HookFormComponents/styled';
import Form from 'core/components/HookFormComponents/styled/Form';
import { Select } from 'core/components/HookFormComponents/styled/Select';
import { ButtonSet } from 'core/components/styled';

export const EditUserFormWrapper = styled(Form)`
  margin: 40px;
  justify-content: space-between;

  ${ButtonSet}{
    flex: 0 0 100%;
  }
`;

export const ChangePasswordFormWrapper = styled(Form)`
  margin: 40px;
  flex-direction: column;
  justify-content: center;
`;

export const TagsSortingFormWrapper = styled(Form)`
  margin: 0;
  flex-direction: column;
  justify-content: center;
  ${Select}{
    padding-left: 10px;
  }
`;

export const AddTagFormWrapper = styled(Form)`
  margin: 0;
  flex-direction: column;
  padding: 40px;
`;

export const AddUserFormWrapper = styled(Form)`
  margin: 40px;
  justify-content: space-between;
  ${FormGroup} {
    flex: 1 0 45%;
    margin: 0 20px 20px;
  }
`;

export const RegisterFormWrapper = styled(Form)`
  margin: 10px 0px 0px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const ForgotPasswordFormWrapper = styled(Form)`
  margin:0px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
