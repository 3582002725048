import * as signalR from '@aspnet/signalr';
import moment from 'moment';

import NotificationItem, {
  INotificationItem,
  INotificationItemDetails,
  NotificationItemDetails,
} from 'contracts/models/service/NotificationItem';
import {
  NotificationsListRequest,
  UnreadNotificationCounterRequest,
} from 'contracts/types/request';
import { UnreadNotificationsCounterResponse } from 'contracts/types/service';
import Environments from 'core/constants/Environments';
import { environment, apiBaseURLs } from 'core/services/environment';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

import { getSessionAuthToken } from './session';

export async function getNotifications({
  fromDate,
  endDate,
  pageNumber,
  pageSize,
  storeIds,
  searchTerm,
  responseWithSites
}: NotificationsListRequest): Promise<NotificationItem[]> {
  const response = await httpCustomerCore.post<NotificationItem[]>(
    'notification/all',
    { fromDate, endDate, storeIds, searchTerm, pageNumber, pageSize, responseWithSites },
    {
      transformResponse: transformServiceResponse<
        INotificationItem,
        NotificationItem
      >(NotificationItem),
    },
  );
  return response.data;
}

export async function getNotificationDetails(
  notificationId: number,
): Promise<NotificationItemDetails> {
  const response = await httpCustomerCore.get<NotificationItemDetails>(
    `notification/${notificationId}/details`,
    {
      transformResponse: transformServiceResponse<INotificationItemDetails, NotificationItemDetails>(
        NotificationItemDetails,
      ),
    },
  );
  return response.data;
}

export async function getUnreadNotificationsCounter({
  startDate,
  endDate,
}: UnreadNotificationCounterRequest): Promise<UnreadNotificationsCounterResponse> {
  const parsedFromDate = moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss');
  const intermediaryEndDate = moment(endDate).format('MM - DD - YYYY');
  const parsedEndDate = moment(
    intermediaryEndDate?.replace(/\s+/g, '') + ' 23:59:59.000',
    'MM-DD-YYYY HH:mm:ss.SSS',
  )
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');

  const response = await httpCustomerCore.post('notification/unread-count', {
    startDate: parsedFromDate,
    endDate: parsedEndDate,
  });
  return response.data;
}

const signalRLoggerConfigure = (): signalR.LogLevel => {
  if (environment === Environments.local) {
    return signalR.LogLevel.Trace;
  } else if (environment === Environments.development) {
    return signalR.LogLevel.Information;
  } else return signalR.LogLevel.None;
};

export async function listenNotification(callBack: () => void): Promise<void> {
  const authToken: string | Promise<string> = getSessionAuthToken();
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${apiBaseURLs.customerCore}/hubs/notification`, { accessTokenFactory: () => authToken.replace('"', '').replace('"', '').replace('bearer ', '') })
    .configureLogging(signalRLoggerConfigure())
    .build();
  
  let connected = false;
  if (!connected) {
    connection.start()
      .then(() => {
        connected = true;
        connection.on('NewNotification', message => {
          if (message) {
            callBack();
          }
        });
      })
      // eslint-disable-next-line no-console
      .catch(e => console.log('Connection failed: ', e));
  }
}

export async function markNotificationsAsRead(
  notificationIds: number[],
): Promise<void> {
  const response = await httpCustomerCore.post('notification/mark-read', {
    notificationIds,
  });
  return response.data;
}

export async function markNotificationAsViewed(
  notificationId: number,
): Promise<void> {
  const response = await httpCustomerCore.post('notification/mark-viewed', {
    notificationId,
  });
  return response.data;
}
