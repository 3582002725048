export interface IFilterRequester {
  readonly requestedByEmail: string;
  readonly requestedByName: string;
}

class FilterRequester {
  requestedByEmail?: string;
  requestedByName?: string;

  constructor(data?: Partial<IFilterRequester>) {
    this.requestedByEmail = data?.requestedByEmail;
    this.requestedByName = data?.requestedByName;
  }
}

export default FilterRequester;
