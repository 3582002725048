import update from 'immutability-helper';

import { HaulerBillConsolidation } from 'contracts/models';
import {
  ActionDispatcher,
  HaulerBillConsolidationAction,
} from 'contracts/types/action';
import {
  HaulerBillConsolidationRequest,
} from 'contracts/types/request';
import {
  ApplicationState,
  HaulerBillConsolidationState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  getInvoicesList, getVendors,
} from 'financial/services/haulerBillConsolidationServices';
import { HaulerBillConsolidationVendorModel } from 'contracts/models/service/HaulerBillConsolidation';

// Actions Keys
const ROOT_KEY = 'financial/haulerBillConsolidation';
enum ActionKey {
  LOAD_INVOICES_LIST = 'financial/haulerBillConsolidation/LOAD_INVOICES_LIST',
  EXTEND_INVOICES_LIST = 'financial/haulerBillConsolidation/EXTEND_INVOICES_LIST',
  LOAD_VENDORS = 'financial/haulerBillConsolidation/LOAD_VENDORS',
  RESET = 'financial/haulerBillConsolidation/RESET',
}

// Initial State
const getInitialState: () => HaulerBillConsolidationState = () => {
  return {
    invoicesList: [],
    vendors: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_INVOICES_LIST,
  ActionKey.EXTEND_INVOICES_LIST,
  ActionKey.LOAD_VENDORS
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  HaulerBillConsolidationState,
  HaulerBillConsolidationAction
> = {
  [ActionKey.LOAD_INVOICES_LIST]: (state, action) => {
    const { invoicesList } = action;
    return update(state, {
      $merge: { invoicesList },
    });
  },
  [ActionKey.EXTEND_INVOICES_LIST]: (state, action) => {
    const { invoicesList: newInvoicesList } = action;
    const invoicesList = [...state.invoicesList, ...(newInvoicesList ? newInvoicesList : [])];
    return update(state, {
      $merge: { invoicesList },
    });
  },
  [ActionKey.LOAD_VENDORS]: (state, action) => {
    const { vendors } = action;
    return update(state, {
      $merge: { vendors },
    });
  },
};

export const reducer = getReducerBuilder<
HaulerBillConsolidationState,
HaulerBillConsolidationAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_INVOICES_LIST: (
    invoicesList?: HaulerBillConsolidation[],
  ): HaulerBillConsolidationAction => ({
    type: ActionKey.LOAD_INVOICES_LIST,
    invoicesList,
  }),
  EXTEND_INVOICES_LIST: (
    invoicesList?: HaulerBillConsolidation[],
  ): HaulerBillConsolidationAction => ({
    type: ActionKey.EXTEND_INVOICES_LIST,
    invoicesList,
  }),
  LOAD_VENDORS: (
    vendors?: HaulerBillConsolidationVendorModel[],
  ): HaulerBillConsolidationAction => ({
    type: ActionKey.LOAD_VENDORS,
    vendors,
  }),
  RESET: (): HaulerBillConsolidationAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadInvoicesList = (invoicesListParams: HaulerBillConsolidationRequest, extend = false) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_INVOICES_LIST,
    () => getInvoicesList(invoicesListParams),
    result => {
      if (extend) {
        dispatch(actionMap.EXTEND_INVOICES_LIST(result.items));
      } else {
        dispatch(actionMap.LOAD_INVOICES_LIST(result.items));
      }
      
    },
    () => {
      dispatch(actionMap.LOAD_INVOICES_LIST());
    },
  );
const loadVendors = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_VENDORS,
    () => getVendors(),
    result => {
      dispatch(actionMap.LOAD_VENDORS(result));
    },
    () => {
      dispatch(actionMap.LOAD_VENDORS());
    },
  );

const haulerBillConsolidationDuck = {
  thunks: { loadInvoicesList, loadVendors },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};

export default haulerBillConsolidationDuck;
