enum SubscriptionComponentKey {
  // REPORTS
  DashboardPage = 'DashboardPage',
  OptimizationReportPage = 'OptimizationReportPage',
  OptimizationDetailsPage = 'CompactorProfilePage',
  FrontLoadDiversionReportPage = 'FrontLoadDiversionReportPage',
  FrontLoadDiversionDetailsPage = 'FrontLoadDiversionReportModelingPage',
  UploadedDocsPage = 'UploadedDocsPage',
  PowerBiPage = 'PowerBiPage',
  FoundryPage = 'FoundryPage',
  RightSizingPage = 'RightSizingPage',
  ServiceConfirmationAnalytics = 'ServiceConfirmationAnalyticsPage',

  // SERVICES
  ServiceLocationsPage = 'ServiceLocationsPage',
  ServiceRequestsPage = 'ServiceRequestsPage',
  ServiceApprovalPage = 'ServiceApprovalPage',
  ServiceInsightsPage = 'ServiceInsightsPage',
  DisposalAuditPage = 'DisposalAuditPage',
  NewServiceDetailsPageWrapper = 'NewServiceDetailsPageWrapper',
  NewServiceStartDatePage = 'NewServiceStartDatePage',
  NewServiceSummaryPage = 'NewServiceSummaryPage',
  NewServiceSubmissionSummaryPage = 'NewServiceSubmissionSummaryPage',
  NewSimplifiedServiceDetailsPageWrapper = 'NewSimplifiedServiceDetailsPageWrapper',
  NewSimplifiedServiceSummaryPage = 'NewSimplifiedServiceSummaryPage',
  ServiceSiteProfilePageWrapper = 'ServiceSiteProfilePageWrapper',
  EditSitePage = 'EditSitePage',
  ServiceUpdatePage = 'ServiceUpdatePage',
  ServiceAgreementPage = 'ServiceAgreementPage',
  ServiceAgreementsPage = 'ServiceAgreementsPage',

  // UNMANAGED SERVICES
  UnmanagedServicesLocationsPage = 'BulkLocationsPage',
  BulkOnDemandServicesUploadPage = 'BulkOnDemandServicesUploadPage',
  BulkRecurringServicesUploadPage = 'BulkRecurringServicesUploadPage',
  BulkSeasonalServicesUploadPage = 'BulkSeasonalServicesUploadPage',
  ServicesUpdatePage = 'ServicesUpdatePage',
  OnDemandPickUpsPage = 'OnDemandPickUpsPage',

  // BILLING
  SummaryPage = 'SummaryPage',
  PaymentSetupPage = 'PaymentSetupPage',
  MakePaymentPage = 'MakePaymentPage',
  CompletePaymentPage = 'CompletePaymentPage',

  // FINANCE
  InvoiceDetailsPage = 'InvoiceDetailsPage',
  InvoicesListPage = 'InvoicesListPage',
  SavingsPage = 'SavingsPage',
  InvoiceAuditPage = 'InvoiceAuditPage',
  HaulerBillConsolidationPage = 'HaulerBillConsolidationPage',
  SpendByMaterialPage = 'SpendByMaterialPage',

  // API 
  ApiKeyManagementPage = 'KeyManagementPage',

  // SUSTAINABILITY
  DiversionPage = 'DiversionPage',
  GhGCarbonEmissionsPage = 'GhGCarbonEmissionsPage',
  CarbonReductionPage = 'CarbonReductionPage',
  MaterialProfilePage = 'MaterialProfilePage',
  SustainabilityHubPage = 'SustainabilityHubPage',

  // REGWATCH
  RegWatchAssessmentsPage = 'RegWatchAssessmentsPage',
  RegWatchUpdatesPage = 'RegWatchUpdatesPage',
  RegWatchUpdateDetailsPage = 'RegWatchUpdateDetailsPage',
  RegWatchLatestUpdateDetailsPage = 'RegWatchLatestUpdateDetailsPage',

  // WHOLESALE
  WholesaleFlow = 'WholesaleFlow',

  // RECOGNITION
  MediaContentPage = 'MediaContentPage',
  MarketingContentPage = 'MarketingContentPage'
}

export default SubscriptionComponentKey;
