export interface IFilterStore {
  readonly siteCustId: string;
  readonly siteId: string;
  readonly storeName: string;
  readonly siteCode: string;
  readonly stateId: string;
}

class FilterStore {
  siteCustId?: string;
  siteId?: string;
  storeName?: string;
  siteCode?: string;
  stateId?: string;

  constructor(data?: Partial<IFilterStore>) {
    this.siteCustId = data?.siteCustId;
    this.siteId = data?.siteId;
    this.storeName = data?.storeName;
    this.siteCode = data?.siteCode;
    this.stateId = data?.stateId;
  }
}

export default FilterStore;
