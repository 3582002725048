export enum Routes {
  root = '/sustainability',
  diversion = '/diversion',
  carbonReduction = '/carbon-reduction',
  materialProfile = '/material-profile',
  ghgCarbonEmissions = '/ghg-carbon-emissions',
  rubiconMethod = '/rubicon-method',
  sharepoint = '/sharepoint',
}

export default Routes;
