import styled, { css } from 'styled-components';

import { theme, Devices } from 'core/styles';

import { PanelContainer } from './Panel2';

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-left: 40px;
    @media ${Devices.tablet} {
      margin-left: 0;
    }
  }

  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const SquaresContainer = styled(PanelContainer)`
  max-width: 1040px;
`;

interface TileParams {
  serviceProfile?: boolean;
  spaced?: boolean;
  user?: boolean;
  ecoBill?: boolean;
}
export const Tile = styled.div<TileParams>`
  width: 100%;
  background-color: ${theme.colors.brandWhite};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 20px 35px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
  ${props =>
    props.serviceProfile &&
    css`
      background-color: ${theme.colors.brandWhite};
      box-shadow: none;
      border-bottom: 1px solid ${theme.colors.grayLight};
    `};
  ${props =>
    props.spaced &&
    css`
      padding: 40px 20px 20px;
    `};
  ${props =>
    props.user &&
    css`
      padding: 15px 0px 35px;
      background-color: ${theme.colors.grayLighter};
      box-shadow: none;
    `};
  ${props =>
    props.ecoBill &&
    css`
      background-color: rgba(84, 183, 127, 1);
      box-shadow: none;
    `};
`;
