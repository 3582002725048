import update from 'immutability-helper';

import { OptimizationReport } from 'contracts/models';
import {
  ActionDispatcher,
  OptimizationReportAction,
} from 'contracts/types/action';
import { LoadOptimizationReportArgs } from 'contracts/types/compactorProfile';
import {
  ApplicationState,
  OptimizationReportState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getOptimizationReport } from 'reports/services/optimizationReportServices';

// Action Keys
const ROOT_KEY = 'reports/optimization-report';
enum ActionKey {
  PAGE_DATA = 'reports/optimization-report/PAGE_DATA',
  TABLE_DATA = 'reports/optimization-report/TABLE_DATA',
  RESET = 'reports/optimization-report/RESET',
}

// Initial State
const getInitialState: () => OptimizationReportState = () => {
  return {
    optimizationReportData: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.TABLE_DATA,
  ActionKey.PAGE_DATA
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  OptimizationReportState,
  OptimizationReportAction
> = {
  [ActionKey.PAGE_DATA]: (state, action) => {
    const { optimizationReportData } = action;
    return update(state, { $merge: { optimizationReportData } });
  },
  [ActionKey.TABLE_DATA]: (state, action) => {
    const { optimizationReportData } = action;
    return update(state, { $merge: { optimizationReportData: {
      ...state.optimizationReportData,
      tableRows: optimizationReportData?.tableRows
    } } });
  },
};

export const reducer = getReducerBuilder<
  OptimizationReportState,
  OptimizationReportAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  PAGE_DATA: (
    optimizationReportData?: OptimizationReport,
  ): OptimizationReportAction => ({
    type: ActionKey.PAGE_DATA,
    optimizationReportData,
  }),
  TABLE_DATA: (
    optimizationReportData?: OptimizationReport,
  ): OptimizationReportAction => ({
    type: ActionKey.TABLE_DATA,
    optimizationReportData,
  }),
  RESET: (): OptimizationReportAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadOptimizationReport = (arg: LoadOptimizationReportArgs) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.PAGE_DATA,
    async() => getOptimizationReport(arg),
    result => {
      dispatch(actionMap.PAGE_DATA(result));
    },
    () => dispatch(actionMap.PAGE_DATA()),
  );

const loadOptimizationReportTable = (arg: LoadOptimizationReportArgs) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_DATA,
    async() => getOptimizationReport(arg),
    result => {
      dispatch(actionMap.TABLE_DATA(result));
    },
    () => dispatch(actionMap.TABLE_DATA()),
  );

const optimizationReportDuck = {
  thunks: { loadOptimizationReport, loadOptimizationReportTable },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default optimizationReportDuck;
