import { LibryoUpdateStatusType } from 'contracts/enums';
import { TableData } from 'contracts/types/component';

import { ICustomerSite } from './CustomerSite';

export interface IRegWatchUpdates {
  readonly sites: RegWatchSite[];
  readonly updates: RegWatchUpdate[];
  readonly map: RegWatchUpdateMapEntry[];
}

class RegWatchUpdates {
  sites?: RegWatchSite[];
  updates?: RegWatchUpdate[];
  map?: RegWatchUpdateMapEntry[];

  constructor(data?: Partial<IRegWatchUpdates>) {
    this.sites = data?.sites;
    this.updates = data?.updates;
    this.map = data?.map;
  }
}

export interface RegWatchSite extends ICustomerSite {
  libryoOrganizationId: number;
  libryoStreamId: number;
}

export interface RegWatchUpdate extends TableData {
  id: number;
  name: string;
  status: LibryoUpdateStatusType;
  description: string;
  noticeNumber: string;
  dateNotified: string | null;
  effectiveDate: string | null;
  highlights: string;
  url: string;
  downloadUpdateUrl: string;
}

export interface RegWatchUpdateMapEntry {
  streamId: number;
  custId: string;
  siteId: string;
  updateId: number;
}

export interface RegWatchSiteMapLocation {
  readonly id: string;
  readonly siteId: string;
  readonly custId: string;
  readonly name: string;
  readonly address: string;
  readonly lat: number;
  readonly lng: number;
}

export default RegWatchUpdates;
