import { AxiosError } from 'axios';

import { LoginSSOError, ServiceError, ServiceRequestError } from 'contracts/types/service';
import translate from 'core/helpers/translate';

export const formatServiceError = (
  error: AxiosError<ServiceError>,
): string => {
  let message = translate('core.anErrorOccurredLoadingThisPage');
  if (error && error.response) {
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
      if (message === '{"Message":"An error has occurred."}') {
        message = translate('core.anErrorHasOccuredPleaseTryAgain');
      }
    } else if (error.response.status === 401) {
      message = translate('core.youAreNotAuthorizedToViewThisPage');
    } else if (error.response.status === 400 && (error.response.data as ServiceRequestError).errors) {
      const serviceRequestError = error.response.data as ServiceRequestError;
      message = serviceRequestError.title;
      for (const key in serviceRequestError.errors) {
        if (serviceRequestError.errors[key].length > 0) {
          serviceRequestError.errors[key].forEach(errorValue => {
            message += `\n\t${errorValue}`;
          });
        }
      }
    }
  }
  return message;
};

export const formatLoginSSOError = (
  error: AxiosError<LoginSSOError>,
): string => {
  let emailAddress = '';
  if (error && error.response) {
    if (error.response.data && error.response.data.emailAddress) {
      emailAddress = error.response.data.emailAddress;
    } 
  }
  return emailAddress;
};
