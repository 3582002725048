import { CommodityOptionItemResponse } from 'contracts/types/service';

import { CustomerDocument } from '..';

export interface IServiceRequestOptions {
  readonly notApplicable: boolean;
  readonly dateSelectionRange: DateRangeSelection;
  readonly suggestedDays: SuggestedDay[];
  readonly showCommodityItems: boolean;
  readonly commodityItemsRequired: boolean;
  readonly commodityItemsEditable: boolean;
  readonly commodityItemOptions: CommodityItemOptionsModel[];
  readonly showRequestDate: boolean;
  readonly requestDateRequired: boolean;
  readonly requestDateEditable: boolean;
  readonly defaultRequestDateToCurrentDate: boolean;
  readonly showPoNumber: boolean;
  readonly poNumberRequired: boolean;
  readonly poNumberEditable: boolean;
  readonly showReferenceNumber: boolean;
  readonly referenceNumberRequired: boolean;
  readonly referenceNumberEditable: boolean;
  readonly showContainerNumber: boolean;
  readonly containerNumberRequired: boolean;
  readonly containerNumberEditable: boolean;
  readonly showBillOfLandingNumber: boolean;
  readonly billOfLandingNumberRequired: boolean;
  readonly billOfLandingNumberEditable: boolean;
  readonly showAdditionalNotes: boolean;
  readonly additionalNotesRequired: boolean;
  readonly additionalNotesEditable: boolean;
  readonly additionalNotesCustomLabel: string;
  readonly showUploadFiles: boolean;
  readonly uploadFilesRequired: boolean;
  readonly uploadFilesEditable: boolean;
  readonly uploadedFiles: CustomerDocument[];
  readonly showCompactorPsi: boolean;
  readonly compactorPsiRequired: boolean;
  readonly compactorPsiEditable: boolean;
  readonly compactorPsiNeedsConfirmationIfEmpty: boolean;
  readonly showRates: boolean;
  readonly serviceRates: ServiceRate[];
  readonly daysOffset: number;
  readonly showCostEvalationMessage: boolean;
  readonly showSuggestedServiceDate: boolean;
  readonly checkAvailabilityOnInit: boolean;
  readonly disableIfDuplicate: boolean;
  readonly checkAvailabilityOnSubmit: boolean;
  readonly checkRequestInSameDayOfFreq: boolean;
  readonly checkRequestInNextDayOfFreq: boolean;
  readonly allowZeroQuantity: boolean;
  readonly checkReferenceNumberUnique: boolean;
}

class ServiceRequestOptions {
  notApplicable?: boolean;
  dateSelectionRange?: DateRangeSelection;
  suggestedDays?: SuggestedDay[];
  showCommodityItems?: boolean;
  commodityItemsRequired?: boolean;
  commodityItemsEditable?: boolean;
  commodityItemOptions?: CommodityItemOptionsModel[];
  showRequestDate?: boolean;
  requestDateRequired?: boolean;
  requestDateEditable?: boolean;
  defaultRequestDateToCurrentDate?: boolean;
  showPoNumber?: boolean;
  poNumberRequired?: boolean;
  poNumberEditable?: boolean;
  showReferenceNumber?: boolean;
  referenceNumberRequired?: boolean;
  referenceNumberEditable?: boolean;
  showContainerNumber?: boolean;
  containerNumberRequired?: boolean;
  containerNumberEditable?: boolean;
  showBillOfLandingNumber?: boolean;
  billOfLandingNumberRequired?: boolean;
  billOfLandingNumberEditable?: boolean;
  showAdditionalNotes?: boolean;
  additionalNotesRequired?: boolean;
  additionalNotesEditable?: boolean;
  additionalNotesCustomLabel?: string;
  showUploadFiles?: boolean;
  uploadFilesRequired?: boolean;
  uploadFilesEditable?: boolean;
  uploadedFiles?: CustomerDocument[];
  showCompactorPsi?: boolean;
  compactorPsiRequired?: boolean;
  compactorPsiEditable?: boolean;
  compactorPsiNeedsConfirmationIfEmpty?: boolean;
  showRates?: boolean;
  serviceRates?: ServiceRate[];
  daysOffset?: number;
  showCostEvalationMessage?: boolean;
  showSuggestedServiceDate?: boolean;
  checkAvailabilityOnInit?: boolean;
  disableIfDuplicate?: boolean;
  checkAvailabilityOnSubmit?: boolean;
  checkRequestInSameDayOfFreq?: boolean;
  checkRequestInNextDayOfFreq?: boolean;
  allowZeroQuantity?: boolean;
  checkReferenceNumberUnique?: boolean;

  constructor(data?: Partial<IServiceRequestOptions>) {
    this.notApplicable = data?.notApplicable;
    this.dateSelectionRange = data?.dateSelectionRange;
    this.suggestedDays = data?.suggestedDays;
    this.showCommodityItems = data?.showCommodityItems;
    this.commodityItemsRequired = data?.commodityItemsRequired;
    this.commodityItemsEditable = data?.commodityItemsEditable;
    this.commodityItemOptions = data?.commodityItemOptions;
    this.showRequestDate = data?.showRequestDate;
    this.requestDateRequired = data?.requestDateRequired;
    this.requestDateEditable = data?.requestDateEditable;
    this.defaultRequestDateToCurrentDate =
      data?.defaultRequestDateToCurrentDate;
    this.showPoNumber = data?.showPoNumber;
    this.poNumberRequired = data?.poNumberRequired;
    this.poNumberEditable = data?.poNumberEditable;
    this.showReferenceNumber = data?.showReferenceNumber;
    this.referenceNumberRequired = data?.referenceNumberRequired;
    this.referenceNumberEditable = data?.referenceNumberEditable;
    this.showContainerNumber = data?.showContainerNumber;
    this.containerNumberRequired = data?.containerNumberRequired;
    this.containerNumberEditable = data?.containerNumberEditable;
    this.showBillOfLandingNumber = data?.showBillOfLandingNumber;
    this.billOfLandingNumberRequired = data?.billOfLandingNumberRequired;
    this.billOfLandingNumberEditable = data?.billOfLandingNumberEditable;
    this.showAdditionalNotes = data?.showAdditionalNotes;
    this.additionalNotesRequired = data?.additionalNotesRequired;
    this.additionalNotesEditable = data?.additionalNotesEditable;
    this.additionalNotesCustomLabel = data?.additionalNotesCustomLabel;
    this.showUploadFiles = data?.showUploadFiles;
    this.uploadFilesRequired = data?.uploadFilesRequired;
    this.uploadFilesEditable = data?.uploadFilesEditable;
    if (data?.uploadedFiles) {
      this.uploadedFiles = data?.uploadedFiles.map(
        file => new CustomerDocument(file),
      );
    }
    this.showCompactorPsi = data?.showCompactorPsi;
    this.compactorPsiRequired = data?.compactorPsiRequired;
    this.compactorPsiEditable = data?.compactorPsiEditable;
    this.compactorPsiNeedsConfirmationIfEmpty = data?.compactorPsiNeedsConfirmationIfEmpty;
    this.showRates = data?.showRates;
    this.serviceRates = data?.serviceRates;
    this.daysOffset = data?.daysOffset;
    this.showCostEvalationMessage = data?.showCostEvalationMessage;
    this.showSuggestedServiceDate = data?.showSuggestedServiceDate;
    this.checkAvailabilityOnInit = data?.checkAvailabilityOnInit;
    this.disableIfDuplicate = data?.disableIfDuplicate;
    this.checkAvailabilityOnSubmit = data?.checkAvailabilityOnSubmit;
    this.checkRequestInSameDayOfFreq = data?.checkRequestInSameDayOfFreq;
    this.checkRequestInNextDayOfFreq = data?.checkRequestInNextDayOfFreq;
    this.allowZeroQuantity = data?.allowZeroQuantity;
    this.checkReferenceNumberUnique = data?.checkReferenceNumberUnique;
  }
}

export interface DateRangeSelection {
  min: number | null;
  max: number | null;
}

export interface CommodityItemOptionsModel extends CommodityOptionItemResponse {
  label: string;
  serviceDescription: string;
  unitTypeOptions: CommoditySelectionModel[];
}

export interface CommoditySelectionModel {
  code: string;
  label: string;
}

export interface ServiceRate {
  key: string;
  title: string;
  fee?: number;
}

export interface SuggestedDay {
  tooltip: string;
  day: string;
}

export default ServiceRequestOptions;
