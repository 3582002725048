import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { NotificationItem } from 'contracts/types/component';
import { ApplicationState } from 'contracts/types/state';

import { deleteNotificationMessage } from '../ducks/notifier';

import Notification from './Notification';

const Notifier: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const notifications = useSelector(
    (state: ApplicationState) => state.core.notifier.notifications
  );

  const onNotificationClose = (notification: NotificationItem): void => {
    if (notification) {
      dispatch(deleteNotificationMessage(notification.id));
      if (notification.redirectUrl) {
        navigate(notification.redirectUrl);
      }
    }
  };

  const displayNotifications: JSX.Element[] = [];
  notifications.forEach(notification => {
    displayNotifications.push(
      <CSSTransition
        key={notification.id}
        classNames='notifier-'
        timeout={{ enter: 500, exit: 300 }}
      >
        <Notification
          type={notification.type}
          message={notification.message}
          onNotificationClose={() => onNotificationClose(notification)}
        />
      </CSSTransition>,
    );
    if (notification.duration && !notification.timer) {
      notification.timer = setTimeout(() => {
        onNotificationClose(notification);
      }, notification.duration);
    }
  });

  return (
    <TransitionGroup className='notifier'>
      {displayNotifications}
    </TransitionGroup>
  );
};

export default Notifier;
