import { TableData } from 'contracts/types/component';

export interface IMarketingContentItem {
  readonly name: string;
  readonly category: string;
  readonly type: string;
  readonly id: number;
  readonly thumbnailPreSignedUrl: string;
}

class MarketingContentItem implements TableData {
  id?: number;
  name?: string;
  type?: string;
  category?: string;
  thumbnailPreSignedUrl?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IMarketingContentItem>) {
    this.name = data?.name;
    this.category = data?.category;
    this.type = data?.type;
    this.id = data?.id;
    this.thumbnailPreSignedUrl = data?.thumbnailPreSignedUrl;
  }
}

export default MarketingContentItem;
