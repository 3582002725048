import update from 'immutability-helper';
import isNumber from 'lodash-es/isNumber';

import RecurringService from 'contracts/models/service/RecurringService';
import RecurringServiceDropdownOptions from 'contracts/models/service/RecurringServiceDropdownOptions';
import RecurringServiceOptions from 'contracts/models/service/RecurringServiceOptions';
import { ActionDispatcher, RecurringServicesEditAction } from 'contracts/types/action';
import { GetRecurringServicesRequest, SaasServiceRecurringOptionsRequest, RecurringServiceRequest, SeasonalServiceRequest, BulkUploadTemplateType } from 'contracts/types/request';
import { ApplicationState, RecurringServicesEditState, ReduceFunctionMap } from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as recurringServicesEditService from '../services/recurringServicesEditServices';

// Actions Keys
const ROOT_KEY = 'bulk-upload/bulk-upload';
enum ActionKey {
  LOAD_RECURRING_SERVICES = 'bulk-upload/LOAD_RECURRING_SERVICES',
  LOAD_RECURRING_SERVICE_OPTIONS = 'bulk-upload/LOAD_RECURRING_SERVICE_OPTIONS',
  LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS = 'bulk-upload/LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS',
  CREATE_RECURRING_SERVICE = 'bulk-upload/CREATE_RECURRING_SERVICE',
  UPDATE_RECURRING_SERVICE = 'bulk-upload/UPDATE_RECURRING_SERVICE',
  RESET = 'bulk-upload/RESET',
}

// Initial State
const getInitialState: () => RecurringServicesEditState = () => {
  return {
    services: [],
    serviceOptions: undefined,
    serviceDropdownOptions: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_RECURRING_SERVICES, 
  ActionKey.LOAD_RECURRING_SERVICE_OPTIONS,
  ActionKey.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS,
  ActionKey.CREATE_RECURRING_SERVICE,
  ActionKey.UPDATE_RECURRING_SERVICE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  RecurringServicesEditState,
  RecurringServicesEditAction
> = {
  [ActionKey.LOAD_RECURRING_SERVICES]: (state, action) => {
    if (!action.services) {
      return state;
    }
    const services = action.services;

    return update(state, { $merge: { services } });
  },
  [ActionKey.LOAD_RECURRING_SERVICE_OPTIONS]: (state, action) => {
    if (!action.serviceOptions) {
      return state;
    }
    const serviceOptions = action.serviceOptions;
    return update(state, { $merge: { serviceOptions } });
  },
  [ActionKey.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS]: (state, action) => {
    if (!action.serviceDropdownOptions) {
      return state;
    }
    const serviceDropdownOptions = action.serviceDropdownOptions;
    return update(state, { $merge: { serviceDropdownOptions } });
  },
  [ActionKey.CREATE_RECURRING_SERVICE]: (state, action) => {
    const { service } = action;
    if (
      !state.services ||
      !service
    ) {
      return state;
    }
    const newState = update(state, {
      services: { $push: [service] },
    });
    return newState;
  },
  [ActionKey.UPDATE_RECURRING_SERVICE]: (state, action) => {
    const { indexService, service } = action;
    if (
      !isNumber(indexService) ||
      !state.services ||
      state.services.length <= indexService ||
      !service
    ) {
      return state;
    }
    const newState = update(state, {
      services: {
        [indexService]: {
          $merge: {
            ...service,
          },
        },
      },
    });
    return newState;
  },
};

export const reducer = getReducerBuilder<RecurringServicesEditState, RecurringServicesEditAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_RECURRING_SERVICES: (services?: RecurringService[]): RecurringServicesEditAction => ({
    type: ActionKey.LOAD_RECURRING_SERVICES,
    services: services || [],
  }),
  LOAD_RECURRING_SERVICE_OPTIONS: (serviceOptions?: RecurringServiceOptions): RecurringServicesEditAction => ({
    type: ActionKey.LOAD_RECURRING_SERVICE_OPTIONS,
    serviceOptions: serviceOptions || undefined,
  }),
  LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS: 
  (serviceDropdownOptions?: RecurringServiceDropdownOptions): RecurringServicesEditAction => ({
    type: ActionKey.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS,
    serviceDropdownOptions: serviceDropdownOptions || undefined,
  }),
  CREATE_RECURRING_SERVICE: (service?: RecurringService): RecurringServicesEditAction => ({
    type: ActionKey.CREATE_RECURRING_SERVICE,
    service,
  }),
  UPDATE_RECURRING_SERVICE: (indexService?: number, service?: RecurringService): RecurringServicesEditAction => ({
    type: ActionKey.UPDATE_RECURRING_SERVICE,
    service,
    indexService,
  }),
  RESET: (): RecurringServicesEditAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadRecurringServices = (request: GetRecurringServicesRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_RECURRING_SERVICES,
    () => recurringServicesEditService.getServices(request),
    result => {
      dispatch(actionMap.LOAD_RECURRING_SERVICES(result.services));
    },
    () => {
      dispatch(actionMap.LOAD_RECURRING_SERVICES());
    },
    true,
  );

const loadRecurringServiceOptions = (req?: SaasServiceRecurringOptionsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_RECURRING_SERVICE_OPTIONS,
    () => recurringServicesEditService.getOptions(req),
    result => {
      dispatch(actionMap.LOAD_RECURRING_SERVICE_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_RECURRING_SERVICE_OPTIONS());
    },
    true,
  );

const loadDropdownOptions = (serviceType: BulkUploadTemplateType) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS,
    () => recurringServicesEditService.getDropdownOptions(serviceType),
    result => {
      dispatch(actionMap.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_RECURRING_SERVICE_DROPDOWN_OPTIONS());
    },
    true,
  );

const createRecurringService = (req: RecurringServiceRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CREATE_RECURRING_SERVICE,
    () => recurringServicesEditService.createRecurringService(req),
    () => {
      const service = {
        extServiceId: req.extServiceId,
        ...req.intervals[0],
        
      } as RecurringService;
      dispatch(actionMap.CREATE_RECURRING_SERVICE(service));
    },
    () => {
      dispatch(actionMap.CREATE_RECURRING_SERVICE());
    },
    true,
  );
  
const updateRecurringService = (req: RecurringServiceRequest, indexService: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_RECURRING_SERVICE,
    () => recurringServicesEditService.updateRecurringService(req),
    () => {
      const service = {
        extServiceId: req.extServiceId,
        ...req.intervals[0],
        
      } as RecurringService;
      dispatch(actionMap.UPDATE_RECURRING_SERVICE(indexService, service));
    },
    () => {
      dispatch(actionMap.UPDATE_RECURRING_SERVICE());
    },
    true,
  );

const createSeasonalService = (req: SeasonalServiceRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CREATE_RECURRING_SERVICE,
    () => recurringServicesEditService.createSeasonalService(req),
    () => {
    },
    () => {
      dispatch(actionMap.CREATE_RECURRING_SERVICE());
    },
    true,
  );

const updateSeasonalServicesBulk = (req: RecurringServiceRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_RECURRING_SERVICE,
    () => recurringServicesEditService.updateRecurringService(req),
    () => {
    },
    () => {
      dispatch(actionMap.UPDATE_RECURRING_SERVICE());
    },
    true,
  );
    
const recurringServicesEditDuck = {
  thunks: { 
    loadRecurringServices, 
    loadRecurringServiceOptions,
    loadDropdownOptions,
    createRecurringService,
    updateRecurringService,
    createSeasonalService,
    updateSeasonalServicesBulk
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default recurringServicesEditDuck;
