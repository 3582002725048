import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionItems from 'contracts/enums/SubscriptionItems';
import SubscriptionType from 'contracts/enums/SubscriptionType';
import ServiceRequestOptions from 'contracts/models/service/ServiceRequestOptions';
import { SiteProfileService } from 'contracts/models/service/SiteProfile';
import { SingleSite } from 'contracts/models/service/User';

import { MarketplaceRegisterFormData } from './form';
import { PreregisterCodeDataView, RequestServiceUpdateRequest } from './request';

export interface ServiceError {
  errorMessage: string;
  message: string;
  error: string;
}

export interface ServiceRequestError extends ServiceError {
  errors: { [key: string]: string[] };
  title: string;
}

export interface LoginSSOError {
  errorMessage: string;
  emailAddress?: string;
  message: string;
  error: string;
}

export interface GetCsvResponse {
  content: string;
  filename: string;
}

export interface GetBlobResponse {
  blob: Blob;
  filename: string;
}

export interface UploadNewServiceFileRequest {
  blob: Blob;
  filename: string;
}

export interface UploadNewServiceFileResponse {
  fileId: string;
  fileName: string;
}

export interface UploadFileRequest {
  blob: Blob;
  filename: string;
}

export interface UploadFileResponse {
  fileId: string;
  fileName: string;
  downloadId?: number;
}
export interface CommodityOptionItemRequest {
  label: string;
  quantity?: number;
  unitType: string;
  id: number;
  unitTypeOptions?: any;
  serviceDescription?: string;
}

export interface CommodityOptionItemResponse {
  commodityServiceId: number;
  quantity: number;
  unitType: string;
}

export interface CategoryEntry {
  identifier: string;
  name: string;
  ticketsCount: number[];
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface RegisterUserResult {
  message: string;
}

export interface ValidateRegistrationResult {
  isValid: boolean;
  message: string;
  customerId: string;
}

export interface ValidateTokenResponse {
  isTokenValid: boolean;
  customReponseMessage: string;
}

export interface IExistentServiceRequestWithOptions {
  custId: string;
  siteId: string;
  serviceProcessor: string;
  requestType: string;
  requestTypeLabel: string;
  locationName: string;
  serviceAddress: string;
  service: SiteProfileService;
  request: RequestServiceUpdateRequest;
  options: ServiceRequestOptions;
}

export class ExistentServiceRequestWithOptions implements IExistentServiceRequestWithOptions {
  custId: string;
  siteId: string;
  serviceProcessor: string;
  requestType: string;
  requestTypeLabel: string;
  locationName: string;
  serviceAddress: string;
  service: SiteProfileService;
  request: RequestServiceUpdateRequest;
  options: ServiceRequestOptions;

  constructor(data: IExistentServiceRequestWithOptions) {
    this.custId = data.custId;
    this.siteId = data.siteId;
    this.serviceProcessor = data.serviceProcessor;
    this.requestType = data.requestType;
    this.requestTypeLabel = data.requestTypeLabel;
    this.locationName = data.locationName;
    this.serviceAddress = data.serviceAddress;
    this.service = data.service;
    this.request = data.request;
    this.options = new ServiceRequestOptions(data.options);
  }
}

export interface ServiceApprovalDetailsResponse {
  custId: string;
  siteId: string;
  serviceId: number | null;
  serviceOrder: number;
  serviceType: string;
  serviceProcessor: string;
  serviceDescription: string;
  serviceAddress: string;
  zipCode: string;
  poNumber: string;
  serviceCode: string;
  matSubType: string;
  quantity: number;
  freqId: string;
  serviceDate: string;
  notes: string;
  compactorPsi: number | null;
  isApprovalRequestReadonly: boolean;
  service: ServiceApproval;
  options: ServiceRequestOptions;
}

export interface ServiceApproval {
  binId: string;
  containerType: string;
  size: string;
  quantity: number;
  wasteType: string;
  freq: string;
  requestType: string;
  requestTypeLabel: string;
}

export interface UnreadNotificationsCounterResponse {
  count: number;
}

export interface ScheduledDays {
  date: string;
  name: string;
}

export interface SuccesfulSaleModel {
  registrationUrl: string;
  registrationToken: string;
}

export interface FranchiseResponseModel {
  standardizedAddress: string;
  zipCode: string;
  exempted: boolean;
  franchise: boolean;
}

export interface SaveOrderResponseAppModel {
  alias: string;
}

export interface UploadServiceThumbnailRequest {
  content?: Blob;
  blob: Blob;
  filename: string;
}

export interface UploadServiceThumbnailResponse {
  fileId: string;
  fileName: string;
}

export interface SubmitThumbnailResponse {
  url: string;
}

export interface ServiceThumbnailRequest {
  svcId: number;
  siteId: string;
  custId: string;
}

export interface SaveServiceThumbnailRequest {
  svcId: number;
  siteId: string;
  custId: string;
  uploadedImageFile: UploadServiceThumbnailResponse;
}

export interface RegisterResult {
  isValid: boolean;
  errorMessage: string;
}

export interface PortalUserAuthenticationDetailsModel extends PortalCurrentUserDetailsModel, SsoUrlRedirectDataView {
  refreshToken: string;
  isBackOfficeSSO: boolean;
}

export interface MobileUserAuthenticationDetailsModel extends MobilePortalCurrentUserDetailsModel {
  refreshToken: string;
}

export interface PortalCurrentUserDetailsModel {
  firstName: string;
  lastName: string;
  emailAddress: string;
  customerName: string;
  businessType: string;
  channel: string;
  trackingIdentity: string;
  isCustomerOnHold: boolean;
  siteCount: number;
  singleSite: SingleSite;
  brandings: BrandingType[];
  hasApproval: boolean;
  hasDisposalAudit: boolean;
  hasLibryoAccess: boolean;
  automaticallyCreateUsers: boolean;
  isNewCreditCardAutopayEditable: boolean;
  newCreditCardAutopayDefaultValue: boolean;
  subscription: SubscriptionType;
  subscriptionItems: SubscriptionItems[];
}

export interface CustomerSiteSimpleModel {
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zip: string;
  name: string;
  custId: string;
  siteId: string;
  latitude: number;
  longitude: number;
  locationName: string;
  siteAddress: string;
  siteCode: string;
}

export interface MobilePortalCurrentUserDetailsModel extends PortalCurrentUserDetailsModel {
  sites: CustomerSiteSimpleModel[];
}

export interface SsoUrlRedirectDataView {
  doSsoRedirect: boolean;
  ssoRedirectUrl: string;
}

export interface RegistrationFormData {
  googleSiteKey: string;
  isRecaptchaEnabled: boolean;
}

export interface ActivationResponseValue extends MarketplaceRegisterFormData, PreregisterCodeDataView {}

export interface ActivationLookupResponse {
  value: ActivationResponseValue,
  isValid: boolean;
  error: string;
}

export interface RegisterWithRetryValidationResult {
  isValid: boolean;
  errorMessage: string;
  retryPreRegistrationCode: string;
}
