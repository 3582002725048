import {
  CommunicationSettingsModel,
  UserCommunicationSettingsRequestArg,
  UserCommunicationSettingsUserRequestArg,
} from 'contracts/models/service/CommunicationSettings';
import { httpCustomerCore } from 'core/services/http';

export async function getCommunicationSettings(): Promise<CommunicationSettingsModel> {
  const response = await httpCustomerCore.get<CommunicationSettingsModel>(
    `communication/settings`,
  );
  return response.data;
}

export async function setCommunicationSettings(
  request: UserCommunicationSettingsRequestArg,
): Promise<void> {
  const response = await httpCustomerCore.post('communication/settings', {
    ...request,
  });
  return response.data;
}

export async function getUserCommunicationSettings(email: string): Promise<CommunicationSettingsModel> {
  const response = await httpCustomerCore.get<CommunicationSettingsModel>(
    `communication/user/settings?email=${email}`
  );
  return response.data;
}

export async function setUserCommunicationSettings(
  request: UserCommunicationSettingsUserRequestArg,
): Promise<void> {
  const response = await httpCustomerCore.post(`communication/user/settings`, {
    ...request,
  });
  return response.data;
}
