import React, { PureComponent } from 'react';

import { SvcOrderTypeFilter as SvcOrderTypeFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

const svcOrderTypes = [
  { id: 'WO', label: translate('core.workOrders') },
  { id: 'RECC', label: translate('core.recurringOrders') },
  { id: 'CIETRADE', label: translate('services.recyclingCommodities') },
];

class SvcOrderTypeFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (selectedIds: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      orderType: selectedIds,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled } = this.props;
    return (
      <DropdownFilter
        label={translate('services.orderType')}
        values={svcOrderTypes}
        selectedIds={currentFilter.orderType || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
      />
    );
  }
}

interface ComponentProps {
  currentFilter: SvcOrderTypeFilterType;
  onFilterChanged: (filter: SvcOrderTypeFilterType) => void;
  isDisabled?: boolean;
}

export default SvcOrderTypeFilter;
