import {
  FrontLoadDiversionReportMetrics,
  FrontLoadDiversionReportSites,
} from 'contracts/types/frontLoadDiversion';

export interface IFrontLoadDiversionReport {
  readonly metrics: FrontLoadDiversionReportMetrics;
  readonly sites: FrontLoadDiversionReportSites[];
}

class FrontLoadDiversionReport {
  metrics?: FrontLoadDiversionReportMetrics;
  sites?: FrontLoadDiversionReportSites[];

  constructor(data?: Partial<IFrontLoadDiversionReport>) {
    this.metrics = data?.metrics;
    this.sites = data?.sites;
  }
}

export default FrontLoadDiversionReport;
