export interface IOnDemandPickUpDropdownOptions {
  readonly serviceCodes: OnDemandPickUpDropdownOptionItem[];
  readonly uom: OnDemandPickUpDropdownOptionItem[];
  readonly materialTypes: OnDemandPickUpDropdownOptionItem[];
  readonly frequency: OnDemandPickUpDropdownOptionItem[];
  readonly disposalMethods: OnDemandPickUpDropdownOptionItem[];
  readonly rules: OnDemandPickUpDropdownOptionItemDisposalMethod[];
}

class OnDemandPickUpDropdownOptions {
  serviceCodes?: OnDemandPickUpDropdownOptionItem[];
  uom?: OnDemandPickUpDropdownOptionItem[];
  materialTypes?: OnDemandPickUpDropdownOptionItem[];
  frequency?: OnDemandPickUpDropdownOptionItem[];
  disposalMethods?: OnDemandPickUpDropdownOptionItem[];
  rules?: OnDemandPickUpDropdownOptionItemDisposalMethod[];

  constructor(data?: Partial<IOnDemandPickUpDropdownOptions>) {
    this.serviceCodes = data?.serviceCodes;
    this.uom = data?.uom;
    this.materialTypes = data?.materialTypes;
    this.frequency = data?.frequency;
    this.disposalMethods = data?.disposalMethods;
    this.rules = data?.rules;
  }
}

export default OnDemandPickUpDropdownOptions;

export interface OnDemandPickUpDropdownOptionItem {
  id: string;
  value: string;
}

export interface OnDemandPickUpDropdownOptionItemDisposalMethod {
  id: string;
  value: string;
  referenceId: string;
  referenceValue: string;
}
