import { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { Location, useLocation } from 'react-router';

import { ModalActionProps, ModalComponentProps } from 'contracts/types/modals';
import modalManager from 'core/ducks/modalManager';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useOnLocationChange = (callback: (location?: Location) => void): void => {
  const location = useLocation();
  
  useEffect(() => {
    callback(location);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export const useModalManager = <T extends ModalComponentProps = ModalComponentProps>(): {
  openModal: (props: ModalActionProps<T>) => void;
  openConfirmation: (message: string,
    closeModal: (value?: boolean) => void,
    noBtnVisible?: boolean,
    yesBtnText?: string,
    noBtnText?: string) => void;
  closeModal: (id: number) => void;
} => {
  const dispatch = useDispatch();
  
  const openModal = (props: ModalActionProps<T>): void => {
    dispatch(modalManager.openModal(props));
  };

  const openConfirmation = (message: string,
    closeModal: (value?: boolean) => void,
    noBtnVisible?: boolean, yesBtnText?:
    string, noBtnText?: string): void => {
    dispatch(modalManager.openConfirmationModal(message, closeModal, noBtnVisible, yesBtnText, noBtnText));
  };

  const closeModal = (id: number): void => {
    dispatch(modalManager.closeModal(id));
  };

  return { openModal, openConfirmation, closeModal };
};
