export interface IFilterRequestType {
  readonly ordType: string;
  readonly typeDescr: string;
}

class FilterRequestType {
  ordType?: string;
  typeDescr?: string;

  constructor(data?: Partial<IFilterRequestType>) {
    this.ordType = data?.ordType;
    this.typeDescr = data?.typeDescr;
  }
}

export default FilterRequestType;
