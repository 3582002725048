import update from 'immutability-helper';

import ShoppingCart from 'contracts/models/service/ShoppingCart';
import {
  ActionDispatcher,
  ServiceAreementAction,
} from 'contracts/types/action';
import { RequestUpdateService } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceAgreementState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getAgreementToken,
  getOrderApproval,
  getServiceByAlias,
  requestUpdate,
} from '../services/serviceUpdateDetails';

import serviceRequestEventsDuck from './serviceRequestEvents';

// Actions Keys
const ROOT_KEY = 'services/serviceAgreement';
enum ActionKey {
  LOAD_SERVICE_DETAILS = 'services/serviceAgreement/LOAD_SERVICE_DETAILS',
  SIGN_AGREEMENT = 'services/serviceAgreement/SIGN_AGREEMENT',
  RESET_AGREEMENT_TOKEN = 'services/serviceAgreement/RESET_AGREEMENT_TOKEN',
  REQUEST_UPDATE_SERVICE = 'services/serviceAgreement/REQUEST_UPDATE_SERVICE',
  RESET = 'services/serviceAgreement/RESET',
}

// Initial state
const getInitialState: () => ServiceAgreementState = () => {
  return {
    serviceDetails: {},
    serviceAgreementToken: undefined,
    serviceUpdateResult: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SERVICE_DETAILS,
  ActionKey.SIGN_AGREEMENT,
  ActionKey.RESET_AGREEMENT_TOKEN,
  ActionKey.REQUEST_UPDATE_SERVICE,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceAgreementState,
  ServiceAreementAction
> = {
  [ActionKey.LOAD_SERVICE_DETAILS]: (state, action) => {
    const { serviceDetails } = action;
    return update(state, { $merge: { serviceDetails } });
  },
  [ActionKey.SIGN_AGREEMENT]: (state, action) => {
    const { serviceAgreementToken } = action;
    return update(state, { $merge: { serviceAgreementToken } });
  },
  [ActionKey.RESET_AGREEMENT_TOKEN]: state => {
    return update(state, { $merge: { serviceAgreementToken: undefined } });
  },
  [ActionKey.REQUEST_UPDATE_SERVICE]: (state, action) => {
    const { serviceUpdateResult } = action;
    return update(state, { $merge: { serviceUpdateResult } });
  },
};

export const reducer = getReducerBuilder<
  ServiceAgreementState,
  ServiceAreementAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
// TODO Fix any
const actionMap = {
  LOAD_SERVICE_DETAILS: (
    serviceDetails?: ShoppingCart,
  ): ServiceAreementAction => ({
    type: ActionKey.LOAD_SERVICE_DETAILS,
    serviceDetails,
  }),
  SIGN_AGREEMENT: (
    serviceAgreementToken?: string | undefined,
  ): ServiceAreementAction => ({
    type: ActionKey.SIGN_AGREEMENT,
    serviceAgreementToken,
  }),
  RESET_AGREEMENT_TOKEN: (): ServiceAreementAction => ({
    type: ActionKey.RESET_AGREEMENT_TOKEN,
  }),
  REQUEST_UPDATE_SERVICE: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    serviceUpdateResult?: any,
  ): ServiceAreementAction => ({
    type: ActionKey.SIGN_AGREEMENT,
    serviceUpdateResult,
  }),
  RESET: (): ServiceAreementAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadServiceAgreementDetails = (serviceId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_DETAILS,
    async() => getServiceByAlias(serviceId),
    result => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS());
    },
    true,
  );

const loadOrderApproval = (svcOrder: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_DETAILS,
    async() => getOrderApproval(svcOrder),
    result => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS(result));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS());
    },
    true,
  );

const signServiceAgreement = (serviceId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SIGN_AGREEMENT,
    async() => getAgreementToken(serviceId),
    result => {
      dispatch(actionMap.SIGN_AGREEMENT(result.token));
    },
    () => {
      dispatch(actionMap.SIGN_AGREEMENT());
    },
    true,
  );

const resetAgreement = () => (
  dispatch: ActionDispatcher,
) => {
  dispatch(actionMap.RESET_AGREEMENT_TOKEN());
};

const requestUpdateService = (params: RequestUpdateService) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REQUEST_UPDATE_SERVICE,
    async() => requestUpdate(params),
    result => {
      dispatch(actionMap.REQUEST_UPDATE_SERVICE(result));
      dispatch(
        serviceRequestEventsDuck.actions.setServiceOrder([
          {
            ...result,
            isServiceUpdate: true,
          },
        ]),
      );
    },
    () => {
      dispatch(actionMap.REQUEST_UPDATE_SERVICE());
    },
    true,
  );

const serviceAgreementDuck = {
  thunks: {
    loadServiceAgreementDetails,
    loadOrderApproval,
    signServiceAgreement,
    resetAgreement,
    requestUpdateService,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default serviceAgreementDuck;
