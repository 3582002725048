import React from 'react';

import translate from 'core/helpers/translate';

import {
  DropdownFilterPopupHeader,
  DropdownFilterSearchIcon,
  DropdownFilterSearchInput,
} from '../styled/DropdownFilter';

const DropdownFilterPopupSearch: React.FC<ComponentProps> = ({
  label,
  value,
  onSearchChange,
}) => (
  <DropdownFilterPopupHeader>
    <DropdownFilterSearchIcon />
    <DropdownFilterSearchInput
      value={value}
      onChange={onSearchChange}
      placeholder={`${translate('searchFor')} ${label}`}
    />
  </DropdownFilterPopupHeader>
);

interface ComponentProps {
  value: string;
  label: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default DropdownFilterPopupSearch;
