import update from 'immutability-helper';

import { Report } from 'contracts/models';
import { ActionDispatcher, UploadedDocsAction } from 'contracts/types/action';
import {
  ApplicationState,
  ReduceFunctionMap,
  UploadedDocsState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import * as uploadedDocsService from '../services/uploadedDocsService';

// Actions Keys
const ROOT_KEY = 'reports/uploaded-docs';
enum ActionKey {
  LOAD_REPORTS = 'reports/uploaded-docs/LOAD_REPORTS',
  RESET = 'reports/uploaded-docs/RESET',
}

// Initial State
const getInitialState: () => UploadedDocsState = () => {
  return {
    reportData: [],
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_REPORTS] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  UploadedDocsState,
  UploadedDocsAction
> = {
  [ActionKey.LOAD_REPORTS]: (state, action) => {
    if (!action.reportData) {
      return state;
    }
    const reportData = action.reportData;
    return update(state, { $merge: { reportData } });
  },
};

export const reducer = getReducerBuilder<UploadedDocsState, UploadedDocsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_REPORTS: (reportData?: Report[]): UploadedDocsAction => ({
    type: ActionKey.LOAD_REPORTS,
    reportData: reportData || [],
  }),
  RESET: (): UploadedDocsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadReports = (year: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_REPORTS,
    () => uploadedDocsService.getReports(year),
    result => dispatch(actionMap.LOAD_REPORTS(result)),
    () => {
      dispatch(actionMap.LOAD_REPORTS());
    },
    true,
  );

const uploadedDocsDuck = {
  thunks: { loadReports },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default uploadedDocsDuck;
