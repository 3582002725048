import moment from 'moment';

import { XDefinition, YDefinition } from 'contracts/models';
import LineSeries from 'contracts/models/chart/LineSeries';
import { formatThousandsToK } from 'core/services/formatter';

function getAxisLabelForDates(
  date: moment.Moment,
  isMonthly?: boolean,
  dailyInfo?: boolean,
): string {
  let axisLabel = '';
  const showOnAxis =
    !dailyInfo ||
    (isMonthly
      ? date.date() === 1 // if monthly then must be first day of the month
      : date.day() === 0); // if daily then must be first day of the week

  if (showOnAxis) {
    axisLabel = isMonthly
      ? date.format('MMM')
      : date.startOf('week').format('MM/DD');
  }

  return axisLabel;
}

function getWeekLongLabel(date: moment.Moment): string {
  const momentWeekStart = moment(date).startOf('week');
  const momentWeekEnd = moment(date).endOf('week');

  const startOfWeek = moment.max([momentWeekStart]);
  const endOfWeek = moment.min([momentWeekEnd]);

  const endOfWeekFormat =
    endOfWeek.month() === startOfWeek.month() ? 'Do' : 'MMMM Do';
  const result = `${startOfWeek.format('MMMM Do')} - ${endOfWeek.format(
    endOfWeekFormat,
  )}`;

  return result;
}

function getTooltipTitleLabelForDates(
  date: moment.Moment,
  isMonthly?: boolean,
  dailyInfo?: boolean,
): string {
  let tooltipTitleLabel = '';
  if (dailyInfo) {
    tooltipTitleLabel = date.format('MMMM Do');
  } else if (isMonthly) {
    tooltipTitleLabel = date.format('MMMM YYYY');
  } else {
    tooltipTitleLabel = getWeekLongLabel(date);
  }
  return tooltipTitleLabel;
}

export function buildXDefinitionForDates(
  dates: string[],
  isMonthly?: boolean,
  dailyInfo?: boolean,
): XDefinition {
  const dateObjects = dates.map(date => moment(date));

  const labels = dateObjects.map(date => [
    getAxisLabelForDates(date, isMonthly, dailyInfo),
    getTooltipTitleLabelForDates(date, isMonthly, dailyInfo),
  ]);

  return new XDefinition({
    labels,
    tooltipTitleLabel: label =>
      label.length > 1 ? label[1] : label.length > 0 ? label[0] : '',
  });
}

export function buildYDefinitionForMoney(series: LineSeries[]): YDefinition {
  return {
    series,
    axisLabel: amount => formatThousandsToK(amount, 0),
    labelRender: amount => formatThousandsToK(amount, 2),
  };
}
