import { WholesaleCart } from 'contracts/models';
import translate from 'core/helpers/translate';
import { initialNavigationItems } from 'wholesale/constants/navigationItems';

export const initialStorageState: WholesaleCart = new WholesaleCart({
  pagePath: translate('wholesale.location'),
  locations: [],
  activeAddressIndex: 0,
  navigationItems: initialNavigationItems,
  serviceCount: 0,
});

export default initialStorageState;
