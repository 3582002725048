import { TableData } from 'contracts/types/component';

export interface IInvoice {
  readonly custId: string;
  readonly siteId: string;
  readonly invoiceNumber: string;
  readonly invoiceDate: string;
  readonly invoicePeriod: string;
  readonly dueDate: string;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly invoiceTotal: number;
  readonly paymentStatus: string;
  readonly balance: number;
  readonly zuoraInvoiceId: string;
  readonly needPayment: boolean;
}

class Invoice implements TableData {
  custId?: string;
  siteId?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  invoicePeriod?: string;
  dueDate?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  invoiceTotal?: number;
  paymentStatus?: string;
  balance?: number;
  zuoraInvoiceId?: string;
  needPayment?: boolean;
  isHidden?: boolean;

  constructor(data?: Partial<IInvoice>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.invoiceNumber = data?.invoiceNumber;
    this.invoiceDate = data?.invoiceDate;
    this.invoicePeriod = data?.invoicePeriod;
    this.dueDate = data?.dueDate;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.invoiceTotal = data?.invoiceTotal;
    this.paymentStatus = data?.paymentStatus;
    this.balance = data?.balance;
    this.zuoraInvoiceId = data?.zuoraInvoiceId;
    this.needPayment = data?.needPayment;
  }
}

export default Invoice;
