import { TableData } from 'contracts/types/component';

export interface ICarbonEmissionsTableItemData {
  readonly serviceDate: string;
  readonly siteName: string;
  readonly siteAddress: string;
  readonly material: string;
  readonly co2Emissions: string;
  readonly bin: string,
  readonly binSize: string,
}

class CarbonEmissionsTableItemData implements TableData {
  serviceDate?: string;
  siteName?: string;
  siteAddress?: string;
  material?: string;
  co2Emissions?: string;
  bin?: string;
  binSize?: string;
  isHidden?: boolean;

  constructor(data?: Partial<ICarbonEmissionsTableItemData>) {
    this.serviceDate = data?.serviceDate;
    this.siteName = data?.siteName;
    this.siteAddress = data?.siteAddress;
    this.material = data?.material;
    this.co2Emissions = data?.co2Emissions;
    this.bin = data?.bin;
    this.binSize = data?.binSize;
  }
}

export default CarbonEmissionsTableItemData;
