import { FrontLoadDiversionReportSortKey } from 'contracts/enums';
import FrontLoadDiversionReport, {
  IFrontLoadDiversionReport,
} from 'contracts/models/service/FrontLoadDiversionReport';
import { FrontLoadDiversionReportRequestParam } from 'contracts/types/frontLoadDiversion';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getFrontLoadDiversionReport({
  fromDate,
  toDate,
  sortFields,
  frontLoadDiversionFilter,
}: FrontLoadDiversionReportRequestParam): Promise<FrontLoadDiversionReport> {
  const inputArg = {
    fromDate,
    toDate,
    filters: {
      ...getLocationFilterApiArg(frontLoadDiversionFilter),
      materialCategories: frontLoadDiversionFilter
        ? frontLoadDiversionFilter.materials || []
        : [],
      size:
        frontLoadDiversionFilter && frontLoadDiversionFilter.sizeRange
          ? {
            from: frontLoadDiversionFilter.sizeRange.from,
            to: frontLoadDiversionFilter.sizeRange.to,
          }
          : null,
    },
    orderByAscending: buildSortArg(
      sortFields.ascending,
      FrontLoadDiversionReportSortKey,
    ),
    orderByDescending: buildSortArg(
      sortFields.descending,
      FrontLoadDiversionReportSortKey,
    ),
  };

  const response = await httpCustomerCore.post<FrontLoadDiversionReport>(
    'frontload-diversion/get-sites',
    inputArg,
    {
      transformResponse: transformServiceResponse<
        IFrontLoadDiversionReport,
        FrontLoadDiversionReport
      >(FrontLoadDiversionReport),
    },
  );
  return response.data;
}

export async function getFrontLoadDiversionReportCsv({
  fromDate,
  toDate,
  frontLoadDiversionFilter,
}: FrontLoadDiversionReportRequestParam): Promise<GetCsvResponse> {
  const inputArg = {
    fromDate,
    toDate,
    filters: {
      ...getLocationFilterApiArg(frontLoadDiversionFilter),
      materialCategories: frontLoadDiversionFilter
        ? frontLoadDiversionFilter.materials || []
        : [],
      size:
        frontLoadDiversionFilter && frontLoadDiversionFilter.sizeRange
          ? {
            from: frontLoadDiversionFilter.sizeRange.from,
            to: frontLoadDiversionFilter.sizeRange.to,
          }
          : null,
    },
  };
  const response = await getCsv(
    httpCustomerCore,
    'frontload-diversion/generate-csv',
    inputArg,
  );
  return response;
}
