import { DateRange, CategoryEntry } from 'contracts/types/service';

export interface IServiceRequestsComparison {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly categoriesEntries: CategoryEntry[];
  readonly slaMetEntries: number[];
  readonly entriesCount: number;
  readonly totalServiceRequests: string;
}

class ServiceRequestsComparison {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  categoriesEntries?: CategoryEntry[];
  slaMetEntries?: number[];
  entriesCount?: number;
  totalServiceRequests?: number;

  constructor(data?: Partial<IServiceRequestsComparison>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.categoriesEntries = data?.categoriesEntries;
    this.slaMetEntries = data?.slaMetEntries;
    this.entriesCount = data?.entriesCount;
    this.totalServiceRequests =
      data && data.totalServiceRequests
        ? parseInt(data.totalServiceRequests, 10)
        : undefined;
  }
}

export default ServiceRequestsComparison;
