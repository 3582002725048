import styled from 'styled-components';

import { theme } from 'core/styles';

export const UnauthorizedPanel = styled.div`
  margin: 100px;
  padding: 20px;
  flex: 1;
  background-color: ${theme.colors.brandWhite};
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const UnauthorizedTitle = styled.h1`
  text-align: center;
  font-size: 28px;
  margin-top: 30px;
  color: ${props => props.theme.colors.brandPrimary};
`;

export const UnauthorizedMessage = styled.h1`
  text-align: center;
  font-size: 22px;
  color: ${props => props.theme.colors.brandPrimary};
`;
