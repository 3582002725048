enum FrontLoadDiversionReportSortKey {
  SiteCode = 'SiteCode',
  SiteName = 'SiteName',
  SiteAddress = 'SiteAddress',
  WasteTons = 'WasteTons',
  DivertedTons = 'DivertedTons',
  Diversion = 'Diversion',
}

export default FrontLoadDiversionReportSortKey;
