export interface IBlogPost {
  readonly summary?: string;
  readonly title: string;
  readonly image: string;
  readonly url: string;
  readonly publishDate: string;
}

class BlogPost {
  summary?: string;
  title?: string;
  image?: string;
  url?: string;
  publishDate?: string;

  constructor(data?: Partial<IBlogPost>) {
    this.summary = data?.summary;
    this.title = data?.title;
    this.image = data?.image;
    this.url = data?.url;
    this.publishDate = data?.publishDate;
  }
}

export default BlogPost;
