import {
  LoadCompactorDataArg,
  OptimizationEquipemntGoalResponse,
  OptimizationGoalArg,
  OptimizationServiceModel,
  OptimizationWorkOrderModel,
} from 'contracts/types/compactorProfile';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore } from 'core/services/http';

export async function getCompactorData(
  params: LoadCompactorDataArg,
): Promise<OptimizationServiceModel> {
  const response = await httpCustomerCore.post(
    'optimization/compactor/overview',
    {
      startDate: params.startDate,
      endDate: params.endDate,
      serviceId: params.serviceId,
      forecastStartDate: params.forecastStartDate,
      forecastEndDate: params.forecastEndDate,
    },
  );

  return response.data;
}

export async function editGoal(serviceId: number, goal: number): Promise<OptimizationEquipemntGoalResponse> {
  const response = await httpCustomerCore.put(
    'optimization/compactor/equipmentGoal',
    {
      serviceId,
      goal,
    },
  );
  return response.data;
}

export async function getCompactorTableData(
  params: LoadCompactorDataArg,
): Promise<OptimizationWorkOrderModel[]> {
  const response = await httpCustomerCore.post(
    'optimization/compactor/workOrders',
    {
      startDate: params.startDate,
      endDate: params.endDate,
      serviceId: params.serviceId,
    },
  );

  return response.data;
}

export async function getCompactorCsv({
  startDate,
  endDate,
  serviceId,
}: LoadCompactorDataArg): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'optimization/compactor/generate-csv',
    {
      startDate,
      endDate,
      serviceId,
    },
  );
  return response;
}

export async function submitOptimizationGoal(
  params: OptimizationGoalArg,
): Promise<unknown> {
  const response = await httpCustomerCore.post(
    'optimization/compactor/submit-goal',
    params,
  );

  return response.data;
}
