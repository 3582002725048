import { TableData } from 'contracts/types/component';

export interface IHaulerInvoice {
  readonly id: number;
  readonly hashId: string;
  readonly invoiceNumber: string;
  readonly billingDate: string;
  readonly invoiceTotal: number;
  readonly billingPeriod: string;
  readonly siteName: string;
  readonly siteAddress: string;
  readonly vendorName: string;
  readonly status: string;
  readonly hasImage: boolean;
  readonly hasPreview: boolean;
}

class HaulerInvoice implements TableData {
  id?: number;
  hashId?: string;
  invoiceNumber?: string;
  billingDate?: string;
  invoiceTotal?: number;
  billingPeriod?: string;
  siteName?: string;
  siteAddress?: string;
  vendorName?: string;
  status?: string;
  hasImage?: boolean;
  hasPreview?: boolean;
  isHidden?: boolean;

  constructor(data?: Partial<IHaulerInvoice>) {
    this.id = data?.id;
    this.hashId = data?.hashId;
    this.invoiceNumber = data?.invoiceNumber;
    this.billingDate = data?.billingDate;
    this.invoiceTotal = data?.invoiceTotal;
    this.billingPeriod = data?.billingPeriod;
    this.siteName = data?.siteName;
    this.siteAddress = data?.siteAddress;
    this.vendorName = data?.vendorName;
    this.status = data?.status;
    this.hasImage = data?.hasImage;
    this.hasPreview = data?.hasPreview;
  }
}

export default HaulerInvoice;
