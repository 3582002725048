import { RequestNewServiceResult, RequestServiceResult } from 'contracts/models';
import ShoppingCart, {
  IShoppingCart,
} from 'contracts/models/service/ShoppingCart';
import { ConfirmOrderRequestArg, RequestUpdateService } from 'contracts/types/request';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getAgreementToken = async(serviceId: string): Promise<{token: string}> => {
  const response = await httpCustomerCore.post(
    'service/get-service-agreement',
    {
      alias: serviceId,
    },
  );
  return response.data;
};

export const getServiceByAlias = async(serviceId: string): Promise<ShoppingCart> => {
  const response = await httpCustomerCore.get<ShoppingCart>(
    'service/get-update-request',
    {
      params: {
        alias: serviceId,
      },
      transformResponse: transformServiceResponse<IShoppingCart, ShoppingCart>(
        ShoppingCart,
      ),
    },
  );

  return response.data;
};

export const requestUpdate = async(params: RequestUpdateService): Promise<RequestNewServiceResult> => {
  const response = await httpCustomerCore.post(
    'service/confirm-update-request',
    {
      startDate: params.startDate,
      alias: params.serviceId,
      custId: params.custId,
      siteId: params.siteId,
      serviceAgreementToken: params.serviceAgreementToken,
    },
  );
  return response.data;
};

export const getOrderApproval = async(svcOrder: number): Promise<ShoppingCart> => {
  const response = await httpCustomerCore.get<ShoppingCart>(
    'service/update-request-approval',
    {
      params: {
        svcOrder,
      },
      transformResponse: transformServiceResponse<IShoppingCart, ShoppingCart>(
        ShoppingCart,
      ),
    },
  );
  return response.data;
};

export const approveOrder = async(params: ConfirmOrderRequestArg): Promise<RequestServiceResult[]> => {
  const response = await httpCustomerCore.post(
    'service/approve-update-request',
    params,
  );
  return response.data;
};

export const denyOrder = async(params: ConfirmOrderRequestArg): Promise<unknown> => {
  const response = await httpCustomerCore.post(
    'service/deny-update-request',
    params,
  );
  return response.data;
};
