import { BaseAction } from 'contracts/types/action';
import { BaseState } from 'contracts/types/state';

import ReducerBuilder from './ReducerBuilder';

function getReducerBuilder<S extends BaseState, A extends BaseAction>(
  rootName: string,
  getInitialState: () => S,
): ReducerBuilder<S, A> {
  const reducer = new ReducerBuilder<S, A>(rootName, getInitialState);
  return reducer;
}

export default getReducerBuilder;
