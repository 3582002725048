import update from 'immutability-helper';

import {
  ActionDispatcher,
  CompactorProfileAction,
} from 'contracts/types/action';
import {
  OptimizationServiceModel,
  OptimizationWorkOrderModel,
} from 'contracts/types/compactorProfile';
import {
  ApplicationState,
  ReduceFunctionMap,
  CompactorProfileState,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getCompactorData,
  getCompactorTableData,
  editGoal,
} from '../services/compactorService';

const ROOT_KEY = 'reports/compactor-profile';

enum ActionKey {
  COMPACTOR_DATA = 'reports/compactor-profile/LOAD_COMPACTOR_DATA',
  TABLE_DATA = 'reports/compactor-profile/LOAD_COMPACTOR_TABLE_DATA',
  EDIT_COMPACTOR_GOAL = 'reports/compactor-profile/EDIT_COMPACTOR_GOAL',
  RESET = 'reports/compactor-profile/RESET',
}

// Initial State
const getInitialState: () => CompactorProfileState = () => {
  return {
    compactorData: {} as OptimizationServiceModel,
    compactorTableData: [] as OptimizationWorkOrderModel[],
    tonnageGoal: 0,
  };
};

// Reducer
const reducerKeys = [ActionKey.COMPACTOR_DATA, ActionKey.EDIT_COMPACTOR_GOAL, ActionKey.TABLE_DATA] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CompactorProfileState,
  CompactorProfileAction
> = {
  [ActionKey.COMPACTOR_DATA]: (
    state: CompactorProfileState,
    action: CompactorProfileAction,
  ) => {
    const { compactorData } = action;
    return update(state, {
      $merge: {
        compactorData,
      },
    });
  },
  [ActionKey.EDIT_COMPACTOR_GOAL]: (
    state: CompactorProfileState,
    action: CompactorProfileAction,
  ) => {
    const { tonnageGoal } = action;
    return update(state, {
      $merge: {
        tonnageGoal,
      },
    });
  },
  [ActionKey.TABLE_DATA]: (
    state: CompactorProfileState,
    action: CompactorProfileAction,
  ) => {
    const { compactorTableData } = action;
    return update(state, { $merge: { compactorTableData } });
  },
};

export const reducer = getReducerBuilder<
  CompactorProfileState,
  CompactorProfileAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  TABLE_DATA: (compactorTableData?: OptimizationWorkOrderModel[]): CompactorProfileAction => ({
    type: ActionKey.TABLE_DATA,
    compactorTableData,
  }),
  COMPACTOR_DATA: (
    compactorData?: OptimizationServiceModel,
  ): CompactorProfileAction => ({
    type: ActionKey.COMPACTOR_DATA,
    compactorData,
  }),
  EDIT_COMPACTOR_GOAL: (tonnageGoal?: number): CompactorProfileAction => ({
    type: ActionKey.EDIT_COMPACTOR_GOAL,
    tonnageGoal
  }),
  RESET: (): CompactorProfileAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadCompactorData = (
  startDate: string,
  endDate: string,
  serviceId: number,
  forecastStartDate: string,
  forecastEndDate: string,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.COMPACTOR_DATA,
    async() =>
      getCompactorData({
        startDate,
        endDate,
        serviceId,
        forecastStartDate,
        forecastEndDate,
      }),
    result => {
      dispatch(actionMap.COMPACTOR_DATA(result));
    },
    () => dispatch(actionMap.COMPACTOR_DATA()),
  );

const loadCompactorTableData = (
  startDate: string,
  endDate: string,
  serviceId: number,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.TABLE_DATA,
    async() =>
      getCompactorTableData({
        startDate,
        endDate,
        serviceId,
      }),
    result => {
      dispatch(actionMap.TABLE_DATA(result));
    },
    () => dispatch(actionMap.TABLE_DATA()),
  );

export const editTonnageGoal = (serviceId: number, goal: number) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.EDIT_COMPACTOR_GOAL,
    async() =>
      editGoal(serviceId, goal),
    () => {
      dispatch(actionMap.EDIT_COMPACTOR_GOAL(goal));
    },
    () => dispatch(actionMap.EDIT_COMPACTOR_GOAL()),
  );

const compactorProfileDuck = {
  thunks: { loadCompactorData, loadCompactorTableData, editTonnageGoal },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default compactorProfileDuck;
