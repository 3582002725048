export interface IRecurringServiceOptions {
  readonly showExtServiceId: boolean;
  readonly extServiceIdRequired: boolean;
  readonly extServiceIdEditable: boolean;
  readonly showSite: boolean;
  readonly siteEditable: boolean;
  readonly siteRequired: boolean;
  readonly showStartDate: boolean;
  readonly startDateRequired: boolean;
  readonly startDateEditable: boolean;
  readonly showEndDate: boolean;
  readonly endDateRequired: boolean;
  readonly endDateEditable: boolean;
  readonly showServiceCode: boolean;
  readonly serviceCodeRequired: boolean;
  readonly serviceCodeEditable: boolean;
  readonly showServiceDescription: boolean;
  readonly serviceDescriptionRequired: boolean;
  readonly serviceDescriptionEditable: boolean;
  readonly showMaterialType: boolean;
  readonly materialTypeRequired: boolean;
  readonly materialTypeEditable: boolean;
  readonly showFrequency: boolean;
  readonly frequencyRequired: boolean;
  readonly frequencyEditable: boolean;
  readonly showWorkOrder: boolean;
  readonly workOrderRequired: boolean;
  readonly workOrderEditable: boolean;
  readonly showQuantity: boolean;
  readonly quantityRequired: boolean;
  readonly quantityEditable: boolean;
  readonly quantityMinValue: number;
  readonly quantityMaxValue: number;
  readonly showTotalPerMonth: boolean;
  readonly totalPerMonthRequired: boolean;
  readonly totalPerMonthEditable: boolean;
  readonly totalPerMonthMin: number;
  readonly showTotalPerOccurrence: boolean;
  readonly totalPerOccurrenceRequired: boolean;
  readonly totalPerOccurrenceEditable: boolean;
  readonly totalPerOccurrenceMin: number;
  readonly showDisposalMethod: boolean;
  readonly disposalMethodRequired: boolean;
  readonly disposalMethodEditable: boolean;
}

class RecurringServiceOptions {
  showExtServiceId?: boolean;
  extServiceIdRequired?: boolean;
  extServiceIdEditable?: boolean;
  showSite?: boolean;
  siteEditable?: boolean;
  siteRequired?: boolean;
  showStartDate?: boolean;
  startDateRequired?: boolean;
  startDateEditable?: boolean;
  showEndDate?: boolean;
  endDateRequired?: boolean;
  endDateEditable?: boolean;
  showServiceCode?: boolean;
  serviceCodeRequired?: boolean;
  serviceCodeEditable?: boolean;
  showServiceDescription?: boolean;
  serviceDescriptionRequired?: boolean;
  serviceDescriptionEditable?: boolean;
  showMaterialType?: boolean;
  materialTypeRequired?: boolean;
  materialTypeEditable?: boolean;
  showFrequency?: boolean;
  frequencyRequired?: boolean;
  frequencyEditable?: boolean;
  showWorkOrder?: boolean;
  workOrderRequired?: boolean;
  workOrderEditable?: boolean;
  showQuantity?: boolean;
  quantityRequired?: boolean;
  quantityEditable?: boolean;
  quantityMinValue?: number;
  quantityMaxValue?: number;
  showTotalPerMonth?: boolean;
  totalPerMonthRequired?: boolean;
  totalPerMonthEditable?: boolean;
  totalPerMonthMin?: number;
  showTotalPerOccurrence?: boolean;
  totalPerOccurrenceRequired?: boolean;
  totalPerOccurrenceEditable?: boolean;
  totalPerOccurrenceMin?: number;
  showDisposalMethod?: boolean;
  disposalMethodRequired?: boolean;
  disposalMethodEditable?: boolean;

  constructor(data?: Partial<IRecurringServiceOptions>) {
    this.showExtServiceId = data?.showExtServiceId;
    this.extServiceIdRequired = data?.extServiceIdRequired;
    this.extServiceIdEditable = data?.extServiceIdEditable;
    this.showSite = data?.showSite;
    this.siteEditable = data?.siteEditable;
    this.siteRequired = data?.siteRequired;
    this.showStartDate = data?.showStartDate;
    this.startDateRequired = data?.startDateRequired;
    this.startDateEditable = data?.startDateEditable;
    this.showEndDate = data?.showEndDate;
    this.endDateRequired = data?.endDateRequired;
    this.endDateEditable = data?.endDateEditable;
    this.showServiceCode = data?.showServiceCode;
    this.serviceCodeRequired = data?.serviceCodeRequired;
    this.serviceCodeEditable = data?.serviceCodeEditable;
    this.showServiceDescription = data?.showServiceDescription;
    this.serviceDescriptionRequired = data?.serviceDescriptionRequired;
    this.serviceDescriptionEditable = data?.serviceDescriptionEditable;
    this.showMaterialType = data?.showMaterialType;
    this.materialTypeRequired = data?.materialTypeRequired;
    this.materialTypeEditable = data?.materialTypeEditable;
    this.showFrequency = data?.showFrequency;
    this.frequencyRequired = data?.frequencyRequired;
    this.frequencyEditable = data?.frequencyEditable;
    this.showWorkOrder = data?.showWorkOrder;
    this.workOrderRequired = data?.workOrderRequired;
    this.workOrderEditable = data?.workOrderEditable;
    this.showQuantity = data?.showQuantity;
    this.quantityRequired = data?.quantityRequired;
    this.quantityEditable = data?.quantityEditable;
    this.quantityMinValue = data?.quantityMinValue;
    this.quantityMaxValue = data?.quantityMaxValue;
    this.showTotalPerMonth = data?.showTotalPerMonth;
    this.totalPerMonthRequired = data?.totalPerMonthRequired;
    this.totalPerMonthEditable = data?.totalPerMonthEditable;
    this.totalPerMonthMin = data?.totalPerMonthMin;
    this.showTotalPerOccurrence = data?.showTotalPerOccurrence;
    this.totalPerOccurrenceRequired = data?.totalPerOccurrenceRequired;
    this.totalPerOccurrenceEditable = data?.totalPerOccurrenceEditable;
    this.totalPerOccurrenceMin = data?.totalPerOccurrenceMin;
    this.showDisposalMethod = data?.showDisposalMethod;
    this.disposalMethodRequired = data?.disposalMethodRequired;
    this.disposalMethodEditable = data?.disposalMethodEditable;
  }
}

export default RecurringServiceOptions;
