import { PaymentStatusType } from 'contracts/enums';
import { TableData } from 'contracts/types/component';

export interface IHaulerBillConsolidation {
  readonly id: number;
  readonly status: PaymentStatusType;
  readonly paymentDate: string | null;
  readonly invoiceDate: string;
  readonly vendorName: string;
  readonly siteAddress: string;
  readonly vendorInvoice: string;
  readonly paymentNumber: string;
  readonly siteName: string;
  readonly remitInformation: string;
  readonly accountNumber: string;
}

class HaulerBillConsolidation implements TableData {
  id?: number;
  status?: PaymentStatusType;
  paymentDate?: string | null;
  invoiceDate?: string;
  vendorName?: string;
  siteAddress?: string;
  vendorInvoice?: string;
  paymentNumber?: string;
  siteName?: string;
  remitInformation?: string;
  accountNumber?: string;
  isHidden?: boolean;
  
  constructor(data?: Partial<IHaulerBillConsolidation>) {
    this.id = data?.id;
    this.status = data?.status as PaymentStatusType;
    this.paymentDate = data?.paymentDate;
    this.invoiceDate = data?.invoiceDate;
    this.vendorName = data?.vendorName;
    this.siteAddress = data?.siteAddress;
    this.vendorInvoice = data?.vendorInvoice;
    this.paymentNumber = data?.paymentNumber;
    this.siteName = data?.siteName;
    this.remitInformation = data?.remitInformation;
    this.accountNumber = data?.accountNumber;
  }
}

export interface HaulerBillConsolidationVendorModel {
  vendorCode: string;
  vendorName: string;
}

export interface IHaulerBillConsolidationModel {
  readonly items: IHaulerBillConsolidation[];
  readonly paymentStatuses: string[];
  readonly vendors: HaulerBillConsolidationVendorModel[];
}

export class HaulerBillConsolidationModel {
  items?: HaulerBillConsolidation[];
  paymentStatuses?: string[];
  vendors?: HaulerBillConsolidationVendorModel[];
  
  constructor(data?: Partial<IHaulerBillConsolidationModel>) {
    this.items = [];
    if (data?.items) {
      data?.items.forEach(element => {
        this.items?.push(new HaulerBillConsolidation(element));
      });
    }
    this.paymentStatuses = data?.paymentStatuses;
    this.vendors = data?.vendors;
  }
}

export default HaulerBillConsolidation;
