import { SiteIdentifier } from 'contracts/types/component';

export interface InvoiceSummaryInfoEntry {
  amount: number;
  siteIdentifier: SiteIdentifier;
  chargeTypeId: string;
  description: string;
}

export interface IInvoiceSummaryInfo {
  readonly totalAmount: number;
  readonly entries: InvoiceSummaryInfoEntry[];
}

class InvoiceSummaryInfo {
  totalAmount?: number;
  entries?: InvoiceSummaryInfoEntry[];

  constructor(data?: Partial<IInvoiceSummaryInfo>) {
    this.totalAmount = data?.totalAmount;
    this.entries = data?.entries;
  }
}
export default InvoiceSummaryInfo;
