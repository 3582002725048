import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import arrowDown from 'core/assets/images/White_arrow_down.svg';
import { Devices, theme } from 'core/styles';

import { BareButtonStyle } from './Button';

export const DateRangeContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  @media ${Devices.mobile} {
    width: 100%;
  }
`;

export const DateRangeInput = styled.button`
  outline: none;
  line-height: 40px;
  height: 40px;
  border: none;
  background-color: ${props =>
    props.disabled ? '#bdbdbd' : props.theme.colors.brandGreenDark};
  border-radius: 4px 0 0 4px;
  padding: 0 30px 0 20px;
  color: ${theme.colors.brandWhite};
  font-size: 16px;
  font-weight: normal;
  font-family: ${theme.fontFamilyBase};
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}

  background-image: url(${arrowDown});
  background-position: 90% center;
  background-repeat: no-repeat;
  background-size: 10px 7px;

  @media ${Devices.mobile} {
    width: 40px;
    font-size: 0px;
    background-position: center center;
  }
`;

export const DateRangeDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  background-color: ${theme.colors.brandWhite};
  border-radius: 4px;
  height: 40px;
`;

export const DateRangeDateOutput = styled.div`
  background-color: #fafafc;
  text-align: center;
  border-radius: 2px;
  border: 1px solid ${theme.colors.grayLight};
  padding: 0px 10px;
  margin: 0px 5px;
  line-height: 26px;
  font-size: 14px;
  white-space: nowrap;

  input {
    border: none;
    background: none;
    outline: none;
    width: 100px;
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamilyBase};
    font-size: 14px;
  }
`;

interface DateRangePickerOptionProps {
  isActive: boolean;
}
export const DateRangePickerOption = styled.button<DateRangePickerOptionProps>`
  ${BareButtonStyle};
  margin-right: 5px;
  padding: 8px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;

  ${props =>
    !props.isActive &&
    css`
      &:hover {
        background-color: ${props.theme.colors.grayLight};
      }
    `};

  ${props =>
    props.isActive &&
    css`
      background-color: ${props.theme.colors.brandPrimary};
      color: ${props.theme.colors.brandWhite};
    `};
`;

export const DateRangePickerOptions = styled.div`
  margin: 0 10px 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.grayLight};
`;

export const DateRangePickerOverlay = styled.div`
  position: absolute;
  z-index: 100;
  top: 50px;
  right: 10px;
  width: 500px;
  padding: 10px;
  background: ${theme.colors.brandWhite};
  box-shadow: 0 4px 8px
    ${props => transparentize(0.9, props.theme.colors.grayBase)};

  @media ${Devices.tablet} {
    right: unset;
    width: 90%;
    left: 10px;
  }
`;
