import update from 'immutability-helper';

import { AccountRoutes } from 'account/routing';
import { NotificationType } from 'contracts/enums';
import { ActionDispatcher, MarketplaceRegistrationAction } from 'contracts/types/action';
import { PreRegisterUserRequest, PreregisterCodeDataView, RegisterUserRequest } from 'contracts/types/request';
import { ApplicationState, MarketplaceRegistrationState, ReduceFunctionMap } from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { accountActivation, activateCodeValidation, initiateSso, preRegistration, validateCode } from '../components/services/marketplaceRegister';
import { RegisterWithRetryValidationResult, ServiceError } from 'contracts/types/service';
import { AxiosError } from 'axios';

// Action Keys
const ROOT_KEY = 'marketplace/registration';
enum ActionKey {
  VALIDATE_CODE = 'marketplace/registration/VALIDATE_CODE',
  PRE_REGISTER = 'marketplace/registration/PRE_REGISTER',
  INITIATE_SSO = 'marketplace/registration/INITIATE_SSO',
  VALIDATE_ACTIVATION_CODE = 'marketplace/registration/VALIDATE_ACTIVATION_CODE',
  RETRY_PREREGISTRATION = 'marketplace/registration/RETRY_PREREGISTRATION',
}

// Initial State
const getInitialState = (): MarketplaceRegistrationState => {
  return {
    isPreregisterValid: false,
    isPreregisterCodeValid: false,
    isActivationCodeValid: false,
    retryPreRegistrationData: {} as RegisterWithRetryValidationResult,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.VALIDATE_CODE,
  ActionKey.PRE_REGISTER,
  ActionKey.VALIDATE_ACTIVATION_CODE,
  ActionKey.RETRY_PREREGISTRATION
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  MarketplaceRegistrationState,
  MarketplaceRegistrationAction
> = {
  [ActionKey.VALIDATE_CODE]: (state, action) => {
    const { isPreregisterCodeValid } = action;
    return update(state, { $merge: { isPreregisterCodeValid } });
  },
  [ActionKey.PRE_REGISTER]: (state, action) => {
    const { isPreregisterValid } = action;
    return update(state, { $merge: { isPreregisterValid } });
  },
  [ActionKey.VALIDATE_ACTIVATION_CODE]: (state, action) => {
    const { isActivationCodeValid } = action;
    return update(state, { $merge: { isActivationCodeValid } });
  },
  [ActionKey.RETRY_PREREGISTRATION]: (state, action) => {
    const { retryPreRegistrationData } = action;
    return update(state, { $merge: { retryPreRegistrationData } });
  },
};

export const reducer = getReducerBuilder(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const actionMap = {
  VALIDATE_CODE: (isPreregisterCodeValid?: boolean): MarketplaceRegistrationAction => ({
    type: ActionKey.VALIDATE_CODE,
    isPreregisterCodeValid,
  }),
  PRE_REGISTER: (isPreregisterValid?: boolean): MarketplaceRegistrationAction => ({
    type: ActionKey.PRE_REGISTER,
    isPreregisterValid,
  }),
  INITIATE_SSO: (): MarketplaceRegistrationAction => ({
    type: ActionKey.INITIATE_SSO,
  }),
  VALIDATE_ACTIVATION_CODE: (isActivationCodeValid?: boolean): MarketplaceRegistrationAction => ({
    type: ActionKey.VALIDATE_ACTIVATION_CODE,
    isActivationCodeValid,
  }),
  RETRY_PREREGISTRATION: (retryPreRegistrationData?: RegisterWithRetryValidationResult): MarketplaceRegistrationAction => ({
    type: ActionKey.RETRY_PREREGISTRATION,
    retryPreRegistrationData,
  }),
};

// Thunks
export const validateRegisterCode = (arg: PreregisterCodeDataView) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.VALIDATE_CODE,
      () => validateCode(arg),
      async() => {
        dispatch(actionMap.VALIDATE_CODE(true));
      },
      (error) => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Error,
            error.response?.data.errorMessage || translate('core.anErrorOccurredLoadingThisPage'),
            3000,
            AccountRoutes.root + AccountRoutes.login
          ),
        );
      }
    );
export const preRegister = (arg: PreRegisterUserRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.PRE_REGISTER,
      () => preRegistration(arg),
      async response => {
        dispatch(actionMap.PRE_REGISTER(response.isValid));
        if (!response.isValid) {
          dispatch(
            createTimedNotificationMessage(
              NotificationType.Error,
              response.errorMessage,
              6000,
            ),
          );
        }
      },
      () => {},
    );
export const activateAccount = (arg: RegisterUserRequest) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.PRE_REGISTER,
      () => accountActivation(arg),
      () => {
        dispatch(actionMap.PRE_REGISTER(true));
      },
      err => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Error,
            err.response?.data.errorMessage || translate('somethingWentWrong'),
            3000,
          ),
        );
      },
    );
export const initiateMarketplaceSso = () => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.INITIATE_SSO,
      () => initiateSso(),
      () => {
        dispatch(actionMap.INITIATE_SSO());
      },
      err => {
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Error,
            err.response?.data.errorMessage || translate('somethingWentWrong'),
            3000,
          ),
        );
      },
    );
export const validateActivationCode = (arg: PreregisterCodeDataView) => 
  async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.VALIDATE_ACTIVATION_CODE,
      () => activateCodeValidation(arg),
      async() => {
        dispatch(actionMap.VALIDATE_ACTIVATION_CODE(true));
      },
      ({ response }: AxiosError<ServiceError | RegisterWithRetryValidationResult>) => {
        if(response?.status && response?.status >= 500)
        dispatch(
          createTimedNotificationMessage(
            NotificationType.Error,
            response?.data.errorMessage || translate('core.anErrorOccurredLoadingThisPage'),
            3000,
          ),
        );
        if(response?.status === 422) {
          if((response?.data as RegisterWithRetryValidationResult).retryPreRegistrationCode) {
            dispatch(actionMap.VALIDATE_ACTIVATION_CODE(true));
            dispatch(actionMap.RETRY_PREREGISTRATION(response.data as RegisterWithRetryValidationResult))
          } else {
            dispatch(actionMap.VALIDATE_ACTIVATION_CODE(false));
          }
        }
        if(response?.status === 400) {
          dispatch(actionMap.VALIDATE_ACTIVATION_CODE(false));
        }
      }
    );
const marketplaceRegisterDuck = {
  thunks: { validateRegisterCode, preRegister, activateAccount, initiateMarketplaceSso, validateActivationCode },
  actionKeys: ActionKey,
};
export default marketplaceRegisterDuck;
