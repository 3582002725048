import HaulerInvoice, {
  IHaulerInvoice,
} from 'contracts/models/service/HaulerInvoice';
import NationalInvoice, {
  INationalInvoice,
} from 'contracts/models/service/NationalInvoice';
import {
  HaulerInvoiceListRequest,
  VendorInvoiceListRequest,
  InvoiceListRequest,
} from 'contracts/types/request';
import { GetBlobResponse } from 'contracts/types/service';
import { getBlobFile } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getInvoicesList({
  fromDate,
  toDate,
  invoiceNumber,
}: InvoiceListRequest): Promise<NationalInvoice[]> {
  const response = await httpCustomerCore.post<NationalInvoice[]>(
    'invoices/national',
    {
      FromDate: fromDate,
      ToDate: toDate,
      InvoiceNumber: invoiceNumber,
    },
    {
      transformResponse: transformServiceResponse<
        INationalInvoice,
        NationalInvoice
      >(NationalInvoice),
    },
  );

  return response.data;
}

export async function getHaulerInvoicesList({
  status,
  siteId,
  fromDate,
  toDate,
}: HaulerInvoiceListRequest): Promise<HaulerInvoice[]> {
  const response = await httpCustomerCore.post<HaulerInvoice[]>(
    'invoices/hauler',
    {
      Status: status,
      SiteId: siteId,
      FromDate: fromDate,
      ToDate: toDate,
    },
    {
      transformResponse: transformServiceResponse<
        IHaulerInvoice,
        HaulerInvoice
      >(HaulerInvoice),
    },
  );

  return response.data;
}

export async function getVendorInvoicesList(request: VendorInvoiceListRequest): Promise<HaulerInvoice[]> {
  const response = await httpCustomerCore.post<HaulerInvoice[]>(
    'invoices/vendor',
    {
      Status: request.status,
      FromDate: request.fromDate,
      ToDate: request.toDate,
      PageNumber: request.pageNumber,
      PageSize: request.pageSize,
      SiteFilters: getLocationFilterApiArg(request.locationFilter),
    },
    {
      transformResponse: transformServiceResponse<
        IHaulerInvoice,
        HaulerInvoice
      >(HaulerInvoice),
    },
  );

  return response.data;
}

export async function downloadInvoiceDocument(
  invoiceNumber: number,
): Promise<GetBlobResponse> {
  const urlPath = `/invoice/download-pdf/${invoiceNumber}`;
  const invoiceDocument = await getBlobFile(httpCustomerCore, urlPath, 'pdf');
  return invoiceDocument;
}
