import { FrontLoadDiversionSiteDetails } from 'contracts/models';
import { IFrontLoadDiversionSiteDetails } from 'contracts/models/service/FrontLoadDiversionSiteDetails';
import { DateRange } from 'contracts/types/component';
import { SendSuggestedServicesArg } from 'contracts/types/frontLoadDiversion';
import {
  httpCustomerCore as httpCustomerApi,
  transformServiceResponse,
} from 'core/services/http';

export const getFrontLoadDiversionDetails = async(
  siteId: string,
  dateRange: DateRange<string>,
  custId: string,
): Promise<FrontLoadDiversionSiteDetails> => {
  const response = await httpCustomerApi.post<FrontLoadDiversionSiteDetails>(
    'frontload-diversion/frontload-diversion-site-details',
    { siteId, dateRange, custId },
    {
      transformResponse: transformServiceResponse<
        IFrontLoadDiversionSiteDetails,
        FrontLoadDiversionSiteDetails
      >(FrontLoadDiversionSiteDetails),
    },
  );
  return response.data;
};

export const sendRequest = async(
  suggestedServices: SendSuggestedServicesArg,
): Promise<unknown> => {
  // The API Returns Ok() - it should return a boolean that informs us that the service request was saved and email sent
  const response = await httpCustomerApi.post<unknown>(
    'frontload-diversion/frontload-diversion-site-details/send-request',
    suggestedServices,
  );
  return response.data;
};
