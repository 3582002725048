enum OnDemandPickUpsUpdateSortKey {
  SiteId = 'SiteId',
  ServiceDate = 'ServiceDate',
  ServiceCode = 'ServiceCode',
  ServiceDescription = 'ServiceDescription',
  Material = 'Material',
  WorkOrderNumber = 'WorkOrderNumber',
  Quantity = 'Quantity',
  TotalAmount = 'WorkOTotalAmountrderTotalAmount',
  DisposalMethod = 'DisposalMethod',
}

export default OnDemandPickUpsUpdateSortKey;
