import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { NewServiceActions } from 'contracts/models';
import NewServiceOptions from 'contracts/models/service/NewServiceOptions';
import RequestNewServiceResult from 'contracts/models/service/RequestNewServiceResult';
import { ActionDispatcher, NewServiceModalAction } from 'contracts/types/action';
import { NewServiceOptionsRequest, NewServiceSubmitRequest } from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  NewServiceModalState,
} from 'contracts/types/state';
import { createNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import { getNewServiceActions, getNewServiceOptions, submitNewService as createNewService } from '../services/newServiceModalRequests';

// Actions Keys
const ROOT_KEY = 'services/newServiceModal';
enum ActionKey {
  LOAD_NEW_SERVICE_OPTIONS = 'services/newServiceModal/LOAD_NEW_SERVICE_OPTIONS',
  LOAD_NEW_SERVICE_ACTIONS = 'services/newServiceModal/LOAD__NEW_SERVICE_ACTIONS',
  SUBMIT_NEW_SERVICE = 'services/newServiceModal/SUBMIT_NEW_SERVICE',
  CLOSE_NEW_SERVICE_CONFIRMATION = 'services/newServiceModal/CLOSE_NEW_SERVICE_CONFIRMATION',
  RESET = 'services/newServiceModal/RESET',
}

// Initial state
const getInitialState: () => NewServiceModalState = () => {
  return {
    serviceOptions: {},
    newServiceActions: [],
    serviceRequestResults: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_NEW_SERVICE_OPTIONS,
  ActionKey.LOAD_NEW_SERVICE_ACTIONS,
  ActionKey.SUBMIT_NEW_SERVICE,
  ActionKey.CLOSE_NEW_SERVICE_CONFIRMATION,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NewServiceModalState,
  NewServiceModalAction
> = {
  [ActionKey.LOAD_NEW_SERVICE_OPTIONS]: (state, action) => {
    const { serviceOptions } = action;
    return update(state, { $merge: { serviceOptions } });
  },
  [ActionKey.LOAD_NEW_SERVICE_ACTIONS]: (state, action) => {
    const { newServiceActions } = action;
    return update(state, { $merge: { newServiceActions } });
  },
  [ActionKey.SUBMIT_NEW_SERVICE]: (state, action) => {
    const { serviceRequestResults } = action;
    return update(state, { $merge: { serviceRequestResults } });
  },
  [ActionKey.CLOSE_NEW_SERVICE_CONFIRMATION]: (state, action) => {
    const resultsToRemove = action.serviceRequestResults;
    if (resultsToRemove && resultsToRemove.length > 0) {
      return update(state, {
        serviceRequestResults: { $set: [] },
      });
    }
    return state;
  },
};

export const reducer = getReducerBuilder<NewServiceModalState, NewServiceModalAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_NEW_SERVICE_OPTIONS: (serviceOptions?: NewServiceOptions): NewServiceModalAction => ({
    type: ActionKey.LOAD_NEW_SERVICE_OPTIONS,
    serviceOptions,
  }),
  LOAD_NEW_SERVICE_ACTIONS: (newServiceActions?: NewServiceActions[]): NewServiceModalAction => ({
    type: ActionKey.LOAD_NEW_SERVICE_ACTIONS,
    newServiceActions,
  }),
  SUBMIT_NEW_SERVICE: (serviceRequestResults?: RequestNewServiceResult[]) => ({
    type: ActionKey.SUBMIT_NEW_SERVICE,
    serviceRequestResults,
  }),
  CLOSE_NEW_SERVICE_CONFIRMATION: (
    serviceRequestResult: RequestNewServiceResult[],
  ) => ({
    type: ActionKey.CLOSE_NEW_SERVICE_CONFIRMATION,
    serviceRequestResults: serviceRequestResult,
  }),
  RESET: (): NewServiceModalAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadNewServiceOptions = (serviceParams: NewServiceOptionsRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NEW_SERVICE_OPTIONS,
    async() => getNewServiceOptions(serviceParams),
    result => {
      dispatch(actionMap.LOAD_NEW_SERVICE_OPTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_NEW_SERVICE_OPTIONS());
    },
  );

const loadNewServiceActions = (siteId: string, custId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_NEW_SERVICE_ACTIONS,
    async() => getNewServiceActions({ siteId, custId }),
    result => {
      dispatch(actionMap.LOAD_NEW_SERVICE_ACTIONS(result));
    },
    () => {
      dispatch(actionMap.LOAD_NEW_SERVICE_ACTIONS());
    },
  );

const submitNewService = (submitParams: NewServiceSubmitRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SUBMIT_NEW_SERVICE,
    async() => createNewService(submitParams),
    response => {
      dispatch(actionMap.SUBMIT_NEW_SERVICE(response));
      if (response && response.length) {
        response.forEach(serviceRequestResult => {
          if (serviceRequestResult.warningMessage) {
            dispatch(
              createNotificationMessage(
                NotificationType.Warning,
                serviceRequestResult.warningMessage,
              ),
            );
          }
        });
      }
    },
    () => {
      dispatch(actionMap.SUBMIT_NEW_SERVICE());
    },
    true
  );

const newServiceModalDuck = {
  thunks: { loadNewServiceOptions, loadNewServiceActions, submitNewService },
  actions: {
    closeNewServiceConfirmation: actionMap.CLOSE_NEW_SERVICE_CONFIRMATION,
    reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default newServiceModalDuck;
