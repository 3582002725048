import { TableData } from 'contracts/types/component';

export interface IInvoiceTableData {
  readonly invoiceNumber: number;
  readonly statementDate: string;
  readonly serviceDate: string;
  readonly chargeType: string;
  readonly siteName: string;
  readonly rate: number;
}

class InvoiceTableData implements TableData {
  invoiceNumber?: number;
  statementDate?: string;
  serviceDate?: string;
  chargeType?: string;
  siteName?: string;
  rate?: number;
  isHidden?: boolean;

  constructor(data?: Partial<IInvoiceTableData>) {
    this.invoiceNumber = data?.invoiceNumber;
    this.statementDate = data?.statementDate;
    this.serviceDate = data?.serviceDate;
    this.chargeType = data?.chargeType;
    this.siteName = data?.siteName;
    this.rate = data?.rate;
  }
}

export default InvoiceTableData;
