import update from 'immutability-helper';

import { SustainabilityDiversionSummary } from 'contracts/models';
import { SustainabilityDiversionTableData } from 'contracts/models/service/SustainabilityDiversionTable';
import { ActionDispatcher, DiversionAction } from 'contracts/types/action';
import { DiversionRequest } from 'contracts/types/request';
import {
  ApplicationState,
  DiversionState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getDiversionData,
  getDiversionTableData,
} from '../services/diversionService';

// ACTIONS
const ROOT_KEY = 'sustainability/diversion';
enum ActionKey {
  LOAD_SUMMARY = 'sustainability/diversion/LOAD_SUMMARY',
  LOAD_TABLE_DATA = 'sustainability/diversion/LOAD_TABLE_DATA',
  RESET = 'sustainability/diversion/RESET',
}

// Initial State
const getInitialState: () => DiversionState = () => {
  return {
    diversionData: {},
    diversionTableData: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SUMMARY,
  ActionKey.LOAD_TABLE_DATA,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  DiversionState,
  DiversionAction
> = {
  [ActionKey.LOAD_SUMMARY]: (state, action) => {
    const { diversionData } = action;
    if (!diversionData) {
      return state;
    }
    return update(state, { $merge: { diversionData } });
  },
  [ActionKey.LOAD_TABLE_DATA]: (state, action) => {
    const { diversionTableData } = action;
    if (!diversionTableData) {
      return state;
    }
    return update(state, { $merge: { diversionTableData } });
  },
};

export const reducer = getReducerBuilder<DiversionState, DiversionAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SUMMARY: (
    diversionData?: SustainabilityDiversionSummary,
  ): DiversionAction => ({
    type: ActionKey.LOAD_SUMMARY,
    diversionData,
  }),
  LOAD_TABLE_DATA: (
    diversionTableData?: SustainabilityDiversionTableData[],
  ): DiversionAction => ({
    type: ActionKey.LOAD_TABLE_DATA,
    diversionTableData,
  }),
  RESET: (): DiversionAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadDiversionData = (inputArg: DiversionRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY,
    () => getDiversionData(inputArg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY(result));
    },
    () => {
      dispatch(actionMap.LOAD_SUMMARY());
    },
    true,
  );

const loadDiversionTableData = (inputArg: DiversionRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE_DATA,
    () => getDiversionTableData(inputArg),
    result => {
      dispatch(actionMap.LOAD_TABLE_DATA(result));
    },
    () => {
      dispatch(actionMap.LOAD_TABLE_DATA());
    },
    true,
  );

const diversionDuck = {
  thunks: { loadDiversionData, loadDiversionTableData },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default diversionDuck;
