import React from 'react';

import { IconLink as IconLinkStyled } from './styled';
import { IconLinkProps } from './styled/IconLink';

const IconLink: React.FC<ComponentProps> = ({ icon, ...rest }) => {
  switch (icon) {
    case 'burger':
      return <IconLinkStyled className='icon-burger' {...rest} alt='icon' />;

    case 'plus-circle':
      return (
        <IconLinkStyled className='icon-plus-circle' {...rest} alt='icon' />
      );

    case 'chat-bubble':
      return (
        <IconLinkStyled className='icon-chat-bubble' {...rest} alt='icon' />
      );

    case 'card':
      return <IconLinkStyled className='icon-card' {...rest} alt='icon' />;

    case 'delete':
      return <IconLinkStyled className='icon-trash' {...rest} alt='icon' />;

    case 'edit':
      return <IconLinkStyled className='icon-pencil' {...rest} alt='icon' />;

    case 'file':
      return <IconLinkStyled className='icon-file-o' {...rest} alt='icon' />;

    case 'left-arrow':
      return (
        <IconLinkStyled className='icon-arrow-left' {...rest} alt='icon' />
      );

    default:
      return null;
  }
};

interface ComponentProps extends IconLinkProps {
  icon: string;
}

export default IconLink;
