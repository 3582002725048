import { BrandingType } from 'contracts/enums/brandedContentDictionary';
import SubscriptionItems from 'contracts/enums/SubscriptionItems';
import SubscriptionType from 'contracts/enums/SubscriptionType';

export interface IUser {
  readonly customerName: string;
  readonly emailAddress: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly refreshToken: string;
  readonly brandings: BrandingType[];
  readonly subscription: SubscriptionType;
  readonly subscriptionItems: SubscriptionItems[];
  readonly businessType: string;
  readonly isCustomerOnHold: boolean;
  readonly hasApproval: boolean;
  readonly hasDisposalAudit: boolean;
  readonly siteCount: number;
  readonly singleSite: SingleSite;
  readonly hasLibryoAccess: boolean;
  readonly automaticallyCreateUsers: boolean;
  readonly isBackOfficeSSO: boolean;
  readonly isNewCreditCardAutopayEditable: boolean;
  readonly newCreditCardAutopayDefaultValue: boolean;
}

class User {
  refreshToken?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  customerName?: string;
  brandings?: BrandingType[];
  subscription?: SubscriptionType;
  subscriptionItems?: SubscriptionItems[];
  businessType?: string;
  isCustomerOnHold?: boolean;
  hasApproval?: boolean;
  hasDisposalAudit?: boolean;
  siteCount?: number;
  singleSite?: SingleSite | null;
  hasLibryoAccess?: boolean;
  automaticallyCreateUsers?: boolean;
  isBackOfficeSSO?: boolean;
  isNewCreditCardAutopayEditable?: boolean;
  newCreditCardAutopayDefaultValue?: boolean;

  constructor(data?: Partial<IUser>) {
    this.refreshToken = data?.refreshToken;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.emailAddress = data?.emailAddress;
    this.customerName = data?.customerName;
    this.brandings = data?.brandings;
    this.subscription = data?.subscription;
    this.subscriptionItems = data?.subscriptionItems;
    this.businessType = data?.businessType;
    this.isCustomerOnHold = data?.isCustomerOnHold;
    this.hasApproval = data?.hasApproval;
    this.hasDisposalAudit = data?.hasDisposalAudit;
    this.siteCount = data?.siteCount;
    this.singleSite = data?.singleSite;
    this.hasLibryoAccess = data?.hasLibryoAccess;
    this.automaticallyCreateUsers = data?.automaticallyCreateUsers;
    this.isBackOfficeSSO = data?.isBackOfficeSSO;
    this.isNewCreditCardAutopayEditable = data?.isNewCreditCardAutopayEditable;
    this.newCreditCardAutopayDefaultValue = data?.newCreditCardAutopayDefaultValue;
  }
}

export interface SingleSite {
  siteId: string;
  custId: string;
}

export default User;
