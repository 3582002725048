import { css, FlattenSimpleInterpolation } from 'styled-components';

import { theme } from 'core/styles';

export default function customerPortalScrollbar(): FlattenSimpleInterpolation {
  return css`
    /* Chrome and Safari*/
    &::-webkit-scrollbar {
      width: 8px;
      background-color: '#F5F5F5';
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      background-color: '#F5F5F5';
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      background-color: ${theme.chart.chartGreen};
    }
    /* Firefox */
    & {
      scrollbar-color: #32bdc1 #f5f5f5;
      scrollbar-width: thin;
    }
    /* Internet explorer 11 */
    & {
      scrollbar-face-color: #32bdc1;
      scrollbar-track-color: #f5f5f5;
      scrollbar-3dlight-color: #f5f5f5;
      scrollbar-highlight-color: #f5f5f5;
      scrollbar-darkshadow-color: #f5f5f5;
      scrollbar-shadow-color: #f5f5f5;
      scrollbar-arrow-color: #32bdc1;
    }
  `;
}
