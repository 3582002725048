export interface IServiceEquipmentRate {
  readonly rateCode: string;
  readonly rateDescription: string;
  readonly rate?: number;
  readonly uOM: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly ordType: string;
  readonly reportType: string;
}

class ServiceEquipmentRate {
  rateCode?: string;
  rateDescription?: string;
  rate?: number;
  uOM?: string;
  startDate?: string;
  endDate?: string;
  ordType?: string;
  reportType?: string;

  constructor(data?: Partial<IServiceEquipmentRate>) {
    this.rateCode = data?.rateCode;
    this.rateDescription = data?.rateDescription;
    this.rate = data?.rate;
    this.uOM = data?.uOM;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.ordType = data?.ordType;
    this.reportType = data?.reportType;
  }
}

export default ServiceEquipmentRate;
