export interface DiversionValue {
  percentage: number;
  tonnage: number;
  pounds: number;
}

export interface DiversionMaterialItem {
  name: string;
  identifier: string;
  value: DiversionValue;
}

export interface DiversionMonthItem {
  month: number;
  year: number;
  value: DiversionValue;
}

export interface DiversionUnitOfMeasure {
  isWeight: boolean;
  isTonnage: boolean;
  label: string;
}

export interface ISustainabilityDiversionSummary {
  readonly total: DiversionValue;
  readonly materials: DiversionMaterialItem[];
  readonly months: DiversionMonthItem[];
}

class SustainabilityDiversionSummary {
  total?: DiversionValue;
  materials?: DiversionMaterialItem[];
  months?: DiversionMonthItem[];

  constructor(data?: Partial<ISustainabilityDiversionSummary>) {
    this.total = data?.total;
    this.materials = data?.materials;
    this.months = data?.months;
  }
}

export default SustainabilityDiversionSummary;
