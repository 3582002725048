import { ContainerContaminationDetails, ContainerFullnessStatistics, LastFillCycleInformation, ContainersFullnessLevels } from 'contracts/models/service/ServiceInsights';
import { SiteIdentifier } from 'contracts/types/component';
import { ContainerFillCycleRequest, ContainerFullnessStatisticsRequest } from 'contracts/types/request';
import { httpCustomerCore } from 'core/services/http';

export const getLocationContainersFullnessLevelsByLocation = async({
  siteId,
  siteCustomerId,
}: SiteIdentifier): Promise<ContainersFullnessLevels> => {
  const response = await httpCustomerCore.get<ContainersFullnessLevels>(
    'partner-data/containers-fullness-levels-by-location',
    {
      params: {
        custId: siteCustomerId,
        siteId,
      }
    },
  );
  return response.data;
};

export const getLocationContainersFullnessLevelsByContainers = 
  async(svcIds: number[]): Promise<ContainersFullnessLevels> => {
    const response = await httpCustomerCore.post<ContainersFullnessLevels>(
      'partner-data/containers-fullness-levels-by-equipments',
      {
        svcIds
      },
    );
    return response.data;
  };
  
export const getContainersFillCycleDetails = 
  async(request: ContainerFillCycleRequest): Promise<ContainerContaminationDetails> => {
    const response = await httpCustomerCore.post<ContainerContaminationDetails>(
      'partner-data/container-fill-cycle',
      request,
    );
    return response.data;
  };

export const getLastFillCycleInformation = 
  async(svcId: number): Promise<LastFillCycleInformation> => {
    const response = await httpCustomerCore.get<LastFillCycleInformation>(
      'partner-data/last-fill-cycle-information',
      {
        params: { svcId }
      },
    );
    return response.data;
  };

export const getContainerFullnessStatistics = async(request: ContainerFullnessStatisticsRequest):
  Promise<ContainerFullnessStatistics> => {
  const response = await httpCustomerCore.post<ContainerFullnessStatistics>(
    'partner-data/container-fullness-statistics',
    request,
  );
  return response.data;
};
