import { OpenTopPriceEquipmentRequestModel, OpenTopPriceQuoteModel } from 'contracts/models/service/OpenTopPriceQuoteModel';
import { OpenTopTempServicesRequest } from 'contracts/types/request';

import { httpCustomerCore } from '../../core/services/http';
import { OpenTopTempTypes } from '../constants/openTopTempTypes';

export const getOpenTopTempServices = async({
  address,
  zipCode,
  material,
}: OpenTopTempServicesRequest): Promise<OpenTopPriceQuoteModel> => {
  const services: OpenTopPriceEquipmentRequestModel[] = [];
  OpenTopTempTypes.forEach(type => {
    const serviceParams = {
      address,
      zipCode,
      equipment: type,
      material,
    };
    services.push(serviceParams);
  });

  const response = await httpCustomerCore.post(
    'wholesale/quote/open-top-temp',
    {
      services,
    },
  );

  return response.data;
};

export default getOpenTopTempServices;
