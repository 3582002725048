import { DateRange } from 'contracts/types/service';

export interface MaterialManagementComparisonMaterialEntry {
  identifier: string;
  name: string;
  materialTonnages: number[];
  materialPounds: number[];
}

export interface IMaterialManagementComparison {
  readonly isMonthly: boolean;
  readonly dateRanges: DateRange[];
  readonly materialEntries: MaterialManagementComparisonMaterialEntry[];
}

class MaterialManagementComparison {
  isMonthly?: boolean;
  dateRanges?: DateRange[];
  materialEntries?: MaterialManagementComparisonMaterialEntry[];

  constructor(data?: Partial<IMaterialManagementComparison>) {
    this.isMonthly = data?.isMonthly;
    this.dateRanges = data?.dateRanges;
    this.materialEntries = data?.materialEntries;
  }
}

export default MaterialManagementComparison;
