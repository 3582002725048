import moment, { MomentInput } from 'moment';
import queryString from 'query-string';
import { Location } from 'react-router';

import { FrontLoadDiversionParamsResult } from 'contracts/types/frontLoadDiversion';

export enum Routes {
  root = '/reports',
  dashboard = '/dashboard',
  optimizationReport = '/optimization-report',
  optimizationDetails = '/optimization/details',
  frontLoadDiversionReport = '/frontload-diversion-report',
  frontLoadDiversionDetails = '/frontload-diversion/details',
  uploadedDocs = '/uploaded-docs',
  powerBi = '/powerbi',
  rightSizing = '/right-sizing',
  foundry = '/foundry',
  serviceConfirmationAnalytics = '/service-confirmation-analytics'
}

export function buildOptimizationDetails(
  serviceId: number,
  startDate: string,
  endDate: string,
): string {
  let result = `${Routes.root + Routes.optimizationDetails}?serviceId=${serviceId}`;
  if (startDate) {
    result += `&start=${moment(startDate, 'MM - DD - YYYY').format(
      'YYYY-MM-DD',
    )}`;
  }
  if (endDate) {
    result += `&end=${moment(endDate, 'MM - DD - YYYY').format('YYYY-MM-DD')}`;
  }
  return result;
}

export function getOptimizationParams(
  location: Location,
): {
  serviceId: number;
  initialDateRange: {
    from: string;
    to: string;
  };
} {
  const values = queryString.parseUrl(location.search).query;
  const serviceId = values.serviceId as string;
  const result = {
    serviceId: parseInt(serviceId, 10),
    initialDateRange: {
      from: '',
      to: '',
    },
  };
  if (values.start && values.end) {
    result.initialDateRange = {
      from: moment(values.start as MomentInput, 'YYYY-MM-DD').format('MM - DD - YYYY'),
      to: moment(values.end as MomentInput, 'YYYY-MM-DD').format('MM - DD - YYYY'),
    };
  }
  return result;
}

export function buildFrontLoadDiversionSiteDetails(
  siteId: string,
  siteName: string,
  startDate: string,
  endDate: string,
  custId: string,
): string {
  let result = `${Routes.root + Routes.frontLoadDiversionDetails}?siteId=${siteId}&siteName=${encodeURIComponent(siteName)}`;
  if (custId) {
    result += `&custId=${custId}`;
  }
  if (startDate) {
    result += `&start=${moment(startDate, 'MM - DD - YYYY').format(
      'YYYY-MM-DD',
    )}`;
  }
  if (endDate) {
    result += `&end=${moment(endDate, 'MM - DD - YYYY').format('YYYY-MM-DD')}`;
  }
  return result;
}

export function getFrontLoadDiversionParams(
  location: Location,
): FrontLoadDiversionParamsResult {
  const values = queryString.parseUrl(location.search).query;
  const siteId = values.siteId as string;
  const siteName = values.siteName as string;
  const custId = values.custId as string;
  const result: FrontLoadDiversionParamsResult = {
    siteId,
    siteName,
    custId,
    initialDateRange: {
      from: '',
      to: '',
    },
  };
  if (values.start && values.end) {
    result.initialDateRange = {
      from: moment(values.start as MomentInput, 'YYYY-MM-DD').format('MM - DD - YYYY'),
      to: moment(values.end as MomentInput, 'YYYY-MM-DD').format('MM - DD - YYYY'),
    };
  }
  return result;
}
