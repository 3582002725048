import { SiteIdentifier, TableData } from 'contracts/types/component';
import { SortFields } from 'contracts/types/request';

export interface ContainerSizingTableMetrics {
  periodFullnessAvg: number | null;
  goalFullness: number;
  estimatedPeriodServiceSavings: number;
  estimatedPeriodServicesReducedNo: number;
}

export interface ContainerSizingTableRowModel extends TableData{
  siteCode: string;
  siteName: string;
  siteAddress: string;
  equipment: string;
  equipmentSize: string;
  material: string;
  currentFullness: number | null;
  currentPickupNo: number | null;
  goalFullness: number;
  newPickupNo: number;
  periodServiceCostSavings: number;
}
export interface LoadContainerSizingReportArgs {
  custID: string;
  custCINEmailMapID: number;
  tagIds: string;
  materialCategories: string;
  siteIdentifiers: SiteIdentifier[];
}

export interface ContainerSizingSiteFiltersModel {
  tagIds: number[];
  storeIds: SiteIdentifier[];
}

export interface ContainerSizingFiltersModel extends ContainerSizingSiteFiltersModel {
  materials: number[];
  sizeRange?: Range;
}

export interface ContainerSizingReportRequestArg {
  startDate: string | null;
  endDate: string | null;
  sortFields: SortFields;
  filters: ContainerSizingFiltersModel;
}
export interface IContainerSizingReport {
  readonly metrics: ContainerSizingTableMetrics;
  readonly tableRows: ContainerSizingTableRowModel[];
}

class ContainerSizingReport {
  metrics?: ContainerSizingTableMetrics;
  tableRows?: ContainerSizingTableRowModel[];

  constructor(data?: Partial<IContainerSizingReport>) {
    this.metrics = data?.metrics;
    this.tableRows = data?.tableRows;
  }
}

export default ContainerSizingReport;
