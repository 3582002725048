import React, { PureComponent } from 'react';

import memoizeOne from 'memoize-one';

import { FilterType } from 'contracts/enums';
import { CityFilterValue } from 'contracts/types/component';

import { DropdownFilterRoot } from '../styled/DropdownFilter';

import DropdownFilterControl from './DropdownFilterControl';
import DropdownFilterPopup, {
  DropdownFilterValue,
} from './DropdownFilterPopup';

const MAX_UNCHECKED_TO_DISPLAY = 100;

function sortByLabel<T extends number | string>(
  v1: DropdownFilterValue<T>,
  v2: DropdownFilterValue<T>,
): number {
  if (v1.label < v2.label) {
    return -1;
  }
  if (v1.label > v2.label) {
    return 1;
  }
  return 0;
}

function getDataSource<T extends number | string>(
  values: Array<DropdownFilterValue<T>>,
  selectedIds: T[],
  sortValues = true
): {
  rows: Array<DropdownFilterValue<T>>;
  maxUnchecked: number;
} {
  if (!values || !values.length) {
    return { rows: [], maxUnchecked: 0 };
  }

  let checkedValues: Array<DropdownFilterValue<T>> = []; // m
  let uncheckedValues: Array<DropdownFilterValue<T>> = []; // n

  // O(kv)
  values.forEach(value => {
    if (selectedIds && selectedIds.indexOf(value.id) > -1) {
      checkedValues.push(value);
    } else {
      uncheckedValues.push(value);
    }
  });

  if (sortValues) {
    // O(mlogm)+O(nlogn)
    checkedValues = checkedValues.sort(sortByLabel);
    uncheckedValues = uncheckedValues.sort(sortByLabel);
  }

  // O(m+n)
  return {
    rows: [...checkedValues, ...uncheckedValues],
    maxUnchecked: checkedValues.length + MAX_UNCHECKED_TO_DISPLAY,
  };
}

const memoizedGetDataSource = memoizeOne(getDataSource);

class DropdownFilter<T extends number | string> extends PureComponent<
  ComponentProps<T>
> {

  state = {
    isExpanded: false,
  };

  rootRef = React.createRef<HTMLDivElement>();

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount(): void {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e: MouseEvent): void => {
    if (
      this.rootRef &&
      this.rootRef.current &&
      !this.rootRef.current.contains(e.target as Node)
    ) {
      this.closePopup();
    }
  };

  toggleExpanded = (): void => {
    const { isDisabled, isLoading } = this.props;
    if (!isDisabled && !isLoading) {
      const { isExpanded } = this.state;
      this.setState({ isExpanded: !isExpanded });
    }
  };

  closePopup = (): void => {
    this.setState({ isExpanded: false });
  };

  onFilterChanged = (selectedIds: T[]): void => {
    const { onFilterChanged } = this.props;
    if (onFilterChanged) {
      onFilterChanged(selectedIds);
    }
    this.closePopup();
  };

  onCityZipFilterChanged = (selectedEntity: T[]): void => {
    const { onCityZipFilterChanged } = this.props;
    if (onCityZipFilterChanged) {
      onCityZipFilterChanged(selectedEntity);
    }
    this.closePopup();
  };

  render(): React.ReactNode {
    const {
      label,
      selectedIds,
      isLoading,
      isDisabled,
      values,
      checkboxLabelLineCount,
      searchByFilterKey,
      sortValues,
      typeAheadType,
      bold,
      openOnLeft
    } = this.props;
    const { isExpanded } = this.state;
    const count = selectedIds && selectedIds.length ? selectedIds.length : 0;
    const sortedValues = values ? memoizedGetDataSource(values, selectedIds as T[], sortValues) : {} as 
    { rows: Array<DropdownFilterValue<T>>; maxUnchecked?: number | undefined; };

    let selectedFilterStatus = undefined;
    if (typeAheadType === FilterType.City) {
      selectedFilterStatus = selectedIds.length > 0 && `${(selectedIds as CityFilterValue[])[0].city}, ${(selectedIds as CityFilterValue[])[0].state}`;
    }
    if (typeAheadType === FilterType.ZipCode) {
      selectedFilterStatus = selectedIds.length > 0 && selectedIds[0];
    }
    
    return (
      <DropdownFilterRoot ref={this.rootRef}>
        <DropdownFilterControl
          isExpanded={isExpanded}
          label={label}
          toggleExpanded={this.toggleExpanded}
          count={count}
          selectedFilterStatus={selectedFilterStatus as string | undefined}
          isLoading={isLoading}
          isDisabled={isDisabled}
          bold={bold}
        />
        {isExpanded && !isDisabled && !isLoading && (
          <DropdownFilterPopup
            label={label}
            dataSource={sortedValues}
            selectedIds={selectedIds as T[]}
            onFilterChanged={this.onFilterChanged}
            onCityZipFilterChanged={this.onCityZipFilterChanged}
            checkboxLabelLineCount={checkboxLabelLineCount}
            searchByFilterKey={searchByFilterKey}
            closePopup={this.closePopup}
            typeAheadType={typeAheadType}
            openOnLeft={openOnLeft}
          />
        )}
      </DropdownFilterRoot>
    );
  }
}

interface ComponentProps<T extends number | string> {
  label: string;
  values?: Array<DropdownFilterValue<T>>;
  selectedIds: T[] | CityFilterValue[];
  onFilterChanged: (selectedIds: T[]) => void;
  onCityZipFilterChanged?: (selectedEntity: T[]) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  checkboxLabelLineCount?: number;
  searchByFilterKey?: boolean;
  sortValues?: boolean;
  bold?: boolean;
  typeAheadType?: string;
  openOnLeft?: boolean;
}

export default DropdownFilter;
