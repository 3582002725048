/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import FullStory from 'react-fullstory';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import { AccountRoutes } from 'account/routing';
import { destroySession } from 'account/services/session';
import MainContainer from 'core/components/styled/MainContainer';
import Environments from 'core/constants/Environments';
import { environment } from 'core/services/environment';
import MainRouter from 'routing/MainRouter';

import registerAuthInterceptor from './account/services/registerAuthInterceptor';
import { initializeAndStartTokenTimer } from './account/services/tokenRefreshTimerService';
import { GlobalStyle, theme } from './core/styles';
import ErrorBoundaryComponent from './ErrorBoundaryComponent';
import store from './store';

import 'core/styles/fontastic.css';
import 'core/styles/multi-toggle.css';
import 'core/styles/notifier.css';
import 'core/styles/search-input.css';

registerAuthInterceptor();
initializeAndStartTokenTimer();

declare global {
  interface Window {
    ga: (...data: any[]) => any;
    ['powerbi-client']: any;
    powerbi: any;
    Z: {
      renderWithErrorHandler: (...data: any[]) => any;
      sendErrorMessageToHpm: (...data: any[]) => any;
      submit: (...data: any[]) => any;
    };
    email?: string;
    custId?: string;
    custName?: string;
    channel?: string;
    billingType?: string;
    userPermission?: string;
    siteCount?: number;
    serviceCount?: number;
    newrelic?: any;
  }
}

const ORG_ID = 'WBE03'; // Full Story org ID

const App: React.FC = () => {
  const [errorOccured, setErrorOccured] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Provider store={store}>
          <BrowserRouter>
            <MainContainer>
              <ErrorBoundary
                FallbackComponent={ErrorBoundaryComponent}
                onReset={() => {
                  setErrorOccured(false);
                  destroySession();
                  window.location.href = AccountRoutes.root + AccountRoutes.login;
                }}
                resetKeys={[errorOccured]}
              >
                <MainRouter />
              </ErrorBoundary>
            </MainContainer>
          </BrowserRouter>
        </Provider>
        <Normalize />
        <GlobalStyle />
        {environment !== Environments.local && <FullStory org={ORG_ID} />}
      </Fragment>
    </ThemeProvider>
  );
};

export default App;
