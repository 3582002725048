enum DipsplayName {
  Payments = 'Payments',
  InvoiceHistory = 'Invoice History',
}

type PaymentOption = {
  displayName: string;
  value: number;
};

const paymentToggleOptions: PaymentOption[] = [
  {
    displayName: DipsplayName.Payments,
    value: 1,
  },
  {
    displayName: DipsplayName.InvoiceHistory,
    value: 2,
  },
];

export default paymentToggleOptions;
