import { combineReducers } from 'redux';

import { reducer as CarbonEmissionsReducer } from './carbonEmissions';
import { reducer as carbonReductionReducer } from './carbonReduction';
import { reducer as diversionReducer } from './diversion';
import { reducer as materialProfileReducer } from './materialProfile';

const reducer = combineReducers({
  diversion: diversionReducer,
  materialProfile: materialProfileReducer,
  carbonReduction: carbonReductionReducer,
  carbonEmissions: CarbonEmissionsReducer,
});

export default reducer;
