import { AxiosError } from 'axios';
import update from 'immutability-helper';
import { FullStoryAPI } from 'react-fullstory';

import { AccountRoutes } from 'account/routing';
import * as coreAuth from 'account/services/auth';
import {
  getSessionClaims,
  getSessionUser,
} from 'account/services/session';
import {
  clearCurrentTokenTimer,
  initializeAndStartTokenTimer,
} from 'account/services/tokenRefreshTimerService';
import { getDecryptedInvoiceDetails } from 'billing/services/billingServices';
import { NotificationType } from 'contracts/enums';
import { QuickpayInvoiceDetails, User } from 'contracts/models';
import { ActionDispatcher, LoginAction } from 'contracts/types/action';
import { SessionClaims } from 'contracts/types/component';
import { LoginSSOError } from 'contracts/types/service';
import {
  ApplicationState,
  LoginState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import Environments from 'core/constants/Environments';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import { formatServiceError, formatLoginSSOError } from 'core/helpers/formatServiceError';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import GLOBAL_RESET from 'core/reducerBuilder/globalActionTypes';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { environment } from 'core/services/environment';

// Actions Keys
const ROOT_KEY = 'account/login';
enum ActionKey {
  LOGIN = 'account/login/LOGIN',
  UPDATE_LOGGED_USER_DETAILS = 'account/login/UPDATE_LOGGED_USER_DETAILS',
  LIBRYO_LOGIN = 'account/login/LIBRYO_LOGIN',
  REGISTER_LOGIN_WHOLESALE = 'account/login/REGISTER_LOGIN_WHOLESALE',
  FETCH_INVOICE_DETAILS = 'account/login/FETCH_INVOICE_DETAILS', // TODO: this should not be here
  LOGOUT = 'account/login/LOGOUT',
  RESET = 'account/login/RESET',
  NETWORK_CHECK = 'account/login/NETWORK_CHECK'
}

// Initial State
const getInitialState: () => LoginState = () => {
  return {
    isLoginFailed: false,
    isLoggedIn: !!getSessionUser() && !!getSessionClaims(),
    errorMessage: '',
    registerErrorMessage: '',
    user: getSessionUser() || new User(),
    claims: getSessionClaims() || {},
    invoiceDetails: {},
  };
};

// Reducer
const reducerKeys = [ActionKey.LOGIN, ActionKey.FETCH_INVOICE_DETAILS, ActionKey.UPDATE_LOGGED_USER_DETAILS] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  LoginState,
  LoginAction
> = {
  [ActionKey.LOGIN]: (state, action) => {
    const {
      isLoginFailed,
      isLoggedIn,
      errorMessage,
      registerErrorMessage,
      user,
      claims,
    } = action;
    return update(state, {
      $merge: {
        isLoginFailed,
        isLoggedIn,
        errorMessage,
        registerErrorMessage,
        user,
        claims,
      },
    });
  },
  [ActionKey.UPDATE_LOGGED_USER_DETAILS]: (state, action) => {
    const newState = update(state, {
      $merge: { 
        user: {
          ...state.user,
          firstName: action.user?.firstName,
          lastName: action.user?.lastName
        } 
      },
    });
    return newState;
  },
  [ActionKey.FETCH_INVOICE_DETAILS]: (state, action) => {
    const { invoiceDetails, errorMessage } = action;
    return update(state, {
      $merge: {
        invoiceDetails,
        errorMessage,
      },
    });
  },
};

export const reducer = getReducerBuilder<LoginState, LoginAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .withReset(GLOBAL_RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOGIN: (user: User, claims?: SessionClaims): LoginAction => ({
    type: ActionKey.LOGIN,
    user,
    claims,
    isLoggedIn: true,
    isLoginFailed: false,
  }),
  UPDATE_LOGGED_USER_DETAILS: (user?: User): LoginAction => ({
    type: ActionKey.UPDATE_LOGGED_USER_DETAILS,
    user
  }),
  LOGIN_FAILED: (
    errorMessage: string,
    registerErrorMessage = '',
  ): LoginAction => ({
    type: ActionKey.LOGIN,
    errorMessage,
    registerErrorMessage,
    isLoggedIn: false,
    isLoginFailed: true,
  }),
  NETWORK_CHECK: (errorMessage: string): LoginAction => ({
    type: ActionKey.LOGIN,
    errorMessage,
    isLoggedIn: false,
    isLoginFailed: true,
  }),
  LIBRYO_LOGIN: () => ({
    type: ActionKey.LOGIN,
  }),
  FETCH_INVOICE_DETAILS: (
    invoiceDetails: QuickpayInvoiceDetails,
  ): LoginAction => ({
    type: ActionKey.FETCH_INVOICE_DETAILS,
    invoiceDetails,
  }),
  FETCH_INVOICE_DETAILS_FAILED: (errorMessage: string): LoginAction => ({
    type: ActionKey.FETCH_INVOICE_DETAILS,
    errorMessage,
  }),
  LOGOUT: (): LoginAction => ({
    type: ActionKey.LOGOUT,
  }),
  RESET: (): LoginAction => ({
    type: ActionKey.RESET,
  }),
  GLOBAL_RESET: (): LoginAction => ({
    type: GLOBAL_RESET,
  }),
};

// Thunks
const login = (
  email: string,
  password: string) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOGIN,
    async() => coreAuth.login(email, password),
    ({ user, claims }) => {
      window.ga('set', 'userId', user.emailAddress);
      dispatch(actionMap.LOGIN(user, claims));
      initializeAndStartTokenTimer();
    },
    error => {
      const errorMessage = formatServiceError(error);
      dispatch(actionMap.LOGIN_FAILED(errorMessage));
    },
    false,
    undefined,
    true,
    false // do not log axios data, contains request credentials
  );

const libryoAuth = (clientId: string, redirectUri: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) => runTakeLastThunk(
  dispatch,
  getState,
  ActionKey.LIBRYO_LOGIN,
  async() => coreAuth.loginToLibryo(clientId, redirectUri),
  result => {
    dispatch(actionMap.LIBRYO_LOGIN());
    window.location.href = result.redirectUrl;
  },
  err => {
    dispatch(actionMap.LIBRYO_LOGIN());
    const errorMessage = err.response
      ? err.response.data.message
      : translate('account.weWereUnableToProcess');
    dispatch(
      createTimedNotificationMessage(NotificationType.Error, errorMessage),
    );
  },
  false,
  undefined,
  true,
  false // do not log axios data, contains request credentials
);

const loginSso = (tokenId: string, customerId: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOGIN,
    async() => coreAuth.loginGusUser(tokenId, customerId),
    result => {
      dispatch(actionMap.LOGIN(result.user, result.claims));
      initializeAndStartTokenTimer();
    },
    error => {
      const errorMessage = formatServiceError(error);
      dispatch(actionMap.LOGIN_FAILED(errorMessage));
    },
    false,
    undefined,
    true,
    false // do not log axios data, contains request credentials
  );

const loginFedexSso = (requestId: string, redirect: (url: string) => void) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOGIN,
    async() => coreAuth.loginFedexUser(requestId),
    result => {
      dispatch(actionMap.LOGIN(result.user, result.claims));
      initializeAndStartTokenTimer();
      redirect(AccountRoutes.root + AccountRoutes.login);
    },
    (error: AxiosError<LoginSSOError>) => {
      const emailAddress = formatLoginSSOError(error);
      const errorMessage = formatServiceError(error);

      dispatch(actionMap.LOGIN_FAILED(errorMessage));
      if (emailAddress && environment !== Environments.local) {
        FullStoryAPI('identify', false, {
          email: emailAddress,
        }); 
      }
      
      if (error?.response?.status === 400) {
        redirect(AccountRoutes.loginError);
      } else {
        redirect(AccountRoutes.root + AccountRoutes.login);
      }
    },
    false,
    undefined,
    true,
    false // do not log axios data, contains request credentials
  );

const logout = (redirect?: () => void) => (dispatch: ActionDispatcher) => {
  coreAuth.logout();
  clearCurrentTokenTimer();
  dispatch(actionMap.LOGOUT());
  dispatch(actionMap.GLOBAL_RESET());
  if (redirect) {
    redirect();
  }
};

const loadBillingInvoiceDetails = (statementId: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.FETCH_INVOICE_DETAILS,
    async() => getDecryptedInvoiceDetails(statementId),
    result => dispatch(actionMap.FETCH_INVOICE_DETAILS(result)),
    error => {
      const errorMessage = formatServiceError(error);
      dispatch(actionMap.FETCH_INVOICE_DETAILS_FAILED(errorMessage));
    },
  );

const registerWholesale = (params: {
  customerName: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.REGISTER_LOGIN_WHOLESALE,
    async() => coreAuth.registerAndLoginWholesaleUser(params),
    result => {
      window.ga('set', 'userId', result.user.emailAddress);
      dispatch(actionMap.LOGIN(result.user, result.claims));
      initializeAndStartTokenTimer();
    },
    error => {
      let errorMessage = formatServiceError(error);
      if (errorMessage === translate('account.youAreNotAuthorized')) {
        errorMessage = translate('account.emailAddressAlreadyRegistered');
      }
      dispatch(actionMap.LOGIN_FAILED('', errorMessage));
    },
    false,
    undefined,
    true,
    false // do not log axios data, contains request credentials
  );

const networkCheck = () => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.NETWORK_CHECK,
    async() => coreAuth.networkCheck(),
    () => {},
    (error: AxiosError) => {
      if (error?.response?.status === 0 || error?.response?.status === null) {
        dispatch(actionMap.NETWORK_CHECK(translate('account.networkCheckError')));
      }
    },
    false,
    undefined,
    true,
    true
  );

const loginDuck = {
  thunks: {
    login,
    libryoAuth,
    loginSso,
    loginFedexSso,
    loadBillingInvoiceDetails,
    registerWholesale,
    networkCheck
  },
  actionKeys: ActionKey,
  actions: {
    updateUser: actionMap.UPDATE_LOGGED_USER_DETAILS,
    reset: actionMap.RESET,
    globalReset: actionMap.GLOBAL_RESET,
    logout,
  },
};

export { loginDuck as default };
