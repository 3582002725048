import moment from 'moment';

import { DateRange } from 'contracts/types/component';

export function getUTCDateRange(dateRange: DateRange<string>): DateRange<string> {
  const utcDateRange = {
    from: moment(
      dateRange.from?.replace(/\s+/g, '') + ' 00:00:00.000',
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(
      dateRange.to?.replace(/\s+/g, '') + ' 23:59:59.000',
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss'),
  };
  return utcDateRange;
}

export function getCurrentDateRange(dateRange: DateRange<string>): DateRange<string> {
  const currentDateRange = {
    from: moment(
      dateRange.from?.replace(/\s+/g, ''),
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(
      dateRange.to?.replace(/\s+/g, ''),
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .format('YYYY-MM-DDTHH:mm:ss'),
  };
  return currentDateRange;
}

export function getCurrentDateRangeWithTime(dateRange: DateRange<string>): DateRange<string> {
  const currentDateRange = {
    from: moment(
      dateRange.from?.replace(/\s+/g, '') + ' 00:00:00.000',
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(
      dateRange.to?.replace(/\s+/g, '') + ' 23:59:59.000',
      'MM-DD-YYYY HH:mm:ss.SSS',
    )
      .format('YYYY-MM-DDTHH:mm:ss'),
  };
  return currentDateRange;
}
