import React from 'react';

import { ProgressBar, Filler } from './styled';

const LinearProgressBar: React.FC<ComponentProps> = ({ percentage }) => (
  <ProgressBar>
    <Filler percentage={percentage} />
  </ProgressBar>
);

interface ComponentProps {
  percentage: number;
}

export default LinearProgressBar;
