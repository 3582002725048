import CarbonFootprint, {
  ICarbonFootprint,
} from 'contracts/models/service/CarbonFootprint';
import { CarbonReductionRequest } from 'contracts/types/request';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getCarbonFootprintReport({
  fromDate,
  toDate,
  locationFilter,
}: CarbonReductionRequest): Promise<CarbonFootprint> {
  const response = await httpCustomerCore.post<CarbonFootprint>(
    'sustainability/carbon-footprint',
    {
      FromDate: fromDate,
      ToDate: toDate,
      SiteFilters: getLocationFilterApiArg(locationFilter),
    },
    {
      transformResponse: transformServiceResponse<
        ICarbonFootprint,
        CarbonFootprint
      >(CarbonFootprint),
    },
  );
  return response.data;
}

export default getCarbonFootprintReport;
