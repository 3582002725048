import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { loading } from 'core/styles';

import { FormLabel } from './FormLabel';

const StringFormLabel = FormLabel.toString();

interface FormGroupProps extends LoadingStyledComponentProps {
  fullWidth?: boolean;
  isFocused?: boolean;
  hasValue?: boolean;
  inline?: boolean;
  unconnected?: boolean;
  noMargin?: boolean;
  smallMargin?: boolean;
  flexed?: boolean;
  flex?: boolean;
  paddingFive?: boolean;
}
export const FormGroup = styled.div<FormGroupProps>`
  position: relative;
  margin-bottom: 20px;
  padding-top: 20px;
  text-align: left;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.isLoading &&
    css`
      &:after {
        position: absolute;
        content: '';
        top: 24px;
        right: 30px;
        ${loading(20)};
      }
    `};

  ${props =>
    (props.isFocused || props.hasValue) &&
    css`
      ${StringFormLabel} {
        line-height: 16px;
        font-size: 12px;
        transform: translateY(-22px);
      }
    `};

  ${props =>
    props.inline &&
    css`
      display: inline;
      margin-right: 10px;
    `};

  ${props =>
    props.unconnected &&
    css`
      margin-bottom: 0;
      padding-top: 0;
    `};

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `};

  ${props =>
    props.smallMargin &&
    css`
      margin-bottom: 10px;
    `};

  ${props =>
    props.flexed &&
    css`
      margin: 0 10px 0 0;
      padding: 0;
      flex: 1;
    `};
  ${props =>
    props.flex &&
    css`
      display: flex;
    `};
  ${props =>
    props.paddingFive &&
    css`
    padding-top: 5px;
    `};

  ${StringFormLabel} {
    transition: transform 0.2s, font-size 0.2s;
  }
`;

export const FormFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${FormGroup} {
    &:nth-child(1) {
      margin-right: 4%;
      width: 48%;
    }
    &:nth-child(2) {
      width: 48%;
    }
  }
`;
