export interface ISiteInformation {
  readonly storeName: string;
  readonly phoneNumber: string;
  readonly siteStatus: string;
  readonly internalRegion: string;
  readonly customerId: string;
  readonly attn: string;
  readonly statusEffectiveDate: string;
  readonly address: string;
  readonly siteCode: string;
  readonly emailAddress: string;
}

class SiteInformation {
  storeName?: string;
  phoneNumber?: string;
  siteStatus?: string;
  internalRegion?: string;
  customerId?: string;
  attn?: string;
  statusEffectiveDate?: string;
  address?: string;
  siteCode?: string;
  emailAddress?: string;

  constructor(data?: Partial<ISiteInformation>) {
    this.storeName = data?.storeName;
    this.phoneNumber = data?.phoneNumber;
    this.siteStatus = data?.siteStatus;
    this.internalRegion = data?.internalRegion;
    this.customerId = data?.customerId;
    this.attn = data?.attn;
    this.statusEffectiveDate = data?.statusEffectiveDate;
    this.address = data?.address;
    this.siteCode = data?.siteCode;
    this.emailAddress = data?.emailAddress;
  }
}

export default SiteInformation;
