import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { ServiceRequestDetails, SiteInformation, SiteProfile } from 'contracts/models';
import ConfirmServiceEquipmentDeliveryOptions
  from 'contracts/models/service/ServiceDeliveryConfirmationOptions';
import { ContainerContaminationDetails, ContainerFullnessLevel, ContainerFullnessStatistics, LastFillCycleInformation } from 'contracts/models/service/ServiceInsights';
import { ActionDispatcher, ServiceListAction } from 'contracts/types/action';
import { SiteIdentifier } from 'contracts/types/component';
import { ComfirmServiceEquipmentDeliveryRequest, ContainerFillCycleRequest, ContainerFullnessStatisticsRequest } from 'contracts/types/request';
import { SaveServiceThumbnailRequest, ServiceThumbnailRequest } from 'contracts/types/service';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceListState,
} from 'contracts/types/state';
import { createNotificationMessage, createTimedNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import { getContainerFullnessStatistics, getContainersFillCycleDetails, getLastFillCycleInformation, getLocationContainersFullnessLevelsByLocation } from 'services/services/partnerDataServices';

import {
  getSiteInformation,
  getSiteProfileServices,
  getConfirmationServiceEquipmentDeliveryOptions,
  confirmServiceEquipmentDelivery as confirmServiceEquipmentDeliveryService,
  getConfirmDeliveryInformation,
  saveServiceThumbnail,
  revertServiceThumbnail,
  getSiteProfileServiceByID,
} from '../services/serviceListServices';

// Actions Keys
const ROOT_KEY = 'services/serviceList';
enum ActionKey {
  LOAD_SITE_SERVICES = 'services/serviceList/LOAD_SITE_SERVICES',
  UPDATE_SERVICE_THUMBNAIL = 'services/serviceList/UPDATE_SERVICE_THUMBNAIL',
  LOAD_SITE_INFO = 'services/serviceList/LOAD_SITE_INFO',
  LOAD_CONTAINER_FULLNESS = 'services/serviceList/LOAD_CONTAINER_FULLNESS',
  LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION = 'services/serviceList/LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION',
  LOAD_LAST_FILL_CYCLE_INFORMATION = 'services/serviceList/LOAD_LAST_FILL_CYCLE_INFORMATION',
  LOAD_CONTAINER_FULLNESS_STATISTICS = 'services/serviceList/LOAD_CONTAINER_FULLNESS_STATISTICS',
  LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS = 'services/serviceList/LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS',
  RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS = 'services/serviceList/RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS',
  CONFIRM_SERVICE_DELIVERY = 'services/serviceList/CONFIRM_SERVICE_DELIVERY',
  LOAD_SERVICE_DELIVERY_INFORMATION = 'services/serviceList/LOAD_SERVICE_DELIVERY_INFORMATION',

  RESET = 'services/serviceList/RESET',
}

// Initial state
const getInitialState: () => ServiceListState = () => {
  return {
    siteProfileInfo: {},
    siteInformation: {},
    confirmServiceEquipmentDeliveryOptions: {},
    serviceDeliveryInformation: {},
    newServiceActions: [],
    containerFullnessLevels: [],
    containerContaminationDetails: { 
      events: [], 
      totalEvents: 0, 
      hasContamination: true, 
      showImages: true, 
    },
    lastFillCycleInformation: {
      materialInsightType: [], currentFillRate: null, lastEmptiedDate: ''
    },
    containerFullnessStatistics: {
      weeklyPickupFullnesses: [],
      weeklyFullnessIncreases: []
    },
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SITE_SERVICES,
  ActionKey.UPDATE_SERVICE_THUMBNAIL,
  ActionKey.LOAD_SITE_INFO,
  ActionKey.LOAD_CONTAINER_FULLNESS,
  ActionKey.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION,
  ActionKey.LOAD_LAST_FILL_CYCLE_INFORMATION,
  ActionKey.LOAD_CONTAINER_FULLNESS_STATISTICS,
  ActionKey.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS,
  ActionKey.CONFIRM_SERVICE_DELIVERY,
  ActionKey.RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS,
  ActionKey.LOAD_SERVICE_DELIVERY_INFORMATION
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceListState,
  ServiceListAction
> = {
  [ActionKey.LOAD_SITE_SERVICES]: (state, action) => {
    const { siteProfileInfo } = action;
    return update(state, { $merge: { siteProfileInfo } });
  },
  [ActionKey.UPDATE_SERVICE_THUMBNAIL]: (state, action) => {
    const { thumbnailImageUrl, indexService } = action;
    if (!indexService && indexService !== 0) {
      return state;
    }
    const newState = update(state, {
      siteProfileInfo: {
        services: {
          [indexService]: {
            $merge: {
              thumbnailImageUrl: thumbnailImageUrl,
            },
          },
        },
      }
    });
    return newState;
  },
  [ActionKey.LOAD_SITE_INFO]: (state, action) => {
    const { siteInformation } = action;
    if (!siteInformation) {
      return state;
    }
    return update(state, { $merge: { siteInformation } });
  },
  [ActionKey.LOAD_CONTAINER_FULLNESS]: (state, action) => {
    const { containerFullnessLevels } = action;
    if (!containerFullnessLevels) {
      return state;
    }
    return update(state, { $merge: { containerFullnessLevels } });
  },
  [ActionKey.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION]: (state, action) => {
    const { containerContaminationDetails } = action;
    if (!containerContaminationDetails) {
      return state;
    }
    return update(state, { $merge: { containerContaminationDetails: {
      ...state.containerContaminationDetails,
      ...containerContaminationDetails
    } } });
  },
  [ActionKey.LOAD_LAST_FILL_CYCLE_INFORMATION]: (state, action) => {
    const { lastFillCycleInformation } = action;
    if (!lastFillCycleInformation) {
      return state;
    }
    return update(state, { $merge: { lastFillCycleInformation } });
  },
  [ActionKey.LOAD_CONTAINER_FULLNESS_STATISTICS]: (state, action) => {
    const { containerFullnessStatistics } = action;
    if (!containerFullnessStatistics) {
      return state;
    }
    return update(state, { $merge: { containerFullnessStatistics } });
  },
  [ActionKey.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS]: (state, action) => {
    const { confirmServiceEquipmentDeliveryOptions } = action;
    if (!confirmServiceEquipmentDeliveryOptions) {
      return state;
    }
    return update(state, { $merge: { confirmServiceEquipmentDeliveryOptions } });
  },
  [ActionKey.RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS]: (state, action) => {
    const { confirmServiceEquipmentDeliveryOptions } = action;
    if (!confirmServiceEquipmentDeliveryOptions) {
      return state;
    }
    return update(state, { $merge: { confirmServiceEquipmentDeliveryOptions: undefined } });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionKey.CONFIRM_SERVICE_DELIVERY]: (state, action) => {
    return update(state, { $merge: {} });
  },
  [ActionKey.LOAD_SERVICE_DELIVERY_INFORMATION]: (state, action) => {
    const { serviceDeliveryInformation } = action;
    if (!serviceDeliveryInformation) {
      return state;
    }
    return update(state, { $merge: { serviceDeliveryInformation } });
  },
};

export const reducer = getReducerBuilder<ServiceListState, ServiceListAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_SITE_SERVICES: (siteProfileInfo?: SiteProfile): ServiceListAction => ({
    type: ActionKey.LOAD_SITE_SERVICES,
    siteProfileInfo,
  }),
  UPDATE_SERVICE_THUMBNAIL: (indexService?: number, thumbnailImageUrl?: string ): ServiceListAction => ({
    type: ActionKey.UPDATE_SERVICE_THUMBNAIL,
    indexService,
    thumbnailImageUrl,
  }),
  LOAD_SITE_INFO: (siteInformation?: SiteInformation): ServiceListAction => ({
    type: ActionKey.LOAD_SITE_INFO,
    siteInformation,
  }),
  LOAD_CONTAINER_FULLNESS: (containerFullnessLevels?: ContainerFullnessLevel[]): ServiceListAction => ({
    type: ActionKey.LOAD_CONTAINER_FULLNESS,
    containerFullnessLevels,
  }),
  LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION:
    (containerContaminationDetails?: ContainerContaminationDetails): ServiceListAction => ({
      type: ActionKey.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION,
      containerContaminationDetails,
    }),
  LOAD_LAST_FILL_CYCLE_INFORMATION: (lastFillCycleInformation?: LastFillCycleInformation): ServiceListAction => ({
    type: ActionKey.LOAD_LAST_FILL_CYCLE_INFORMATION,
    lastFillCycleInformation,
  }),
  LOAD_CONTAINER_FULLNESS_STATISTICS:
    (containerFullnessStatistics?: ContainerFullnessStatistics): ServiceListAction => ({
      type: ActionKey.LOAD_CONTAINER_FULLNESS_STATISTICS,
      containerFullnessStatistics,
    }),
  LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS:
    (confirmServiceEquipmentDeliveryOptions?: ConfirmServiceEquipmentDeliveryOptions): ServiceListAction => ({
      type: ActionKey.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS,
      confirmServiceEquipmentDeliveryOptions,
    }),
  CONFIRM_SERVICE_DELIVERY: (): ServiceListAction => ({
    type: ActionKey.CONFIRM_SERVICE_DELIVERY,
  }),
  LOAD_SERVICE_DELIVERY_INFORMATION:
    (serviceDeliveryInformation?: ServiceRequestDetails): ServiceListAction => ({
      type: ActionKey.LOAD_SERVICE_DELIVERY_INFORMATION,
      serviceDeliveryInformation,
    }),
  RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS: (): ServiceListAction =>
    ({ type: ActionKey.RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS }),
  RESET: (): ServiceListAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadSiteServices = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_SERVICES,
    async() => getSiteProfileServices(siteIdentifier),
    result => {
      dispatch(actionMap.LOAD_SITE_SERVICES(result));
    },
    () => {
      dispatch(actionMap.LOAD_SITE_SERVICES());
    },
  );

const loadSiteServiceByID = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_SERVICES,
    async() => getSiteProfileServiceByID(siteIdentifier),
    result => {
      dispatch(actionMap.LOAD_SITE_SERVICES(result));
    },
    () => {
      dispatch(actionMap.LOAD_SITE_SERVICES());
    },
  );

const loadSiteInformation = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SITE_INFO,
    async() => getSiteInformation(siteIdentifier),
    result => {
      dispatch(actionMap.LOAD_SITE_INFO(result));
    },
    () => {
      dispatch(actionMap.LOAD_SITE_INFO());
    },
    true,
  );

const loadContainersFullnessByLocation = (siteIdentifier: SiteIdentifier) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CONTAINER_FULLNESS,
    async() => getLocationContainersFullnessLevelsByLocation(siteIdentifier),
    result => {
      dispatch(actionMap.LOAD_CONTAINER_FULLNESS(result.containerFullnessLevels));
    },
    () => {
      dispatch(actionMap.LOAD_CONTAINER_FULLNESS());
    },
    true,
  );

const loadLastFillCycleInformation = (svcId: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_LAST_FILL_CYCLE_INFORMATION,
    async() => getLastFillCycleInformation(svcId),
    result => {
      dispatch(actionMap.LOAD_LAST_FILL_CYCLE_INFORMATION(result));
    },
    () => {
      dispatch(actionMap.LOAD_LAST_FILL_CYCLE_INFORMATION());
    },
    true,
  );

const loadContainersFillCycleDetails = (request: ContainerFillCycleRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION,
    async() => getContainersFillCycleDetails(request),
    result => {
      dispatch(actionMap.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION(result));
    },
    () => {
      dispatch(actionMap.LOAD_CONTAINER_FILL_CYCLE_CONTAMINATION());
    },
    true,
  );

const loadContainerFullnessStatistics = (request: ContainerFullnessStatisticsRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_CONTAINER_FULLNESS_STATISTICS,
    async() => getContainerFullnessStatistics(request),
    result => {
      dispatch(actionMap.LOAD_CONTAINER_FULLNESS_STATISTICS(result));
    },
    () => {
      dispatch(actionMap.LOAD_CONTAINER_FULLNESS_STATISTICS());
    },
    true,
  );

const loadServiceDeliveryConfirmationOptions
  = (svcId: number, siteId: string, custId: string, resetState: () => void) => (
    dispatch: ActionDispatcher,
    getState: () => ApplicationState,
  ) =>
    runTakeLastThunk(
      dispatch,
      getState,
      ActionKey.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS,
      async() => getConfirmationServiceEquipmentDeliveryOptions(svcId, siteId, custId),
      options => {
        dispatch(actionMap.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS(options));
      },
      () => {
        dispatch(actionMap.LOAD_SERVICE_DELIVERY_CONFIRMATION_OPTIONS());
        resetState();
      },
      true,
    );

const confirmServiceEquipmentDelivery = (arg: ComfirmServiceEquipmentDeliveryRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CONFIRM_SERVICE_DELIVERY,
    async() => confirmServiceEquipmentDeliveryService(arg),
    (siteProfile: SiteProfile) => {
      dispatch(actionMap.CONFIRM_SERVICE_DELIVERY());
      dispatch(actionMap.LOAD_SITE_SERVICES(siteProfile));
      dispatch(
        createNotificationMessage(
          NotificationType.Success,
          'Delivery Confirmation received.',
        ),
      );
    },
    () => {
      dispatch(actionMap.CONFIRM_SERVICE_DELIVERY());
    },
    true,
  );

const loadServiceDeliveryInformation = (svcId: number, siteId: string, custId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_DELIVERY_INFORMATION,
    async() => getConfirmDeliveryInformation(svcId, siteId, custId),
    information => {
      dispatch(actionMap.LOAD_SERVICE_DELIVERY_INFORMATION(information));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_DELIVERY_INFORMATION());
    },
    true,
  );

const submitServiceThumbail = (req: SaveServiceThumbnailRequest, index: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_SERVICE_THUMBNAIL,
    async() => saveServiceThumbnail(req),
    result => {
      dispatch(actionMap.UPDATE_SERVICE_THUMBNAIL(index, result.url));
      dispatch(createTimedNotificationMessage(
        NotificationType.Success,
        translate('services.successChangeThumbnailMessage'),
        3000,
      ));
    },
    () => {
      dispatch(actionMap.UPDATE_SERVICE_THUMBNAIL());
    },
    true,
  );

const resetServiceThumbail = (req: ServiceThumbnailRequest, index: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.UPDATE_SERVICE_THUMBNAIL,
    async() => revertServiceThumbnail(req),
    () => {
      dispatch(actionMap.UPDATE_SERVICE_THUMBNAIL(index, ''));
      dispatch(createTimedNotificationMessage(
        NotificationType.Success,
        translate('services.successRevertThumbnailMessage'),
        3000,
      ));
    },
    () => {
      dispatch(actionMap.UPDATE_SERVICE_THUMBNAIL());
    },
    true,
  );

const serviceListDuck = {
  thunks: {
    loadSiteServices,
    loadSiteServiceByID,
    loadSiteInformation,
    loadContainersFullnessByLocation,
    loadLastFillCycleInformation,
    loadContainersFillCycleDetails,
    loadContainerFullnessStatistics,
    loadServiceDeliveryConfirmationOptions,
    confirmServiceEquipmentDelivery,
    loadServiceDeliveryInformation,
    submitServiceThumbail,
    resetServiceThumbail
  },
  actions: {
    reset: actionMap.RESET,
    resetLoadServiceDeliveryConfirmationOptions: actionMap.RESET_SERVICE_DELIVERY_CONFIRMATION_OPTIONS
  },
  actionKeys: ActionKey,
};

export default serviceListDuck;
