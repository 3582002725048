import { ServiceRequestDetails } from 'contracts/models';
import ConfirmServiceEquipmentDeliveryOptions from 'contracts/models/service/ServiceDeliveryConfirmationOptions';
import SiteInformation, {
  ISiteInformation,
} from 'contracts/models/service/SiteInformation';
import SiteProfile, {
  ISiteProfile,
  SiteProfileServiceAction,
} from 'contracts/models/service/SiteProfile';
import SiteProfileEdit, {
  ISiteProfileEdit,
} from 'contracts/models/service/SiteProfileEdit';
import { SiteIdentifier } from 'contracts/types/component';
import {
  ComfirmServiceEquipmentDeliveryRequest,
  ServiceActionsRequest,
  SiteProfileEditRequest,
} from 'contracts/types/request';
import { UploadServiceThumbnailResponse, UploadServiceThumbnailRequest, ServiceThumbnailRequest, SaveServiceThumbnailRequest, SubmitThumbnailResponse } from 'contracts/types/service';
import { writeBlobFile } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getSiteEditInfo = async(siteIdentifier: SiteIdentifier): Promise<SiteProfileEdit> => {
  const response = await httpCustomerCore.get('site-profile/edit', {
    params: {
      custId: siteIdentifier.siteCustomerId,
      siteId: siteIdentifier.siteId,
    },
    transformResponse: transformServiceResponse<
      ISiteProfileEdit,
      SiteProfileEdit
    >(SiteProfileEdit),
  });
  return response.data;
};

export const saveSiteInfo = async({
  custId,
  siteId,
  siteName,
  linkedUsers,
  linkedTags,
}: SiteProfileEditRequest): Promise<SiteProfileEdit> => {
  const response = await httpCustomerCore.post('site-profile/edit', {
    custId,
    siteId,
    siteName,
    linkedUsers,
    linkedTags,
  });
  return response.data;
};

export const getSiteProfileServices = async(
  siteIdentifier: SiteIdentifier,
): Promise<SiteProfile> => {
  const response = await httpCustomerCore.get<SiteProfile>('site-profile', {
    params: {
      custId: siteIdentifier.siteCustomerId,
      siteId: siteIdentifier.siteId,
    },
    transformResponse: transformServiceResponse<ISiteProfile, SiteProfile>(
      SiteProfile,
    ),
  });
  return response.data;
};

export const getSiteProfileServiceByID = async(
  siteIdentifier: SiteIdentifier,
): Promise<SiteProfile> => {
  const response = await httpCustomerCore.get<SiteProfile>('site-profile', {
    params: {
      custId: siteIdentifier.siteCustomerId,
      siteId: siteIdentifier.siteId,
      svcId: siteIdentifier.serviceId
    },
    transformResponse: transformServiceResponse<ISiteProfile, SiteProfile>(
      SiteProfile,
    ),
  });
  return response.data;
};

export const getSiteInformation = async({
  siteId,
  siteCustomerId,
}: SiteIdentifier): Promise<SiteInformation> => {
  const response = await httpCustomerCore.get<SiteInformation>(
    'site-profile/site-information',
    {
      params: {
        custId: siteCustomerId,
        siteId,
      },
      transformResponse: transformServiceResponse<
        ISiteInformation,
        SiteInformation
      >(SiteInformation),
    },
  );
  return response.data;
};

export const getServiceActions = async(
  arg: ServiceActionsRequest,
): Promise<SiteProfileServiceAction[]> => {
  const response = await httpCustomerCore.post<SiteProfileServiceAction[]>(
    'site-profile/service-actions',
    arg,
  );
  return response.data;
};

export const getServiceScheduledDays = async(svcId: number, siteId: string): Promise<string[]> => {
  const response = await httpCustomerCore.get<string[]>(
    `service-equipment/equipment-schedule?svcId=${svcId}&siteId=${siteId}`,
  );
  return response.data;
};

export const getConfirmationServiceEquipmentDeliveryOptions =
  async(svcId: number, siteId: string, custId: string): Promise<ConfirmServiceEquipmentDeliveryOptions> => {
    const response = await httpCustomerCore.get<ConfirmServiceEquipmentDeliveryOptions>(
      `service-equipment/confirm-delivery-options?svcId=${svcId}&siteId=${siteId}&custID=${custId}`,
    );
    return response.data;
  };

export const confirmServiceEquipmentDelivery =
  async(arg: ComfirmServiceEquipmentDeliveryRequest): Promise<SiteProfile> => {
    const response = await httpCustomerCore.post<SiteProfile>(
      `service-equipment/confirm-delivery`, {
        ...arg
      }
    );
    return response.data;
  };

export const getConfirmDeliveryInformation =
  async(svcId: number, siteId: string, custId: string): Promise<ServiceRequestDetails> => {
    const response = await httpCustomerCore.get<ServiceRequestDetails>(
      `service-equipment/delivery-notes?svcId=${svcId}&siteId=${siteId}&custId=${custId}`,
    );
    return response.data;
  };

export const getServiceThumbnail =
  async(getThumbnailReq: ServiceThumbnailRequest): Promise<UploadServiceThumbnailResponse> => {
    const response = await httpCustomerCore.get<UploadServiceThumbnailResponse>(
      `service-equipment/get-image`, {
        params: {
          ...getThumbnailReq
        }
      },
    );
    return response.data;
  };
  
export async function uploadServiceThumbnail(
  uploadFileRequest: UploadServiceThumbnailRequest,
): Promise<UploadServiceThumbnailResponse> {
  const response = await writeBlobFile(
    httpCustomerCore,
    'service-equipment/image/upload',
    uploadFileRequest,
  );
  return response;
}

export const revertServiceThumbnail = async(revertThumbnailReq: ServiceThumbnailRequest): 
Promise<UploadServiceThumbnailResponse> => {
  const response = await httpCustomerCore.post<UploadServiceThumbnailResponse>(
    `service-equipment/image/revert`, {
      ...revertThumbnailReq
    }
  );
  return response.data;
};

export const saveServiceThumbnail = async(saveThumbnailReq: SaveServiceThumbnailRequest): 
Promise<SubmitThumbnailResponse> => {
  const response = await httpCustomerCore.post<SubmitThumbnailResponse>(
    `service-equipment/image/submit`, {
      ...saveThumbnailReq
    }
  );
  return response.data;
};
