import React, { PureComponent } from 'react';

import { SlaStatusFilter as SlaStatusFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

const slaOptions = [
  { id: 'Missed', label: translate('services.missed') },
  { id: 'Met', label: translate('services.met') },
];

class SlaStatusFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (selectedIds: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      sla: selectedIds,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled } = this.props;
    return (
      <DropdownFilter
        label={translate('core.SLAStatus')}
        values={slaOptions}
        selectedIds={currentFilter.sla || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
      />
    );
  }
}

interface ComponentProps {
  currentFilter: SlaStatusFilterType;
  onFilterChanged: (filter: SlaStatusFilterType) => void;
  isDisabled?: boolean;
}

export default SlaStatusFilter;
