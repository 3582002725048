import { WholesaleCart } from 'contracts/models';
import NewService from 'contracts/models/service/NewService';
import { NewServiceEquipmentRequest } from 'contracts/models/service/NewServiceQuote';
import ServiceQuoteItem from 'contracts/models/service/ServiceQuoteItem';
import { SiteProfileServiceDays } from 'contracts/models/service/SiteProfile';

import { SiteFiltersModel } from './compactorProfile';
import {
  HaulerBillConsolidationFilter,
  InvoiceFilter,
  LocationEquipmentStatusFilter,
  LocationFilter,
  MaterialSpendFilter,
  ServiceAnalyticsFilter,
  ServiceApprovalFilter,
  ServiceRequestsFilter,
  SiteIdentifier,
} from './component';
import { CommodityOptionItemResponse, UploadFileResponse } from './service';

export interface SortFields {
  ascending: string[];
  descending: string[];
}

export interface StoreSortFields extends SortFields {
  storeIds: SiteIdentifier[];
}

export interface CreateUserRequest {
  emailAddress: string;
  role: string;
  firstName: string;
  lastName: string;
  userId?: string;
}

export interface SetUserAccessRequest {
  allCustomerLocationsAccess: boolean;
  allOwnerLocationsAccess: boolean;
}

export interface AddUserSitesRequest {
  emailAddress: string;
  sites: AddUserSite[];
}

export interface AddUserSite {
  custId: string;
  siteId: string;
}

export interface RemoveUserSitesRequest {
  emailAddress: string;
  sites: RemoveUserSite[];
}

export interface RemoveUserSite {
  custId: string;
  siteId: string;
  tagId: number | null;
}

export interface UpdateUserSitesRequest {
  emailAddress: string;
  addSites: AddUserSite[];
  removeSites: RemoveUserSite[];
}

export interface AddTagSitesToUserRequest {
  userId: number;
  tagIds: number[];
}

export interface CustomerTagRequest {
  name: string;
  description: string;
  isAdminTag: boolean;
}

export interface CustomerTagSiteAddRequest {
  customerId: string;
  siteId: string;
  tagId: number;
}

export interface CustomerTagSiteDeleteRequest {
  tagSiteId: number;
  siteId: string;
  customerId: string;
}

export interface InvoiceDetailsRequest {
  fromDate: string;
  toDate: string;
  pageSize: number;
  pageNumber: number;
  locationFilter: InvoiceFilter;
  isRecurring: boolean | null;
  isProject: boolean | null;
  chargeType?: string;
  siteIdentifier?: SiteIdentifier;
  sortFields: SortFields;
  isTemporary?: boolean;
  isPermanent?: boolean;
}

export interface InvoiceDetailsRequestArg {
  startDate: string;
  endDate: string;
  siteFilters: SiteFiltersModel | null;
  chargeType: string;
  siteIdentifier: SiteIdentifier | null;
  isProject: boolean | null;
  isRecurring: boolean | null;
  pageSize: number;
  pageNumber: number;
}

export interface InvoiceDetailsTableRequestArg extends InvoiceDetailsRequestArg {
  orderByAscending: InvoiceDetailsSortModel;
  orderByDescending: InvoiceDetailsSortModel;
}

export interface InvoiceDetailsSortModel {
 [key: string]: boolean;
}

export interface SavingsRequest {
  fromDate: string;
  toDate: string;
  locationFilter: LocationFilter;
  buildSavingsDetails?: boolean;
}

export interface InvoiceListRequest {
  fromDate: string;
  toDate: string;
  invoiceNumber?: number;
}

export interface HaulerInvoiceListRequest {
  status?: string;
  siteId: string;
  fromDate?: string;
  toDate?: string;
}

export interface VendorInvoiceListRequest {
  fromDate: string;
  toDate: string;
  pageSize: number;
  pageNumber: number;
  locationFilter: InvoiceFilter;
  status?: string;
}

export interface HaulerBillConsolidationRequest {
  fromDate: string;
  toDate: string;
  pageNumber: number;
  pageSize: number;
  siteFilters: HaulerBillConsolidationFilter;
}

export interface HaulerBillConsolidationReportIssueRequest {
  invoiceIds: number[];
  issueTypeId: number;
}

export interface HaulerBillConsolidationPayInvoiceRequest {
  invoiceIds: number[];
  paymentAddress?: string;
  paymentCity?: string;
  paymentState?: string;
  paymentZip?: string;
  paymentNumber: string;
  paymentDate: string;
  isPartiallyPaid: boolean;
}

export interface ServiceRequestsRequest {
  fromDate: string;
  toDate: string;
  filter: ServiceRequestsFilter;
  serviceTypeId?: string;
  siteIdentifier?: SiteIdentifier;
  pageNumber?: number;
  pageSize?: number;
}

export interface ServiceRequestOption {
  custId: string;
  siteId: string;
  serviceId: number;
  requestType: string;
  commodityServiceIds: number[];
  serviceCode: string;
  containerType: string;
  serviceProcessor: string;
  svcOrd?: string;
  orderNumber?: string;
}

export interface NewServiceOptionsRequest {
  custId: string;
  siteId: string;
  serviceOrderType?: string;
  service: string;
  serviceCode?: string;
}

export interface NewServiceSubmitRequest {
  custId: string;
  siteId: string;
  address: string;
  zipCode?: string;
  siteName: string;
  services: NewService[] | NewServiceEquipmentRequest[];
}

export interface UpdateServiceRequestOption {
  workOrder: number;
  serviceProcessor?: string;
}

export interface CieTradeRequest {
  cietradeTrackingHeaderId?: number;
  referenceNumber?: string;
  siteId?: string;
  matSubType?: string;
  cietradeRequestTypeId?: number;
  serviceDate?: string;
  corePallet?: number;
  nonCorePallet?: number;
  chepPallet?: number;
  peco?: number;
  igps?: number;
  baleCount?: number;
  requestNote?: string;
}

export interface SpendByMaterialRequest {
  fromDate: string;
  toDate: string;
  reportFilters: MaterialSpendFilter;
  materialCategoryId?: number;
  site?: number;
}

export interface DiversionRequest {
  fromDate: string;
  toDate: string;
  reportFilters: LocationEquipmentStatusFilter;
  materialType?: string;
  diverted?: boolean;
  materialCategoryId?: string;
  sortFields?: {
    ascending?: string[];
    descending?: string[];
  };
}
export interface MaterialProfileRequest {
  fromDate: string;
  toDate: string;
  reportFilters: LocationEquipmentStatusFilter;
  categoryId: string | null;
  siteIdentifier: SiteIdentifier | null;
}

export interface MaterialManagementRequest {
  fromDate: string;
  toDate: string;
  reportFilters: LocationEquipmentStatusFilter;
  disposalMethodType?: string;
  siteIdentifier: SiteIdentifier;
}

export interface CarbonEmissionsRequest {
  fromDate: string;
  toDate: string;
  locationFilter: LocationFilter;
  categoryId: string;
}

export interface CarbonEmissionsTableRequest extends CarbonEmissionsRequest {
  pageSize: number;
  pageNumber: number;
}

export interface GhGCarbonEmissionsRequest {
  fromDate: string;
  toDate: string;
  locationFilter: LocationFilter;
  categoryId: number | null;
}

export interface GhGCarbonEmissionsTableRequest extends GhGCarbonEmissionsRequest {
  pageSize: number;
  pageNumber: number;
}

export interface PaginationdRequest {
  pageSize: number;
  pageNumber: number;
}

export interface CarbonReductionRequest {
  fromDate: string;
  toDate: string;
  locationFilter: LocationFilter;
}

export interface DisposalAuditRequest {
  fromDate: string;
  toDate: string;
  pageNumber: number;
  pageSize: number;
  locationFilter: LocationFilter;
}

export interface ApprovalRequestsRequest {
  fromDate: string;
  toDate: string;
  pageNumber: number;
  pageSize: number;
  filter: ServiceApprovalFilter;
}

export interface RequestServiceRequest {
  serviceProcessor: string;
  serviceId: number | null;
  siteId: string;
  custId: string;
  serviceCode: string | null;
  serviceType: string;
  serviceDate: string;
  notes?: string;
  binId: string;
  containerType: string;
  size?: string;
  quantity: number;
  wasteType: string;
  freq: string;
  requestType: string;
  poNumber?: string;
  referenceNumber?: string;
  billOfLandingNo?: string;
  addressRelocation?: string;
  containerNumber?: string;
  compactorPsi?: number | null;
  uploadedImageFiles?: UploadFileResponse[];
  commodities?: CommodityOptionItemResponse[];
  svcOrder?: string;
  orderNumber?: string;
}

export interface RequestServiceUpdateRequest extends RequestServiceRequest {
  serviceOrder: number;
  workOrder: number;
}

export interface ServiceUpdateQuoteRequest {
  serviceId?: number;
  businessCode?: string;
  zipCode?: string;
  address?: string;
  serviceType?: string;
  material?: string;
  frequency?: string;
  schedule?: string;
  numberOfContainers?: number;
}

// New Service
export interface ServiceAvailabilityRequest {
  customerId: string;
  siteId: string;
  duration?: string;
}
export interface MarketAvailabilityRequest {
  customerId: string;
  siteId: string;
  serviceCode: string
}
export interface NewServiceQuoteRequest {
  customerId: string;
  siteId: string;
  service: string
  quantity: number;
  material: string;
  schedule: string
  equipment: string;
  frequency: string;
}
export interface ServiceUpdateMarketAvailabilityRequest {
  zipCode: string;
  address: string;
  serviceId: number;
  siteId: string;
  custId: string;
}

export interface ServiceSupportContactRequest {
  siteId: string;
  custId: string;
}
export interface CreateServiceUpdateRequest {
  serviceId: number;
  siteId?: string;
  custId?: string;
  service: ServiceQuoteItem;
}

export interface CheckRequestDuplicateArg {
  CustId: string;
  SiteId: string;
  ServiceId: number;
  ServiceType: string;
  ServiceDate: string | null;
  DaysOfWeek?: SiteProfileServiceDays[] | null;
  CompactorPsi?: number | null;
  CheckRequestInSameDayOfFreq?: boolean;
  CheckRequestInNextDayOfFreq?: boolean;
  CheckCompactorPsiIsEmpty?: boolean;
  CheckRequestDayWithExpected?: boolean;
  SvcOrd?: string;
  OrderNumber?: string;
}

export interface SiteProfileEditRequest {
  custId: string;
  siteId: string;
  siteName: string;
  linkedUsers: number[];
  linkedTags: number[];
}

export type WholesaleCartRequest = WholesaleCart

export interface OpenTopTempServicesRequest {
  address: string;
  zipCode: string;
  material: string;
}

export interface ZuoraPaynowRequestArg {
  accountId: string;
  paymentMethodId: string;
  invoicesToPay: ZuoraPaynowInvoiceRequestArg[];
  quickPay: boolean;
  siteId?: string;
}

export interface ZuoraPaynowInvoiceRequestArg {
  zuoraInvoiceId: string;
  amount: number;
  invoiceNumber: string;
  siteIdentifier?: SiteIdentifier;
}

export interface UpdateUserRequest {
  role: string;
  phone: string;
  firstName: string;
  lastName: string;
}

export interface UserSites {
  siteId: string;
  custId: string;
}

export interface UserSitesWithTag {
  siteId: string;
  custId: string;
  tagId?: string;
}

export interface ServiceActionsRequest {
  serviceId: number;
  commodityServiceIds?: number[];
  siteIdentifier?: SiteIdentifier;
}

export interface ServiceCancelPaymentRequest {
  alias: string;
  paymentMethodId?: string;
}

export interface ServiceApprovalDetailsArg {
  serviceOrder: number;
  serviceProcessor?: string;
}

export interface ServiceApproveRequestArg {
  isUpdate: boolean;
  serviceDate: string;
  serviceOrder: number;
  uploadedImageFiles?: UploadFileResponse[];
}

export interface ServiceDenyRequestArg {
  denyReason: string;
  serviceOrder: number;
}

export interface UniqueReferenceNrRequest {
  releaseNumber: string;
  custId: string;
  siteId: string;
}
export interface ConfirmOrderRequestArg {
  startDate: string;
  alias: string;
  custId: string;
  siteId: string;
  serviceAgreementToken: string;
  denyReason: string;
}

export interface RequestUpdateService {
  startDate: string;
  serviceId: string;
  custId: string;
  siteId: string;
  serviceAgreementToken?: string;
}

export interface NotificationsListRequest {
  fromDate?: string;
  endDate?: string;
  pageNumber: number;
  pageSize: number;
  searchTerm?: string;
  storeIds?: SiteIdentifier[];
  responseWithSites?: boolean;
}

export interface UnreadNotificationCounterRequest {
  startDate: Date;
  endDate: Date;
}

export interface CustomerRegWatchUpdatesRequest {
  startDate: string;
  endDate: string;
}

export interface CustomerRegWatchUpdatesCsvRequest extends CustomerRegWatchUpdatesRequest {
  locationFilter: LocationFilter;
}

export interface SiteProfileRegWatchUpdatesRequest {
  custId: string;
  siteId: string;
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
  accessToken?: string;
}

export interface UpdateAcknowledgementRequest {
  updateId: number;
  regWatchToken?: string;
}

export interface NewServiceActionsRequest {
  siteId: string;
  custId: string;
}

export interface MarketingContentRequest {
  categories: string[];
}

export interface CityFilterRequest {
  city: string;
}

export interface ZipFilterRequest {
  zip: string;
}

export interface ContainerFillCycleRequest {
  svcId: number;
  pageSize: number | null;
  pageNumber: number | null;
}

export interface ContainerFullnessStatisticsRequest {
  svcId: number;
  fullnessStatisticsStartDate: string | null;
  fullnessStatisticsEndDate: string | null;
}

export interface ServiceAgreementsListRequest {
  pageNumber: number;
  pageSize: number;
}

export interface ComfirmServiceEquipmentDeliveryRequest {
  deliveryDate: string;
  notes: string;
  uploadedImageFiles?: UploadFileResponse[];
  svcId: number;
  siteId: string;
  svcOrd: string;
  activityCaseId?: string;
  custId?: string;
}

export enum BulkUploadTemplateType {
  recurringService = 'Recurring',
  onDemandService = 'OnDemand',
  seasonalService = 'Seasonal',
  allServices = 'All'
}

export interface RecurringServiceRequest {
  extServiceId: string;
  isOverrider: boolean;
  intervals: RecurringServiceDetailsItem[]
}

export interface OnDemandPickUpRequest {
  custId: string;
  siteId: string;
  serviceDate: string;
  serviceCode: string;
  serviceDescription?: string;
  material: string;
  workOrderNumber?: string;
  quantity: number;
  unitOfMeasure: string;
  totalAmount: number;
  disposalMethod: string;
  id?: number;
}

export interface OnDemandPickUpUpdateRequest extends OnDemandPickUpRequest{
  id?: number;
}

export interface RecurringServiceDetailsItem {
  serviceId?: number;
  startDate: string;
  endDate: string;
  custId: string;
  siteId: string;
  serviceCode: string;
  serviceDescription: string;
  material: string;
  frequency: string;
  workOrderNumber: string;
  disposalMethod: string;
  quantity: number;
  pricePerOccurrence: number;
  pricePerMonth: number;
}

export interface SeasonalServiceRequest {
  extServiceId?: string;
  startDate: string;
  endDate: string;
  frequency: string;
  workOrderNumber: string;
  pricePerOccurrence: number;
  pricePerMonth: number;
}

export interface CheckRecurringServiceUpdateResponse {
  askToRestimateExistent: boolean
}

export interface CheckOnDemandPickUpsUpdateRequest {
  isImpactReports: boolean
}

export interface GetRecurringServicesRequest extends StoreSortFields {
  pageNumber: number,
  pageSize: number
  filter?: {
    isSeasonal: boolean,
    isRecurring: boolean;
  }
}

export interface GetOnDemandPickUpsRequest extends StoreSortFields {
  pageNumber: number,
  pageSize: number,
}
export interface SaasCheckUniqueRecurringSvcRequest {
  extServiceId?: string;
}

export interface SaasServiceRecurringOptionsRequest extends SaasCheckUniqueRecurringSvcRequest {
  isSeasonalOptions?: boolean;
}

export interface GetOnDemandPickUpOptionsRequest {
  isNewPickup: boolean
}

export interface DisposalAuditDetailsRequestArg {
  fromDate: string;
  toDate: string;
  pageNumber: number;
  pageSize: number;
  siteFilters: ServiceAnalyticsFilter;
}

export interface PreregisterCodeDataView {
  encodedValue: string;
  utcExpiration?: string;
}

export interface PreRegisterUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  expirationDate?: string;
  encodedValue: string;
  recaptchaResponse?: string;
}

export interface RegisterUserRequest {
  encodedValue: string;
  password: string;
}

export interface CustomerDocumentPaginationArg {
  startDate?: string | null;
  endDate?: string | null;
  custId: string;
  siteId: string;
  serviceId: number | null;
  serviceOrd?: number | null;
  pageNumber?: number | null;
  pageSize?: number | null;
  documentTypes: string[];
  orderByAscending?: SiteProfileRequestPaginationOrderModel;
  orderByDescending?: SiteProfileRequestPaginationOrderModel;
}

export interface SiteProfileRequestPaginationOrderModel {
  date: boolean;
}

export interface CustomerDocumentArg {
  startDate: string | null;
  endDate: string | null;
  custId: string;
  siteId: string;
  serviceId?: number | null;
  serviceOrd?: number | null;
}
