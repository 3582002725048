import update from 'immutability-helper';

import { SaleAddress } from 'contracts/models';
import { WholesaleAddress } from 'contracts/models/service/WholesaleCart';
import { ActionDispatcher, AddressesAction } from 'contracts/types/action';
import { FranchiseResponseModel } from 'contracts/types/service';
import {
  AddressesState,
  ApplicationState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import loadAddress from 'core/services/loadAddresses';
import checkFranchise from 'wholesale/services/checkFranchise';

// Actions Keys
const ROOT_KEY = 'addresses';
enum ActionKey {
  LOAD_ADDRESSES = 'addresses/LOAD_ADDRESSES',
  CHECK_FRANCHISE = 'addresses/CHECK_FRANCHISE',
  RESET = 'addresses/RESET',
}

// Initial State
const getInitialState: () => AddressesState = () => {
  return {
    addresses: [],
    addressErrors: [],
    isValidAddress: false
  };
};

// Reducer
const reducerKeys = [ActionKey.LOAD_ADDRESSES, ActionKey.CHECK_FRANCHISE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AddressesState,
  AddressesAction
> = {
  [ActionKey.LOAD_ADDRESSES]: (state, action) => {
    return update(state, {
      $merge: { addresses: action.addresses },
    });
  },
  [ActionKey.CHECK_FRANCHISE]: (state, action) => {
    return update(state, {
      $merge: {
        addressErrors: action.addressErrors,
        isValidAddress: action.isValidAddress,
      },
    });
  },
};

export const reducer = getReducerBuilder<AddressesState, AddressesAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_ADDRESS: (addresses?: SaleAddress[]): AddressesAction => ({
    type: ActionKey.LOAD_ADDRESSES,
    addresses,
  }),
  CHECK_FRANCHISE: (addressErrors?: string[]): AddressesAction => ({
    type: ActionKey.CHECK_FRANCHISE,
    addressErrors,
    isValidAddress: addressErrors?.length === 0,
  }),
  RESET: (): AddressesAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadAddresses = (searchTerm: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_ADDRESSES,
    async() => loadAddress(searchTerm),
    result => {
      dispatch(actionMap.LOAD_ADDRESS([result]));
    },
    () => {
      dispatch(actionMap.LOAD_ADDRESS([]));
    },
  );

const validateAddress = (address: WholesaleAddress, errosList: string[]) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CHECK_FRANCHISE,
    async() =>
      checkFranchise({
        standardizedAddress: address.line1,
        zipCode: address.zip,
      }),
    (response: FranchiseResponseModel) => {
      if (response.franchise) {
        errosList.push('We cannot provide service in this area.');
      }
      dispatch(actionMap.CHECK_FRANCHISE(errosList));
    },
    () => {
      errosList.push('Cannot check the address. Please try again.');
      dispatch(actionMap.CHECK_FRANCHISE(errosList));
    },
  );
const addressesDuck = {
  thunks: { loadAddresses, validateAddress },
  actionKeys: ActionKey,
  actions: { reset: actionMap.RESET },
};
export default addressesDuck;
