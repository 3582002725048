import update from 'immutability-helper';

import { GhGCarbonEmissionsComparison, GhGCarbonEmissionsSummary, GhGCarbonEmissionsTable } from 'contracts/models';
import CarbonEmissionsComparison from 'contracts/models/service/CarbonEmissionsComparison';
import CarbonEmissionsSummary from 'contracts/models/service/CarbonEmissionsSummary';
import CarbonEmissionsTableItemData from 'contracts/models/service/CarbonEmissionsTableItemData';
import {
  ActionDispatcher,
  CarbonEmissionsAction,
} from 'contracts/types/action';
import {
  CarbonEmissionsRequest,
  CarbonEmissionsTableRequest,
  GhGCarbonEmissionsRequest,
  GhGCarbonEmissionsTableRequest,
} from 'contracts/types/request';
import {
  ApplicationState,
  CarbonEmissionsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getCarbonEmissionsComparison,
  getCarbonEmissionsSummary,
  getCarbonEmissionsTable,
  getGHGProtocolEmissionsComparison,
  getGHGProtocolEmissionsSummary,
  getGHGProtocolEmissionsTable,
} from '../services/carbonEmissionsService';

// Actions
const ROOT_KEY = 'sustainability/carbonEmissions';
enum ActionKey {
  LOAD_SUMMARY = 'sustainability/carbonEmissions/LOAD_SUMMARY',
  LOAD_COMPARISON = 'sustainability/carbonEmissions/LOAD_COMPARISON',
  LOAD_TABLE = 'sustainability/carbonEmissions/LOAD_TABLE',
  LOAD_GHG_SUMMARY = 'sustainability/carbonEmissions/LOAD_GHG_SUMMARY',
  LOAD_GHG_COMPARISON = 'sustainability/carbonEmissions/LOAD_GHG_COMPARISON',
  LOAD_GHG_TABLE = 'sustainability/carbonEmissions/LOAD_GHG_TABLE',
  RESET = 'sustainability/carbonEmissions/RESET',
}

// Initial State
const getInitialState: () => CarbonEmissionsState = () => {
  return { 
    summary: {},
    comparison: {},
    table: [],
    ghgSummary: {},
    ghgComparison: {},
    ghgTable: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SUMMARY,
  ActionKey.LOAD_COMPARISON,
  ActionKey.LOAD_TABLE,
  ActionKey.LOAD_GHG_SUMMARY,
  ActionKey.LOAD_GHG_COMPARISON,
  ActionKey.LOAD_GHG_TABLE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CarbonEmissionsState,
  CarbonEmissionsAction
> = {
  [ActionKey.LOAD_SUMMARY]: (state, action) => {
    const { summary } = action;
    if (!summary) {
      return state;
    }
    return update(state, { $merge: { summary } });
  },
  [ActionKey.LOAD_COMPARISON]: (state, action) => {
    const { comparison } = action;
    if (!comparison) {
      return state;
    }
    return update(state, { $merge: { comparison } });
  },
  [ActionKey.LOAD_TABLE]: (state, action) => {
    const { table } = action;
    if (!table) {
      return state;
    }
    return update(state, { $merge: { table } });
  },
  [ActionKey.LOAD_GHG_SUMMARY]: (state, action) => {
    const { ghgSummary } = action;
    if (!ghgSummary) {
      return state;
    }
    return update(state, { $merge: { ghgSummary } });
  },
  [ActionKey.LOAD_GHG_COMPARISON]: (state, action) => {
    const { ghgComparison } = action;
    if (!ghgComparison) {
      return state;
    }
    return update(state, { $merge: { ghgComparison } });
  },
  [ActionKey.LOAD_GHG_TABLE]: (state, action) => {
    const { ghgTable } = action;
    if (!ghgTable) {
      return state;
    }
    return update(state, { $merge: { ghgTable } });
  },
};

export const reducer = getReducerBuilder<
  CarbonEmissionsState,
  CarbonEmissionsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action
const actionMap = {
  LOAD_SUMMARY: (summary?: CarbonEmissionsSummary): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_SUMMARY,
    summary,
  }),
  LOAD_COMPARISON: (
    comparison?: CarbonEmissionsComparison,
  ): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_COMPARISON,
    comparison,
  }),
  LOAD_TABLE: (
    table?: CarbonEmissionsTableItemData[],
  ): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_TABLE,
    table,
  }),
  LOAD_GHG_SUMMARY: (ghgSummary?: GhGCarbonEmissionsSummary): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_GHG_SUMMARY,
    ghgSummary,
  }),
  LOAD_GHG_COMPARISON: (
    ghgComparison?: GhGCarbonEmissionsComparison,
  ): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_GHG_COMPARISON,
    ghgComparison,
  }),
  LOAD_GHG_TABLE: (
    ghgTable?: GhGCarbonEmissionsTable,
  ): CarbonEmissionsAction => ({
    type: ActionKey.LOAD_GHG_TABLE,
    ghgTable,
  }),
  RESET: (): CarbonEmissionsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadCarbonEmissionsSummary = (inputArg: CarbonEmissionsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SUMMARY,
    () => getCarbonEmissionsSummary(inputArg),
    result => {
      dispatch(actionMap.LOAD_SUMMARY(result));
    },
    () => {
      dispatch(actionMap.LOAD_SUMMARY());
    },
    true,
  );

const loadCarbonEmissionsComparison = (
  inputArg: CarbonEmissionsRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_COMPARISON,
    () => getCarbonEmissionsComparison(inputArg),
    result => {
      dispatch(actionMap.LOAD_COMPARISON(result));
    },
    () => {
      dispatch(actionMap.LOAD_COMPARISON());
    },
    true,
  );

const loadCarbonEmissionsTable = (
  inputArg: CarbonEmissionsTableRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_TABLE,
    () => getCarbonEmissionsTable(inputArg),
    result => {
      dispatch(actionMap.LOAD_TABLE(result));
    },
    () => {
      dispatch(actionMap.LOAD_TABLE());
    },
    true,
  );

const loadGHGProtocolEmissionsSummary = (inputArg: GhGCarbonEmissionsRequest) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_GHG_SUMMARY,
    () => getGHGProtocolEmissionsSummary(inputArg),
    result => {
      dispatch(actionMap.LOAD_GHG_SUMMARY(result));
    },
    () => {
      dispatch(actionMap.LOAD_GHG_SUMMARY());
    },
    true,
  );
  
const loadGHGProtocolEmissionsComparison = (
  inputArg: GhGCarbonEmissionsRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_GHG_COMPARISON,
    () => getGHGProtocolEmissionsComparison(inputArg),
    result => {
      dispatch(actionMap.LOAD_GHG_COMPARISON(result));
    },
    () => {
      dispatch(actionMap.LOAD_GHG_COMPARISON());
    },
    true,
  );
  
const loadGHGProtocolEmissionsTable = (
  inputArg: GhGCarbonEmissionsTableRequest,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_GHG_TABLE,
    () => getGHGProtocolEmissionsTable(inputArg),
    result => {
      dispatch(actionMap.LOAD_GHG_TABLE(result));
    },
    () => {
      dispatch(actionMap.LOAD_GHG_TABLE());
    },
    true,
  );

const carbonEmissionsDuck = {
  thunks: {
    loadCarbonEmissionsSummary,
    loadCarbonEmissionsComparison,
    loadCarbonEmissionsTable,
    loadGHGProtocolEmissionsSummary,
    loadGHGProtocolEmissionsComparison,
    loadGHGProtocolEmissionsTable
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default carbonEmissionsDuck;
