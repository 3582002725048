/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axios, { AxiosRequestConfig, AxiosRequestTransformer, AxiosResponseTransformer, AxiosResponse, AxiosInstance } from 'axios';
import humps from 'humps';
import isObject from 'lodash-es/isObject';

import { ServiceError } from 'contracts/types/service';
import API_KEY from 'core/constants/ApiKey';
import getAxiosTransformers from 'core/helpers/axiosTransformers';
import { logError } from 'core/logging';

import { apiBaseURLs } from './environment';

const camelizeResponse: AxiosRequestTransformer = (data: unknown) =>
  isObject(data) ? humps.camelizeKeys(data) : data;

const transformResponse: AxiosResponseTransformer[] = getAxiosTransformers(
  camelizeResponse,
);

export function transformServiceResponse<ModelInterface, ModelClass>(
  model: new (data: ModelInterface) => ModelClass,
): AxiosResponseTransformer[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseMapper = (response: any): any => {
    if (response) {
      const error = response as ServiceError;
      if (error && error.error && error.message) {
        return response;
      } else {
        if (Array.isArray(response)) {
          return response.map(item => new model(item));
        }
        return new model(response);
      }
    }
  };
  const transformers = getAxiosTransformers(
    ...transformResponse,
    responseMapper,
  );
  return transformers;
}

const defaultHttpSettings: AxiosRequestConfig = {
  responseType: 'json',
  transformResponse,
  headers: {
    'ocp-apim-subscription-key': API_KEY,
    Pragma: 'no-cache'
  },
};

const defaultZuoraHttpSettings: AxiosRequestConfig = {
  responseType: 'json',
  transformResponse,
  headers: {
    Pragma: 'no-cache'
  },
};

export const httpZuora = axios.create({
  ...defaultZuoraHttpSettings,
  baseURL: `${apiBaseURLs.zuora}/`,
});

const httpCustomerCoreApi = axios.create({
  ...defaultHttpSettings,
  baseURL: `${apiBaseURLs.customerCore}/`
});

export function transformServiceResponseSimple(
  transformer: AxiosRequestTransformer,
): AxiosResponseTransformer[] {
  const transformers = getAxiosTransformers(...transformResponse, transformer);
  return transformers;
}

enum CancellationExceptions {
  notifications = 'notification/unread-count',
  openAuthPrivate = 'open-auth/private'
}

const abortControllers: Record<string, AbortController> = {};

export const httpCustomerCore: AxiosInstance = {
  ...httpCustomerCoreApi,
  get: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
    const path = window.location.pathname;
    if (!abortControllers[path]) {
      abortControllers[path] = new AbortController();
    }
    
    const signal = Object.values(CancellationExceptions).includes(url as CancellationExceptions) ? undefined : abortControllers[path].signal;
    return new Promise((resolve, reject) => {
      httpCustomerCoreApi.get<T, R, D>(url, { ...config, signal }).then(response => {
        resolve(response);
      }).catch(error => {
        if (!axios.isCancel(error)) {
          reject(error);
        } else {
          logError(`API Cancelled: page = '${path}' api = GET '${url}'`);
        }
      });
    });
  },
  post: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => {
    const path = window.location.pathname;
    if (!abortControllers[path]) {
      abortControllers[path] = new AbortController();
    }
    
    const signal = Object.values(CancellationExceptions).includes(url as CancellationExceptions) ? undefined : abortControllers[path].signal;
    return new Promise((resolve, reject) => {
      httpCustomerCoreApi.post<T, R, D>(url, data, { ...config, signal }).then(response => {
        resolve(response);
      }).catch(error => {
        if (!axios.isCancel(error)) {
          reject(error);
        } else {
          logError(`API Cancelled: page = '${path}' api = POST '${url}'`);
        }
      });
    });
  },
  put: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => {
    const path = window.location.pathname;
    if (!abortControllers[path]) {
      abortControllers[path] = new AbortController();
    }
    
    const signal = Object.values(CancellationExceptions).includes(url as CancellationExceptions) ? undefined : abortControllers[path].signal;
    return new Promise((resolve, reject) => {
      httpCustomerCoreApi.put<T, R, D>(url, data, { ...config, signal }).then(response => {
        resolve(response);
      }).catch(error => {
        if (!axios.isCancel(error)) {
          reject(error);
        } else {
          logError(`API Cancelled: page = '${path}' api = PUT '${url}'`);
        }
      });
    });
  },
  delete: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
    const path = window.location.pathname;
    if (!abortControllers[path]) {
      abortControllers[path] = new AbortController();
    }
    
    const signal = Object.values(CancellationExceptions).includes(url as CancellationExceptions) ? undefined : abortControllers[path].signal;
    return new Promise((resolve, reject) => {
      httpCustomerCoreApi.delete<T, R, D>(url, { ...config, signal }).then(response => {
        resolve(response);
      }).catch(error => {
        if (!axios.isCancel(error)) {
          reject(error);
        } else {
          logError(`API Cancelled: page = '${path}' api = DELETE '${url}'`);
        }
      });
    });
  }
} as AxiosInstance;

export function abortPreviousApis(currentLocation?: string): void {
  if (currentLocation) {
    for (const abortPath in abortControllers) {
      if (abortControllers[abortPath] && abortPath !== currentLocation) {
        abortControllers[abortPath].abort();
        delete abortControllers[abortPath];
      }
    }
  }
}
