import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fontFamilyBase: 'Montserrat, Helvetica Neue, Helvetica, sans-serif',
  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  colors: {
    brandDefault: '#191b18',
    brandDefaultQuiet: '#9ea39c',
    brandPrimary: '#009d90',
    brandDanger: '#d42727',
    brandWarning: '#C69035',
    grayBase: '#242833',
    grayDark: '#b4b4c4',
    grayDarker: '#87879c',
    grayDarkest: '#f9f9f9',
    grayText: '#4d4f53',
    grayLight: '#f2f2f5',
    grayLighter: '#f6f6f6',
    brandWhite: '#ffffff',
    brandBlack: '#000000',
    brandGreenLight: '#e5f6f4',
    brandGreenLighter: '#b9e5e2',
    brandGreenDark: '#00A69A',
    brandGreenDarker: '#17978b',

    labelText: '#9CAFC3',
    wasteColor: '#CCD8E1',

    compactorGray: '#B8C5D6',
  },

  chart: {
    chartSecondBlue: '#345C92',
    chartGreen: '#32BDC1',
    chartBlue: '#357FE5',
    chartIndigo: '#5907A2',
    chartViolet: '#AA00FF',
    chartRed: '#E53935',
    chartOrange: '#FF8200',
    chartYellow: '#FFD270',
    chartGray: '#B9B9B9',
  },
};

export default theme;
