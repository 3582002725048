export enum BrandingType {
  Premier = 'Premier',
  PremierHaulerInvoices = 'PremierHaulerInvoices',
  RegWatchUpdates = 'RegWatchUpdates',
  RegWatchAssessments = 'RegWatchAssessments',
  RecognizedMediaContent = 'RecognizedMediaContent',
  RecognizedMarketingContent = 'RecognizedMarketingContent',
  RssNss = 'RssNss',
  EndDestination = 'EndDestination',
  ServiceConfirmationAnalytics = 'ServiceConfirmationAnalytics',
  Marketplace = 'Marketplace',
}

export enum BrandingComponentKey {
  ServiceSiteProfilePage = 'ServiceSiteProfilePage',
  HaulerInvoices = 'HaulerInvoices',
  EndDestination = 'EndDestination'
}

type BrandedContentDictionaryElement = {
  customerKey: BrandingType;
  components: BrandingComponentKey[];
};

const brandedContentDictionary: BrandedContentDictionaryElement[] = [
  {
    customerKey: BrandingType.Premier,
    components: [BrandingComponentKey.ServiceSiteProfilePage],
  },
  {
    customerKey: BrandingType.PremierHaulerInvoices,
    components: [BrandingComponentKey.HaulerInvoices],
  },
  {
    customerKey: BrandingType.EndDestination,
    components: [BrandingComponentKey.EndDestination],
  },
];

export default brandedContentDictionary;
