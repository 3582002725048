import React, { Suspense } from 'react';

import isString from 'lodash-es/isString';
import { useSelector } from 'react-redux';
import { Path, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { ModalStateProps } from 'contracts/types/modals';
import { ApplicationState } from 'contracts/types/state';
import { DisablePageScroll, EnablePageScroll } from 'core/helpers';
import { useModalManager, useOnLocationChange } from 'core/helpers/hooks';
import { getLocationParams } from 'core/services/queryStringParser';

import { PageLoading } from '../styled';
import { ModalSize } from '../styled/Modal';

import { modalDictionary, ModalRoutes, ModalType } from '.';

// Modal manager usage:
// const { openModal } = useModalManager();
// openModal({
//   modalType: ModalType,
//   props: ModalProps extends ModalComponentProps
// });
export const ModalManager: React.FC = () => {
  const { closeModal } = useModalManager();
  
  useOnLocationChange(() => {
    modals.forEach(modal => {
      if (modal.isOpen) {
        closeModal(modal.id);
      }
    });
  });

  const modals = useSelector(
    (state: ApplicationState) => state.core.modalManager.modals
  );

  const renderModal = (modal: ModalStateProps): JSX.Element | null => {
    const Component = modalDictionary[modal.modalType];
    if (Component) {
      const alreadyOpened = modals.find(m => m.id === modal.id)?.isOpen;
      if (!alreadyOpened) {
        const onModalClose = (value?: boolean): void => {
          if (componenetModalClose) {
            componenetModalClose(value);
          }
          closeModal(modal.id);
          modal.isOpen = false;
          if (!modals.find(m => m.isOpen)) {
            EnablePageScroll();
          }
        };

        const componenetModalClose = modal.props.closeModal;
        modal.props.closeModal = onModalClose;
        modal.isOpen = true;
      }
      DisablePageScroll();
      return (
        <Suspense key={modal.id} fallback={<PageLoading />}>
          <Component key={modal.id} { ...modal.props} />
        </Suspense>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {modals.map(modal => renderModal(modal))}
    </React.Fragment>
  );
};

export const ModalRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path={ModalRoutes.service} element={
        <FullscreenModal modalType={ModalType.service} />
      } />
    </Routes>
  );
};

// Full screen modals usage: navigate(route: ModalRoutes, ROUTE_ON_CLOSE?: Route);
const FullscreenModal: React.FC<{ modalType: ModalType }> = ({ modalType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Component = modalDictionary[modalType];
  if (Component) {
    const onModalClose = (): void => {
      let redirectUrl: string | undefined = undefined;
      if (
        location.state &&
        (location.state as Path).pathname
      ) {
        redirectUrl =
          (location.state as Path).pathname +
          ((location.state as Path).search || '');
      }

      if (isString(redirectUrl)) {
        navigate(redirectUrl);
      } else {
        navigate(-1);
      }
    };
    const params = getLocationParams(location.search);
    return (
      <Component modalSize={ModalSize.fullScreen} closeModal={onModalClose} routeParams={params}/>
    );
  } else {
    return null;
  }
};

export default ModalManager;
