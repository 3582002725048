import { combineReducers } from 'redux';

import { reducer as accountDetailsReducer } from './accountDetails';
import { reducer as communicationSettingsReducer } from './communicationSettings';
import { reducer as currentTagReducer } from './currentTag';
import { reducer as currentUserReducer } from './currentUser';
import { reducer as keyManagementReducer } from './keyManagement';
import { reducer as loginReducer } from './login';
import { reducer as newsReducer } from './news';
import { reducer as notificationsReducer } from './notifications';
import { reducer as registerReducer } from './register';
import { reducer as usersSitesTagsReducer } from './usersSitesTags';

const reducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  accountDetails: accountDetailsReducer,
  usersSitesTags: usersSitesTagsReducer,
  currentUser: currentUserReducer,
  currentTag: currentTagReducer,
  news: newsReducer,
  notifications: notificationsReducer,
  communicationSettings: communicationSettingsReducer,
  keyManagement: keyManagementReducer
});

export default reducer;
