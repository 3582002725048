import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { theme } from 'core/styles';

import { Input } from './Input';

export const SelectStyle = css`
  appearance: none;
  padding-right: 36px;
  line-height: 24px;
  font-size: 16px;

  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iLjQ5OCIvPjwvc3ZnPg==')
    right 2px center no-repeat;

  &:focus {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwbDUuNSA2TDExIDB6IiBmaWxsPSIjMDA5RDkwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
  }
  &::-ms-expand {
    display: none;
  }
`;

interface SelectProps {
  isEmpty: boolean;
}
export const Select = styled(Input.withComponent('select'))<SelectProps>`
  ${SelectStyle};
  color: ${transparentize(0.1, theme.colors.brandBlack)};
  ${props =>
    props.isEmpty &&
    css`
      color: ${transparentize(0.6, theme.colors.brandBlack)};
    `};
`;
