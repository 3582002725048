export interface IFilterState {
  readonly stateId: string;
  readonly countryId: string;
  readonly stateName: string;
}

class FilterState {
  stateId?: string;
  countryId?: string;
  stateName?: string;

  constructor(data?: Partial<IFilterState>) {
    this.stateId = data?.stateId;
    this.countryId = data?.countryId;
    this.stateName = data?.stateName;
  }
}

export default FilterState;
