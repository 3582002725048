// Services
import update from 'immutability-helper';

import {
  getPaymentMethodDetails,
  setAutoPayForAccount,
  updateDefaultPaymentMethod,
} from 'billing/services/zuoraServices';
import { NotificationType } from 'contracts/enums';
import { ZuoraPaymentMethod } from 'contracts/models/service/ZuoraPayment';
import {
  ActionDispatcher,
  PaymentMethodDetailsAction,
} from 'contracts/types/action';
import {
  ApplicationState,
  PaymentMethodDetailsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import { createTimedNotificationMessage } from 'core/ducks/notifier';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

// Actions Keys
const ROOT_KEY = 'billing/paymentMethodDetail';
enum ActionKey {
  LOAD_PAYMENT_METHOD = 'billing/paymentMethodDetail/LOAD_PAYMENT_METHOD',
  MAKE_DEFAULT = 'billing/paymentMethodDetail/MAKE_DEFAULT',
  SET_AUTO_PAY = 'billing/paymentMethodDetail/SET_AUTO_PAY',
  RESET = 'billing/paymentMethodDetail/RESET',
}

// Initial State
const getInitialState: () => PaymentMethodDetailsState = () => {
  return {
    paymentMethodDetails: {} as ZuoraPaymentMethod,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_PAYMENT_METHOD,
  ActionKey.MAKE_DEFAULT,
  ActionKey.SET_AUTO_PAY,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  PaymentMethodDetailsState,
  PaymentMethodDetailsAction
> = {
  [ActionKey.LOAD_PAYMENT_METHOD]: (state, action) => {
    return update(state, {
      $merge: {
        paymentMethodDetails: action.paymentMethodDetails,
      },
    });
  },
  [ActionKey.MAKE_DEFAULT]: state => {
    return state;
  },
  [ActionKey.SET_AUTO_PAY]: state => {
    return state;
  },
};

export const reducer = getReducerBuilder<
  PaymentMethodDetailsState,
  PaymentMethodDetailsAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_PAYMENT_METHOD: (
    paymentMethodDetails?: ZuoraPaymentMethod,
  ): PaymentMethodDetailsAction => ({
    type: ActionKey.LOAD_PAYMENT_METHOD,
    paymentMethodDetails,
  }),
  MAKE_DEFAULT: (): PaymentMethodDetailsAction => ({
    type: ActionKey.MAKE_DEFAULT,
  }),
  SET_AUTO_PAY: (): PaymentMethodDetailsAction => ({
    type: ActionKey.SET_AUTO_PAY,
  }),
  RESET: (): PaymentMethodDetailsAction => ({ type: ActionKey.RESET }),
};

// Thunks
const loadPaymentMethodDetails = (paymentMethodId: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_PAYMENT_METHOD,
    async() => getPaymentMethodDetails(paymentMethodId),
    result => {
      dispatch(actionMap.LOAD_PAYMENT_METHOD(result));
    },
    () => dispatch(actionMap.LOAD_PAYMENT_METHOD()),
    true,
  );

const updatePaymentMethod = (
  siteId: string,
  custId: string,
  paymentMethodId: string,
  autoPay: boolean,
  onClose: () => void,
) => async(dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.MAKE_DEFAULT,
    async() => updateDefaultPaymentMethod(siteId, custId, paymentMethodId, autoPay),
    () => {
      dispatch(actionMap.MAKE_DEFAULT());
      dispatch(
        createTimedNotificationMessage(
          NotificationType.Success,
          'Payment Method added successfully.',
        ),
      );
      // close modal with delay
      setTimeout(() => {
        onClose();
      }, 100);
    },
    () => {
      dispatch(actionMap.MAKE_DEFAULT());
    },
    true,
  ); 

const setAutoPay = (isAutoPay: boolean, accountId: string) => async(
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.SET_AUTO_PAY,
    async() => setAutoPayForAccount(isAutoPay, accountId),
    () => {
      dispatch(actionMap.SET_AUTO_PAY());
    },
    () => {
      dispatch(actionMap.SET_AUTO_PAY());
    },
    true,
  );

const paymentMethodDetailsDuck = {
  actionKeys: ActionKey,
  thunks: {
    loadPaymentMethodDetails,
    updatePaymentMethod,
    setAutoPay,
  },
  actions: { reset: actionMap.RESET },
};

export default paymentMethodDetailsDuck;
