import styled, { css } from 'styled-components';

import { theme } from 'core/styles';

interface SwitchIconProps {
  selected?: boolean;
  disabled?: boolean;
}
export const SwitchIconButton = styled.div<SwitchIconProps>`
  height: 40px;
  width: 50%;
  color: ${theme.colors.brandBlack};
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${props => props.selected && css`
    color: ${theme.colors.brandGreenDark};
  `};
  ${props => props.disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const SwitchIconContainer = styled.div`
  height: 40px;
  width: 130px;
  margin-right: 12px;
  margin-left: auto;
  border-radius: 4px;
  background-color: ${theme.colors.brandWhite};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  display: flex;

  ${SwitchIconButton} {
    &:nth-child(1) {
      border-right: 1px solid #ebeff3;
    }
  }
`;
