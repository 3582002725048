export interface InvoiceComparisonInfoEntry {
  billingDate: string;
  total: number;
}

export interface IInvoiceComparisonInfo {
  readonly isMonthly: boolean;
  readonly entries: InvoiceComparisonInfoEntry[];
}

class InvoiceComparisonInfo {
  isMonthly?: boolean;
  entries?: InvoiceComparisonInfoEntry[];

  constructor(data?: Partial<IInvoiceComparisonInfo>) {
    this.isMonthly = data?.isMonthly;
    this.entries = data?.entries;
  }
}

export default InvoiceComparisonInfo;
