import update from 'immutability-helper';

import { CustomerSiteEquipment, CustomerUserSiteLocation } from 'contracts/models';
import CustomerSearchUserSite from 'contracts/models/service/CustomerSearchUserSite';
import { ActionDispatcher, LocationsAction } from 'contracts/types/action';
import {
  ApplicationState,
  LocationsState,
  ReduceFunctionMap,
} from 'contracts/types/state';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';

import {
  getEquipmentCountDetail,
  getLocations,
  getSearchLocations,
} from '../services/locationsServices';

// Actions Keys
const ROOT_KEY = 'services/locations';
enum ActionKey {
  LOAD_LOCATIONS = 'services/locations/LOAD_LOCATIONS',
  LOAD_SEARCH_LOCATIONS = 'services/locations/LOAD_SEARCH_LOCATIONS',
  LOAD_EQUIPMENT = 'services/locations/LOAD_EQUIPMENT',
  RESET = 'services/locations/RESET',
}

// Initial state
const getInitialState: () => LocationsState = () => {
  return {
    locations: [],
    searchLocations: [],
    equipmentDetails: {},
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_LOCATIONS,
  ActionKey.LOAD_SEARCH_LOCATIONS,
  ActionKey.LOAD_EQUIPMENT,
] as const;

type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  LocationsState,
  LocationsAction
> = {
  [ActionKey.LOAD_LOCATIONS]: (state, action) => {
    const { locations } = action;
    return update(state, {
      $merge: {
        locations,
      },
    });
  },
  [ActionKey.LOAD_SEARCH_LOCATIONS]: (state, action) => {
    const { searchLocations } = action;
    return update(state, {
      $merge: {
        searchLocations,
      },
    });
  },
  [ActionKey.LOAD_EQUIPMENT]: (state, action) => {
    const { equipmentDetails } = action;
    if (!equipmentDetails) {
      return state;
    }
    const newEquipmentDetails = update(state.equipmentDetails, {
      $merge: equipmentDetails,
    });
    return update(state, {
      $merge: {
        equipmentDetails: newEquipmentDetails,
      },
    });
  },
};

export const reducer = getReducerBuilder<LocationsState, LocationsAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOAD_LOCATIONS: (locations?: CustomerUserSiteLocation[]): LocationsAction => ({
    type: ActionKey.LOAD_LOCATIONS,
    locations,
  }),
  LOAD_SEARCH_LOCATIONS: (searchLocations?: CustomerSearchUserSite[]): LocationsAction => ({
    type: ActionKey.LOAD_SEARCH_LOCATIONS,
    searchLocations,
  }),
  LOAD_EQUIPMENT: (equipmentDetails?: {
    [key: string]: CustomerSiteEquipment[];
  }): LocationsAction => ({
    type: ActionKey.LOAD_EQUIPMENT,
    equipmentDetails,
  }),
  RESET: (): LocationsAction => ({ type: ActionKey.RESET }),
};

const loadLocations = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_LOCATIONS,
    () => getLocations(),
    result => {
      dispatch(actionMap.LOAD_LOCATIONS(result));
      window.siteCount = result.length;
    },
    () => dispatch(actionMap.LOAD_LOCATIONS()),
  );

const loadSearchLocations = () => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SEARCH_LOCATIONS,
    () => getSearchLocations(),
    result => {
      dispatch(actionMap.LOAD_SEARCH_LOCATIONS(result));
    },
    () => dispatch(actionMap.LOAD_SEARCH_LOCATIONS()),
  );

const getEquipmentDetails = (custId: string, siteId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_EQUIPMENT,
    () => getEquipmentCountDetail(custId, siteId),
    result => {
      const key = `${siteId}:${custId}`;
      const equipmentDetails = { [key]: result };
      dispatch(actionMap.LOAD_EQUIPMENT(equipmentDetails));
    },
    () => dispatch(actionMap.LOAD_EQUIPMENT()),
  );

const locationsDuck = {
  thunks: { loadLocations, loadSearchLocations, getEquipmentDetails },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};

export default locationsDuck;
