import CustomerUser, {
  ICustomerUser
} from 'contracts/models/service/CustomerUser';
import UserRole, { IUserRole } from 'contracts/models/service/UserRole';
import { CreateUserRequest, SetUserAccessRequest, UpdateUserRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export const getUsers = async(): Promise<CustomerUser[]> => {
  const response = await httpCustomerCore.get<CustomerUser[]>('user/all', {
    transformResponse: transformServiceResponse<ICustomerUser, CustomerUser>(
      CustomerUser,
    ),
  });
  return response.data;
};

export const getUser = async(email?: string): Promise<CustomerUser> => {
  const response = await httpCustomerCore.get<CustomerUser>(
    email ? `user?email=${email}` : 'user',
    {
      transformResponse: transformServiceResponse<ICustomerUser, CustomerUser>(
        CustomerUser,
      ),
    },
  );
  return response.data;
};

export const getUserRoles = async(): Promise<UserRole[]> => {
  const response = await httpCustomerCore.get<UserRole[]>('user/roles', {
    transformResponse: transformServiceResponse<IUserRole, UserRole>(UserRole),
  });
  return response.data;
};

export const createUser = async(params: CreateUserRequest): Promise<number> => {
  const response = await httpCustomerCore.post<number>('user', params);
  return response.data;
};

export const deleteUser = async(userId: number): Promise<unknown> => {
  const response = await httpCustomerCore.delete(`/account/user/${userId}`);
  return response.data;
};

export const updatePhone = async(phoneNumber: string): Promise<unknown> => {
  const response = await httpCustomerCore.put(`user/phone/${phoneNumber}`);
  return response.data;
};

export const updateAccountUser = async(
  userId: number,
  params: UpdateUserRequest,
): Promise<unknown> => {
  const response = await httpCustomerCore.put(`/user?userId=${userId}`, params);
  return response.data;
};

export const getCustomerPhone = async(): Promise<string> => {
  const response = await httpCustomerCore.get<string>('user/phone');
  return response.data;
};

export async function downloadUsersList(): Promise<GetCsvResponse> {
  const response = await getCsv(httpCustomerCore, '/user/export-csv', {});
  return response;
}

export const setUserAccess = async(userId: number, params: SetUserAccessRequest): Promise<unknown> => {
  const response = await httpCustomerCore.put(`/sites/user/access/all?userId=${userId}`, params);
  return response.data;
};
