import { TableData } from 'contracts/types/component';

export interface IMaterialSpendTable {
  readonly hasCostPerTon: boolean;
  readonly costRows: SpendByMaterialTableData[];
  readonly rebateRows: SpendByMaterialTableData[];
}

class MaterialSpendTable {
  hasCostPerTon?: boolean;
  costRows?: SpendByMaterialTableData[];
  rebateRows?: SpendByMaterialTableData[];

  constructor(data?: Partial<IMaterialSpendTable>) {
    this.hasCostPerTon = data?.hasCostPerTon;
    this.costRows = data?.costRows;
    this.rebateRows = data?.rebateRows;
  }
}

export interface SpendByMaterialTableData extends TableData {
  serviceDate: string;
  equipment: string;
  siteName: string;
  material: string;
  tonnage: number;
  total: number;
  totalPerTon: number;
}

export default MaterialSpendTable;
