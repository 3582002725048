import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SubscriptionComponentKey from 'contracts/enums/SubscriptionComponentKey';
import lazy from 'lazyLoader';
import { AuthRoute } from 'routing/Routes';

import RecognitionRoutes from './recognitionRoutes';

const MarketingContentPage = lazy(() => import('recognition/components/MarketingContent/MarketingContentPage'));
const MediaContentPage = lazy(() => import('recognition/components/MediaContent/MediaContentPage'));

const RecognitionRouter = (): JSX.Element => (
  <Routes>
    {/* Routes for logged in user */}
    <Route path={RecognitionRoutes.mediaContent} element={
      <AuthRoute componentKey={SubscriptionComponentKey.MediaContentPage}>
        <MediaContentPage />
      </AuthRoute>
    } />
    <Route path={RecognitionRoutes.marketingContent} element={
      <AuthRoute componentKey={SubscriptionComponentKey.MarketingContentPage}>
        <MarketingContentPage />
      </AuthRoute>
    } />

    {/* Default Route */}
    <Route path="*" element={<Navigate to={RecognitionRoutes.root + RecognitionRoutes.mediaContent} replace />} />
    <Route index element={<Navigate to={RecognitionRoutes.root + RecognitionRoutes.mediaContent} replace/>} />
  </Routes>
);

export default RecognitionRouter;
