const formatInteger = (value: number): string => {
  if (value.toString().includes('.')) {
    const splittedNumberArray = value.toString().split('.');
    const integerPart = splittedNumberArray[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ',',
    );
    const decimalPart = splittedNumberArray[1];
    return integerPart + '.' + decimalPart;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default formatInteger;
