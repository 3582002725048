import { TableData } from 'contracts/types/component';
import { formatDate } from 'core/helpers';

export interface IReport {
  readonly id: number;
  readonly siteName: string;
  readonly reportYear: number;
  readonly reportType: string;
  readonly createdDate: string;
}

class Report implements TableData {
  id?: number;
  siteName?: string;
  reportYear?: number;
  reportType?: string;
  createdDate?: string;
  isHidden?: boolean;

  constructor(data?: Partial<IReport>) {
    this.id = data?.id;
    this.siteName = data?.siteName;
    this.reportYear = data?.reportYear;
    this.reportType = data?.reportType;
    this.createdDate = formatDate(data?.createdDate);
  }
}

export default Report;
