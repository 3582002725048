import RegWatchSiteProfileUpdates, { IRegWatchSiteProfileUpdates } from 'contracts/models/service/RegWatchSiteProfileUpdates';
import RegWatchUpdates, { IRegWatchUpdates } from 'contracts/models/service/RegWatchUpdates';
import { CustomerRegWatchUpdatesRequest, CustomerRegWatchUpdatesCsvRequest, SiteProfileRegWatchUpdatesRequest, UpdateAcknowledgementRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getRegWatchCustomerUpdates(request: CustomerRegWatchUpdatesRequest): Promise<RegWatchUpdates> {
  const response = await httpCustomerCore.post<RegWatchUpdates>(
    'reg-watch/customer/updates',
    {
      StartDate: request.startDate,
      EndDate: request.endDate,
    },
    {
      transformResponse: transformServiceResponse<
        IRegWatchUpdates,
        RegWatchUpdates
      >(RegWatchUpdates),
    },
  );
  return response.data;
}

export async function getRegWatchSiteProfileUpdates(request: SiteProfileRegWatchUpdatesRequest):
  Promise<RegWatchSiteProfileUpdates> {
  const response = await httpCustomerCore.post<RegWatchSiteProfileUpdates>(
    'reg-watch/customer/site/updates',
    {
      CustId: request.custId,
      SiteId: request.siteId,
      StartDate: request.startDate,
      EndDate: request.endDate,
      PageNumber: request.pageNumber,
      PageSize: request.pageSize,
      AccessToken: request.accessToken
    },
    {
      transformResponse: transformServiceResponse<
        IRegWatchSiteProfileUpdates,
        RegWatchSiteProfileUpdates
      >(RegWatchSiteProfileUpdates),
    },
  );
  return response.data;
}

export async function getRegWatchSingleUpdate(params: UpdateAcknowledgementRequest):
  Promise<RegWatchUpdates> {
  const response = await httpCustomerCore.post<RegWatchUpdates>(
    'reg-watch/update/open',
    { updateId: params.updateId },
    {
      transformResponse: transformServiceResponse<
        IRegWatchUpdates,
        RegWatchUpdates
      >(RegWatchUpdates),
    },
  );
  return response.data;
}

export const submitAcknowledgement = async(params: UpdateAcknowledgementRequest): Promise<unknown> => {
  const response = await httpCustomerCore.put('reg-watch/update/read-undestood', params);
  return response.data;
};

export async function getRegWatchUpdatesCsv(request: CustomerRegWatchUpdatesCsvRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'reg-watch/generate-updates-csv',
    {
      StartDate: request.startDate,
      EndDate: request.endDate,
      SiteFilters: getLocationFilterApiArg(request.locationFilter),
    }
  );
  return response;
}
