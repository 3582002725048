import CustomerSearchUserSite, { ICustomerSearchUserSite } from 'contracts/models/service/CustomerSearchUserSite';
import CustomerSiteEquipment, {
  ICustomerSiteEquipment,
} from 'contracts/models/service/CustomerSiteEquipment';
import CustomerUserSiteLocation, { ICustomerUserSiteLocation } from 'contracts/models/service/CustomerUserSiteLocation';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getLocations(): Promise<CustomerUserSiteLocation[]> {
  const response = await httpCustomerCore.get<CustomerUserSiteLocation[]>(
    'sites/locations',
    {
      transformResponse: transformServiceResponse<
        ICustomerUserSiteLocation,
        CustomerUserSiteLocation
      >(CustomerUserSiteLocation),
    },
  );
  return response.data;
}

export async function getSearchLocations(): Promise<CustomerSearchUserSite[]> {
  const response = await httpCustomerCore.get<CustomerSearchUserSite[]>(
    'sites/site-profile-search',
    {
      transformResponse: transformServiceResponse<
        ICustomerSearchUserSite,
        CustomerSearchUserSite
      >(CustomerSearchUserSite),
    },
  );
  return response.data;
}

export async function getEquipmentCountDetail(
  custId: string,
  siteId: string,
): Promise<CustomerSiteEquipment[]> {
  const response = await httpCustomerCore.get<CustomerSiteEquipment[]>(
    `site/equipments?custId=${custId}&siteId=${siteId}`,
    {
      transformResponse: transformServiceResponse<
        ICustomerSiteEquipment,
        CustomerSiteEquipment
      >(CustomerSiteEquipment),
    },
  );
  return response.data;
}
