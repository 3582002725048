import SaleAddress, {
  ISaleAddress,
} from 'contracts/models/service/SaleAddress';

import { httpCustomerCore, transformServiceResponse } from './http';

const loadAddress = async(searchTerm: string): Promise<SaleAddress> => {
  const response = await httpCustomerCore.post<SaleAddress>(
    'wholesale/usa-address-parse',
    { formatteddAddress: searchTerm },
    {
      transformResponse: transformServiceResponse<ISaleAddress, SaleAddress>(
        SaleAddress,
      ),
    },
  );
  return response.data;
};

export default loadAddress;
