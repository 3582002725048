import { AxiosResponse } from 'axios';

import {
  RegisterUserResult,
  ValidateRegistrationResult,
  ValidateTokenResponse,
} from 'contracts/types/service';
import { httpCustomerCore } from 'core/services/http';

export async function sendRegistrationSetupEmail(
  email: string,
): Promise<AxiosResponse<RegisterUserResult>> {
  const arg = { email };
  return httpCustomerCore.post<RegisterUserResult>(
    'account/send-registration-email',
    arg,
  );
}

export async function validateRegistrationHashKey(
  email: string,
  hashKey: string,
): Promise<ValidateRegistrationResult> {
  const arg = { hashKey, email };
  const response = await httpCustomerCore.post<ValidateRegistrationResult>(
    'account/validate-registration',
    arg,
  );
  return response.data;
}

export async function register(
  emailAddress: string,
  activationCode: string,
  password: string,
  confirmPassword: string,
): Promise<Record<string, unknown>> {
  const arg = { emailAddress, activationCode, password, confirmPassword };
  const response = await httpCustomerCore.post('account/register', arg);
  return response.data;
}

export async function setPassword(
  emailAddress: string,
  password: string,
  confirmPassword: string,
  hashKey: string,
): Promise<Record<string, unknown>> {
  const arg = { emailAddress, password, confirmPassword, hashKey };
  const response = await httpCustomerCore.post('account/set-password', arg);
  return response.data;
}

export async function requestPasswordReset(email: string): Promise<Record<string, unknown>> {
  const arg = { email };
  const response = await httpCustomerCore.post('account/forgot-password', arg);
  return response.data;
}

export async function resetPassword(
  code: string,
  username: string,
  password: string,
): Promise<AxiosResponse<Record<string, unknown>>> {
  const arg = { code, username, password };
  const response = await httpCustomerCore.post('account/reset-password', arg);
  return response.data;
}

export async function changePassword(
  password: string,
  passConfirm: string,
  oldPass: string,
  username: string,
): Promise<Record<string, unknown>> {
  const arg = {
    newPassword: password,
    newPasswordConfirm: passConfirm,
    oldPassword: oldPass,
    userName: username,
  };
  const response = await httpCustomerCore.post('account/change-password', arg);
  return response.data;
}

export async function changeUserPassword(
  password: string,
  passConfirm: string,
  username: string,
): Promise<Record<string, unknown>> {
  const arg = {
    newPassword: password,
    newPasswordConfirm: passConfirm,
    userName: username,
  };
  const response = await httpCustomerCore.post(
    'account/change-user-password',
    arg,
  );
  return response.data;
}

export async function validatePasswordResetKey(
  email: string,
  token: string,
): Promise<ValidateTokenResponse> {
  const response = await httpCustomerCore.post<ValidateTokenResponse>(
    'account/validate/reset-password',
    {
      token,
      email,
    },
  );
  return response.data;
}

export async function validateRegisterKey(
  email: string,
  token: string,
): Promise<ValidateTokenResponse> {
  const response = await httpCustomerCore.post<ValidateTokenResponse>(
    'account/validate/register',
    {
      token,
      email,
    },
  );
  return response.data;
}
