import React, { PureComponent } from 'react';

import { BillingFilter } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

const billingOptions = [
  { id: 'Project', label: translate('core.project') },
  { id: 'Recurring', label: translate('core.recurring') },
];

class BillingTypeFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (selectedIds: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      billingType: selectedIds,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled } = this.props;
    return (
      <DropdownFilter
        label={translate('core.billingType')}
        values={billingOptions}
        selectedIds={currentFilter.billingType || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
      />
    );
  }
}

interface ComponentProps {
  currentFilter: BillingFilter;
  onFilterChanged: (filter: BillingFilter) => void;
  isDisabled?: boolean;
}

export default BillingTypeFilter;
