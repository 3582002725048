import React, { PureComponent } from 'react';

import { FilterRequester } from 'contracts/models';
import { RequesterCustomersFilter as RequesterCustomersFilterType } from 'contracts/types/component';
import translate from 'core/helpers/translate';

import DropdownFilter from '../DropdownFilter';

class RequesterCustomersFilter extends PureComponent<ComponentProps> {
  onFilterChanged = (requesterCustomers: string[]): void => {
    const { currentFilter, onFilterChanged } = this.props;
    onFilterChanged({
      ...currentFilter,
      requesterCustomers,
    });
  };

  render(): React.ReactNode {
    const { currentFilter, isDisabled, isLoading, requesters } = this.props;
    return (
      <DropdownFilter
        label={translate('core.requestedBy')}
        values={requesters.map(requester => ({
          id: requester.requestedByEmail,
          label: requester.requestedByName,
        }))}
        selectedIds={currentFilter.requesterCustomers || []}
        onFilterChanged={this.onFilterChanged}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    );
  }
}

interface ComponentProps {
  requesters: Array<Required<FilterRequester>>;
  currentFilter: RequesterCustomersFilterType;
  onFilterChanged: (filter: RequesterCustomersFilterType) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export default RequesterCustomersFilter;
