import { TableData } from 'contracts/types/component';

export interface ICustomerDocument {
  readonly id: number;
  readonly downloadUrl: string;
  readonly externalUrl: string;
  readonly custId: string;
  readonly siteId: string;
  readonly documentName: string;
  readonly documentType: string;
  readonly createdDate: string;
}

class CustomerDocument implements TableData {
  id?: number;
  downloadUrl?: string;
  externalUrl?: string;
  custId?: string;
  siteId?: string;
  documentName?: string;
  documentType?: string;
  createdDate?: string;
  isHidden?: boolean;

  constructor(data?: Partial<ICustomerDocument>) {
    this.id = data?.id;
    this.downloadUrl = data?.downloadUrl;
    this.externalUrl = data?.externalUrl;
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.documentName = data?.documentName;
    this.documentType = data?.documentType;
    this.createdDate = data?.createdDate;
  }
}

export default CustomerDocument;
