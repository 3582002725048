export interface ILocationBalance {
  readonly custId: string;
  readonly siteId: string;
  readonly addr1: string;
  readonly addr2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly balance: number;
  readonly zuoraAccountId: string;
  readonly zuoraInvoiceOwnerId: string;
  readonly autoPay: boolean;
}

class LocationBalance {
  custId?: string;
  siteId?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  balance?: number;
  zuoraAccountId?: string;
  zuoraInvoiceOwnerId?: string;
  autoPay?: boolean;

  constructor(data?: Partial<ILocationBalance>) {
    this.custId = data?.custId;
    this.siteId = data?.siteId;
    this.addr1 = data?.addr1;
    this.addr2 = data?.addr2;
    this.city = data?.city;
    this.state = data?.state;
    this.zip = data?.zip;
    this.balance = data?.balance;
    this.zuoraAccountId = data?.zuoraAccountId;
    this.zuoraInvoiceOwnerId = data?.zuoraInvoiceOwnerId;
    this.autoPay = data?.autoPay;
  }
}

export default LocationBalance;
