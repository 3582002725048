import React, { Component } from 'react';

import { GetBlobResponse, GetCsvResponse } from 'contracts/types/service';
import translate from 'core/helpers/translate';
import { theme } from 'core/styles';

import DownloadBlob from './DownloadBlob';
import {
  ExportCsvButtonStyled,
  ExportCsvGeneratingSpan,
  ExportCsvIcon,
  ExportCsvTextSpan,
} from './styled/ExportCsvButton';

class ExportCsvButton<T> extends Component<ComponentProps<T>> {
  getBlob = async(): Promise<GetBlobResponse> => {
    const { args, generateCsvFunc } = this.props;
    const { filename, content } = await generateCsvFunc(args);
    const blob = new Blob([content], { type: 'text/csv' });
    return { blob, filename };
  };

  render(): React.ReactNode {
    return (
      <span>
        <DownloadBlob
          generatingComponent={() => (
            <ExportCsvGeneratingSpan>{translate('generatingCsv')}...</ExportCsvGeneratingSpan>
          )}
          getBlob={this.getBlob}
        >
          <ExportCsvButtonStyled>
            <ExportCsvTextSpan>{translate('exportData')}</ExportCsvTextSpan>
            <ExportCsvIcon
              icon='download'
              iconColor={theme.colors.brandPrimary}
              size={11}
            />
          </ExportCsvButtonStyled>
        </DownloadBlob>
      </span>
    );
  }
}

interface ComponentProps<T> {
  args: T;
  generateCsvFunc: (args: T) => Promise<GetCsvResponse>;
}
export default ExportCsvButton;
