export interface IRecurringServiceDropdownOptions {
  readonly serviceCodes: RecurringServiceDropdownOptionItem[];
  readonly materialTypes: RecurringServiceDropdownOptionItem[];
  readonly frequency: RecurringServiceDropdownOptionItem[];
  readonly disposalMethods: RecurringServiceDropdownOptionItem[];
  readonly rules: RecurringServiceDropdownOptionItemDisposalMethod[];
}

class RecurringServiceDropdownOptions {
  serviceCodes?: RecurringServiceDropdownOptionItem[];
  materialTypes?: RecurringServiceDropdownOptionItem[];
  frequency?: RecurringServiceDropdownOptionItem[];
  disposalMethods?: RecurringServiceDropdownOptionItem[];
  rules?: RecurringServiceDropdownOptionItemDisposalMethod[];

  constructor(data?: Partial<IRecurringServiceDropdownOptions>) {
    this.serviceCodes = data?.serviceCodes;
    this.materialTypes = data?.materialTypes;
    this.frequency = data?.frequency;
    this.disposalMethods = data?.disposalMethods;
    this.rules = data?.rules;
  }
}

export default RecurringServiceDropdownOptions;

export interface RecurringServiceDropdownOptionItem {
  id: string;
  value: string;
}

export interface RecurringServiceDropdownOptionItemDisposalMethod {
  id: string;
  value: string;
  referenceId: string;
  referenceValue: string;
}
