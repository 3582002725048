import { SustainabilityDiversionSortKey } from 'contracts/enums';
import SustainabilityDiversionSummary, {
  ISustainabilityDiversionSummary,
} from 'contracts/models/service/SustainabilityDiversionSummary';
import { SustainabilityDiversionTableData } from 'contracts/models/service/SustainabilityDiversionTable';
import { DiversionRequest } from 'contracts/types/request';
import { GetCsvResponse } from 'contracts/types/service';
import { buildSortArg } from 'core/components/FlexTable/sortTableHeaderManager';
import { getCsv } from 'core/services/fileManagement';
import { getLocationFilterApiArg } from 'core/services/getLocationFilterApiArg';
import { httpCustomerCore, transformServiceResponse } from 'core/services/http';

export async function getDiversionData({
  fromDate,
  toDate,
  reportFilters,
  materialType,
  diverted,
  materialCategoryId,
}: DiversionRequest): Promise<SustainabilityDiversionSummary> {
  const response = await httpCustomerCore.post<SustainabilityDiversionSummary>(
    'sustainability/diversion-summary',
    {
      fromDate,
      toDate,
      reportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        isTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        isPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      materialType,
      diverted,
      materialCategoryId,
    },
    {
      transformResponse: transformServiceResponse<
        ISustainabilityDiversionSummary,
        SustainabilityDiversionSummary
      >(SustainabilityDiversionSummary),
    },
  );
  return response.data;
}

export async function getDiversionTableData({
  fromDate,
  toDate,
  reportFilters,
  materialType,
  diverted,
  materialCategoryId,
  sortFields,
}: DiversionRequest): Promise<SustainabilityDiversionTableData[]> {
  const response = await httpCustomerCore.post<
    SustainabilityDiversionTableData[]
  >('sustainability/diversion-table', {
    fromDate,
    toDate,
    reportFilters: {
      ...getLocationFilterApiArg(reportFilters),
      isTemporary: Boolean(reportFilters && reportFilters.isTemporary),
      isPermanent: Boolean(reportFilters && reportFilters.isPermanent),
    },
    materialType,
    diverted,
    materialCategoryId,
    orderByAscending:
      sortFields && sortFields.ascending
        ? buildSortArg(sortFields.ascending, SustainabilityDiversionSortKey)
        : {},
    orderByDescending:
      sortFields && sortFields.descending
        ? buildSortArg(sortFields.descending, SustainabilityDiversionSortKey)
        : {},
  });

  return response.data;
}

export async function getDiversionCsv({
  fromDate,
  toDate,
  reportFilters,
  materialType,
  diverted,
  materialCategoryId,
}: DiversionRequest): Promise<GetCsvResponse> {
  const response = await getCsv(
    httpCustomerCore,
    'sustainability/diversion-csv',
    {
      fromDate,
      toDate,
      reportFilters: {
        ...getLocationFilterApiArg(reportFilters),
        isTemporary: Boolean(reportFilters && reportFilters.isTemporary),
        isPermanent: Boolean(reportFilters && reportFilters.isPermanent),
      },
      materialType,
      diverted,
      materialCategoryId,
    },
  );
  return response;
}
