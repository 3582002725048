export interface IHoliday {
  readonly id: number;
  readonly name: string;
  readonly date: string;
  readonly description: string;
}

class Holiday {
  id?: number;
  name?: string;
  date?: Date;
  description?: string;

  constructor(data?: Partial<IHoliday>) {
    this.id = data?.id;
    this.name = data?.name;
    if (data?.date) {
      this.date = new Date(data.date);
    }
    this.description = data?.description;
  }
}

export default Holiday;
