import update from 'immutability-helper';

import { NotificationType } from 'contracts/enums';
import { ServiceDetails } from 'contracts/models';
import { FormOptionsInterface } from 'contracts/models/service/FormSettingsServiceUpdate';
import ServiceMarketAvailability, {
  ServiceDependencyProps
} from 'contracts/models/service/ServiceMarketAvailability';
import ServiceQuoteItem from 'contracts/models/service/ServiceQuoteItem';
import ServiceSupportContact from 'contracts/models/service/ServiceSupportContact';
import { ServiceUpdateAction } from 'contracts/types/action';
import { ActionDispatcher } from 'contracts/types/PremierSiteProfile/action';
import {
  CreateServiceUpdateRequest,
  ServiceUpdateMarketAvailabilityRequest,
  ServiceUpdateQuoteRequest
} from 'contracts/types/request';
import {
  ApplicationState,
  ReduceFunctionMap,
  ServiceUpdateState
} from 'contracts/types/state';
import { createNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/helpers/translate';
import getReducerBuilder from 'core/reducerBuilder/buildReducer';
import { runTakeLastThunk } from 'core/reducerBuilder/thunkBuilder';
import {
  createServiceUpdate as createServiceUpdateService,
  getMarketAvailability,
  getServiceDetails,
  getServiceQuote,
  getServiceRequestSupportContact
} from 'services/services/serviceUpdate';

// Actions
const ROOT_KEY = 'services/service-update';
enum ActionKey {
  LOAD_SERVICE_DETAILS = 'services/service-update/LOAD_SERVICE_DETAILS',
  LOAD_SERVICE_SUPPORT_CONTACT = 'services/service-update/LOAD_SERVICE_SUPPORT_CONTACT',
  LOAD_MARKET_AVAILABILITY = 'services/service-update/LOAD_MARKET_AVAILABILITY',
  LOAD_SERVICE_QUOTE = 'services/service-update/LOAD_SERVICE_QUOTE',
  CREATE_SERVICE_UPDATE = 'services/service-update/CREATE_SERVICE_UPDATE',
  UPDATE_SELECTED_EQUIPMENT = 'services/service-update/UPDATE_SELECTED_EQUIPMENT',
  RESET_SERVICE_QUOTE = 'services/service-update/RESET_SERVICE_QUOTE',
  UPDATE_FORM_VALUES = 'services/service-update/UPDATE_FORM_VALUES',
  UPDATE_FORM_PROPS = 'services/service-update/UPDATE_FORM_PROPS',
  RESET = 'services/service-update/RESET',
}

// Initial state
const getInitialState: () => ServiceUpdateState = () => {
  return {
    serviceDetails: {},
    supportDetails: {},
    marketAvailability: {},
    quotes: [],
    formSettings: {
      props: {
        editMaterial: true,
        editFrequency: true,
        editNumberOfContainers: true,
        editStartDate: true,
        editEndDate: true,
      },
      formOptions: {},
    },
    selectedEquipment: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_SERVICE_DETAILS,
  ActionKey.LOAD_SERVICE_SUPPORT_CONTACT,
  ActionKey.LOAD_MARKET_AVAILABILITY,
  ActionKey.LOAD_SERVICE_QUOTE,
  ActionKey.CREATE_SERVICE_UPDATE,
  ActionKey.UPDATE_SELECTED_EQUIPMENT,
  ActionKey.RESET_SERVICE_QUOTE,
  ActionKey.UPDATE_FORM_VALUES,
  ActionKey.UPDATE_FORM_PROPS,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  ServiceUpdateState,
  ServiceUpdateAction
> = {
  [ActionKey.LOAD_SERVICE_DETAILS]: (state, action) => {
    const { serviceDetails } = action;
    if (!serviceDetails) {
      return state;
    }
    return update(state, { $merge: { serviceDetails } });
  },
  [ActionKey.LOAD_SERVICE_SUPPORT_CONTACT]: (state, action) => {
    const { supportDetails } = action;
    if (!supportDetails) {
      return state;
    }
    return update(state, { $merge: { supportDetails } });
  },
  [ActionKey.LOAD_MARKET_AVAILABILITY]: (state, action) => {
    const { marketAvailability } = action;
    if (!marketAvailability) {
      return state;
    }
    return update(state, { $merge: { marketAvailability } });
  },
  [ActionKey.LOAD_SERVICE_QUOTE]: (state, action) => {
    const { quotes } = action;
    if (!quotes) {
      return state;
    }
    return update(state, { $merge: { quotes } });
  },
  [ActionKey.CREATE_SERVICE_UPDATE]: (state, action) => {
    const { quotes } = action;
    if (!quotes) {
      return state;
    }
    return update(state, { $merge: { quotes } });
  },
  [ActionKey.UPDATE_SELECTED_EQUIPMENT]: (state, action) => {
    const { selectedEquipment } = action;
    if (!selectedEquipment) {
      return state;
    }
    return update(state, { $merge: { selectedEquipment } });
  },
  [ActionKey.RESET_SERVICE_QUOTE]: state => {
    return update(state, {
      $merge: { quotes: [], selectedEquipment: undefined },
    });
  },
  [ActionKey.UPDATE_FORM_VALUES]: (state, action) => {
    const formOptions = action.formSettings;
    if (!formOptions) {
      return state;
    }
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          formOptions: {
            ...state.formSettings.formOptions,
            ...formOptions,
          },
        },
      },
    });
  },
  [ActionKey.UPDATE_FORM_PROPS]: (state, action) => {
    const props = action.formSettings;
    if (!props) {
      return state;
    }
    return update(state, {
      $merge: {
        formSettings: {
          ...state.formSettings,
          props,
        },
      },
    });
  },
};

export const reducer = getReducerBuilder<
  ServiceUpdateState,
  ServiceUpdateAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Action creators
const actionMap = {
  LOAD_SERVICE_DETAILS: (
    serviceDetails?: ServiceDetails,
  ): ServiceUpdateAction => ({
    type: ActionKey.LOAD_SERVICE_DETAILS,
    serviceDetails,
  }),
  LOAD_SERVICE_SUPPORT_CONTACT: (
    supportDetails?: ServiceSupportContact,
  ): ServiceUpdateAction => ({
    type: ActionKey.LOAD_SERVICE_SUPPORT_CONTACT,
    supportDetails,
  }),
  LOAD_MARKET_AVAILABILITY: (
    marketAvailability?: ServiceMarketAvailability,
  ): ServiceUpdateAction => ({
    type: ActionKey.LOAD_MARKET_AVAILABILITY,
    marketAvailability,
  }),
  LOAD_SERVICE_QUOTE: (quotes?: ServiceQuoteItem[]): ServiceUpdateAction => ({
    type: ActionKey.LOAD_SERVICE_QUOTE,
    quotes,
  }),
  CREATE_SERVICE_UPDATE: (quotes?: ServiceQuoteItem[]): ServiceUpdateAction => ({
    type: ActionKey.CREATE_SERVICE_UPDATE,
    quotes,
  }),
  UPDATE_SELECTED_EQUIPMENT: (
    selectedEquipment?: string,
  ): ServiceUpdateAction => ({
    type: ActionKey.UPDATE_SELECTED_EQUIPMENT,
    selectedEquipment,
  }),
  RESET_SERVICE_QUOTE: (): ServiceUpdateAction => ({
    type: ActionKey.RESET_SERVICE_QUOTE,
  }),
  UPDATE_FORM_VALUES: (
    formSettings?: FormOptionsInterface,
  ): ServiceUpdateAction => ({
    type: ActionKey.UPDATE_FORM_VALUES,
    formSettings,
  }),
  UPDATE_FORM_PROPS: (
    formSettings?: ServiceDependencyProps,
  ): ServiceUpdateAction => ({
    type: ActionKey.UPDATE_FORM_PROPS,
    formSettings,
  }),
  RESET: (): ServiceUpdateAction => ({
    type: ActionKey.RESET,
  }),
};

// Thunks
const loadServiceDetails = (serviceId: number) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_DETAILS,
    async() => getServiceDetails(serviceId),
    result => {
      dispatch(actionMap.LOAD_SERVICE_DETAILS(result));
    },
    err => {
      let errorMessage =
        translate('core.anErrorOccurredLoadingThisPage');

      if (err.response && err.response.status === 403) {
        errorMessage = `You don't have sufficient priviledges to update this service!`;
      }

      if (err.response && err.response.data) {
        errorMessage = err.response.data.message;
      }
      dispatch(actionMap.LOAD_SERVICE_DETAILS());
      dispatch(createNotificationMessage(NotificationType.Error, errorMessage));
    },
  );

const loadServiceSupportContact = (siteId: string, custId: string) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_SUPPORT_CONTACT,
    async() => getServiceRequestSupportContact({ siteId, custId }),
    result => {
      dispatch(actionMap.LOAD_SERVICE_SUPPORT_CONTACT(result));
    },
    err => {
      let errorMessage =
        translate('core.anErrorOccurredLoadingThisPage');

      if (err.response && err.response.status === 403) {
        errorMessage = `You don't have sufficient priviledges to update this service!`;
      }

      if (err.response && err.response.data) {
        errorMessage = err.response.data.message;
      }
      dispatch(actionMap.LOAD_SERVICE_SUPPORT_CONTACT());
      dispatch(createNotificationMessage(NotificationType.Error, errorMessage));
    },
  );

const loadMarketAvailability = (
  params: ServiceUpdateMarketAvailabilityRequest,
) => (dispatch: ActionDispatcher, getState: () => ApplicationState) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_MARKET_AVAILABILITY,
    async() => getMarketAvailability(params),
    result => {
      dispatch(actionMap.LOAD_MARKET_AVAILABILITY(result));
      const formOptions = {
        serviceLabels: result.serviceLabels,
        materialLabels: result.materialLabels,
        frequencyLabels: result.frequencyLabels,
        scheduleLabels: result.scheduleLabels,
      };
      dispatch(actionMap.UPDATE_FORM_VALUES(formOptions));
    },
    () => {
      dispatch(actionMap.LOAD_MARKET_AVAILABILITY());
    },
    true,
  );

export const updateFormSettings = (
  props: ServiceDependencyProps | undefined,
  formOptions: FormOptionsInterface,
) => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.UPDATE_FORM_PROPS(props));
  dispatch(actionMap.UPDATE_FORM_VALUES(formOptions));
};

export const resetServiceQuote = () => (dispatch: ActionDispatcher) => {
  dispatch(actionMap.RESET_SERVICE_QUOTE());
};

export const updateSelectedEquipment = (selectedEquipment?: string) => (
  dispatch: ActionDispatcher,
) => {
  dispatch(actionMap.UPDATE_SELECTED_EQUIPMENT(selectedEquipment));
};

export const loadServiceQuote = (params: ServiceUpdateQuoteRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.LOAD_SERVICE_QUOTE,
    async() => getServiceQuote(params),
    result => {
      if (result.quotes.length === 0) {
        dispatch(
          createNotificationMessage(
            NotificationType.Error,
            'There are no prices available for the current selection! Please try a different configuration.',
          ),
        );
      }
      dispatch(actionMap.LOAD_SERVICE_QUOTE(result.quotes));
    },
    () => {
      dispatch(actionMap.LOAD_SERVICE_QUOTE([]));
    },
    false
  );

export const createServiceUpdate = (params: CreateServiceUpdateRequest) => (
  dispatch: ActionDispatcher,
  getState: () => ApplicationState,
) =>
  runTakeLastThunk(
    dispatch,
    getState,
    ActionKey.CREATE_SERVICE_UPDATE,
    async() => createServiceUpdateService(params),
    () => {},
    () => {},
    true,
  );

const serviceUpdateDuck = {
  thunks: {
    loadServiceDetails,
    loadServiceSupportContact,
    loadMarketAvailability,
    loadServiceQuote,
    createServiceUpdate,
    updateFormSettings,
    updateSelectedEquipment,
    resetServiceQuote,
  },
  actions: { reset: actionMap.RESET },
  actionKeys: ActionKey,
};
export default serviceUpdateDuck;
