import NewService from './NewService';
import { NewServiceEquipmentRequest } from './NewServiceQuote';

export interface INewServiceAvailability{
  readonly serviceLabels: NewServiceAvailabilityItem[];
}

class NewServiceAvailability {
  serviceLabels?: NewServiceAvailabilityItem[];

  constructor(data?: Partial<INewServiceAvailability>) {
    this.serviceLabels = data?.serviceLabels;
  }
}

export interface NewServiceAvailabilityItem {
  code: string;
  label: string;
}

export interface NewServiceLocationDetails {
  siteId: string;
  customerId: string
  address: string;
  zipCode: string;
  locationName: string,
  duration?: string,
}

export interface NewServiceCart {
  location?: NewServiceLocationDetails,
  services: NewServiceEquipmentRequest[],
  simplifiedServices: NewService[], 
}

export default NewServiceAvailability;
