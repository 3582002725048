enum SubscriptionItems {
  ManagedService = 'ManagedService',
  UnmanageServicedUpload = 'UnmanageServicedUpload',
  UnmanagedServiceOCR = 'UnmanagedServiceOCR',
  BillConsolidation = 'BillConsolidation',
  AuditInvoice = 'AuditInvoice',
  InvoicingBilling = 'InvoicingBilling',
  ProcurementManaged = 'ProcurementManaged',
  ProcurementTool = 'ProcurementTool',
  CustomReport = 'CustomReport',
  PublicApi = 'PublicApi',
  ESGReporting = 'ESGReporting',
  EquipmentSensor = 'EquipmentSensor',
  Regwatch = 'Regwatch',
  BillPay = 'BillPay',
  SecretShopper = 'SecretShopper',
  SSOLogin = 'SSOLogin',
  SolutionConsulting = 'SolutionConsulting'
}

export default SubscriptionItems;
