import { UploadFileResponse } from 'contracts/types/service';

import { OpenTopPriceEquipmentRequestModel } from './OpenTopPriceQuoteModel';

export interface LocationsList {
  address: WholesaleAddress;
  services: WholesaleService[];
}

export interface WholesaleAddress {
  id: string;
  latitude: number;
  longitude: number;
  streetNumber: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  line1: string;
}

export interface WholesaleService extends OpenTopPriceEquipmentRequestModel{
  priceToken: string;
  price: WholesalePrice;
  address: string;
  zipCode: string;
  equipment: string;
  material: string;
  hasLiner: boolean;
  poNumber: string;
  uuidService: string;
  serviceType: string;
  placementInstructionFiles: WholesaleDocument[];
  startDate: string;
  endDate: string;
  notes: string;
}

export interface WholesaleDocument extends UploadFileResponse {
  fileUrl?: string;
}

export interface WholesalePrice {
  activation: number;
  depositAmmount: number;
  tonnageIncluded: number;
  disposal: number;
  overTonnage: number;
  inactivity: number;
  usageInDays: number;
  dryRun: number;
  haul: number;
  delivery: number;
  liner: number;
}

export interface WholesaleNavigationItem {
  isActive: boolean;
  isChecked: boolean;
  navLabel: string;
  navInfo: string;
  navPath: string;
  labelIndex: number;
}

export interface IWholesaleCart {
  readonly pagePath: string;
  readonly activeAddressIndex: number;
  readonly serviceCount: number;
  readonly serviceDateCount: number;
  readonly locations: LocationsList[];
  readonly navigationItems: WholesaleNavigationItem[];
}

class WholesaleCart {
  pagePath?: string;
  activeAddressIndex?: number;
  serviceCount?: number;
  serviceDateCount?: number;
  locations?: LocationsList[];
  navigationItems?: WholesaleNavigationItem[];

  constructor(data?: Partial<IWholesaleCart>) {
    this.pagePath = data?.pagePath;
    this.activeAddressIndex = data?.activeAddressIndex;
    this.serviceCount = data?.serviceCount;
    this.serviceDateCount = data?.serviceDateCount;
    this.locations = data?.locations;
    this.navigationItems = data?.navigationItems;
  }
}

export default WholesaleCart;
