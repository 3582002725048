enum SustainabilityDiversionSortKey {
  Date = 'Date',
  Address = 'Address',
  Equipment = 'Equipment',
  EquipmentSize = 'EquipmentSize',
  Material = 'Material',
  Volume = 'Volume',
}

export default SustainabilityDiversionSortKey;
