export interface IEcoBillStatus {
  readonly totalSites: number;
  readonly totalEcoBillSites: number;
}

class EcoBillStatus {
  totalSites?: number;
  totalEcoBillSites?: number;

  constructor(data?: Partial<IEcoBillStatus>) {
    this.totalSites = data?.totalSites;
    this.totalEcoBillSites = data?.totalEcoBillSites;
  }
}

export default EcoBillStatus;
