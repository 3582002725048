import { TooltipData } from 'contracts/types/chart';

import { ChartSeries } from '..';

class BarSeries extends ChartSeries {
  values: number[];
  tooltipValues?: TooltipData[];
  labelValues?: string[];
  colors?: string[];
  barThickness?: number;

  constructor({
    title,
    identifier,
    color,
    values,
    colors,
    barThickness,
  }: BarSeries) {
    super({ title, identifier, color });
    this.values = values;
    this.colors = colors;
    this.barThickness = barThickness;
  }
}

export default BarSeries;
